import React from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";

import {Box} from "@mui/material";
import WarningIcon from '@mui/icons-material/Warning';

import {AppState} from "../state/state";
import {LoginWorkspaceBlock} from "../../workspace/auth/login-block/LoginWorkspaceBlock";
import {Workspace} from "../../qdep/components/app/workspace/Workspace";
import {Spacer} from "../../qdep/components/app/util/spacer/Spacer";

import './LoginPage.css'


interface LoginPageProps {
    isLogged: boolean
    isWaiting: boolean
    errorMessage?: string

    appVersion: string
}

const LoginPage = (props: LoginPageProps) => {
    if (props.isLogged) {
        return <Redirect to="/"/>
    }

    return <Workspace className="login-page-workspace">
        <LoginWorkspaceBlock isWaiting={props.isWaiting}/>
        { props.errorMessage !== undefined && <Box className={"login-page-error-container"}>
            <WarningIcon color={"error"}/>
            <span className={"text"}>{ props.errorMessage }</span>
        </Box> }
        <Spacer/>
        <div className={"platform-desc"}>
            <div>FINHOUND App { props.appVersion }</div>
        </div>
    </Workspace>;
}

const mapStateToProps = (state: AppState): Omit<LoginPageProps, "appVersion"> => {
    return {
        isLogged: state.auth.isLogged,
        isWaiting: state.auth.isWaiting,
        errorMessage: state.auth.errorMessage,
    };
}

const ConnectedLoginPage = connect(mapStateToProps)(LoginPage)

export {ConnectedLoginPage as LoginPage}