import {AnyAction} from "redux";
import { call, put, select, takeEvery } from 'redux-saga/effects'

import {apiClient, ApiResponse, ErrorReason} from "../../client/app/client";
import {ProjectDto, ProjectListDto} from "../../client/app/entity/Project";
import {
    AppActionTypes,
    projectDeleteRequestSucceeded,
    projectListRequestSucceeded,
} from "../action/AppAction";
import {notificationEmit} from "../../../qdep/notification/reducer";
import {AppState} from "../../state/state";
import {push} from "connected-react-router";
import {authTokenExpired} from "../../../workspace/auth/login-block/data";


function * projectListRequestSaga() {
    let response: ApiResponse<ProjectDto[]> = yield call(apiClient.projectList)
    if (!response.errorReason && response.data) {
        yield put(projectListRequestSucceeded(response.data))
    } else {
        if (response.errorReason === ErrorReason.TOKEN_EXPIRED) {
            yield put(authTokenExpired());
            yield put(push("/login"));
        } else {
            yield put(notificationEmit(
                "project-list-request-error",
                "error",
                "Something went wrong. Try again later or contact your administrator"
            ))
        }
    }
}

function* projectDeleteRequestSaga(action: AnyAction) {
    const response: ApiResponse<boolean> = yield call(apiClient.projectDelete, action.entityId);
    if (!response.errorReason && response.data) {
        yield put(projectDeleteRequestSucceeded(action.entityId))
        yield put(notificationEmit(
            "project-delete-request-success",
            "success",
            "Project deleted successfully"
        ))
    } else {
        if (response.errorReason === ErrorReason.TOKEN_EXPIRED) {
            yield put(authTokenExpired());
            yield put(push("/login"));
        } else {
            yield put(notificationEmit(
                "project-delete-request-error",
                "error",
                "Something went wrong during the deletion of the project. Try again later or contact your administrator"
            ));
        }
    }
}

const selectProject = (state: AppState, index: number) => {
    const projects = state.projects.data.data;
    if (index < projects.length) {
        return projects[index]
    }
    return null;
}

function* projectOpenOverviewSaga(action: AnyAction) {
    const projectIndex: number | undefined = action.index;
    if (projectIndex === undefined) {
        return
    }

    const project: ProjectListDto | null = yield select(selectProject, projectIndex);
    if (project !== null) {
        yield put(push(`report/${project.id}/1/1`))
    }
}

export function * watchProjectApiSaga() {
    yield takeEvery(AppActionTypes.PROJECT_LIST_REQUEST, projectListRequestSaga)
    yield takeEvery(AppActionTypes.PROJECT_DELETE_REQUEST, projectDeleteRequestSaga)
    yield takeEvery(AppActionTypes.PROJECT_OPEN_OVERVIEW, projectOpenOverviewSaga)
}
