import {AnyAction} from "redux";
import produce from "immer";
import {WritableDraft} from "immer/dist/types/types-external";

import {ActionTypes} from "./action";
import {defaultRp4State, Rp4PageMenuItemState, Rp4State, Rp4WorkspaceMode} from "./state";
import {EntitySettings} from "../../../../../app/client/app/entity/report/settings/EntitySettings";
import {FeatureFlagDefinition} from "../../../../../app/client/app/entity/report/settings/FeatureDefinition";
import {
    EntityCategory,
    EntityDefinition, EntitySubItem,
    Feature
} from "../../../../../app/client/app/entity/report/settings/EntityDefinition";
import {Rp4PageMenuItem} from "../../../../../app/client/app/entity/Rp4Report";
import {
    mapEntityDefinitionsToOptions,
    SelectorWarnings,
    sortEntityDefinitions
} from "../../../entity/entity-editor/state";
import {getEntityKey} from "../../../entity/entity-editor/reducer";


function rp4Reducer(state: Rp4State, action: AnyAction): Rp4State {
    return produce(state, draft => {
        switch (action.type) {
            case ActionTypes.SET_ENTITY_SETTINGS:
                setEntitySettingsReducer(draft, action)
                break
            case ActionTypes.SELECT_DEFAULT:
                selectDefaultReducer(draft, action)
                break

            // region entity definition
            case ActionTypes.SELECT_ENTITY_DEFINITION:
                selectEntityDefinitionReducer(draft, action)
                break
            case ActionTypes.SELECT_ENTITY_DEFINITION_BY_ID:
                selectEntityDefinitionByIdReducer(draft, action)
                break
            case ActionTypes.ADD_ENTITY_DEFINITION:
                addEntityDefinitionReducer(draft, action)
                break
            case ActionTypes.DELETE_ENTITY_DEFINITION:
                deleteDefinitionReducer(draft, action);
                break
            case ActionTypes.APPLY_ENTITY_DEFINITION_CHANGES:
                applyEntityDefinitionChangesReducer(draft, action)
                break
            case ActionTypes.DISABLE_ENTITY_DEFINITION:
                disableEntityDefinitionReducer(draft, action)
                break
            // endregion

            // region feature definition
            case ActionTypes.ADD_FEATURE_DEFINITION:
                addFeatureDefinitionReducer(draft, action)
                break
            case ActionTypes.SELECT_FEATURE_DEFINITION:
                selectFeatureDefinitionReducer(draft, action)
                break
            case ActionTypes.SELECT_FEATURE_DEFINITION_BY_ID:
                selectFeatureDefinitionByIdReducer(draft, action)
                break
            case ActionTypes.DELETE_FEATURE_DEFINITION:
                deleteDefinitionReducer(draft, action);
                break
            case ActionTypes.APPLY_FEATURE_DEFINITION_CHANGES:
                applyFeatureDefinitionChangesReducer(draft, action)
                break
            case ActionTypes.DISABLE_FEATURE_DEFINITION:
                disableFeatureDefinitionReducer(draft, action)
                break
            // endregion

            case ActionTypes.SET_PAGES_MENU:
                setPagesMenuReducer(draft, action)
                break
            case ActionTypes.SELECT_PAGE:
                selectPageReducer(draft, action)
                break
            case ActionTypes.SELECT_PAGE_BY_NUM:
                selectPageByNumReducer(draft, action)
                break
        }
        return draft
    })
}

function setEntitySettingsReducer(state: WritableDraft<Rp4State>, action: AnyAction) {
    const settings: EntitySettings | undefined = action.settings;
    if (settings === undefined) {
        return
    }

    const entities = sortEntityDefinitions(settings.entityDefinitions)
    const features = settings.featureFlagDefinitions

    const entityIndex = new Map<string, EntityDefinition>();
    for (let entity of entities) {
        entityIndex.set(getEntityKey(entity), entity)
    }

    state.entityModelRef = settings.entityModelRef || null
    state.entities = entities
    state.entityIndex = entityIndex
    state.features = features

    state.menuItems.features = features
        .map(featureDefinition => ({
            itemId: featureDefinition.id || "new",
            itemName: featureDefinition.featureFlagName,
            selected: false,
            modification: null,
        }))

    const featureOptions: Feature[] = []
    for (let feature of features) {
        for (let value of feature.values) {
            featureOptions.push({name: feature.featureFlagName, value: value.value})
        }
    }

    state.featureOptions = featureOptions
    state.entityVariants = mapEntityDefinitionsToOptions(entities)

    state.menuItems.entities = entities
        .map(entityDefinition => ({
            itemId: entityDefinition.id || "new",
            itemName: entityDefinition.entityName,
            category: entityDefinition.category || EntityCategory.OTHER,
            builtInFeatures: entityDefinition.builtInFeatures,
            selected: false,
            modification: null,
        }))
}

function selectDefaultReducer(state: WritableDraft<Rp4State>, _: AnyAction) {
    state.mode = Rp4WorkspaceMode.ENTITY_EDITOR
    state.menuItems.selectedMenuItem.section = "ENTITY"
    if (state.menuItems.entities.length > 0) {
        state.menuItems.selectedMenuItem.isNew = false
        state.menuItems.selectedMenuItem.index = 0
        state.menuItems.entities[0].selected = true

        state.entityDefinitionEditor = {
            isNew: false,
            entity: state.entities[0],
            relativeEntities: new Map(),
            selectorWarnings: [],
        }
    } else {
        state.menuItems.selectedMenuItem.isNew = true
        state.menuItems.selectedMenuItem.index = undefined
        state.entityDefinitionEditor = defaultRp4State.entityDefinitionEditor
    }
}

// region entity definition
function selectEntityDefinitionReducer(state: WritableDraft<Rp4State>, action: AnyAction) {
    const currIndex: number | undefined = action.index;
    if (currIndex === undefined || currIndex < 0) {
        return
    }

    resetSelectedMenuItem(state)

    if (currIndex < state.menuItems.entities.length) {
        state.menuItems.selectedMenuItem.section = "ENTITY"
        state.menuItems.selectedMenuItem.isNew = false
        state.menuItems.selectedMenuItem.index = currIndex
        state.menuItems.entities[currIndex].selected = true

        const entity = state.entities[currIndex];
        state.entityDefinitionEditor.entity = entity
        state.entityDefinitionEditor.isNew = false

        state.menuItems.queryParams.delete("feature")
        state.menuItems.queryParams.set("entity", entity.id || "new")

        let warnings: SelectorWarnings[] = []
        // todo move to backend entity model validation
        for (let selectorIndex = 0; selectorIndex < entity.selectors.length; selectorIndex++){
            let entitySelector = entity.selectors[selectorIndex];
            if (entitySelector.equal !== undefined && entitySelector.equal !== null && entitySelector.equal !== "") {
                for (let feature of state.features) {
                    for (let featureValue of feature.values) {
                        for (let featureSelector of featureValue.selectors) {
                            if (entitySelector.equal.includes(featureSelector.equal)) {
                                warnings.push({
                                    selectorIndex: selectorIndex,
                                    text: `Selector includes the feature selector (${feature.featureFlagName} / ${featureValue.value})`,
                                })
                            }
                        }
                    }
                }
            }
        }
        state.entityDefinitionEditor.selectorWarnings = warnings

        const relativeEntityNames = listSubEntityNames(entity);
        const relativeEntities = new Map<string, EntityDefinition>()
        for (let entityKey of relativeEntityNames) {
            const relativeEntity = state.entityIndex.get(entityKey)
            if (relativeEntity !== undefined) {
                relativeEntities.set(entityKey, relativeEntity);
            }
        }
        state.entityDefinitionEditor.relativeEntities = relativeEntities
    }

    state.mode = Rp4WorkspaceMode.ENTITY_EDITOR
}

function selectEntityDefinitionByIdReducer(state: WritableDraft<Rp4State>, action: AnyAction) {
    const id: string | undefined = action.id;
    if (id === undefined) {
        return
    }

    const index = state.menuItems.entities.findIndex(entity => entity.itemId === id);
    if (index === -1) {
        return
    }

    selectEntityDefinitionReducer(state, {type: ActionTypes.SELECT_ENTITY_DEFINITION, index: index})
}

function addEntityDefinitionReducer(state: WritableDraft<Rp4State>, _: AnyAction) {
    state.mode = Rp4WorkspaceMode.ENTITY_EDITOR
    resetSelectedMenuItem(state)

    state.menuItems.selectedMenuItem.section = "ENTITY"
    state.menuItems.selectedMenuItem.isNew = true
    state.menuItems.selectedMenuItem.index = undefined

    state.entityDefinitionEditor = defaultRp4State.entityDefinitionEditor
}

function deleteDefinitionReducer(state: WritableDraft<Rp4State>, _: AnyAction) {
    if (state.menuItems.selectedMenuItem.index !== undefined && state.menuItems.selectedMenuItem.section !== undefined) {
        const definitionIndex = state.menuItems.selectedMenuItem.index
        const definitionSection = state.menuItems.selectedMenuItem.section

        if (definitionSection === "ENTITY") {
            if (definitionIndex >= 0 && definitionIndex < state.menuItems.entities.length) {
                state.menuItems.entities[definitionIndex].modification = "DELETED";
            }
        } else if (definitionSection === "FEATURE") {
            if (definitionIndex >= 0 && definitionIndex < state.menuItems.features.length) {
                state.menuItems.entities[definitionIndex].modification = "DELETED";
            }
        }
    }
}

function applyEntityDefinitionChangesReducer(state: WritableDraft<Rp4State>, action: AnyAction) {
    const entityDefinition: EntityDefinition | undefined = action.entityDefinition;
    if (entityDefinition === undefined) {
        return
    }

    if (state.entityDefinitionEditor.isNew) {
        let newEntityIndex = state.entities.findIndex(entity => {
            if (entity.category === entityDefinition.category) {
                return entity.entityName.localeCompare(entityDefinition.entityName) > 0
            }
            return false;
        });
        if (newEntityIndex === -1) {
            newEntityIndex = state.entities.length-1
        }

        state.entities.splice(newEntityIndex, 0, entityDefinition)
        state.menuItems.entities.splice(newEntityIndex, 0, {
            itemId: entityDefinition.id || "new",
            itemName: entityDefinition.entityName,
            category: entityDefinition.category,
            builtInFeatures: entityDefinition.builtInFeatures,
            selected: true,
            modification: "NEW",
        })
        state.entityVariants.splice(newEntityIndex, 0, {
            entityName: entityDefinition.entityName,
            category: entityDefinition.category,
            builtInFeatures: entityDefinition.builtInFeatures,
        })

        state.menuItems.selectedMenuItem.isNew = false
        state.menuItems.selectedMenuItem.index = newEntityIndex

        state.entityDefinitionEditor.isNew = false
        state.entityDefinitionEditor.entity = entityDefinition
    } else if (state.menuItems.selectedMenuItem.index !== undefined) {
        // todo reorder if entityDefinition.entityName is changed
        const entityIndex = state.menuItems.selectedMenuItem.index
        state.menuItems.entities[entityIndex].itemName = entityDefinition.entityName
        state.menuItems.entities[entityIndex].builtInFeatures = entityDefinition.builtInFeatures
        state.menuItems.entities[entityIndex].selected = true
        state.menuItems.entities[entityIndex].modification = "EDITOR"

        state.entities[entityIndex] = entityDefinition
    }
}

function disableEntityDefinitionReducer(state: WritableDraft<Rp4State>, action: AnyAction) {
    const disable: boolean | undefined = action.disable;
    if (disable === undefined) {
        return
    }

    state.entityDefinitionEditor.entity.disabled = disable;

    const entityIndex = state.menuItems.selectedMenuItem.index
    if (entityIndex !== undefined) {
        state.entities[entityIndex].disabled = disable
    }
}
// endregion

// region feature definition
function addFeatureDefinitionReducer(state: WritableDraft<Rp4State>, _: AnyAction) {
    state.mode = Rp4WorkspaceMode.FEATURE_EDITOR
    resetSelectedMenuItem(state)

    state.menuItems.selectedMenuItem.section = "FEATURE"
    state.menuItems.selectedMenuItem.isNew = true
    state.menuItems.selectedMenuItem.index = undefined

    state.featureDefinitionEditor = defaultRp4State.featureDefinitionEditor
}

function selectFeatureDefinitionReducer(state: WritableDraft<Rp4State>, action: AnyAction) {
    const currIndex: number | undefined = action.index;
    if (currIndex === undefined || currIndex < 0) {
        return
    }

    resetSelectedMenuItem(state)

    if (currIndex < state.menuItems.features.length) {
        state.menuItems.selectedMenuItem.section = "FEATURE"
        state.menuItems.selectedMenuItem.isNew = false
        state.menuItems.selectedMenuItem.index = currIndex
        state.menuItems.features[currIndex].selected = true

        const feature = state.features[currIndex];
        state.featureDefinitionEditor.feature = feature
        state.featureDefinitionEditor.isNew = false

        state.menuItems.queryParams.delete("entity")
        state.menuItems.queryParams.set("feature", feature.id || "new")
    }

    state.mode = Rp4WorkspaceMode.FEATURE_EDITOR
}

function selectFeatureDefinitionByIdReducer(state: WritableDraft<Rp4State>, action: AnyAction) {
    const id: string | undefined = action.id;
    if (id === undefined) {
        return
    }

    const index = state.menuItems.features.findIndex(entity => entity.itemId === id);
    if (index === -1) {
        return
    }

    selectFeatureDefinitionReducer(state, {type: ActionTypes.SELECT_FEATURE_DEFINITION, index: index})
}



function applyFeatureDefinitionChangesReducer(state: WritableDraft<Rp4State>, action: AnyAction) {
    const featureDefinition: FeatureFlagDefinition | undefined = action.featureDefinition;
    if (featureDefinition === undefined) {
        return
    }

    if (state.featureDefinitionEditor.isNew) {
        state.features.push(featureDefinition)
        state.menuItems.features.push({
            itemId: featureDefinition.id || "new",
            itemName: featureDefinition.featureFlagName,
            selected: true,
            modification: "NEW",
        })

        state.menuItems.selectedMenuItem.isNew = false
        state.menuItems.selectedMenuItem.index = state.features.length-1

        state.featureDefinitionEditor.isNew = false
        state.featureDefinitionEditor.feature = featureDefinition
    } else if (state.menuItems.selectedMenuItem.index !== undefined) {
        const featureIndex = state.menuItems.selectedMenuItem.index

        state.features[featureIndex] = featureDefinition
        state.menuItems.features[featureIndex].itemName = featureDefinition.featureFlagName
        state.menuItems.features[featureIndex].modification = "EDITOR"
    }
}

function disableFeatureDefinitionReducer(state: WritableDraft<Rp4State>, action: AnyAction) {
    const disable: boolean | undefined = action.disable;
    if (disable === undefined) {
        return
    }

    state.featureDefinitionEditor.feature.disabled = disable;

    const featureIndex = state.menuItems.selectedMenuItem.index
    if (featureIndex !== undefined) {
        state.features[featureIndex].disabled = disable
    }
}
// endregion

function setPagesMenuReducer(state: WritableDraft<Rp4State>, action: AnyAction) {
    const pages: Rp4PageMenuItem[] | undefined = action.pages;
    if (pages) {
        state.menuItems.pages = pages.map<Rp4PageMenuItemState>(item => ({
            ...item,
            selected: false,
        }))
    }
}

function selectPageReducer(state: WritableDraft<Rp4State>, action: AnyAction) {
    const currIndex: number | undefined = action.index;
    if (currIndex === undefined || currIndex < 0) {
        return
    }

    resetSelectedMenuItem(state)

    if (currIndex < state.menuItems.pages.length) {
        state.menuItems.selectedMenuItem.section = "PAGE"
        state.menuItems.selectedMenuItem.isNew = false
        state.menuItems.selectedMenuItem.index = currIndex
        state.menuItems.pages[currIndex].selected = true

        const page = state.menuItems.pages[currIndex].page;
        state.menuItems.selectedMenuItem.page = page

        state.menuItems.queryParams.delete("entity")
        state.menuItems.queryParams.delete("feature")
        state.menuItems.queryParams.set("page", page.toString())
    }

    state.mode = Rp4WorkspaceMode.PAGE_EDITOR
}

function selectPageByNumReducer(state: WritableDraft<Rp4State>, action: AnyAction) {
    const pageNum: number | undefined = action.num;
    if (pageNum === undefined || pageNum < 0) {
        return
    }

    const index = state.menuItems.pages.findIndex(page => page.page === pageNum);
    if (index === -1) {
        return;
    }

    selectPageReducer(state, {type: ActionTypes.SELECT_PAGE, index: index})
}

function resetSelectedMenuItem(state: WritableDraft<Rp4State>) {
    const prevIndex = state.menuItems.selectedMenuItem.index;
    if (prevIndex !== undefined && prevIndex >= 0) {
        if (state.menuItems.selectedMenuItem.section === "ENTITY") {
            if (prevIndex < state.menuItems.entities.length) {
                state.menuItems.entities[prevIndex].selected = false
            }
        } else if (state.menuItems.selectedMenuItem.section === "FEATURE") {
            if (prevIndex < state.menuItems.features.length) {
                state.menuItems.features[prevIndex].selected = false
            }
        } else if (state.menuItems.selectedMenuItem.section === "PAGE") {
            if (prevIndex < state.menuItems.pages.length) {
                state.menuItems.pages[prevIndex].selected = false
            }
        }
    }
    state.menuItems.selectedMenuItem.page = undefined
}

function listSubEntityNames(entity: EntityDefinition): Set<string> {
    const result = new Set<string>()

    let levelSubItems: EntitySubItem[] = []
    for (let variant of entity.variants) {
        levelSubItems.push(...variant.subItems)
    }
    while (levelSubItems.length > 0) {
        const nextLevel: EntitySubItem[] = []

        for (let subItem of levelSubItems) {
            nextLevel.push(...subItem.subItems)
            result.add(getEntityKey(subItem))
        }

        levelSubItems = nextLevel
    }

    return result;
}

export {rp4Reducer}
