import React, {useState} from "react";
import {Pie, PieChart, ResponsiveContainer} from "recharts";

import styles from './IssueStats.module.css'
import {formatPercent, OtherMetricContainer} from "../OtherMetricContainer";
import {renderActiveShape, renderShape} from "./chart";


interface ComponentProps {
    num: number
    numValid: number
    numFailed: number
}

const Rp5IssueStats = (props: ComponentProps) => {
    const [activeBucket, setActiveBucket] = useState<number>(0);

    return <>
        <div className={styles.chart_block}>
            <ResponsiveContainer>
                <PieChart width={350} height={170}>
                    <Pie
                        data={[
                            { name: 'passed', value: props.numValid, fill: 'rgb(7, 163, 0, 0.8)' },
                            { name: 'failed', value: props.numFailed, fill: 'rgb(216,0,12, 0.8)' },
                        ]}
                        activeIndex={activeBucket}
                        activeShape={renderActiveShape}
                        inactiveShape={renderShape}
                        innerRadius={106}
                        outerRadius={136}
                        startAngle={180}
                        endAngle={0}
                        dataKey="value"
                        cx="50%"
                        cy="90%"
                        onMouseEnter={(_, index) => setActiveBucket(index)}
                    />
                </PieChart>
            </ResponsiveContainer>
        </div>
        <div className={styles.metric_block}>
            <div className={styles.metric_block_other_container}>
                <OtherMetricContainer
                    title={"Table Inspections"}
                    value={props.num}
                />
                <OtherMetricContainer
                    title={"Passed"}
                    value={formatPercent(props.numValid / props.num)}
                />
            </div>
        </div>
    </>
}

export {Rp5IssueStats}