import {Stack, Typography} from "@mui/material";

const UnknownErrorBlock = () => {
    return <Stack direction={"column"} justifyContent={"center"} spacing={2} paddingTop={10} width={"100%"}>
        <Typography variant={"h3"}>Whoops, something went wrong on our end.</Typography>
        <Stack>
            <Typography>
                Try refreshing the page, or going back and attempting the action again.
            </Typography>
            <Typography>
                Please contact your administrator if this problem persists.
            </Typography>
        </Stack>
    </Stack>
}

export {UnknownErrorBlock}
