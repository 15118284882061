import React, {useState} from "react";
import {Link} from "react-router-dom";
import * as H from "history";

import {Button, Menu, MenuItem} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import {StageStatuses} from "../../../../../app/client/app/entity/ops/ReportStatusResult";
import {Spacer} from "../../../../../qdep/components/app/util/spacer/Spacer";
import {ReportStageStatusLabel} from "../util/ReportStageStatusLabel";
import {PRRKey} from "../../../../../app/client/app/entity/Project";

import './StageNav.css'
import {StageNames} from "../StageNames";


interface StageNavProps {
    prrKey: PRRKey
    reportPage: string | number | undefined
    reportStage: StageNames
    reportStageStatuses: StageStatuses | undefined
}

const StageNav = (props: StageNavProps) => {
    const [menuAnchorEl, setMenuAnchorEl] = useState(null as Element | null);

    return <div>
        <Button
            sx={{
                '&:hover': {
                    backgroundColor: "rgba(2,123,243,0.30)",
                },
                fontFamily: "inherit",
                fontSize: "inherit",
                fontWeight: "inherit",
                letterSpacing: "inherit",
                whiteSpace: "nowrap",
                lineHeight: "20px",
                marginRight: "8px",
                color: "#027bf3",
                backgroundColor: "rgba(2,123,243,0.20)",
                textTransform: "uppercase"
            }}
            className={"label-container large"}
            onClick={(event) => setMenuAnchorEl(event.currentTarget)}
        >
            { props.reportStage }
            <ArrowDropDownIcon sx={{width: "20px", height: "20px"}}/>
        </Button>

        <Menu
            open={menuAnchorEl !== null}
            anchorEl={menuAnchorEl}
            onClose={()=> setMenuAnchorEl(null)}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <StageMenuItem
                disabled={props.reportStage === StageNames.OCR}
                title={StageNames.OCR}
                status={props.reportStageStatuses?.ocr}
                to={{
                    pathname: `/report/${props.prrKey.projectId}/${props.prrKey.reportIndex}/${props.prrKey.revisionIndex}/ocr`,
                    search: props.reportPage !== undefined ? `?page=${props.reportPage}` : undefined,
                }}
            />
            <StageMenuItem
                disabled={props.reportStage === StageNames.DATA_NORMALIZATION}
                title={StageNames.DATA_NORMALIZATION}
                status={props.reportStageStatuses?.normalization}
                to={{
                    pathname: `/report/${props.prrKey.projectId}/${props.prrKey.reportIndex}/${props.prrKey.revisionIndex}/data-normalization`,
                    search: props.reportPage !== undefined ? `?page=${props.reportPage}` : undefined,
                }}
            />
            <StageMenuItem
                disabled={props.reportStage === StageNames.ENTITY_DETECTION}
                title={StageNames.ENTITY_DETECTION}
                status={props.reportStageStatuses?.entityDetection}
                to={{
                    pathname: `/report/${props.prrKey.projectId}/${props.prrKey.reportIndex}/${props.prrKey.revisionIndex}/entity-detection`,
                    search: props.reportPage !== undefined ? `?page=${props.reportPage}` : undefined,
                }}
            />
            <StageMenuItem
                disabled={props.reportStage === StageNames.TABLE_ANALYSIS}
                title={StageNames.TABLE_ANALYSIS}
                status={props.reportStageStatuses?.tableAnalysis}
                to={{
                    pathname: `/report/${props.prrKey.projectId}/${props.prrKey.reportIndex}/${props.prrKey.revisionIndex}/table-analysis`,
                    search: props.reportPage !== undefined ? `?page=${props.reportPage}` : undefined,
                }}
            />
            <StageMenuItem
                disabled={props.reportStage === StageNames.CROSS_TABLE_ANALYSIS}
                title={StageNames.CROSS_TABLE_ANALYSIS}
                status={props.reportStageStatuses?.tableRefs}
                to={{
                    pathname: `/report/${props.prrKey.projectId}/${props.prrKey.reportIndex}/${props.prrKey.revisionIndex}/cross-analysis`,
                    search: props.reportPage !== undefined ? `?page=${props.reportPage}` : undefined,
                }}
            />
            <StageMenuItem
                disabled={props.reportStage === StageNames.SUMMARY}
                title={StageNames.SUMMARY}
                status={props.reportStageStatuses?.summary}
                to={{
                    pathname: `/report/${props.prrKey.projectId}/${props.prrKey.reportIndex}/${props.prrKey.revisionIndex}/summary`,
                    search: props.reportPage !== undefined ? `?page=${props.reportPage}` : undefined,
                }}
            />
        </Menu>
    </div>
}

interface StageMenuItemProps {
    title: string
    status: string | undefined | null
    disabled?: boolean
    to?: H.LocationDescriptor<unknown>
}

const StageMenuItem = (props: StageMenuItemProps) => {
    return <MenuItem disabled={props.disabled}>
        <Link to={props.to || "/"} className={"stage-menu-item-link"}>
            <span>{ props.title }</span>
            <Spacer minWidth={20}/>
            <ReportStageStatusLabel value={props.status === null ? "UNKNOWN" : props.status || "SKIPPED"}/>
        </Link>
    </MenuItem>
}

export {StageNav}
