import React, {useReducer} from "react";
import {useTitle} from "react-use";
import {ReportMenu} from "./ReportMenu";
import {defaultRp3State, rp2Reducer} from "./data/reducer";
import {useReportPageAddress} from "../base/useReportPageAddress";
import {withBaseAndModificationRuleReducer, withReportTablesContainerReducer} from "../base/reportPageReducer";
import {ReportWorkspace} from "../base/report-workspace/ReportWorkspace";
import {ReportStagePage} from "../base/report-page/ReportStagePage";

import './ReportDataNormalization.css'
import {Rp3Body} from "./rp3_body";


const pageReducer = withReportTablesContainerReducer(
    withBaseAndModificationRuleReducer(
        rp2Reducer
    )
)

const ReportDataNormalizationPage = () => {
    const address = useReportPageAddress();

    const [state, dispatch] = useReducer(pageReducer, defaultRp3State);
    useTitle(state.reportName || "FINHOUND", {restoreOnUnmount: true})

    return <ReportStagePage>
        <ReportMenu
            prrKey={address.prrKey}
            menu={state.menu}
            dispatch={dispatch}
        />
        <ReportWorkspace>
            <Rp3Body address={address} state={state} dispatch={dispatch}/>
        </ReportWorkspace>
    </ReportStagePage>
}

export {ReportDataNormalizationPage}
