import React, {Dispatch, useState} from "react";
import {AnyAction} from "redux";

import {Stack, Tab, Tabs} from "@mui/material";

import {TabPanel} from "../../../qdep/components/app/util/tab-panel/TabPanel";
import {OcrPostProcessingSettingsTab} from "./tabs/ocr-post-processing/OcrPostProcessingSettingsTab";
import {DataNormalizationSettingsTab} from "./tabs/data-normalization/DataNormalizationSettingsTab";
import {RowRuleSettingsTab} from "./tabs/row-rules/RowRuleSettingsTab";
import {ColumnRuleSettingsTab} from "./tabs/column-rules/ColumnRuleSettingsTab";
import {DataGroupSettingsTab} from "./tabs/data-group/DataGroupSettingsTab";
import {DevSettingsTab} from "./tabs/dev/DevSettingsTab";
import {RefDetectionSettingsTab} from "./tabs/ref-detection/RefDetectionSettingsTab";
import {ReportSettingsEditorState} from "./editor_reducer";

import styles from './editor.module.css'
import {HotkeysTab} from "./tabs/hotkeys/HotkeysTab";


interface ComponentProps {
    state: ReportSettingsEditorState
    dispatch: Dispatch<AnyAction>
    readonly: boolean
}

const AnalysisSettingEditor = (props: ComponentProps) => {
    const [tabIndex, setTabIndex] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    return <Stack direction="row" height={"100%"}>
        <Tabs
            orientation="vertical"
            indicatorColor="secondary"
            textColor="secondary"
            sx={{minWidth: "150px", maxWidth: "190px", borderRight: 1, borderColor: 'divider'}}

            value={tabIndex}
            onChange={handleChange}
        >
            <Tab sx={{alignItems: "flex-start", textAlign: "left"}} label={"OCR Post-Processing"}/>
            <Tab sx={{alignItems: "flex-start", textAlign: "left"}} label={"Data Normalization"}/>
            <Tab sx={{alignItems: "flex-start", textAlign: "left"}} label={"Ref Detection"}/>
            <Tab sx={{alignItems: "flex-start", textAlign: "left"}} label={"Row Rules"}/>
            <Tab sx={{alignItems: "flex-start", textAlign: "left"}} label={"Column Rules"}/>
            <Tab sx={{alignItems: "flex-start", textAlign: "left"}} label={"Data Groups"}/>
            <Tab sx={{alignItems: "flex-start", textAlign: "left"}} label={"Dev"}/>
            <Tab sx={{alignItems: "flex-start", textAlign: "left"}} label={"Hotkeys"}/>
        </Tabs>
        <div className={styles.report_settings_item_container}>
            <TabPanel index={0} value={tabIndex}>
                <OcrPostProcessingSettingsTab
                    settings={props.state.postProcessingSettings}
                    dispatch={props.dispatch}
                    readonly={props.readonly}
                />
            </TabPanel>
            <TabPanel index={1} value={tabIndex}>
                <DataNormalizationSettingsTab
                    tableTitleNormalization={props.state.tableTitleNormalization}
                    headerDateFormatSettings={props.state.headerDateFormatSettings}
                    dispatch={props.dispatch}
                    readonly={props.readonly}
                />
            </TabPanel>
            <TabPanel index={2} value={tabIndex}>
                <RefDetectionSettingsTab
                    settings={props.state.rowRefDetectionSettings}
                    dispatch={props.dispatch}
                    readonly={props.readonly}
                />
            </TabPanel>
            <TabPanel index={3} value={tabIndex}>
                <RowRuleSettingsTab
                    settings={props.state.rowRuleSettings}
                    dispatch={props.dispatch}
                    readonly={props.readonly}
                />
            </TabPanel>
            <TabPanel index={4} value={tabIndex}>
                <ColumnRuleSettingsTab
                    settings={props.state.columnRuleSettings}
                    dispatch={props.dispatch}
                    readonly={props.readonly}
                />
            </TabPanel>
            <TabPanel index={5} value={tabIndex}>
                <DataGroupSettingsTab
                    settings={props.state.dataGroupSettings}
                    devSettings={props.state.devSettings}
                    dispatch={props.dispatch}
                    readonly={props.readonly}
                />
            </TabPanel>
            <TabPanel index={6} value={tabIndex}>
                <DevSettingsTab
                    greedyAlgorithm={props.state.dataGroupSettings.enableGreedyAlgorithm}
                    unattachedDataGroups={props.state.dataGroupSettings.enableUnattachedDataGroups}
                    devSettings={props.state.devSettings}
                    dispatch={props.dispatch}
                    readonly={props.readonly}
                />
            </TabPanel>
            <TabPanel index={7} value={tabIndex}>
                <HotkeysTab/>
            </TabPanel>
        </div>
    </Stack>;
}

export {AnalysisSettingEditor}