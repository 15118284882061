import {defaultReportBaseState, ReportBaseState} from "../../base/report-workspace-header/data/ReportBaseState";
import {WithReportTablesWorkspace} from "../../base/report-tables-container/state";
import {Rp7CoverageTable, Rp7PageCoverageMenuItem} from "../../../../../app/client/app/entity/Rp7Report";

interface Rp7CoverageState extends
    ReportBaseState,
    WithReportTablesWorkspace<Rp7CoverageTable>
{
    menu: Rp7CoverageMenuState
}

interface Rp7CoverageMenuState {
    isLoading: boolean
    selectedItem: number | undefined
    selectedPage: number | undefined
    items: Rp7PageCoverageMenuItemState[]
}

interface Rp7PageCoverageMenuItemState extends Rp7PageCoverageMenuItem {
    selected: boolean
}

const defaultRp7CoverageState: Rp7CoverageState = {
    ...defaultReportBaseState,
    menu: {
        isLoading: true,
        selectedItem: undefined,
        selectedPage: undefined,
        items: []
    },
    tableWorkspace: {
        isLoading: true,
        tables: [],
    }
}

export {
    defaultRp7CoverageState,
}
export type {
    Rp7CoverageState,
    Rp7CoverageMenuState,
    Rp7PageCoverageMenuItemState,
}