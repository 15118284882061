import React from "react";
import {connect} from "react-redux";

import {Divider, ListItemIcon, MenuItem, MenuList} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import {ControlledActionGroupButton} from "../../../../qdep/components/entity-list-workspace-block/ActionGroupButton";
import {CreateButton} from "./CreateButton";
import {ViewButton} from "./ViewButton";
import {OpenOverview} from "./OpoenOverview";
import {ConfirmDeleteDialog} from "../../../../qdep/components/app/dialog/ConfirmDeleteDialog";
import {AppState} from "../../../../app/state/state";
import {Dispatch} from "redux";
import {projectDeleteRequest} from "../../../../app/data/action/AppAction";
import {ProjectListDto} from "../../../../app/client/app/entity/Project";


interface ProjectListActionGroupProps {
    projectId: string | undefined
    projectName: string | undefined
}

interface ProjectListActionGroupActions {
    delete: (projectId: string) => void
}

const ProjectListActionGroup = (props: ProjectListActionGroupProps & ProjectListActionGroupActions) => {
    const [open, setOpen] = React.useState(false);
    const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = React.useState(false);

    return <>
        <ControlledActionGroupButton
            isOpen={open}
            open={() => setOpen(true)}
            close={() => setOpen(false)}
            menu={
                <MenuList id="split-button-menu">
                    <OpenOverview key={"overview"}/>
                    <ViewButton key={"view"}/>
                    <Divider/>
                    <CreateButton key={"create"}/>
                    <MenuItem
                        key="delete"
                        disabled={props.projectId === undefined}
                        onClick={() => {
                            setOpen(false)
                            setOpenConfirmDeleteDialog(true)
                        }}
                    >
                        <ListItemIcon>
                            <DeleteIcon/>
                        </ListItemIcon>
                        Delete Project
                    </MenuItem>
                </MenuList>
            }
        />
        <ConfirmDeleteDialog
            object={`${props.projectName} project` || ""}
            isOpen={openConfirmDeleteDialog}
            close={() => setOpenConfirmDeleteDialog(false)}
            confirm={() => {
                if (props.projectId !== undefined) {
                    props.delete(props.projectId);
                }
                setOpenConfirmDeleteDialog(false)
            }}
        />
    </>
}

const mapStateToProps = (state: AppState): ProjectListActionGroupProps => {
    if (state.projects.selectedRow !== undefined && state.projects.selectedRow >= 0) {
        const project: ProjectListDto = state.projects.data.data[state.projects.selectedRow]
        if (project !== undefined) {
            return {
                projectId: project.id,
                projectName: project.name,
            };
        }
    }
    return {
        projectId: undefined,
        projectName: undefined,
    }
}

const mapDispatchToProps = (dispatch: Dispatch): ProjectListActionGroupActions => {
    return {
        delete: (projectId: string) => dispatch(projectDeleteRequest(projectId)),
    }
}

const ConnectedProjectListActionGroup = connect(mapStateToProps, mapDispatchToProps)(ProjectListActionGroup)

export {ConnectedProjectListActionGroup as ProjectListActionGroup}