import React from "react";
import {AnyAction} from "redux";

import {PRRKey} from "../../../../app/client/app/entity/Project";
import {ReportMenuContainer} from "../base/report-menu/ReportMenuContainer";
import {BaseMenuState} from "../base/report-menu/state";
import {Rp2MenuItemState} from "./data/reducer";
import {ReportMenuGroupContainer} from "../base/report-menu/ReportMenuGroupContainer";
import {ReportMenuItemLink, ReportMenuItemList} from "../base/report-menu/ReportMenuItemList";

import styles from './PageMenu.module.css'


interface ReportOcrMenuProps {
    prrKey: PRRKey
    menu: BaseMenuState<Rp2MenuItemState>
    dispatch: React.Dispatch<AnyAction>
}

const PageMenu = (props: ReportOcrMenuProps) => {
    return <ReportMenuContainer prrKey={props.prrKey} dispatch={props.dispatch}>
        <ReportMenuGroupContainer isHighLevelGroup label={"Pages"}>
            <ReportMenuItemList>
                { props.menu.items.map((item, index) =>
                    <ReportMenuItemLink
                        key={index}
                        index={index}
                        item={item}
                    >
                        <span className={styles.menu_item_value}>Page {item.page}</span>
                        <span className={styles.menu_item_desc}>{item.count} table(s)</span>
                    </ReportMenuItemLink>
                )}
            </ReportMenuItemList>
        </ReportMenuGroupContainer>
    </ReportMenuContainer>
}

export {PageMenu}