import {AnyAction} from "redux";

import {ProjectReportListWorkspaceActionTypes as WorkspaceActions} from "./action";
import {ProjectReportListState, defaultProjectReportListState} from "./state";
import {AppActionTypes} from "../../../../app/data/action/AppAction";
import {
    selectRowReducer,
    updateDataReducer,
    updateSettingsReducer
} from "../../../../qdep/components/entity-list-workspace-block/EntityListSettingsState";


function projectReportListReducer(state: ProjectReportListState = defaultProjectReportListState, action: AnyAction): ProjectReportListState {
    switch (action.type) {
        case AppActionTypes.PROJECT_REPORT_LIST_REQUEST_SUCCEEDED:
            return updateDataReducer(state, action)
        case WorkspaceActions.UPDATE_SETTINGS:
            return updateSettingsReducer(state, action)
        case WorkspaceActions.SELECT_ROW:
            return selectRowReducer(state, action)
    }
    return state
}

export {projectReportListReducer}