import React, {useReducer, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {useAsyncFn, useMount} from "react-use";

import {Breadcrumbs, Button, IconButton, Typography} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';

import {EntityDefinitionEditorBody} from "../../entity/entity-editor/editor_body";
import {Workspace, WorkspaceHeader} from "../../../../qdep/components/app/workspace/Workspace";
import {PageMenu} from "./menu";
import {apiClient, ApiResponse} from "../../../../app/client/app/client";
import {pageReducer} from "./page_reducer";
import {defaultState} from "./page_state";
import {applyEntityDefinitionChanges, applyFeatureDefinitionChanges, setRevision} from "./page_action";
import {EditorActionsContainer} from "../../../../qdep/components/app/editor/EditorActionsContainer";
import {EntitiesModelRevision} from "../../../../app/client/app/entity/report/model/ReportModel";
import {Spacer} from "../../../../qdep/components/app/util/spacer/Spacer";
import {mapStateToEntityDefinition} from "../../entity/entity-editor/state";
import {FeatureDefinitionEditorBody} from "../../entity/feature-editor/editor_body";
import {stateToValue} from "../../entity/feature-editor/state";
import {NewRevisionDialog} from "./new-revision-dialog/NewRevisionDialog";

import styles from "./page.module.css";


interface RoutePageParams {
    modelId: string
    revisionId: string
}

const addEntityModelRevision = async (modelId: string, revision: EntitiesModelRevision): Promise<ApiResponse<EntitiesModelRevision>> => {
    return apiClient.addEntityModelRevision(modelId, revision)
}

const EntityEditorPage = () => {
    const routeParams = useParams<RoutePageParams>();

    const [state, dispatch] = useReducer(pageReducer, defaultState);
    const [addEntityModelRevisionStatus, addEntityModelRevisionFn] = useAsyncFn(addEntityModelRevision, []);
    const [openNewRevisionDialog, setOpenNewRevisionDialog] = useState(false)

    useMount(() => {
        if (routeParams.revisionId !== "new") {
            apiClient
                .getEntityModelRevision(routeParams.modelId, routeParams.revisionId)
                .then(response => {
                    if (response.errorReason === undefined && response.data !== undefined) {
                        dispatch(setRevision(response.data));
                    }
                });
        }
    })

    return <>
        <PageMenu
            modelId={routeParams.modelId}
            menuState={state.menuItems}
            dispatch={dispatch}
        />
        <Workspace>
            <div className={styles.workspace_wrapper}>
                <Breadcrumbs sx={{marginBottom: "8px", marginTop: "8px", fontSize: "14px", width: "100%"}}>
                    <Typography sx={{fontSize: "14px"}}>Report Model</Typography>
                    <Typography sx={{fontSize: "14px"}}>
                        <Link to={`/report/models/${routeParams.modelId}`}>
                            { routeParams.modelId }
                        </Link>
                    </Typography>
                    <Typography sx={{fontSize: "14px"}}>Entity Model</Typography>
                    <Typography sx={{fontSize: "14px"}}>{ routeParams.revisionId }</Typography>
                </Breadcrumbs>
                <WorkspaceHeader>
                    <span>Revision</span>
                    <Spacer/>
                    <IconButton
                        onClick={() => setOpenNewRevisionDialog(true)}
                    >
                        <SaveIcon/>
                    </IconButton>
                </WorkspaceHeader>

                { state.entityDefinitionEditor !== undefined &&
                    <>
                        <EntityDefinitionEditorBody
                            isNew={false}
                            entityVariants={state.entityVariants}
                            featureOptions={state.featureOptions}
                            state={state.entityDefinitionEditor}
                            dispatch={dispatch}
                        />
                        <EditorActionsContainer>
                            <Button
                                variant={"contained"}
                                color={"secondary"}
                                onClick={() => {
                                    if (state.entityDefinitionEditor !== undefined) {
                                        const entityDefinition = mapStateToEntityDefinition(state.entityDefinitionEditor.entity);
                                        dispatch(applyEntityDefinitionChanges(entityDefinition));
                                    }
                                }}
                            >
                                Apply
                            </Button>
                        </EditorActionsContainer>
                    </>
                }

                { state.featureDefinitionEditor !== undefined &&
                    <>
                        <FeatureDefinitionEditorBody
                            isNew={false}
                            state={state.featureDefinitionEditor}
                            dispatch={dispatch}
                        />
                        <EditorActionsContainer>
                            <Button
                                variant={"contained"}
                                color={"secondary"}
                                onClick={() => {
                                    if (state.featureDefinitionEditor !== undefined) {
                                        const feature = stateToValue(state.featureDefinitionEditor);
                                        dispatch(applyFeatureDefinitionChanges(feature));
                                    }
                                }}
                            >
                                Apply
                            </Button>
                        </EditorActionsContainer>
                    </>
                }
            </div>
        </Workspace>

        <NewRevisionDialog
            open={openNewRevisionDialog}
            loading={addEntityModelRevisionStatus.loading}
            close={() => setOpenNewRevisionDialog(false)}
            apply={info => {
                const revision: EntitiesModelRevision = {
                    revisionId: "",
                    date: "",
                    name: info.name,
                    description: info.description,
                    entityDefinitions: state.entities,
                    featureFlagDefinitions: state.features,
                    attachedReports: null,
                };
                addEntityModelRevisionFn(routeParams.modelId, revision)
                    .then(response => {
                        if (response.errorReason === undefined) {
                            setOpenNewRevisionDialog(false)
                        }
                    })
            }}
        />
    </>
}

export {EntityEditorPage}