import {ExplicitCellRef, Rp6Table} from "../../../../../app/client/app/entity/Rp6Report";
import {TableTitle} from "../../../../../app/client/app/entity/report/TableTitle";

interface TableViewState {
    prrKey: string
    entityId: string
    title: TableTitle
    page: number

    rows: RowViewState[]
}

interface RowViewState {
    cells: CellViewState[]
}

interface CellViewState {
    colSpan: number
    cellType: string
    dataType: string
    value: string
    rowRefNames: Set<string> | null

    cellRefs: ExplicitCellRefViewState | null
}

interface ExplicitCellRefViewState {
    num: number
    ref: ExplicitCellRef
}

function mapToTableViewState(table: Rp6Table): TableViewState {
    const rows: RowViewState[] = table.rows.map(row => ({
        cells: row.cells.map(cell => ({
            colSpan: cell.colSpan,
            cellType: cell.cellType,
            dataType: cell.dataType,
            value: cell.value,
            rowRefNames: null,
            cellRefs: null,
        }))
    }))

    if (table.explicitRefs !== null) {
        for (let explicitRef of table.explicitRefs) {
            let rowRefNames = rows[explicitRef.ref.rowIndex].cells[0].rowRefNames;
            if (rowRefNames === null) {
                rowRefNames = new Set<string>()
            }
            rowRefNames.add(explicitRef.ref.refName)
            rows[explicitRef.ref.rowIndex].cells[0].rowRefNames = rowRefNames

            const rowIndex = explicitRef.source.cell.row - 1;
            const columnIndex = explicitRef.source.cell.column - 1;

            rows[rowIndex].cells[columnIndex].cellRefs = {
                num: explicitRef.destTable !== null ? Object.keys(explicitRef.destTable).length: 0,
                ref: explicitRef
            }
        }
    }

    return {
        prrKey: table.prrKey,
        entityId: table.entityId,
        title: table.title,
        page: table.page,
        rows: rows,
    }
}

export type {TableViewState, RowViewState, CellViewState, ExplicitCellRefViewState}
export {mapToTableViewState}
