import {ContentItem} from "../../../app/client/app/entity/ReportContents";
import {Period} from "../../../app/client/app/entity/report/TableMetadata";

interface TocItemState {
    nodeId: string
    page: number
    pageEnd: number | null
    title: string
    totalAttachedTables: number
    subItems: TocItemState[]
    attachedTables: string[]
    period: Period | null
}

function mapTocItemsToState(items: ContentItem[] | null, parentId: string = ""): TocItemState[] {
    items = items || []

    return items
        .sort((a, b) => a.page - b.page)
        .map((item, index) => {
            const nodeId = `${parentId}/${index.toString()}`
            return {
                nodeId: nodeId,
                page: item.page,
                pageEnd: item.pageEnd,
                title: item.title.originalText,
                totalAttachedTables: item.totalAttachedTables || 0,
                subItems: mapTocItemsToState(item.subItems, nodeId),
                attachedTables: item.attachedTables || [],
                period: item.title.period
            }
        })
}

export {mapTocItemsToState}
export type {TocItemState}