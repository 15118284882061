import {AnyAction} from "redux";
import {StageStatuses} from "../../../../../../app/client/app/entity/ops/ReportStatusResult";


enum ReportBaseActions {
    REPORT_SET_NAME         = "REPORT_PAGE.BASE.SET_NAME",
    REPORT_SET_STAGE_STATUS = "REPORT_PAGE.BASE.SET_STAGE_STATUS",
}

function setReportName(name: string): AnyAction {
    return {
        type: ReportBaseActions.REPORT_SET_NAME,
        name: name,
    }
}

function setReportStageStatus(stages?: StageStatuses): AnyAction {
    return {
        type: ReportBaseActions.REPORT_SET_STAGE_STATUS,
        stages: stages,
    }
}

export {
    ReportBaseActions,
    setReportName, setReportStageStatus,
};
