
interface EntityDefinition {
    id: string | null
    disabled: boolean | null
    entityName: string
    category: EntityCategory
    builtInFeatures: FeatureSet | null

    selectors: EntitySelectorRule[]
    variants: EntityRelationshipsVariant[]
}

interface EntitySelectorRule {
    equal: string | undefined | null
    regexp: string | undefined | null
}

interface EntitySubItem {
    entityName: string
    builtInFeatures: FeatureSet | null

    required: boolean
    inlineable: boolean
    subItems: EntitySubItem[]
    requiredFeatures: Record<string, string> | null

    condition: EntityConditionSubItem | null
}

interface EntityConditionSubItem {
    conditionName: "SYSTEM_CONDITION_ANY" | "SYSTEM_RATIO_CHECK" | string
    absoluteValue: boolean
    subItems: EntitySubItem[] | null
}

interface EntityRelationshipsVariant {
    subItems: EntitySubItem[]
    requiredFeatures: Record<string, string> | null
    excludedFeatures: Feature[] | null
}

interface FeatureSet {
    [name: string]: string
}

interface Feature {
    name: string
    value: string
}

const enum EntityCategory {
    INCOME_STATEMENT = "INCOME_STATEMENT",
    BALANCE_SHEET = "BALANCE_SHEET",
    CASH_FLOW_STATEMENT = "CASH_FLOW_STATEMENT",
    ORGANISATION = "ORGANISATION",
    OTHER = "OTHER",
}

function entityCategoryToString(entity: EntityCategory): string {
    switch (entity) {
        case EntityCategory.INCOME_STATEMENT: return "Income"
        case EntityCategory.BALANCE_SHEET: return "Balance"
        case EntityCategory.CASH_FLOW_STATEMENT: return "Cash Flow"
        case EntityCategory.ORGANISATION: return "Organisation"
        case EntityCategory.OTHER: return "Other"
    }
}

export {EntityCategory, entityCategoryToString}
export type {
    EntityDefinition,
    EntitySelectorRule,
    EntityRelationshipsVariant,
    EntitySubItem, EntityConditionSubItem,
    FeatureSet, Feature,
}
