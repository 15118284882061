import React from "react";

import {Checkbox, MenuItem, Select, SelectChangeEvent, TextField} from "@mui/material";
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

import {SelectableItem} from "../../../../../qdep/data/util";
import {EntitySelectorRuleState, EntitySelectorRuleType} from "../state";

import styles from '../editor_body.module.css'


interface EntitySelectorProps {
    index: number
    selector: SelectableItem<EntitySelectorRuleState>
    onChange: (index: number, update: Partial<SelectableItem<Partial<EntitySelectorRuleState>>>) => void
}

const EntitySelector = (props: EntitySelectorProps) => {
    return <>
        <Checkbox
            checked={props.selector.isSelected}
            onChange={(_, checked) => {
                props.onChange(props.index, {isSelected: checked})
            }}
        />
        <Select
            size="small"
            value={props.selector.data.type}
            onChange={(event: SelectChangeEvent<EntitySelectorRuleType>) => {
                props.onChange(props.index, {data: {type: event.target.value as EntitySelectorRuleType}})
            }}
        >
            <MenuItem value={EntitySelectorRuleType.REGEXP}>REGEXP</MenuItem>
            <MenuItem value={EntitySelectorRuleType.EQUAL}>EQUAL</MenuItem>
        </Select>
        <TextField
            size="small"
            fullWidth
            value={props.selector.data.value}
            onChange={event => {
                props.onChange(props.index, {data: {value: event.target.value}})
            }}
        />

        { props.selector.data.warningText !== undefined &&
            <>
                <span/>
                <span/>
                <div className={styles.entity_selector_warning_container}>
                    <WarningRoundedIcon color={"warning"}/>
                    <span className={styles.entity_selector_warning_text}>
                        { props.selector.data.warningText }
                    </span>
                </div>
            </>
        }
    </>
}

export {EntitySelector}