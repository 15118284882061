import React from "react";
import {useHistory} from "react-router-dom";

import {Button} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {PRRKey} from "../../../../../app/client/app/entity/Project";

import styles from './ReportMenuContainer.module.css'
import {useHotkeys} from "react-hotkeys-hook";
import {nextPage, prevPage} from "../report-page/action";
import {AnyAction} from "redux";


interface ReportMenuContainerProps {
    prrKey: PRRKey
    children: React.ReactNode | React.ReactNode[]
    dispatch: React.Dispatch<AnyAction>
}

const ReportMenuContainer = (props: ReportMenuContainerProps) => {
    const history = useHistory();
    useHotkeys('ctrl + alt + up', () => props.dispatch(prevPage()))
    useHotkeys('ctrl + alt + down', () => props.dispatch(nextPage()))

    return <div className={styles.menu_container}>
        <div className={styles.action_container}>
            <Button
                variant={"text"}
                color={"info"}
                disableRipple
                startIcon={<ArrowBackIcon/>}
                onClick={() => history.push(`/report/${props.prrKey.projectId}/${props.prrKey.reportIndex}/${props.prrKey.revisionIndex}`)}
            >
                Back
            </Button>
        </div>

        <div className={styles.list_container}>
            { props.children }
        </div>

    </div>
}

export {ReportMenuContainer}