import React, {useReducer} from "react";
import {defaultRp5State, rp5Reducer} from "./reducer";
import {ReportMenu} from "./ReportMenu";
import {useReportPageAddress} from "../../base/useReportPageAddress";
import {withBaseAndModificationRuleReducer, withReportTablesContainerReducer} from "../../base/reportPageReducer";
import {ReportWorkspace} from "../../base/report-workspace/ReportWorkspace";
import {ReportStagePage} from "../../base/report-page/ReportStagePage";

import '../../base/Report.css'
import './ReportTableAnalysisPage.css'
import {Rp5Body} from "./rp5_body";
import {useTitle} from "react-use";


const pageReducer = withReportTablesContainerReducer(
    withBaseAndModificationRuleReducer(
        rp5Reducer
    )
)

const ReportTableAnalysisPage = () => {
    const address = useReportPageAddress();
    const [state, dispatch] = useReducer(pageReducer, defaultRp5State);
    useTitle(state.reportName || "FINHOUND", {restoreOnUnmount: true})

    return <ReportStagePage>
        <ReportMenu
            prrKey={address.prrKey}
            menu={state.menu}
            dispatch={dispatch}
        />
        <ReportWorkspace>
            <Rp5Body address={address} state={state} dispatch={dispatch}/>
        </ReportWorkspace>
    </ReportStagePage>
}

export {ReportTableAnalysisPage}
