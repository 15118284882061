import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {ReportEditorBlock} from "../component/ReportEditorBlock";
import {NewReportDto, ReportDto} from "../../../app/client/app/entity/Project";
import React, {useState} from "react";
import moment from "moment";
import {EntityModelRevisionRef} from "../../../app/client/app/entity/report/model/ReportModel";


interface AddReportDialogProps {
    projectName: string
    isOpen: boolean
    close: () => void
    apply: (report: ReportDto, model: EntityModelRevisionRef | null) => void
}

const AddReportDialog = (props: AddReportDialogProps) => {
    const [state, updateState] = useState(NewReportDto(-1));
    const [model, setModel] = useState<EntityModelRevisionRef | null>(null)

    return <Dialog open={props.isOpen}>
        <DialogTitle>New Report</DialogTitle>
        <DialogContent>
            <ReportEditorBlock
                projectName={props.projectName}
                report={state}
                updateReport={(_, updates: Partial<ReportDto>) => {
                    updateState(prev => ({...prev, ...updates}))
                }}
                updateModel={(_, model) => setModel(model)}
                addRevision={(_, file: File) =>
                    updateState(prev => ({
                        ...prev,
                        revisions: [{
                            index: 1,
                            revisionNumber: 1,
                            filename: (file as File).name,
                            file: file,
                            status: "NEW",
                            createData: moment()
                        }],
                        revisionSeq: 1,
                    }))
                }
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={() => {
                props.close()
                updateState(NewReportDto(-1))
            }}>
                Cancel
            </Button>
            <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                    props.apply(state, model)
                    props.close()
                    updateState(NewReportDto(-1))
                }}
            >
                Add
            </Button>
        </DialogActions>
    </Dialog>
}

export {AddReportDialog}
