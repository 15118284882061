import moment from "moment";

type OptionalField<T> = T | undefined | null

interface DataPiece <T> {
    isLoaded: boolean
    data: T
    fetchTime?: moment.Moment
}

function getDataPieceData(data: DataPiece<any>): any {
    return data.isLoaded ? (data.data ? data.data : []): []
}

interface IndexedTableItem<T> {
    rowIndex: number
    data: T
}

interface IndexedTableData<T> {
    seq: number
    items:  IndexedTableItem<T>[]
}

interface SelectableItem <T> {
    isSelected: boolean
    data: T
}

type IndexedAndSelectableItem<T> = IndexedTableItem<T> & SelectableItem<T>

type DeepPartial<T> = {
    [K in keyof T]?: T[K] extends object ? DeepPartial<T[K]> : T[K]
}

export {
    getDataPieceData,
}

export type {
    OptionalField,
    DataPiece,
    IndexedTableItem, IndexedTableData, IndexedAndSelectableItem,
    SelectableItem,
    DeepPartial,
}