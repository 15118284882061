import {RowRefDetectionSettings} from "./RowRefDetectionSettings";
import {HeaderDateFormatSettings, mapDateParamDtoToValue, mapDateParamValueToDto} from "./HeaderDateFormatSettings";
import {
    NegativeNumberViewType,
    SeparatorValue,
    TableTitleNormalizationSettings
} from "./TableTitleNormalizationSettings";
import {
    ColumnRuleSettings,
    ColumnRuleSettingsDto,
    mapColumnTriggerSelectorDtoToValue,
    mapColumnTriggerSelectorToDto
} from "./ColumnRuleSettings";
import {
    mapRowRuleSettingsDtoToValue,
    mapRowRuleSettingsToDto,
    RowRuleSettings,
    RowRuleSettingsDto
} from "./RowRuleSettings";
import {DataGroupSettings} from "./DataGroupSettings";
import {OcrPostProcessingSettings} from "./OcrPostProcessingSettings";
import {ModelRevisionRef} from "../model/ReportModel";
import {OptionalField} from "../../../../../../qdep/data/util";
import {DevSettings} from "./DevSettings";


interface AnalysisSettings {
    analysisSettingsRef: OptionalField<ModelRevisionRef>

    headerDateFormatSettings: HeaderDateFormatSettings
    tableTitleNormalization: TableTitleNormalizationSettings
    rowRefDetectionSettings: RowRefDetectionSettings

    rowRuleSettings: RowRuleSettings
    columnRuleSettings: ColumnRuleSettings

    dataGroupSettings: DataGroupSettings
    postProcessingSettings: OcrPostProcessingSettings

    devSettings: DevSettings
}

interface AnalysisSettingsDto {
    analysisSettingsRef: OptionalField<ModelRevisionRef>

    headerDateFormatSettings: HeaderDateFormatSettings
    rowRefDetectionSettings: RowRefDetectionSettings
    tableTitleNormalization: TableTitleNormalizationSettings

    rowRuleSettings: RowRuleSettingsDto
    columnRuleSettings: ColumnRuleSettingsDto

    dataGroupSettings: DataGroupSettings
    postProcessingSettings: OcrPostProcessingSettings

    devSettings: DevSettings
}

const DefaultReportAnalysisSettings: AnalysisSettings = {
    analysisSettingsRef: undefined,
    headerDateFormatSettings: {
        formatters: [],
        fysDate: {
            day: null,
            month: null
        },
        fyeDate: {
            day: null,
            month: null
        },
        fyeAsDefault: false,
    },
    rowRefDetectionSettings: {
        enabled: true,
        hideRefColumn: true,
        selectors: [],
        destSelectors: [],
    },
    tableTitleNormalization: {
        topLeftHeaderCellFeature: false,
        thousandsSeparator: {
            source: SeparatorValue.COMMA,
            view: SeparatorValue.COMMA,
        },
        decimalSeparator: {
            source: SeparatorValue.POINT,
            view: SeparatorValue.POINT,
        },
        negativeNumberView: NegativeNumberViewType.ACCOUNTING,
        autoCorrection: {
            bracketSequence: false,
            floatNumberDelimiter: false,
        }
    },
    rowRuleSettings: {
        simpleRule: {
            enabled: false,
            emptyRowHeaderTrigger: false,
            selectors: [],
            exclusions: [],
        },
        dateRule: {
            enabled: false,
            selectors: [],
        },
    },
    columnRuleSettings: {
        enabled: true,
        selectors: [],
    },
    dataGroupSettings: {
        enabledListPattern: false,
        enabledOffsetPattern: false,
        enabledIndentImplicitTrigger: false,
        enableGreedyAlgorithm: false,
        enableUnattachedDataGroups: false,
        enableUnnamedTableContext: false,
        enableCompoundContext: false,
    },
    postProcessingSettings: {
        enableRowHeaderOffsetDetection: false,
        autoCorrection: {
            enable: false,
            splitRows: false,
            mergeRows: false,
            deleteEmptyRows: false,
            fixTableSubHeaders: false,
        },
    },
    devSettings: {
        strictEntityDependencies: false,
        strictRootEntityDependencies: false,
        disableExcludedClause: false,
        disableRelatedToClause: false,
        entityEngineVersion: "EnglishV1",
        titleHeightErrorPercent: 15,
        enablePageHeaderAndFooterFiltering: false,
        pageHeaderSelectors: [],
    }
}

function mapAnalysisSettingsDtoToValue(settingsDto: AnalysisSettingsDto): AnalysisSettings {
    return {
        analysisSettingsRef: settingsDto.analysisSettingsRef,
        headerDateFormatSettings: {
            formatters: settingsDto.headerDateFormatSettings?.formatters || [],
            fysDate: mapDateParamDtoToValue({
                day: settingsDto.headerDateFormatSettings?.fysDate?.day || null,
                month: settingsDto.headerDateFormatSettings?.fysDate?.month || null,
            }),
            fyeDate: mapDateParamDtoToValue({
                day: settingsDto.headerDateFormatSettings?.fyeDate?.day || null,
                month: settingsDto.headerDateFormatSettings?.fyeDate?.month || null,
            }),
            fyeAsDefault: settingsDto.headerDateFormatSettings?.fyeAsDefault || false,
        },
        rowRefDetectionSettings: {
            enabled: settingsDto.rowRefDetectionSettings?.enabled || false,
            hideRefColumn: settingsDto.rowRefDetectionSettings?.hideRefColumn || false,
            selectors: settingsDto.rowRefDetectionSettings?.selectors || [],
            destSelectors: settingsDto.rowRefDetectionSettings?.destSelectors || [],
        },
        tableTitleNormalization: {
            topLeftHeaderCellFeature: settingsDto?.tableTitleNormalization?.topLeftHeaderCellFeature || false,
            thousandsSeparator: {
                source: settingsDto?.tableTitleNormalization?.thousandsSeparator?.source || SeparatorValue.COMMA,
                view: settingsDto?.tableTitleNormalization?.thousandsSeparator?.view || SeparatorValue.COMMA,
            },
            decimalSeparator: {
                source: settingsDto?.tableTitleNormalization?.decimalSeparator?.source || SeparatorValue.POINT,
                view: settingsDto?.tableTitleNormalization?.decimalSeparator?.view || SeparatorValue.POINT,
            },
            negativeNumberView: settingsDto?.tableTitleNormalization?.negativeNumberView || NegativeNumberViewType.ACCOUNTING,
            autoCorrection: {
                bracketSequence: settingsDto?.tableTitleNormalization?.autoCorrection?.bracketSequence,
                floatNumberDelimiter: settingsDto?.tableTitleNormalization?.autoCorrection?.floatNumberDelimiter,
            }
        },
        rowRuleSettings: mapRowRuleSettingsDtoToValue({
            simpleRule: {
                enabled: settingsDto?.rowRuleSettings?.simpleRule?.enabled || false,
                emptyRowHeaderTrigger: settingsDto?.rowRuleSettings?.simpleRule?.emptyRowHeaderTrigger || false,
                selectors: settingsDto?.rowRuleSettings?.simpleRule?.selectors || [],
                exclusions: settingsDto?.rowRuleSettings?.simpleRule?.exclusions || [],
            },
            dateRule: {
                enabled: settingsDto?.rowRuleSettings?.dateRule?.enabled || false,
                selectors: settingsDto?.rowRuleSettings?.dateRule?.selectors || [],
            }
        }),
        columnRuleSettings: {
            enabled: settingsDto?.columnRuleSettings?.enabled || false,
            selectors: (settingsDto?.columnRuleSettings?.selectors || []).map(mapColumnTriggerSelectorDtoToValue),
        },
        dataGroupSettings: {
            enabledListPattern: settingsDto?.dataGroupSettings?.enabledListPattern || false,
            enabledOffsetPattern: settingsDto?.dataGroupSettings?.enabledOffsetPattern || false,
            enabledIndentImplicitTrigger: settingsDto?.dataGroupSettings?.enabledIndentImplicitTrigger || false,
            enableGreedyAlgorithm: settingsDto?.dataGroupSettings?.enableGreedyAlgorithm || false,
            enableUnattachedDataGroups: settingsDto?.dataGroupSettings?.enableUnattachedDataGroups || false,
            enableUnnamedTableContext: settingsDto?.dataGroupSettings?.enableUnnamedTableContext || false,
            enableCompoundContext: settingsDto?.dataGroupSettings?.enableCompoundContext || false,
        },
        postProcessingSettings: {
            enableRowHeaderOffsetDetection: settingsDto?.postProcessingSettings?.enableRowHeaderOffsetDetection || false,
            autoCorrection: {
                enable: settingsDto?.postProcessingSettings?.autoCorrection.enable || false,
                splitRows: settingsDto?.postProcessingSettings?.autoCorrection.splitRows || false,
                mergeRows: settingsDto?.postProcessingSettings?.autoCorrection.mergeRows || false,
                deleteEmptyRows: settingsDto?.postProcessingSettings?.autoCorrection.deleteEmptyRows || false,
                fixTableSubHeaders: settingsDto?.postProcessingSettings?.autoCorrection.fixTableSubHeaders || false,
            },
        },
        devSettings: {
            strictEntityDependencies: settingsDto?.devSettings?.strictEntityDependencies || false,
            strictRootEntityDependencies: settingsDto?.devSettings?.strictRootEntityDependencies || false,
            disableExcludedClause: settingsDto?.devSettings?.disableExcludedClause || false,
            disableRelatedToClause: settingsDto?.devSettings?.disableRelatedToClause || false,
            entityEngineVersion: settingsDto?.devSettings?.entityEngineVersion || "EnglishV1",
            titleHeightErrorPercent: settingsDto?.devSettings?.titleHeightErrorPercent || 15,
            enablePageHeaderAndFooterFiltering: settingsDto?.devSettings?.enablePageHeaderAndFooterFiltering || false,
            pageHeaderSelectors: settingsDto?.devSettings?.pageHeaderSelectors || [],
        }
    }
}

function mapAnalysisSettingsValueToDto(settings: AnalysisSettings): AnalysisSettingsDto {
    return {
        analysisSettingsRef: undefined, // ignore on update
        headerDateFormatSettings: {
            formatters: settings.headerDateFormatSettings.formatters,
            fysDate: mapDateParamValueToDto(settings.headerDateFormatSettings.fysDate),
            fyeDate: mapDateParamValueToDto(settings.headerDateFormatSettings.fyeDate),
            fyeAsDefault: settings.headerDateFormatSettings.fyeAsDefault,
        },
        rowRefDetectionSettings: {
            enabled: settings.rowRefDetectionSettings.enabled,
            hideRefColumn: settings.rowRefDetectionSettings.hideRefColumn,
            selectors: settings.rowRefDetectionSettings.selectors,
            destSelectors: settings.rowRefDetectionSettings.destSelectors,
        },
        tableTitleNormalization: {
            topLeftHeaderCellFeature: settings.tableTitleNormalization.topLeftHeaderCellFeature,
            thousandsSeparator: settings.tableTitleNormalization.thousandsSeparator,
            decimalSeparator: settings.tableTitleNormalization.decimalSeparator,
            negativeNumberView: settings.tableTitleNormalization.negativeNumberView,
            autoCorrection: {
                bracketSequence: settings.tableTitleNormalization.autoCorrection.bracketSequence,
                floatNumberDelimiter: settings.tableTitleNormalization.autoCorrection.floatNumberDelimiter,
            }
        },
        rowRuleSettings: mapRowRuleSettingsToDto(settings.rowRuleSettings),
        columnRuleSettings: {
            enabled: settings.columnRuleSettings.enabled,
            selectors: settings.columnRuleSettings.selectors.map(mapColumnTriggerSelectorToDto),
        },
        dataGroupSettings: {
            enabledListPattern: settings.dataGroupSettings.enabledListPattern,
            enabledOffsetPattern: settings.dataGroupSettings.enabledOffsetPattern,
            enabledIndentImplicitTrigger: settings.dataGroupSettings.enabledIndentImplicitTrigger,
            enableGreedyAlgorithm: settings.dataGroupSettings.enableGreedyAlgorithm,
            enableUnattachedDataGroups: settings.dataGroupSettings.enableUnattachedDataGroups,
            enableUnnamedTableContext: settings.dataGroupSettings.enableUnnamedTableContext,
            enableCompoundContext: settings.dataGroupSettings.enableCompoundContext,
        },
        postProcessingSettings: {
            enableRowHeaderOffsetDetection: settings.postProcessingSettings.enableRowHeaderOffsetDetection,
            autoCorrection: {
                enable: settings.postProcessingSettings.autoCorrection.enable,
                splitRows: settings.postProcessingSettings.autoCorrection.splitRows,
                mergeRows: settings.postProcessingSettings.autoCorrection.mergeRows,
                deleteEmptyRows: settings.postProcessingSettings.autoCorrection.deleteEmptyRows,
                fixTableSubHeaders: settings.postProcessingSettings.autoCorrection.fixTableSubHeaders,
            }
        },
        devSettings: {
            strictEntityDependencies: settings.devSettings.strictEntityDependencies,
            strictRootEntityDependencies: settings.devSettings.strictRootEntityDependencies,
            disableExcludedClause: settings.devSettings.disableExcludedClause,
            disableRelatedToClause: settings.devSettings.disableRelatedToClause,
            entityEngineVersion: settings.devSettings.entityEngineVersion,
            titleHeightErrorPercent: settings.devSettings.titleHeightErrorPercent,
            enablePageHeaderAndFooterFiltering: settings.devSettings.enablePageHeaderAndFooterFiltering,
            pageHeaderSelectors: settings.devSettings.pageHeaderSelectors,
        }
    }
}

export type {
    AnalysisSettings,
    AnalysisSettingsDto,
}
export {
    DefaultReportAnalysisSettings,
    mapAnalysisSettingsDtoToValue, mapAnalysisSettingsValueToDto,
}
