import React, {Dispatch} from "react";
import {AnyAction} from "redux";

import {Button, ButtonGroup, Menu, MenuItem} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import {addConditionNode, addNode, addSiblingNode} from "../action";


interface ComponentProps {
    disableOptions: boolean
    dispatch: Dispatch<AnyAction>
}

const AddEntitySubItemButtonGroup = (props: ComponentProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const closeMenu = () => setAnchorEl(null)

    return <>
        <ButtonGroup variant="outlined">
            <Button
                startIcon={<AddIcon/>}
                onClick={() => props.dispatch(addNode())}
            >
                Add Child Node
            </Button>
            <Button
                size="small"
                sx={{padding: "0"}}
                onClick={event => setAnchorEl(event.currentTarget)}
            >
                <ArrowDropDownIcon/>
            </Button>
        </ButtonGroup>

        <Menu
            open={anchorEl !== null}
            anchorEl={anchorEl}
            onClose={closeMenu}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem
                onClick={() => {
                    props.dispatch(addNode())
                    closeMenu()
                }}
            >
                Add Child Node
            </MenuItem>
            <MenuItem
                onClick={() => {
                    props.dispatch(addSiblingNode())
                    closeMenu()
                }}
                disabled={props.disableOptions}
            >
                Add Sibling Node
            </MenuItem>
            <MenuItem
                onClick={() => {
                    props.dispatch(addConditionNode())
                    closeMenu()
                }}
                disabled={props.disableOptions}
            >
                Add Condition Node
            </MenuItem>
        </Menu>
    </>
}

export {AddEntitySubItemButtonGroup}
