import React, {useState} from "react";

import {IconButton} from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import {
    WorkspaceBlock,
    WorkspaceBlockBody,
    WorkspaceBlockHeader
} from "../../../../qdep/components/app/workspace/Workspace";
import {Spacer} from "../../../../qdep/components/app/util/spacer/Spacer";
import {TocView} from "../../toc/TocView";
import {PRRKey} from "../../../../app/client/app/entity/Project";

import styles from './TocWorkspaceBlock.module.css'


interface ComponentProps {
    prrKey: PRRKey
}

const TocWorkspaceBlock = (props: ComponentProps) => {
    const [hide, setHide] = useState<boolean>(true);

    return <WorkspaceBlock>
        <WorkspaceBlockHeader classes={styles.title_container}>
            <span>Table of contents</span>
            <Spacer/>
            <IconButton
                onClick={() => setHide(prev => !prev)}
            >
                <VisibilityOffIcon color={hide ? "secondary" : undefined}/>
            </IconButton>
        </WorkspaceBlockHeader>
        <WorkspaceBlockBody>
            <TocView prrKey={props.prrKey} hideItemWithoutTable={hide}/>
        </WorkspaceBlockBody>
    </WorkspaceBlock>
}

export {TocWorkspaceBlock}
