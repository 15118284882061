import React, {useCallback, useMemo} from "react";
import {AnyAction} from "redux";
import {useLocalStorage, useMount} from "react-use";

import {Drawer, Slide} from "@mui/material";

import {openTableContextMenu, setMenuItems, setTable} from "./action";
import {Rp5State} from "./reducer";
import {ReportWorkspaceHeader} from "../../base/report-workspace-header/ReportWorkspaceHeader";
import {StageNames} from "../../base/StageNames";
import {setReportName, setReportStageStatus} from "../../base/report-workspace-header/data/ReportBaseActions";
import {openCustomRulePane} from "../../base/report-custom-rule-pane/data/action";
import {ReportTablesContainer} from "../../base/report-tables-container/ReportTablesContainer";
import {ReportTable} from "../report-table/table";
import {TaxTemplateEditor} from "../tax-template-editor/TaxTemplateEditor";
import {TableContextMenu} from "../table-context-menu/TableContextMenu";
import {ManualModificationRulePane} from "../../base/report-custom-rule-pane/ManualModificationRulePane";
import {Stage} from "../../../../../app/client/app/entity/report/Stage";
import {ReportPageAddress} from "../../base/useReportPageAddress";
import {startLoadingTables} from "../../base/report-tables-container/state";
import {apiClient, ApiResponse, ErrorReason} from "../../../../../app/client/app/client";
import {Rp5MenuItem, Rp5Table} from "../../../../../app/client/app/entity/Rp5Report";
import {usePageNavigation} from "../../base/report-page/navigation/page_navigation";
import {ReportRevisionDto} from "../../../../../app/client/app/entity/Report";
import {ReportFeatures} from "../../base/report-workspace-header/data/ReportFeatures";
import {selectMenuItem} from "../../base/report-page/action";
import {useAuthErrorBoundaryContext} from "../../../../../qdep/components/app/error/UnauthorizedErrorBoundary";

import styles from "./ReportTableAnalysisPage.module.css";


interface Props {
    address: ReportPageAddress
    state: Rp5State
    dispatch: React.Dispatch<AnyAction>
}

const defaultRp5Features: ReportFeatures = {
    rowDataGroup: true,
    rowDataGroupEntityNames: false,
    columnDataGroup: false,
    references: false,
    rowNumbers: false,
    columnNumbers: false,
    showGutterIcons: true,
    showCellValueTags: true,
}

const Rp5Body = (props: Props) => {
    const state = props.state
    const dispatch = props.dispatch
    const address = props.address

    const { submitError } = useAuthErrorBoundaryContext()
    const [rpFeatures, setRpFeatures] = useLocalStorage("rp5_table_analysis/features/v1", defaultRp5Features);
    const loadPageTablesCallback = useCallback((page: number) => {
        dispatch(startLoadingTables())
        setTimeout(() => {
            apiClient
                .getReportTableAnalysisPageTables(address.prrKey, page)
                .then((response: ApiResponse<Rp5Table[]>) => {
                    if (!response.errorReason && response.data !== undefined) {
                        dispatch(setTable(response.data))
                    } else {
                        const error = response.errorReason || ErrorReason.UNKNOWN_ERROR;
                        submitError(error)
                    }
                });
        }, 200)
        return
    }, [dispatch, address.prrKey, submitError]);
    const selectMenuItemCallback= useCallback(
        (itemIndex: number) => dispatch(selectMenuItem(itemIndex)),
        [dispatch]
    );
    usePageNavigation(selectMenuItemCallback, loadPageTablesCallback, state.menu.selectedPage)

    useMount(() => {
        apiClient
            .getReportRevision(address.prrKey)
            .then((response: ApiResponse<ReportRevisionDto>) => {
                if (response.data !== undefined) {
                    dispatch(setReportName(response.data.reportName))
                    dispatch(setReportStageStatus({
                        ocr: response.data.analysis.stages.ocr.status,
                        normalization: response.data.analysis.stages.normalization.status,
                        entityDetection: response.data.analysis.stages.entityDetection.status,
                        tableAnalysis: response.data.analysis.stages.tableAnalysis.status,
                        tableRefs: response.data.analysis.stages.tableRefs.status,
                        summary: response.data.analysis.stages.summary.status,
                    }))
                }
            })

        apiClient
            .getReportTableAnalysisMenuItems(address.prrKey)
            .then((response: ApiResponse<Rp5MenuItem[]>) => {
                const menu = response.data || [];
                let reportPage = address.query.page
                if (reportPage === undefined && menu.length > 0) {
                    reportPage = menu[0].page
                }

                dispatch(setMenuItems(menu, reportPage))
            })
    })

    const reportTablesContainerClass = useMemo(() => {
        if (state.drawer.open) {
            return [styles.tables_wrapper, styles.open_drawer].join(" ");
        }
        return styles.tables_wrapper
    }, [state.drawer.open])

    return <>
        <ReportWorkspaceHeader
            prrKey={address.prrKey}
            reportName={state.reportName}
            reportStage={StageNames.TABLE_ANALYSIS}
            reportPage={state.menu.selectedPage}
            reportStageStatuses={state.reportStageStatuses}
            updateReportStatuses={stages => dispatch(setReportStageStatus(stages))}

            numCustomRuleUpdates={state.manualModificationRules.customRuleUpdates.length}
            openCustomRuleBlock={() => dispatch(openCustomRulePane())}

            reportFeatures={rpFeatures}
            updateReportFeatures={setRpFeatures}
        />

        <ReportTablesContainer isLoading={state.tableWorkspace.isLoading}>
            <div className={reportTablesContainerClass}>
                {
                    state.tableWorkspace.tables.map((table, index) =>
                        <ReportTable
                            table={table}
                            key={`rt-${index}`}
                            controlPaneShowed={!!state.controlPane && state.controlPane.show && table.entityId === state.controlPane.tableId}
                            reportFeatures={rpFeatures || defaultRp5Features}
                            dispatch={dispatch}
                            openContextMenu={context => dispatch(openTableContextMenu(context))}
                        />
                    )
                }
            </div>
        </ReportTablesContainer>

        <Drawer
            open={state.drawer.open}
            anchor="right"
            variant="persistent"
        >
            <TaxTemplateEditor
                rowIndex={state.contextMenu.position.rowIndex}
                tableId={state.contextMenu.tableId}
                dispatch={dispatch}
            />
        </Drawer>

        <TableContextMenu contextMenu={state.contextMenu} dispatch={dispatch}/>

        <Slide
            direction={"up"}
            in={state.manualModificationRules.show}
            unmountOnExit
        >
            <div className={"report-bottom-sticky-container"}>
                <ManualModificationRulePane
                    prrKey={address.prrKey}
                    stage={Stage.TABLE_ANALYSIS_STAGE}

                    tableId={state.manualModificationRules.selectedTableId}
                    tableCustomRuleResult={state.manualModificationRules.selectedTableCustomRuleResults}
                    ruleUpdates={state.manualModificationRules.selectedTableRuleUpdates}

                    dispatch={dispatch}
                />
            </div>
        </Slide>
    </>
}

export {Rp5Body}