import React, {Dispatch} from "react";
import {AnyAction} from "redux";

import {
    OcrPostProcessingSettings
} from "../../../../../app/client/app/entity/report/settings/OcrPostProcessingSettings";
import {updateOcrPostProcessingAutoCorrectionSettings, updateOcrPostProcessingSettings} from "../../editor_reducer";
import {FlagControl} from "../../../../../qdep/components/app/editor/FlagControl";

import tabBodyClasses from "../TabBody.module.css";


interface OcrPostProcessingSettingsTabProps {
    settings: OcrPostProcessingSettings
    dispatch: Dispatch<AnyAction>
    readonly: boolean
}

const OcrPostProcessingSettingsTab = (props: OcrPostProcessingSettingsTabProps) => {
    return <div className={tabBodyClasses.settings_group_container}>
        <div>
            <FlagControl
                label={"Row Header Offset"}
                value={props.settings.enableRowHeaderOffsetDetection}
                readonly={props.readonly}
                onUpdate={value => props.dispatch(updateOcrPostProcessingSettings({enableRowHeaderOffsetDetection: value}))}
            />
        </div>

        <div>
            <div className={tabBodyClasses.group_title}>Auto Correction</div>
            <FlagControl
                label={"Enabled"}
                value={props.settings.autoCorrection.enable}
                readonly={props.readonly}
                onUpdate={value => props.dispatch(updateOcrPostProcessingAutoCorrectionSettings({enable: value}))}
            />
            <FlagControl
                label={"Merge Rows"}
                value={props.settings.autoCorrection.mergeRows}
                readonly={props.readonly}
                onUpdate={value => props.dispatch(updateOcrPostProcessingAutoCorrectionSettings({mergeRows: value}))}
            />
            <FlagControl
                label={"Split Rows"}
                value={props.settings.autoCorrection.splitRows}
                readonly={props.readonly}
                onUpdate={value => props.dispatch(updateOcrPostProcessingAutoCorrectionSettings({splitRows: value}))}
            />
            <FlagControl
                label={"Delete Empty Rows"}
                value={props.settings.autoCorrection.deleteEmptyRows}
                readonly={props.readonly}
                onUpdate={value => props.dispatch(updateOcrPostProcessingAutoCorrectionSettings({deleteEmptyRows: value}))}
            />
            <FlagControl
                label={"Fix Table Sub Headers"}
                value={props.settings.autoCorrection.fixTableSubHeaders}
                readonly={props.readonly}
                onUpdate={value => props.dispatch(updateOcrPostProcessingAutoCorrectionSettings({fixTableSubHeaders: value}))}
            />
        </div>
    </div>
}

export {OcrPostProcessingSettingsTab}