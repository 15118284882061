import {AnyAction} from "redux";

import {ActionTypes, ConditionNodeUpdate, VariantNodeUpdate} from "./action";
import {
    defaultEntityDefinitionEditorState,
    EntityDefinitionEditorState,
    EntityDefinitionState,
    EntityRelationshipsVariantState,
    EntitySelectorRuleType,
    EntityDependencyNode,
    mapEntityDefinitionToState,
    SelectorWarnings,
    NodeEditorState,
    VariantEditorState
} from "./state";
import {
    EntityDefinition,
    EntitySubItem,
    Feature,
} from "../../../../app/client/app/entity/report/settings/EntityDefinition";
import {WritableDraft} from "immer/dist/types/types-external";
import produce from "immer";
import _ from "lodash";
import {EntityOption} from "./sub-items-block/EntitySubItemEditor";


function entityDefinitionEditorReducer(state: EntityDefinitionEditorState = defaultEntityDefinitionEditorState, action: AnyAction): EntityDefinitionEditorState {
    return produce(state, draft => {
        switch (action.type) {
            case ActionTypes.SET_ENTITY:
                return setEntityReducer(draft, action)
            case ActionTypes.UPDATE_ENTITY:
                return updateEntityReducer(draft, action)
            // selectors
            case ActionTypes.ADD_ENTITY_SELECTOR:
                return addEntitySelectorReducer(draft, action)
            case ActionTypes.UPDATE_ENTITY_SELECTOR:
                return updateEntitySelectorReducer(draft, action)
            case ActionTypes.DELETE_ENTITY_SELECTOR:
                return deleteEntitySelectorReducer(draft, action)
            // sub items
            case ActionTypes.ENTITY_TREE_TOGGLE_NODE:
                toggleNodeReducer(draft, action)
                break
            case ActionTypes.ENTITY_TREE_SELECT_NODE:
                selectNodeReducer(draft, action)
                break
            case ActionTypes.ENTITY_TREE_ADD_NODE:
                addNodeReducer(draft, action)
                break
            case ActionTypes.ENTITY_TREE_ADD_SIBLING_NODE:
                addSiblingNodeReducer(draft, action)
                break
            case ActionTypes.ENTITY_TREE_ADD_CONDITION_NODE:
                addConditionNodeReducer(draft, action)
                break
            case ActionTypes.ENTITY_TREE_DELETE_NODE:
                deleteNodeReducer(draft, action)
                break
            case ActionTypes.ENTITY_TREE_UPDATE_SUB_ITEM:
                updateSubItemReducer(draft, action)
                break
            case ActionTypes.ENTITY_TREE_UPDATE_VARIANT:
                updateVariantReducer(draft, action)
                break
            case ActionTypes.ENTITY_TREE_UPDATE_CONDITION:
                updateConditionNodeReducer(draft, action)
                break
        }
        return draft
    })
}

function setEntityReducer(state: WritableDraft<EntityDefinitionEditorState>, action: AnyAction) {
    const entity: EntityDefinition = action.entity;
    const entityIndex: Map<string, EntityDefinition> | null = action.entityIndex;
    const selectorWarnings: SelectorWarnings[] = action.selectorWarnings || [];
    if (entity === undefined) {
        return state
    }

    const relativeEntities = new Map<string, EntityDefinitionState>()
    if (entityIndex !== null) {
        for (let [entityKey, entity] of entityIndex) {
            relativeEntities.set(entityKey, mapEntityDefinitionToState(entity));
        }
    }

    const entityState = mapEntityDefinitionToState(entity);
    findAndUpdateVariantInlineItems(entityState, relativeEntities)

    state.entity = entityState
    state.relativeEntities = relativeEntities

    state.entityTree.editorState = undefined;
    state.entityTree.subItemTree.expandedNodes = treeNodeIds(state.entity)

    for (let selectorWarning of selectorWarnings) {
        state.entity.selectors[selectorWarning.selectorIndex].data.warningText = selectorWarning.text
    }
}

function updateEntityReducer(state: WritableDraft<EntityDefinitionEditorState>, action: AnyAction) {
    _.mergeWith(state.entity, action.updates, (obj, src, key) => {
        if (key === "builtInFeatures") {
            if (_.isArray(src)) {
                const features = new Map<string, string>();
                (src as Feature[]).forEach((option: Feature) => features.set(option.name, option.value))
                return [...features.entries()].map(entry => ({
                    name: entry[0],
                    value: entry[1],
                }))
            }
            return [];
        }
    })
}

// selectors
function addEntitySelectorReducer(state: WritableDraft<EntityDefinitionEditorState>, _: AnyAction) {
    state.entity.selectors.push({
        isSelected: false,
        data: {
            type: EntitySelectorRuleType.EQUAL,
            value: "",
        },
    })
}

function updateEntitySelectorReducer(state: WritableDraft<EntityDefinitionEditorState>, action: AnyAction) {
    if (action.selector.isSelected !== undefined) {
        state.entity.selectors[action.index].isSelected = action.selector.isSelected
    }
    if (action.selector.data !== undefined) {
        state.entity.selectors[action.index].data = _.merge(state.entity.selectors[action.index].data, action.selector.data)
    }
}

function deleteEntitySelectorReducer(state: WritableDraft<EntityDefinitionEditorState>, _: AnyAction) {
    state.entity.selectors = state.entity.selectors.filter(item => !item.isSelected)
}

// sub items
function toggleNodeReducer(state: WritableDraft<EntityDefinitionEditorState>, action: AnyAction) {
    const nodeId: string | undefined = action.nodeId;
    if (nodeId) {
        const itemIndex = state.entityTree.subItemTree.expandedNodes.findIndex(item => item === nodeId);
        if (itemIndex === -1) {
            state.entityTree.subItemTree.expandedNodes.push(nodeId)
        } else {
            state.entityTree.subItemTree.expandedNodes.splice(itemIndex, 1)
        }
    }
}

function selectNodeReducer(state: WritableDraft<EntityDefinitionEditorState>, action: AnyAction) {
    const selectedNodeId: string | undefined = action.nodeId;
    const selectedNodePath = unmarshalNodePath(selectedNodeId);

    let selectedItem: VariantEditorState | NodeEditorState | undefined = undefined
    const pathLength = selectedNodePath.length;
    if (pathLength > 0) {
        const variantIndex = selectedNodePath[0];
        const entityPath: string[] = ["Variant " + variantIndex];
        const variant = state.entity.variants[variantIndex]

        if (pathLength > 1) {
            let currentItem: EntityDependencyNode = variant.subItems[selectedNodePath[1]]
            entityPath.push(currentItem.entity?.entityName || "")

            for (let i = 2; i < pathLength; i++) {
                const itemIndex = selectedNodePath[i]
                currentItem = currentItem.subItems[itemIndex]

                let itemName = currentItem.entity?.entityName || "";
                if (itemName === "" && currentItem.conditionName !== null) {
                    itemName = currentItem.conditionName
                }
                entityPath.push(itemName);
            }

            if (currentItem.inlined) {
                return
            }

            if (currentItem.type === "CONDITION") {
                selectedItem = {
                    type: "Condition",
                    entityPath: entityPath,
                    entity: currentItem,
                };
            } else {
                selectedItem = {
                    type: "SubItem",
                    entityPath: entityPath,
                    entity: currentItem,
                };
            }
        } else {
            selectedItem = {
                type: "Variant",
                name: "Variant " + variantIndex,
                requiredFeatures: variant.requiredFeatures,
                excludedFeatures: variant.excludedFeatures,
            }
        }
    }

    state.entityTree.subItemTree.selectedNodePath = selectedNodePath
    state.entityTree.editorState = selectedItem
}

function addNodeReducer(state: WritableDraft<EntityDefinitionEditorState>, _: AnyAction) {
    const selectedNodePath = state.entityTree.subItemTree.selectedNodePath;
    const pathLength = selectedNodePath.length;

    if (pathLength === 0) {
        state.entity.variants.push({
            nodeId: state.entity.variants.length.toString(10),
            isNew: true,
            subItems: [],
            requiredFeatures: [],
            excludedFeatures: [],
        })
    } else {
        const variantIndex = selectedNodePath[0];
        let currentItem: EntityDependencyNode | EntityRelationshipsVariantState = state.entity.variants[variantIndex];

        if (pathLength > 1) {
            for (let i = 1; i < pathLength; i++) {
                const itemIndex = selectedNodePath[i]
                currentItem = currentItem.subItems[itemIndex]
            }
        }

        const newNodePath = [...selectedNodePath, currentItem.subItems.length]
        currentItem.subItems.push({
            type: "ENTITY",
            nodeId: marshalNodePath(newNodePath),
            isNew: true,
            entity: null,
            required: false,
            inlineable: false,
            inlined: false,
            subItems: [],
            requiredFeatures: [],
            conditionName: null,
            absoluteValue: false,
        })
        state.entityTree.subItemTree.expandedNodes.push(selectedNodePath.join("/"))
    }
}

function addSiblingNodeReducer(state: WritableDraft<EntityDefinitionEditorState>, _: AnyAction) {
    if (state.entityTree.subItemTree.selectedNodePath.length < 2) {
        return
    }

    const selectedNodePath = state.entityTree.subItemTree.selectedNodePath.slice(0, -1);
    const pathLength = selectedNodePath.length;

    const variantIndex = selectedNodePath[0];
    const variant = state.entity.variants[variantIndex];

    let newNodePath: number[]
    let currentItem: EntityDependencyNode | EntityRelationshipsVariantState = state.entity.variants[variantIndex];

    if (pathLength === 1) {
        newNodePath = [...selectedNodePath, variant.subItems.length]
    } else {
        for (let i = 1; i < pathLength; i++) {
            const itemIndex = selectedNodePath[i]
            currentItem = currentItem.subItems[itemIndex]
        }

        newNodePath = [...selectedNodePath, currentItem.subItems.length];
    }

    currentItem.subItems.push({
        type: "ENTITY",
        nodeId: marshalNodePath(newNodePath),
        isNew: true,
        entity: null,
        required: false,
        inlineable: false,
        inlined: false,
        subItems: [],
        requiredFeatures: [],
        conditionName: null,
        absoluteValue: false,
    })
    state.entityTree.subItemTree.expandedNodes.push(selectedNodePath.join("/"));
}

function addConditionNodeReducer(state: WritableDraft<EntityDefinitionEditorState>, _: AnyAction) {
    const selectedNodePath = state.entityTree.subItemTree.selectedNodePath;
    const pathLength = selectedNodePath.length;

    if (pathLength >= 1) {
        const variantIndex = selectedNodePath[0];
        let currentItem: EntityDependencyNode | EntityRelationshipsVariantState = state.entity.variants[variantIndex];
        for (let i = 1; i < pathLength; i++) {
            const itemIndex = selectedNodePath[i]
            currentItem = currentItem.subItems[itemIndex]
        }

        const newNodePath = [...selectedNodePath, currentItem.subItems.length]
        currentItem.subItems.push({
            type: "CONDITION",
            nodeId: marshalNodePath(newNodePath),
            isNew: true,
            entity: null,
            required: false,
            inlineable: false,
            inlined: false,
            subItems: [],
            requiredFeatures: [],
            conditionName: "SYSTEM_CONDITION_ANY",
            absoluteValue: false,
        })
        state.entityTree.subItemTree.expandedNodes.push(selectedNodePath.join("/"))
    }
}

function deleteNodeReducer(state: WritableDraft<EntityDefinitionEditorState>, _: AnyAction) {
    const selectedNodePath = state.entityTree.subItemTree.selectedNodePath;
    const pathLength = selectedNodePath.length;

    if (pathLength === 1) {
        state.entity.variants.splice(selectedNodePath[0], 1)
    } else if (pathLength > 1) {
        const variantIndex = selectedNodePath[0];
        let subItems = state.entity.variants[variantIndex].subItems;

        for (let i = 1; i < pathLength - 1; i++) {
            const itemIndex = selectedNodePath[i]
            subItems = subItems[itemIndex].subItems
        }
        subItems.splice(selectedNodePath[pathLength-1], 1)
    }
}

function updateSubItemReducer(state: WritableDraft<EntityDefinitionEditorState>, action: AnyAction) {
    const updates: Partial<EntityDependencyNode>| undefined = action.updates;
    if (updates === undefined) {
        return
    }

    const selectedNodePath = state.entityTree.subItemTree.selectedNodePath;
    const pathLength = selectedNodePath.length;

    if (pathLength > 1) {
        const variantIndex = selectedNodePath[0];
        let subItems = state.entity.variants[variantIndex].subItems;

        for (let i = 1; i < pathLength-1; i++) {
            const itemIndex = selectedNodePath[i]
            subItems = subItems[itemIndex].subItems
        }

        const subItem = subItems[selectedNodePath[pathLength-1]];

        if (updates.entity !== undefined) {
            subItem.entity = updates.entity
        }
        if (!!updates.requiredFeatures) {
            subItem.requiredFeatures = updates.requiredFeatures
        }
        if (updates.required !== undefined) {
            subItem.required = updates.required
        }
        if (updates.inlineable !== undefined) {
            subItem.inlineable = updates.inlineable

            if (subItem.inlineable && subItem.entity !== null && state.relativeEntities !== null) {
                const entityKey = getEntityKey(subItem.entity);
                const subItemDefinition = state.relativeEntities.get(entityKey);
                if (subItemDefinition !== undefined && subItemDefinition.variants.length === 1) {
                    for (let inlinedItem of subItemDefinition.variants[0].subItems) {
                        const newNodePath = [...selectedNodePath, subItem.subItems.length]
                        subItem.subItems.push(inlineSubItem(inlinedItem, newNodePath))
                    }
                }
            } else {
                subItem.subItems = []
            }
        }

        if (state.entityTree.editorState !== undefined) {
            if (state.entityTree.editorState.type === "SubItem") {
                state.entityTree.editorState.entity = subItem
            }
        }

    }
}

function updateVariantReducer(state: WritableDraft<EntityDefinitionEditorState>, action: AnyAction) {
    const updates: VariantNodeUpdate | undefined = action.updates;
    if (updates === undefined) {
        return
    }

    const selectedNodePath = state.entityTree.subItemTree.selectedNodePath;
    const pathLength = selectedNodePath.length;

    if (pathLength === 1) {
        const variantIndex = selectedNodePath[0];
        const variant = state.entity.variants[variantIndex];

        if (!!updates.requiredFeatures) {
            variant.requiredFeatures = updates.requiredFeatures
        }

        if (!!updates.excludedFeatures) {
            variant.excludedFeatures = updates.excludedFeatures
        }

        if (state.entityTree.editorState !== undefined) {
            if (state.entityTree.editorState.type === "Variant") {
                state.entityTree.editorState.requiredFeatures = variant.requiredFeatures
                state.entityTree.editorState.excludedFeatures = variant.excludedFeatures
            }
        }

    }
}

function updateConditionNodeReducer(state: WritableDraft<EntityDefinitionEditorState>, action: AnyAction) {
    const updates: ConditionNodeUpdate | undefined = action.updates;
    if (updates === undefined) {
        return
    }

    const selectedNodePath = state.entityTree.subItemTree.selectedNodePath;
    const pathLength = selectedNodePath.length;

    if (pathLength > 1) {
        const variantIndex = selectedNodePath[0];
        let subItems = state.entity.variants[variantIndex].subItems;

        for (let i = 1; i < pathLength-1; i++) {
            const itemIndex = selectedNodePath[i]
            subItems = subItems[itemIndex].subItems
        }

        const subItem = subItems[selectedNodePath[pathLength-1]];

        if (!!updates.conditionName) {
            subItem.conditionName = updates.conditionName
        }
        if (updates.absoluteValue !== undefined) {
            subItem.absoluteValue = updates.absoluteValue
        }
        if (state.entityTree.editorState !== undefined && state.entityTree.editorState.type === "Condition") {
            state.entityTree.editorState.entity = subItem
        }
    }
}

function unmarshalNodePath(selectedNodeId: string | undefined): number[] {
    const path: number[] = []

    if (selectedNodeId !== undefined && selectedNodeId !== "root") {
        const rawPathItems = selectedNodeId.split("/");
        for (let item of rawPathItems) {
            const index = parseInt(item, 10)
            if (!isNaN(index)) {
                path.push(index)
            }
        }
    }

    return path
}

function marshalNodePath(path: number[]): string {
    return path.reduce<string>((acc, value) => `${acc}/${value}`, "").substring(1)
}

function treeNodeIds(entity: EntityDefinitionState): string[] {
    const ids: string[] = ["root"]
    entity.variants.forEach(variant => ids.push(...variantNodeIds(variant)))
    return ids
}

function variantNodeIds(variant: EntityRelationshipsVariantState): string[] {
    const ids: string[] = [variant.nodeId]
    variant.subItems.forEach(item => ids.push(...itemNodeIds(item)))
    return ids
}

function itemNodeIds(item: EntityDependencyNode): string[] {
    const ids: string[] = [item.nodeId]
    item.subItems.forEach(item => ids.push(...itemNodeIds(item)))
    return ids
}

function getEntityKey(item: EntityDefinition | EntitySubItem | EntityOption): string {
    const keyParts: string[] = []
    if (item.builtInFeatures !== null) {
        for (let feature in item.builtInFeatures) {
            keyParts.push(`${feature}: ${item.builtInFeatures[feature]}`)
        }
    }
    keyParts.sort()
    return `${item.entityName}[${keyParts.join(", ")}]`
}

function inlineSubItem(subItem: EntityDependencyNode, nodePath: number[]): EntityDependencyNode {
    const children: EntityDependencyNode[] = []
    for (let child of subItem.subItems) {
        const newNodePath = [...nodePath, children.length]
        children.push(inlineSubItem(child, newNodePath))
    }

    return {
        ...subItem,
        nodeId: marshalNodePath(nodePath),
        inlined: true,
        subItems: children
    }
}

function findAndUpdateVariantInlineItems(
    entity: EntityDefinitionState,
    entityIndex: Map<string, EntityDefinitionState>
) {
    for (let i = 0; i < entity.variants.length; i++) {
        for (let j = 0; j < entity.variants[i].subItems.length; j++) {
            findAndUpdateInlineItems(entity.variants[i].subItems[j], [i, j], entityIndex)
        }
    }
}

function findAndUpdateInlineItems(
    subItem: EntityDependencyNode,
    path: number[],
    entityIndex: Map<string, EntityDefinitionState>
) {
    if (subItem.entity !== null && subItem.inlineable) {
        const entityKey = getEntityKey(subItem.entity);
        const subItemDefinition = entityIndex.get(entityKey);
        if (subItemDefinition !== undefined && subItemDefinition.variants.length === 1) {
            for (let inlinedItem of subItemDefinition.variants[0].subItems) {
                const newNodePath = [...path, subItem.subItems.length]
                subItem.subItems.push(inlineSubItem(inlinedItem, newNodePath))
            }
        }
    } else {
        for (let i = 0; i < subItem.subItems.length; i++) {
            findAndUpdateInlineItems(subItem.subItems[i], [...path, i], entityIndex)
        }
    }
}

export {entityDefinitionEditorReducer, getEntityKey}
