import React, {useEffect, useState} from "react";

import {useReportPageAddress} from "../../../base/useReportPageAddress";
import {apiClient} from "../../../../../../app/client/app/client";
import {Rp7OverviewCoverageData} from "../../../../../../app/client/app/entity/Rp7Report";
import {OverviewSkeleton} from "../OverviewSkeleton";
import {
    WorkspaceBlock,
    WorkspaceBlockBody,
    WorkspaceBlockHeader
} from "../../../../../../qdep/components/app/workspace/Workspace";
import {CoverageChart} from "./CoverageChart";
import {CoverageMetric} from "./CoverageMetric";

import styles from './CoverageOverview.module.css'
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {Label, LabelVariant} from "../../../../../../qdep/components/app/util/label/Label";


interface CoverageOverviewState {
    isLoading: boolean
    selectedBucket: number | undefined
    data: Rp7OverviewCoverageData | undefined
}

const defaultCoverageOverviewState: CoverageOverviewState = {
    isLoading: true,
    selectedBucket: undefined,
    data: undefined
}

const CoverageOverview = () => {
    const address = useReportPageAddress();
    const [state, setState] = useState<CoverageOverviewState>(defaultCoverageOverviewState);

    useEffect(() => {
        setState(defaultCoverageOverviewState)
        apiClient
            .rp7GetOverviewCoverageData(address.prrKey)
            .then(response => {
                if (response.errorReason === undefined && response.data !== undefined) {
                    setState({
                        isLoading: false,
                        selectedBucket: 0,
                        data: response.data,
                    })
                }
            })

    }, [address.prrKey])

    return <>
        { state.isLoading && <OverviewSkeleton title={"Coverage"}/> }
        { (!state.isLoading && state.data !== undefined) &&
            <>
                <WorkspaceBlock>
                    <WorkspaceBlockHeader>
                        Coverage
                    </WorkspaceBlockHeader>
                    <WorkspaceBlockBody>
                        <div className={styles.content_block}>
                            <CoverageChart
                                coverageHistogram={state.data.coverageHistogram}
                                selectBucket={(bucketIndex) =>
                                    setState(prev => ({...prev, selectedBucket: bucketIndex}))
                                }
                            />
                            <hr className={styles.divider}/>
                            <CoverageMetric metrics={state.data}/>
                        </div>
                    </WorkspaceBlockBody>
                </WorkspaceBlock>

                { state.selectedBucket !== undefined &&
                    <WorkspaceBlock>
                        <WorkspaceBlockHeader>
                            Coverage / Table List
                        </WorkspaceBlockHeader>
                        <WorkspaceBlockBody>
                            <TableContainer>
                                <Table size={"small"}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Page</TableCell>
                                            <TableCell>Title</TableCell>
                                            <TableCell>Coverage Rate</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            state.data.coverageHistogram[state.selectedBucket].tables?.map((table, index) =>
                                                <TableRow key={`row-${index}`}>
                                                    <TableCell>{table.page}</TableCell>
                                                    <TableCell>{table.title}</TableCell>
                                                    <TableCell>{ renderCoverageRateLabel(table.coverageRate) }</TableCell>
                                                </TableRow>
                                            )
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </WorkspaceBlockBody>
                    </WorkspaceBlock>
                }
            </>
        }
    </>
}

function renderCoverageRateLabel(value: number): React.ReactNode {
    const percent = value * 100
    let variant: LabelVariant = "warning"
    if (percent >= 80) {
        variant = "success"
    }
    if (percent < 40) {
        variant = "error"
    }

    return <Label variant={variant} text={percent.toFixed(2) + "%"}/>;
}

export {CoverageOverview}
