import React from "react";
import moment from "moment/moment";

import {Label} from "../app/util/label/Label";


function formatDate(value: moment.Moment): string {
    return value.format('YYYY-MM-DD HH:mm')
}

function formatBoolean(value: boolean): JSX.Element {
    if (value) {
        return <Label text={"True"} variant={"success"}/>
    }
    return <Label text={"False"} variant={"error"}/>
}

export {formatDate, formatBoolean}