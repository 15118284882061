import React from "react";
import {Link} from "react-router-dom";

import {Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator} from "@mui/lab";
import {IconButton, Skeleton, Tooltip} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";

import {
    WorkspaceBlock,
    WorkspaceBlockBody,
    WorkspaceBlockHeader
} from "../../../../qdep/components/app/workspace/Workspace";
import {StageStatus} from "../../../../app/client/app/entity/report/Stage";
import {formatDate} from "../../../../qdep/components/entity-list-workspace-block/formatters";
import {PRRKey} from "../../../../app/client/app/entity/Project";
import {ReportStageStatusLabel} from "../../stages/base/util/ReportStageStatusLabel";
import {Spacer} from "../../../../qdep/components/app/util/spacer/Spacer";
import {Analysis} from "../../../../app/client/app/entity/Report";


interface ComponentProps {
    prrKey: PRRKey
    analysis: Analysis | undefined
}

const StagesWorkspaceBlock = (props: ComponentProps) => {
    return <WorkspaceBlock>
        <WorkspaceBlockHeader>
            Stages
        </WorkspaceBlockHeader>
        <WorkspaceBlockBody>
            <Timeline>
                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot color="primary"/>
                        <TimelineConnector/>
                    </TimelineSeparator>
                    <TimelineContent>
                        <StepItem
                            title={"OCR / Document Parsing"}
                            stageSlag={"ocr"}
                            prrKey={props.prrKey}
                            stageStatus={props.analysis?.stages.ocr}
                            stageDescriptionMapper={stage => [
                                ...defaultStageItems(stage),
                                { title: "Tables", value: stage.tables },
                            ]}
                        />
                    </TimelineContent>
                </TimelineItem>

                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot color="primary"/>
                        <TimelineConnector/>
                    </TimelineSeparator>
                    <TimelineContent>
                        <StepItem
                            title={"Data Normalization"}
                            stageSlag={"data-normalization"}
                            prrKey={props.prrKey}
                            stageStatus={props.analysis?.stages.normalization}
                            stageDescriptionMapper={stage => [
                                ...defaultStageItems(stage),
                                { title: "Tables", value: stage.tables },
                                { title: "Filtered Tables", value: stage.filteredTables },
                            ]}
                        />
                    </TimelineContent>
                </TimelineItem>

                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot color="primary"/>
                        <TimelineConnector/>
                    </TimelineSeparator>
                    <TimelineContent>
                        <StepItem
                            title={"Entity Detection"}
                            stageSlag={"entity-detection"}
                            prrKey={props.prrKey}
                            stageStatus={props.analysis?.stages.entityDetection}
                            stageDescriptionMapper={stage => [
                                ...defaultStageItems(stage),
                                { title: "Entities", value: stage.entities },
                            ]}
                        />
                    </TimelineContent>
                </TimelineItem>

                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot color="primary"/>
                        <TimelineConnector/>
                    </TimelineSeparator>
                    <TimelineContent>
                        <StepItem
                            title={"Table Analysis"}
                            stageSlag={"table-analysis"}
                            prrKey={props.prrKey}
                            stageStatus={props.analysis?.stages.tableAnalysis}
                            stageDescriptionMapper={stage => [
                                ...defaultStageItems(stage),
                                { title: "Rules", value: stage.rules },
                                { title: "Issues", value: stage.issues },
                            ]}
                        />
                    </TimelineContent>
                </TimelineItem>

                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot color="primary"/>
                        <TimelineConnector/>
                    </TimelineSeparator>
                    <TimelineContent>
                        <StepItem
                            title={"Cross Table Analysis"}
                            stageSlag={"cross-analysis"}
                            prrKey={props.prrKey}
                            stageStatus={props.analysis?.stages.tableRefs}
                            stageDescriptionMapper={stage => [
                                ...defaultStageItems(stage),
                                { title: "Refs", value: stage.refs },
                                { title: "Unresolved Refs", value: stage.unresolvedRefs },
                            ]}
                        />
                    </TimelineContent>
                </TimelineItem>

                <TimelineItem>
                    <TimelineSeparator>
                        <TimelineDot color="primary"/>
                    </TimelineSeparator>
                    <TimelineContent>
                        <StepItem
                            title={"Summary"}
                            stageSlag={"summary"}
                            prrKey={props.prrKey}
                            stageStatus={props.analysis?.stages.summary}
                            stageDescriptionMapper={defaultStageItems}
                        />
                    </TimelineContent>
                </TimelineItem>
            </Timeline>
        </WorkspaceBlockBody>
    </WorkspaceBlock>
}

function stageDuration(stage: StageStatus): string | undefined {
    if (stage.startTimestamp !== undefined && stage.endTimestamp !== undefined) {
        const diff = stage.endTimestamp.diff(stage.startTimestamp, "seconds");
        if (diff === 0) {
            return "< 1s"
        }
        return diff + "s"
    }

    return undefined
}

function defaultStageItems(stage: StageStatus): StepItemDescriptionItem[] {
    return [
        {
            title: "Start Time",
            value: stage.startTimestamp === undefined
                    ? undefined
                    : formatDate(stage.startTimestamp)
        },
        {
            title: "Duration",
            value: stageDuration(stage)
        },
        {
            title: "Custom Rules",
            value: stage.customRules
                    ? stage.customRules
                    : undefined
        },
        {
            title: "Failed Custom Rules",
            value: stage.failedCustomRules
                    ? stage.failedCustomRules
                    : undefined
        },
    ]
}

interface StepItemDescriptionItem {
    title: string
    value: string | number | undefined | null
}

interface StepItemProps <T extends StageStatus> {
    title: string
    prrKey: PRRKey
    stageSlag: string

    stageStatus: T | undefined
    stageDescriptionMapper: (stage: T) => StepItemDescriptionItem[]
}

function StepItem<T extends StageStatus>(props: StepItemProps<T>): JSX.Element {
    return <div className={"report-steps-item-container"}>
        <div className={"report-steps-item-header"}>
            <div>{props.title}</div>
            { props.stageStatus !== undefined &&
                <>
                    <ReportStageStatusLabel value={props.stageStatus.status}/>
                    <Spacer/>
                    <Tooltip title="Open Stage">
                        <Link to={`/report/${props.prrKey.projectId}/${props.prrKey.reportIndex}/${props.prrKey.revisionIndex}/${props.stageSlag}`}>
                            <IconButton disabled={props.stageStatus.status !== "PASSED" && props.stageStatus.status !== "OUTDATED"}>
                                <LaunchIcon/>
                            </IconButton>
                        </Link>
                    </Tooltip>
                </>
            }
        </div>
        <div className={"report-steps-item-description"}>
            { props.stageStatus === undefined && <Skeleton height={"80px"} sx={{transform: "unset"}}/>}
            { props.stageStatus !== undefined &&
                props.stageDescriptionMapper(props.stageStatus)
                    .filter(item => item.value !== undefined && item.value !== null)
                    .map(item =>
                        <div key={item.title} className={"report-steps-item-description-item"}>
                            <span className={"title"}>{item.title}:</span>
                            <span className={"value"}>{item.value}</span>
                        </div>
                    )
            }
        </div>
    </div>
}

export {StagesWorkspaceBlock}