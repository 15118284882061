import React from "react";
import {AnyAction} from "redux";

import {Rp5MenuItemState} from "./reducer";
import {Label} from "../../../../../qdep/components/app/util/label/Label";
import {PRRKey} from "../../../../../app/client/app/entity/Project";
import {ReportMenuContainer} from "../../base/report-menu/ReportMenuContainer";
import {BaseMenuState} from "../../base/report-menu/state";
import {ReportMenuGroupContainer} from "../../base/report-menu/ReportMenuGroupContainer";
import {ReportMenuItemLink, ReportMenuItemList} from "../../base/report-menu/ReportMenuItemList";


interface ReportMenuProps {
    prrKey: PRRKey
    menu: BaseMenuState<Rp5MenuItemState>
    dispatch: React.Dispatch<AnyAction>
}

const ReportMenu = (props: ReportMenuProps) => {
    return <ReportMenuContainer prrKey={props.prrKey} dispatch={props.dispatch}>
        <ReportMenuGroupContainer isHighLevelGroup label={"Pages"}>
            <ReportMenuItemList>
                { props.menu.items.map((item, index) =>
                    <ReportMenuItemLink
                        key={index}
                        index={index}
                        item={item}
                    >
                        <span className={"rp5-menu-item-page"}>Page {item.page}</span>
                        <span className={"rp5-menu-item-desc"}>{item.tables} table(s)</span>
                        {
                            item.issues > 0 && <Label variant={"error"} text={item.issues} size={"small"}/>
                        }
                    </ReportMenuItemLink>
                )}
            </ReportMenuItemList>
        </ReportMenuGroupContainer>
    </ReportMenuContainer>
}

export {ReportMenu}
