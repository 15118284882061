import React, {useEffect, useReducer} from "react";
import {useAsyncFn} from "react-use";
import {SnackbarKey, SnackbarMessage, useSnackbar, VariantType} from "notistack";

import {Button} from "@mui/material";
import {LoadingButton} from "@mui/lab";

import {PRRKey} from "../../../../../../app/client/app/entity/Project";
import {FeatureFlagDefinition} from "../../../../../../app/client/app/entity/report/settings/FeatureDefinition";
import {featureEditorReducer} from "../../../../entity/feature-editor/reducer";
import {defaultFeatureDefinitionEditorState, stateToValue} from "../../../../entity/feature-editor/state";
import {setFeature} from "../../../../entity/feature-editor/action";
import {apiClient, ApiResponse} from "../../../../../../app/client/app/client";
import {
    ReportStatusResultWithEntity,
    StageStatuses
} from "../../../../../../app/client/app/entity/ops/ReportStatusResult";
import {EditorActionsContainer} from "../../../../../../qdep/components/app/editor/EditorActionsContainer";
import {FeatureDefinitionEditorBody} from "../../../../entity/feature-editor/editor_body";


interface FeatureFlagDefinitionEditorProps {
    prrKey: PRRKey

    isNew: boolean
    feature: FeatureFlagDefinition

    apply: (feature: FeatureFlagDefinition) => void
    updateReportStatuses: (stages: StageStatuses) => void
}

const FeatureFlagDefinitionEditor = (props: FeatureFlagDefinitionEditorProps) => {
    const [state, dispatch] = useReducer(featureEditorReducer, defaultFeatureDefinitionEditorState);

    useEffect(
        () => dispatch(setFeature(props.feature)),
        [props.feature],
    )

    const { enqueueSnackbar } = useSnackbar();
    const [applyFeatureOp, applyFeature] = useAsyncFn(async (prrKey: PRRKey, feature: FeatureFlagDefinition): Promise<ApiResponse<ReportStatusResultWithEntity<FeatureFlagDefinition>>> => {
        if (feature.id === null) {
            return await apiClient.createReportFeatureDefinition(prrKey, feature);
        } else {
            return await apiClient.updateReportFeatureDefinition(prrKey, feature);
        }
    }, []);

    return <>
        <FeatureDefinitionEditorBody
            isNew={props.isNew}
            state={state}
            dispatch={dispatch}
        />

        <EditorActionsContainer>
            <Button disabled variant="outlined">
                Cancel
            </Button>
            <LoadingButton
                loading={applyFeatureOp.loading}
                variant="contained"
                color="secondary"
                onClick={() => {
                    const feature = stateToValue(state);
                    applyFeature(props.prrKey, feature)
                        .then(response => {
                            const notificationKey: SnackbarKey = "update-feature-request"
                            let notificationVariant: VariantType = "error"
                            let notificationMessage: SnackbarMessage = "Something went wrong. Try again later or contact your administrator"

                            if (!response.errorReason && response.data && response.data.status === "ok") {
                                notificationVariant = "success"
                                notificationMessage = "Entity Settings successfully updated. Refresh the page"

                                if (response.data.entity !== undefined) {
                                    props.apply(response.data.entity)
                                }
                                if (response.data.stages !== undefined) {
                                    props.updateReportStatuses(response.data.stages)
                                }
                            }

                            enqueueSnackbar(notificationMessage, {key: notificationKey, variant: notificationVariant});
                        })
                }}
            >
                Apply
            </LoadingButton>
        </EditorActionsContainer>
    </>
}

export {FeatureFlagDefinitionEditor}
