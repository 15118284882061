import {AnyAction} from "redux";

import {
    addCustomRuleReducer, clearCustomRuleUpdatesReducer,
    closeCustomRuleBlockReducer, deleteCustomRuleReducer, deleteSelectedCustomRuleUpdatesReducer,
    openCustomRuleBlockReducer, selectCustomRuleReducer, selectCustomRuleUpdateReducer,
    WithCustomRules
} from "./CustomRules";
import {RpModificationRulePaneActions} from "./action";


function modificationRulePaneReducer<T extends WithCustomRules<any>>(state: T, action: AnyAction): T {
    switch (action.type) {
        case RpModificationRulePaneActions.OPEN_CUSTOM_RULE_PANE:
            return openCustomRuleBlockReducer(state, action)
        case RpModificationRulePaneActions.CLOSE_CUSTOM_RULE_PANE:
            return closeCustomRuleBlockReducer(state, action)
        case RpModificationRulePaneActions.ADD_CUSTOM_RULE:
            return addCustomRuleReducer(state, action)
        case RpModificationRulePaneActions.DELETE_CUSTOM_RULE:
            return deleteCustomRuleReducer(state, action)
        case RpModificationRulePaneActions.SELECT_CUSTOM_RULE:
            return selectCustomRuleReducer(state, action)
        case RpModificationRulePaneActions.SELECT_CUSTOM_RULE_UPDATE:
            return selectCustomRuleUpdateReducer(state, action)
        case RpModificationRulePaneActions.DELETE_CUSTOM_RULE_UPDATE:
            return deleteSelectedCustomRuleUpdatesReducer(state, action)
        case RpModificationRulePaneActions.CLEAR_CUSTOM_RULE_UPDATE:
            return clearCustomRuleUpdatesReducer(state, action)
    }
    return state
}

export {modificationRulePaneReducer}
