import React, {useState} from "react";
import {Link} from "react-router-dom";
import {useAsyncFn} from "react-use";

import {Divider, ListItemIcon, ListItemText, Menu, MenuItem} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import LaunchIcon from '@mui/icons-material/Launch';

import {EntityModelRevisionRef} from "../../../../../../app/client/app/entity/report/model/ReportModel";
import {NewRevisionDialog} from "../../../../model/entity-editor-page/new-revision-dialog/NewRevisionDialog";
import {ApiResponse} from "../../../../../../app/client/types";
import {apiClient} from "../../../../../../app/client/app/client";
import {CopyReportEntitiesRequestDto} from "../../../../../../app/client/app/entity/report/model/ReportModelDto";
import {PRRKey} from "../../../../../../app/client/app/entity/Project";

import styles from './ModelMenu.module.css'
import {OpsResultWithData} from "../../../../../../app/client/app/entity/ops/OpsResult";
import {CreateEntityModelDialog} from "./CreateEntityModelDialog";


interface ComponentProps {
    anchorEl: Element | null
    close: () => void

    prrKey: PRRKey
    entityModelRef: EntityModelRevisionRef | null
}

const copyReportEntity = async (modelId: string, props: CopyReportEntitiesRequestDto): Promise<ApiResponse<OpsResultWithData<EntityModelRevisionRef>>> => {
    return apiClient.copyReportEntities(modelId, props)
}

const ModelMenu = (props: ComponentProps) => {
    const [modelRef, setModelRef] = useState(props.entityModelRef);

    const [openNewRevisionDialog, setOpenNewRevisionDialog] = useState(false)
    const [copyReportEntityStatus, copyReportEntityFn] = useAsyncFn(copyReportEntity, []);

    const [openCreateModelDialog, setOpenCreateModelDialog] = useState(false)

    return <>
        <Menu
            open={props.anchorEl !== null}
            anchorEl={props.anchorEl}
            onClose={props.close}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <div className={styles.info_container}>
                { modelRef !== null &&
                    <div>
                        <div className={styles.info_field_container}>
                            <label>Model Name</label>
                            <span>{ modelRef.modelName } / { modelRef.revisionName }</span>
                        </div>
                        <div className={styles.info_field_container}>
                            <label>Description</label>
                            <span>{ modelRef.description || "-" }</span>
                        </div>
                    </div>
                }
                { props.entityModelRef === null &&
                    <div className={styles.no_item_container}>
                        There is no attached model
                    </div>
                }
            </div>

            <MenuItem
                disabled={props.entityModelRef === null}
                component={Link}
                to={`/report/models/${props.entityModelRef?.modelId || "unknown"}/entity/${props.entityModelRef?.revisionId || "unknown"}`}
            >
                <ListItemIcon>
                    <LaunchIcon/>
                </ListItemIcon>
                <ListItemText>
                    Open Attached Model
                </ListItemText>

            </MenuItem>
            <MenuItem
                disabled={props.entityModelRef === null}
                onClick={() => {
                    setOpenNewRevisionDialog(true)
                    props.close()
                }}
            >
                <ListItemIcon>
                    <AddIcon/>
                </ListItemIcon>
                <ListItemText>
                    Add Entity Revision
                </ListItemText>
            </MenuItem>

            <Divider/>

            <MenuItem
                onClick={() => {
                    setOpenCreateModelDialog(true)
                    props.close()
                }}
            >
                <ListItemIcon>
                    <AddIcon/>
                </ListItemIcon>
                <ListItemText>
                    Create Entity Model
                </ListItemText>
            </MenuItem>
        </Menu>

        <NewRevisionDialog
            open={openNewRevisionDialog}
            loading={copyReportEntityStatus.loading}
            close={() => setOpenNewRevisionDialog(false)}
            apply={info => {
                if (modelRef !== null) {
                    copyReportEntityFn(modelRef.modelId, {
                        reportPrrKey: `${props.prrKey.projectId}-${props.prrKey.reportIndex}-${props.prrKey.revisionIndex}`,
                        name: info.name,
                        description: info.description,
                    })
                        .then(response => {
                            if (response.errorReason === undefined && response.data !== undefined) {
                                setOpenNewRevisionDialog(false)
                                setModelRef(response.data.data)
                            }
                        });
                }
            }}
        />
        <CreateEntityModelDialog
            open={openCreateModelDialog}
            close={() => setOpenCreateModelDialog(false)}

            prrKeyStr={`${props.prrKey.projectId}-${props.prrKey.reportIndex}-${props.prrKey.revisionIndex}`}
            updateModelRef={setModelRef}
        />
    </>
}

export {ModelMenu}