import React from 'react';
import {Route, Switch} from "react-router-dom";
import {useTitle} from "react-use";
import {ConnectedRouter} from "connected-react-router";

import {history} from "./app/state/store";
import {AppState} from "./app/state/state";
import {LoginPage} from "./app/page/LoginPage";
import {MainPage} from "./app/page/MainPage";
import useNotifier from "./qdep/notification/useNotifier";
import {ReportOcrPage} from "./workspace/report/stages/rp2-ocr/ReportOcrPage";
import {ReportDataNormalizationPage} from "./workspace/report/stages/rp3-data-normalization/ReportDataNormalizationPage";
import {ReportTableAnalysisPage} from "./workspace/report/stages/rp5-table-analysis/page/ReportTableAnalysisPage";
import {ReportCrossAnalysisPage} from "./workspace/report/stages/rp6-cross-analysis/ReportCrossAnalysisPage";
import {ReportEntityPage} from "./workspace/report/stages/rp4-entity/page";
import {ReportModelPage} from "./workspace/report/model/page";
import {ReportSummaryPage} from "./workspace/report/stages/rp7-summary/page";
import {ReportOverviewPage} from "./workspace/report/overview/page";
import {CoverageTablePage} from "./workspace/report/stages/rp7-summary/coverage-table/page";
import {UnauthorizedErrorBoundary} from "./qdep/components/app/error/UnauthorizedErrorBoundary";

import './App.css';
import './app/ui/entity-registry-table.css'


const appVersionName = process.env.REACT_APP_VERSION_NAME || "latest"
const onMaintenance = process.env.REACT_APP_ON_MAINTENANCE || "false"
const notificationSelector = (store: AppState) => store.notification || []

const App = () => {
    return <>
        { onMaintenance === "true" && <OnMaintenanceMode/> }
        { onMaintenance !== "true" && <NormalMode/> }
    </>
}

const NormalMode = () => {
    useNotifier({
        notificationSelector: notificationSelector
    })
    useTitle("FINHOUND")

    return <ConnectedRouter history={history}>
        <UnauthorizedErrorBoundary>
            <Switch>
                <Route exact path="/login">
                    <LoginPage appVersion={appVersionName}/>
                </Route>
                <Route path="/report/:project/:report/:revision/ocr">
                    <ReportOcrPage/>
                </Route>
                <Route path="/report/:project/:report/:revision/data-normalization">
                    <ReportDataNormalizationPage/>
                </Route>
                <Route path="/report/:project/:report/:revision/entity-detection">
                    <ReportEntityPage/>
                </Route>
                <Route path="/report/:project/:report/:revision/table-analysis">
                    <ReportTableAnalysisPage/>
                </Route>
                <Route path="/report/:project/:report/:revision/cross-analysis">
                    <ReportCrossAnalysisPage/>
                </Route>
                <Route path="/report/:project/:report/:revision/summary/coverage">
                    <CoverageTablePage/>
                </Route>
                <Route path="/report/:project/:report/:revision/summary">
                    <ReportSummaryPage/>
                </Route>
                <Route path={["/report/models/:modelId", "/report/models"]}>
                    <ReportModelPage/>
                </Route>
                <Route path={["/report/:project/:report/:revision/:workspace", "/report/:project/:report/:revision"]}>
                    <ReportOverviewPage/>
                </Route>
                <Route>
                    <MainPage appVersion={appVersionName}/>
                </Route>
            </Switch>
        </UnauthorizedErrorBoundary>
    </ConnectedRouter>
}

const OnMaintenanceMode = () => {
    return <div className={"on_maintenance_container"}>
        <div className={"title"}>
            Service is down for scheduled maintenance.
        </div>
        <div className={"desc"}>
            Please check back soon
        </div>
    </div>
}

export default App;
