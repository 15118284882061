import React, {ReactNode} from "react";
import {EmptyListAddItemButton} from "./EmptyList";

import styles from './ListContainer.module.css'


interface ListContainerProps {
    add: () => void
    children: ReactNode[]
}

const ListContainer = (props: ListContainerProps) => {
    if (props.children && props.children.length > 0) {
        return <div className={styles.list_container}>
            { props.children }
        </div>
    }
    return <EmptyListAddItemButton onClick={props.add}/>;
}

export {ListContainer}