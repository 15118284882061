interface Period {
    isUndefined: boolean | null
    year: number | null
    month: number | null
    day: number | null
    isFys: boolean | null
    isFye: boolean | null
}

interface ColumnMetadata {
    size: number
    headerSize: number
    periods: Period[]
}

const shortMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

function formatPeriod(period: Period): string {
    if (!period.isUndefined) {
        const items: string[] = []

        if (period.day !== undefined && period.day !== null) {
            items.push(period.day.toString())
        }
        if (period.month !== undefined && period.month !== null) {
            items.push(shortMonths[period.month-1])
        }
        if (period.year !== undefined && period.year !== null) {
            items.push(period.year.toString())
        }

        if (items.length > 0) {
            return items.join(" ")
        }
    }
    return "unknown";
}

export {formatPeriod}
export type {ColumnMetadata, Period}
