import React from "react";

import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import {Typography} from "@mui/material";

import {Rp5RowTransform} from "../../../../../../app/client/app/entity/Rp5Report";
import {GutterTooltip} from "../../../base/util/GutterTolltip";


const TransformsGutter = (props: {transforms: Rp5RowTransform[]}) => {
    return <GutterTooltip
        placement={"bottom-start"}
        title={
            <>
                <Typography>Transforms: {props.transforms.length}</Typography>
                <ul style={{paddingLeft: "16px"}}>
                    { props.transforms.map((transform, index) =>
                        <li key={index}>{transform.type}</li>
                    )}
                </ul>
            </>
        }
    >
        <AutoFixHighIcon color={"info"} fontSize={"small"}/>
    </GutterTooltip>
}

export {TransformsGutter}