import {AnyAction} from "redux";

import {ProjectDto} from "../../client/app/entity/Project";
import {ReportFile, ReportListItem} from "../../client/app/entity/Report";


enum AppActionTypes {
    PROJECT_LIST_REQUEST             = "PROJECT.LIST_REQUEST",
    PROJECT_LIST_REQUEST_SUCCEEDED   = "PROJECT.LIST_REQUEST.SUCCEEDED",
    // PROJECT_CREATE_REQUEST           = "PROJECT.CREATE_REQUEST",
    PROJECT_CREATE_REQUEST_SUCCEEDED = "PROJECT.CREATE_REQUEST.SUCCEEDED",
    // PROJECT_UPDATE_REQUEST           = "PROJECT.UPDATE_REQUEST",
    // PROJECT_UPDATE_REQUEST_SUCCEEDED = "PROJECT.UPDATE_REQUEST.SUCCEEDED",
    PROJECT_DELETE_REQUEST           = "PROJECT.DELETE_REQUEST",
    PROJECT_DELETE_REQUEST_SUCCEEDED = "PROJECT.DELETE_REQUEST.SUCCEEDED",
    PROJECT_OPEN_OVERVIEW            = 'PROJECT.OPEN_OVERVIEW',

    PROJECT_REPORT_LIST_REQUEST           = "PROJECT.REPORT.LIST_REQUEST",
    PROJECT_REPORT_LIST_REQUEST_SUCCEEDED = "PROJECT.REPORT.LIST_REQUEST.SUCCEEDED",

    REPORT_FILE_LIST_REQUEST           = "REPORT_FILE_REQUEST",
    REPORT_FILE_LIST_REQUEST_SUCCEEDED = "REPORT_FILE_REQUEST.SUCCEEDED",
}

function projectListRequest(): AnyAction {
    return {type: AppActionTypes.PROJECT_LIST_REQUEST}
}

function projectListRequestSucceeded(entities: ProjectDto[]): AnyAction {
    return {type: AppActionTypes.PROJECT_LIST_REQUEST_SUCCEEDED, data: entities}
}

function projectCreateRequestSucceeded(entity: ProjectDto): AnyAction {
    return {type: AppActionTypes.PROJECT_CREATE_REQUEST_SUCCEEDED, entity: entity}
}

function projectDeleteRequest(entityId: string): AnyAction {
    return {type: AppActionTypes.PROJECT_DELETE_REQUEST, entityId: entityId}
}

function projectDeleteRequestSucceeded(entityId: string): AnyAction {
    return {type: AppActionTypes.PROJECT_DELETE_REQUEST_SUCCEEDED, entityId: entityId}
}

function projectOpenOverview(index: number): AnyAction {
    return {
        type: AppActionTypes.PROJECT_OPEN_OVERVIEW,
        index,
    }
}

// -------------------------------------------------------------------------------------------
function projectReportListRequest(): AnyAction {
    return {type: AppActionTypes.PROJECT_REPORT_LIST_REQUEST}
}

function projectReportListRequestSucceeded(entities: ReportListItem[]): AnyAction {
    return {type: AppActionTypes.PROJECT_REPORT_LIST_REQUEST_SUCCEEDED, data: entities}
}

// -------------------------------------------------------------------------------------------
function reportFileListRequest(): AnyAction {
    return {type: AppActionTypes.REPORT_FILE_LIST_REQUEST}
}

function reportFileListRequestSucceeded(entities: ReportFile[]): AnyAction {
    return {type: AppActionTypes.REPORT_FILE_LIST_REQUEST_SUCCEEDED, data: entities}
}

export {
    AppActionTypes,
    projectCreateRequestSucceeded,
    projectListRequest, projectListRequestSucceeded,
    projectDeleteRequest, projectDeleteRequestSucceeded,
    projectReportListRequest, projectReportListRequestSucceeded,
    projectOpenOverview,
    reportFileListRequest, reportFileListRequestSucceeded,
}
