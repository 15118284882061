import React, {Dispatch, useMemo} from "react";
import {AnyAction} from "redux";

import {Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";

import {Label} from "../../../../../qdep/components/app/util/label/Label";
import {TableRulesPaneState} from "../report-table/state";
import {selectAllRules, selectRule} from "../report-table/reducer";


type ReportTableRulePaneProps = Omit<TableRulesPaneState, "show"> & { dispatch: Dispatch<AnyAction> }

const ReportTableRulePane = (props: ReportTableRulePaneProps) => {
    const rules = useMemo(() => [...props.rules.values()], [props.rules]);

    return <TableContainer>
        <Table size={"small"}>
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={props.numSelectedRules > 0 && props.numSelectedRules < props.numRule}
                            checked={props.numRule > 0 && props.numSelectedRules === props.numRule}
                            onClick={() => props.dispatch(selectAllRules())}
                        />
                    </TableCell>
                    <TableCell>Id</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Row</TableCell>
                    <TableCell>Column</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Cell Refs</TableCell>
                    <TableCell>Description</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    rules.map(rule =>
                        <TableRow key={`row-${rule.data.ruleId}`}>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    color="primary"
                                    checked={rule.isSelected}
                                    onClick={() => props.dispatch(selectRule([rule.data.ruleId]))}
                                />
                            </TableCell>
                            <TableCell>{rule.data.ruleId}</TableCell>
                            <TableCell>{rule.data.name}</TableCell>
                            <TableCell>{rule.data.row}</TableCell>
                            <TableCell>{rule.data.rowColumn}</TableCell>
                            <TableCell>
                                {
                                    rule.data.isPassed
                                        ? <Label variant={"success"} text={"PASSED"}/>
                                        : <Label variant={"error"} text={"FAILED"}/>
                                }
                            </TableCell>
                            <TableCell>{rule.data.cellRefs.length}</TableCell>
                            <TableCell>{rule.data.description}</TableCell>
                        </TableRow>
                    )
                }
            </TableBody>
        </Table>
    </TableContainer>
}

export {ReportTableRulePane}