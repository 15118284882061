import React, {useEffect, useState} from "react";

import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";

import {FieldContainer} from "../workspace/Workspace";


interface AddStringValueDialogProps {
    title: string
    isOpen: boolean
    close: () => void
    confirm: (value: string) => void

    defaultValue?: string
}

const AddStringValueDialog = (props: AddStringValueDialogProps) => {
    const [value, setValue] = useState("");
    useEffect(() => {
        setValue(props.defaultValue || "")
    }, [props.defaultValue])

    return <Dialog open={props.isOpen}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent sx={{paddingBottom: "0"}}>
            <FieldContainer notLabelWidth title={"Value"}>
                <TextField
                    className={"field-control tiny"}
                    size={"small"}
                    value={value}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setValue(event.target.value)
                    }}
                />
            </FieldContainer>
        </DialogContent>
        <DialogActions>
            <DialogActions>
                <Button
                    onClick={() => {
                        props.close()
                        setValue("")
                    }}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained" color="secondary"
                    onClick={() => {
                        if (value !== "") {
                            props.confirm(value)
                            setValue("")
                        }
                        props.close()
                    }}
                >
                    Confirm
                </Button>
            </DialogActions>
        </DialogActions>
    </Dialog>
}

export {AddStringValueDialog}