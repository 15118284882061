import React, {useState} from "react";
import {AnyAction} from "redux";

import {Divider, ListItemIcon, Menu, MenuItem} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

import {Rp6ContextMenu} from "../data/reducer";
import {closeContextMenu} from "../data/action";
import {addCustomRule} from "../../base/report-custom-rule-pane/data/action";
import {Rp6ModificationRule, Rp6ModificationRuleType} from "../../../../../app/client/app/entity/Rp6Report";
import {RowRefSubMenu} from "./RowRefSubMenu";
import {AddStringValueDialog} from "../../../../../qdep/components/app/dialog/AddStringValueDialog";


interface ComponentProps {
    contextMenu: Rp6ContextMenu,
    dispatch: React.Dispatch<AnyAction>
}

interface DialogState {
    open: boolean
    mode: "ADD" | "EDIT"
    refName: string | undefined
}

const TableContextMenu = (props: ComponentProps) => {
    const [dialogState, setDialogState] = useState<DialogState>({
        open: false,
        mode: "ADD",
        refName: undefined,
    });

    return <>
        <Menu
            open={props.contextMenu.show}
            onClose={() => props.dispatch(closeContextMenu())}
            anchorReference="anchorPosition"
            anchorPosition={
                props.contextMenu.show
                    ? { top: props.contextMenu.position.y, left: props.contextMenu.position.x }
                    : undefined
            }
        >
            <MenuItem
                onClick={() => {
                    props.dispatch(closeContextMenu())
                    if (props.contextMenu.tableId !== undefined) {
                        props.dispatch(addCustomRule({
                            tableId: props.contextMenu.tableId,
                            type: Rp6ModificationRuleType.SUPPRESS_CELL_REF,
                            suppressCellRefs: {
                                columnIndex: props.contextMenu.position.columnIndex,
                                rowIndex: props.contextMenu.position.rowIndex,
                            },
                        } as Rp6ModificationRule))
                    }
                }}
            >
                <ListItemIcon>
                    <FilterAltOffIcon/>
                </ListItemIcon>
                Suppress Cell Ref
            </MenuItem>
            <MenuItem
                onClick={() => {
                    props.dispatch(closeContextMenu())
                    if (props.contextMenu.tableId !== undefined) {
                        props.dispatch(addCustomRule({
                            tableId: props.contextMenu.tableId,
                            type: Rp6ModificationRuleType.SUPPRESS_CELL_REF_BY_COLUMN,
                            suppressCellRefs: {
                                columnIndex: props.contextMenu.position.columnIndex,
                            },
                        } as Rp6ModificationRule))
                    }
                }}
            >
                <ListItemIcon>
                    <FilterAltOffIcon/>
                </ListItemIcon>
                Suppress Column Refs
            </MenuItem>

            <Divider/>

            <MenuItem
                onClick={() => {
                    props.dispatch(closeContextMenu())
                    if (props.contextMenu.tableId !== undefined) {
                        setDialogState({
                            open: true,
                            mode: "ADD",
                            refName: undefined,
                        })
                    }
                }}
            >
                <ListItemIcon>
                    <AddIcon/>
                </ListItemIcon>
                Add Ref
            </MenuItem>

            { (props.contextMenu.refs !== undefined && props.contextMenu.refs.length > 0) &&
                props.contextMenu.refs.map((ref, index) =>
                    <RowRefSubMenu
                        key={`ref-group-${index}`}
                        rowRef={ref}
                        onEdit={(refName: string) => {
                            props.dispatch(closeContextMenu())
                            if (props.contextMenu.tableId !== undefined) {
                                setDialogState({
                                    open: true,
                                    mode: "EDIT",
                                    refName: refName,
                                })
                            }
                        }}
                        onDelete={() => {
                            props.dispatch(closeContextMenu())
                            if (props.contextMenu.tableId !== undefined) {
                                props.dispatch(addCustomRule({
                                    tableId: props.contextMenu.tableId,
                                    type: Rp6ModificationRuleType.DELETE_REF,
                                    deleteRef: {
                                        rowIndex: props.contextMenu.position.rowIndex,
                                        refName: ref.refName
                                    },
                                } as Rp6ModificationRule))
                            }
                        }}
                    />
                )
            }

        </Menu>

        <AddStringValueDialog
            isOpen={dialogState.open}
            title={dialogState.mode === "ADD" ? "New Row Ref": "Edit Row Ref"}
            defaultValue={dialogState.refName}
            close={() => setDialogState(prev => ({...prev, open: false}))}
            confirm={refName => {
                if (props.contextMenu.tableId !== undefined) {
                    if (dialogState.mode === "ADD") {
                        props.dispatch(addCustomRule({
                            tableId: props.contextMenu.tableId,
                            type: Rp6ModificationRuleType.ADD_REF,
                            addRef: {
                                rowIndex: props.contextMenu.position.rowIndex,
                                refName: refName
                            },
                        } as Rp6ModificationRule));
                    } else {
                        props.dispatch(addCustomRule({
                            tableId: props.contextMenu.tableId,
                            type: Rp6ModificationRuleType.EDIT_REF,
                            editRef: {
                                rowIndex: props.contextMenu.position.rowIndex,
                                refName: dialogState.refName,
                                newRefName: refName,
                            },
                        } as Rp6ModificationRule));
                    }
                }
            }}
        />
    </>
}

export {TableContextMenu}