import {AnyAction} from "redux";

const enum RpActions {
    NAVIGATION_PREV_PAGE    = "REPORT.NAV.PREV_PAGE",
    NAVIGATION_NEXT_PAGE    = "REPORT.NAV.NEXT_PAGE",
    NAVIGATION_MENU_ITEM    = "REPORT.NAV.MENU_ITEM",
}

function prevPage(): AnyAction {
    return {type: RpActions.NAVIGATION_PREV_PAGE}
}

function nextPage(): AnyAction {
    return {type: RpActions.NAVIGATION_NEXT_PAGE}
}

function selectMenuItem(menuIndex: number): AnyAction {
    return {type: RpActions.NAVIGATION_MENU_ITEM, menuIndex}
}

export {
    RpActions,
    prevPage, nextPage,
    selectMenuItem,
}
