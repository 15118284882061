import React, {Dispatch, useState} from "react";
import {AnyAction} from "redux";
import {useAsyncFn} from "react-use";
import {SnackbarKey, SnackbarMessage, useSnackbar, VariantType} from "notistack";

import {IconButton, Tooltip} from "@mui/material";
import StorageIcon from "@mui/icons-material/Storage";
import DeleteIcon from "@mui/icons-material/Delete";

import {EnableFlag} from "./EnableFlag";
import {PRRKey} from "../../../../../../app/client/app/entity/Project";
import {apiClient, ApiResponse} from "../../../../../../app/client/app/client";
import {
    deleteEntityDefinition,
    deleteFeatureDefinition,
    disableEntityDefinition,
    disableFeatureDefinition
} from "../../data/action";
import {ReportStatusResult} from "../../../../../../app/client/app/entity/ops/ReportStatusResult";
import {ModelMenu} from "./ModelMenu";
import {EntityModelRevisionRef} from "../../../../../../app/client/app/entity/report/model/ReportModel";


interface ComponentProps {
    type: "ENTITY" | "FEATURE"

    prrKey: PRRKey
    definitionId: string | null
    enabled: boolean
    entityModelRef: EntityModelRevisionRef | null

    dispatch: Dispatch<AnyAction>
}

const HeaderControlGroup = (props: ComponentProps) => {
    const [modelMenuAnchorEl, setModelMenuAnchorEl] = useState(null as Element | null);

    const { enqueueSnackbar } = useSnackbar();
    const [, deleteDefinition] = useAsyncFn(async (): Promise<void> => {
        if (props.definitionId === null) {
            return
        }

        let response: ApiResponse<ReportStatusResult>
        if (props.type === "ENTITY") {
            response = await apiClient.deleteReportEntityDefinition(props.prrKey, props.definitionId);
        } else {
            response = await apiClient.deleteReportFeatureDefinition(props.prrKey, props.definitionId);
        }

        const notificationKey: SnackbarKey = "delete-definition-request"
        let notificationVariant: VariantType = "error"
        let notificationMessage: SnackbarMessage = "Something went wrong. Try again later or contact your administrator"

        if (!response.errorReason && response.data && response.data.status === "ok") {
            notificationVariant = "success"
            notificationMessage = "Entity Settings successfully deleted. Refresh the page"
            if (props.type === "ENTITY") {
                props.dispatch(deleteEntityDefinition())
            } else {
                props.dispatch(deleteFeatureDefinition())
            }
        }

        enqueueSnackbar(notificationMessage, {key: notificationKey, variant: notificationVariant});
    }, [props.prrKey, props.definitionId, props.type]);
    const [, disableDefinition] = useAsyncFn(async (disable: boolean): Promise<void> => {
        if (props.definitionId === null) {
            return
        }

        let response: ApiResponse<ReportStatusResult>
        const isDefinitionDisabled = !disable;
        if (props.type === "ENTITY") {
            response = await apiClient.disableReportEntityDefinition(props.prrKey, props.definitionId, isDefinitionDisabled);
        } else {
            response = await apiClient.disableReportFeatureDefinition(props.prrKey, props.definitionId, isDefinitionDisabled);
        }

        const notificationKey: SnackbarKey = "disable-definition-request"
        let notificationVariant: VariantType = "error"
        let notificationMessage: SnackbarMessage = "Something went wrong. Try again later or contact your administrator"

        if (!response.errorReason && response.data && response.data.status === "ok") {
            notificationVariant = "success"
            notificationMessage = "Entity Settings successfully updated. Refresh the page"
            if (props.type === "ENTITY") {
                props.dispatch(disableEntityDefinition(isDefinitionDisabled))
            } else {
                props.dispatch(disableFeatureDefinition(isDefinitionDisabled))
            }
        }

        enqueueSnackbar(notificationMessage, {key: notificationKey, variant: notificationVariant});
    }, [props.prrKey, props.definitionId, props.type]);

    return <>
        <EnableFlag
            enabled={props.enabled}
            onChange={disableDefinition}
        />
        <div>
            <Tooltip title={"Entity Model"}>
                <IconButton
                    onClick={event => setModelMenuAnchorEl(prev => {
                        return !prev ? event.currentTarget : null;
                    })}
                >
                    <StorageIcon/>
                </IconButton>
            </Tooltip>
            <ModelMenu
                prrKey={props.prrKey}
                entityModelRef={props.entityModelRef}
                anchorEl={modelMenuAnchorEl}
                close={() => setModelMenuAnchorEl(null)}
            />
        </div>
        <Tooltip title={props.type === "ENTITY" ? "Delete Entity": "Delete Feature"}>
            <IconButton onClick={deleteDefinition}>
                <DeleteIcon/>
            </IconButton>
        </Tooltip>
    </>
}

export {HeaderControlGroup}
