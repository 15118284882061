import {styled} from "@mui/material";
import ButtonBase, {ButtonBaseProps} from "@mui/material/ButtonBase";
import AddIcon from "@mui/icons-material/Add";

interface ComponentProps {
    title: string
    onClick: () => void
}

const StyledButtonBase = styled((props: ButtonBaseProps) => (
    <ButtonBase {...props}/>
))({
    font: "inherit",
    padding: "16px 16px",
    color: "rgba(0, 0, 0, 0.54)",
    backgroundColor: "rgba(104, 112, 120, 0.14)",
    borderRadius: "10px",
    "&:hover": {
        backgroundColor: "rgba(104, 112, 120, 0.24)",
    },
})

const EditorTypeButton = (props: ComponentProps) => {
    return <StyledButtonBase onClick={props.onClick}>
        <AddIcon sx={{paddingRight: "8px"}}/>
        <span>{ props.title }</span>
    </StyledButtonBase>
}

export {EditorTypeButton}
