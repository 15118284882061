import React, {useState} from "react";
import {useAsyncFn} from "react-use";
import {SnackbarKey, useSnackbar, VariantType} from "notistack";

import {Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel} from "@mui/material";
import {LoadingButton} from "@mui/lab";

import {PRRKey} from "../../../../app/client/app/entity/Project";
import {apiClient} from "../../../../app/client/app/client";
import {Attachment} from "../../../../app/client/app/entity/Report";
import {ResultReportOptions} from "../../../../app/client/app/entity/ops/ops";

import styles from './GenerateTickReportDialog.module.css'


interface ComponentProps {
    isOpen: boolean
    close: () => void
    apply: (attachment: Attachment) => void
    prrKey: PRRKey
}

const GenerateTickReportDialog = (props: ComponentProps) => {
    const [options, setOptions] = useState<ResultReportOptions>({
        disableRefTicks: false,
        disableSumTicks: false
    });
    const { enqueueSnackbar } = useSnackbar();
    const [operationState, operation] = useAsyncFn(async (options: ResultReportOptions): Promise<Attachment | undefined> => {
        const response = await apiClient.generateTickReport(props.prrKey, options);
        if (response.errorReason === undefined && response.data !== undefined && response.data.status === "OK") {
            return response.data.data
        }
        return undefined
    }, [props.prrKey]);

    return <Dialog open={props.isOpen}>
        <DialogTitle>
            Generate New Tick Report
        </DialogTitle>
        <DialogContent sx={{paddingBottom: "0"}}>
            <div className={styles.report_option_dialog_body}>
                <FormControlLabel
                    label="Table Analysis Ticks"
                    control={<Checkbox
                        checked={!options.disableSumTicks}
                        onChange={(_, checked) => setOptions(prev => ({...prev, disableSumTicks: !checked}))}
                    />}
                />
                <FormControlLabel
                    label="Cross Table Ticks"
                    control={<Checkbox
                        checked={!options.disableRefTicks}
                        onChange={(_, checked) => setOptions(prev => ({...prev, disableRefTicks: !checked}))}
                    />}
                />
            </div>
        </DialogContent>
        <DialogActions>
            <DialogActions>
                <Button
                    onClick={() => {
                        props.close()
                    }}
                >
                    Cancel
                </Button>
                <LoadingButton
                    variant="contained" color="secondary"
                    loading={operationState.loading}
                    onClick={() => {
                        operation(options)
                            .then((attachment: Attachment | undefined) => {
                                if (attachment !== undefined) {
                                    props.apply(attachment)
                                } else {
                                    const notificationKey: SnackbarKey = "generate-tick-report-request";
                                    const notificationVariant: VariantType = "success"
                                    const notificationMessage = "Task submitted. Wait and refresh the page"
                                    enqueueSnackbar(notificationMessage, {key: notificationKey, variant: notificationVariant});
                                }
                            })
                    }}
                >
                    Generate
                </LoadingButton>
            </DialogActions>
        </DialogActions>
    </Dialog>
}

export {GenerateTickReportDialog}