import React, {useEffect, useState} from "react";

import {FieldContainer, WorkspaceBlockBody} from "../../../../qdep/components/app/workspace/Workspace";
import {PRRKey} from "../../../../app/client/app/entity/Project";
import {apiClient} from "../../../../app/client/app/client";
import {EntityModelRevisionRef} from "../../../../app/client/app/entity/report/model/ReportModel";
import {EntityModelRef} from "./EntityModelRef";
import {OptionalField} from "../../../../qdep/data/util";


interface ComponentProps {
    prrKey: PRRKey
}

const EntityModelWorkspaceBlock = (props: ComponentProps) => {
    const [entityModelRef, setSettings] = useState<OptionalField<EntityModelRevisionRef>>(undefined);
    useEffect(() => {
        apiClient
            .getReportRevisionEntitySettings(props.prrKey)
            .then(response => {
                if (response.errorReason === undefined) {
                    if (response.data !== null && response.data !== undefined) {
                        setSettings(response.data.entityModelRef)
                    }
                }
            })
    }, [props.prrKey])

    return <WorkspaceBlockBody>
        <EntityModelRef
            prrKey={props.prrKey}
            modelRevisionRef={entityModelRef}
            onAttach={() => {}}
        />

        {/*<FieldContainer title={"Model Name"}>*/}
        {/*    <span>todo</span>*/}
        {/*</FieldContainer>*/}
        <FieldContainer title={"Revision Name"}>
            <span>{ entityModelRef?.revisionName || "-" }</span>
        </FieldContainer>
        <FieldContainer title={"Description"}>
            <span>{ entityModelRef?.description || "-" }</span>
        </FieldContainer>
        <FieldContainer title={"Created"}>
            <span>{ entityModelRef?.date || "-" }</span>
        </FieldContainer>
    </WorkspaceBlockBody>
}

export {EntityModelWorkspaceBlock}