import React, {Dispatch} from "react";
import {AnyAction} from "redux";

import {EntityDefinitionEditorState} from "./state";
import {
    FieldContainer,
    WorkspaceBlock,
    WorkspaceBlockBody,
    WorkspaceBlockHeader
} from "../../../../qdep/components/app/workspace/Workspace";
import {IconButton, MenuItem, Select, SelectChangeEvent, TextField} from "@mui/material";
import {addEntitySelector, deleteEntitySelector, updateEntity, updateEntitySelector} from "./action";
import {Spacer} from "../../../../qdep/components/app/util/spacer/Spacer";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {EntitySelector} from "./entity-selector/EntitySelector";
import {EmptyListAddItemButton} from "../../../../qdep/components/app/util/list-container/EmptyList";
import {EntitySubItemWorkspace} from "./sub-items-block/EntitySubItemWorkspace";

import styles from './editor_body.module.css'
import {
    EntityCategory,
    entityCategoryToString, Feature
} from "../../../../app/client/app/entity/report/settings/EntityDefinition";
import {EntityOption} from "./sub-items-block/EntitySubItemEditor";
import {FeatureSetControl} from "./components/FeatureSetControl";


interface ComponentProps {
    isNew: boolean
    entityVariants: EntityOption[]
    featureOptions: Feature[]

    state: EntityDefinitionEditorState
    dispatch: Dispatch<AnyAction>
}

const EntityDefinitionEditorBody = (props: ComponentProps) => {
    return <>
        <WorkspaceBlock>
            <WorkspaceBlockHeader>
                { props.isNew ? "New Entity Definition": props.state.entity.entityName }
            </WorkspaceBlockHeader>
            <WorkspaceBlockBody>
                <FieldContainer title={"Name"}>
                    <TextField
                        className={"field-control"}
                        size={"small"}
                        value={props.state.entity.entityName}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            props.dispatch(updateEntity({entityName: event.target.value}))
                        }}
                    />
                </FieldContainer>

                <FieldContainer title={"Category"}>
                    <Select
                        className={"field-control"}
                        size={"small"}
                        value={props.state.entity.category}
                        onChange={(event: SelectChangeEvent<EntityCategory>) => {
                            props.dispatch(updateEntity({category: event.target.value as EntityCategory}))
                        }}
                    >
                        <MenuItem value={EntityCategory.INCOME_STATEMENT}>
                            { entityCategoryToString(EntityCategory.INCOME_STATEMENT) }
                        </MenuItem>
                        <MenuItem value={EntityCategory.BALANCE_SHEET}>
                            { entityCategoryToString(EntityCategory.BALANCE_SHEET) }
                        </MenuItem>
                        <MenuItem value={EntityCategory.CASH_FLOW_STATEMENT}>
                            { entityCategoryToString(EntityCategory.CASH_FLOW_STATEMENT) }
                        </MenuItem>
                        <MenuItem value={EntityCategory.ORGANISATION}>
                            { entityCategoryToString(EntityCategory.ORGANISATION) }
                        </MenuItem>
                        <MenuItem value={EntityCategory.OTHER}>
                            { entityCategoryToString(EntityCategory.OTHER) }
                        </MenuItem>
                    </Select>
                </FieldContainer>

                <FeatureSetControl
                    label={"Built-in Features"}
                    autocomplete={{
                        value: props.state.entity.builtInFeatures,
                        options: props.featureOptions,
                        onChange: (value) => props.dispatch(updateEntity({builtInFeatures: value})),
                    }}
                />
            </WorkspaceBlockBody>
        </WorkspaceBlock>

        <WorkspaceBlock>
            <WorkspaceBlockHeader classes={styles.editor_control_header}>
                <span>Selectors</span>
                <Spacer/>
                <IconButton onClick={() => props.dispatch(addEntitySelector())}>
                    <AddIcon/>
                </IconButton>
                <IconButton onClick={() => props.dispatch(deleteEntitySelector())}>
                    <DeleteIcon/>
                </IconButton>
            </WorkspaceBlockHeader>
            <WorkspaceBlockBody>
                { props.state.entity.selectors.length > 0
                    ? <div className={styles.entity_selectors_container}>
                        { props.state.entity.selectors.map((selector, index) =>
                            <EntitySelector
                                key={`selector-${index}`}
                                index={index}
                                selector={selector}
                                onChange={(index, update) => props.dispatch(updateEntitySelector(index, update))}
                            />
                        )}
                    </div>
                    : <EmptyListAddItemButton onClick={() => props.dispatch(addEntitySelector())}/>
                }
            </WorkspaceBlockBody>
        </WorkspaceBlock>

        <EntitySubItemWorkspace
            entityVariants={props.entityVariants}
            featureOptions={props.featureOptions}

            entityName={props.state.entity.entityName}
            variants={props.state.entity.variants}
            state={props.state.entityTree}

            dispatch={props.dispatch}
        />
    </>
}

export {EntityDefinitionEditorBody}