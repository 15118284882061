import React, {Dispatch} from "react";
import {AnyAction} from "redux";

import {TextField} from "@mui/material";

import {
    FieldContainer,
    WorkspaceBlock,
    WorkspaceBlockBody,
    WorkspaceBlockHeader
} from "../../../../qdep/components/app/workspace/Workspace";
import {
    addPreposition, addValue,
    deletePreposition, deleteValue,
    updateFeature
} from "./action";
import {EnumController} from "./enum-controller/EnumController";
import {
    FeatureValueSelectorsBlock
} from "./feature-selector/FeatureValueSelectorsBlock";
import {FeatureDefinitionEditorState} from "./state";



interface ComponentProps {
    isNew: boolean

    state: FeatureDefinitionEditorState
    dispatch: Dispatch<AnyAction>
}

const FeatureDefinitionEditorBody = (props: ComponentProps) => {
    return <>
        <WorkspaceBlock>
            <WorkspaceBlockHeader>
                { props.isNew ? "New Feature Definition": props.state.featureName }
            </WorkspaceBlockHeader>
            <WorkspaceBlockBody>
                <FieldContainer title={"Name"}>
                    <TextField
                        className={"field-control"}
                        size={"small"}
                        value={props.state.featureName}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            props.dispatch(updateFeature({featureName: event.target.value}))
                        }}
                    />
                </FieldContainer>
                <FieldContainer title={"Prepositions"}>
                    <EnumController
                        dialogTitle={"Add Preposition"}
                        values={props.state.prepositions}
                        add={value => props.dispatch(addPreposition(value))}
                        delete={index => props.dispatch(deletePreposition(index))}
                    />
                </FieldContainer>
                <FieldContainer title={"Values"}>
                    <EnumController
                        dialogTitle={"Add Feature Value"}
                        values={props.state.values.map(value => value.name)}
                        add={value => props.dispatch(addValue(value))}
                        delete={index => props.dispatch(deleteValue(index))}
                    />
                </FieldContainer>
            </WorkspaceBlockBody>
        </WorkspaceBlock>

        { props.state.values.map(value =>
            <FeatureValueSelectorsBlock key={value.name} {...value} dispatch={props.dispatch}/>
        )}
    </>
}

export {FeatureDefinitionEditorBody}