import React from "react";
import {Link} from "react-router-dom";

import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded';
import FolderOpenRoundedIcon from '@mui/icons-material/FolderOpenRounded';
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import StorageIcon from '@mui/icons-material/Storage';
import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded';

import {WorkspaceItemTypes, workspaceItemTypesToSlug} from "./state";
import {PRRKey} from "../../../app/client/app/entity/Project";

import styles from './side_bar.module.css'


interface ComponentProps {
    prrKey: PRRKey
    currentWorkspaceType: WorkspaceItemTypes
    onChangeWorkspaceType: (type: WorkspaceItemTypes) => void
}

const ReportOverviewSideBar = (props: ComponentProps) => {
    return <div className={styles.container}>
        <ul className={styles.menu_container}>
            <MenuItemContainer
                prrKey={props.prrKey}
                icon={<ViewListRoundedIcon/>}
                title={"Stages"}
                type={WorkspaceItemTypes.STAGES}
                currentType={props.currentWorkspaceType}
                onClick={props.onChangeWorkspaceType}
            />
            <MenuItemContainer
                prrKey={props.prrKey}
                icon={<ErrorOutlineRoundedIcon/>}
                title={"Issues"}
                type={WorkspaceItemTypes.ISSUES}
                currentType={props.currentWorkspaceType}
                onClick={props.onChangeWorkspaceType}
            />
            <MenuItemContainer
                prrKey={props.prrKey}
                icon={<TuneRoundedIcon/>}
                title={"Analysis Settings"}
                type={WorkspaceItemTypes.ANALYSIS_SETTINGS}
                currentType={props.currentWorkspaceType}
                onClick={props.onChangeWorkspaceType}
            />
            <MenuItemContainer
                prrKey={props.prrKey}
                icon={<StorageIcon/>}
                title={"Entity Model"}
                type={WorkspaceItemTypes.ENTITY_MODEL}
                currentType={props.currentWorkspaceType}
                onClick={props.onChangeWorkspaceType}
            />
            <MenuItemContainer
                prrKey={props.prrKey}
                icon={<ModeEditOutlineRoundedIcon/>}
                title={"Modification"}
                type={WorkspaceItemTypes.MODIFICATION}
                currentType={props.currentWorkspaceType}
                onClick={props.onChangeWorkspaceType}
            />
            <MenuItemContainer
                prrKey={props.prrKey}
                icon={<AccountTreeRoundedIcon/>}
                title={"Table of contents"}
                type={WorkspaceItemTypes.TOC}
                currentType={props.currentWorkspaceType}
                onClick={props.onChangeWorkspaceType}
            />
            <MenuItemContainer
                prrKey={props.prrKey}
                icon={<FolderOpenRoundedIcon/>}
                title={"Files"}
                type={WorkspaceItemTypes.FILES}
                currentType={props.currentWorkspaceType}
                onClick={props.onChangeWorkspaceType}
            />
        </ul>
    </div>
}

interface MenuItemContainerProps {
    prrKey: PRRKey
    title: string
    type: WorkspaceItemTypes
    currentType: WorkspaceItemTypes
    icon: React.ReactNode
    onClick: (type: WorkspaceItemTypes) => void
}

const MenuItemContainer = (props: MenuItemContainerProps) => {
    return <li>
        <Link to={`/report/${props.prrKey.projectId}/${props.prrKey.reportIndex}/${props.prrKey.revisionIndex}/${workspaceItemTypesToSlug(props.type)}`}>
            <div
                className={styles.menu_item_container}
                onClick={() => props.onClick(props.type)}
                data-selected={props.type === props.currentType}
            >
                <span className={styles.icon}>
                    { props.icon }
                </span>
                { props.title }
            </div>
        </Link>
    </li>
}

export {ReportOverviewSideBar}