import {AnyAction} from "redux";

import {EntitySettings} from "../../../../../app/client/app/entity/report/settings/EntitySettings";
import {FeatureFlagDefinition} from "../../../../../app/client/app/entity/report/settings/FeatureDefinition";
import {EntityDefinition} from "../../../../../app/client/app/entity/report/settings/EntityDefinition";
import {Rp4PageMenuItem} from "../../../../../app/client/app/entity/Rp4Report";


enum ActionTypes {
    SET_ENTITY_SETTINGS = "SET_ENTITY_SETTINGS",
    SELECT_DEFAULT      = "SELECT_DEFAULT",

    // entity definition
    SELECT_ENTITY_DEFINITION        = "SELECT_ENTITY_DEFINITION",
    SELECT_ENTITY_DEFINITION_BY_ID  = "SELECT_ENTITY_DEFINITION_BY_ID",
    ADD_ENTITY_DEFINITION           = "ADD_ENTITY_DEFINITION",
    DELETE_ENTITY_DEFINITION        = "DELETE_ENTITY_DEFINITION",
    APPLY_ENTITY_DEFINITION_CHANGES = "APPLY_ENTITY_DEFINITION_CHANGES",
    DISABLE_ENTITY_DEFINITION       = "DISABLE_ENTITY_DEFINITION",

    // feature definition
    ADD_FEATURE_DEFINITION           = "ADD_FEATURE_DEFINITION",
    SELECT_FEATURE_DEFINITION        = "SELECT_FEATURE_DEFINITION",
    SELECT_FEATURE_DEFINITION_BY_ID  = "SELECT_FEATURE_DEFINITION_BY_ID",
    DELETE_FEATURE_DEFINITION        = "DELETE_FEATURE_DEFINITION",
    APPLY_FEATURE_DEFINITION_CHANGES = "APPLY_FEATURE_DEFINITION_CHANGES",
    DISABLE_FEATURE_DEFINITION       = "DISABLE_FEATURE_DEFINITION",

    // pages
    SET_PAGES_MENU     = "SET_PAGES_MENU",
    SELECT_PAGE        = "SELECT_PAGE",
    SELECT_PAGE_BY_NUM = "SELECT_PAGE_BY_NUM",
}

function setEntitySettings(settings: EntitySettings): AnyAction {
    return {
        type: ActionTypes.SET_ENTITY_SETTINGS,
        settings: settings,
    }
}

function selectDefault(): AnyAction {
    return {type: ActionTypes.SELECT_DEFAULT}
}

// region entity definition
function selectEntityDefinition(index: number | undefined): AnyAction {
    return {
        type: ActionTypes.SELECT_ENTITY_DEFINITION,
        index: index,
    }
}

function selectEntityDefinitionById(id: string): AnyAction {
    return {type: ActionTypes.SELECT_ENTITY_DEFINITION_BY_ID, id}
}

function addEntityDefinition(): AnyAction {
    return {
        type: ActionTypes.ADD_ENTITY_DEFINITION,
    }
}

function deleteEntityDefinition(): AnyAction {
    return {
        type: ActionTypes.DELETE_ENTITY_DEFINITION,
    }
}

function applyEntityDefinitionChanges(entityDefinition: EntityDefinition): AnyAction {
    return {
        type: ActionTypes.APPLY_ENTITY_DEFINITION_CHANGES,
        entityDefinition: entityDefinition,
    }
}

function disableEntityDefinition(disable: boolean): AnyAction {
    return {type: ActionTypes.DISABLE_ENTITY_DEFINITION, disable: disable}
}
// endregion

// region feature definition
function addFeatureDefinition(): AnyAction {
    return {type: ActionTypes.ADD_FEATURE_DEFINITION}
}

function selectFeatureDefinition(index: number | undefined): AnyAction {
    return {type: ActionTypes.SELECT_FEATURE_DEFINITION, index: index}
}

function selectFeatureDefinitionById(id: string): AnyAction {
    return {type: ActionTypes.SELECT_FEATURE_DEFINITION_BY_ID, id}
}

function deleteFeatureDefinition(): AnyAction {
    return {
        type: ActionTypes.DELETE_FEATURE_DEFINITION,
    }
}

function applyFeatureDefinitionChanges(featureDefinition: FeatureFlagDefinition): AnyAction {
    return {
        type: ActionTypes.APPLY_FEATURE_DEFINITION_CHANGES,
        featureDefinition: featureDefinition,
    }
}

function disableFeatureDefinition(disable: boolean): AnyAction {
    return {type: ActionTypes.DISABLE_FEATURE_DEFINITION, disable: disable}
}
// endregion

// region Pages
function setPagesMenu(pages: Rp4PageMenuItem[]): AnyAction {
    return {type: ActionTypes.SET_PAGES_MENU, pages: pages}
}

function selectPage(index: number | undefined): AnyAction {
    return {type: ActionTypes.SELECT_PAGE, index: index}
}

function selectPageByNum(num: number): AnyAction {
    return {type: ActionTypes.SELECT_PAGE_BY_NUM, num}
}
// endregion

export {
    ActionTypes,
    setEntitySettings, selectDefault,
    selectEntityDefinition, selectEntityDefinitionById,
    addEntityDefinition, deleteEntityDefinition, applyEntityDefinitionChanges, disableEntityDefinition,
    selectFeatureDefinition, selectFeatureDefinitionById,
    addFeatureDefinition, deleteFeatureDefinition, applyFeatureDefinitionChanges, disableFeatureDefinition,
    setPagesMenu, selectPage, selectPageByNum,
};
