import {AnyAction} from "redux";
import produce from "immer";
import {WritableDraft} from "immer/dist/types/types-external";
import _ from "lodash";

import {FeatureDefinitionEditorState, FeatureValueSelectorState} from "./state";
import {FeatureFlagDefinition} from "../../../../app/client/app/entity/report/settings/FeatureDefinition";
import {SelectableItem} from "../../../../qdep/data/util";
import {ActionTypes} from "./action";


function featureEditorReducer(state: FeatureDefinitionEditorState, action: AnyAction): FeatureDefinitionEditorState {
    return produce(state, draft => {
        switch (action.type) {
            case ActionTypes.SET_FEATURE:
                setFeatureReducer(draft, action)
                break
            case ActionTypes.UPDATE_FEATURE:
                updateFeatureReducer(draft, action)
                break
            case ActionTypes.ADD_PREPOSITION:
                addPrepositionReducer(draft, action)
                break
            case ActionTypes.DELETE_PREPOSITION:
                deletePrepositionReducer(draft, action)
                break

            case ActionTypes.ADD_VALUE:
                addValueReducer(draft, action)
                break
            case ActionTypes.DELETE_VALUE:
                deleteValueReducer(draft, action)
                break

            case ActionTypes.ADD_VALUE_SELECTOR:
                addValueSelectorReducer(draft, action)
                break
            case ActionTypes.UPDATE_VALUE_SELECTOR:
                updateValueSelectorReducer(draft, action)
                break
            case ActionTypes.DELETE_VALUE_SELECTOR:
                deleteValueSelectorReducer(draft, action)
                break
        }
        return draft
    })
}

function setFeatureReducer(state: WritableDraft<FeatureDefinitionEditorState>, action: AnyAction) {
    const feature: FeatureFlagDefinition | undefined = action.feature
    if (feature === undefined) {
        return
    }

    state.id = feature.id
    state.featureName = feature.featureFlagName
    state.prepositions = [...feature.prepositions]
    state.values = feature.values.map(value => ({
        name: value.value,
        selectors: value.selectors.map(selector => ({isSelected: false, data: {value: selector.equal}}))
    }))
}

function updateFeatureReducer(state: WritableDraft<FeatureDefinitionEditorState>, action: AnyAction) {
    const updates: Partial<FeatureDefinitionEditorState> | undefined = action.updates
    if (updates === undefined) {
        return
    }

    _.merge(state, updates)
}

function addPrepositionReducer(state: WritableDraft<FeatureDefinitionEditorState>, action: AnyAction) {
    const value: string | undefined = action.value;
    if (value === undefined) {
        return
    }

    state.prepositions.push(value)
}

function deletePrepositionReducer(state: WritableDraft<FeatureDefinitionEditorState>, action: AnyAction) {
    const index: number | undefined = action.index;
    if (index === undefined || index < 0 || index > state.prepositions.length) {
        return
    }

    state.prepositions.splice(index, 1)
}

function addValueReducer(state: WritableDraft<FeatureDefinitionEditorState>, action: AnyAction) {
    const value: string | undefined = action.value;
    if (value === undefined) {
        return
    }

    state.values.push({
        name: value,
        selectors: [],
    })
}

function deleteValueReducer(state: WritableDraft<FeatureDefinitionEditorState>, action: AnyAction) {
    const index: number | undefined = action.index;
    if (index === undefined || index < 0 || index > state.values.length) {
        return
    }

    state.values.splice(index, 1)
}

function addValueSelectorReducer(state: WritableDraft<FeatureDefinitionEditorState>, action: AnyAction) {
    const valueName: string | undefined = action.value;
    if (valueName === undefined) {
        return
    }

    const featureValue = state.values.find(item => item.name === valueName);
    if (featureValue !== undefined) {
        featureValue.selectors.push({
            isSelected: false,
            data: {value: ""},
        })
    }
}

function updateValueSelectorReducer(state: WritableDraft<FeatureDefinitionEditorState>, action: AnyAction) {
    const valueName: string | undefined = action.value;
    const selectorIndex: number | undefined = action.selectorIndex;
    const updates: Partial<SelectableItem<Partial<FeatureValueSelectorState>>> | undefined = action.updates;
    if (valueName === undefined || selectorIndex === undefined || selectorIndex < 0 || updates === undefined) {
        return
    }

    const featureValue = state.values.find(item => item.name === valueName);
    if (featureValue !== undefined && selectorIndex < featureValue.selectors.length) {
        _.merge(featureValue.selectors[selectorIndex], updates)
    }
}

function deleteValueSelectorReducer(state: WritableDraft<FeatureDefinitionEditorState>, action: AnyAction) {
    const valueName: string | undefined = action.value;
    if (valueName === undefined) {
        return
    }

    const featureValue = state.values.find(item => item.name === valueName);
    if (featureValue !== undefined) {
        featureValue.selectors = featureValue.selectors.filter(selector => !selector.isSelected)
    }
}

export {featureEditorReducer}