import axios, {AxiosError, AxiosInstance, AxiosProgressEvent, AxiosRequestConfig, AxiosResponse} from "axios";
import moment from "moment";
import fileDownload from 'js-file-download';

import {ProjectDto, ProjectListDto, PRRKey} from "./entity/Project";
import {
    Attachment,
    parseReportListItem,
    ReportFile,
    ReportIssue,
    ReportListItem,
    ReportRevisionDto
} from "./entity/Report";
import {ProjectUpdates} from "./entity/ProjectUpdate";
import {ApiClientProps, ApiResponse, ErrorReason} from "../types";
import {Rp3MenuItem, Rp3Table} from "./entity/Rp3Report";
import {Rp5MenuItem, Rp5Table} from "./entity/Rp5Report";
import {
    ModificationRule,
    ModificationRuleData,
    ModificationRuleUpdate,
    ReportModificationRuleUpdateEvent
} from "./entity/report/ModificationRule";
import {parseStageStatus, Stage, stageSlug} from "./entity/report/Stage";
import {
    AnalysisSettings,
    AnalysisSettingsDto,
    mapAnalysisSettingsDtoToValue,
    mapAnalysisSettingsValueToDto,
} from "./entity/report/settings/AnalysisSettings";
import {ReportOcrMenuItem, ReportOcrTable} from "./entity/Rp1Report";
import {Rp6MenuItem, Rp6Table} from "./entity/Rp6Report";
import {ReportStatusResult, ReportStatusResultWithEntity} from "./entity/ops/ReportStatusResult";
import {defaultEntitySettings, EntitySettings} from "./entity/report/settings/EntitySettings";
import {FeatureFlagDefinition} from "./entity/report/settings/FeatureDefinition";
import {EntityDefinition} from "./entity/report/settings/EntityDefinition";
import {
    EntitiesModelRevision,
    EntityModelRevisionRef,
    ModelRefDto,
    ModelRevisionRefDto,
    parseReportModel,
    ReportModel
} from "./entity/report/model/ReportModel";
import {
    CopyReportEntitiesRequestDto,
    CopyReportEntitiesToModelRequest,
    ReportModelDto
} from "./entity/report/model/ReportModelDto";
import {Rp4PageMenuItem, Rp4Table} from "./entity/Rp4Report";
import {
    Rp7CoverageTable,
    Rp7OverviewCoverageData,
    Rp7OverviewIssueData,
    Rp7PageCoverageMenuItem
} from "./entity/Rp7Report";
import {OpsResultWithData} from "./entity/ops/OpsResult";
import {ReportContents} from "./entity/ReportContents";
import {ResultReportOptions, TableOperation} from "./entity/ops/ops";


class ApiClient {
    readonly client: AxiosInstance
    private readonly tKey = "5e73c922077b"

    constructor(client: AxiosInstance) {
        this.client = client

        this.setAuthToken    = this.setAuthToken.bind(this)
        this.unsetAuthToken  = this.unsetAuthToken.bind(this)
        this.loadAuthToken   = this.loadAuthToken.bind(this)
        this.saveAuthToken   = this.saveAuthToken.bind(this)
        this.removeAuthToken = this.removeAuthToken.bind(this)

        this.getError         = this.getError.bind(this)
        this.getResponseError = this.getResponseError.bind(this)

        //-------------------------------------------------------------------------------------
        this.projectGet    = this.projectGet.bind(this)
        this.projectList   = this.projectList.bind(this)
        this.projectCreate = this.projectCreate.bind(this)
        this.projectUpdate = this.projectUpdate.bind(this)
        this.projectDelete = this.projectDelete.bind(this)

        this.projectReportList = this.projectReportList.bind(this)

        this.downloadSourceReportFile = this.downloadSourceReportFile.bind(this)
        this.reportFileUpload = this.reportFileUpload.bind(this)
        this.reportFileList   = this.reportFileList.bind(this)

        this.getReportRevision         = this.getReportRevision.bind(this)
        this.runReportRevisionAnalyse  = this.runReportRevisionAnalyse.bind(this)
        this.updateReportCustomRule    = this.updateReportCustomRule.bind(this)
        this.listModificationRules       = this.listModificationRules.bind(this)
        this.saveReportModificationRules = this.saveReportModificationRules.bind(this)
        this.getTableById                = this.getTableById.bind(this)
        this.downloadAttachment          = this.downloadAttachment.bind(this)
        this.listAttachments             = this.listAttachments.bind(this)
        this.generateTickReport          = this.generateTickReport.bind(this)

        this.getReportRevisionSettings    = this.getReportRevisionSettings.bind(this)
        this.updateReportRevisionSettings = this.updateReportRevisionSettings.bind(this)
        this.resetReportSettings          = this.resetReportSettings.bind(this)

        this.listModelRefs             = this.listModelRefs.bind(this)
        this.getModelById              = this.getModelById.bind(this)
        this.getEntityModelRevision    = this.getEntityModelRevision.bind(this)
        this.addEntityModelRevision    = this.addEntityModelRevision.bind(this)
        this.copyReportEntitiesToModel = this.copyReportEntitiesToModel.bind(this)
        this.copyReportEntities        = this.copyReportEntities.bind(this)
        this.deleteEntityModelRevision = this.deleteEntityModelRevision.bind(this)
        this.saveModel                 = this.saveModel.bind(this)
        this.listAnalysisSettingsRefs  = this.listAnalysisSettingsRefs.bind(this)
        this.listEntityModelRefs       = this.listEntityModelRefs.bind(this)
        this.attachAnalysisSettingsRef = this.attachAnalysisSettingsRef.bind(this)
        this.attachEntityModelRef      = this.attachEntityModelRef.bind(this)

        this.getReportRevisionEntitySettings = this.getReportRevisionEntitySettings.bind(this)

        this.createReportEntityDefinition  = this.createReportEntityDefinition.bind(this)
        this.updateReportEntityDefinition  = this.updateReportEntityDefinition.bind(this)
        this.deleteReportEntityDefinition  = this.deleteReportEntityDefinition.bind(this)
        this.disableReportEntityDefinition = this.disableReportEntityDefinition.bind(this)

        this.createReportFeatureDefinition  = this.createReportFeatureDefinition.bind(this)
        this.updateReportFeatureDefinition  = this.updateReportFeatureDefinition.bind(this)
        this.deleteReportFeatureDefinition  = this.deleteReportFeatureDefinition.bind(this)
        this.disableReportFeatureDefinition = this.disableReportFeatureDefinition.bind(this)

        //-------------------------------------------------------------------------------------
        this.listReportIssue = this.listReportIssue.bind(this)
        this.getReportContents  = this.getReportContents.bind(this)

        this.getReportOcrMenuItems  = this.getReportOcrMenuItems.bind(this)
        this.getReportOcrPageTables = this.getReportOcrPageTables.bind(this)

        this.getReportDataNormalizationMenuItems  = this.getReportDataNormalizationMenuItems.bind(this)
        this.getReportDataNormalizationPageTables = this.getReportDataNormalizationPageTables.bind(this)
        this.rp2EditTable = this.rp2EditTable.bind(this)

        this.getRp4Pages          = this.getRp4Pages.bind(this)
        this.getRp4ListPageTables = this.getRp4ListPageTables.bind(this)

        this.getReportTableAnalysisMenuItems  = this.getReportTableAnalysisMenuItems.bind(this)
        this.getReportTableAnalysisPageTables = this.getReportTableAnalysisPageTables.bind(this)

        this.getReportCrossAnalysisMenuItems  = this.getReportCrossAnalysisMenuItems.bind(this)
        this.rp6ListPageTables  = this.rp6ListPageTables.bind(this)

        this.rp7ListCoverageMenuItems   = this.rp7ListCoverageMenuItems.bind(this)
        this.rp7ListPageCoverageTables  = this.rp7ListPageCoverageTables.bind(this)
        this.rp7GetOverviewCoverageData = this.rp7GetOverviewCoverageData.bind(this)
        this.rp7GetOverviewIssueData    = this.rp7GetOverviewIssueData.bind(this)

        const token = this.loadAuthToken();
        if (token !== null) {
            this.setAuthToken(token)
        }
    }

    setAuthToken(token: string) {
        this.client.defaults.headers.common["Token"] = token
    }

    unsetAuthToken() {
        delete this.client.defaults.headers.common["Token"]
        this.removeAuthToken()
    }

    loadAuthToken(): string | null {
        return localStorage.getItem("5e73c922077b")
    }

    saveAuthToken(token: string) {
        localStorage.setItem("5e73c922077b", token)
    }

    removeAuthToken() {
        localStorage.removeItem(this.tKey)
    }

    // region Project
    projectGet(id: string): Promise<ApiResponse<ProjectDto>> {
        return this.client
            .get(`/v1alpha3/project/${id}`)
            .then((response: AxiosResponse): ApiResponse<ProjectDto> => {
                if (response.status === 200) {
                    const item = response.data
                    return {
                        data: this.mapProjectDto(item)
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    projectList(): Promise<ApiResponse<ProjectListDto[]>> {
        return this.client
            .get("/v1alpha3/project")
            .then((response: AxiosResponse): ApiResponse<ProjectListDto[]> => {
                if (response.status === 200) {
                    return {
                        data: (response.data as Array<Record<string, any>>).map(item => ({
                            id: item.id,
                            name: item.name,
                            description: item.description,

                            status: item.status,
                            reports: item.reports,

                            createDate: moment.utc(item.createDate, 'YYYY-MM-DDThh:mm:ss.SSSZ'),
                            updateDate: moment.utc(item.updateDate, 'YYYY-MM-DDThh:mm:ss.SSSZ'),
                        }))
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    projectCreate(entity: ProjectDto): Promise<ApiResponse<ProjectDto>> {
        const mapProject = (entity: ProjectDto) => {
            return {
                name: entity.name,
                description: entity.description,
                roles: entity.roles,
                reports: entity.reports.map(report => {
                    const createReportRequest: any = {
                        index: report.index,
                        name: report.name,
                        description: report.description,
                        periodType: report.periodType,
                        periodValue: report.periodValue,
                        filename: null,
                        modelId: null,
                        modelRevisionId: null,
                    };

                    if (report.revisions.length === 1 && report.revisions[0].filename !== "") {
                        createReportRequest.filename = report.revisions[0].filename
                        createReportRequest.modelId = report.revisions[0].modelId
                        createReportRequest.modelRevisionId = report.revisions[0].modelRevisionId
                    }

                    return createReportRequest;
                })
            }
        }
        return this.client
            .post("/v1alpha3/project", mapProject(entity))
            .then((response: AxiosResponse): ApiResponse<ProjectDto> => {
                if (response.status === 200) {
                    const item = response.data
                    return {
                        data: this.mapProjectDto(item)
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    projectUpdate(projectId: string, updates: ProjectUpdates): Promise<ApiResponse<string>> {
        return this.client
            .post(`/v1alpha3/project/${projectId}`, updates)
            .then((response: AxiosResponse): ApiResponse<string> => {
                if (response.status === 200) {
                    return {
                        data: "ok"
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    projectDelete(projectId: string): Promise<ApiResponse<boolean>> {
        return this.client
            .delete(`/v1alpha3/project/${projectId}`)
            .then((response: AxiosResponse): ApiResponse<boolean> => {
                if (response.status === 200) {
                    return {
                        data: response.data.count === 1
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }
    // endregion

//-------------------------------------------------------------------------------------
    projectReportList(): Promise<ApiResponse<ReportListItem[]>> {
        return this.client
            .get("/v1alpha3/project/report")
            .then((response: AxiosResponse): ApiResponse<ReportListItem[]> => {
                if (response.status === 200) {
                    return {
                        data: (response.data as Array<Record<string, any>>).map(parseReportListItem)
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

//-------------------------------------------------------------------------------------
    reportFileUpload(
        revision: RevisionFilePayload,
        progressCallback?: (progress: number) => void,
    ): Promise<ApiResponse<string>> {
        let formData = new FormData();
        formData.append("file", revision.file)
        formData.append("path", revision.path)
        formData.append("revision_index", revision.revision_index.toString())

        const config: AxiosRequestConfig<FormData> = {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        };
        if (progressCallback !== undefined) {
            config.onUploadProgress = (event: AxiosProgressEvent) => {
                if (event.progress !== undefined) {
                    progressCallback(event.progress);
                }
            }
        }

        return this.client
            .post(`/v1alpha3/project/${revision.project_id}/${revision.report_index}/file/upload`,
                formData,
                config
            )
            .then((response: AxiosResponse): ApiResponse<string> => {
                if (response.status === 200) {
                    return {
                        data: "ok"
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError);
    }

    downloadSourceReportFile(prrKey: PRRKey): Promise<void> {
        return this.client
            .get(`/v1alpha3/report/${prrKey.projectId}/${prrKey.reportIndex}/${prrKey.revisionIndex}/source-file`,
                {
                    responseType: 'blob', // important
                }
            ).then((response) => {
                fileDownload(response.data, `${prrKey.projectId}-${prrKey.reportIndex}-${prrKey.revisionIndex}.pdf`);
            })
    }

    downloadAttachment(prrKey: PRRKey, attachmentId: string): Promise<void> {
        return this.client
            .get(`/v1alpha3/report/${prrKey.projectId}/${prrKey.reportIndex}/${prrKey.revisionIndex}/attachment/${attachmentId}`,
                {
                    responseType: 'blob', // important
                }
            ).then((response) => {
                fileDownload(response.data, `${prrKey.projectId}_${prrKey.reportIndex}_${prrKey.revisionIndex}_${attachmentId}.pdf`);
            })
    }

    listAttachments(prrKey: PRRKey): Promise<ApiResponse<Attachment[]>> {
        return this.client
            .get(`/v1alpha3/report/${prrKey.projectId}/${prrKey.reportIndex}/${prrKey.revisionIndex}/attachment`,)
            .then((response: AxiosResponse): ApiResponse<Attachment[]> => {
                if (response.status === 200) {
                    return {
                        data: (response.data as Array<Record<string, any>>).map(item => ({
                            fileId: item.fileId,
                            createDate: moment.utc(item.createDate, 'YYYY-MM-DDThh:mm:ss.SSSZ'),
                            type: item.type,
                            tags: item.tags || [],
                        }))
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    generateTickReport(prrKey: PRRKey, options: ResultReportOptions): Promise<ApiResponse<OpsResultWithData<Attachment>>> {
        return this.client
            .post(`/v1alpha3/report/${prrKey.projectId}/${prrKey.reportIndex}/${prrKey.revisionIndex}/tick-report`,
                options,
            )
            .then((response: AxiosResponse): ApiResponse<OpsResultWithData<Attachment>> => {
                if (response.status === 200) {
                    return {
                        data: {
                            status: response.data.status,
                            data: {
                                fileId: response.data.data.fileId,
                                createDate: moment.utc(response.data.data.createDate, 'YYYY-MM-DDThh:mm:ss.SSSZ'),
                                type: response.data.data.type,
                                tags: response.data.data.tags || [],
                            }
                        }
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    reportFileList(): Promise<ApiResponse<ReportFile[]>> {
        return this.client
            .get("/v1alpha3/report/file")
            .then((response: AxiosResponse): ApiResponse<ReportFile[]> => {
                if (response.status === 200) {
                    return {
                        data: (response.data as Array<Record<string, any>>).map(item => ({
                            path: item.path,
                            type: item.type,
                            status: item.status,
                            size: item.size,
                            issues: item.issues,
                            createDate: moment.utc(item.createDate, 'YYYY-MM-DDThh:mm:ss.SSSZ'),
                        }))
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

//-------------------------------------------------------------------------------------
    getReportRevision(prrKey: PRRKey): Promise<ApiResponse<ReportRevisionDto>> {
        return this.client
            .get(`/v1alpha3/report/${prrKey.projectId}/${prrKey.reportIndex}/${prrKey.revisionIndex}`)
            .then((response: AxiosResponse): ApiResponse<ReportRevisionDto> => {
                if (response.status === 200) {
                    const data = response.data;
                    return {
                        data: {
                            projectId: data.projectId,
                            reportIndex: data.reportIndex,
                            revisionIndex: data.revisionIndex,
                            reportName: data.reportName,
                            status: data.status,
                            analysis: {
                                stages: {
                                    ocr: {
                                        ...parseStageStatus(data.analysis.stages.ocr),
                                        jobId: data.analysis.stages.ocr?.jobId,
                                        tables: data.analysis.stages.ocr?.tables,
                                    },
                                    normalization: {
                                        ...parseStageStatus(data.analysis.stages.normalization),
                                        tables: data.analysis.stages.normalization?.tables,
                                        filteredTables: data.analysis.stages.normalization?.filteredTables,
                                    },
                                    entityDetection: {
                                        ...parseStageStatus(data.analysis.stages.entityDetection),
                                        entities: data.analysis.stages.entityDetection?.entities,
                                    },
                                    tableAnalysis: {
                                        ...parseStageStatus(data.analysis.stages.tableAnalysis),
                                        rules: data.analysis.stages.tableAnalysis?.rules,
                                        issues: data.analysis.stages.tableAnalysis?.issues,
                                    },
                                    tableRefs: {
                                        ...parseStageStatus(data.analysis.stages.tableRefs),
                                        refs: data.analysis.stages.tableRefs?.refs,
                                        unresolvedRefs: data.analysis.stages.tableRefs?.unresolvedRefs,
                                    },
                                    summary: {
                                        ...parseStageStatus(data.analysis.stages.summary),
                                    }
                                }
                            },
                        },
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    updateReportCustomRule(prrKey: PRRKey, stage: Stage, updates: ModificationRuleUpdate<any>[]): Promise<ApiResponse<ReportStatusResult>> {
        return this.client
            .post(
                `/v1alpha2/report/${stageSlug(stage)}/${prrKey.projectId}/${prrKey.reportIndex}/${prrKey.revisionIndex}/custom-rules`,
                updates,
            )
            .then((response: AxiosResponse): ApiResponse<ReportStatusResult> => {
                if (response.status === 200) {
                    return {
                        data: response.data,
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    listModificationRules(prrKey: PRRKey): Promise<ApiResponse<ModificationRule[]>> {
        return this.client
            .get(`/v1alpha2/report/${prrKey.projectId}/${prrKey.reportIndex}/${prrKey.revisionIndex}/analyse/modification-rules`)
            .then((response: AxiosResponse): ApiResponse<ModificationRule[]> => {
                if (response.status === 200) {
                    return {
                        data: response.data,
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError);
    }

    saveReportModificationRules(prrKey: PRRKey, updates: ReportModificationRuleUpdateEvent[]): Promise<ApiResponse<ReportStatusResult>> {
        return this.client
            .post(
                `/v1alpha2/report/${prrKey.projectId}/${prrKey.reportIndex}/${prrKey.revisionIndex}/analyse/modification-rules`,
                updates,
            )
            .then((response: AxiosResponse): ApiResponse<ReportStatusResult> => {
                if (response.status === 200) {
                    return {
                        data: response.data,
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    getTableById(tableId: string): Promise<ApiResponse<Rp3Table | null>> {
        return this.client
            .get(`/v1alpha3/report/table/${tableId}`)
            .then(response => {
                if (response.status === 200) {
                    return {
                        data: response.data
                    }
                }
                return {
                    errorReason: this.getResponseError(response),
                }
            })
            .catch(this.getError)
    }


    runReportRevisionAnalyse(prrKey: PRRKey, force?: boolean): Promise<ApiResponse<ReportStatusResult>> {
        return this.client
            .post(
                `/v1alpha3/report/${prrKey.projectId}/${prrKey.reportIndex}/${prrKey.revisionIndex}/analyse`,
                undefined,
                {
                    params: {
                        force: force || false,
                    },
                },
            )
            .then((response: AxiosResponse): ApiResponse<ReportStatusResult> => {
                if (response.status === 200) {
                    return {
                        data: response.data,
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }
//--- report_model --------------------------------------------------------------------
    listModelRefs(): Promise<ApiResponse<ModelRefDto[]>> {
        return this.client
            .get(`/v1alpha2/report/model`)
            .then((response: AxiosResponse): ApiResponse<ModelRefDto[]> => {
                if (response.status === 200) {
                    return {
                        data: response.data,
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    getModelById(modelId: string): Promise<ApiResponse<ReportModel>> {
        return this.client
            .get(`/v1alpha2/report/model/${modelId}`)
            .then((response: AxiosResponse): ApiResponse<ReportModel> => {
                const data: Record<string, any> | undefined = response.data;
                if (response.status === 200 && !!data) {
                    return {
                        data: parseReportModel(data),
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    getEntityModelRevision(modelId: string, revisionId: string): Promise<ApiResponse<EntitiesModelRevision>> {
        return this.client
            .get(`/v1alpha2/report/model/${modelId}/entity/${revisionId}`)
            .then((response: AxiosResponse): ApiResponse<EntitiesModelRevision> => {
                if (response.status === 200 && !!response.data) {
                    return {
                        data: response.data,
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    addEntityModelRevision(modelId: string, revision: EntitiesModelRevision): Promise<ApiResponse<EntitiesModelRevision>> {
        return this.client
            .post(
                `/v1alpha2/report/model/${modelId}/entity`,
                revision,
            )
            .then((response: AxiosResponse): ApiResponse<EntitiesModelRevision> => {
                if (response.status === 200 && !!response.data) {
                    return {
                        data: response.data,
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    copyReportEntitiesToModel(requestBody: CopyReportEntitiesToModelRequest): Promise<ApiResponse<OpsResultWithData<EntityModelRevisionRef>>> {
        return this.client
            .post(
                `/v1alpha2/report/model/copy`,
                requestBody,
            )
            .then((response: AxiosResponse): ApiResponse<OpsResultWithData<EntityModelRevisionRef>> => {
                if (response.status === 200 && !!response.data) {
                    return {
                        data: response.data,
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    copyReportEntities(modelId: string, requestBody: CopyReportEntitiesRequestDto): Promise<ApiResponse<OpsResultWithData<EntityModelRevisionRef>>> {
        return this.client
            .post(
                `/v1alpha2/report/model/${modelId}/entity/copy`,
                requestBody,
            )
            .then((response: AxiosResponse): ApiResponse<OpsResultWithData<EntityModelRevisionRef>> => {
                if (response.status === 200 && !!response.data) {
                    return {
                        data: response.data,
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    deleteEntityModelRevision(modelId: string, revisionId: string): Promise<ApiResponse<boolean>> {
        return this.client
            .delete(`/v1alpha2/report/model/${modelId}/entity/${revisionId}`)
            .then((response: AxiosResponse): ApiResponse<boolean> => {
                if (response.status === 200 && !!response.data) {
                    return {
                        data: response.data === "ok",
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }


    saveModel(model: ReportModel): Promise<ApiResponse<ReportModel>> {
        return this.client
            .post<any, AxiosResponse, ReportModelDto>(
                `/v1alpha2/report/model`,
                {
                    id: model.id,
                    name: model.name,
                    description: model.description,
                    analysisSettingsRevisions: model.analysisSettingsRevisions
                        .map(revision => ({
                            revisionId: revision.revisionId,
                            date: revision.date,
                            settings: mapAnalysisSettingsValueToDto(revision.settings),
                        })),
                },
            )
            .then((response: AxiosResponse): ApiResponse<ReportModel> => {
                const data: Record<string, any> | undefined = response.data;
                if (response.status === 200 && !!data) {
                    return {
                        data: parseReportModel(data),
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    listAnalysisSettingsRefs(): Promise<ApiResponse<ModelRevisionRefDto[]>> {
        return this.client
            .get(`/v1alpha2/report/model/analysis`)
            .then((response: AxiosResponse): ApiResponse<ModelRevisionRefDto[]> => {
                if (response.status === 200) {
                    return {
                        data: response.data,
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    listEntityModelRefs(): Promise<ApiResponse<EntityModelRevisionRef[]>> {
        return this.client
            .get(`/v1alpha2/report/model/entity`)
            .then((response: AxiosResponse): ApiResponse<EntityModelRevisionRef[]> => {
                if (response.status === 200) {
                    return {
                        data: response.data,
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    attachAnalysisSettingsRef(prrKey: PRRKey, modelId: string, revisionId: string): Promise<ApiResponse<ReportStatusResult>> {
        return this.client
            .post(
                "/v1alpha2/report/model/analysis/attach",
                {
                    projectId: prrKey.projectId,
                    report: prrKey.reportIndex,
                    revision: prrKey.revisionIndex,

                    modelId: modelId,
                    revisionId: revisionId,
                },
            )
            .then((response: AxiosResponse): ApiResponse<ReportStatusResult> => {
                if (response.status === 200) {
                    return {
                        data: response.data,
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    attachEntityModelRef(prrKey: PRRKey, modelId: string, revisionId: string): Promise<ApiResponse<ReportStatusResult>> {
        return this.client
            .post(
                "/v1alpha2/report/model/entity/attach",
                {
                    projectId: prrKey.projectId,
                    report: prrKey.reportIndex,
                    revision: prrKey.revisionIndex,

                    modelId: modelId,
                    revisionId: revisionId,
                },
            )
            .then((response: AxiosResponse): ApiResponse<ReportStatusResult> => {
                if (response.status === 200) {
                    return {
                        data: response.data,
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

//--- PRR-analysis_settings --------------------------------------------------------------------
    getReportRevisionSettings(project: string, report: string | number, revision: string | number): Promise<ApiResponse<AnalysisSettings | null>> {
        return this.client
            .get(`/v1alpha3/report/${project}/${report}/${revision}/analyse/settings`)
            .then((response: AxiosResponse): ApiResponse<AnalysisSettings | null> => {
                if (response.status === 200) {
                    const data: AnalysisSettingsDto = response.data;
                    if (data === null || data === undefined) {
                        return {
                            data: null
                        }
                    }
                    return {
                        data: mapAnalysisSettingsDtoToValue(data),
                    };
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    updateReportRevisionSettings(prrKey: PRRKey, settings: AnalysisSettings): Promise<ApiResponse<ReportStatusResult>> {
        return this.client
            .post<any, AxiosResponse, AnalysisSettingsDto>(
                `/v1alpha3/report/${prrKey.projectId}/${prrKey.reportIndex}/${prrKey.revisionIndex}/analyse/settings`,
                mapAnalysisSettingsValueToDto(settings),
            )
            .then((response: AxiosResponse): ApiResponse<ReportStatusResult> => {
                if (response.status === 200) {
                    return {
                        data: response.data,
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    resetReportSettings(prrKey: PRRKey): Promise<ApiResponse<ReportStatusResult>> {
        return this.client
            .get<any, AxiosResponse<ApiResponse<ReportStatusResult>>>(
                `/v1alpha3/report/${prrKey.projectId}/${prrKey.reportIndex}/${prrKey.revisionIndex}/analyse/settings/default`
            )
            .then((response: AxiosResponse): ApiResponse<ReportStatusResult> => {
                if (response.status === 200) {
                    return {
                        data: response.data,
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    getReportRevisionEntitySettings(prrKey: PRRKey): Promise<ApiResponse<EntitySettings>> {
        return this.client
            .get(`/v1alpha2/report/${prrKey.projectId}/${prrKey.reportIndex}/${prrKey.revisionIndex}/analyse/entity-settings`,)
            .then((response: AxiosResponse): ApiResponse<EntitySettings> => {
                if (response.status === 200) {
                    if (response.data === null || response.data === undefined) {
                        return {
                            data: defaultEntitySettings
                        }
                    }
                    return {
                        data: {
                            entityModelRef: response.data.entityModelRef,
                            entityDefinitions: response.data.entityDefinitions || defaultEntitySettings.entityDefinitions,
                            featureFlagDefinitions: response.data.featureFlagDefinitions || defaultEntitySettings.featureFlagDefinitions,
                        },
                    };
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    createReportEntityDefinition(
        prrKey: PRRKey,
        entityDefinition: EntityDefinition
    ): Promise<ApiResponse<ReportStatusResultWithEntity<EntityDefinition>>> {
        return this.client
            .post(
                `/v1alpha2/report/${prrKey.projectId}/${prrKey.reportIndex}/${prrKey.revisionIndex}/settings/entity/definition`,
                entityDefinition,
            )
            .then((response: AxiosResponse): ApiResponse<ReportStatusResultWithEntity<EntityDefinition>> => {
                if (response.status === 200) {
                    return {
                        data: response.data,
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    updateReportEntityDefinition(
        prrKey: PRRKey,
        entityDefinition: EntityDefinition
    ): Promise<ApiResponse<ReportStatusResultWithEntity<EntityDefinition>>> {
        return this.client
            .put(
                `/v1alpha2/report/${prrKey.projectId}/${prrKey.reportIndex}/${prrKey.revisionIndex}/settings/entity/definition`,
                entityDefinition,
            )
            .then((response: AxiosResponse): ApiResponse<ReportStatusResultWithEntity<EntityDefinition>> => {
                if (response.status === 200) {
                    return {
                        data: response.data,
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    deleteReportEntityDefinition(prrKey: PRRKey, entityDefinitionId: string): Promise<ApiResponse<ReportStatusResult>> {
        return this.client
            .delete(`/v1alpha2/report/${prrKey.projectId}/${prrKey.reportIndex}/${prrKey.revisionIndex}/settings/entity/definition/${entityDefinitionId}`)
            .then((response: AxiosResponse): ApiResponse<ReportStatusResult> => {
                if (response.status === 200) {
                    return {
                        data: response.data,
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    disableReportEntityDefinition(prrKey: PRRKey, entityDefinitionId: string, disable: boolean): Promise<ApiResponse<ReportStatusResult>> {
        return this.client
            .get(`/v1alpha2/report/${prrKey.projectId}/${prrKey.reportIndex}/${prrKey.revisionIndex}/settings/entity/definition/${entityDefinitionId}/${disable ? "disable": "enable"}`)
            .then((response: AxiosResponse): ApiResponse<ReportStatusResult> => {
                if (response.status === 200) {
                    return {
                        data: response.data,
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    createReportFeatureDefinition(
        prrKey: PRRKey,
        featureDefinition: FeatureFlagDefinition
    ): Promise<ApiResponse<ReportStatusResultWithEntity<FeatureFlagDefinition>>> {
        return this.client
            .post(
                `/v1alpha2/report/${prrKey.projectId}/${prrKey.reportIndex}/${prrKey.revisionIndex}/settings/entity/feature`,
                featureDefinition,
            )
            .then((response: AxiosResponse): ApiResponse<ReportStatusResultWithEntity<FeatureFlagDefinition>> => {
                if (response.status === 200) {
                    return {
                        data: response.data,
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    updateReportFeatureDefinition(
        prrKey: PRRKey,
        featureDefinition: FeatureFlagDefinition
    ): Promise<ApiResponse<ReportStatusResultWithEntity<FeatureFlagDefinition>>> {
        return this.client
            .put(
                `/v1alpha2/report/${prrKey.projectId}/${prrKey.reportIndex}/${prrKey.revisionIndex}/settings/entity/feature`,
                featureDefinition,
            )
            .then((response: AxiosResponse): ApiResponse<ReportStatusResultWithEntity<FeatureFlagDefinition>> => {
                if (response.status === 200) {
                    return {
                        data: response.data,
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    deleteReportFeatureDefinition(prrKey: PRRKey, featureDefinitionId: string): Promise<ApiResponse<ReportStatusResult>> {
        return this.client
            .delete(`/v1alpha2/report/${prrKey.projectId}/${prrKey.reportIndex}/${prrKey.revisionIndex}/settings/entity/feature/${featureDefinitionId}`)
            .then((response: AxiosResponse): ApiResponse<ReportStatusResult> => {
                if (response.status === 200) {
                    return {
                        data: response.data,
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    disableReportFeatureDefinition(prrKey: PRRKey, entityDefinitionId: string, disable: boolean): Promise<ApiResponse<ReportStatusResult>> {
        return this.client
            .get(`/v1alpha2/report/${prrKey.projectId}/${prrKey.reportIndex}/${prrKey.revisionIndex}/settings/entity/feature/${entityDefinitionId}/${disable ? "disable": "enable"}`)
            .then((response: AxiosResponse): ApiResponse<ReportStatusResult> => {
                if (response.status === 200) {
                    return {
                        data: response.data,
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

//-------------------------------------------------------------------------------------
    listReportIssue(prrKey: PRRKey): Promise<ApiResponse<ReportIssue[]>> {
        return this.client
            .get(`/v1alpha3/report/summary/issue/${prrKey.projectId}/${prrKey.reportIndex}/${prrKey.revisionIndex}/list`)
            .then((response: AxiosResponse): ApiResponse<ReportIssue[]> => {
                if (response.status === 200) {
                    return {
                        data: response.data,
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    getReportContents(prrKey: PRRKey): Promise<ApiResponse<ReportContents>> {
        return this.client
            .get(`/v1alpha3/report/${prrKey.projectId}/${prrKey.reportIndex}/${prrKey.revisionIndex}/contents`)
            .then((response: AxiosResponse): ApiResponse<ReportContents> => {
                if (response.status === 200) {
                    return {
                        data: response.data,
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    getReportOcrMenuItems(prrKey: PRRKey): Promise<ApiResponse<ReportOcrMenuItem[]>> {
        return this.client
            .get(`/v1alpha2/report/ocr/${prrKey.projectId}/${prrKey.reportIndex}/${prrKey.revisionIndex}/list`)
            .then((response: AxiosResponse): ApiResponse<ReportOcrMenuItem[]> => {
                if (response.status === 200) {
                    return {
                        data: response.data,
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    getReportOcrPageTables(prrKey: PRRKey, page: number): Promise<ApiResponse<ReportOcrTable[]>> {
        return this.client
            .get(`/v1alpha2/report/ocr/${prrKey.projectId}/${prrKey.reportIndex}/${prrKey.revisionIndex}/${page}/tables`)
            .then((response: AxiosResponse): ApiResponse<ReportOcrTable[]> => {
                if (response.status === 200) {
                    return {
                        data: response.data,
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError);
    }

    getReportDataNormalizationMenuItems(prrKey: PRRKey): Promise<ApiResponse<Rp3MenuItem[]>> {
        return this.client
            .get(`/v1alpha2/report/data-normalization/${prrKey.projectId}/${prrKey.reportIndex}/${prrKey.revisionIndex}/list`)
            .then((response: AxiosResponse): ApiResponse<Rp3MenuItem[]> => {
                if (response.status === 200) {
                    return {
                        data: response.data,
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    getReportDataNormalizationPageTables(prrKey: PRRKey, page: number): Promise<ApiResponse<Rp3Table[]>> {
        return this.client
            .get(`/v1alpha2/report/data-normalization/${prrKey.projectId}/${prrKey.reportIndex}/${prrKey.revisionIndex}/${page}/tables`,)
            .then((response: AxiosResponse): ApiResponse<Rp3Table[]> => {
                if (response.status === 200) {
                    return {
                        data: response.data
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    rp2EditTable(prrKey: PRRKey, operation: ModificationRuleData<any>): Promise<ApiResponse<ReportStatusResultWithEntity<TableOperation<Rp3Table>[]>>> {
        return this.client
            .post(
                `/v1alpha3/report/data-normalization/${prrKey.projectId}/${prrKey.reportIndex}/${prrKey.revisionIndex}/edit`,
                operation,
            )
            .then((response: AxiosResponse): ApiResponse<ReportStatusResultWithEntity<TableOperation<Rp3Table>[]>> => {
                if (response.status === 200) {
                    return {
                        data: response.data,
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    getRp4Pages(prrKey: PRRKey): Promise<ApiResponse<Rp4PageMenuItem[]>> {
        return this.client
            .get(`/v1alpha3/report/entity/${prrKey.projectId}/${prrKey.reportIndex}/${prrKey.revisionIndex}/list`,)
            .then((response: AxiosResponse): ApiResponse<Rp4PageMenuItem[]> => {
                if (response.status === 200) {
                    return {
                        data: response.data,
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    getRp4ListPageTables(prrKey: PRRKey, page: number): Promise<ApiResponse<Rp4Table[]>> {
        return this.client
            .get(`/v1alpha3/report/entity/${prrKey.projectId}/${prrKey.reportIndex}/${prrKey.revisionIndex}/${page}/tables`,)
            .then((response: AxiosResponse): ApiResponse<Rp4Table[]> => {
                if (response.status === 200) {
                    return {
                        data: response.data,
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    getReportTableAnalysisMenuItems(prrKey: PRRKey): Promise<ApiResponse<Rp5MenuItem[]>> {
        return this.client
            .get(`/v1alpha2/report/table-analysis/${prrKey.projectId}/${prrKey.reportIndex}/${prrKey.revisionIndex}/list`)
            .then((response: AxiosResponse): ApiResponse<Rp5MenuItem[]> => {
                if (response.status === 200) {
                    return {
                        data: response.data,
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    getReportTableAnalysisPageTables(prrKey: PRRKey, page: number): Promise<ApiResponse<Rp5Table[]>> {
        return this.client
            .get(`/v1alpha2/report/table-analysis/${prrKey.projectId}/${prrKey.reportIndex}/${prrKey.revisionIndex}/${page}/tables`)
            .then((response: AxiosResponse): ApiResponse<Rp5Table[]> => {
                if (response.status === 200) {
                    return {
                        data: response.data
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    getReportCrossAnalysisMenuItems(prrKey: PRRKey): Promise<ApiResponse<Rp6MenuItem[]>> {
        return this.client
            .get(`/v1alpha2/report/cross-analysis/${prrKey.projectId}/${prrKey.reportIndex}/${prrKey.revisionIndex}/list`)
            .then((response: AxiosResponse): ApiResponse<Rp6MenuItem[]> => {
                if (response.status === 200) {
                    return {
                        data: response.data,
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    rp6ListPageTables(prrKey: PRRKey, page: number): Promise<ApiResponse<Rp6Table[]>> {
        return this.client
            .get(`/v1alpha2/report/cross-analysis/${prrKey.projectId}/${prrKey.reportIndex}/${prrKey.revisionIndex}/${page}/tables`,)
            .then((response: AxiosResponse): ApiResponse<Rp6Table[]> => {
                if (response.status === 200) {
                    return {
                        data: response.data
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    rp7ListCoverageMenuItems(prrKey: PRRKey): Promise<ApiResponse<Rp7PageCoverageMenuItem[]>> {
        return this.client
            .get(`/v1alpha3/report/summary/coverage/${prrKey.projectId}/${prrKey.reportIndex}/${prrKey.revisionIndex}/list`,)
            .then((response: AxiosResponse): ApiResponse<Rp7PageCoverageMenuItem[]> => {
                if (response.status === 200) {
                    return {
                        data: response.data,
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    rp7ListPageCoverageTables(prrKey: PRRKey, page: number): Promise<ApiResponse<Rp7CoverageTable[]>> {
        return this.client
            .get(`/v1alpha3/report/summary/coverage/${prrKey.projectId}/${prrKey.reportIndex}/${prrKey.revisionIndex}/${page}/tables`,)
            .then((response: AxiosResponse): ApiResponse<Rp7CoverageTable[]> => {
                if (response.status === 200) {
                    return {
                        data: response.data,
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    rp7GetOverviewCoverageData(prrKey: PRRKey): Promise<ApiResponse<Rp7OverviewCoverageData>> {
        return this.client
            .get(`/v1alpha3/report/summary/coverage/${prrKey.projectId}/${prrKey.reportIndex}/${prrKey.revisionIndex}/overview`,)
            .then((response: AxiosResponse): ApiResponse<Rp7OverviewCoverageData> => {
                if (response.status === 200) {
                    return {
                        data: response.data,
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

    rp7GetOverviewIssueData(prrKey: PRRKey): Promise<ApiResponse<Rp7OverviewIssueData>> {
        return this.client
            .get(`/v1alpha3/report/summary/issue/${prrKey.projectId}/${prrKey.reportIndex}/${prrKey.revisionIndex}/overview`,)
            .then((response: AxiosResponse): ApiResponse<Rp7OverviewIssueData> => {
                if (response.status === 200) {
                    return {
                        data: response.data,
                    }
                } else {
                    return {
                        errorReason: this.getResponseError(response),
                    }
                }
            })
            .catch(this.getError)
    }

//-------------------------------------------------------------------------------------
    mapProjectDto(data: any): ProjectDto {
        return {
            id: data.id,
            name: data.name,
            description: data.description,
            roles: [],
            status: data.status,
            reports: data.reports,
            createDate: moment.utc(data.createDate, 'YYYY-MM-DDThh:mm:ss.SSSZ'),
            updateDate: moment.utc(data.updateDate, 'YYYY-MM-DDThh:mm:ss.SSSZ'),
        }
    }

    getError(error: AxiosError): ApiResponse<any> {
        let reason: ErrorReason = ErrorReason.UNKNOWN_ERROR
        if (error.response !== undefined) {
            reason = this.getResponseError(error.response)
        }
        return {
            errorReason: reason,
        }
    }

    getResponseError(response: AxiosResponse): ErrorReason {
        if (response.status === 401 || response.data === "SESSION_EXPIRED") {
            return ErrorReason.TOKEN_EXPIRED
        }
        return ErrorReason.UNKNOWN_ERROR
    }

}

interface RevisionFilePayload {
    project_id: string
    report_index: number
    revision_index: number

    file: File
    path: string

    uploading: boolean
    progress: number
}

export const apiClient = new ApiClient(
    axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL || "http://localhost:8080",
    }),
)

export {
    ApiClient,
    ErrorReason,
}
export type {ApiClientProps, ApiResponse, RevisionFilePayload}