import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {AnyAction} from "redux";

interface ComponentProps {
    isOpen: boolean
    close: () => void
    confirm: (deferredAction: AnyAction | undefined) => void

    deferredAction?: AnyAction
}

const DiscardChangesDialog = (props: ComponentProps) => {
    return <Dialog open={props.isOpen}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Are you sure you want to discard the changes?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.close}>Cancel</Button>
            <Button
                variant="contained"
                color="secondary"
                onClick={() => props.confirm(props.deferredAction)}
            >
                Confirm
            </Button>
        </DialogActions>
    </Dialog>
}

export {DiscardChangesDialog}