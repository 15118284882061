import React, {useState} from "react";
import {useAsyncFn} from "react-use";

import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {LoadingButton} from "@mui/lab";

import {FieldContainer} from "../../../../../../qdep/components/app/workspace/Workspace";
import {ApiResponse} from "../../../../../../app/client/types";
import {EntityModelRevisionRef} from "../../../../../../app/client/app/entity/report/model/ReportModel";
import {apiClient} from "../../../../../../app/client/app/client";
import {CopyReportEntitiesToModelRequest} from "../../../../../../app/client/app/entity/report/model/ReportModelDto";
import {OpsResultWithData} from "../../../../../../app/client/app/entity/ops/OpsResult";

import styles from './CreateEntityModelDialog.module.css'


interface DialogProps {
    open: boolean
    close: () => void

    prrKeyStr: string
    updateModelRef: (ref: EntityModelRevisionRef) => void
}

const copyReportEntitiesToModel = async (request: CopyReportEntitiesToModelRequest): Promise<ApiResponse<OpsResultWithData<EntityModelRevisionRef>>> => {
    return await apiClient.copyReportEntitiesToModel(request)
}

const CreateEntityModelDialog = (props: DialogProps) => {
    const [state, setState] = useState<CopyReportEntitiesToModelRequest>({
        reportPrrKey: props.prrKeyStr,
        modelDescription: "", modelName: "", revisionDescription: "", revisionName: "",
    });
    const [copyModelStatus, copyModelFn] = useAsyncFn(copyReportEntitiesToModel, []);

    return <Dialog
        open={props.open}
        onClose={props.close}
    >
        <DialogTitle>
            Create Entity Model
        </DialogTitle>
        <DialogContent>
            <div className={styles.dialog_group_container}>
                <div className={styles.subtitle}>
                    Model Properties
                </div>
                <FieldContainer title={"Name"}>
                    <TextField
                        className={"field-control"}
                        size={"small"}
                        value={state.modelName}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setState(prev => ({
                                ...prev, modelName: event.target.value
                            }))
                        }}
                    />
                </FieldContainer>
                <FieldContainer title={"Description"} notAlign>
                    <TextField
                        multiline
                        rows={3}
                        className={"field-control"}
                        size={"small"}
                        value={state.modelDescription}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setState(prev => ({
                                ...prev, modelDescription: event.target.value
                            }))
                        }}
                    />
                </FieldContainer>
            </div>
            <div className={styles.dialog_group_container}>
                <div className={styles.subtitle}>
                    Revision Properties
                </div>
                <FieldContainer title={"Name"}>
                    <TextField
                        className={"field-control"}
                        size={"small"}
                        value={state.revisionName}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setState(prev => ({
                                ...prev, revisionName: event.target.value
                            }))
                        }}
                    />
                </FieldContainer>
                <FieldContainer title={"Description"} notAlign>
                    <TextField
                        multiline
                        rows={3}
                        className={"field-control"}
                        size={"small"}
                        value={state.revisionDescription}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setState(prev => ({
                                ...prev, revisionDescription: event.target.value
                            }))
                        }}
                    />
                </FieldContainer>
            </div>
        </DialogContent>
        <DialogActions>
            <Button
                onClick={props.close}
            >
                Cancel
            </Button>
            <LoadingButton
                variant={"contained"}
                color={"secondary"}
                loading={copyModelStatus.loading}
                onClick={() => {
                    copyModelFn(state)
                        .then(response => {
                            if (response.errorReason === undefined && response.data !== undefined) {
                                props.updateModelRef(response.data.data)
                                props.close();
                            }
                        })
                }}
            >
                Apply
            </LoadingButton>
        </DialogActions>
    </Dialog>
}

export {CreateEntityModelDialog}
