import {AnyAction} from "redux";
import moment from "moment/moment";

import {DataPiece} from "../../data/util";


interface EntityListDataState<DataType> {
    data: DataPiece<DataType[]>
    isLoading?: boolean
}

interface EntityListSettingsState {
    settings: EntityListSettings
    selectedRow?: number
}

interface EntityListSettings {
    pageSize: number
    columns: EntityListColumnSettings
}

interface EntityListColumnSettings {
    [key: string]: boolean
}

function updateDataReducer<T extends EntityListDataState<any>>(state: T, action: AnyAction): T {
    return {
        ...state,
        data: {
            isLoaded: true,
            data: action.data,
            fetchTime: moment(),
        },
        isLoading: false,
    }
}

function updateSettingsReducer<T extends EntityListSettingsState>(state: T, action: AnyAction): T {
    return {
        ...state,
        settings: action.settings,
    }
}

function selectRowReducer<T extends EntityListSettingsState>(state: T, action: AnyAction): T {
    let selectedRow = action.index
    if (state.selectedRow === selectedRow) {
        selectedRow = undefined
    }
    return {
        ...state,
        selectedRow: selectedRow
    };
}

export type {EntityListDataState, EntityListColumnSettings, EntityListSettings, EntityListSettingsState}
export {updateDataReducer, updateSettingsReducer, selectRowReducer}