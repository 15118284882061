import React from "react";

import {Rp3CustomRule, Rp3MenuItem} from "../../../../../app/client/app/entity/Rp3Report";
import {TableTitle} from "../../../../../app/client/app/entity/report/TableTitle";
import {defaultReportBaseState, ReportBaseState} from "../../base/report-workspace-header/data/ReportBaseState";
import {WithReportTablesWorkspace} from "../../base/report-tables-container/state";
import {defaultManualModificationRules, WithCustomRules} from "../../base/report-custom-rule-pane/data/CustomRules";
import {defaultTableContextMenuState, WithTableContextMenu} from "../../base/context-menu/TableContextMenuState";
import {Rp3ContextMenu} from "./reducer";
import {BaseMenuItemState, WithMenuState} from "../../base/report-menu/state";


interface Rp3State extends
    ReportBaseState,
    WithMenuState<Rp3MenuItemState>,
    WithReportTablesWorkspace<Rp3TableState>,
    WithCustomRules<Rp3CustomRule>,
    WithTableContextMenu<Rp3ContextMenu>
{
    contextMenu: Rp3ContextMenu
}

type Rp3MenuItemState = BaseMenuItemState & Rp3MenuItem

interface Rp3TableState {
    entityId: string
    filtered: boolean
    title: TableTitle
    rows: Rp3RowState[]

    verticalMergeInsight: boolean
}

interface Rp3CellState {
    className: string | undefined

    rowIndex: number
    rowColumnIndex: number
    colSpan: number

    value: React.ReactNode
    refs: string[] | null
}

interface Rp3RowState {
    cells: Rp3CellState[]
}

const defaultRp3State: Rp3State = {
    ...defaultReportBaseState,

    menu: {
        items: [],
        selectedItem: undefined,
        selectedPage: undefined,
    },
    tableWorkspace: {
        isLoading: true,
        tables: [],
    },
    contextMenu: {
        ...defaultTableContextMenuState,
        filtered: false,
        verticalMerge: false,
    },

    manualModificationRules: defaultManualModificationRules
}

export {defaultRp3State}
export type {
    Rp3State,
    Rp3MenuItemState,
    Rp3TableState, Rp3RowState, Rp3CellState
}