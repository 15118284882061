import React, {useMemo} from "react";

import {Stack} from "@mui/material";
import BuildCircleOutlinedIcon from "@mui/icons-material/BuildCircleOutlined";
import LinkOffIcon from "@mui/icons-material/LinkOff";

import {Cell, DataGroupKey} from "./IntermediateTable";
import {Label, LabelVariant} from "../../../../../qdep/components/app/util/label/Label";
import {FormulaTargetTooltip} from "./FormulaTargetTooltip";
import {Spacer} from "../../../../../qdep/components/app/util/spacer/Spacer";

import styles from './cell.module.css'


interface CellProps extends Cell {
    onClick: (cell: Cell) => void
    openContextMenu: (x: number, y: number, cell: Cell) => void
    selectDataGroup: (dgId: string, dkKeyIndex: number, value: boolean) => void
}

const TableCell = (cell: CellProps) => {
    return <td
        key={cell.column}
        colSpan={cell.colSpan}
        className={cell.className}
        style={cell.styles}

        onClick={() => cell.onClick(cell)}

        onContextMenu={event => {
            event.preventDefault()
            cell.openContextMenu(event.clientX - 2, event.clientY - 4, cell)
        }}
    >
        { !cell.isDataCell && <HeaderCell {...cell}/> }
        { cell.isDataCell && <DataCell {...cell}/> }
    </td>
}

const DataCell = (cell: CellProps) => {
    const ruleNum = cell.inspections.length;
    return <>
        { ruleNum === 0 && cell.value }
        { ruleNum !== 0 &&
            <FormulaTargetTooltip key={"tooltip"} inspections={cell.inspections}>
                <Stack direction={"row"} alignItems={"center"} gap={"4px"} flexWrap={"nowrap"}>
                    { ruleNum > 1 && <Label key={"ruleResultNums"} size={"small"} variant={"info"} text={ruleNum}/>}
                    <Spacer key={"ruleResultNums_Spacer"}/>
                    {<span key={"value"}>{cell.value}</span>}
                </Stack>
            </FormulaTargetTooltip>
        }
    </>
}

const HeaderCell = (props: CellProps) => {
    return <>
        { props.dgKeys !== undefined && props.dgKeys.length > 0 &&
            <HeaderDgKeysWrapper
                dgKeys={props.dgKeys}
                inline={props.isSubHeader}
                selectDataGroup={props.selectDataGroup}
            >
                { props.value }
            </HeaderDgKeysWrapper>
        }
        { (props.dgKeys === undefined || props.dgKeys.length === 0) && props.value }
    </>
}


interface HeaderDgKeysWrapperProps {
    dgKeys: DataGroupKey[]
    inline: boolean
    selectDataGroup: (dgId: string, dkKeyIndex: number, value: boolean) => void
    children: React.ReactNode
}

const HeaderDgKeysWrapper = (props: HeaderDgKeysWrapperProps) => {
    return <div className={styles.header_dg_wrapper} data-subheader={props.inline}>
        <div className={styles.header_dgs_container}>
            { props.dgKeys.map((dgKey, index) =>
                <DataGroupLabel
                    {...dgKey}
                    key={dgKey.id}
                    index={index}
                    selectDataGroup={props.selectDataGroup}
                />
            )}
        </div>
        { props.children }
    </div>
}

interface DataGroupLabelProps extends DataGroupKey {
    index: number
    selectDataGroup: (dgId: string, dkKeyIndex: number, value: boolean) => void
}

const DataGroupLabel = (dgKey: DataGroupLabelProps) => {
    const icon = useMemo(() => {
        if (dgKey.editedManually && dgKey.detached) {
            return <Stack direction={"row"} gap={"4px"}>
                <BuildCircleOutlinedIcon fontSize={"small"}/>
                <LinkOffIcon fontSize={"small"}/>
            </Stack>
        } else {
            if (dgKey.editedManually) {
                return <BuildCircleOutlinedIcon fontSize={"small"}/>
            }
            if (dgKey.detached) {
                return <LinkOffIcon fontSize={"small"}/>
            }
        }
        return undefined
    }, [dgKey.detached, dgKey.editedManually]);
    const variant = useMemo(() => {
        let variant: LabelVariant = dgKey.detached ? "disabled": "info";
        if (dgKey.selected) {
            variant = "warning"
        }
        return variant
    }, [dgKey.detached, dgKey.selected]);

    return <Label
        variant={variant}
        icon={icon}
        text={dgKey.displayName}
        onClick={() => dgKey.selectDataGroup(dgKey.id, dgKey.index, !dgKey.selected)}
    />
}

export {TableCell}