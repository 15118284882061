import {AnyAction} from "redux";
import {Rp7MenuItemType} from "./state";

const enum ActionTypes {
    SELECT_MENU_ITEM = "SELECT_MENU_ITEM",
}

function selectMenuItem(item: Rp7MenuItemType): AnyAction {
    return {type: ActionTypes.SELECT_MENU_ITEM, item: item}
}

export {
    ActionTypes,
    selectMenuItem,
}
