interface ApiClientProps {
    baseUrl: string,
}

enum ErrorReason {
    NONE,
    UNKNOWN_ERROR,
    TOKEN_EXPIRED,
    WRONG_USER_OR_PASS,
    INVALID_TOKEN,
    TIMEOUT,
    SERVER_SIDE,
}

function isAuthError(reason: ErrorReason): boolean {
    return reason === ErrorReason.TOKEN_EXPIRED ||
        reason === ErrorReason.INVALID_TOKEN ||
        reason === ErrorReason.WRONG_USER_OR_PASS
}

function isNotAuthError(reason: ErrorReason): boolean {
    return !isAuthError(reason)
}

function isUnknownError(reason: ErrorReason): boolean {
    return reason === ErrorReason.UNKNOWN_ERROR
}

interface ApiResponse<T> {
    data?: T,
    errorReason?: ErrorReason
}

export type { ApiResponse, ApiClientProps }
export { ErrorReason, isAuthError, isNotAuthError, isUnknownError }