import React, {Children, useMemo, useState} from "react";

import {Badge, IconButton} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SettingsIcon from '@mui/icons-material/Settings';
import LayersIcon from '@mui/icons-material/Layers';
import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';

import {Spacer} from "../../../../../qdep/components/app/util/spacer/Spacer";
import {WorkspaceHeader} from "../../../../../qdep/components/app/workspace/Workspace";
import {ReportSettingsDialog} from "../../../analysis_settings/ReportSettingsDialog";
import {PRRKey} from "../../../../../app/client/app/entity/Project";
import {LayersMenu} from "./LayersMenu";
import {StageNav} from "./StageNav";
import {ReportFeatures} from "./data/ReportFeatures";
import {ReportStageStatusLabel} from "../util/ReportStageStatusLabel";
import {StageStatuses} from "../../../../../app/client/app/entity/ops/ReportStatusResult";
import {StageNames} from "../StageNames";
import {useAsyncFn} from "react-use";
import {apiClient} from "../../../../../app/client/app/client";
import {useSnackbar} from "notistack";


interface ReportWorkspaceHeaderProps {
    prrKey: PRRKey

    reportName: string | undefined
    reportStage: StageNames
    reportPage: string | number | undefined

    reportStageStatuses: StageStatuses | undefined
    updateReportStatuses: (stages: StageStatuses) => void

    numCustomRuleUpdates: number
    openCustomRuleBlock?: () => void

    reportFeatures?: ReportFeatures
    updateReportFeatures?: (updates: ReportFeatures) => void

    disableSettings?: boolean

    children?: React.ReactElement | React.ReactElement[]
}

const ReportWorkspaceHeader = (props: ReportWorkspaceHeaderProps) => {
    const [openSettingsDialog, setOpenSettingsDialog] = useState(false);
    const [layerMenuAnchorEl, setLayerMenuAnchorEl] = useState(null as Element | null);

    const { enqueueSnackbar } = useSnackbar();
    let [, runAnalysis] = useAsyncFn(async () => {
        const response = await apiClient.runReportRevisionAnalyse(props.prrKey);
        if (!response.errorReason && response.data && response.data.status === "ok" && response.data.stages) {
            enqueueSnackbar(
                "Report analysis started",
                {key: "report-revision-analyse-request", variant: "success"}
            );
            props.updateReportStatuses(response.data.stages)
        } else {
            enqueueSnackbar(
                "Something went wrong. Try again later or contact your administrator",
                {key: "report-revision-analyse-request", variant: "error"}
            );
        }
    }, [props.prrKey, props.updateReportStatuses, enqueueSnackbar]);

    const stageStatus = useMemo(() => {
        if (props.reportStageStatuses !== undefined) {
            switch (props.reportStage) {
                case StageNames.OCR:
                    return props.reportStageStatuses.ocr
                case StageNames.DATA_NORMALIZATION:
                    return props.reportStageStatuses.normalization
                case StageNames.ENTITY_DETECTION:
                    return props.reportStageStatuses.entityDetection
                case StageNames.TABLE_ANALYSIS:
                    return props.reportStageStatuses.tableAnalysis
                case StageNames.CROSS_TABLE_ANALYSIS:
                    return props.reportStageStatuses.tableRefs
                case StageNames.SUMMARY:
                    return props.reportStageStatuses.summary
            }
        }
        return null;
    }, [props.reportStage, props.reportStageStatuses])

    return <>
        <WorkspaceHeader>
            <span style={{marginRight: "16px"}}>
                {props.reportName || ""}
            </span>
            <StageNav
                prrKey={props.prrKey}
                reportPage={props.reportPage}
                reportStage={props.reportStage}
                reportStageStatuses={props.reportStageStatuses}
            />
            { (stageStatus !== null && stageStatus !== "PASSED") &&
                <ReportStageStatusLabel value={stageStatus} size={"large"}/>
            }
            <Spacer/>

            { Children.count(props.children) > 0 && props.children }

            <IconButton
                disabled={stageStatus === null || stageStatus !== "OUTDATED" }
                onClick={() => runAnalysis()}
            >
                <PlayCircleFilledRoundedIcon/>
            </IconButton>

            { props.openCustomRuleBlock !== undefined &&
                <Badge
                    color="secondary"
                    overlap="circular"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    badgeContent={props.numCustomRuleUpdates}
                >
                    <IconButton
                        disabled={props.numCustomRuleUpdates === 0}
                        onClick={props.openCustomRuleBlock}
                    >
                        <EditIcon/>
                    </IconButton>
                </Badge>
            }

            { (props.reportFeatures && props.updateReportFeatures) &&
                <>
                    <IconButton
                        disabled={!(props.reportFeatures && props.updateReportFeatures)}
                        onClick={(event) => {
                            setLayerMenuAnchorEl(prev => {
                                if (!prev) {
                                    return event.currentTarget;
                                } else {
                                    return null;
                                }
                            })
                        }}
                    >
                        <LayersIcon/>
                    </IconButton>
                    <LayersMenu
                        anchorEl={layerMenuAnchorEl}
                        close={() => setLayerMenuAnchorEl(null)}
                        reportFeatures={props.reportFeatures}
                        updateReportFeatures={props.updateReportFeatures}
                    />
                </>
            }

            { !props.disableSettings &&
                <>
                    <IconButton
                        onClick={() => setOpenSettingsDialog(true)}
                    >
                        <SettingsIcon/>
                    </IconButton>
                    <ReportSettingsDialog
                        prrKey={props.prrKey}
                        isOpen={openSettingsDialog}
                        close={() => setOpenSettingsDialog(false)}
                        updateReportStatuses={props.updateReportStatuses}
                    />
                </>
            }
        </WorkspaceHeader>
    </>
}

export {ReportWorkspaceHeader}
