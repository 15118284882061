import React, {useState} from "react";
import {AnyAction} from "redux";

import {Button, IconButton, TextField} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import styles from './TaxTemplateEditor.module.css'
import {Spacer} from "../../../../../qdep/components/app/util/spacer/Spacer";
import {closeDrawer} from "../page/action";
import {EditorActionsContainer} from "../../../../../qdep/components/app/editor/EditorActionsContainer";
import {FlagControl} from "../../../../../qdep/components/app/editor/FlagControl";
import {addCustomRule} from "../../base/report-custom-rule-pane/data/action";
import {Rp5CustomRuleType} from "../../../../../app/client/app/entity/Rp5Report";


interface ComponentProps {
    rowIndex: number
    tableId: string | undefined
    dispatch: React.Dispatch<AnyAction>
}

const TaxTemplateEditor = (props: ComponentProps) => {
    const [addDataGroup, setAddDataGroup] = useState<boolean>(false);

    return <div className={styles.container}>
        <div className={styles.header_container}>
            <span>Tax Formula Properties</span>
            <Spacer/>
            <IconButton
                onClick={() => props.dispatch(closeDrawer())}
            >
                <CloseIcon/>
            </IconButton>
        </div>

        <div className={styles.content_container}>
            <TextField
                size={"small"}
                label={"Taxation charge (%)"}
                // disabled
                value={"19.0"}
                sx={{marginBottom: "8px"}}
            />
            <FlagControl
                label={"Add Data Group"}
                value={addDataGroup}
                onUpdate={value => setAddDataGroup(value)}
            />
            <FlagControl
                disabled
                label={"Round"}
                value={true}
                onUpdate={() => {}}
            />
            <TextField
                size={"small"}
                label={"Round Precision"}
                // disabled
                value={"0"}
                sx={{marginBottom: "8px"}}
            />
        </div>

        <EditorActionsContainer>
            <Button
                onClick={() => props.dispatch(closeDrawer())}
            >
                Cancel
            </Button>
            <Button
                color={"secondary"}
                variant={"contained"}
                onClick={() => {
                    props.dispatch(closeDrawer())
                    if (props.tableId !== undefined) {
                        props.dispatch(addCustomRule({
                            tableId: props.tableId,
                            type: Rp5CustomRuleType.ADD_ROW_FORMULA_TEMPLATE,
                            addRowFormulaTemplate: {
                                rowIndex: props.rowIndex,
                                templateName: "TaxTemplate",
                                addDataGroup: addDataGroup,
                            }
                        }));
                    }
                }}
            >
                Apply
            </Button>
        </EditorActionsContainer>
    </div>
}

export {TaxTemplateEditor}