enum HeaderDateFormatterType {
    YEAR_RANGE = "YEAR_RANGE",
    REGEXP = "REGEXP",
    FYS_ALIAS = "FYS_ALIAS",
    FYE_ALIAS = "FYE_ALIAS",
}

interface HeaderDateFormatter {
    type: HeaderDateFormatterType
    fromYear: number | undefined
    toYear: number | undefined
    regexp: string | undefined
}

interface HeaderDateFormatSettings {
    formatters: HeaderDateFormatter[]
    fysDate: DateParam
    fyeDate: DateParam
    fyeAsDefault: boolean
}

interface DateParam {
    day: number | null
    month: number | null
}

function mapDateParamValueToDto(value: DateParam): DateParam {
    return {
        day:  (value.day !== null && value.day > -1) ? value.day : null,
        month: (value.month !== null && value.month > -1) ? value.month : null,
    }
}

function mapDateParamDtoToValue(dto: DateParam): DateParam {
    return {
        day:  dto.day === null ? -1 : dto.day,
        month: dto.month === null ? -1 : dto.month,
    }
}

export {
    HeaderDateFormatterType,
    mapDateParamValueToDto, mapDateParamDtoToValue,
}
export type {
    HeaderDateFormatSettings, HeaderDateFormatter, DateParam
}
