import {AnyAction} from "redux";

import {Rp5MenuItem, Rp5Table} from "../../../../../app/client/app/entity/Rp5Report";
import {ContextProps} from "../report-table/table";


enum Rp5Actions {
    REPORT_SET_MENU_ITEMS = "REPORT.TABLE_ANALYSIS.SET_MENU_ITEMS",
    REPORT_SET_TABLES     = "REPORT.TABLE_ANALYSIS.SET_TABLES",

    OPEN_CONTEXT_MENU  = "REPORT.TABLE_ANALYSIS.TABLE_CONTEXT_MENU.OPEN",
    CLOSE_CONTEXT_MENU = "REPORT.TABLE_ANALYSIS.TABLE_CONTEXT_MENU.CLOSE",

    OPEN_DRAWER  = "REPORT.TABLE_ANALYSIS.DRAWER.OPEN",
    CLOSE_DRAWER = "REPORT.TABLE_ANALYSIS.DRAWER.CLOSE",
}

function setMenuItems(menu: Rp5MenuItem[], pageNumber: number | undefined): AnyAction {
    return {
        type: Rp5Actions.REPORT_SET_MENU_ITEMS,
        menu: menu,
        pageNumber: pageNumber,
    }
}

function setTable(tables: Rp5Table[]): AnyAction {
    return {
        type: Rp5Actions.REPORT_SET_TABLES,
        tables: tables,
    }
}

function openTableContextMenu(context: ContextProps): AnyAction {
    return {
        type: Rp5Actions.OPEN_CONTEXT_MENU,
        tableId: context.tableId,
        position: {
            x: context.position.x,
            y: context.position.y,
            rowIndex: context.rowIndex - 1,
            columnIndex: context.columnIndex - 1,
        },
        dgs: context.dgs,
    };
}

function closeTableContextMenu(): AnyAction {
    return {
        type: Rp5Actions.CLOSE_CONTEXT_MENU,
    }
}

function openDrawer(): AnyAction {
    return {type: Rp5Actions.OPEN_DRAWER}
}

function closeDrawer(): AnyAction {
    return {type: Rp5Actions.CLOSE_DRAWER}
}

export {
    Rp5Actions,
    setMenuItems, setTable,
    openTableContextMenu, closeTableContextMenu,
    openDrawer, closeDrawer,
}
