import React, {Component} from "react";

import {Rp4WorkspaceMode} from "../data/state";
import {UnknownErrorBlock} from "../../../../../qdep/components/app/error/UnknownError";


interface ErrorBoundaryProps {
    mode: Rp4WorkspaceMode
    entityId: string | null
    featureId: string | null
}

interface ErrorBoundaryState {
    hasError: boolean
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidUpdate(nextProps: ErrorBoundaryProps) {
        if (nextProps.mode !== this.props.mode
            || nextProps.featureId !== this.props.featureId
            || nextProps.entityId !== this.props.entityId
        ) {
            this.setState({hasError: false});
        }
    }

    static getDerivedStateFromError(_: Error) {
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return <UnknownErrorBlock/>;
        }

        return this.props.children;
    }
}

export {ErrorBoundary}
