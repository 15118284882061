import moment from "moment";

enum Stage {
    RP2_STAGE         = "OCR_PARSING_STAGE",
    OCR_PARSING_STAGE = "OCR_PARSING_STAGE",

    RP3_STAGE                = "DATA_NORMALIZATION_STAGE",
    DATA_NORMALIZATION_STAGE = "DATA_NORMALIZATION_STAGE",

    RP4_STAGE              = "ENTITY_DETECTION_STAGE",
    ENTITY_DETECTION_STAGE = "ENTITY_DETECTION_STAGE",

    RP5_STAGE            = "TABLE_ANALYSIS_STAGE",
    TABLE_ANALYSIS_STAGE = "TABLE_ANALYSIS_STAGE",

    RP6_STAGE                  = "CROSS_TABLE_ANALYSIS",
    CROSS_TABLE_ANALYSIS_STAGE = "CROSS_TABLE_ANALYSIS",
}

function stageSlug(stage: Stage): string {
    switch (stage) {
        case Stage.RP2_STAGE:
        case Stage.OCR_PARSING_STAGE:
            return "ocr"
        case Stage.RP3_STAGE:
        case Stage.DATA_NORMALIZATION_STAGE:
            return "data-normalization"
        case Stage.RP4_STAGE:
        case Stage.ENTITY_DETECTION_STAGE:
            return "entity-detection"
        case Stage.RP5_STAGE:
        case Stage.TABLE_ANALYSIS_STAGE:
            return "table-analysis"
        case Stage.RP6_STAGE:
        case Stage.CROSS_TABLE_ANALYSIS_STAGE:
            return "cross-analysis"
    }
}

interface StageStatus {
    status: string
    startTimestamp: moment.Moment | undefined
    endTimestamp:   moment.Moment | undefined
    customRules: number | undefined | null
    failedCustomRules: number | undefined | null
}

function parseStageStatus(data: any): StageStatus {
    if (data === null) {
        return {
            status: "UNKNOWN",
            startTimestamp: undefined,
            endTimestamp: undefined,
            customRules: undefined,
            failedCustomRules: undefined,
        };
    }

    return {
        status: data.status,
        startTimestamp: !!data.startTimestamp ? moment.utc(data.startTimestamp, 'YYYY-MM-DDThh:mm:ss.SSSZ') : undefined,
        endTimestamp: !!data.endTimestamp ? moment.utc(data.endTimestamp, 'YYYY-MM-DDThh:mm:ss.SSSZ') : undefined,
        customRules: data.customRules,
        failedCustomRules: data.failedCustomRules,
    };
}

export type {StageStatus}
export {Stage, stageSlug, parseStageStatus}
