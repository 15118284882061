import React, {useEffect, useState} from "react";
import {useAsyncFn, useList} from "react-use";

import {IconButton, IconButtonProps, Skeleton, styled} from "@mui/material";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import TaskIcon from '@mui/icons-material/Task';
import NoteAddIcon from '@mui/icons-material/NoteAdd';

import {PRRKey} from "../../../../app/client/app/entity/Project";
import {Attachment} from "../../../../app/client/app/entity/Report";
import {apiClient} from "../../../../app/client/app/client";
import {
    WorkspaceBlock,
    WorkspaceBlockBody,
    WorkspaceBlockHeader
} from "../../../../qdep/components/app/workspace/Workspace";
import {Spacer} from "../../../../qdep/components/app/util/spacer/Spacer";
import { Label } from "../../../../qdep/components/app/util/label/Label";
import {GenerateTickReportDialog} from "./GenerateTickReportDialog";

import styles from './FilesWorkspaceBlock.module.css'


interface ComponentProps {
    prrKey: PRRKey
}

const FilesWorkspaceBlock = ({ prrKey }: ComponentProps) => {
    const [, downloadSourceReportFile] = useAsyncFn(async () => {
        await apiClient.downloadSourceReportFile(prrKey)
    }, [prrKey]);

    const [, downloadAttachment] = useAsyncFn(async (attachmentId: string) => {
        await apiClient.downloadAttachment(prrKey, attachmentId)
    }, [prrKey]);

    const [attachments, {set, push}] = useList<Attachment>([]);
    const [isLoading, setLoading] = useState(false);
    const [openGenerateTickReport, setOpenGenerateTickReport] = useState(false);

    useEffect(() => {
        setLoading(true)
        apiClient.listAttachments(prrKey)
            .then(response => {
                if (response.errorReason === undefined && response.data !== undefined) {
                    set(response.data)
                    setLoading(false)
                }
            })
    }, [prrKey, set])

    return <>
        <WorkspaceBlock>
            <WorkspaceBlockHeader>
                Files
            </WorkspaceBlockHeader>
            <WorkspaceBlockBody>
                <div className={styles.file_container}>
                    <InsertDriveFileIcon htmlColor={"rgba(0, 0, 0, 0.54)"}/>
                    <span>Source Report</span>
                    <Spacer/>
                    <IconButton
                        onClick={downloadSourceReportFile}
                    >
                        <CloudDownloadIcon/>
                    </IconButton>
                </div>
            </WorkspaceBlockBody>
        </WorkspaceBlock>

        <WorkspaceBlock>
            <WorkspaceBlockHeader>
                <span>Attachments</span>
                <Spacer/>
                <IconButton onClick={() => setOpenGenerateTickReport(true)}>
                    <NoteAddIcon/>
                </IconButton>
            </WorkspaceBlockHeader>
            <WorkspaceBlockBody>
                { isLoading && <Skeleton height={200} variant={'rectangular'}/> }
                { !isLoading &&
                    <div className={styles.attachment_list}>
                        { attachments.map(attachment =>
                            <div key={attachment.fileId} className={styles.attachment_list_item}>
                                <div className={styles.attachment_icon}>
                                    <TaskIcon htmlColor={"rgba(0, 0, 0, 0.54)"}/>
                                </div>
                                <div className={styles.attachment_content}>
                                    <div>Ticks Report as of {attachment.createDate.format('YYYY-MM-DD HH:mm')}</div>
                                    { attachment.tags.map((tag, index) =>
                                        <Label key={index} variant={"info"} text={tag} size={"normal"}/>
                                    )}
                                </div>
                                <div className={styles.attachment_actions}>
                                    <AttachmentActionButton
                                        onClick={() => downloadAttachment(attachment.fileId)}
                                    >
                                        <CloudDownloadIcon/>
                                    </AttachmentActionButton>
                                </div>
                            </div>
                        )}
                    </div>
                }
            </WorkspaceBlockBody>
        </WorkspaceBlock>

        <GenerateTickReportDialog
            isOpen={openGenerateTickReport}
            close={() => setOpenGenerateTickReport(false)}
            apply={(attachment) => {
                push(attachment)
                setOpenGenerateTickReport(false)
            }}
            prrKey={prrKey}
        />
    </>
}

const AttachmentActionButton = styled((props: IconButtonProps) => (
    <IconButton {...props}/>
))({
    "&:hover": {
        backgroundColor: "unset",
        color: "#027bf3",
    },
})

export {FilesWorkspaceBlock}