import React, {Dispatch, useMemo} from "react";
import {AnyAction} from "redux";

import {IconButton, Stack} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import {
    WorkspaceBlock,
    WorkspaceBlockBody,
    WorkspaceBlockHeader
} from "../../../../../qdep/components/app/workspace/Workspace";
import {Spacer} from "../../../../../qdep/components/app/util/spacer/Spacer";
import {EntitySubItemTree} from "./EntitySubItemTree";
import {EntityOption, EntitySubItemEditor} from "./EntitySubItemEditor";
import {deleteNode, selectNode, toggleNode, updateConditionNode, updateSubItemNode, updateVariantNode} from "../action";
import {EntityRelationshipsVariantState, EntityTreeState} from "../state";

import styles from './EntitySubItemWorkspace.module.css'
import {EntityVariantEditor} from "./EntityVariantEditor";
import {AddEntitySubItemButtonGroup} from "./AddEntitySubItemButtonGroup";
import {EntityConditionEditor} from "./EntityConditionEditor";
import {Feature} from "../../../../../app/client/app/entity/report/settings/EntityDefinition";


interface EntitySubItemWorkspaceProps {
    entityVariants: EntityOption[]
    featureOptions: Feature[]

    entityName: string
    variants: EntityRelationshipsVariantState[]

    state: EntityTreeState
    dispatch: Dispatch<AnyAction>
}

const EntitySubItemWorkspace = (props: EntitySubItemWorkspaceProps) => {
    const disableOptions = useMemo(
        () => props.state.editorState === undefined,
        [props.state.editorState],
    )

    return <WorkspaceBlock>
        <WorkspaceBlockHeader classes={styles.editor_control_header}>
            <span>Sub Items</span>
            <Spacer/>
            <Stack direction={"row"} gap={"6px"}>
                <AddEntitySubItemButtonGroup
                    disableOptions={disableOptions}
                    dispatch={props.dispatch}
                />
                <IconButton
                    disabled={props.state.subItemTree.selectedNodePath.length === 0}
                    onClick={() => props.dispatch(deleteNode())}
                >
                    <DeleteIcon/>
                </IconButton>
            </Stack>
        </WorkspaceBlockHeader>

        <WorkspaceBlockBody>
            <div className={styles.sub_item_container}>
                <div className={styles.sub_item_tree_container}>
                    <EntitySubItemTree
                        rootName={props.entityName || "UNKNOWN"}
                        variants={props.variants}
                        expandedNodes={props.state.subItemTree.expandedNodes}

                        onSubItemToggle={nodeId => props.dispatch(toggleNode(nodeId))}
                        onSubItemSelect={nodeId => props.dispatch(selectNode(nodeId))}
                    />
                </div>
                <hr className={styles.divider}/>
                <div className={styles.sub_item_editor_container}>
                    {(props.state.editorState !== undefined && props.state.editorState.type === "SubItem") &&
                        <EntitySubItemEditor
                            entityVariants={props.entityVariants}
                            featureOptions={props.featureOptions}

                            entitySubItemPath={props.state.editorState.entityPath}
                            entitySubItem={props.state.editorState.entity}
                            onChange={updates => props.dispatch(updateSubItemNode(updates))}
                        />
                    }
                    {(props.state.editorState !== undefined && props.state.editorState.type === "Variant") &&
                        <EntityVariantEditor
                            featureOptions={props.featureOptions}

                            name={props.state.editorState.name}
                            requiredFeatures={props.state.editorState.requiredFeatures}
                            excludedFeatures={props.state.editorState.excludedFeatures}
                            onChange={updates => props.dispatch(updateVariantNode(updates))}
                        />
                    }
                    {(props.state.editorState !== undefined && props.state.editorState.type === "Condition") &&
                        <EntityConditionEditor
                            entitySubItemPath={props.state.editorState.entityPath}
                            node={props.state.editorState.entity}
                            onChange={updates => props.dispatch(updateConditionNode(updates))}
                        />
                    }
                    {props.state.editorState === undefined &&
                        <div className={styles.no_item_editor_container}>
                            Item not selected
                        </div>}
                </div>
            </div>
        </WorkspaceBlockBody>
    </WorkspaceBlock>
}

export {EntitySubItemWorkspace}
