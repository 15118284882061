import React from "react";
import {useAsyncFn} from "react-use";
import {useSnackbar} from "notistack";

import {IconButton, Skeleton, Tooltip} from "@mui/material";
import PlayCircleFilledRoundedIcon from "@mui/icons-material/PlayCircleFilledRounded";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import {WorkspaceHeader} from "../../../qdep/components/app/workspace/Workspace";
import {PRRKey} from "../../../app/client/app/entity/Project";
import {Label} from "../../../qdep/components/app/util/label/Label";
import {Spacer} from "../../../qdep/components/app/util/spacer/Spacer";
import {apiClient} from "../../../app/client/app/client";
import {StageStatuses} from "../../../app/client/app/entity/ops/ReportStatusResult";


interface ComponentProps {
    prrKey: PRRKey
    reportName: string | undefined
    reportStatus: string | undefined
    onStagesUpdate: (stages: StageStatuses) => void
}

const ReportOverviewWorkspaceHeader = (props: ComponentProps) => {
    const { enqueueSnackbar } = useSnackbar();
    let [, runAnalysis] = useAsyncFn(async (force?: boolean) => {
        const response = await apiClient.runReportRevisionAnalyse(props.prrKey, force);
        if (!response.errorReason && response.data && response.data.status === "ok" && response.data.stages) {
            enqueueSnackbar(
                "Report analysis started",
                {key: "report-revision-analyse-request", variant: "success"}
            );
            props.onStagesUpdate(response.data.stages)
        } else {
            enqueueSnackbar(
                "Something went wrong. Try again later or contact your administrator",
                {key: "report-revision-analyse-request", variant: "error"}
            );
        }
    }, [props.prrKey, enqueueSnackbar]);

    return <WorkspaceHeader>
        <span style={{marginRight: "16px"}}>
            { props.reportName === undefined && <Skeleton width={"250px"} sx={{transform: "unset"}}/> }
            { props.reportName !== undefined && props.reportName }
        </span>
        { props.reportStatus !== undefined &&
            <Label text={props.reportStatus || ""} variant={"info"} size={"large"}/>
        }
        <Spacer/>
        <Tooltip title="Run Analysis">
            <IconButton
                disabled={props.reportStatus === undefined || props.reportStatus === "PENDING"}
                onClick={() => runAnalysis()}
            >
                <PlayCircleFilledRoundedIcon/>
            </IconButton>
        </Tooltip>
        <Tooltip title="Run Full Analysis">
            <IconButton
                disabled={props.reportStatus === undefined || props.reportStatus === "PENDING"}
                onClick={() => runAnalysis(true)}
            >
                <RestartAltIcon/>
            </IconButton>
        </Tooltip>
    </WorkspaceHeader>
}

export {ReportOverviewWorkspaceHeader}
