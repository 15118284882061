import {AnyAction} from "redux";

import {
    ModificationRuleData,
    ModificationRuleResultRef,
    ModificationRuleUpdate
} from "../../../../../../app/client/app/entity/report/ModificationRule";
import { SelectableItem } from "../../../../../../qdep/data/util";

interface WithCustomRules <T extends ModificationRuleData<any>> {
    manualModificationRules: {
        show: boolean
        customRuleUpdates: ModificationRuleUpdate<T>[]

        selectedTableId: string | undefined
        selectedTableCustomRuleResults: SelectableItem<ModificationRuleResultRef>[]
        selectedTableRuleUpdates: SelectableItem<ModificationRuleUpdate<T>>[]
    }
}

const defaultManualModificationRules = {
    show: false,
    customRuleUpdates: [],
    selectedTableId: undefined,
    selectedTableCustomRuleResults: [],
    selectedTableRuleUpdates: [],
}

function addCustomRuleReducer<T extends WithCustomRules<any>>(state: T, action: AnyAction): T {
    const rule = action.rule;
    if (rule) {
        const update: ModificationRuleUpdate<any> = {
            eventType: "ADD",
            rule: action.rule,
            description: action.description,
        }
        return addCustomRuleUpdate(state, update)
    }
    return state;
}

function selectCustomRuleReducer<T extends WithCustomRules<any>>(state: T, action: AnyAction): T {
    const itemIndex = action.itemIndex;
    if (itemIndex !== undefined && itemIndex > -1) {
        const selectedTableCustomRuleResults: SelectableItem<ModificationRuleResultRef>[] = [
            ...state.manualModificationRules.selectedTableCustomRuleResults
        ]
        selectedTableCustomRuleResults[itemIndex].isSelected = !selectedTableCustomRuleResults[itemIndex].isSelected
        return {
            ...state,
            selectedTableCustomRuleResults: selectedTableCustomRuleResults,
        }
    }
    return state
}

function selectCustomRuleUpdateReducer<T extends WithCustomRules<any>>(state: T, action: AnyAction): T {
    const itemIndex = action.itemIndex;
    if (itemIndex !== undefined && itemIndex > -1) {
        const selectedTableRuleUpdates: SelectableItem<ModificationRuleUpdate<any>>[] = [
            ...state.manualModificationRules.selectedTableRuleUpdates
        ]
        selectedTableRuleUpdates[itemIndex].isSelected = !selectedTableRuleUpdates[itemIndex].isSelected
        return {
            ...state,
            selectedTableRuleUpdates: selectedTableRuleUpdates,
        }
    }
    return state
}

function deleteCustomRuleReducer<T extends WithCustomRules<any>>(state: T, _: AnyAction): T {
    const tableId = state.manualModificationRules.selectedTableId;
    if (tableId !== undefined) {
        const customRuleUpdates: ModificationRuleUpdate<any>[] = [...state.manualModificationRules.customRuleUpdates];
        const selectedRuleResults = state.manualModificationRules.selectedTableCustomRuleResults.filter(rule => rule.isSelected);
        const selectedTableRuleUpdates = [...state.manualModificationRules.selectedTableRuleUpdates]

        for (let rule of selectedRuleResults) {
            const update: ModificationRuleUpdate<any> = {
                eventType: "DELETE",
                rule: {
                    tableId: tableId,
                    type:  rule.data.type,
                },
                ruleId: rule.data.ruleId,
                description: `Delete the rule ${rule.data.ruleId}`
            }
            selectedTableRuleUpdates.push({
                isSelected: false,
                data: update,
            })
            customRuleUpdates.push(update)
        }

        return {
            ...state,
            manualModificationRules: {
                ...state.manualModificationRules,
                customRuleUpdates: customRuleUpdates,
                selectedTableCustomRuleResults: state.manualModificationRules.selectedTableCustomRuleResults.map(rule => ({...rule, isSelected: false})),
                selectedTableRuleUpdates: selectedTableRuleUpdates,
            }
        }
    }
    return state;
}

function deleteSelectedCustomRuleUpdatesReducer<T extends WithCustomRules<any>>(state: T, _: AnyAction): T {
    const tableId = state.manualModificationRules.selectedTableId;
    const selectedTableRuleUpdates = state.manualModificationRules.selectedTableRuleUpdates.filter(update => !update.isSelected)
    let customRuleUpdates: ModificationRuleUpdate<any>[]

    if (tableId === undefined) {
        customRuleUpdates = selectedTableRuleUpdates.map(item => item.data)
    } else {
        customRuleUpdates = state.manualModificationRules.customRuleUpdates.filter(update => update.rule.tableId !== tableId)
        customRuleUpdates.push(...selectedTableRuleUpdates.map(item => item.data))
    }
    return {
        ...state,
        manualModificationRules: {
            ...state.manualModificationRules,
            customRuleUpdates: customRuleUpdates,
            selectedTableRuleUpdates: selectedTableRuleUpdates,
        }
    }
}

function clearCustomRuleUpdatesReducer<T extends WithCustomRules<any>>(state: T, _: AnyAction): T {
    return {
        ...state,
        manualModificationRules: {
            ...state.manualModificationRules,
            customRuleUpdates: [],
            selectedTableRuleUpdates: [],
        }
    }
}

function openCustomRuleBlockReducer<T extends WithCustomRules<any>>(state: T, action: AnyAction): T {
    const tableId: string | undefined = action.tableId;
    let selectedRuleResult: SelectableItem<ModificationRuleResultRef>[] = state.manualModificationRules.selectedTableCustomRuleResults;
    let selectedTableRuleUpdates: SelectableItem<ModificationRuleUpdate<any>>[] = state.manualModificationRules.selectedTableRuleUpdates;

    const manualModificationRules = state.manualModificationRules;
    if (manualModificationRules.show) {
        if (manualModificationRules.selectedTableId === tableId) {
            return {
                ...state,
                manualModificationRules: {
                    ...manualModificationRules,
                    show: false,
                    selectedTableId: undefined,
                    selectedTableCustomRuleResults: [],
                }
            };
        }
    }

    if (manualModificationRules.selectedTableId !== tableId) {
        selectedRuleResult = []
        const manualModificationRuleResult: ModificationRuleResultRef[] | undefined = action.manualModificationRuleResult;
        if (manualModificationRuleResult) {
            selectedRuleResult = manualModificationRuleResult.map(ruleResult => ({
                isSelected: false,
                data: ruleResult,
            }))
        }
        selectedTableRuleUpdates = (tableId !== undefined
                ? state.manualModificationRules.customRuleUpdates.filter(update => update.rule.tableId === tableId)
                : state.manualModificationRules.customRuleUpdates
        ).map(update => ({
            isSelected: false,
            data: update,
        }))
    }

    return {
        ...state,
        manualModificationRules: {
            ...manualModificationRules,
            show: true,
            selectedTableId: tableId,
            selectedTableCustomRuleResults: selectedRuleResult,
            selectedTableRuleUpdates: selectedTableRuleUpdates,
        }
    };
}

function closeCustomRuleBlockReducer<T extends WithCustomRules<any>>(state: T, _: AnyAction): T {
    return {
        ...state,
        manualModificationRules: {
            ...state.manualModificationRules,
            show: false,
        }
    }
}

function addCustomRuleUpdate<T extends WithCustomRules<any>>(state: T, update: ModificationRuleUpdate<any>): T {
    const tableId = state.manualModificationRules.selectedTableId
    const customRuleUpdates: ModificationRuleUpdate<any>[] = [...state.manualModificationRules.customRuleUpdates, update];
    const selectedTableRuleUpdates: SelectableItem<ModificationRuleUpdate<any>>[] = [
        ...state.manualModificationRules.selectedTableRuleUpdates
    ]

    if ((tableId !== undefined && update.rule.tableId === tableId) || tableId === undefined) {
        selectedTableRuleUpdates.push({
            isSelected: false,
            data: update,
        })
    }

    return {
        ...state,
        manualModificationRules: {
            ...state.manualModificationRules,
            customRuleUpdates: customRuleUpdates,
            selectedTableRuleUpdates: selectedTableRuleUpdates,
        }
    };
}

export type {WithCustomRules, SelectableItem}
export {
    addCustomRuleReducer, deleteCustomRuleReducer, selectCustomRuleReducer,
    selectCustomRuleUpdateReducer, deleteSelectedCustomRuleUpdatesReducer,
    openCustomRuleBlockReducer, closeCustomRuleBlockReducer,
    clearCustomRuleUpdatesReducer,
    defaultManualModificationRules
}
