import moment from "moment";
import {StageStatus} from "./report/Stage";
import {TableTitle} from "./report/TableTitle";


interface ReportRevision {
    index: number
    revisionNumber: number
    filename: string
    status: string
    fileMetadata: FileMetadata | null
}

interface FileMetadata {
    path: string
    type: string
    size: number
    createDate: moment.Moment
}

interface ReportFile {
    path: string
    type: string
    status: string
    issues: number
    size: number
    createDate: moment.Moment
}

interface ReportListItem {
    projectId: string
    index: number
    name: string
    description: string
    periodType: string
    periodValue: string
    revisions: number
    lastRevision: ReportRevision
}

function parseReportListItem(data: Record<string, any>): ReportListItem {
    const fileMetadata: FileMetadata | null = !!data.lastRevision.fileMetadata ? {
        path: data.lastRevision.fileMetadata.path,
        type: data.lastRevision.fileMetadata.type,
        size: data.lastRevision.fileMetadata.size,
        createDate: moment.utc(data.lastRevision.fileMetadata.createDate, 'YYYY-MM-DDThh:mm:ss.SSSZ'),
    }: null

    return {
        projectId: data.projectId,
        index: data.index,
        name: data.name,
        description: data.description,
        periodType: data.periodType,
        periodValue: data.periodValue,
        revisions: data.revisions,
        lastRevision: {
            index: data.lastRevision.index,
            revisionNumber: data.lastRevision.revisionNumber,
            filename: data.lastRevision.filename,
            status: data.lastRevision.status,
            fileMetadata: fileMetadata
        }
    }
}

interface ReportRevisionDto {
    projectId: string
    reportIndex: number
    revisionIndex: number

    reportName: string
    status: string

    analysis: Analysis
}

interface Attachment {
    fileId: string
    createDate: moment.Moment
    type: "SOURCE" | "TICK_REPORT" | string
    tags: string[]
}

interface Analysis {
    stages: Stages
}

interface Stages {
    ocr: OcrStageStatus
    normalization: DataNormalizationStageStatus
    entityDetection: EntityDetectionStageStatus
    tableAnalysis: TableAnalysisStageStatus
    tableRefs: TableRefsStageStatus
    summary: SummaryStageStatus
}

interface OcrStageStatus extends StageStatus {
    jobId:  string | undefined | null
    tables: number | undefined | null
}

interface DataNormalizationStageStatus extends StageStatus {
    tables:         number | undefined | null
    filteredTables: number | undefined | null
}

interface EntityDetectionStageStatus extends StageStatus {
    entities: number | undefined | null
}

interface TableAnalysisStageStatus extends StageStatus {
    rules:  number | undefined | null
    issues: number | undefined | null
}

interface TableRefsStageStatus extends StageStatus {
    refs:  number | undefined | null
    unresolvedRefs: number | undefined | null
}

interface SummaryStageStatus extends StageStatus {

}

interface ReportIssue {
    tableId: string
    page: number
    title: TableTitle
    type: string
    description: string
}

export type {
    ReportFile,
    ReportListItem, ReportRevision,
    ReportRevisionDto, Analysis, Stages,
    OcrStageStatus, DataNormalizationStageStatus, EntityDetectionStageStatus, TableAnalysisStageStatus, TableRefsStageStatus, SummaryStageStatus,
    ReportIssue,
    Attachment,
}
export {parseReportListItem}
