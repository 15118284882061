import React from "react";

import {ColumnSpec, QTable, RowFactory} from "@qdep/react-table";
import {CircularProgress} from "@mui/material";

import {EntityTableControlPanel} from "./EntityTableControlPane";
import {EntityListSettings} from "./EntityListSettingsState";
import {DataPiece, getDataPieceData} from "../../data/util";

import './EntityListWorkspaceBlock.css'


interface EntityListBlockProps {
    title: string
    titleDetail?: string
    tableCss?: string

    actionGroup?: React.ReactElement

    settings?: EntityListSettings

    data: DataPiece<any[]>,
    columnSpec: ColumnSpec[],

    selectedRow?: number,
    rowFactory?: RowFactory,

    isLoading?: boolean
}

interface EntityListBlockAction {
    refresh?: (limit: number) => void
    select?: (index: number) => void
    open?: (index: number) => void
    updateSettings?: (settings: EntityListSettings) => void
}

class EntityListBlock extends React.Component<EntityListBlockProps & EntityListBlockAction> {

    componentDidMount() {
        if (this.props.refresh && !this.props.data.isLoaded) {
            this.props.refresh(this.props.settings?.pageSize || 25)
        }
    }

    render(): React.ReactNode {
        const dataPieceData = getDataPieceData(this.props.data);
        return <>
            <EntityTableControlPanel
                title={this.props.title}
                titleDetail={this.props.titleDetail}

                refreshTime={this.props.data.fetchTime}
                refresh={this.props.refresh === undefined ? undefined : () => this.props.refresh?.(this.props.settings?.pageSize || 25)}
                actionGroup={this.props.actionGroup}
                settings={this.props.settings}

                updateSettingsCallback={(settings) => {
                    if (this.props.updateSettings) {
                        this.props.updateSettings(settings)
                    }
                }}
            />
            <div className={"entity-list-table-container"}>
                <QTable
                    data={dataPieceData}
                    spec={this.activeColumns()}
                    tableCss={"entity-registry-table " + this.props.tableCss}
                    disableEmptyTableBlock={true}

                    onClick={this.props.select}
                    onDoubleClick={this.props.open}
                    selectedRow={this.props.selectedRow}
                    rowFactory={this.props.rowFactory}

                    isLoading={this.props.isLoading}
                    loaderBlock={
                        <div className={"entity-list-table-loader-container"}>
                            <CircularProgress color="secondary"/>
                        </div>
                    }
                />
            </div>
            { dataPieceData.length === 0 &&
                <div className={"entity-list-table-empty-container"}>
                    <div className={"title"}>
                        There are no items yet.
                    </div>
                </div>
            }
        </>;
    }

    activeColumns(): ColumnSpec[] {
        const settings = this.props.settings
        if (settings === undefined) {
            return this.props.columnSpec
        }
        return this.props.columnSpec.filter((column: ColumnSpec) => settings.columns[column.title])
    }

}

export {EntityListBlock}
export type {EntityListBlockProps, EntityListBlockAction}