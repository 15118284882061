import {AnyAction} from "redux";

import {
    ModificationRuleData,
    ModificationRuleResultRef
} from "../../../../../../app/client/app/entity/report/ModificationRule";


enum RpModificationRulePaneActions {
    OPEN_CUSTOM_RULE_PANE  = "REPORT_PAGE.CUSTOM_RULE.PANE.OPEN",
    CLOSE_CUSTOM_RULE_PANE = "REPORT_PAGE.CUSTOM_RULE.PANE.CLOSE",

    ADD_CUSTOM_RULE    = "REPORT_PAGE.CUSTOM_RULE.APPLIED.ADD",
    DELETE_CUSTOM_RULE = "REPORT_PAGE.CUSTOM_RULE.APPLIED.DELETE",
    SELECT_CUSTOM_RULE = "REPORT_PAGE.CUSTOM_RULE.APPLIED.SELECT",

    SELECT_CUSTOM_RULE_UPDATE = "REPORT_PAGE.CUSTOM_RULE.UPDATE.SELECT",
    DELETE_CUSTOM_RULE_UPDATE = "REPORT_PAGE.CUSTOM_RULE.UPDATE.DELETE",
    CLEAR_CUSTOM_RULE_UPDATE  = "REPORT_PAGE.CUSTOM_RULE.UPDATE.CLEAR",
}

function openCustomRulePane(tableId?: string, manualModificationRuleResult?: ModificationRuleResultRef[]): AnyAction {
    return {
        type: RpModificationRulePaneActions.OPEN_CUSTOM_RULE_PANE,
        tableId: tableId,
        manualModificationRuleResult: manualModificationRuleResult,
    }
}

function closeCustomRulePane(): AnyAction {
    return {
        type: RpModificationRulePaneActions.CLOSE_CUSTOM_RULE_PANE,
    }
}

function addCustomRule(rule: ModificationRuleData<any>, description?: string): AnyAction {
    return {
        type: RpModificationRulePaneActions.ADD_CUSTOM_RULE,
        rule: rule,
        description: description,
    }
}

function deleteSelectedCustomRule(): AnyAction {
    return {
        type: RpModificationRulePaneActions.DELETE_CUSTOM_RULE,
    }
}

function selectCustomRule(itemIndex: number): AnyAction {
    return {
        type: RpModificationRulePaneActions.SELECT_CUSTOM_RULE,
        itemIndex: itemIndex,
    }
}

function selectCustomRuleUpdate(itemIndex: number): AnyAction {
    return {
        type: RpModificationRulePaneActions.SELECT_CUSTOM_RULE_UPDATE,
        itemIndex: itemIndex
    }
}

function deleteSelectedCustomRuleUpdates(): AnyAction {
    return {
        type: RpModificationRulePaneActions.DELETE_CUSTOM_RULE_UPDATE,
    }
}

function clearCustomRuleUpdates(): AnyAction {
    return {
        type: RpModificationRulePaneActions.CLEAR_CUSTOM_RULE_UPDATE,
    }
}

export {
    RpModificationRulePaneActions,
    openCustomRulePane, closeCustomRulePane,
    addCustomRule, deleteSelectedCustomRule, selectCustomRule,
    selectCustomRuleUpdate, deleteSelectedCustomRuleUpdates, clearCustomRuleUpdates
}