import {AnyAction} from "redux";
import {EntityListSettings} from "../../../../qdep/components/entity-list-workspace-block/EntityListSettingsState";

enum ReportFileWorkspaceActionTypes {
    UPDATE_SETTINGS = 'WORKSPACE.REPORT_FILE_LIST.UPDATE_SETTINGS',
    SELECT_ROW      = 'WORKSPACE.REPORT_FILE_LIST.SELECT_ROW',
}

function updateSettings(settings: EntityListSettings): AnyAction {
    return {
        type: ReportFileWorkspaceActionTypes.UPDATE_SETTINGS,
        settings: settings,
    }
}

function listSelectRow(index: number): AnyAction {
    return {
        type: ReportFileWorkspaceActionTypes.SELECT_ROW,
        index: index
    }
}

export {
    ReportFileWorkspaceActionTypes,
    updateSettings, listSelectRow,
}