import React, {useState} from "react";

import {Chip, IconButton} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import {AddStringValueDialog} from "../../../../../qdep/components/app/dialog/AddStringValueDialog";

import styles from './EnumController.module.css'


interface EnumControllerProps {
    dialogTitle: string

    values: string[]

    add: (value: string) => void
    delete: (index: number) => void
}

const EnumController = (props: EnumControllerProps) => {
    const [addValueDialogOpen, setAddValueDialogOpen] = useState(false);
    return <>
        <div className={styles.enum_values_container}>
            { props.values.length ===0 && <span className={styles.no_items_value}>No items</span> }
            { props.values.map((value, index) =>
                <Chip key={value} label={value} onDelete={() => props.delete(index)}/>
            )}
            <IconButton onClick={() => setAddValueDialogOpen(true)}>
                <AddIcon/>
            </IconButton>
        </div>

        <AddStringValueDialog
            title={props.dialogTitle}
            isOpen={addValueDialogOpen}
            close={() => setAddValueDialogOpen(false)}
            confirm={props.add}
        />
    </>
}

export {EnumController}