import React from "react";
import {AnyAction} from "redux";

import {Divider, ListItemIcon, Menu, MenuItem} from "@mui/material";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import SplitscreenIcon from '@mui/icons-material/Splitscreen';
import VerticalAlignCenterIcon from '@mui/icons-material/VerticalAlignCenter';

import {Rp3CustomRule, Rp3CustomRuleType} from "../../../../../app/client/app/entity/Rp3Report";
import {closeTableContextMenu, replaceTable} from "../data/action";
import {Rp3ContextMenu} from "../data/reducer";
import {useAsyncFn} from "react-use";
import {apiClient} from "../../../../../app/client/app/client";
import {PRRKey} from "../../../../../app/client/app/entity/Project";


interface TableContextMenuProps {
    prrKey: PRRKey
    contextMenu: Rp3ContextMenu
    dispatch: React.Dispatch<AnyAction>
}

const TableContextMenu = (props: TableContextMenuProps) => {
    // const { enqueueSnackbar } = useSnackbar();
    //     const [saveUpdatesStatus, saveUpdates] = useAsyncFn(async (prrKey: PRRKey, stage: Stage, updates: ModificationRuleUpdate<any>[]) => {
    //         const response = await apiClient.updateReportCustomRule(prrKey, stage, updates);
    //
    //         const notificationKey: SnackbarKey = "update-report-revision-custom-rule-request"
    //         let notificationVariant: VariantType = "error"
    //         let notificationMessage: SnackbarMessage = "Something went wrong. Try again later or contact your administrator"
    //         let opsResult: ReportStatusResult = {status: "failed", stages: undefined}
    //         if (!response.errorReason && response.data && response.data.status === "ok") {
    //             notificationVariant = "success"
    //             notificationMessage = "Report updated. Refresh the page"
    //             opsResult = response.data
    //         }
    //         enqueueSnackbar(notificationMessage, {key: notificationKey, variant: notificationVariant});
    //         return opsResult
    //     }, [enqueueSnackbar, props.dispatch]);

    const [, editTable] = useAsyncFn(async (operation: Rp3CustomRule) => {
        const response = await apiClient.rp2EditTable(props.prrKey, operation);
        if (response.errorReason === undefined && response.data !== undefined && response.data.entity !== undefined) {
            // todo update report stage statuses
            props.dispatch(replaceTable(response.data.entity))
        }
    }, [props.prrKey, props.dispatch]);

    return <>
        <Menu
            open={props.contextMenu.show}
            onClose={() => props.dispatch(closeTableContextMenu())}
            anchorReference="anchorPosition"
            anchorPosition={
                props.contextMenu.show
                    ? { top: props.contextMenu.position.y, left: props.contextMenu.position.x }
                    : undefined
            }
        >
            <MenuItem
                onClick={() => {
                    props.dispatch(closeTableContextMenu())
                    if (props.contextMenu.tableId !== undefined) {
                        editTable({
                            tableId: props.contextMenu.tableId,
                            type: Rp3CustomRuleType.SPLIT_TABLE,
                            rowIndex: props.contextMenu.position.rowIndex,
                        })
                    }
                }}
            >
                <ListItemIcon>
                    <SplitscreenIcon/>
                </ListItemIcon>
                Split Table
            </MenuItem>
            <MenuItem
                onClick={() => {
                    props.dispatch(closeTableContextMenu())
                    if (props.contextMenu.tableId !== undefined) {
                        editTable({
                            tableId: props.contextMenu.tableId,
                            type: Rp3CustomRuleType.MERGE_TABLES,
                        })
                    }
                }}
            >
                <ListItemIcon>
                    <VerticalAlignCenterIcon/>
                </ListItemIcon>
                Merge Tables (Horizontal)
            </MenuItem>
            <MenuItem
                disabled={!props.contextMenu.verticalMerge}
                onClick={() => {
                    props.dispatch(closeTableContextMenu())
                    if (props.contextMenu.tableId !== undefined) {
                        editTable({
                            tableId: props.contextMenu.tableId,
                            type: Rp3CustomRuleType.VERTICAL_MERGE_TABLES,
                        })
                    }
                }}
            >
                <ListItemIcon>
                    <VerticalAlignCenterIcon transform={"rotate(90)"}/>
                </ListItemIcon>
                Merge Tables (Vertical)
            </MenuItem>
            <Divider/>

            <MenuItem
                onClick={() => {
                    props.dispatch(closeTableContextMenu())
                    if (props.contextMenu.tableId !== undefined) {
                        editTable({
                            tableId: props.contextMenu.tableId,
                            type: Rp3CustomRuleType.MARK_COLUMN_AS_HEADER,
                            columnIndex: props.contextMenu.position.columnIndex,
                        })
                    }
                }}
            >
                <ListItemIcon/>
                Mark Column as Header
            </MenuItem>
            <MenuItem
                onClick={() => {
                    props.dispatch(closeTableContextMenu())
                    if (props.contextMenu.tableId !== undefined) {
                        editTable({
                            tableId: props.contextMenu.tableId,
                            type: Rp3CustomRuleType.MARK_ROW_AS_HEADER,
                            rowIndex: props.contextMenu.position.rowIndex,
                        })
                    }
                }}
            >
                <ListItemIcon/>
                Mark Row as Header
            </MenuItem>
            <Divider/>

            <MenuItem
                disabled={props.contextMenu.tableId !== undefined && props.contextMenu.filtered}
                onClick={() => {
                    props.dispatch(closeTableContextMenu())
                    if (props.contextMenu.tableId !== undefined) {
                        editTable({
                            tableId: props.contextMenu.tableId,
                            type: Rp3CustomRuleType.FILTER_TABLE,
                        })
                    }
                }}
            >
                <ListItemIcon>
                    <FilterAltIcon/>
                </ListItemIcon>
                Filter Table
            </MenuItem>
            <MenuItem
                disabled={props.contextMenu.tableId !== undefined && !props.contextMenu.filtered}
                onClick={() => {
                    props.dispatch(closeTableContextMenu())
                    if (props.contextMenu.tableId !== undefined) {
                        editTable({
                            tableId: props.contextMenu.tableId,
                            type: Rp3CustomRuleType.NOT_FILTER_TABLE,
                        })
                    }
                }}
            >
                <ListItemIcon>
                    <FilterAltOffIcon/>
                </ListItemIcon>
                Not Filter Table
            </MenuItem>
        </Menu>
    </>
}

export {TableContextMenu}
