import {AnyAction} from "redux";

import {Rp3Table, Rp3MenuItem} from "../../../../../app/client/app/entity/Rp3Report";
import {TableOperation} from "../../../../../app/client/app/entity/ops/ops";


enum Rp2Actions {
    REPORT_SET_MENU_ITEMS = "REPORT.DATA_NORMALIZATION.SET_MENU_ITEMS",
    REPORT_SET_TABLES     = "REPORT.DATA_NORMALIZATION.SET_TABLES",
    REPORT_REPLACE_TABLE = "REPORT.DATA_NORMALIZATION.REPLACE_TABLE",

    OPEN_CONTEXT_MENU  = "REPORT.DATA_NORMALIZATION.TABLE_CONTEXT_MENU.OPEN",
    CLOSE_CONTEXT_MENU = "REPORT.DATA_NORMALIZATION.TABLE_CONTEXT_MENU.CLOSE",
}

function setMenuItems(menuItems: Rp3MenuItem[], pageNumber: number | undefined): AnyAction {
    return {type: Rp2Actions.REPORT_SET_MENU_ITEMS, menuItems, pageNumber}
}

function setTable(tables: Rp3Table[]): AnyAction {
    return {
        type: Rp2Actions.REPORT_SET_TABLES,
        tables: tables,
    }
}
function replaceTable(tables: TableOperation<Rp3Table>[]): AnyAction {
    return {type: Rp2Actions.REPORT_REPLACE_TABLE, tables}
}

function openTableContextMenu(
    x: number, y: number,
    rowIndex: number, columnIndex: number,
    tableId: string
): AnyAction {
    return {
        type: Rp2Actions.OPEN_CONTEXT_MENU,
        tableId: tableId,
        position: {
            x: x,
            y: y,
            rowIndex: rowIndex,
            columnIndex: columnIndex,
        },
    };
}

function closeTableContextMenu(): AnyAction {
    return {
        type: Rp2Actions.CLOSE_CONTEXT_MENU,
    }
}

export {
    Rp2Actions,
    setMenuItems,
    setTable, replaceTable,
    openTableContextMenu, closeTableContextMenu,
}
