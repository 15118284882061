import {AnalysisSettings, mapAnalysisSettingsDtoToValue} from "../settings/AnalysisSettings";
import {EntityDefinition} from "../settings/EntityDefinition";
import {FeatureFlagDefinition} from "../settings/FeatureDefinition";
import {OptionalField} from "../../../../../../qdep/data/util";

interface ReportModel {
    id: string | null
    name: string
    description: string

    analysisSettingsRevisions: AnalysisSettingsRevision[]
    entityModelRevisions: EntitiesModelRevisionOverview[]
}

interface AnalysisSettingsRevision {
    revisionId: string
    date: string
    settings: AnalysisSettings
}

interface EntitiesModelRevisionOverview {
    revisionId: string
    date: string
    name: string
    description: string

    attachedReports: AttachedReportRef[] | null
}

interface AttachedReportRef {
    prrKey: string
}

interface EntitiesModelRevision extends EntitiesModelRevisionOverview {
    entityDefinitions: EntityDefinition[]
    featureFlagDefinitions: FeatureFlagDefinition[]
}

function parseReportModel(data: Record<string, any>): ReportModel {
    return {
        id: data.id,
        name: data.name,
        description: data.description,
        analysisSettingsRevisions: (data.analysisSettingsRevisions as Record<string, any>[] || [])
            .map(revision => ({
                revisionId: revision.revisionId,
                date: revision.date,
                settings: mapAnalysisSettingsDtoToValue(revision.settings),
            })),
        entityModelRevisions: (data.entityModelRevisions as Record<string, any>[] || [])
            .map(revision => ({
                revisionId: revision.revisionId,
                date: revision.date,
                name: revision.name,
                description: revision.description,
                attachedReports: revision.attachedReports || null,
            })),
    }
}

// --------------------------------------------------------

interface ModelRevisionRefDto {
    modelId: string
    revisionId: string

    modelName: string
    revisionDate: string
}

interface EntityModelRevisionRef {
    modelId: string
    revisionId: string
    date: string
    modelName: string
    revisionName: string
    description: string
    entities: OptionalField<string[]>
    features: OptionalField<string[]>
}

interface ModelRevisionRef {
    modelId: string
    revisionId: string
}

interface ModelRefDto {
    modelId: string
    modelName: string
}

// --------------------------------------------------------

export type {
    ReportModel,
    AnalysisSettingsRevision,
    EntitiesModelRevisionOverview, EntitiesModelRevision,
    ModelRefDto,
    ModelRevisionRef, ModelRevisionRefDto, EntityModelRevisionRef,
}
export {parseReportModel}
