import React from "react";
import {AnyAction} from "redux";

import LinkIcon from "@mui/icons-material/Link";

import {Label} from "../../../../qdep/components/app/util/label/Label";
import {PRRKey} from "../../../../app/client/app/entity/Project";
import {ReportMenuContainer} from "../base/report-menu/ReportMenuContainer";
import {ReportMenuGroupContainer} from "../base/report-menu/ReportMenuGroupContainer";
import {ReportMenuItemLink, ReportMenuItemList} from "../base/report-menu/ReportMenuItemList";
import {Rp6MenuItemState} from "./data/reducer";
import {BaseMenuState} from "../base/report-menu/state";


interface ReportMenuProps {
    prrKey: PRRKey
    menu: BaseMenuState<Rp6MenuItemState>
    dispatch: React.Dispatch<AnyAction>
}

const ReportMenu = (props: ReportMenuProps) => {
    return <ReportMenuContainer prrKey={props.prrKey} dispatch={props.dispatch}>
        <ReportMenuGroupContainer isHighLevelGroup label={"Pages"}>
            <ReportMenuItemList>
                { props.menu.items.map((item, index) =>
                    <ReportMenuItemLink
                        key={index}
                        index={index}
                        item={item}
                        itemClass={item.refs === 0 ? "no_refs" : undefined}
                    >
                        <span className={"rp6-menu-item-page"}>Page {item.page}</span>
                        <span className={"rp6-menu-item-desc"}>{item.tables} table(s)</span>
                        {
                            item.refs > 0 && <Label icon={<LinkIcon fontSize={"small"}/>} variant={"info"} text={item.refs} size={"small"}/>
                        }
                    </ReportMenuItemLink>
                )}
            </ReportMenuItemList>
        </ReportMenuGroupContainer>
    </ReportMenuContainer>
}

export {ReportMenu}
