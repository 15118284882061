import {EntityListSettingsState} from "../../../../qdep/components/entity-list-workspace-block/EntityListSettingsState";
import {ReportFile} from "../../../../app/client/app/entity/Report";
import {DataPiece} from "../../../../qdep/data/util";

interface ReportFilesListState extends EntityListSettingsState {
    data: DataPiece<ReportFile[]>
}

const defaultReportFilesListState: ReportFilesListState = {
    data: {isLoaded: false, data: []},
    settings: {
        pageSize: 25,
        columns: {
            "Filename": true,
            "Type": true,
            "Status": true,
            "Size": false,
            "Issues": true,
            "Creation date": true,
        }
    }
}

export type {ReportFilesListState}
export {defaultReportFilesListState}
