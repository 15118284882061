import {AnyAction} from "redux";
import produce from "immer";
import {WritableDraft} from "immer/dist/types/types-external";

import {OpenContextMenuProps, Rp6Actions} from "./action";
import {
    ExplicitCellRef,
    Rp6MenuItem,
    Rp6ModificationRule,
    Rp6Table,
    SourceCell,
    TableMatchRef
} from "../../../../../app/client/app/entity/Rp6Report";
import {defaultReportBaseState, ReportBaseState} from "../../base/report-workspace-header/data/ReportBaseState";
import {WithReportTablesWorkspace} from "../../base/report-tables-container/state";
import {
    closeTableContextMenuReducer,
    defaultTableContextMenuState,
    TableContextMenuState,
    WithTableContextMenu
} from "../../base/context-menu/TableContextMenuState";
import {defaultManualModificationRules, WithCustomRules} from "../../base/report-custom-rule-pane/data/CustomRules";
import {
    BaseMenuItemState,
    nextPageReducer,
    prevPageReducer,
    selectPageReducer,
    WithMenuState
} from "../../base/report-menu/state";
import {RpActions} from "../../base/report-page/action";


interface Rp6State extends
    ReportBaseState,
    WithMenuState<Rp6MenuItemState>,
    WithReportTablesWorkspace<Rp6Table>,
    WithTableContextMenu<Rp6ContextMenu>,
    WithCustomRules<Rp6ModificationRule>
{
    cellRefsDrawer: Rp6CellRefsDrawerState
}

type Rp6MenuItemState = BaseMenuItemState & Rp6MenuItem

interface Rp6CellRefsDrawerState {
    open: boolean
    ref: Rp6CellRefDetailsState | null
}

interface Rp6CellRefDetailsState {
    source: SourceCell
    dest: Rp6RefGroupState[]
}

interface Rp6RefGroupState {
    page: number
    refs: TableMatchRef[]
}

interface Rp6ContextMenu extends TableContextMenuState {
    refs: Rp6ContextRefs
}

interface Rp6ContextRef {
    refName: string
}

type Rp6ContextRefs = Rp6ContextRef[] | undefined

const defaultRp6State: Rp6State = {
    ...defaultReportBaseState,

    menu: {
        items: [],
        selectedItem: undefined,
        selectedPage: undefined,
    },
    tableWorkspace: {
        isLoading: true,
        tables: []
    },

    cellRefsDrawer: {
        open: false,
        ref: null,
    },

    contextMenu: {
        ...defaultTableContextMenuState,
        refs: []
    },

    manualModificationRules: defaultManualModificationRules,
}

function rp6Reducer(state: Rp6State = defaultRp6State, action: AnyAction): Rp6State {
    return produce(state, draft => {
        switch (action.type) {
            case RpActions.NAVIGATION_PREV_PAGE:
                prevPageReducer(draft)
                break
            case RpActions.NAVIGATION_NEXT_PAGE:
                nextPageReducer(draft)
                break
            case RpActions.NAVIGATION_MENU_ITEM:
                selectPageReducer(draft, action);
                break

            case Rp6Actions.REPORT_SET_MENU_ITEMS:
                setMenuItems(draft, action)
                break
            case Rp6Actions.REPORT_SET_TABLES:
                setTablesReducer(draft, action)
                break
            case Rp6Actions.OPEN_CELL_REF_DRAWER:
                openCellRefDrawerReducer(draft, action)
                break
            case Rp6Actions.CLOSE_CELL_REF_DRAWER:
                closeCellRefDrawerReducer(draft)
                break
            case Rp6Actions.OPEN_CONTEXT_MENU:
                openTableContextMenuReducer(draft, action)
                break
            case Rp6Actions.CLOSE_CONTEXT_MENU:
                closeTableContextMenuReducer(draft, action)
                break
        }
        return draft
    })
}

// ------------------------------------------------------------------------------------------------------

function setMenuItems(state: WritableDraft<Rp6State>, action: AnyAction) {
    const menuItems: Rp6MenuItem[] = action.menu || defaultRp6State.menu.items;
    let pageNumber: number | undefined = action.page;

    if (pageNumber === undefined && menuItems.length > 0) {
        pageNumber = menuItems[0].page
    }

    const itemStates = menuItems.map<Rp6MenuItemState>(item => ({
        ...item,
        selected: pageNumber === item.page
    }));
    const selectedItem = itemStates.findIndex(item => item.selected);

    state.menu.items = itemStates
    state.menu.selectedItem = selectedItem === -1 ? undefined: selectedItem
    state.menu.selectedPage = pageNumber
}

function setTablesReducer(state: WritableDraft<Rp6State>, action: AnyAction) {
    const tables: Rp6Table[] | undefined = action.tables;
    if (tables === undefined) {
        return
    }

    state.tableWorkspace = {
        isLoading: false,
        tables: tables
    }
}

function openCellRefDrawerReducer(state: WritableDraft<Rp6State>, action: AnyAction) {
    const cellRef: ExplicitCellRef | undefined = action.ref
    if (cellRef === undefined) {
        return
    }

    const dest = Object.values(cellRef.destTable || {})
        .sort((a, b) => a.page - b.page)
        .reduce<Rp6RefGroupState[]>((prev, curr) => {
            const lastGroup = prev.length > 0 ? prev[prev.length - 1]: undefined
            if (lastGroup === undefined || lastGroup.page !== curr.page) {
                prev.push({
                    page: curr.page,
                    refs: [curr]
                })
            } else {
                prev[prev.length - 1].refs.push(curr)
            }
            return prev;
        }, []);

    const refDetails: Rp6CellRefDetailsState = {
        source: cellRef.source,
        dest: dest,
    }

    state.cellRefsDrawer = {
        open: true,
        ref: refDetails,
    }
}

function closeCellRefDrawerReducer(state: WritableDraft<Rp6State>) {
    state.cellRefsDrawer.open = false
    state.cellRefsDrawer.ref = null
}

function openTableContextMenuReducer(state: WritableDraft<Rp6State>, action: AnyAction) {
    const props: OpenContextMenuProps | undefined = action.props;
    if (props === undefined) {
        return
    }

    const table = state.tableWorkspace.tables.find(table => table.entityId === props.tableId);
    if (table) {
        const rowIndex = props.position.rowIndex;
        const tableRow = table.rows[rowIndex].cells;

        let correctedColumnIndex = 0;
        for (let i = 0; i < props.position.columnIndex; i++) {
            correctedColumnIndex += tableRow[i].colSpan
        }

        const refs: Rp6ContextRefs = []
        if (props.rowRefs !== null && props.rowRefs.size > 0) {
            for (let rowRefName of props.rowRefs) {
                refs.push({
                    refName: rowRefName,
                })
            }
        }

        state.contextMenu = {
            show: true,
            tableId: props.tableId,
            position: {
                x: props.position.x,
                y: props.position.y,
                columnIndex: correctedColumnIndex,
                rowIndex: rowIndex,
            },
            refs: refs,
        }
    }
}

export {rp6Reducer, defaultRp6State}
export type {
    Rp6State, Rp6ContextMenu, Rp6MenuItemState,
    Rp6ContextRef, Rp6ContextRefs, Rp6CellRefDetailsState, Rp6RefGroupState,
}
