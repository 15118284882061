import React from "react";
import _ from "lodash";

import {MenuItem} from "@mui/material";

import {DateParam} from "../../../../app/client/app/entity/report/settings/HeaderDateFormatSettings";
import {Selector} from "./Selector";

import styles from "./DateParamControl.module.css"


interface ComponentProps {
    date: DateParam
    readonly: boolean
    onChange: (date: Partial<DateParam>) => void
}

const containerClassName = ["field-control", styles.container].join(" ")

const DateParamControl = (props: ComponentProps) => {
    return <div className={containerClassName}>
        <Selector
            value={props.date.day}
            readonly={props.readonly}
            onChange={(value: number | null) => {
                props.onChange({day: value})
            }}
        >
            <MenuItem value={-1}><em>None</em></MenuItem>
            { _.range(1, 32).map(day =>
                <MenuItem key={day} value={day}>{day}</MenuItem>
            )}
        </Selector>
        <Selector
            value={props.date.month}
            readonly={props.readonly}
            onChange={(value: number | null) => {
                props.onChange({month: value})
            }}
        >
            <MenuItem value={-1}><em>None</em></MenuItem>
            <MenuItem value={1}>January</MenuItem>
            <MenuItem value={2}>February</MenuItem>
            <MenuItem value={3}>March</MenuItem>
            <MenuItem value={4}>April</MenuItem>
            <MenuItem value={5}>May</MenuItem>
            <MenuItem value={6}>June</MenuItem>
            <MenuItem value={7}>July</MenuItem>
            <MenuItem value={8}>August</MenuItem>
            <MenuItem value={9}>September</MenuItem>
            <MenuItem value={10}>October</MenuItem>
            <MenuItem value={11}>November</MenuItem>
            <MenuItem value={12}>December</MenuItem>
        </Selector>
    </div>
}

export {DateParamControl}