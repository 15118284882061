import {styled} from "@mui/material";
import ButtonBase, {ButtonBaseProps} from "@mui/material/ButtonBase";
import AddIcon from "@mui/icons-material/Add";

import styles from './EmptyList.module.css'


interface EmptyListAddItemButtonProps {
    onClick: () => void
}

const StyledButtonBase = styled((props: ButtonBaseProps) => (
    <ButtonBase {...props}/>
))({
    font: "inherit",
    padding: "8px 16px",
    color: "rgba(0, 0, 0, 0.54)",
    backgroundColor: "rgba(104, 112, 120, 0.14)",
    borderRadius: "10px",
    "&:hover": {
        backgroundColor: "rgba(104, 112, 120, 0.24)",
    },
})

const EmptyListAddItemButton = (props: EmptyListAddItemButtonProps) => {
    return <div className={styles.empty_list_container}>
        <StyledButtonBase onClick={props.onClick}>
            <AddIcon sx={{paddingRight: "8px"}}/>
            <span>Empty list. Click to add a new item</span>
        </StyledButtonBase>
    </div>
}

export {EmptyListAddItemButton}
