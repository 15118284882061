import {SelectableItem} from "../../../../qdep/data/util";
import {
    EntityCategory,
    EntityDefinition,
    EntityRelationshipsVariant,
    EntitySelectorRule,
    EntitySubItem, Feature
} from "../../../../app/client/app/entity/report/settings/EntityDefinition";
import _ from "lodash";
import {EntityOption} from "./sub-items-block/EntitySubItemEditor";


interface EntityDefinitionEditorState {
    entity: EntityDefinitionState
    relativeEntities: Map<string, EntityDefinitionState> | null
    entityTree: EntityTreeState
}

interface EntityTreeState {
    subItemTree: SubItemTreeState
    editorState: VariantEditorState | NodeEditorState | undefined
}

interface SubItemTreeState {
    selectedNodePath: number[]
    expandedNodes: string[]
}

interface NodeEditorState {
    type: "SubItem" | "Condition"
    entityPath: string[]
    entity: EntityDependencyNode
}

interface VariantEditorState {
    type: "Variant"
    name: string
    requiredFeatures: Feature[]
    excludedFeatures: Feature[]
}

enum EntitySelectorRuleType {
    REGEXP = "REGEXP",
    EQUAL = "EQUAL",
}

interface EntitySelectorRuleState {
    type: EntitySelectorRuleType
    value: string

    warningText?: string
}

interface SelectorWarnings {
    selectorIndex: number
    text: string
}

interface EntityDependencyNode {
    type: "ENTITY" | "CONDITION"
    nodeId: string
    isNew?: boolean

    // entity
    entity: EntityOption | null
    required: boolean
    inlineable: boolean
    inlined: boolean
    subItems: EntityDependencyNode[]
    requiredFeatures: Feature[]

    // condition
    conditionName: "SYSTEM_CONDITION_ANY" | "SYSTEM_RATIO_CHECK" | string | null
    absoluteValue: boolean
}

interface EntityDefinitionState {
    id: string | null
    disabled: boolean,
    entityName: string
    category: EntityCategory
    builtInFeatures: Feature[]

    selectors: SelectableItem<EntitySelectorRuleState>[]
    variants: EntityRelationshipsVariantState[]
}

interface EntityRelationshipsVariantState {
    nodeId: string
    isNew?: boolean
    subItems: EntityDependencyNode[]
    requiredFeatures: Feature[]
    excludedFeatures: Feature[]
}

const defaultEntityDefinitionEditorState: EntityDefinitionEditorState = {
    entity: {
        id: null,
        disabled: false,
        entityName: "",
        category: EntityCategory.OTHER,
        builtInFeatures: [],
        selectors: [],
        variants: [],
    },
    relativeEntities: new Map(),
    entityTree: {
        subItemTree: {
            selectedNodePath: [],
            expandedNodes: [],
        },
        editorState: undefined,
    }
}

const mapFeatureSetToView = (features: Record<string, string> | null): Feature[] => {
    let result: Feature[] = []
    if (features !== null) {
        for (let entry of Object.entries(features)) {
            result.push({name: entry[0], value: entry[1]});
        }
    }
    return result
}

const mapFeatureSetFromView = (features: Feature[]): Record<string, string> => {
    let result = {}
    for (let feature of features) {
        _.merge(result, {[feature.name]: feature.value})
    }
    return result
}


function mapSubItemsToState(subItems: EntitySubItem[], parentId: string): EntityDependencyNode[] {
    const nodes: EntityDependencyNode[] = []
    for (let index = 0; index < subItems.length; index++){
        let item = subItems[index];
        const nodeId = `${parentId}/${index}`;

        if (item.entityName === "" && item.condition !== null) {
            const node: EntityDependencyNode = {
                type: "CONDITION",
                nodeId: nodeId,
                isNew: false,
                required: false,
                inlineable: false,
                inlined: false,
                entity: null,
                subItems: [],
                requiredFeatures: [],
                conditionName: item.condition.conditionName,
                absoluteValue: item.condition.absoluteValue,
            };

            if (item.condition.subItems !== null) {
                node.subItems = mapSubItemsToState(item.condition.subItems, node.nodeId)
            }
            nodes.push(node)
        } else {
            const node: EntityDependencyNode = {
                type: "ENTITY",
                nodeId: nodeId,

                isNew: false,
                entity: {
                    entityName: item.entityName,
                    category: EntityCategory.OTHER,
                    builtInFeatures: item.builtInFeatures || null,
                },
                required: item.required,
                inlineable: item.inlineable,
                subItems: [],
                inlined: false,
                requiredFeatures: mapFeatureSetToView(item.requiredFeatures),

                conditionName: null,
                absoluteValue: false
            };

            if (item.subItems.length > 0) {
                node.subItems = mapSubItemsToState(item.subItems, node.nodeId)
            } else if (item.condition !== null) {
                const conditionNodeId = nodeId + "/0";
                const conditionNode: EntityDependencyNode = {
                    type: "CONDITION",
                    nodeId: conditionNodeId,
                    isNew: false,
                    required: false,
                    inlineable: false,
                    inlined: false,
                    entity: null,
                    subItems: [],
                    requiredFeatures: [],
                    conditionName: item.condition.conditionName,
                    absoluteValue: item.condition.absoluteValue,
                };

                if (item.condition.subItems !== null) {
                    conditionNode.subItems = mapSubItemsToState(item.condition.subItems, conditionNode.nodeId)
                }

                node.subItems = [conditionNode];
            }
            nodes.push(node)
        }
    }
    return nodes
}
function mapEntityRelationshipsVariantToState(variants: EntityRelationshipsVariant[]): EntityRelationshipsVariantState[] {
    return variants.map((variant, index) => {
        const nodeId = index.toString(10);
        return {
            nodeId: nodeId,
            subItems: mapSubItemsToState(variant.subItems, nodeId),
            requiredFeatures: mapFeatureSetToView(variant.requiredFeatures),
            excludedFeatures: variant.excludedFeatures || [],
        }
    })
}

function mapEntityDefinitionToState(entityDefinition: EntityDefinition): EntityDefinitionState {
    const mapSelector = (selector: EntitySelectorRule): EntitySelectorRuleState => {
        if (selector.equal) {
            return {
                type: EntitySelectorRuleType.EQUAL,
                value: selector.equal
            }
        }
        if (selector.regexp) {
            return {
                type: EntitySelectorRuleType.REGEXP,
                value: selector.regexp,
            }
        }
        return {
            type: EntitySelectorRuleType.EQUAL,
            value: ""
        }
    }

    return {
        id: entityDefinition.id,
        disabled: entityDefinition.disabled || false,
        entityName: entityDefinition.entityName,
        category: entityDefinition.category,
        builtInFeatures: mapFeatureSetToView(entityDefinition.builtInFeatures),
        selectors: entityDefinition.selectors.map(selector => ({isSelected: false, data: mapSelector(selector)})),
        variants: mapEntityRelationshipsVariantToState(entityDefinition.variants)
    }
}

function mapSubItemsState(nodes: EntityDependencyNode[]): EntitySubItem[] {
    const items : EntitySubItem[] = []

    for (let node of nodes) {
        if (node.inlined) {
            continue
        }

        switch (node.type) {
            case "ENTITY": {
                const subItem: EntitySubItem = {
                    entityName: node.entity?.entityName || "Invalid",
                    builtInFeatures: node.entity?.builtInFeatures || null,
                    required: node.required,
                    inlineable: node.inlineable,
                    requiredFeatures: mapFeatureSetFromView(node.requiredFeatures),
                    subItems: [],
                    condition: null
                }
                if (node.subItems.length === 1 && node.subItems[0].type === "CONDITION") {
                    subItem.condition = {
                        conditionName: node.subItems[0].conditionName || "UNKNOWN",
                        absoluteValue: node.subItems[0].absoluteValue,
                        subItems: [],
                    }
                    if (node.subItems[0].subItems.length > 0) {
                        subItem.condition.subItems = mapSubItemsState(node.subItems[0].subItems)
                    }
                } else if (node.subItems.length > 0) {
                    subItem.subItems = mapSubItemsState(node.subItems)
                }
                items.push(subItem)
                break
            }
            case "CONDITION": {
                const subItem: EntitySubItem = {
                    entityName: "",
                    builtInFeatures: null,
                    required: false,
                    inlineable: false,
                    requiredFeatures: null,
                    subItems: [],
                    condition: {
                        conditionName: node.conditionName || "UNKNOWN",
                        absoluteValue: node.absoluteValue,
                        subItems: mapSubItemsState(node.subItems),
                    }
                }
                items.push(subItem)
                break
            }
        }
    }

    return items
}

function mapStateToEntityDefinition(state: EntityDefinitionState): EntityDefinition {
    const mapSelector = (selector: EntitySelectorRuleState): EntitySelectorRule => {
        switch(selector.type){
            case EntitySelectorRuleType.EQUAL:
                return {equal: selector.value, regexp: undefined}
            case EntitySelectorRuleType.REGEXP:
                return {equal: undefined, regexp: selector.value}
        }
    }

    return {
        id: state.id,
        disabled: state.disabled,
        entityName: state.entityName,
        category: state.category,
        builtInFeatures: mapFeatureSetFromView(state.builtInFeatures),
        selectors: state.selectors
            .filter(selector => selector.data.value.trim() !== "")
            .map(selector => mapSelector(selector.data)),
        variants: state.variants.map(variant => ({
            subItems: mapSubItemsState(variant.subItems),
            requiredFeatures: mapFeatureSetFromView(variant.requiredFeatures),
            excludedFeatures: variant.excludedFeatures,
        })),
    }
}

function sortEntityDefinitions(entities: EntityDefinition[]): EntityDefinition[] {
    return [...entities]
        .sort((a, b) => {
            const aCategory = a.category || EntityCategory.OTHER;
            const bCategory = b.category || EntityCategory.OTHER;
            if (aCategory === bCategory) {
                return a.entityName.localeCompare(b.entityName)
            }
            return aCategory.localeCompare(bCategory)
        })
}

function mapEntityDefinitionsToOptions(entities: EntityDefinition[]): EntityOption[] {
    return entities
        .map(item => ({
            entityName: item.entityName,
            category: item.category || EntityCategory.OTHER,
            builtInFeatures: item.builtInFeatures,
        }))
}

export {
    defaultEntityDefinitionEditorState, EntitySelectorRuleType,
    mapEntityDefinitionToState, mapEntityRelationshipsVariantToState, mapStateToEntityDefinition,
    sortEntityDefinitions, mapEntityDefinitionsToOptions,
}
export type {
    EntityDefinitionEditorState,
    EntityDefinitionState,
    EntitySelectorRuleState, EntityDependencyNode, EntityRelationshipsVariantState,
    EntityTreeState, SubItemTreeState,
    NodeEditorState, VariantEditorState,
    SelectorWarnings,
}