import React from "react";
import {Button, ClickAwayListener, Grow, Paper, Popper} from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface ActionGroupButtonProps {
    menu: React.ReactElement
}

// todo https://mui.com/components/menus/#customization
const ActionGroupButton = (props: ActionGroupButtonProps) => {
    const [open, setOpen] = React.useState(false);

    return <ControlledActionGroupButton
        menu={props.menu}
        isOpen={open}
        open={() => setOpen(true)}
        close={() => setOpen(false)}
    />
}

interface ControlledActionGroupButtonProps {
    menu: React.ReactElement

    isOpen: boolean
    open: () => void
    close: () => void
}

const ControlledActionGroupButton = (props: ControlledActionGroupButtonProps) => {
    const anchorRef = React.useRef(null);

    return <div className="entity-table-control-panel-btn-group">
        <Button variant="outlined"
                sx={{
                    padding: "0 5px 0 12px",
                }}
                ref={anchorRef}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={() => props.isOpen ? props.close : props.open()}
        >
            Actions <ArrowDropDownIcon />
        </Button>
        <Popper
            open={props.isOpen}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            placement={"bottom-end"}
            style={{zIndex: 10}}
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin:
                            placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={() => props.close()}>
                            {props.menu}
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    </div>
}

export {ActionGroupButton, ControlledActionGroupButton}
