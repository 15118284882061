import {ReportRevisionDto} from "../../../app/client/app/entity/Report";

interface ReportOverviewWorkspaceState {
    entity: ReportRevisionDto
    numModificationErrors: number
}

const enum WorkspaceItemTypes {
    STAGES,
    TOC,
    ISSUES,
    MODIFICATION,
    ANALYSIS_SETTINGS,
    ENTITY_MODEL,
    FILES,
}

function slugToWorkspaceItemTypes(slug: string | undefined): WorkspaceItemTypes {
    switch (slug) {
        case "stages": return WorkspaceItemTypes.STAGES
        case "toc": return WorkspaceItemTypes.TOC
        case "issues": return WorkspaceItemTypes.ISSUES
        case "modification": return WorkspaceItemTypes.MODIFICATION
        case "settings": return WorkspaceItemTypes.ANALYSIS_SETTINGS
        case "entity": return WorkspaceItemTypes.ENTITY_MODEL
        case "attachments": return WorkspaceItemTypes.FILES
        default: return WorkspaceItemTypes.STAGES
    }
}

function workspaceItemTypesToSlug(item: WorkspaceItemTypes): string {
    switch (item) {
        case WorkspaceItemTypes.STAGES: return "stages"
        case WorkspaceItemTypes.TOC: return "toc"
        case WorkspaceItemTypes.ISSUES: return "issues"
        case WorkspaceItemTypes.MODIFICATION: return "modification"
        case WorkspaceItemTypes.ANALYSIS_SETTINGS: return "settings"
        case WorkspaceItemTypes.ENTITY_MODEL: return "entity"
        case WorkspaceItemTypes.FILES: return "attachments"
    }
}

export {WorkspaceItemTypes, slugToWorkspaceItemTypes, workspaceItemTypesToSlug}
export type {ReportOverviewWorkspaceState}
