import React, {useReducer} from "react";
import {useTitle} from "react-use";

import {ReportMenu} from "./ReportMenu";
import {defaultRp6State, rp6Reducer} from "./data/reducer";
import {useReportPageAddress} from "../base/useReportPageAddress";

import './ReportCrossAnalysisPage.css'
import {
    withBaseReducer,
    withModificationRuleReducer,
    withReportTablesContainerReducer
} from "../base/reportPageReducer";
import {ReportStagePage} from "../base/report-page/ReportStagePage";
import {Rp6Body} from "./rp6_body";
import {ReportWorkspace} from "../base/report-workspace/ReportWorkspace";


const pageReducer = withReportTablesContainerReducer(
    withModificationRuleReducer(
        withBaseReducer(
            rp6Reducer
        )
    )
)

const ReportCrossAnalysisPage = () => {
    const address = useReportPageAddress();
    const [state, dispatch] = useReducer(pageReducer, defaultRp6State);
    useTitle(state.reportName || "FINHOUND", {restoreOnUnmount: true})

    return <ReportStagePage>
        <ReportMenu
            prrKey={address.prrKey}
            menu={state.menu}
            dispatch={dispatch}
        />
        <ReportWorkspace>
            <Rp6Body address={address} state={state} dispatch={dispatch}/>
        </ReportWorkspace>
    </ReportStagePage>
}

export {ReportCrossAnalysisPage}