import {defaultReportFilesListState, ReportFilesListState} from "./state";
import {AnyAction} from "redux";
import {AppActionTypes} from "../../../../app/data/action/AppAction";
import {
    selectRowReducer,
    updateDataReducer,
    updateSettingsReducer
} from "../../../../qdep/components/entity-list-workspace-block/EntityListSettingsState";
import {ReportFileWorkspaceActionTypes} from "./action";




function reportFilesListReducer(state: ReportFilesListState = defaultReportFilesListState, action: AnyAction): ReportFilesListState {
    switch (action.type) {
        case AppActionTypes.REPORT_FILE_LIST_REQUEST_SUCCEEDED:
            return updateDataReducer(state, action)
        case ReportFileWorkspaceActionTypes.UPDATE_SETTINGS:
            return updateSettingsReducer(state, action)
        case ReportFileWorkspaceActionTypes.SELECT_ROW:
            return selectRowReducer(state, action)
    }
    return state
}

export {reportFilesListReducer}