import React, {ReactElement} from "react";
import {AnyAction} from "redux";

import {MenuItem, Stack, TextField} from "@mui/material";

import {
    NegativeNumberViewType, SeparatorValue,
    TableTitleNormalizationSettings
} from "../../../../../app/client/app/entity/report/settings/TableTitleNormalizationSettings";
import {
    HeaderDateFormatSettings,
    HeaderDateFormatterType
} from "../../../../../app/client/app/entity/report/settings/HeaderDateFormatSettings";
import {FieldContainer} from "../../../../../qdep/components/app/workspace/Workspace";
import {updateDateFormatSettings, updateNormalizationSettings} from "../../editor_reducer";
import {FlagControl} from "../../../../../qdep/components/app/editor/FlagControl";
import {SelectorList, SelectorListItemProps} from "../../selectors-control/SelectorList";
import {Spacer} from "../../../../../qdep/components/app/util/spacer/Spacer";
import {DateParamControl} from "../../field-control/DateParamControl";
import {Selector} from "../../field-control/Selector";

import tabBodyClasses from '../TabBody.module.css'


interface DataNormalizationSettingsTabProps {
    tableTitleNormalization: TableTitleNormalizationSettings
    headerDateFormatSettings: HeaderDateFormatSettings
    dispatch: React.Dispatch<AnyAction>
    readonly: boolean
}

const DataNormalizationSettingsTab = (props: DataNormalizationSettingsTabProps) => {
    return <div className={tabBodyClasses.settings_group_container}>
        <div>
            <div className={tabBodyClasses.group_title}>Numeric Data Type</div>
            <FieldContainer title={"Negative Number Format"}>
                <Spacer/>
                <Selector
                    value={props.tableTitleNormalization.negativeNumberView}
                    readonly={props.readonly}
                    minWidth={"200px"}
                    onChange={(value: NegativeNumberViewType) => {
                        props.dispatch(updateNormalizationSettings({negativeNumberView: value}))
                    }}
                >
                    <MenuItem value={NegativeNumberViewType.ACCOUNTING}>ACCOUNTING</MenuItem>
                    <MenuItem value={NegativeNumberViewType.ARITHMETIC}>ARITHMETIC</MenuItem>
                </Selector>
            </FieldContainer>
        </div>

        <div>
            <div className={tabBodyClasses.subtitle}>Thousands Separator</div>
            <FieldContainer title={"Source"}>
                <Spacer/>
                <Selector
                    value={props.tableTitleNormalization.thousandsSeparator.source}
                    readonly={props.readonly}
                    minWidth={"200px"}
                    onChange={(value: SeparatorValue) => {
                        props.dispatch(updateNormalizationSettings({thousandsSeparator: {source: value}}))
                    }}
                >
                    <MenuItem value={SeparatorValue.COMMA}>COMMA</MenuItem>
                    <MenuItem value={SeparatorValue.POINT}>POINT</MenuItem>
                    <MenuItem value={SeparatorValue.SPACE}>SPACE</MenuItem>
                    <MenuItem value={SeparatorValue.NONE}>NONE</MenuItem>
                </Selector>
            </FieldContainer>
            <FieldContainer title={"View"}>
                <Spacer/>
                <Selector
                    value={props.tableTitleNormalization.thousandsSeparator.view}
                    readonly={props.readonly}
                    minWidth={"200px"}
                    onChange={(value: SeparatorValue) => {
                        props.dispatch(updateNormalizationSettings({thousandsSeparator: {view: value}}))
                    }}
                >
                    <MenuItem value={SeparatorValue.COMMA}>COMMA</MenuItem>
                    <MenuItem value={SeparatorValue.POINT}>POINT</MenuItem>
                    <MenuItem value={SeparatorValue.SPACE}>SPACE</MenuItem>
                    <MenuItem value={SeparatorValue.NONE}>NONE</MenuItem>
                </Selector>
            </FieldContainer>
        </div>

        <div>
            <div className={tabBodyClasses.subtitle}>Decimal Separator</div>
            <FieldContainer title={"Source"}>
                <Spacer/>
                <Selector
                    value={props.tableTitleNormalization.decimalSeparator.source}
                    readonly={props.readonly}
                    minWidth={"200px"}
                    onChange={(value: SeparatorValue) => {
                        props.dispatch(updateNormalizationSettings({decimalSeparator: {source: value}}))
                    }}
                >
                    <MenuItem value={SeparatorValue.COMMA}>COMMA</MenuItem>
                    <MenuItem value={SeparatorValue.POINT}>POINT</MenuItem>
                </Selector>
            </FieldContainer>
            <FieldContainer title={"View"}>
                <Spacer/>
                <Selector
                    value={props.tableTitleNormalization.decimalSeparator.view}
                    readonly={props.readonly}
                    minWidth={"200px"}
                    onChange={(value: SeparatorValue) => {
                        props.dispatch(updateNormalizationSettings({decimalSeparator: {view: value}}))
                    }}
                >
                    <MenuItem value={SeparatorValue.COMMA}>COMMA</MenuItem>
                    <MenuItem value={SeparatorValue.POINT}>POINT</MenuItem>
                </Selector>
            </FieldContainer>
        </div>

        <div>
            <div className={tabBodyClasses.group_title}>Period Data Type</div>
            <FieldContainer title={"Fiscal year start"}>
                <Spacer/>
                <DateParamControl
                    date={props.headerDateFormatSettings.fysDate}
                    readonly={props.readonly}
                    onChange={value => props.dispatch(updateDateFormatSettings({fysDate: value}))}
                />
            </FieldContainer>
            <FieldContainer title={"Fiscal year end"}>
                <Spacer/>
                <DateParamControl
                    date={props.headerDateFormatSettings.fyeDate}
                    readonly={props.readonly}
                    onChange={value => props.dispatch(updateDateFormatSettings({fyeDate: value}))}
                />
            </FieldContainer>
            <FlagControl
                label={"Fiscal year end as default table period"}
                value={props.headerDateFormatSettings.fyeAsDefault}
                readonly={props.readonly}
                onUpdate={value => props.dispatch(updateDateFormatSettings({fyeAsDefault: value}))}
            />

            <SelectorList
                initValues={props.headerDateFormatSettings.formatters}
                defaultValue={{type: HeaderDateFormatterType.REGEXP, value: ""}}
                readonly={props.readonly}
                onChange={value => props.dispatch(updateDateFormatSettings({formatters: value}))}
            >
                <SelectorList.Toolbar label={"Formatters"}/>
                <SelectorList.Body itemComponent={HeaderDateFormatterControl}/>
            </SelectorList>
        </div>

        <div>
            <div className={tabBodyClasses.group_title}>Auto Correction</div>
            <FlagControl
                label={"Bracket Sequence"}
                value={props.tableTitleNormalization.autoCorrection.bracketSequence}
                readonly={props.readonly}
                onUpdate={value => props.dispatch(updateNormalizationSettings({autoCorrection: {bracketSequence: value}}))}
            />
            <FlagControl
                label={"Float Number Delimiter"}
                value={props.tableTitleNormalization.autoCorrection.floatNumberDelimiter}
                readonly={props.readonly}
                onUpdate={value => props.dispatch(updateNormalizationSettings({autoCorrection: {floatNumberDelimiter: value}}))}
            />
        </div>
    </div>
}

const HeaderDateFormatterControl = (props: SelectorListItemProps) => {
    return <>
        <Selector
            value={props.value.data.type}
            readonly={props.readonly}
            onChange={(value: HeaderDateFormatterType) => {
                props.onUpdate(props.index, {data: {type: value}})
            }}
        >
            <MenuItem value={HeaderDateFormatterType.YEAR_RANGE}>YEAR_RANGE</MenuItem>
            <MenuItem value={HeaderDateFormatterType.REGEXP}>REGEXP</MenuItem>
            <MenuItem value={HeaderDateFormatterType.FYS_ALIAS}>FYS_ALIAS</MenuItem>
            <MenuItem value={HeaderDateFormatterType.FYE_ALIAS}>FYE_ALIAS</MenuItem>
        </Selector>
        { renderHeaderDateFormatter(props) }
    </>
}

function renderHeaderDateFormatter(props: SelectorListItemProps): ReactElement {
    switch (props.value.data.type) {
        case HeaderDateFormatterType.YEAR_RANGE:
            return <Stack direction="row" alignItems="center" spacing="8px">
                <TextField
                    sx={{flexGrow: 1}}
                    size="small"
                    label={"From"}
                    value={props.value.data.fromYear}
                    disabled={props.readonly}
                    onChange={(event) => {
                        const fromYear = event.target.value === "" ? undefined : parseInt(event.target.value)
                        if (fromYear === undefined || !isNaN(fromYear)) {
                            props.onUpdate(props.index, {
                                data: {
                                    fromYear: fromYear,
                                    regexp: undefined,
                                }
                            })
                        }
                    }}
                />
                <span>-</span>
                <TextField
                    sx={{flexGrow: 1}}
                    size="small"
                    label={"To"}
                    value={props.value.data.toYear}
                    disabled={props.readonly}
                    onChange={(event) => {
                        const toYear = event.target.value === "" ? undefined : parseInt(event.target.value)
                        if (toYear === undefined || !isNaN(toYear)) {
                            props.onUpdate(props.index, {
                                data: {
                                    toYear: toYear,
                                    regexp: undefined,
                                }
                            })
                        }
                    }}
                />
            </Stack>
        case HeaderDateFormatterType.REGEXP:
        case HeaderDateFormatterType.FYS_ALIAS:
        case HeaderDateFormatterType.FYE_ALIAS:
            return <TextField
                size="small"
                fullWidth
                value={props.value.data.regexp}
                disabled={props.readonly}
                onChange={(event) => {
                    props.onUpdate(props.index, {
                        data: {
                            fromYear: undefined,
                            toYear: undefined,
                            regexp: event.target.value,
                        }
                    })
                }}
            />
        default:
            return <TextField
                size="small"
                fullWidth
                disabled
                value={""}
            />
    }
}

export {DataNormalizationSettingsTab}