import React from "react";
import {useDispatch} from "react-redux";
import {useAsyncFn} from "react-use";
import {AnyAction} from "redux";
import {push} from "connected-react-router";
import {useSnackbar} from "notistack";

import {ListItemIcon, MenuItem, MenuList} from "@mui/material";
import AssessmentIcon from "@mui/icons-material/Assessment";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import {AddRevisionButton, AddRevisionDialog} from "./AddRevisionButton";
import {ControlledActionGroupButton} from "../../../../qdep/components/entity-list-workspace-block/ActionGroupButton";
import {addReportRevision} from "../data/reducer";
import {apiClient} from "../../../../app/client/app/client";
import {PRRKey} from "../../../../app/client/app/entity/Project";
import {EntityModelRevisionRef} from "../../../../app/client/app/entity/report/model/ReportModel";


interface RevisionActionGroupProps {
    projectId: string
    reportIndex: number
    selectedRevisionIndex: number | undefined
    dispatch: React.Dispatch<AnyAction>
}

const RevisionActionGroup = (props: RevisionActionGroupProps) => {
    const [open, setOpen] = React.useState(false);
    const [openAddRevisionDialog, setOpenAddRevisionDialog] = React.useState(false);
    const globalDispatch = useDispatch();

    const { enqueueSnackbar } = useSnackbar();
    let [, runAnalysis] = useAsyncFn(async (prrKey: PRRKey) => {
        const response = await apiClient.runReportRevisionAnalyse(prrKey);
        if (!response.errorReason && response.data && response.data.status === "ok" && response.data.stages) {
            enqueueSnackbar(
                "Report analysis started",
                {key: "report-revision-analyse-request", variant: "success"}
            );
        } else {
            enqueueSnackbar(
                "Something went wrong. Try again later or contact your administrator",
                {key: "report-revision-analyse-request", variant: "error"}
            );
        }
    }, [enqueueSnackbar]);

    return <>
        <ControlledActionGroupButton
            isOpen={open}
            open={() => setOpen(true)}
            close={() => setOpen(false)}

            menu={
                <MenuList id="split-button-menu">
                    <AddRevisionButton
                        onClick={() => {
                            setOpen(false)
                            setOpenAddRevisionDialog(true)
                        }}
                    />
                    <MenuItem
                        disabled={props.selectedRevisionIndex === undefined}
                        onClick={() => {
                            if (props.selectedRevisionIndex !== undefined) {
                                globalDispatch(push(`/report/${props.projectId}/${props.reportIndex}/${props.selectedRevisionIndex}`));
                            }
                            setOpen(false)
                        }}
                    >
                        <ListItemIcon>
                            <AssessmentIcon/>
                        </ListItemIcon>
                        Open Report
                    </MenuItem>
                    <MenuItem
                        disabled={props.selectedRevisionIndex === undefined}
                        onClick={() => {
                            if (props.selectedRevisionIndex !== undefined) {
                                runAnalysis({
                                    projectId: props.projectId,
                                    reportIndex: props.reportIndex,
                                    revisionIndex: props.selectedRevisionIndex,
                                })
                            }
                            setOpen(false)
                        }}
                    >
                        <ListItemIcon>
                            <RestartAltIcon/>
                        </ListItemIcon>
                        Restart
                    </MenuItem>
                </MenuList>
            }
        />
        <AddRevisionDialog
            isOpen={openAddRevisionDialog}
            close={() => setOpenAddRevisionDialog(false)}
            apply={(file: File, model: EntityModelRevisionRef | null) => {
                props.dispatch(addReportRevision(props.reportIndex, file, model))
                setOpenAddRevisionDialog(false)
            }}
        />
    </>
}

export {RevisionActionGroup}
