import {Label} from "../../../../../qdep/components/app/util/label/Label";
import React from "react";


interface ReportStageStatusLabelProps {
    value: string
    size?: "small" | "normal" | "large"
}

const ReportStageStatusLabel = (props: ReportStageStatusLabelProps) => {
    return <Label text={props.value} variant={stageStatusVariant(props.value)} size={props.size}/>
}

function stageStatusVariant(status: string): "success" | "error" | "info" | "disabled" | "warning" {
    switch (status) {
        case "SKIPPED":
            return "disabled"
        case "QUEUED":
        case "RUNNING":
            return "info"
        case "PASSED":
            return "success"
        case "UNKNOWN":
        case "ERROR":
            return "error"
        case "OUTDATED":
            return "warning"
    }
    return "info"
}

export {ReportStageStatusLabel}
