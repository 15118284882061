interface TableTitleNormalizationSettings {
    topLeftHeaderCellFeature: boolean
    thousandsSeparator: SeparatorSettings
    decimalSeparator: SeparatorSettings
    negativeNumberView: NegativeNumberViewType
    autoCorrection: Rp3AutoCorrectionSettings
}

const enum SeparatorValue {
    NONE  = "NONE",
    SPACE = "SPACE",
    COMMA = "COMMA",
    POINT = "POINT",
}

interface SeparatorSettings {
    source: SeparatorValue
    view: SeparatorValue
}

const enum NegativeNumberViewType {
    ACCOUNTING = "ACCOUNTING",
    ARITHMETIC = "ARITHMETIC",
}

interface Rp3AutoCorrectionSettings {
    bracketSequence: boolean
    floatNumberDelimiter: boolean
}

export {NegativeNumberViewType, SeparatorValue}
export type {TableTitleNormalizationSettings, SeparatorSettings, Rp3AutoCorrectionSettings}
