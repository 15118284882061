import React, {SyntheticEvent, useMemo} from "react";

import {alpha, styled, SvgIcon} from "@mui/material";
import {SvgIconProps} from "@mui/material/SvgIcon/SvgIcon";
import {TreeItemProps, TreeView} from "@mui/lab";
import TreeItem, {treeItemClasses} from '@mui/lab/TreeItem';
import collapseClasses from "@mui/material/Collapse/collapseClasses";
import ErrorOutlineSharpIcon from '@mui/icons-material/ErrorOutlineSharp';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

import {EntityRelationshipsVariantState, EntityDependencyNode} from "../state";
import {Label} from "../../../../../qdep/components/app/util/label/Label";
import {Feature, FeatureSet} from "../../../../../app/client/app/entity/report/settings/EntityDefinition";


const subItemInlinedGroup = "sub_item_inlined_group"
const subItemNodeContent = "sub_item_node_content"

const enum SubItemTreeNodeFlags {
    UNKNOWN = 0,
    NEW_NODE = 1 << 0,
    REQUIRED = 1 << 1,
    INLINEABLE = 1 << 2,
    INLINED = 1 << 3,
}

function entitySubItemStateFlags(item: EntityDependencyNode): SubItemTreeNodeFlags {
    let result: SubItemTreeNodeFlags = SubItemTreeNodeFlags.UNKNOWN
    if (item.isNew) {
        result |= SubItemTreeNodeFlags.NEW_NODE
    }
    if (item.required) {
        result |= SubItemTreeNodeFlags.REQUIRED
    }
    if (item.inlineable) {
        result |= SubItemTreeNodeFlags.INLINEABLE
    }
    if (item.inlined) {
        result |= SubItemTreeNodeFlags.INLINED
    }
    return result;
}

interface EntitySubItemTreeProps {
    rootName: string
    variants: EntityRelationshipsVariantState[]

    expandedNodes: string[]

    onSubItemToggle: (nodeIds: string) => void
    onSubItemSelect: (nodeId: string) => void
}

const EntitySubItemTree = (props: EntitySubItemTreeProps) => {
    return <TreeView
        defaultCollapseIcon={<MinusSquare/>}
        defaultExpandIcon={<PlusSquare/>}
        defaultEndIcon={<CloseSquare/>}
        expanded={props.expandedNodes}
        onNodeSelect={(event: SyntheticEvent, nodeIds: string) => {
            if (event.target instanceof Element) {
                if (event.target.closest(".MuiTreeItem-label")) {
                    props.onSubItemSelect(nodeIds)
                } else {
                    props.onSubItemToggle(nodeIds)
                }
            }
        }}
    >
        <StyledTreeItem nodeId={"root"} label={props.rootName}>
            { props.variants.map(variant => {
                return <VariantTreeNode key={`variant-${variant.nodeId}`} {...variant}/>
            })}
        </StyledTreeItem>
    </TreeView>
}

const VariantTreeNode = (props: EntityRelationshipsVariantState) => {
    return <StyledTreeItem
        nodeId={props.nodeId}
        label={<SubItemTreeNodeContent
            value={"Variant " + props.nodeId}
            builtInFeatures={{}}
            features={props.requiredFeatures}
            excludedFeatures={props.excludedFeatures}
            flags={props.isNew ? SubItemTreeNodeFlags.NEW_NODE: SubItemTreeNodeFlags.UNKNOWN}
        />}
    >
        { props.subItems.map(item => {
            return <SubItemTreeNode key={item.nodeId} {...item}/>
        })}
    </StyledTreeItem>
}

const SubItemTreeNode = (props: EntityDependencyNode) => {
    let label = "UNKNOWN"
    if (props.type === "ENTITY") {
        if (props.entity === null) {
            if (props.isNew) {
                label = "New Sub Item";
            }
        } else {
            label = props.entity.entityName
        }
    } else {
        switch (props.conditionName) {
            case "SYSTEM_CONDITION_ANY":
                label = "Condition: Any (*)"
                break
            case "SYSTEM_RATIO_CHECK":
                label = "Condition: Ratio Check (19)"
                break
            case "SYSTEM_NEGATIVE_CONTEXT_VALUE":
                label = "Condition: Negative context value"
                break
            default:
                label = "Condition: UNKNOWN"
                break
        }
    }

    const className = useMemo(() => {
            if (props.inlineable) {
                return subItemInlinedGroup
            }
            return undefined
        },
        [props.inlineable]
    );

    return <StyledTreeItem
        nodeId={props.nodeId}
        className={className}
        label={<SubItemTreeNodeContent
            value={label}
            builtInFeatures={props.entity?.builtInFeatures || {}}
            features={props.requiredFeatures}
            excludedFeatures={[]}
            flags={entitySubItemStateFlags(props)}
        />}
    >
        { props.subItems.map(item => {
            return <SubItemTreeNode key={item.nodeId} {...item}/>
        })}
    </StyledTreeItem>;
}

interface SubItemTreeNodeContentProps {
    value: string
    flags: SubItemTreeNodeFlags
    builtInFeatures: FeatureSet
    features: Feature[]
    excludedFeatures: Feature[]
}

const SubItemTreeNodeContent = (props: SubItemTreeNodeContentProps) => {
    return <div className={subItemNodeContent}>
        <span>{ props.value }</span>
        { Object.values(props.builtInFeatures).map((feature, index) => <Label key={index} text={feature} variant={"disabled"}/>) }
        { props.features.map((feature, index) => <Label key={index} text={feature.value} variant={"info"}/>) }
        { props.excludedFeatures.map((feature, index) => <Label key={index} text={feature.value} variant={"excluded"}/>) }
        { !!(props.flags & SubItemTreeNodeFlags.NEW_NODE) && <Label text={"New"} variant={"success"}/> }
        { !!(props.flags & SubItemTreeNodeFlags.REQUIRED) && <ErrorOutlineSharpIcon color={"info"}/> }
        { !!(props.flags & SubItemTreeNodeFlags.INLINEABLE) && <MenuOpenIcon color={"info"}/> }
    </div>
}

function MinusSquare(props: SvgIconProps) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
    );
}

function PlusSquare(props: SvgIconProps) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
    );
}

function CloseSquare(props: SvgIconProps) {
    return (
        <SvgIcon
            className="close"
            fontSize="inherit"
            style={{ width: 14, height: 14 }}
            {...props}
        >
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
        </SvgIcon>
    );
}


const StyledTreeItem = styled((props: TreeItemProps) => (
    <TreeItem {...props}/>
))(({ theme }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
        '& .close': {
            opacity: 0.3,
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 15,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
        rowGap: 8,
    },
    [`& .${treeItemClasses.content}`]: {
        marginTop: 8,
        width: "calc(100% - 16px)",
    },
    [`& .${subItemNodeContent}`]: {
        minHeight: 32,
        margin: "4px 0",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        columnGap: 8,
        rowGap: 4,
    },
    [`& .${subItemInlinedGroup} > ul > div > div.${collapseClasses.wrapperInner}`]: {
        backgroundColor: "rgb(237, 241, 241)",
        marginTop: 8,
    },
    [`& .${subItemInlinedGroup} li .${treeItemClasses.content}`]: {
        color: "rgba(22, 25, 31, 0.6)",
    },
    [`& .${subItemInlinedGroup} li .${treeItemClasses.label}`]: {
        cursor: "not-allowed",
    },
    [`& .${subItemInlinedGroup} li .${treeItemClasses.content}:hover`]: {
        backgroundColor: "unset",
    },
    [`& .${subItemInlinedGroup} li .${treeItemClasses.content}.${treeItemClasses.selected}`]: {
        backgroundColor: "unset",
    },
    [`& .${subItemInlinedGroup} li:first-of-type .${treeItemClasses.content}`]: {
        marginTop: 0,
    },
}));

export {EntitySubItemTree}