import React from "react";

import {Select, SelectChangeEvent,} from "@mui/material";

interface ComponentProps<T> {
    value: T | undefined
    readonly: boolean
    minWidth?: string
    onChange: (value: T) => void
    children: React.ReactNode | React.ReactNode[]
}

function Selector<T>(props: ComponentProps<T>): JSX.Element {
    return <>
        <Select
            size="small"
            sx={{minWidth: props.minWidth}}
            value={props.value}
            disabled={props.readonly}
            onChange={(event: SelectChangeEvent<T>) => props.onChange(event.target.value as T)}
        >
            { props.children }
        </Select>
    </>
}

export {Selector}