import React from "react";

import tabBodyClasses from "../TabBody.module.css";
import {FieldContainer} from "../../../../../qdep/components/app/workspace/Workspace";
import {Spacer} from "../../../../../qdep/components/app/util/spacer/Spacer";


const HotkeysTab = () => {
    return <div className={tabBodyClasses.settings_group_container}>
        <div>
            <div className={tabBodyClasses.group_title}>Navigation</div>
            <FieldContainer title={"Open ToC"}>
                <Spacer/>
                <span>Ctrl + T</span>
            </FieldContainer>
            <FieldContainer title={"Next Report Page"}>
                <Spacer/>
                <div>Ctrl + Alt + &#x2193;</div>
            </FieldContainer>
            <FieldContainer title={"Previous Report Page"}>
                <Spacer/>
                <span>Ctrl + Alt + &#8593;</span>
            </FieldContainer>
        </div>
    </div>
}

export {HotkeysTab}