import React from "react";

import {WorkspaceBlock} from "../../../../../qdep/components/app/workspace/Workspace";
import {EntityListBlock} from "../../../../../qdep/components/entity-list-workspace-block/EntityListBlock";
import {TableTitle} from "../../../../../app/client/app/entity/report/TableTitle";
import {ReportIssue} from "../../../../../app/client/app/entity/Report";


interface ComponentProps {
    isLoaded: boolean
    data: ReportIssue[]
}

const IssueList = (props: ComponentProps) => {
    return <WorkspaceBlock>
        <EntityListBlock
            title={"Report Issues"}
            data={{
                isLoaded: props.isLoaded,
                data: props.data,
            }}
            columnSpec={[
                {name: "tableId", title: "TableId"},
                {name: "page", title: "Page"},
                {name: "title", title: "Title", format: (title: TableTitle) => title.originalText},
                {name: "type", title: "Type"},
                {name: "description", title: "Description"},
            ]}
        />
    </WorkspaceBlock>
}

export {IssueList}
