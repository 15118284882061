import React, {useEffect, useReducer, useState} from "react";
import {useParams} from "react-router-dom";

import {CircularProgress} from "@mui/material";

import {
    FieldContainer,
    WorkspaceBlock,
    WorkspaceBlockBody,
    WorkspaceBlockHeader,
    WorkspaceHeader
} from "../../../qdep/components/app/workspace/Workspace";
import {apiClient} from "../../../app/client/app/client";
import {addReport, closeReportEditor, initEntity, ProjectViewWorkspaceReducer} from "./data/reducer";
import {NewProjectViewState} from "./data/state";
import {ViewerTabs} from "./tabs/ViewerTabs";
import {ReportDto} from "../../../app/client/app/entity/Project";
import {AddReportDialog} from "./AddReportDialog";
import {ProjectStatusFormatter} from "../../util/ProjectStatusFormatter";
import {ProjectEditorBody} from "../component/ProjectEditorBody";
import {EntityModelRevisionRef} from "../../../app/client/app/entity/report/model/ReportModel";


interface RouteParams {
    id: string
}

const ProjectViewWorkspace = () => {
    const {id} = useParams<RouteParams>()

    const [editorState, setEditorState] = useState("initializing");
    const [state, dispatch] = useReducer(ProjectViewWorkspaceReducer, NewProjectViewState());


    useEffect(() => {
        apiClient
            .projectGet(id)
            .then(response => {
                if (response.data !== undefined) {
                    dispatch(initEntity(response.data))
                    setEditorState("initialized")
                }
            })
    }, [id])

    if (editorState === "initializing") {
        return <CircularProgress color="secondary"/>
    }

    return <>
        <ProjectEditorBody
            entity={state.entity}
            entityUpdates={state.updates}
            newRevisions={state.newRevisions}
        >
            <WorkspaceHeader>Project: {state.entity.name}</WorkspaceHeader>
            <WorkspaceBlock>
                <WorkspaceBlockHeader>Basic information</WorkspaceBlockHeader>
                <WorkspaceBlockBody>
                    <FieldContainer title={"Name"}>
                        <span>{state.entity.name}</span>
                    </FieldContainer>
                    <FieldContainer title={"Description"}>
                        <span>{state.entity.description || "-"}</span>
                    </FieldContainer>
                    <FieldContainer title={"Creation time"}>
                        <span>{state.entity.createDate.format('YYYY-MM-DD HH:mm') || "-"}</span>
                    </FieldContainer>
                    <FieldContainer title={"Last updated"}>
                        <span>{state.entity.updateDate.format('YYYY-MM-DD HH:mm') || "-"}</span>
                    </FieldContainer>

                    <FieldContainer title={"Number of reports"}>
                        <span>{state.entity.reports.length}</span>
                    </FieldContainer>
                    <FieldContainer title={"Latest report status"}>
                        <span><ProjectStatusFormatter value={state.entity.status}/></span>
                    </FieldContainer>
                </WorkspaceBlockBody>
            </WorkspaceBlock>

            <ViewerTabs
                projectId={id}
                reports={state.entity.reports}
                dispatch={dispatch}
            />
        </ProjectEditorBody>
        <AddReportDialog
            projectName={state.entity.name}
            isOpen={state.openNewReportDialog}
            close={() => dispatch(closeReportEditor())}
            apply={(report: ReportDto, model: EntityModelRevisionRef | null) => dispatch(addReport(report, model))}
        />
    </>
}

export {ProjectViewWorkspace}