import React, {useEffect, useState} from "react";

import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";

import {FieldContainer} from "../../../../../qdep/components/app/workspace/Workspace";
import {NumberInputController} from "../../../../../qdep/components/app/util/number-input/NumberInputController";
import {Rp1ContextMenuCellDetail} from "../data/reducer";
import {Rp1CustomRule, Rp1CustomRuleType} from "../../../../../app/client/app/entity/Rp1Report";


interface EditCellSpanDialogProps {
    isOpen: boolean
    close: () => void
    apply: (rule: Rp1CustomRule) => void

    tableId: string | undefined
    cellRef: Rp1ContextMenuCellDetail | undefined
}

const EditCellSpanDialog = (props: EditCellSpanDialogProps) => {
    const [state, setState] = useState(0);
    useEffect(() => {
        setState(props.cellRef !== undefined ? props.cellRef.columnSpan : 1)
    }, [props.cellRef])

    return <Dialog open={props.isOpen}>
        <DialogTitle>Edit Cell Span</DialogTitle>
        <DialogContent sx={{minWidth: "500px"}}>
            <FieldContainer title={"Table Id"}>
                <span className={"field-control"}>
                    { props.tableId || "UNKNOWN" }
                </span>
            </FieldContainer>
            <FieldContainer title={"Row"}>
                <span className={"field-control"}>
                    { props.cellRef !== undefined ? props.cellRef.row : "UNKNOWN" }
                </span>
            </FieldContainer>
            <FieldContainer title={"Column"}>
                <span className={"field-control"}>
                    { props.cellRef !== undefined ? `${props.cellRef.column} (${props.cellRef.columnIndex})` : "UNKNOWN" }
                </span>
            </FieldContainer>
            <FieldContainer title={"Start Index"}>
                <NumberInputController
                    minValue={1}
                    value={state}
                    onChange={setState}
                />
            </FieldContainer>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.close}>Cancel</Button>
            <Button
                variant="contained"
                color="secondary"
                disabled={props.tableId === undefined}
                onClick={() => {
                    if (props.tableId && props.cellRef) {
                        props.apply({
                            type: Rp1CustomRuleType.EDIT_CELL_COLSPAN,
                            tableId: props.tableId || "UNKNOWN",
                            editCell: {
                                rowIndex: props.cellRef.row,
                                columnIndex: props.cellRef.columnIndex,
                                colSpan: state,
                                value: undefined,
                            },
                        });
                    }
                }}
            >
                Apply
            </Button>
        </DialogActions>
    </Dialog>
}

export {EditCellSpanDialog}