import React from "react";

import {Checkbox, ListItemIcon, Menu, MenuItem, Stack} from "@mui/material";
import ClosedCaptionIcon from '@mui/icons-material/ClosedCaption';
import DataArrayIcon from '@mui/icons-material/DataArray';
import NumbersIcon from '@mui/icons-material/Numbers';
import LinkIcon from "@mui/icons-material/Link";
import LabelIcon from '@mui/icons-material/Label';
import LightbulbIcon from '@mui/icons-material/Lightbulb';

import {ReportFeatures} from "./data/ReportFeatures";
import {Spacer} from "../../../../../qdep/components/app/util/spacer/Spacer";


interface LayersMenuProps {
    anchorEl: Element | null
    close: () => void

    reportFeatures: ReportFeatures
    updateReportFeatures: (updates: ReportFeatures) => void
}

const LayersMenu = (props: LayersMenuProps) => {
    return <Menu
        open={props.anchorEl !== null}
        anchorEl={props.anchorEl}
        onClose={props.close}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
        <LayerMenuItem
            icon={<DataArrayIcon/>}
            featureName={"Row Context Groups"}
            featureValue={props.reportFeatures.rowDataGroup}
            onClick={() => props.updateReportFeatures({...props.reportFeatures, rowDataGroup: !props.reportFeatures.rowDataGroup})}
        />
        <LayerMenuItem
            icon={<ClosedCaptionIcon/>}
            featureName={"Entity Names"}
            offset={"48px"}
            disabled={!props.reportFeatures.rowDataGroup}
            featureValue={props.reportFeatures.rowDataGroupEntityNames}
            onClick={() => props.updateReportFeatures({...props.reportFeatures, rowDataGroupEntityNames: !props.reportFeatures.rowDataGroupEntityNames})}
        />
        <LayerMenuItem
            icon={<DataArrayIcon/>}
            featureName={"Column Context Groups"}
            featureValue={props.reportFeatures.columnDataGroup}
            onClick={() => props.updateReportFeatures({...props.reportFeatures, columnDataGroup: !props.reportFeatures.columnDataGroup})}
        />
        <LayerMenuItem
            icon={<LinkIcon/>}
            featureName={"References"}
            featureValue={props.reportFeatures.references}
            onClick={() => props.updateReportFeatures({...props.reportFeatures, references: !props.reportFeatures.references})}
        />
        <LayerMenuItem
            icon={<NumbersIcon/>}
            featureName={"Row Numbers"}
            featureValue={props.reportFeatures.rowNumbers}
            onClick={() => props.updateReportFeatures({...props.reportFeatures, rowNumbers: !props.reportFeatures.rowNumbers})}
        />
        <LayerMenuItem
            icon={<NumbersIcon/>}
            featureName={"Column Numbers"}
            featureValue={props.reportFeatures.columnNumbers}
            onClick={() => props.updateReportFeatures({...props.reportFeatures, columnNumbers: !props.reportFeatures.columnNumbers})}
        />
        <LayerMenuItem
            icon={<LightbulbIcon/>}
            featureName={"Show Gutter Icons"}
            featureValue={props.reportFeatures.showGutterIcons}
            onClick={() => props.updateReportFeatures({...props.reportFeatures, showGutterIcons: !props.reportFeatures.showGutterIcons})}
        />
        <LayerMenuItem
            icon={<LabelIcon/>}
            featureName={"Show Cell Value Tags"}
            featureValue={props.reportFeatures.showCellValueTags}
            onClick={() => props.updateReportFeatures({...props.reportFeatures, showCellValueTags: !props.reportFeatures.showCellValueTags})}
        />
    </Menu>
}

interface LayerMenuItemProps {
    icon: React.ReactElement
    featureName: string
    featureValue: boolean
    disabled?: boolean
    onClick: () => void
    offset?: string
}

const LayerMenuItem = (props: LayerMenuItemProps) => {
    return <MenuItem
        onClick={props.onClick}
        disabled={props.disabled}
        sx={{pl: props.offset ? props.offset : "16px"}}
    >
        <Stack direction={"row"} alignItems={"center"} width={"100%"}>
            <ListItemIcon>
                {props.icon}
            </ListItemIcon>
            <span>{props.featureName}</span>
            <Spacer minWidth={20}/>
            <Checkbox
                disableRipple
                sx={{padding: "0"}}
                checked={props.featureValue}
            />
        </Stack>
    </MenuItem>
}

export {LayersMenu}