import {useState} from "react";

import {Dialog, DialogContent, DialogTitle, IconButton} from "@mui/material";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import {PRRKey} from "../../../../../../app/client/app/entity/Project";
import {TocView} from "../../../../toc/TocView";
import {Spacer} from "../../../../../../qdep/components/app/util/spacer/Spacer";

import styles from './TocViewDialog.module.css'


interface DialogProps {
    prrKey: PRRKey
    isOpen: boolean
    close: () => void
}

const TocViewDialog = (props: DialogProps) => {
    const [hide, setHide] = useState<boolean>(true);

    return <Dialog
        fullWidth
        maxWidth="md"
        open={props.isOpen}
        onClose={props.close}
    >
        <DialogTitle className={styles.title_container}>
            <span>Table of contents</span>
            <Spacer/>
            <IconButton
                onClick={() => setHide(prev => !prev)}
            >
                <VisibilityOffIcon color={hide ? "secondary" : undefined}/>
            </IconButton>
        </DialogTitle>
        <DialogContent sx={{minHeight: "300px", height: "75vh"}}>
            <TocView prrKey={props.prrKey} hideItemWithoutTable={hide}/>
        </DialogContent>
    </Dialog>
}

export {TocViewDialog}