import React from "react";

import {Chip, Skeleton} from "@mui/material";
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';

import {Label} from "../../../../../qdep/components/app/util/label/Label";
import {TableTitle} from "../../../../../app/client/app/entity/report/TableTitle";

import styles from './TableTitleContainer.module.css'


interface TableTitleContainerProps {
    title: TableTitle | undefined
    tableId: string | undefined
}

const TableTitleContainer = (props: TableTitleContainerProps) => {
    return <div className={styles.container}>
        { (props.title === undefined) &&
            <div className={styles.title_value_container}>
                <span className={styles.title_value}><Skeleton width={"250px"}/></span>
            </div>
        }
        { (props.title !== undefined && !props.title.unknown) &&
            <div className={styles.title_value_container}>
                { (props.title.noteName !== null && props.title.noteName !== "") &&
                    <Label text={`Note ${props.title.noteName}`} variant={"info"}/>
                }
                <span className={styles.title_value}>{ props.title.titleText }</span>
                { (props.title.features !== undefined && props.title.features !== null) &&
                    Object.values(props.title.features).map((feature, index) =>
                        <Label
                            key={index}
                            variant={"info"}
                            containerStyles={{
                                fontSize: "0.8125rem",
                                fontWeight: 700,
                            }}
                            text={feature}
                        />
                    )
                }
                { props.title.continued && <Chip size={"small"} label={"continued"}/> }
            </div>
        }

        <div className={styles.title_description_container}>
            { (props.title !== undefined && props.title.unknown) &&
                <Chip size={"medium"}
                      color={"warning"}
                      icon={<ErrorRoundedIcon/>}
                      label={"Table Untitled"}
                />
            }
            <div className={styles.title_id_container}>
                { props.tableId === undefined && <Skeleton width={"350px"}/> }
                { props.tableId !== undefined &&
                    <>
                        <label>TableId:</label>
                        <span>{ props.tableId }</span>
                    </>
                }
            </div>
        </div>
    </div>
}

export {TableTitleContainer}
