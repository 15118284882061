import {AnyAction} from "redux";
import produce from "immer";

import {WritableDraft} from "immer/dist/types/types-external";
import {defaultRp7CoverageState, Rp7CoverageState} from "./state";
import {ActionTypes} from "./action";
import {Rp7CoverageTable, Rp7PageCoverageMenuItem} from "../../../../../app/client/app/entity/Rp7Report";


function rp7CoverageReducer(state: Rp7CoverageState = defaultRp7CoverageState, action: AnyAction): Rp7CoverageState {
    return produce(state, draft => {
        switch (action.type) {
            case ActionTypes.SET_COVERAGE_PAGE_LIST:
                setCoveragePageListReducer(draft, action)
                break
            case ActionTypes.SELECT_COVERAGE_PAGE:
                selectCoveragePageReducer(draft, action)
                break
            case ActionTypes.SET_COVERAGE_PAGE_TABLES:
                setCoveragePageTablesReducer(draft, action)
                break
        }
        return draft
    })
}

function setCoveragePageListReducer(state: WritableDraft<Rp7CoverageState>, action: AnyAction) {
    const items: Rp7PageCoverageMenuItem[] | undefined = action.items;
    if (items === undefined) {
        return
    }

    state.menu.isLoading = false;
    if (items.length > 0) {
        state.menu.items = items.map(item => ({
            ...item, selected: false,
        }));

        state.menu.selectedPage = items[0].page
        state.menu.selectedItem = 0
        state.menu.items[0].selected = true
    } else {
        state.menu.selectedPage = undefined
        state.menu.selectedItem = undefined
        state.menu.items = []
    }
}

function selectCoveragePageReducer(state: WritableDraft<Rp7CoverageState>, action: AnyAction) {
    const pageIndex: number | undefined = action.page;
    if (pageIndex === undefined) {
        return
    }

    const prevPage = state.menu.selectedItem
    if (prevPage !== undefined && prevPage < state.menu.items.length) {
        state.menu.items[prevPage].selected = false;
    }

    state.menu.selectedPage = state.menu.items[pageIndex].page
    state.menu.selectedItem = pageIndex
    state.menu.items[pageIndex].selected = true;
}

function setCoveragePageTablesReducer(state: WritableDraft<Rp7CoverageState>, action: AnyAction) {
    const tables: Rp7CoverageTable[] | undefined = action.tables;
    if (tables === undefined) {
        return
    }

    state.tableWorkspace.isLoading = false
    state.tableWorkspace.tables = tables
}

export {rp7CoverageReducer}