import {AnyAction} from "redux";
import {Rp7CoverageTable, Rp7PageCoverageMenuItem} from "../../../../../app/client/app/entity/Rp7Report";

const enum ActionTypes {

    SET_COVERAGE_PAGE_LIST   = "SET_COVERAGE_PAGE_LIST",
    SELECT_COVERAGE_PAGE     = "SELECT_COVERAGE_PAGE",
    SET_COVERAGE_PAGE_TABLES = "SET_COVERAGE_PAGE_TABLES",
}

function setCoveragePageList(items: Rp7PageCoverageMenuItem[]): AnyAction {
    return {type: ActionTypes.SET_COVERAGE_PAGE_LIST, items: items}
}

function selectCoveragePage(page: number): AnyAction {
    return {type: ActionTypes.SELECT_COVERAGE_PAGE, page: page}
}

function setCoveragePageTables(tables: Rp7CoverageTable[]): AnyAction {
    return {type: ActionTypes.SET_COVERAGE_PAGE_TABLES, tables: tables}
}

export {
    ActionTypes,
    setCoveragePageList, selectCoveragePage, setCoveragePageTables,
}
