import {ReactNode} from "react";

import {Workspace} from "../../../../../qdep/components/app/workspace/Workspace";
import {isNotAuthError} from "../../../../../app/client/types";
import {ChainErrorBoundary} from "../../../../../qdep/components/app/error/ChainErrorBoundary";

import styles from "./ReportWorkspace.module.css"


interface ReportWorkspaceProps {
    children: ReactNode | ReactNode[]
    alignCenter?: boolean
}

const ReportWorkspace = (props: ReportWorkspaceProps) => {
    return <Workspace className={props.alignCenter ? undefined: styles.report_workspace}>
        <ChainErrorBoundary errorMatcher={isNotAuthError} dependencyHash={"none"}>
            { props.children }
        </ChainErrorBoundary>
    </Workspace>
}

export {ReportWorkspace}
