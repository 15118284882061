import {Dispatch} from "redux";
import {WorkspaceBlock} from "../../../qdep/components/app/workspace/Workspace";
import {AppState} from "../../../app/state/state";
import {
    EntityListBlock,
    EntityListBlockAction,
    EntityListBlockProps
} from "../../../qdep/components/entity-list-workspace-block/EntityListBlock";
import {ActionGroupButton} from "../../../qdep/components/entity-list-workspace-block/ActionGroupButton";
import {ListItemIcon, MenuItem, MenuList} from "@mui/material";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AssessmentIcon from '@mui/icons-material/Assessment';
import {reportFileListRequest} from "../../../app/data/action/AppAction";
import {EntityListSettings} from "../../../qdep/components/entity-list-workspace-block/EntityListSettingsState";
import {connect} from "react-redux";
import {listSelectRow, updateSettings} from "./data/action";
import {defaultReportFilesListState} from "./data/state";
import {formatDate} from "../../../qdep/components/entity-list-workspace-block/formatters";
import {DefaultRowFactory} from "../../../qdep/components/entity-list-workspace-block/default/RowFactory";
import {RevisionStatusFormatter} from "../../util/RevisionStatusFormatter";

const mapStateToProps = (state: AppState): EntityListBlockProps => {
    const workspaceState = state.report.files || defaultReportFilesListState
    const data = workspaceState.data

    return {
        title: "Files",
        titleDetail: data.data !== undefined ? `(${data.data.length})`: "",

        selectedRow: workspaceState.selectedRow,
        rowFactory: DefaultRowFactory,

        settings: workspaceState.settings,
        data: workspaceState.data,

        columnSpec: [
            {name: "path", title: "Filename"},
            {name: "type", title: "Type"},
            {name: "status", title: "Status", format: (value: string) => <RevisionStatusFormatter value={value}/>},
            {name: "size", title: "Size"},
            {name: "issues", title: "Issues"},
            {name: "createDate", title: "Creation date", format: formatDate},
        ],
        actionGroup: <ActionGroupButton menu={
            <MenuList id="split-button-menu">
                <MenuItem
                    key="download"
                    disabled
                >
                    <ListItemIcon>
                        <CloudDownloadIcon/>
                    </ListItemIcon>
                    Download
                </MenuItem>
                <MenuItem
                    key="report"
                    disabled
                >
                    <ListItemIcon>
                        <AssessmentIcon/>
                    </ListItemIcon>
                    Report Analysis
                </MenuItem>
            </MenuList>
        }/>
    }
}

const mapDispatchToProps = (dispatch: Dispatch): EntityListBlockAction => {
    return {
        refresh: (limit: number) => dispatch(reportFileListRequest()),
        updateSettings: (settings: EntityListSettings) => dispatch(updateSettings(settings)),
        select: (index: number) => dispatch(listSelectRow(index)),
    }
}

const ReportFilesListBlock = connect(mapStateToProps, mapDispatchToProps)(EntityListBlock)

const ReportFilesListWorkspace = () => {
    return <WorkspaceBlock>
        <ReportFilesListBlock/>
    </WorkspaceBlock>
}

export {ReportFilesListWorkspace}