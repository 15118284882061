import {AnyAction} from "redux";
import produce from "immer";
import {WritableDraft} from "immer/dist/types/types-external";

import {EntitiesModelRevision} from "../../../../app/client/app/entity/report/model/ReportModel";
import {EntityDefinition, Feature} from "../../../../app/client/app/entity/report/settings/EntityDefinition";
import {FeatureFlagDefinition} from "../../../../app/client/app/entity/report/settings/FeatureDefinition";
import {defaultState, MenuItemState, State} from "./page_state";
import {ActionTypes} from "./page_action";
import {
    defaultEntityDefinitionEditorState,
    mapEntityDefinitionsToOptions,
    mapEntityDefinitionToState,
    sortEntityDefinitions
} from "../../entity/entity-editor/state";
import {entityDefinitionEditorReducer} from "../../entity/entity-editor/reducer";
import {defaultFeatureDefinitionEditorState, valueToState} from "../../entity/feature-editor/state";
import {featureEditorReducer} from "../../entity/feature-editor/reducer";


function pageReducer(state: State, action: AnyAction): State {
    return produce(state, draft => {
        if (action.type.startsWith("ENTITY_DEFINITION_EDITOR.")) {
            if (draft.entityDefinitionEditor !== undefined) {
                draft.entityDefinitionEditor = entityDefinitionEditorReducer(draft.entityDefinitionEditor, action);
            }
        } else if (action.type.startsWith("FEATURE_DEFINITION_EDITOR.")) {
            if (draft.featureDefinitionEditor !== undefined) {
                draft.featureDefinitionEditor = featureEditorReducer(draft.featureDefinitionEditor, action)
            }
        } else {
            switch (action.type) {
                case ActionTypes.SET_REVISION:
                    setRevisionReducer(draft, action);
                    break;

                // region entity definition
                case ActionTypes.SELECT_ENTITY_DEFINITION:
                    selectEntityDefinitionReducer(draft, action);
                    break;
                case ActionTypes.ADD_ENTITY_DEFINITION:
                    addEntityDefinitionReducer(draft, action);
                    break;
                case ActionTypes.DELETE_ENTITY_DEFINITION:
                    deleteDefinitionReducer(draft, action);
                    break;
                case ActionTypes.APPLY_ENTITY_DEFINITION_CHANGES:
                    applyEntityDefinitionChangesReducer(draft, action);
                    break;
                // endregion

                // region feature definition
                case ActionTypes.ADD_FEATURE_DEFINITION:
                    addFeatureDefinitionReducer(draft, action);
                    break;
                case ActionTypes.SELECT_FEATURE_DEFINITION:
                    selectFeatureDefinitionReducer(draft, action);
                    break;
                case ActionTypes.DELETE_FEATURE_DEFINITION:
                    deleteDefinitionReducer(draft, action);
                    break;
                case ActionTypes.APPLY_FEATURE_DEFINITION_CHANGES:
                    applyFeatureDefinitionChangesReducer(draft, action);
                    break;
                // endregion
            }
        }
        return draft
    })
}

function setRevisionReducer(state: WritableDraft<State>, action: AnyAction) {
    const revision: EntitiesModelRevision | undefined = action.revision;
    if (revision === undefined) {
        return
    }

    const entities = sortEntityDefinitions(revision.entityDefinitions)
    const features = revision.featureFlagDefinitions

    state.entities = entities
    state.features = features

    state.menuItems.features = features
        .map(featureDefinition => ({
            itemName: featureDefinition.featureFlagName,
            selected: false,
            new: false,
            deleted: false,
            edited: false,
        }))

    const featureOptions: Feature[] = []
    for (let feature of features) {
        for (let value of feature.values) {
            featureOptions.push({name: feature.featureFlagName, value: value.value})
        }
    }

    state.featureOptions = featureOptions
    state.entityVariants = mapEntityDefinitionsToOptions(entities)

    const entityMenuItems: MenuItemState[] = entities
        .map(entityDefinition => ({
            itemName: entityDefinition.entityName,
            selected: false,
            new: false,
            deleted: false,
            edited: false,
        }));
    state.menuItems.entities = entityMenuItems

    state.menuItems.selectedMenuItem.section = "ENTITY"
    if (entityMenuItems.length > 0) {
        state.menuItems.selectedMenuItem.isNew = false
        state.menuItems.selectedMenuItem.index = 0
        entityMenuItems[0].selected = true

        state.entityDefinitionEditor = {
            ...defaultEntityDefinitionEditorState,
            entity: mapEntityDefinitionToState(entities[0])
        }
    } else {
        state.menuItems.selectedMenuItem.isNew = true
        state.menuItems.selectedMenuItem.index = undefined
        state.entityDefinitionEditor = defaultState.entityDefinitionEditor
    }
}

// region entity definition
function selectEntityDefinitionReducer(state: WritableDraft<State>, action: AnyAction) {
    const currIndex: number | undefined = action.index;
    if (currIndex === undefined || currIndex < 0) {
        return
    }

    resetSelectedMenuItem(state)

    if (currIndex < state.menuItems.entities.length) {
        state.menuItems.selectedMenuItem.section = "ENTITY"
        state.menuItems.selectedMenuItem.isNew = false
        state.menuItems.selectedMenuItem.index = currIndex
        state.menuItems.entities[currIndex].selected = true

        state.entityDefinitionEditor = {
            ...defaultEntityDefinitionEditorState,
            entity: mapEntityDefinitionToState(state.entities[currIndex])
        }
        state.featureDefinitionEditor = undefined
    }

}

function addEntityDefinitionReducer(state: WritableDraft<State>, _: AnyAction) {
    resetSelectedMenuItem(state)

    state.menuItems.selectedMenuItem.section = "ENTITY"
    state.menuItems.selectedMenuItem.isNew = true
    state.menuItems.selectedMenuItem.index = undefined

    state.entityDefinitionEditor = defaultState.entityDefinitionEditor
    state.featureDefinitionEditor = undefined
}

function deleteDefinitionReducer(state: WritableDraft<State>, _: AnyAction) {
    if (state.menuItems.selectedMenuItem.index !== undefined && state.menuItems.selectedMenuItem.section !== undefined) {
        const definitionIndex = state.menuItems.selectedMenuItem.index
        const definitionSection = state.menuItems.selectedMenuItem.section

        if (definitionSection === "ENTITY") {
            if (definitionIndex >= 0 && definitionIndex < state.menuItems.entities.length) {
                state.menuItems.entities[definitionIndex].deleted = true;
            }
        } else if (definitionSection === "FEATURE") {
            if (definitionIndex >= 0 && definitionIndex < state.menuItems.features.length) {
                state.menuItems.features[definitionIndex].deleted = true;
            }
        }
    }
}

function applyEntityDefinitionChangesReducer(state: WritableDraft<State>, action: AnyAction) {
    const entityDefinition: EntityDefinition | undefined = action.entityDefinition;
    if (entityDefinition === undefined) {
        return
    }

    if (state.menuItems.selectedMenuItem.isNew) {
        state.entities.push(entityDefinition)

        state.menuItems.selectedMenuItem.isNew = false
        state.menuItems.selectedMenuItem.index = state.entities.length-1

        state.menuItems.entities.push({
            itemName: entityDefinition.entityName,
            new: true,
            selected: true,
            deleted: false,
            edited: false
        })

        state.entityVariants.push({
            category: entityDefinition.category,
            entityName: entityDefinition.entityName,
            builtInFeatures: entityDefinition.builtInFeatures,
        })
    } else if (state.menuItems.selectedMenuItem.index !== undefined) {
        const entityIndex = state.menuItems.selectedMenuItem.index
        const entity = state.entities[entityIndex];

        if (entity.entityName !== entityDefinition.entityName) {
            state.menuItems.entities[entityIndex].itemName = entityDefinition.entityName;
            state.entityVariants[entityIndex] = {
                entityName: entityDefinition.entityName,
                category: entityDefinition.category,
                builtInFeatures: entityDefinition.builtInFeatures,
            }
        }
        state.menuItems.entities[entityIndex].selected = true
        state.menuItems.entities[entityIndex].edited = true

        state.entities[entityIndex] = entityDefinition
    }
}
// endregion

// region feature definition
function addFeatureDefinitionReducer(state: WritableDraft<State>, _: AnyAction) {
    resetSelectedMenuItem(state)

    state.menuItems.selectedMenuItem.section = "FEATURE"
    state.menuItems.selectedMenuItem.isNew = true
    state.menuItems.selectedMenuItem.index = undefined

    state.featureDefinitionEditor = defaultFeatureDefinitionEditorState
    state.entityDefinitionEditor = undefined
}

function selectFeatureDefinitionReducer(state: WritableDraft<State>, action: AnyAction) {
    const currIndex: number | undefined = action.index;
    if (currIndex === undefined || currIndex < 0) {
        return
    }

    resetSelectedMenuItem(state)

    if (currIndex < state.menuItems.features.length) {
        state.menuItems.selectedMenuItem.section = "FEATURE"
        state.menuItems.selectedMenuItem.isNew = false
        state.menuItems.selectedMenuItem.index = currIndex
        state.menuItems.features[currIndex].selected = true

        state.featureDefinitionEditor = valueToState(state.features[currIndex])
        state.entityDefinitionEditor = undefined
    }
}

function applyFeatureDefinitionChangesReducer(state: WritableDraft<State>, action: AnyAction) {
    const featureDefinition: FeatureFlagDefinition | undefined = action.featureDefinition;
    if (featureDefinition === undefined) {
        return
    }

    if (state.menuItems.selectedMenuItem.isNew) {
        state.features.push(featureDefinition)
        state.menuItems.features.push({
            itemName: featureDefinition.featureFlagName,
            selected: true,
            new: true,
            deleted: false,
            edited: false
        })

        state.menuItems.selectedMenuItem.isNew = false
        state.menuItems.selectedMenuItem.index = state.features.length-1

        for (let value of featureDefinition.values) {
            state.featureOptions.push({
                name: featureDefinition.featureFlagName,
                value: value.value
            })
        }
    } else if (state.menuItems.selectedMenuItem.index !== undefined) {
        const featureIndex = state.menuItems.selectedMenuItem.index
        const feature = state.features[featureIndex]

        const featureOptions = new Map<string, Feature>();
        state.featureOptions
            .filter(option => option.name !== feature.featureFlagName)
            .forEach(option => featureOptions.set(`${option.name}/${option.value}`, option))

        for (let value of featureDefinition.values) {
            featureOptions.set(
                `${featureDefinition.featureFlagName}/${value.value}`,
                {name: featureDefinition.featureFlagName, value: value.value}
            )
        }
        state.featureOptions = [...featureOptions.values()]

        state.menuItems.features[featureIndex].itemName = featureDefinition.featureFlagName;
        state.menuItems.features[featureIndex].edited = true
        state.features[featureIndex] = featureDefinition
    }
}
// endregion

function resetSelectedMenuItem(state: WritableDraft<State>) {
    const prevIndex = state.menuItems.selectedMenuItem.index;
    if (prevIndex !== undefined && prevIndex >= 0) {
        if (state.menuItems.selectedMenuItem.section === "ENTITY") {
            if (prevIndex < state.menuItems.entities.length) {
                state.menuItems.entities[prevIndex].selected = false
            }
        } else if (state.menuItems.selectedMenuItem.section === "FEATURE") {
            if (prevIndex < state.menuItems.features.length) {
                state.menuItems.features[prevIndex].selected = false
            }
        }
    }
}


export {pageReducer}