import React from "react";

import {Switch} from "@mui/material";

import styles from './EnableFlag.module.css'


interface ComponentProps {
    enabled: boolean
    onChange: (value: boolean) => void
}

const EnableFlag = (props: ComponentProps) => {
    const value = props.enabled
    return <div className={[styles.container, value ? styles.enabled: styles.disabled].join(" ")}>
        <label>{ value ? "Enabled" : "Disabled" }</label>
        <Switch
            size={"small"}
            color={ value ? "success": "default"}
            checked={value}
            onChange={(_, checked) => props.onChange(checked)}
        />
    </div>
}

export {EnableFlag}