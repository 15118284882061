import React from "react";
import {NavLink} from "react-router-dom";

import './Menu.css'

interface MenuItemProps {
    title: string
    path: string
    exact?: boolean

    disabled?: boolean
}

const MenuItem = (props: MenuItemProps) => {
    return <li className={"menu-leaf-item"}>
        {
            props.disabled
                ? <span className={"disabled"}>{props.title}</span>
                : <NavLink to={props.path} activeClassName={"selected-item"} exact={props.exact}>{props.title}</NavLink>
        }
    </li>;
}

interface MenuGroupItemProps {
    title: string
    children: React.ReactElement | React.ReactElement[]
}

const MenuGroupItem = (props: MenuGroupItemProps) => {
    return <li className={"menu-leaf-item"}>
        <span>{props.title}</span>
        <ul className={"menu-group-item"}>
            { props.children }
        </ul>
    </li>
}

class Menu extends React.Component<{}, {}> {
    render(): React.ReactNode {
        return <div className={"main-menu-container"}>
            { this.props.children }
        </div>;
    }
}

class MenuNavigationContainer extends React.Component<{}, {}> {
    render() {
        return <nav>
            <ul>
                { this.props.children }
            </ul>
        </nav>;
    }
}

export { Menu, MenuItem, MenuGroupItem, MenuNavigationContainer }