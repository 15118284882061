import React, {useCallback, useEffect, useState} from "react";
import {Link} from "react-router-dom";

import {Button} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import KeyRoundedIcon from '@mui/icons-material/KeyRounded';

import {PRRKey} from "../../../app/client/app/entity/Project";
import {apiClient} from "../../../app/client/app/client";

import styles from './menu.module.css'
import {Spacer} from "../../../qdep/components/app/util/spacer/Spacer";


interface ComponentProps {
    prrKey: PRRKey
    onSelect: (prrKey: PRRKey) => void
}

const ReportMenu = ({ prrKey, onSelect }: ComponentProps) => {
    const [currentPRRKey, setCurrentKey] = useState(normalizeKey(prrKey));
    const [reports, setReports] = useState<ReportMenuItemData[] | undefined>(undefined);
    useEffect(() => {
        apiClient
            .projectGet(prrKey.projectId)
            .then(response => {
                if (response.errorReason === undefined && response.data !== undefined) {
                    const reports: ReportMenuItemData[] = []
                    for (let report of response.data.reports) {
                        const revisions: RevisionMenuItemData[] = []
                        for (let revision of report.revisions) {
                            revisions.push({
                                prrKey: {
                                    projectId: prrKey.projectId,
                                    reportIndex: report.index,
                                    revisionIndex: revision.index,
                                },
                                name: revision.filename,
                                keyRevision: false,
                            })
                        }

                        if (revisions.length > 0) {
                            revisions[revisions.length-1].keyRevision = true
                        }
                        reports.push({
                            name: report.name,
                            revisions: revisions
                        });
                    }
                    setReports(reports)
                }
            })
    }, [prrKey.projectId])
    const updatePRRKey = useCallback((prrKey: PRRKey) => {
        setCurrentKey(prrKey)
        onSelect(prrKey)
    }, [setCurrentKey, onSelect]);

    return <div className={styles.container}>
        <div className={styles.action_container}>
            <Link to={"/projects"}>
                <Button
                    variant={"text"}
                    color={"info"}
                    disableRipple
                    startIcon={<ArrowBackIcon/>}
                >
                    Back
                </Button>
            </Link>
        </div>

        <div className={styles.menu_container}>
            { reports !== undefined &&
                reports.map((report, index) =>
                    <ReportMenuItem key={index} {...report} selectedKey={currentPRRKey} onClick={updatePRRKey}/>
            )}
        </div>
    </div>
}

interface ReportMenuItemData {
    name: string
    revisions: RevisionMenuItemData[]
}

type ReportMenuItemProps = ReportMenuItemData & {
    selectedKey: PRRKey
    onClick: (prrkey: PRRKey) => void
}

const ReportMenuItem = (props: ReportMenuItemProps) => {
    return <div className={styles.report_menu_item}>
        <label>{props.name}</label>
        <ul>
            { props.revisions.map((revision, index) =>
                <RevisionMenuItem key={index} {...revision} selectedKey={props.selectedKey} onClick={props.onClick}/>
            )}
        </ul>
    </div>
}

interface RevisionMenuItemData {
    prrKey: PRRKey
    name: string
    keyRevision: boolean
}

type RevisionMenuItemProps = RevisionMenuItemData & {
    selectedKey: PRRKey
    onClick: (prrkey: PRRKey) => void
}

const RevisionMenuItem = (props: RevisionMenuItemProps) => {
    return <li className={styles.revision_menu_item}>
        <Link to={`/report/${props.prrKey.projectId}/${props.prrKey.reportIndex}/${props.prrKey.revisionIndex}`}>
            <div
                className={styles.revision_container}
                data-selected={comparePRRKey(props.selectedKey, props.prrKey)}
                onClick={() => props.onClick(props.prrKey)}
            >
                <span>{ props.name }</span>
                { props.keyRevision &&
                    <>
                        <Spacer minWidth={4}/>
                        <KeyRoundedIcon fontSize={"small"} htmlColor={"#027bf3"}/>
                    </>
                }
            </div>
        </Link>
    </li>
}

function comparePRRKey(currentKey: PRRKey, itemKey: PRRKey): boolean {
    let expectedReportIndex = currentKey.reportIndex
    let expectedRevisionIndex = currentKey.revisionIndex
    return expectedReportIndex === itemKey.reportIndex && expectedRevisionIndex === itemKey.revisionIndex;
}

function normalizeKey(prrKey: PRRKey): PRRKey {
    let reportIndex = prrKey.reportIndex
    if (typeof reportIndex === "string") {
        reportIndex = parseInt(reportIndex, 10)
    }

    let revisionIndex = prrKey.revisionIndex
    if (typeof revisionIndex === "string") {
        revisionIndex = parseInt(revisionIndex, 10)
    }

    return {
        projectId: prrKey.projectId,
        reportIndex: reportIndex,
        revisionIndex: revisionIndex,
    }
}

export {ReportMenu}