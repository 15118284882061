import {EntityDefinition, Feature} from "../../../../app/client/app/entity/report/settings/EntityDefinition";
import {FeatureFlagDefinition} from "../../../../app/client/app/entity/report/settings/FeatureDefinition";
import {
    defaultEntityDefinitionEditorState,
    EntityDefinitionEditorState
} from "../../entity/entity-editor/state";
import {FeatureDefinitionEditorState} from "../../entity/feature-editor/state";
import {EntityOption} from "../../entity/entity-editor/sub-items-block/EntitySubItemEditor";


interface MenuState {
    selectedMenuItem: {
        isNew: boolean
        section: "ENTITY" | "FEATURE" | undefined
        index: number | undefined
    }

    entities: MenuItemState[]
    features: MenuItemState[]
}

interface MenuItemState {
    itemName: string
    selected: boolean

    new: boolean
    edited: boolean
    deleted: boolean
}

interface State {
    menuItems: MenuState

    entities: EntityDefinition[]
    features: FeatureFlagDefinition[]

    entityVariants: EntityOption[]
    featureOptions: Feature[],

    entityDefinitionEditor: EntityDefinitionEditorState | undefined
    featureDefinitionEditor: FeatureDefinitionEditorState | undefined
}

const defaultState: State = {
    menuItems: {
        selectedMenuItem: {
            isNew: true,
            section: "ENTITY",
            index: undefined,
        },
        entities: [],
        features: [],
    },

    entities: [],
    features: [],

    entityVariants: [],
    featureOptions: [],

    entityDefinitionEditor: {
        ...defaultEntityDefinitionEditorState,
    },
    featureDefinitionEditor: undefined,
}

export type {
    State,
    MenuState, MenuItemState,
}
export {defaultState}
