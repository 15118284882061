import React from "react";
import {Link} from "react-router-dom";

import {Chip, Tooltip} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import RemoveIcon from '@mui/icons-material/Remove';

import {PRRKey} from "../../../../../app/client/app/entity/Project";
import {Label} from "../../../../../qdep/components/app/util/label/Label";
import {GutterTooltip} from "./GutterTolltip";
import {Rp3Cell} from "../../../../../app/client/app/entity/Rp3Report";
import {Rp4Cell} from "../../../../../app/client/app/entity/Rp4Report";

import styles from './CellTags.module.css'
import {Rp5Cell} from "../../../../../app/client/app/entity/Rp5Report";

class TagCounter {
    counter: number = 0

    getTagId(): number {
        return this.counter++
    }
}

type RpCell = Rp3Cell | Rp4Cell | Rp5Cell

// todo add tests!!!
function extractCellTags(cell: RpCell, prrKey: PRRKey): React.ReactNode {
    const cellValue = cell.value
    const cellTags = cell.tags
    const lexemes = cell.lexemes

    if (cellTags === null || cellTags.length === 0 || lexemes === null || lexemes.length === 0) {
        return cellValue
    }

    const result: React.ReactNode[] = []
    let valueOffset = 0
    const tagCounter = new TagCounter()
    for (let i = 0; i < cellTags.length; i++) {
        const tag = cellTags[i]
        const start = lexemes[tag.startPosition].startIndex
        const end = lexemes[tag.endIndex-1].endIndex

        if (valueOffset < start) {
            const value = cellValue.slice(valueOffset, start);
            if (value.length > 0) {
                result.push(<b key={tagCounter.getTagId()} className={styles.text_fragment}>{value}</b>)
            }
        }

        switch (tag.type) {
            case "LIST_ITEM":
                result.push(<span key={tagCounter.getTagId()} className={styles.list_item_tag}>{tag.value}.</span>)
                break
            case "PREFIX":
                result.push(<b key={tagCounter.getTagId()} className={styles.prefix_tag}>{tag.value}</b>)
                break
            case "ENTITY":
                result.push(<EntityTag key={tagCounter.getTagId()} prrKey={prrKey} name={tag.value} original={tag.originalValue} entityRef={tag.ref}/>)
                break
            case "ENTITY_RELATED":
                result.push(<Label
                    key={tagCounter.getTagId()}
                    textStyles={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: 300,
                    }}
                    icon={<KeyboardTabIcon fontSize={"small"}/>}
                    text={tag.value}
                    variant={"info"}
                />)
                break
            case "EXCLUDED_CLAUSE":
                result.push(<Label
                    key={tagCounter.getTagId()}
                    textStyles={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: 300,
                    }}
                    icon={<RemoveIcon fontSize={"small"}/>}
                    text={tag.value}
                    variant={"info"}
                />)
                break
            case "INCLUDED_CLAUSE":
                result.push(<Label
                    key={tagCounter.getTagId()}
                    textStyles={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: 300,
                    }}
                    icon={<AddIcon fontSize={"small"}/>}
                    text={tag.value}
                    variant={"info"}
                />)
                break
            case "FEATURE":
                result.push(<FeatureTag key={tagCounter.getTagId()} prrKey={prrKey} name={tag.value} original={tag.originalValue} featureRef={tag.ref}/>)
                break
            case "PERIOD":
                result.push(<PeriodTag key={tagCounter.getTagId()} period={tag.value} original={tag.originalValue}/>)
                break
            case "DEST_REF_NAME":
                result.push(<Label key={tagCounter.getTagId()} text={`Note ${tag.value}`} variant={"info"}/>)
                break
            case "SRC_TABLE_NOTE":
                result.push(
                    <Label
                        key={tagCounter.getTagId()}
                        icon={<SpeakerNotesIcon sx={{fontSize: "12px"}}/>}
                        variant={"disabled"}
                        size={"small"}
                        textStyles={{fontSize: "12px"}}
                        text={tag.value}
                    />
                )
                break
            default:
                result.push(<b key={tagCounter.getTagId()} className={styles.text_fragment}>{tag.value}</b>)
                break
        }

        valueOffset = Math.max(valueOffset, end)
    }

    if (valueOffset < cellValue.length) {
        const value = cellValue.slice(valueOffset, cellValue.length);
        if (value.length > 0) {
            result.push(<b key={tagCounter.getTagId()} className={styles.text_fragment}>{value}</b>)
        }
    }

    if (result.length > 0) {
        return <span className={styles.cell_container}>
            { result }
        </span>
    }

    return result[0];
}

interface EntityTagProps {
    prrKey: PRRKey
    name: string
    original: string
    entityRef: string | null
}

const EntityTag = (props: EntityTagProps) => {
    return <GutterTooltip
        title={
            <div>
                <div>Original Text: "{props.original}"</div>
                <div>
                    <Link
                        to={`/report/${props.prrKey.projectId}/${props.prrKey.reportIndex}/${props.prrKey.revisionIndex}/entity-detection?entity=${props.entityRef}`}
                        target="_blank"
                    >
                        Open entity definition
                    </Link>
                </div>
            </div>
        }
    >
        <div className={styles.tag_container}>
            <Label variant={"info"} text={props.name}/>
        </div>
    </GutterTooltip>
}

interface FeatureTagProps {
    prrKey: PRRKey
    name: string
    original: string
    featureRef: string | null
}
const FeatureTag = (props: FeatureTagProps) => {
    return <GutterTooltip
        title={
            <div>
                <div>Original Text: "{props.original}"</div>
                <div>
                    <Link
                        to={`/report/${props.prrKey.projectId}/${props.prrKey.reportIndex}/${props.prrKey.revisionIndex}/entity-detection?feature=${props.featureRef}`}
                        target="_blank"
                    >
                        Open feature definition
                    </Link>
                </div>
            </div>
        }
    >
        <Chip size={"small"} label={props.name}/>
    </GutterTooltip>
}

interface PeriodTagProps {
    period: string
    original: string
}
const PeriodTag = (props: PeriodTagProps) => {
    return <Tooltip
        title={props.original}
    >
        <Chip size={"small"} label={props.period}/>
    </Tooltip>
}

export {extractCellTags}
