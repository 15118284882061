import React from "react";

import {Divider, ListItemIcon, MenuItem, Stack} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import LinkOffIcon from '@mui/icons-material/LinkOff';
import LinkIcon from '@mui/icons-material/Link';

import {Label} from "../../../../../qdep/components/app/util/label/Label";
import {Rp5ContextDataGroup} from "../page/reducer";


interface DataGroupSubMenuProps {
    dataGroup: Rp5ContextDataGroup

    onEdit: () => void
    onDelete: () => void
    onDetach: () => void
    onAttach: () => void
}

const DataGroupSubMenu = (props: DataGroupSubMenuProps) => {
    return <>
        <Divider/>
        <li>
            <Stack direction={"row"} alignItems={"center"} spacing={"4px"} sx={{padding: "2px 16px", fontSize: "0.95rem"}}>
                <span>Data Group:</span>
                <Label text={`${props.dataGroup.name} (${props.dataGroup.orientation})`} variant={"info"}/>
            </Stack>
        </li>
        <MenuItem onClick={props.onEdit}>
            <ListItemIcon>
                <EditIcon/>
            </ListItemIcon>
            Edit Data Group
        </MenuItem>
        <MenuItem onClick={props.onDelete}>
            <ListItemIcon>
                <DeleteIcon/>
            </ListItemIcon>
            Delete Data Group
        </MenuItem>

        { !props.dataGroup.detached &&
            <MenuItem onClick={props.onDetach}>
                <ListItemIcon>
                    <LinkOffIcon/>
                </ListItemIcon>
                Detach Data Group
            </MenuItem>
        }
        { props.dataGroup.detached &&
            <MenuItem onClick={props.onAttach}>
                <ListItemIcon>
                    <LinkIcon/>
                </ListItemIcon>
                Attach Data Group
            </MenuItem>
        }
    </>
}
export {DataGroupSubMenu}