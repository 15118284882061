import React, {useReducer} from "react";
import {useMount} from "react-use";
import {useParams} from "react-router-dom";

import {apiClient, ApiResponse} from "../../../../app/client/app/client";
import {ModelRefDto} from "../../../../app/client/app/entity/report/model/ReportModel";
import {PageMenu} from "./menu";
import {Workspace, WorkspaceHeader} from "../../../../qdep/components/app/workspace/Workspace";
import {ModelEditorWorkspace} from "./workspace";
import {defaultState, pageReducer, setMenu, updateMenuItem} from "./page_reducer";

import styles from "./page.module.css";
import {Breadcrumbs, Typography} from "@mui/material";


interface RoutePageParams {
    modelId: string | undefined
}

const ReportModelEditorPage = () => {
    const routeParams = useParams<RoutePageParams>();

    const [state, dispatch] = useReducer(pageReducer, defaultState);

    useMount(() => {
        apiClient.listModelRefs()
            .then((response: ApiResponse<ModelRefDto[]>) => {
                if (response.errorReason === undefined && response.data !== undefined) {
                    dispatch(setMenu(response.data, routeParams.modelId))
                }
            })
    })

    return <>
        <PageMenu
            items={state.menu.items}
            selectedMenuItem={state.menu.selectedMenuItem}
            dispatch={dispatch}
        />
        <Workspace>
            <div className={styles.workspace_wrapper}>
                <Breadcrumbs sx={{marginBottom: "8px", marginTop: "8px", fontSize: "14px"}}>
                    <Typography sx={{fontSize: "14px"}}>Report Model</Typography>
                    <Typography sx={{fontSize: "14px"}}>{ routeParams.modelId }</Typography>
                </Breadcrumbs>
                <WorkspaceHeader>
                    Report Model
                </WorkspaceHeader>

                <ModelEditorWorkspace
                    modelId={
                        !state.menu.selectedMenuItem.isNew && state.menu.selectedMenuItem.index !== undefined
                            ? state.menu.items[state.menu.selectedMenuItem.index].itemId
                            : undefined
                    }
                    onUpdate={modelRef => dispatch(updateMenuItem(modelRef))}
                />
            </div>
        </Workspace>
    </>
}

export {ReportModelEditorPage}