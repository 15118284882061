import React, {useEffect, useReducer, useState} from "react";

import {WorkspaceBlock, WorkspaceBlockBody} from "../../../../qdep/components/app/workspace/Workspace";
import {PRRKey} from "../../../../app/client/app/entity/Project";
import {
    AnalysisSettings,
    DefaultReportAnalysisSettings
} from "../../../../app/client/app/entity/report/settings/AnalysisSettings";
import {apiClient} from "../../../../app/client/app/client";
import {AnalysisSettingEditor} from "../../analysis_settings/editor";
import {AnalysisSettingSkeleton} from "../../analysis_settings/skeleton";
import {BaseAnalysisSettingsRef} from "./BaseAnalysisSettingsRef";
import {analysisSettingsEditorReducer, setSettings} from "../../analysis_settings/editor_reducer";
import {EditorActionsContainer} from "../../../../qdep/components/app/editor/EditorActionsContainer";
import {SnackbarKey, SnackbarMessage, useSnackbar, VariantType} from "notistack";
import {useAsyncFn} from "react-use";
import {ReportStatusResult} from "../../../../app/client/app/entity/ops/ReportStatusResult";
import {LoadingButton} from "@mui/lab";


interface ComponentProps {
    prrKey: PRRKey
}

const AnalysisSettingsWorkspaceBlock = (props: ComponentProps) => {
    const [readonly, setReadonly] = useState(true);
    const [isLoading, setLoading] = useState(true);
    const [settings, settingsReducer] = useReducer(analysisSettingsEditorReducer, DefaultReportAnalysisSettings);

    useEffect(() => {
        setLoading(true)
        apiClient
            .getReportRevisionSettings(props.prrKey.projectId, props.prrKey.reportIndex, props.prrKey.revisionIndex)
            .then(response => {
                if (response.errorReason === undefined) {
                    if (response.data !== null && response.data !== undefined) {
                        setLoading(false)
                        settingsReducer(setSettings(response.data))
                    }
                }
            })
    }, [props.prrKey])

    // todo useUpdateReportSettingsAsync
    const { enqueueSnackbar } = useSnackbar();
    const [applySettingsState, applySettings] = useAsyncFn(async (prrKey: PRRKey, settings: AnalysisSettings) => {
        const response = await apiClient.updateReportRevisionSettings(prrKey, settings);

        const notificationKey: SnackbarKey = "update-report-revision-analysis-analysis_settings-request"
        let notificationVariant: VariantType = "error"
        let notificationMessage: SnackbarMessage = "Something went wrong. Try again later or contact your administrator"
        let opsResult: ReportStatusResult = {status: "failed", stages: undefined}
        if (!response.errorReason && response.data && response.data.status === "ok") {
            notificationVariant = "success"
            notificationMessage = "Report Settings updated. Refresh the page"
            opsResult = response.data
        }
        enqueueSnackbar(notificationMessage, {key: notificationKey, variant: notificationVariant});
        return opsResult
    }, [enqueueSnackbar]);

    return <>
        <WorkspaceBlock>
            <WorkspaceBlockBody>
                { settings === undefined
                    ? <AnalysisSettingSkeleton/>
                    : <div>
                        <BaseAnalysisSettingsRef
                            prrKey={props.prrKey}
                            modelRevisionRef={settings.analysisSettingsRef}
                            onAttach={ref => {
                                // todo update settings ref
                                // setSettings(prev => {
                                //     if (prev) {
                                //         return {
                                //             ...prev,
                                //             analysisSettingsRef: ref,
                                //         }
                                //     }
                                //     return prev
                                // })
                            }}
                            readonly={readonly}
                            updateReadOnly={setReadonly}
                        />
                        { isLoading
                            ? <AnalysisSettingSkeleton/>
                            : <AnalysisSettingEditor
                                state={settings}
                                dispatch={settingsReducer}
                                readonly={readonly}
                            />
                        }
                    </div>
                }
            </WorkspaceBlockBody>
        </WorkspaceBlock>
        { !readonly &&
            <EditorActionsContainer>
                {/* todo cancel action*/}
                <LoadingButton
                    variant="contained"
                    color="secondary"
                    loading={applySettingsState.loading}
                    onClick={() => {
                        applySettings(props.prrKey, settings)
                            .then((opsResult: ReportStatusResult) => {
                                if (opsResult.status === "ok") {
                                    // todo update report stage statuses
                                }
                            })
                    }}
                >
                    Apply
                </LoadingButton>
            </EditorActionsContainer>
        }
    </>
}

export {AnalysisSettingsWorkspaceBlock}
