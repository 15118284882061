import React from "react";

import {Checkbox, MenuItem, Select, TextField} from "@mui/material";

import {SelectableItem} from "../../../../../qdep/data/util";
import {FeatureValueSelectorState} from "../state";


interface FeatureValueSelectorProps extends SelectableItem<FeatureValueSelectorState> {
    onChange: (update: Partial<SelectableItem<Partial<FeatureValueSelectorState>>>) => void
}


const FeatureValueSelector = (props: FeatureValueSelectorProps) => {
    return <>
        <Checkbox
            checked={props.isSelected}
            onChange={(_, checked) => {
                props.onChange({isSelected: checked})
            }}
        />
        <Select
            size="small"
            value={"EQUAL"}
            disabled
        >
            <MenuItem value={"EQUAL"}>EQUAL</MenuItem>
        </Select>
        <TextField
            size="small"
            fullWidth
            value={props.data.value}
            onChange={event => {
                props.onChange({data: {value: event.target.value}})
            }}
        />
    </>
}

export {FeatureValueSelector}

