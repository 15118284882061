interface ColumnTriggerSelectorDto {
    regexp: string | undefined | null
    equal: string | undefined | null
    starts: string | undefined | null
    ends: string | undefined | null
    contains: string | undefined | null
}

interface ColumnRuleSettingsDto {
    enabled: boolean
    selectors: ColumnTriggerSelectorDto[]
}

enum ColumnTriggerSelectorType {
    REGEXP = "REGEXP",
    EQUAL = "EQUAL",
    STARTS_WITH = "STARTS_WITH",
    ENDS_WITH = "ENDS_WITH",
    CONTAINS = "CONTAINS",
}

interface ColumnTriggerSelector {
    type: ColumnTriggerSelectorType
    value: string
}

interface ColumnRuleSettings {
    enabled: boolean
    selectors: ColumnTriggerSelector[]
}

function mapColumnTriggerSelectorToDto(selector: ColumnTriggerSelector): ColumnTriggerSelectorDto {
    const value: ColumnTriggerSelectorDto = {
        contains: undefined, ends: undefined, equal: undefined, regexp: undefined, starts: undefined
    }
    switch (selector.type) {
        case ColumnTriggerSelectorType.REGEXP:
            value.regexp = selector.value
            break
        case ColumnTriggerSelectorType.EQUAL:
            value.equal = selector.value
            break
        case ColumnTriggerSelectorType.STARTS_WITH:
            value.starts = selector.value
            break
        case ColumnTriggerSelectorType.ENDS_WITH:
            value.ends = selector.value
            break
        case ColumnTriggerSelectorType.CONTAINS:
            value.contains = selector.value
            break
    }

    return value
}

function mapColumnTriggerSelectorDtoToValue(dto: ColumnTriggerSelectorDto): ColumnTriggerSelector {
    let type: ColumnTriggerSelectorType = ColumnTriggerSelectorType.REGEXP
    let value: string = ""

    if (dto.regexp !== undefined && dto.regexp !== null) {
        type = ColumnTriggerSelectorType.REGEXP
        value = dto.regexp
    }
    if (dto.equal !== undefined && dto.equal !== null) {
        type = ColumnTriggerSelectorType.EQUAL
        value = dto.equal
    }
    if (dto.starts !== undefined && dto.starts !== null) {
        type = ColumnTriggerSelectorType.STARTS_WITH
        value = dto.starts
    }
    if (dto.ends !== undefined && dto.ends !== null) {
        type = ColumnTriggerSelectorType.ENDS_WITH
        value = dto.ends
    }
    if (dto.contains !== undefined && dto.contains !== null) {
        type = ColumnTriggerSelectorType.CONTAINS
        value = dto.contains
    }

    return {
        type: type,
        value: value,
    };
}

export {
    ColumnTriggerSelectorType,
    mapColumnTriggerSelectorToDto, mapColumnTriggerSelectorDtoToValue,
}
export type {
    ColumnRuleSettingsDto, ColumnTriggerSelectorDto,
    ColumnRuleSettings, ColumnTriggerSelector,
}

