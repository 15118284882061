import {useHistory} from "react-router-dom";

import {ListItemIcon, MenuItem} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import {connect} from "react-redux";
import {AppState} from "../../../../app/state/state";

interface ViewButtonProps {
    projectId: string | undefined
}

const ViewButton = (props: ViewButtonProps) => {
    const history = useHistory();
    return <MenuItem
        disabled={props.projectId === undefined}
        onClick={() => {
            if (props.projectId !== undefined) {
                history.push(`/projects/${props.projectId}`)
            }
        }}
    >
        <ListItemIcon>
            <LaunchIcon/>
        </ListItemIcon>
        Open Project
    </MenuItem>
}

const mapStateToProps = (state: AppState): ViewButtonProps => {
    let projectId: string | undefined
    if (state.projects.selectedRow !== undefined && state.projects.selectedRow >= 0) {
        projectId = state.projects.data.data[state.projects.selectedRow].id
    }
    return {
        projectId: projectId
    };
}

const ConnectedViewButton = connect(mapStateToProps)(ViewButton)

export {ConnectedViewButton as ViewButton}