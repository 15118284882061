import {AnyAction} from "redux";

import {ReportOcrMenuItem, ReportOcrTable} from "../../../../../app/client/app/entity/Rp1Report";


enum ReportOcrActions {
    REPORT_OCR_SET_MENU_ITEMS = "REPORT_OCR.SET_MENU_ITEMS",
    REPORT_OCR_SET_TABLES     = "REPORT_OCR.SET_TABLES",

    OPEN_CONTEXT_MENU  = "REPORT.OCR_PARSING.TABLE_CONTEXT_MENU.OPEN",
    CLOSE_CONTEXT_MENU = "REPORT.OCR_PARSING.TABLE_CONTEXT_MENU.CLOSE",
}

function setMenuItems(items: ReportOcrMenuItem[], pageNumber: number | undefined): AnyAction {
    return {
        type: ReportOcrActions.REPORT_OCR_SET_MENU_ITEMS,
        items: items,
        pageNumber: pageNumber,
    }
}

function setTable(tables: ReportOcrTable[]): AnyAction {
    return {
        type: ReportOcrActions.REPORT_OCR_SET_TABLES,
        tables: tables,
    }
}

function openTableContextMenu(
    x: number, y: number,
    rowIndex: number, columnIndex: number,
    tableId: string
): AnyAction {
    return {
        type: ReportOcrActions.OPEN_CONTEXT_MENU,
        tableId: tableId,
        position: {
            x: x,
            y: y,
            rowIndex: rowIndex,
            columnIndex: columnIndex,
        },
    };
}

function closeTableContextMenu(): AnyAction {
    return {
        type: ReportOcrActions.CLOSE_CONTEXT_MENU,
    }
}

export {
    ReportOcrActions,
    setMenuItems, setTable,
    openTableContextMenu, closeTableContextMenu,
}
