import {useEffect, useState} from "react";
import {apiClient, ApiResponse} from "../../../../app/client/app/client";
import {ModelRefDto, ReportModel} from "../../../../app/client/app/entity/report/model/ReportModel";
import {ModelEditorSkeleton} from "./skeleton";
import {ModelEditor} from "./model-editor/editor";


interface ComponentProps {
    modelId: string | undefined
    onUpdate: (modelRef: ModelRefDto) => void
}

const ModelEditorWorkspace = (props: ComponentProps) => {
    const [state, setState] = useState<ReportModel | null>(null);
    useEffect(() => {
        if (props.modelId !== undefined) {
            apiClient
                .getModelById(props.modelId)
                .then((response: ApiResponse<ReportModel>) => {
                    if (response.errorReason === undefined && response.data !== undefined) {
                        setState(response.data)
                    }
                })
        } else {
            setState({
                id: null,
                name: "",
                description: "",
                analysisSettingsRevisions: [],
                entityModelRevisions: [],
            })
        }
    }, [props.modelId])

    return <>
        { state === null && <ModelEditorSkeleton/> }
        { state !== null &&
            <ModelEditor
                model={state}
                onUpdate={(model: ReportModel) => {
                    setState(model)
                    if (!!model.id) {
                        props.onUpdate({
                            modelId: model.id,
                            modelName: model.name,
                        });
                    }
                }}
            />
        }
    </>
}

export {ModelEditorWorkspace}