import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {useAsyncFn, useList} from "react-use";

import {Button} from "@mui/material";

import {FileUploadingProgress, ProgressDialog} from "./SavingProgressDialog";
import {apiClient, ApiResponse, RevisionFilePayload} from "../../../app/client/app/client";
import {ProjectDto} from "../../../app/client/app/entity/Project";
import {ProjectUpdates} from "../../../app/client/app/entity/ProjectUpdate";
import {projectCreateRequestSucceeded} from "../../../app/data/action/AppAction";

import styles from './ProjectEditorBody.module.css'


interface ComponentProps {
    children: React.ReactElement | React.ReactElement[]

    entity: ProjectDto
    entityUpdates?: ProjectUpdates
    newRevisions: RevisionFilePayload[]

}

const ProjectEditorBody = (props: ComponentProps) => {
    const history = useHistory();
    // todo get rid of
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [progress, {updateAt, set}] = useList<FileUploadingProgress>([]);

    const [saveProjectState, saveProject] = useAsyncFn(async (entity: ProjectDto, updates: ProjectUpdates | undefined): Promise<ProjectDto | undefined> => {
        if (entity.id === "") {
            const response = await apiClient.projectCreate(entity);
            if (response.errorReason === undefined && response.data !== undefined) {
                dispatch(projectCreateRequestSucceeded(response.data))
                return response.data
            }
        } else if (updates !== undefined) {
            const response = await apiClient.projectUpdate(entity.id, updates)
            if (response.errorReason === undefined && response.data !== undefined) {
                return entity
            }
        }
        return undefined
    })
    const [, uploadRevisions] = useAsyncFn(async (revisions: RevisionFilePayload[]): Promise<boolean> => {
        set(revisions.map(rev => ({
            name: rev.file.name,
            progress: rev.progress,
        })))
        for (let i = 0; i < revisions.length; i++) {
            const revision = revisions[i]
            const response: ApiResponse<string> = await apiClient.reportFileUpload(revision,
                progress => {
                    updateAt(i, {name: revision.file.name, progress: Math.floor(progress*100)})
                }
            )
            if (response.errorReason !== undefined) {
                return false
            }
        }
        return true
    }, [set, updateAt]);

    return <div className={styles.project_editor_block}>
        { props.children }

        <div className={styles.project_editor_action_container}>
            <Button onClick={() => history.goBack()}>
                Cancel
            </Button>
            <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                    setOpen(true);
                    saveProject(props.entity, props.entityUpdates)
                        .then(entity => {
                            if (entity !== undefined && props.newRevisions.length > 0) {
                                return uploadRevisions(props.newRevisions.map(item => ({
                                    ...item,
                                    project_id: entity.id,
                                })))
                            }
                            return true
                        })
                        .then(done => {
                            if (done) {
                                setOpen(false);
                                history.goBack()
                            }
                        })
                }}
            >
                Save
            </Button>
        </div>

        <ProgressDialog open={open} projectSaving={saveProjectState.loading} fileUploadProgress={progress}/>
    </div>
}

export {ProjectEditorBody}