import {AnyAction} from "redux";
import React, {useCallback} from "react";
import {useMount} from "react-use";

import {Drawer, Slide} from "@mui/material";

import {ReportPageAddress} from "../base/useReportPageAddress";
import {Rp6State} from "./data/reducer";
import {apiClient, ApiResponse, ErrorReason} from "../../../../app/client/app/client";
import {ReportRevisionDto} from "../../../../app/client/app/entity/Report";
import {setReportName, setReportStageStatus} from "../base/report-workspace-header/data/ReportBaseActions";
import {ExplicitCellRef, Rp6MenuItem, Rp6Table} from "../../../../app/client/app/entity/Rp6Report";
import {
    closeCellRefDrawer,
    openCellRefDrawer,
    openContextMenu,
    OpenContextMenuProps,
    setMenuItems,
    setTable
} from "./data/action";
import {startLoadingTables} from "../base/report-tables-container/state";
import {ReportWorkspaceHeader} from "../base/report-workspace-header/ReportWorkspaceHeader";
import {StageNames} from "../base/StageNames";
import {openCustomRulePane} from "../base/report-custom-rule-pane/data/action";
import {ReportTablesContainer} from "../base/report-tables-container/ReportTablesContainer";
import {ReportTable} from "./table/table";
import {TableContextMenu} from "./table-context-menu/TableContextMenu";
import {ManualModificationRulePane} from "../base/report-custom-rule-pane/ManualModificationRulePane";
import {Stage} from "../../../../app/client/app/entity/report/Stage";
import {CellRefsView} from "./cell_refs_view/CellRefsView";
import {usePageNavigation} from "../base/report-page/navigation/page_navigation";
import {selectMenuItem} from "../base/report-page/action";
import {useAuthErrorBoundaryContext} from "../../../../qdep/components/app/error/UnauthorizedErrorBoundary";


interface Props {
    address: ReportPageAddress
    state: Rp6State
    dispatch: React.Dispatch<AnyAction>
}

const Rp6Body = (props: Props) => {
    const state = props.state
    const dispatch = props.dispatch
    const address = props.address

    useMount(() => {
        apiClient
            .getReportRevision(address.prrKey)
            .then((response: ApiResponse<ReportRevisionDto>) => {
                if (response.data !== undefined) {
                    dispatch(setReportName(response.data.reportName))
                    dispatch(setReportStageStatus({
                        ocr: response.data.analysis.stages.ocr.status,
                        normalization: response.data.analysis.stages.normalization.status,
                        entityDetection: response.data.analysis.stages.entityDetection.status,
                        tableAnalysis: response.data.analysis.stages.tableAnalysis.status,
                        tableRefs: response.data.analysis.stages.tableRefs.status,
                        summary: response.data.analysis.stages.summary.status,
                    }))
                }
            })

        apiClient
            .getReportCrossAnalysisMenuItems(address.prrKey)
            .then((response: ApiResponse<Rp6MenuItem[]>) => {
                if (response.data !== undefined) {
                    dispatch(setMenuItems(response.data, address.query.page))
                }
            })
    })

    const { submitError } = useAuthErrorBoundaryContext()
    const loadPageTablesCallback = useCallback((page: number) => {
        dispatch(startLoadingTables())
        setTimeout(() => {
            apiClient
                .rp6ListPageTables(address.prrKey, page)
                .then((response: ApiResponse<Rp6Table[]>) => {
                    if (!response.errorReason && response.data !== undefined) {
                        dispatch(setTable(response.data))
                    } else {
                        const error = response.errorReason || ErrorReason.UNKNOWN_ERROR;
                        submitError(error)
                    }
                });
        }, 200)
        return
    }, [dispatch, address.prrKey, submitError]);
    const selectMenuItemCallback= useCallback(
        (itemIndex: number) => dispatch(selectMenuItem(itemIndex)),
        [dispatch]
    );
    usePageNavigation(selectMenuItemCallback, loadPageTablesCallback, state.menu.selectedPage)

    return <>
        <ReportWorkspaceHeader
            prrKey={address.prrKey}
            reportName={state.reportName}
            reportStage={StageNames.CROSS_TABLE_ANALYSIS}
            reportPage={state.menu.selectedPage}
            reportStageStatuses={state.reportStageStatuses}
            updateReportStatuses={stages => dispatch(setReportStageStatus(stages))}

            numCustomRuleUpdates={state.manualModificationRules.customRuleUpdates.length}
            openCustomRuleBlock={() => dispatch(openCustomRulePane())}
        />
        <ReportTablesContainer isLoading={state.tableWorkspace.isLoading}>
            {
                state.tableWorkspace.tables.map((table, index) =>
                    <ReportTable
                        key={`rt-${index}`}
                        table={table}
                        openCellRefDrawer={(ref: ExplicitCellRef) => dispatch(openCellRefDrawer(ref))}
                        openContextMenu={(props: OpenContextMenuProps) => dispatch(openContextMenu(props))}
                    />
                )
            }
        </ReportTablesContainer>

        <TableContextMenu contextMenu={state.contextMenu} dispatch={dispatch}/>

        <Slide
            direction={"up"}
            in={state.manualModificationRules.show}
            unmountOnExit
        >
            <div className={"report-bottom-sticky-container"}>
                <ManualModificationRulePane
                    prrKey={address.prrKey}
                    stage={Stage.CROSS_TABLE_ANALYSIS_STAGE}

                    tableId={state.manualModificationRules.selectedTableId}
                    tableCustomRuleResult={state.manualModificationRules.selectedTableCustomRuleResults}
                    ruleUpdates={state.manualModificationRules.selectedTableRuleUpdates}

                    dispatch={dispatch}
                />
            </div>
        </Slide>

        <Drawer
            anchor={"right"}
            PaperProps={{sx: {
                    maxWidth: "90%",
                    minWidth: "40%",
                }}}
            open={state.cellRefsDrawer.open}
            onClose={() => dispatch(closeCellRefDrawer())}
        >
            <CellRefsView
                cellRef={state.cellRefsDrawer.ref}
                onClose={() => dispatch(closeCellRefDrawer())}
            />
        </Drawer>
    </>
}

export {Rp6Body}