import React from "react";

import {Skeleton} from "@mui/material";

import {
    WorkspaceBlock,
    WorkspaceBlockBody,
    WorkspaceBlockHeader
} from "../../../../../qdep/components/app/workspace/Workspace";
import {TableTitleContainer} from "../report-table-title-container/TableTitleContainer";


const TableBlockSkeleton = () => {
    return <WorkspaceBlock>
        <WorkspaceBlockHeader>
            <TableTitleContainer title={undefined} tableId={undefined}/>
        </WorkspaceBlockHeader>
        <WorkspaceBlockBody>
            <Skeleton variant={"rectangular"} height={"200px"}/>
        </WorkspaceBlockBody>
    </WorkspaceBlock>
}

export {TableBlockSkeleton}