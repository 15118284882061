import React, {Dispatch} from "react";
import {AnyAction} from "redux";
import {Link} from "react-router-dom";

import {ReportMenuContainer} from "../../base/report-menu/ReportMenuContainer";
import {ReportMenuGroupContainer} from "../../base/report-menu/ReportMenuGroupContainer";
import {PRRKey} from "../../../../../app/client/app/entity/Project";
import {Rp7CoverageMenuState} from "./state";

import styles from './menu.module.css'
import {Skeleton} from "@mui/material";
import {Rp7PageCoverageMenuItemState} from "../data/state";
import {Label} from "../../../../../qdep/components/app/util/label/Label";
import {selectCoveragePage} from "./action";
import {ReportMenuItemList} from "../../base/report-menu/ReportMenuItemList";


interface PageMenuProps {
    prrKey: PRRKey
    state: Rp7CoverageMenuState
    dispatch: Dispatch<AnyAction>
}

const CoverageTableMenu = (props: PageMenuProps) => {
    return <ReportMenuContainer prrKey={props.prrKey} dispatch={props.dispatch}>
        <ReportMenuGroupContainer label={"Summary"}>
            <ReportMenuItemList>
                <OverviewMenuItem
                    label={"Overview"}
                    to={`/report/${props.prrKey.projectId}/${props.prrKey.reportIndex}/${props.prrKey.revisionIndex}/summary`}
                />
                <OverviewMenuItem
                    label={"Coverage"}
                    to={`/report/${props.prrKey.projectId}/${props.prrKey.reportIndex}/${props.prrKey.revisionIndex}/summary/coverage`}
                    selected
                />
                <OverviewMenuItem
                    label={"Issues"}
                    to={`/report/${props.prrKey.projectId}/${props.prrKey.reportIndex}/${props.prrKey.revisionIndex}/summary`}
                />
            </ReportMenuItemList>
        </ReportMenuGroupContainer>

        <ReportMenuGroupContainer label={"Pages"}>
            <ReportMenuItemList>
                { props.state.isLoading &&
                    <>
                        <PageMenuItemSkeleton/>
                        <PageMenuItemSkeleton/>
                        <PageMenuItemSkeleton/>
                    </>
                }
                { !props.state.isLoading &&
                    props.state.items.map((menuItem, index) =>
                        <PageCoverageMenuItem
                            key={"page_" + index}
                            onClick={() => props.dispatch(selectCoveragePage(index))}
                            {...menuItem}
                        />
                    )
                }
            </ReportMenuItemList>
        </ReportMenuGroupContainer>
    </ReportMenuContainer>
}

interface OverviewMenuItemProps {
    label: string
    selected?: boolean
    to: string
}

const OverviewMenuItem = (props: OverviewMenuItemProps) => {
    const itemClasses = [styles.menu_item]
    if (props.selected) {
        itemClasses.push(styles.selected)
    }

    return <li className={styles.menu_item_container}>
        <Link to={props.to}>
            <div className={itemClasses.join(" ")}>
                { props.label }
            </div>
        </Link>
    </li>;
}

const PageMenuItemSkeleton = () => {
    return <li className={[styles.menu_item_container, styles.skeleton].join(" ")}>
        <Skeleton/>
    </li>
}

type PageCoverageMenuItemProps = Rp7PageCoverageMenuItemState & {
    onClick: () => void
}

const PageCoverageMenuItem = (props: PageCoverageMenuItemProps) => {
    const itemClasses = [styles.menu_item]
    if (props.selected) {
        itemClasses.push(styles.selected)
    }

    return <li className={styles.menu_item_container}>
        <div className={itemClasses.join(" ")} onClick={props.onClick}>
            <span className={styles.menu_item_page_num}>Page {props.page}</span>
            <span className={styles.menu_item_page_tables}>{props.tables} table(s)</span>
            { renderCoverageLabel(props.minCoverageRate) }
        </div>
    </li>
}

function renderCoverageLabel(coverageRate: number) {
    const percent = coverageRate * 100
    let variant: "success" | "error" = "success"
    if (percent < 80) {
        variant = "error"
    }

    return <Label size={"small"} variant={variant} text={percent.toFixed(0) + "%"}/>;
}


export {CoverageTableMenu}