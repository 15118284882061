import {PRRKey} from "../../../../../app/client/app/entity/Project";
import {IssueList} from "./IssueList";
import {useMount} from "react-use";
import {useState} from "react";
import {ReportIssue} from "../../../../../app/client/app/entity/Report";
import {apiClient} from "../../../../../app/client/app/client";

interface ComponentProps {
    prrKey: PRRKey
}

const AutoLoadedIssueList = (props: ComponentProps) => {
    const [issues, setIssues] = useState<ReportIssue[] | null>(null);
    useMount(() => {
        apiClient
            .listReportIssue(props.prrKey)
            .then(response => {
                if (response.errorReason === undefined && response.data !== undefined) {
                    setIssues(response.data)
                }
            })
    })

    return <IssueList isLoaded={issues !== null} data={issues || []}/>
}

export {AutoLoadedIssueList}
