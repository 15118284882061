import React from "react";
import {connect, useDispatch} from "react-redux";
import {Redirect, Route, Switch} from "react-router-dom";

import {Button} from "@mui/material";

import {Main} from "../../qdep/components/app/main/Main";
import {Menu, MenuGroupItem, MenuItem, MenuNavigationContainer} from "../../qdep/components/app/menu/Menu";
import {Workspace} from "../../qdep/components/app/workspace/Workspace";
import {ProjectListWorkspace} from "../../workspace/project/list/ProjectListWorkspace";
import {ProjectEditorWorkspace} from "../../workspace/project/editor/ProjectEditorWorkspace";
import {ProjectViewWorkspace} from "../../workspace/project/view/ProjectViewWorkspace";
import {ReportFilesListWorkspace} from "../../workspace/report/files/ReportFilesList";
import {AppState} from "../state/state";
import {Spacer} from "../../qdep/components/app/util/spacer/Spacer";
import {logoutRequest} from "../../workspace/auth/login-block/data";
import {ProjectReportListWorkspace} from "../../workspace/report/list/ProjectReportList";


interface MainPageProps {
    isLogged: boolean
    appVersion: string
}

const MainPage = (props: MainPageProps) => {
    const dispatch = useDispatch();

    if (!props.isLogged) {
        return <Redirect to="/login"/>
    }

    return <Main>
        <Menu>
            <MenuNavigationContainer>
                <MenuItem title="Dashboard" path="/dashboard" disabled/>
                <MenuItem title="Projects" path="/projects"/>
                <MenuGroupItem title={"Reports"}>
                    <MenuItem title="List" path="/reports"/>
                    <MenuItem title="Files" path="/report/files"/>
                    <MenuItem title="Models" path="/report/models"/>
                </MenuGroupItem>
                <MenuItem title="Settings" path="/settings" disabled/>
            </MenuNavigationContainer>
            <Spacer/>
            <div className="main-menu-action-container">
                <Button
                    variant="outlined"
                    onClick={() => dispatch(logoutRequest())}
                >
                    Logout
                </Button>
                <span className={"platform-desc"}>{ props.appVersion }</span>
            </div>
        </Menu>
        <Workspace>
            <Switch>
                <Route exact path="/">
                    <Redirect to={"/projects"}/>
                </Route>
                <Route exact path={"/dashboard"}>
                    Dashboard
                </Route>
                <Route exact path={"/projects"}>
                    <ProjectListWorkspace/>
                </Route>
                <Route exact path={"/projects/create"}>
                    <ProjectEditorWorkspace/>
                </Route>
                <Route path={"/projects/:id"}>
                    <ProjectViewWorkspace/>
                </Route>
                <Route exact path={"/report/files"}>
                    <ReportFilesListWorkspace/>
                </Route>
                <Route exact path={"/reports"}>
                    <ProjectReportListWorkspace/>
                </Route>
                <Route exact path={"/analysis_settings"}>
                    Settings
                </Route>
            </Switch>
        </Workspace>
    </Main>;
}

const mapStateToProps = (state: AppState): Omit<MainPageProps, "appVersion"> => {
    return {
        isLogged: state.auth.isLogged,
    };
}

const ConnectedMainPage = connect(mapStateToProps)(MainPage)

export {ConnectedMainPage as MainPage}
