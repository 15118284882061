import {AnyAction} from "redux";
import produce from "immer";
import {WritableDraft} from "immer/dist/types/types-external";

import {Rp5Actions} from "./action";
import {
    Rp5CustomRule,
    Rp5MenuItem,
    Rp5RowFormulaTrigger,
    Rp5Table
} from "../../../../../app/client/app/entity/Rp5Report";
import {
    closeTableContextMenuImmerReducer,
    defaultTableContextMenuState,
    TableContextMenuState,
    WithTableContextMenu
} from "../../base/context-menu/TableContextMenuState";
import {defaultReportBaseState, ReportBaseState} from "../../base/report-workspace-header/data/ReportBaseState";
import {defaultManualModificationRules, WithCustomRules} from "../../base/report-custom-rule-pane/data/CustomRules";
import {DataGroup} from "../report-table/IntermediateTable";
import {WithReportTablesWorkspace} from "../../base/report-tables-container/state";
import {RpActions} from "../../base/report-page/action";
import {
    BaseMenuItemState,
    nextPageReducer,
    prevPageReducer,
    selectPageReducer,
    WithMenuState
} from "../../base/report-menu/state";


interface Rp5State extends
    ReportBaseState,
    WithMenuState<Rp5MenuItemState>,
    WithReportTablesWorkspace<Rp5Table>,
    WithCustomRules<Rp5CustomRule>,
    WithTableContextMenu<Rp5ContextMenu>
{
    controlPane: Rp5ControlPane | undefined

    drawer: Rp5Drawer
}

type Rp5MenuItemState = BaseMenuItemState & Rp5MenuItem

interface Rp5ContextTableMetadata {
    numRows: number
}

interface Rp5ContextDataGroup {
    dgId: string
    name: string
    title: string
    orientation: string
    detached: boolean
    startIndex: number
    endIndex: number
}

type Rp5ContextDataGroups = Rp5ContextDataGroup[] | undefined

interface Rp5ContextMenu extends TableContextMenuState {
    table: Rp5ContextTableMetadata | undefined
    rowTrigger: Rp5RowFormulaTrigger | null
    dataGroups: Rp5ContextDataGroups
}

interface Rp5ControlPane {
    show: boolean
    tableId: string | undefined
    numSelected: number
}

interface Rp5Drawer {
    open: boolean
}

const defaultRp5State: Rp5State = {
    ...defaultReportBaseState,

    menu: {
        items: [],
        selectedItem: undefined,
        selectedPage: undefined,
    },

    tableWorkspace: {
        isLoading: true,
        tables: [],
    },

    controlPane: undefined,
    contextMenu: {
        ...defaultTableContextMenuState,
        table: undefined,
        rowTrigger: null,
        dataGroups: undefined,
    },

    manualModificationRules: defaultManualModificationRules,

    drawer: {
        open: false
    },
}

function rp5Reducer(state: Rp5State = defaultRp5State, action: AnyAction): Rp5State {
    return produce(state, draft => {
        switch (action.type) {
            case RpActions.NAVIGATION_PREV_PAGE:
                prevPageReducer(draft)
                break
            case RpActions.NAVIGATION_NEXT_PAGE:
                nextPageReducer(draft)
                break
            case RpActions.NAVIGATION_MENU_ITEM:
                selectPageReducer(draft, action);
                break

            case Rp5Actions.REPORT_SET_MENU_ITEMS:
                setMenuItems(draft, action);
                break
            case Rp5Actions.REPORT_SET_TABLES:
                setTables(draft, action);
                break
            case Rp5Actions.OPEN_CONTEXT_MENU:
                openContextMenu(draft, action);
                break
            case Rp5Actions.CLOSE_CONTEXT_MENU:
                closeTableContextMenuImmerReducer(draft);
                break
            case Rp5Actions.OPEN_DRAWER:
                openDrawerReducer(draft)
                break
            case Rp5Actions.CLOSE_DRAWER:
                closeDrawerReducer(draft)
                break

        }
        return draft
    })
}

// ------------------------------------------------------------------------------------------------------

function setMenuItems(state: WritableDraft<Rp5State>, action: AnyAction) {
    const menuItems: Rp5MenuItem[] | undefined = action.menu;
    const pageNumber: number | undefined = action.pageNumber;

    if (menuItems === undefined) {
        return
    }

    const itemStates = menuItems.map<Rp5MenuItemState>(item => ({
        ...item,
        selected: pageNumber === item.page
    }));
    const selectedItem = itemStates.findIndex(item => item.selected);

    state.menu.items = itemStates
    state.menu.selectedItem = selectedItem === -1 ? undefined: selectedItem
    state.menu.selectedPage = pageNumber
}

function setTables(state: WritableDraft<Rp5State>, action: AnyAction) {
    const tables: Rp5Table[] = action.tables || [];

    state.tableWorkspace.isLoading = false
    state.tableWorkspace.tables = tables

    state.controlPane = {
        show: false,
        tableId: state.controlPane?.tableId,
        numSelected: 0,
    }
}

function openContextMenu(state: WritableDraft<Rp5State>, action: AnyAction) {
    const tableId = action.tableId;
    const rowIndex = action.position.rowIndex;
    const columnIndex = action.position.columnIndex;

    const table = state.tableWorkspace.tables.find(table => table.entityId === tableId);

    state.contextMenu = {
        show: true,
        position: {
            x: action.position.x,
            y: action.position.y,
            rowIndex: rowIndex,
            columnIndex: columnIndex,
        },
        tableId: undefined,
        table: undefined,
        rowTrigger: null,
        dataGroups: undefined
    }

    if (table) {
        let correctedColumnIndex = 0;
        const tableRow = table.rows[rowIndex].cells;
        for (let i = 0; i < columnIndex; i++) {
            correctedColumnIndex += tableRow[i].colSpan
        }
        state.contextMenu.position.columnIndex = correctedColumnIndex

        state.contextMenu.tableId = tableId
        state.contextMenu.table = {
            numRows: table.rows.length,
        }

        const dataGroups: DataGroup[] | undefined = action.dgs
        if (dataGroups && dataGroups.length > 0) {
            for (let dg of dataGroups) {
                if (!state.contextMenu.dataGroups) {
                    state.contextMenu.dataGroups = []
                }
                state.contextMenu.dataGroups.push({
                    dgId: dg.id,
                    name: dg.name,
                    title: dg.title,
                    orientation: dg.orientation,
                    detached: dg.detached,
                    startIndex: dg.startIndex,
                    endIndex: dg.endIndex,
                })
            }
        }

        state.contextMenu.rowTrigger = table.rows[rowIndex].trigger || null
    }
}

function openDrawerReducer(state: WritableDraft<Rp5State>) {
    state.drawer.open = true
}

function closeDrawerReducer(state: WritableDraft<Rp5State>) {
    state.drawer.open = false
}

export {rp5Reducer, defaultRp5State}
export type {
    Rp5State,
    Rp5MenuItemState,
    Rp5ContextDataGroup, Rp5ContextTableMetadata, Rp5ContextMenu,
}