import React, {useMemo} from "react";

import {IconButton} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import {Spacer} from "../../../../../qdep/components/app/util/spacer/Spacer";

import styles from "./ReportMenuGroupContainer.module.css";


interface ReportMenuGroupContainerProps {
    label: string
    isHighLevelGroup?: boolean
    children: React.ReactNode | React.ReactNode[]

    add?: () => void
    isNew?: boolean
}

const ReportMenuGroupContainer = (props: ReportMenuGroupContainerProps) => {
    const className = useMemo(() => {
        if (props.isHighLevelGroup) {
            return [styles.menu_group_container, styles.high_level].join(" ")
        }
        return styles.menu_group_container;
    }, [props.isHighLevelGroup])

    return <div className={className}>
        <div className={styles.menu_item_group_header}>
            <span>{ props.label }</span>
            <Spacer/>
            { props.add !== undefined &&
                <IconButton color={props.isNew ? "secondary": "default"} onClick={props.add}>
                    <AddIcon/>
                </IconButton>
            }
        </div>
        { props.children }
    </div>
}

export {ReportMenuGroupContainer}