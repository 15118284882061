import React, {useCallback} from "react";
import {useMount} from "react-use";
import {AnyAction} from "redux";

import {Slide} from "@mui/material";

import {ReportWorkspaceHeader} from "../base/report-workspace-header/ReportWorkspaceHeader";
import {StageNames} from "../base/StageNames";
import {
    setReportName,
    setReportStageStatus,
} from "../base/report-workspace-header/data/ReportBaseActions";
import {openCustomRulePane} from "../base/report-custom-rule-pane/data/action";
import {ReportTablesContainer} from "../base/report-tables-container/ReportTablesContainer";
import {ReportTableBlock} from "./ReportTableBlock";
import {TableContextMenu} from "./context-menu/TableContextMenu";
import {ManualModificationRulePane} from "../base/report-custom-rule-pane/ManualModificationRulePane";
import {Stage} from "../../../../app/client/app/entity/report/Stage";
import {startLoadingTables} from "../base/report-tables-container/state";
import {apiClient, ApiResponse, ErrorReason} from "../../../../app/client/app/client";
import {Rp3MenuItem, Rp3Table} from "../../../../app/client/app/entity/Rp3Report";
import {setMenuItems, setTable} from "./data/action";
import {usePageNavigation} from "../base/report-page/navigation/page_navigation";
import {ReportRevisionDto} from "../../../../app/client/app/entity/Report";
import {Rp3State} from "./data/state";
import {ReportPageAddress} from "../base/useReportPageAddress";
import {selectMenuItem} from "../base/report-page/action";
import {useAuthErrorBoundaryContext} from "../../../../qdep/components/app/error/UnauthorizedErrorBoundary";


interface Props {
    address: ReportPageAddress
    state: Rp3State
    dispatch: React.Dispatch<AnyAction>
}

const Rp3Body = (props: Props) => {
    const state = props.state
    const dispatch = props.dispatch

    const { submitError } = useAuthErrorBoundaryContext()
    const loadPageTablesCallback = useCallback((page: number) => {
        dispatch(startLoadingTables());
        setTimeout(() => {
            if (page !== undefined) {
                apiClient
                    .getReportDataNormalizationPageTables(props.address.prrKey, page)
                    .then((response: ApiResponse<Rp3Table[]>) => {
                        if (!response.errorReason && response.data !== undefined) {
                            dispatch(setTable(response.data))
                        } else {
                            const error = response.errorReason || ErrorReason.UNKNOWN_ERROR;
                            submitError(error)
                        }
                    });
            }
        }, 200);
        return
    }, [dispatch, props.address.prrKey, submitError]);
    const selectMenuItemCallback= useCallback(
        (itemIndex: number) => dispatch(selectMenuItem(itemIndex)),
        [dispatch]
    );
    usePageNavigation(selectMenuItemCallback, loadPageTablesCallback, state.menu.selectedPage)

    useMount(() => {
        apiClient
            .getReportRevision(props.address.prrKey)
            .then((response: ApiResponse<ReportRevisionDto>) => {
                if (response.data !== undefined) {
                    dispatch(setReportName(response.data.reportName))
                    dispatch(setReportStageStatus({
                        ocr: response.data.analysis.stages.ocr.status,
                        normalization: response.data.analysis.stages.normalization.status,
                        entityDetection: response.data.analysis.stages.entityDetection.status,
                        tableAnalysis: response.data.analysis.stages.tableAnalysis.status,
                        tableRefs: response.data.analysis.stages.tableRefs.status,
                        summary: response.data.analysis.stages.summary.status,
                    }))
                }
            })

        apiClient
            .getReportDataNormalizationMenuItems(props.address.prrKey)
            .then((response: ApiResponse<Rp3MenuItem[]>) => {
                if (response.data !== undefined) {
                    const pages: Rp3MenuItem[] = response.data || []
                    let reportPage = props.address.query.page
                    if (reportPage === undefined && pages.length > 0) {
                        reportPage = pages[0].page
                    }

                    dispatch(setMenuItems(pages, reportPage))
                }
            })
    })

    return <>
        <ReportWorkspaceHeader
            prrKey={props.address.prrKey}
            reportName={state.reportName}
            reportStage={StageNames.DATA_NORMALIZATION}
            reportPage={state.menu.selectedPage}
            reportStageStatuses={state.reportStageStatuses}
            updateReportStatuses={stages => dispatch(setReportStageStatus(stages))}

            numCustomRuleUpdates={state.manualModificationRules.customRuleUpdates.length}
            openCustomRuleBlock={() => dispatch(openCustomRulePane())}
        />

        <ReportTablesContainer isLoading={state.tableWorkspace.isLoading}>
            { state.tableWorkspace.tables.map((table, index) =>
                <ReportTableBlock key={`rt-${index}`} table={table} dispatch={dispatch}/>
            )}
        </ReportTablesContainer>

        <TableContextMenu prrKey={props.address.prrKey} contextMenu={state.contextMenu} dispatch={dispatch}/>

        <Slide
            direction={"up"}
            in={state.manualModificationRules.show}
            unmountOnExit
        >
            <div className={"report-bottom-sticky-container"}>
                <ManualModificationRulePane
                    prrKey={props.address.prrKey}
                    stage={Stage.DATA_NORMALIZATION_STAGE}

                    ruleUpdates={state.manualModificationRules.selectedTableRuleUpdates}

                    dispatch={dispatch}
                />
            </div>
        </Slide>
    </>
}

export {Rp3Body}