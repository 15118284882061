import {
    Button,
    Checkbox,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItemButton,
    ListItemIcon, ListItemText,
    Skeleton
} from "@mui/material";

import {PRRKey} from "../../../../app/client/app/entity/Project";
import {EntityModelRevisionRef} from "../../../../app/client/app/entity/report/model/ReportModel";
import React, {useEffect, useState} from "react";
import {apiClient} from "../../../../app/client/app/client";
import {useAsyncFn} from "react-use";
import {LoadingButton} from "@mui/lab";


interface ComponentProps {
    prrKey: PRRKey
    currentRevisionId: string | undefined

    isOpen: boolean
    close: () => void
    apply: (modelRevisionRef: EntityModelRevisionRef) => void
}

const attachModel = async (prrKey: PRRKey, refDto: EntityModelRevisionRef) => {
    return await apiClient.attachEntityModelRef(prrKey, refDto.modelId, refDto.revisionId);
};

const SelectEntityModelRef = (props: ComponentProps) => {
    const [selectedItem, setSelectedItem] = useState<number | undefined>(undefined);
    const [refs, setRefs] = useState<EntityModelRevisionRef[] | undefined>(undefined);
    useEffect(() => {
        if (props.isOpen) {
            apiClient.listEntityModelRefs()
                .then(response => {
                    const revisionRefs = response.data;
                    if (response.errorReason === undefined && revisionRefs !== undefined) {
                        setRefs(revisionRefs)

                        if (props.currentRevisionId !== undefined) {
                            const index = revisionRefs.findIndex(ref => ref.revisionId === props.currentRevisionId);
                            if (index < 0) {
                                setSelectedItem(undefined);
                            } else {
                                setSelectedItem(index);
                            }
                        }
                    }
                });
        }
    }, [props.isOpen, props.currentRevisionId])

    const [attachModelState, attachModelOp] = useAsyncFn(attachModel, []);

    const handleToggle = (value: number) => () => {
        if (value === selectedItem) {
            setSelectedItem(undefined)
        } else {
            setSelectedItem(value)
        }
    };

    return <Dialog open={props.isOpen} fullWidth maxWidth={"sm"}>
        <DialogTitle>
            Select Entity Model
        </DialogTitle>

        <DialogContent>
            { refs === undefined &&
                <div>
                    <Skeleton/>
                    <Skeleton/>
                    <Skeleton/>
                </div>
            }
            { refs !== undefined &&
                <List dense>
                    { refs.map((ref, index) => {
                        const labelId = `checkbox-list-label-${index}`;

                        return <ListItemButton
                            dense
                            onClick={handleToggle(index)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    tabIndex={-1}
                                    disableRipple
                                    checked={selectedItem === index}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                id={labelId}
                                primary={`${ref.modelName} / ${ref.revisionName}`}
                                secondary={`${ref.modelId} / ${ref.revisionId}`}
                            />
                        </ListItemButton>
                    })}
                </List>
            }
        </DialogContent>

        <DialogActions>
            <Button onClick={props.close}>Cancel</Button>
            <LoadingButton
                variant="contained"
                color="secondary"
                loading={attachModelState.loading}
                onClick={() => {
                    if (refs !== undefined && selectedItem !== undefined && selectedItem >= 0) {
                        const selectedRef = refs[selectedItem];
                        attachModelOp(props.prrKey, selectedRef)
                            .then(response => {
                                console.log(response)
                                if (response.errorReason === undefined) {
                                    if (response.data?.status === "ok" || response.data?.status === "not_change") {
                                        props.apply(selectedRef);
                                    }
                                }
                            });
                    }
                }}
            >
                Apply
            </LoadingButton>
        </DialogActions>
    </Dialog>
}

export {SelectEntityModelRef}