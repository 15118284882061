import React, {ReactElement} from "react";
import {Sector} from "recharts";

const renderActiveShape = (props: any): ReactElement<SVGElement> => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, percent, value } = props;

    return (
        <g>
            <text
                x={cx}
                y={cy}
                dy={-12}
                textAnchor="middle"
                fontSize={"26px"}
                fill={fill}
            >
                {value} / {`${(percent * 100).toFixed(2)}%`}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle - 0.5}
                endAngle={endAngle + 0.5}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                startAngle={startAngle - 0.5}
                endAngle={endAngle + 0.5}
                fill={fill}
            />
        </g>
    );
};

const renderShape = (props: any): ReactElement<SVGElement> => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;

    return (
        <g>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle - 0.5}
                endAngle={endAngle + 0.5}
                fill={fill}
            />
        </g>
    );
};

export {renderShape, renderActiveShape}