import {WorkspaceBlock} from "../../../../qdep/components/app/workspace/Workspace";
import {EntityListBlock} from "../../../../qdep/components/entity-list-workspace-block/EntityListBlock";
import {RevisionActionGroup} from "../action/RevisionActionGroup";
import React, {useState} from "react";
import {ReportDto} from "../../../../app/client/app/entity/Project";
import {AnyAction} from "redux";
import {EmptyReportList} from "../../component/EmptyReportList";
import {openReportEditor} from "../data/reducer";
import {RevisionStatusFormatter} from "../../../util/RevisionStatusFormatter";
import {DefaultRowFactory} from "../../../../qdep/components/entity-list-workspace-block/default/RowFactory";


interface ProjectReportTabProps {
    projectId: string
    reports: ReportDto[]

    dispatch: React.Dispatch<AnyAction>
}

const ProjectReportTab = (props: ProjectReportTabProps) => {
    return <>
        {props.reports.length === 0
            ? <EmptyReportList onClick={() => props.dispatch(openReportEditor())}/>
            : props.reports.map(report =>
                <ReportRevisionListBlock
                    key={report.index}
                    projectId={props.projectId}
                    report={report}
                    dispatch={props.dispatch}
                />
            )}
    </>
}

interface ReportRevisionListBlockProps {
    projectId: string
    report: ReportDto
    dispatch: React.Dispatch<AnyAction>
}

const ReportRevisionListBlock = (props: ReportRevisionListBlockProps) => {
    const [selectedRow, setSelectedRow] = useState(undefined as number | undefined);

    return <WorkspaceBlock>
        <EntityListBlock
            title={props.report.name}
            data={{
                isLoaded: true,
                data: props.report.revisions,
            }}
            columnSpec={[
                {name: "revisionNumber", title: "Revision"},
                {name: "filename", title: "Filename"},
                {
                    name: "status",
                    title: "Status",
                    format: (value: string) => <RevisionStatusFormatter value={value}/>
                },
                {name: "Issues", title: "Issues", calc: () => "0"},
            ]}
            actionGroup={
                <RevisionActionGroup
                    projectId={props.projectId}
                    reportIndex={props.report.index}
                    selectedRevisionIndex={selectedRow !== undefined ? props.report.revisions[selectedRow].index : undefined}
                    dispatch={props.dispatch}
                />
            }

            rowFactory={DefaultRowFactory}
            selectedRow={selectedRow}
            select={index => {
                setSelectedRow(prev => {
                    if (prev === index) {
                        return undefined
                    }
                    return index;
                })
            }}
        />
    </WorkspaceBlock>
}

export {ProjectReportTab}
