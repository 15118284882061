import React from "react";

import './Workspace.css'

interface WorkspaceProps {
    children:  React.ReactNode

    className?: string
}

const Workspace = (props: WorkspaceProps) => {
    let workspaceClasses = "workspace"
    if (props.className) {
        workspaceClasses += " " + props.className
    }

    return <div className={"workspace-container"}>
        <div className={workspaceClasses}>
            {props.children}
        </div>
    </div>;
}

const WorkspaceHeader = (props: {children: React.ReactNode | React.ReactNode[]}) => {
    return <div className={"workspace-header"}>
        { props.children }
    </div>
}

interface WorkspaceSecondaryHeaderProps {
    className?: string
    children: React.ReactNode | React.ReactNode[]
}

const WorkspaceSecondaryHeader = (props: WorkspaceSecondaryHeaderProps) => {
    let className = "workspace-second-header";
    if (props.className !== undefined) {
        className += " " + props.className
    }

    return <div className={className}>
        { props.children }
    </div>
}


interface WorkspaceBlockProps {
    classes?: string
    children: React.ReactNode | React.ReactNode[]
}

const WorkspaceBlock = (props: WorkspaceBlockProps) => {
    let className = "workspace-block";
    if (props.classes !== undefined) {
        className += " " + props.classes
    }

    return <div className={className}>
        { props.children }
    </div>
}

interface WorkspaceBlockHeaderProps {
    classes?: string
    children: React.ReactNode | React.ReactNode[]
}

const WorkspaceBlockHeader = (props: WorkspaceBlockHeaderProps) => {
    let className = "workspace-block-header";
    if (props.classes !== undefined) {
        className += " " + props.classes
    }
    return <div className={className}>
        { props.children }
    </div>
}

const WorkspaceBlockBody = (props: WorkspaceBlockProps) => {
    let className = "workspace-block-body"
    if (props.classes !== undefined) {
        className += " " + props.classes
    }

    return <div className={className}>
        { props.children }
    </div>
}

interface FieldContainerProps {
    title: string
    notAlign?: boolean
    notLabelWidth?: boolean
    horizontal?: boolean
    fill?: boolean

    children: React.ReactNode
}

const FieldContainer = (props: FieldContainerProps) => {
    let className = "filed-container";
    if (props.notAlign) {
        className += " not-align"
    }
    if (props.notLabelWidth) {
        className += " not-label-width"
    }
    if (props.horizontal) {
        className += " horizontal";
    }
    if (props.fill) {
        className += " fill";
    }

    return <div className={className}>
        <label>{ props.title }</label>
        { props.children }
    </div>;
}

export {
    Workspace, WorkspaceHeader, WorkspaceSecondaryHeader,
    WorkspaceBlock, WorkspaceBlockHeader, WorkspaceBlockBody,
    FieldContainer
}
export type {WorkspaceProps}
