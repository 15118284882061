import React from "react";

import {Divider, Skeleton, Stack, Tab, Tabs} from "@mui/material";

import styles from "./editor.module.css";


const AnalysisSettingSkeleton = () => {
    return <Stack direction="row" sx={{minHeight: "300px", height: "75vh"}}>
        <Tabs
            orientation="vertical"
            indicatorColor="secondary"
            textColor="secondary"
            sx={{minWidth: "150px", maxWidth: "190px", borderRight: 1, borderColor: 'divider'}}
            value={0}
        >
            <Tab sx={{alignItems: "flex-start", textAlign: "left"}} disabled label={<Skeleton variant="rectangular" height={20} width={"100%"}/>}/>
            <Tab sx={{alignItems: "flex-start", textAlign: "left"}} disabled label={<Skeleton variant="rectangular" height={20} width={"100%"}/>}/>
            <Tab sx={{alignItems: "flex-start", textAlign: "left"}} disabled label={<Skeleton variant="rectangular" height={20} width={"100%"}/>}/>
            <Tab sx={{alignItems: "flex-start", textAlign: "left"}} disabled label={<Skeleton variant="rectangular" height={20} width={"100%"}/>}/>
            <Tab sx={{alignItems: "flex-start", textAlign: "left"}} disabled label={<Skeleton variant="rectangular" height={20} width={"100%"}/>}/>
            <Tab sx={{alignItems: "flex-start", textAlign: "left"}} disabled label={<Skeleton variant="rectangular" height={20} width={"100%"}/>}/>
        </Tabs>
        <div className={styles.report_settings_item_container}>
            <div>
                <Skeleton width={300}/>
                <Skeleton variant={"rectangular"} height={130}/>
                <Divider sx={{
                    margin: "16px 0 8px",
                }}/>
                <Skeleton width={220}/>
                <Skeleton variant={"rectangular"} height={100}/>
            </div>
        </div>
    </Stack>
}

export {AnalysisSettingSkeleton}