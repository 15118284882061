import {AnyAction} from "redux";
import produce from "immer";

import {defaultRp7State, Rp7MenuItemType, Rp7State} from "./state";
import {ActionTypes} from "./action";
import {WritableDraft} from "immer/dist/types/types-external";


function rp7Reducer(state: Rp7State = defaultRp7State, action: AnyAction): Rp7State {
    return produce(state, draft => {
        switch (action.type) {
            case ActionTypes.SELECT_MENU_ITEM:
                selectMenuItemReducer(draft, action)
                break
        }
        return draft
    })
}

function selectMenuItemReducer(state: WritableDraft<Rp7State>, action: AnyAction) {
    const item: Rp7MenuItemType | undefined = action.item;
    if (item === undefined) {
        return
    }
    state.menu.selectedItem = item
    if (item === Rp7MenuItemType.COVERAGE_TABLES || item === Rp7MenuItemType.ISSUE_TABLES || item === Rp7MenuItemType.ISSUE_OVERVIEW) {
        state.menu.pages = {
            isLoading: true,
            selectedPageIndex: undefined,
            pages: []
        }
    } else {
        state.menu.pages = undefined
    }
}

export {rp7Reducer}