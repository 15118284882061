import React, {MouseEventHandler} from "react";
import {ColumnSpec, defaultColumnSpecKey, QTableAction, QTableStaticProps, RowFactory} from "@qdep/react-table";

function defaultCellFormatter(data: any): string {
    return data ? data : '-'
}

function selectRowCallback(clickCount: number, handler: (value: any) => void): MouseEventHandler<HTMLTableRowElement> {
    return (event: React.MouseEvent<HTMLTableRowElement>) => {
        event.preventDefault();
        if (event.detail === clickCount) {
            const row = event.currentTarget;
            handler(row.rowIndex - 1)
        }
    }
}

const DefaultRowFactory: RowFactory = (rowData: any, props: QTableStaticProps & QTableAction, rowIndex?: number) => {
    let rowClass = ""
    if (props.selectedRow !== undefined && props.selectedRow === rowIndex) {
        rowClass = "selected"
    }
    return <tr
        key={rowIndex}
        className={rowClass}
        onClick={props.onClick ? selectRowCallback(1, props.onClick): undefined}
        onDoubleClick={props.onDoubleClick ? selectRowCallback(2, props.onDoubleClick): undefined}
    >
        { props.spec.map((column: ColumnSpec) => {
            const value = rowData[column.name];
            const cellValue = column.format ? column.format(value) : defaultCellFormatter(value)
            return <td key={defaultColumnSpecKey(column)}>{cellValue}</td>;
        })}
    </tr>
}

export {DefaultRowFactory}
