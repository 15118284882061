import {AnyAction} from "redux";

import {AnalysisSettings} from "../../../app/client/app/entity/report/settings/AnalysisSettings";
import {DeepPartial} from "../../../qdep/data/util";
import {HeaderDateFormatSettings} from "../../../app/client/app/entity/report/settings/HeaderDateFormatSettings";
import {ColumnRuleSettings} from "../../../app/client/app/entity/report/settings/ColumnRuleSettings";
import {DataGroupSettings} from "../../../app/client/app/entity/report/settings/DataGroupSettings";
import {
    AutoCorrectionSettings,
    OcrPostProcessingSettings
} from "../../../app/client/app/entity/report/settings/OcrPostProcessingSettings";
import {DevSettings} from "../../../app/client/app/entity/report/settings/DevSettings";
import {
    TableTitleNormalizationSettings
} from "../../../app/client/app/entity/report/settings/TableTitleNormalizationSettings";
import {RowRefDetectionSettings} from "../../../app/client/app/entity/report/settings/RowRefDetectionSettings";
import {
    RowDateRuleSettings,
    RowSimpleRuleSettings
} from "../../../app/client/app/entity/report/settings/RowRuleSettings";


type ReportSettingsEditorState = AnalysisSettings

// ------------ Actions ------------
enum ActionTypes {
    SET_SETTINGS = "ANALYSIS_SETTINGS_EDITOR.SET_SETTINGS",

    UPDATE_REF_DETECTION_SETTINGS = "ANALYSIS_SETTINGS_EDITOR.UPDATE_REF_DETECTION_SETTINGS",
    UPDATE_SIMPLE_ROW_RULE_SETTINGS = "ANALYSIS_SETTINGS_EDITOR.UPDATE_SIMPLE_ROW_RULE_SETTINGS",
    UPDATE_DATE_ROW_RULE_SETTINGS = "ANALYSIS_SETTINGS_EDITOR.UPDATE_DATE_ROW_RULE_SETTINGS",
    UPDATE_DATE_FORMAT_SETTINGS = "ANALYSIS_SETTINGS_EDITOR.UPDATE_DATE_FORMAT_SETTINGS",
    UPDATE_DEV_SETTINGS = "ANALYSIS_SETTINGS_EDITOR.UPDATE_DEV_SETTINGS",
    UPDATE_COLUMN_RULE_SETTINGS = "ANALYSIS_SETTINGS_EDITOR.UPDATE_COLUMN_RULE_SETTINGS",
    UPDATE_NORMALIZATION_SETTINGS = "ANALYSIS_SETTINGS_EDITOR.UPDATE_THOUSANDS_SEPARATOR_SETTINGS",
    UPDATE_DATA_GROUP_SETTINGS = "ANALYSIS_SETTINGS_EDITOR.UPDATE_DATA_GROUP_SETTINGS",
    UPDATE_OCR_POST_PROCESSING_SETTINGS                 = "ANALYSIS_SETTINGS_EDITOR.UPDATE_OCR_POST_PROCESSING_SETTINGS",
    UPDATE_OCR_POST_PROCESSING_AUTO_CORRECTION_SETTINGS = "ANALYSIS_SETTINGS_EDITOR.UPDATE_OCR_POST_PROCESSING_AUTO_CORRECTION_SETTINGS",
}

function setSettings(data: AnalysisSettings): AnyAction {
    return {
        type: ActionTypes.SET_SETTINGS,
        data: data,
    }
}

function updateRefDetectionSettings(updates: Partial<RowRefDetectionSettings>): AnyAction {
    return {
        type: ActionTypes.UPDATE_REF_DETECTION_SETTINGS,
        updates: updates,
    }
}

function updateSimpleRowRuleSettings(updates: Partial<RowSimpleRuleSettings>): AnyAction {
    return {type: ActionTypes.UPDATE_SIMPLE_ROW_RULE_SETTINGS, updates: updates}
}

function updateDateRowRuleSettings(updates: Partial<RowDateRuleSettings>): AnyAction {
    return {type: ActionTypes.UPDATE_DATE_ROW_RULE_SETTINGS, updates: updates}
}

function updateDateFormatSettings(updates: DeepPartial<HeaderDateFormatSettings>): AnyAction {
    return {
        type: ActionTypes.UPDATE_DATE_FORMAT_SETTINGS,
        updates: updates,
    }
}

function updateColumnRuleSettings(updates: Partial<ColumnRuleSettings>): AnyAction {
    return {
        type: ActionTypes.UPDATE_COLUMN_RULE_SETTINGS,
        updates: updates,
    }
}

function updateDataGroupSettings(changes: Partial<DataGroupSettings>): AnyAction {
    return {type: ActionTypes.UPDATE_DATA_GROUP_SETTINGS, changes: changes}
}

function updateOcrPostProcessingSettings(changes: Partial<OcrPostProcessingSettings>): AnyAction {
    return {type: ActionTypes.UPDATE_OCR_POST_PROCESSING_SETTINGS, changes: changes}
}

function updateOcrPostProcessingAutoCorrectionSettings(changes: Partial<AutoCorrectionSettings>): AnyAction {
    return {type: ActionTypes.UPDATE_OCR_POST_PROCESSING_AUTO_CORRECTION_SETTINGS, changes: changes}
}

function updateDevSettings(changes: Partial<DevSettings>): AnyAction {
    return {type: ActionTypes.UPDATE_DEV_SETTINGS, changes: changes}
}

function updateNormalizationSettings(changes: DeepPartial<TableTitleNormalizationSettings>): AnyAction {
    return {type: ActionTypes.UPDATE_NORMALIZATION_SETTINGS, changes: changes}
}

// ------------ Reducer ------------
function analysisSettingsEditorReducer(state: ReportSettingsEditorState, action: AnyAction): ReportSettingsEditorState {
    switch (action.type) {
        case ActionTypes.SET_SETTINGS:
            return {...action.data}
        case ActionTypes.UPDATE_REF_DETECTION_SETTINGS:
            return updateRefDetectionSettingsReducer(state, action)
        case ActionTypes.UPDATE_SIMPLE_ROW_RULE_SETTINGS:
            return updateSimpleRowRuleSettingsReducer(state, action)
        case ActionTypes.UPDATE_DATE_ROW_RULE_SETTINGS:
            return updateDateRowRuleSettingsReducer(state, action)
        case ActionTypes.UPDATE_DATE_FORMAT_SETTINGS:
            return updateDateFormatSettingsReducer(state, action)
        case ActionTypes.UPDATE_COLUMN_RULE_SETTINGS:
            return updateColumnRuleSettingsReducer(state, action)

        // data-group-analysis_settings
        case ActionTypes.UPDATE_DATA_GROUP_SETTINGS:
            return updateDataGroupSettingsReducer(state, action)
        // ocr-post-processing-analysis_settings
        case ActionTypes.UPDATE_OCR_POST_PROCESSING_SETTINGS:
            return updateOcrPostProcessingSettingsReducer(state, action)
        case ActionTypes.UPDATE_OCR_POST_PROCESSING_AUTO_CORRECTION_SETTINGS:
            return updateOcrPostProcessingSettingsAutoCorrectionReducer(state, action)
        // dev_settings
        case ActionTypes.UPDATE_DEV_SETTINGS:
            return updateDevSettingsReducer(state, action)
        case ActionTypes.UPDATE_NORMALIZATION_SETTINGS:
            return updateNormalizationSettingsReducer(state, action)
    }
    return state
}

function updateRefDetectionSettingsReducer(state: ReportSettingsEditorState, action: AnyAction): ReportSettingsEditorState {
    const updates: Partial<RowRefDetectionSettings> | undefined = action.updates
    if (updates === undefined) {
        return state
    }
    return {
        ...state,
        rowRefDetectionSettings: {
            ...state.rowRefDetectionSettings,
            ...updates,
        },
    };
}

function updateSimpleRowRuleSettingsReducer(state: ReportSettingsEditorState, action: AnyAction): ReportSettingsEditorState {
    const updates: Partial<RowSimpleRuleSettings> | undefined = action.updates
    if (updates === undefined) {
        return state
    }
    return {
        ...state,
        rowRuleSettings: {
            ...state.rowRuleSettings,
            simpleRule: {
                ...state.rowRuleSettings.simpleRule,
                ...updates,
            },
        },
    };
}

function updateDateRowRuleSettingsReducer(state: ReportSettingsEditorState, action: AnyAction): ReportSettingsEditorState {
    const updates: Partial<RowDateRuleSettings> | undefined = action.updates
    if (updates === undefined) {
        return state
    }
    return {
        ...state,
        rowRuleSettings: {
            ...state.rowRuleSettings,
            dateRule: {
                ...state.rowRuleSettings.dateRule,
                ...updates,
            },
        },
    };
}

function updateDateFormatSettingsReducer(state: ReportSettingsEditorState, action: AnyAction): ReportSettingsEditorState {
    const updates: Partial<HeaderDateFormatSettings> | undefined = action.updates
    if (updates === undefined) {
        return state
    }

    const newHeaderDateFormatSettings = {
        ...state.headerDateFormatSettings
    }
    if (updates.formatters !== undefined) {
        newHeaderDateFormatSettings.formatters = updates.formatters
    }
    if (updates.fysDate !== undefined) {
        newHeaderDateFormatSettings.fysDate = {
            ...newHeaderDateFormatSettings.fysDate,
            ...updates.fysDate
        }
    }
    if (updates.fyeDate !== undefined) {
        newHeaderDateFormatSettings.fyeDate = {
            ...newHeaderDateFormatSettings.fyeDate,
            ...updates.fyeDate
        }
    }
    if (updates.fyeAsDefault !== undefined) {
        newHeaderDateFormatSettings.fyeAsDefault = updates.fyeAsDefault
    }

    return {
        ...state,
        headerDateFormatSettings: newHeaderDateFormatSettings,
    };
}

function updateColumnRuleSettingsReducer(state: ReportSettingsEditorState, action: AnyAction): ReportSettingsEditorState {
    const updates: Partial<ColumnRuleSettings> | undefined = action.updates
    if (updates === undefined) {
        return state
    }

    return {
        ...state,
        columnRuleSettings: {
            ...state.columnRuleSettings,
            ...updates,
        }
    }
}

// data-group-analysis_settings
function updateDataGroupSettingsReducer(state: ReportSettingsEditorState, action: AnyAction) {
    const changes: Partial<DataGroupSettings> | undefined = action.changes;
    if (changes !== undefined) {
        return {
            ...state,
            dataGroupSettings: {
                ...state.dataGroupSettings,
                ...changes
            }
        }
    }

    return state;
}

// ocr-post-processing-analysis_settings
function updateOcrPostProcessingSettingsReducer(state: ReportSettingsEditorState, action: AnyAction): ReportSettingsEditorState {
    const changes: Partial<OcrPostProcessingSettings> | undefined = action.changes;
    if (changes !== undefined) {
        return {
            ...state,
            postProcessingSettings: {
                ...state.postProcessingSettings,
                ...changes
            }
        }
    }
    return state;
}

function updateOcrPostProcessingSettingsAutoCorrectionReducer(state: ReportSettingsEditorState, action: AnyAction): ReportSettingsEditorState {
    const changes: Partial<AutoCorrectionSettings> | undefined = action.changes;
    if (changes !== undefined) {
        return {
            ...state,
            postProcessingSettings: {
                ...state.postProcessingSettings,
                autoCorrection: {
                    ...state.postProcessingSettings.autoCorrection,
                    ...changes
                },
            }
        }
    }
    return state;
}

function updateNormalizationSettingsReducer(state: ReportSettingsEditorState, action: AnyAction): ReportSettingsEditorState {
    const changes: DeepPartial<TableTitleNormalizationSettings> | undefined = action.changes;
    if (changes === undefined) {
        return state
    }
    return {
        ...state,
        tableTitleNormalization: {
            ...state.tableTitleNormalization,
            ...changes,
            thousandsSeparator: {
                ...state.tableTitleNormalization.thousandsSeparator,
                ...changes.thousandsSeparator,
            },
            decimalSeparator: {
                ...state.tableTitleNormalization.decimalSeparator,
                ...changes.decimalSeparator,
            },
            autoCorrection: {
                ...state.tableTitleNormalization.autoCorrection,
                ...changes.autoCorrection,
            }
        }
    }
}

function updateDevSettingsReducer(state: ReportSettingsEditorState, action: AnyAction): ReportSettingsEditorState {
    const changes: Partial<DevSettings> | undefined = action.changes;
    if (changes === undefined) {
        return state
    }
    return {
        ...state,
        devSettings: {
            ...state.devSettings,
            ...changes
        }
    }
}

export type {ReportSettingsEditorState}
export {
    analysisSettingsEditorReducer,
    setSettings,
    updateRefDetectionSettings,
    updateSimpleRowRuleSettings, updateDateRowRuleSettings, updateColumnRuleSettings,
    updateDataGroupSettings,
    updateOcrPostProcessingSettings,
    updateOcrPostProcessingAutoCorrectionSettings,
    updateNormalizationSettings, updateDateFormatSettings,
    updateDevSettings,
}
