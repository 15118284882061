import {AnyAction} from "redux";
import {reportBaseReducer} from "./report-workspace-header/data/ReportBaseReducer";
import {ReportBaseState} from "./report-workspace-header/data/ReportBaseState";
import {modificationRulePaneReducer} from "./report-custom-rule-pane/data/reducer";
import {WithCustomRules} from "./report-custom-rule-pane/data/CustomRules";
import {
    reportTablesContainerReducer,
    TablesWorkspacePrefix,
    WithReportTablesWorkspace
} from "./report-tables-container/state";

function withBaseReducer<T extends ReportBaseState>(customReducer: (state: T, action: AnyAction) => T): (state: T, action: AnyAction) => T {
    return (state, action) => {
        if (action.type.startsWith("REPORT_PAGE.BASE.")) {
            return reportBaseReducer(state, action)
        }
        return customReducer(state, action)
    }
}

function withReportTablesContainerReducer<T extends WithReportTablesWorkspace<any>>(customReducer: (state: T, action: AnyAction) => T): (state: T, action: AnyAction) => T {
    return (state, action) => {
        if (action.type.startsWith(TablesWorkspacePrefix)) {
            return reportTablesContainerReducer(state, action)
        }
        return customReducer(state, action)
    }
}

function withModificationRuleReducer<T extends WithCustomRules<any>>(customReducer: (state: T, action: AnyAction) => T): (state: T, action: AnyAction) => T {
    return (state, action) => {
        if (action.type.startsWith("REPORT_PAGE.CUSTOM_RULE.")) {
            return modificationRulePaneReducer(state, action)
        }
        return customReducer(state, action)
    }
}

function withBaseAndModificationRuleReducer<T extends ReportBaseState &  WithCustomRules<any>>(customReducer: (state: T, action: AnyAction) => T): (state: T, action: AnyAction) => T {
    return (state, action) => {
        if (action.type.startsWith("REPORT_PAGE.BASE.")) {
            return reportBaseReducer(state, action)
        }
        if (action.type.startsWith("REPORT_PAGE.CUSTOM_RULE.")) {
            return modificationRulePaneReducer(state, action)
        }
        return customReducer(state, action)
    }
}

export {
    withBaseReducer, withReportTablesContainerReducer, withModificationRuleReducer,
    withBaseAndModificationRuleReducer
}
