import React from "react";

import {Divider, ListItemIcon, MenuItem, Stack} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import LinkIcon from "@mui/icons-material/Link";

import {Label} from "../../../../../qdep/components/app/util/label/Label";
import {Rp6ContextRef} from "../data/reducer";


interface ComponentProps {
    rowRef: Rp6ContextRef
    onEdit: (refName: string) => void
    onDelete: () => void
}

const RowRefSubMenu = (props: ComponentProps) => {
    return <>
        <Divider/>
        <li>
            <Stack direction={"row"} alignItems={"center"} spacing={"4px"} sx={{padding: "2px 16px", fontSize: "0.95rem"}}>
                <span>Row Ref:</span>
                <Label icon={<LinkIcon fontSize={"small"}/>} text={props.rowRef.refName} variant={"info"}/>
            </Stack>
        </li>
        <MenuItem onClick={() => props.onEdit(props.rowRef.refName)}>
            <ListItemIcon>
                <EditIcon/>
            </ListItemIcon>
            Edit Ref
        </MenuItem>
        <MenuItem onClick={props.onDelete}>
            <ListItemIcon>
                <DeleteIcon/>
            </ListItemIcon>
            Delete Ref
        </MenuItem>
    </>
}
export {RowRefSubMenu}