import React, {ReactNode} from "react";

import {WorkspaceBlockBody} from "../../../../../qdep/components/app/workspace/Workspace";

import styles from './ReportTableWorkspaceBlockBody.module.css'


interface ReportTableWorkspaceBlockBodyProps {
    children: ReactNode | ReactNode[]
}

const ReportTableWorkspaceBlockBody = (props: ReportTableWorkspaceBlockBodyProps) => {
    return <WorkspaceBlockBody classes={styles.report_table_container}>
        <table className={styles.report_table}>
            <tbody>
                {props.children}
            </tbody>
        </table>
    </WorkspaceBlockBody>
}

export {ReportTableWorkspaceBlockBody}