import React, {Dispatch} from "react";
import {AnyAction} from "redux";

import {IconButton} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import {
    WorkspaceBlock,
    WorkspaceBlockBody,
    WorkspaceBlockHeader
} from "../../../../../qdep/components/app/workspace/Workspace";
import {Spacer} from "../../../../../qdep/components/app/util/spacer/Spacer";
import {FeatureValueState} from "../state";
import {ListContainer} from "../../../../../qdep/components/app/util/list-container/ListContainer";
import {FeatureValueSelector} from "./FeatureValueSelectors";

import styles from './FeatureValueSelectorsBlock.module.css'
import {addValueSelector, deleteValueSelector, updateValueSelector} from "../action";

type FeatureValueSelectorsBlockProps = FeatureValueState & {
    dispatch: Dispatch<AnyAction>
}

const FeatureValueSelectorsBlock = (props: FeatureValueSelectorsBlockProps) => {
    return <WorkspaceBlock>
        <WorkspaceBlockHeader classes={styles.block_header}>
            <span>{ props.name }</span>
            <Spacer/>
            <IconButton onClick={() => props.dispatch(addValueSelector(props.name))}>
                <AddIcon/>
            </IconButton>
            <IconButton onClick={() => props.dispatch(deleteValueSelector(props.name))}>
                <DeleteIcon/>
            </IconButton>
        </WorkspaceBlockHeader>
        <WorkspaceBlockBody>
            <ListContainer add={() => props.dispatch(addValueSelector(props.name))}>
                { props.selectors.map((selector, index) =>
                    <FeatureValueSelector
                        key={index}
                        {...selector}
                        onChange={update => props.dispatch(updateValueSelector(props.name, index, update))}/>
                )}
            </ListContainer>
        </WorkspaceBlockBody>
    </WorkspaceBlock>
}

export {FeatureValueSelectorsBlock}
