import React, {useEffect, useReducer, useState} from "react";
import {useAsyncFn} from "react-use";
import {SnackbarKey, SnackbarMessage, useSnackbar, VariantType} from "notistack";

import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {LoadingButton} from "@mui/lab";

import {apiClient} from "../../../app/client/app/client";
import {PRRKey} from "../../../app/client/app/entity/Project";
import {
    AnalysisSettings,
    DefaultReportAnalysisSettings
} from "../../../app/client/app/entity/report/settings/AnalysisSettings";
import {ReportStatusResult, StageStatuses} from "../../../app/client/app/entity/ops/ReportStatusResult";
import {analysisSettingsEditorReducer, setSettings} from "./editor_reducer";
import {AnalysisSettingEditor} from "./editor";
import {AnalysisSettingSkeleton} from "./skeleton";


interface ReportSettingsDialogProps {
    prrKey: PRRKey
    isOpen: boolean
    close: () => void

    updateReportStatuses: (stages: StageStatuses) => void
}

const ReportSettingsDialog = (props: ReportSettingsDialogProps) => {
    const [isLoading, setLoading] = useState(true);
    const [state, componentDispatch] = useReducer(analysisSettingsEditorReducer, DefaultReportAnalysisSettings);

    useEffect(() => {
        if (props.isOpen) {
            setLoading(true)
            apiClient
                .getReportRevisionSettings(props.prrKey.projectId, props.prrKey.reportIndex, props.prrKey.revisionIndex)
                .then(response => {
                    if (response.errorReason === undefined) {
                        if (response.data !== null && response.data !== undefined) {
                            setLoading(false)
                            componentDispatch(setSettings(response.data))
                        }
                    }
                })
        }
    }, [props.prrKey, props.isOpen])

    const { enqueueSnackbar } = useSnackbar();
    const [applySettingsState, applySettings] = useAsyncFn(async (prrKey: PRRKey, settings: AnalysisSettings) => {
        const response = await apiClient.updateReportRevisionSettings(prrKey, settings);

        const notificationKey: SnackbarKey = "update-report-revision-analysis-analysis_settings-request"
        let notificationVariant: VariantType = "error"
        let notificationMessage: SnackbarMessage = "Something went wrong. Try again later or contact your administrator"
        let opsResult: ReportStatusResult = {status: "failed", stages: undefined}
        if (!response.errorReason && response.data && response.data.status === "ok") {
            notificationVariant = "success"
            notificationMessage = "Report Settings updated. Refresh the page"
            opsResult = response.data
        }
        enqueueSnackbar(notificationMessage, {key: notificationKey, variant: notificationVariant});
        return opsResult
    }, [enqueueSnackbar]);

    return <Dialog open={props.isOpen} fullWidth maxWidth="md">
        <DialogTitle>
            Report Analysis Settings
        </DialogTitle>
        <DialogContent sx={{minHeight: "300px", height: "75vh"}}>
            { isLoading
                ? <AnalysisSettingSkeleton/>
                : <AnalysisSettingEditor
                    state={state}
                    dispatch={componentDispatch}
                    readonly={false}
                />
            }
        </DialogContent>
        <DialogActions>
            <Button onClick={props.close}>Cancel</Button>
            <LoadingButton
                variant="contained"
                color="secondary"
                loading={applySettingsState.loading}
                onClick={() => {
                    applySettings(props.prrKey, state)
                        .then((opsResult: ReportStatusResult) => {
                            if (opsResult.status === "ok") {
                                if (opsResult.stages !== undefined) {
                                    props.updateReportStatuses(opsResult.stages)
                                }
                                props.close();
                            }
                        })
                }}
            >
                Apply
            </LoadingButton>
        </DialogActions>
    </Dialog>;
}

export {ReportSettingsDialog}
