import React, {useMemo} from "react";

import {Autocomplete, Chip, TextField} from "@mui/material";

import {Feature, FeatureSet} from "../../../../../app/client/app/entity/report/settings/EntityDefinition";


interface FeatureSetAutocompleteProps {
    fixedValues?: FeatureSet | null
    value: Feature[]

    options: Feature[]
    onChange: (value: Feature[]) => void

    className?: string
}

const FeatureSetAutocomplete = (props: FeatureSetAutocompleteProps) => {
    const className = useMemo(
        () => {
            let className = "field-control"
            if (props.className !== undefined) {
                className += " " + props.className
            }
            return className
        },
        [props.className]
    );

    const [featureOptions, numFixedOptions] = useMemo(() => {
        const value: Feature[] = []
        let fixedOptions: Feature[] = []
        if (!!props.fixedValues) {
            fixedOptions = Object.entries(props.fixedValues).map(item => ({
                name: item[0],
                value: item[1],
            }));
            value.push(...fixedOptions)
        }
        value.push(...props.value)
        return [value, fixedOptions.length] as const
    }, [props.value, props.fixedValues]);

    return <Autocomplete
        multiple
        filterSelectedOptions
        disableClearable
        className={className}
        size={"small"}
        value={featureOptions}
        onChange={(event, newValue) => props.onChange(newValue.slice(numFixedOptions))}
        options={props.options}
        getOptionLabel={option => `${option.name} / ${option.value}`}
        isOptionEqualToValue={(option, value) => option.name === value.name && option.value === value.value}
        renderInput={(params) => <TextField {...params}/>}
        renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
                <Chip
                    label={`${option.name} / ${option.value}`}
                    {...getTagProps({ index })}
                    size={"small"}
                    disabled={index < numFixedOptions}
                />
            ))
        }
    />
}

export {FeatureSetAutocomplete}
export type {FeatureSetAutocompleteProps}
