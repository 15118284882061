import React, {Dispatch} from "react";
import {AnyAction} from "redux";

import {updateDataGroupSettings, updateDevSettings} from "../../editor_reducer";
import {DataGroupSettings} from "../../../../../app/client/app/entity/report/settings/DataGroupSettings";
import {FlagControl} from "../../../../../qdep/components/app/editor/FlagControl";
import tabBodyClasses from "../TabBody.module.css";
import {DevSettings} from "../../../../../app/client/app/entity/report/settings/DevSettings";


interface DataGroupSettingsTabProps {
    settings: DataGroupSettings
    devSettings: DevSettings
    dispatch: Dispatch<AnyAction>
    readonly: boolean
}

const DataGroupSettingsTab = (props: DataGroupSettingsTabProps) => {
    return <div className={tabBodyClasses.settings_group_container}>
        <div>
            <FlagControl
                label={"List Pattern Detection"}
                value={props.settings.enabledListPattern}
                readonly={props.readonly}
                onUpdate={value => props.dispatch(updateDataGroupSettings({enabledListPattern: value}))}
            />
            <FlagControl
                label={"Indent Pattern Detection"}
                value={props.settings.enabledOffsetPattern}
                readonly={props.readonly}
                onUpdate={value => props.dispatch(updateDataGroupSettings({enabledOffsetPattern: value}))}
            />
            <FlagControl
                disabled={!props.settings.enabledOffsetPattern}
                label={"Indent Pattern Detection / Implicit Total Value"}
                value={props.settings.enabledIndentImplicitTrigger && props.settings.enabledOffsetPattern}
                readonly={props.readonly}
                onUpdate={value => props.dispatch(updateDataGroupSettings({enabledIndentImplicitTrigger: value}))}
            />
            <FlagControl
                label={"Unnamed Table Context"}
                value={props.settings.enableUnnamedTableContext}
                readonly={props.readonly}
                onUpdate={value => props.dispatch(updateDataGroupSettings({enableUnnamedTableContext: value}))}
            />
        </div>

        <div>
            <div className={tabBodyClasses.group_title}>Natural Language Processing (English)</div>
            <FlagControl
                label={"Compound Independent Clauses"}
                value={props.settings.enableCompoundContext}
                readonly={props.readonly}
                onUpdate={value => props.dispatch(updateDataGroupSettings({enableCompoundContext: value}))}
            />
            <FlagControl
                disabled
                label={"Entity Detection / DFSM Backend"}
                value={true}
                readonly={props.readonly}
                onUpdate={()=> {}}
            />
            <FlagControl
                label={"Entity Clause / Excluded"}
                value={!props.devSettings.disableExcludedClause}
                readonly={props.readonly}
                onUpdate={value => props.dispatch(updateDevSettings({disableExcludedClause: !value}))}
            />
            <FlagControl
                label={"Entity Clause / Related To"}
                value={!props.devSettings.disableRelatedToClause}
                readonly={props.readonly}
                onUpdate={value => props.dispatch(updateDevSettings({disableRelatedToClause: !value}))}
            />
        </div>
    </div>
}

export {DataGroupSettingsTab}
