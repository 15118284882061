import React, {useCallback} from "react";
import {AnyAction} from "redux";
import {useMount} from "react-use";

import {Slide} from "@mui/material";

import {ReportOcrState} from "./data/reducer";
import {apiClient, ApiResponse, ErrorReason} from "../../../../app/client/app/client";
import {ReportRevisionDto} from "../../../../app/client/app/entity/Report";
import {setReportName, setReportStageStatus} from "../base/report-workspace-header/data/ReportBaseActions";
import {ReportOcrMenuItem, ReportOcrTable} from "../../../../app/client/app/entity/Rp1Report";
import {setMenuItems, setTable} from "./data/action";
import {startLoadingTables} from "../base/report-tables-container/state";
import {ReportPageAddress} from "../base/useReportPageAddress";
import {ReportWorkspaceHeader} from "../base/report-workspace-header/ReportWorkspaceHeader";
import {StageNames} from "../base/StageNames";
import {openCustomRulePane} from "../base/report-custom-rule-pane/data/action";
import {ReportTablesContainer} from "../base/report-tables-container/ReportTablesContainer";
import {ReportOcrTableBlock} from "./ReportOcrTableBlock";
import {TableContextMenu} from "./context-menu/TableContextMenu";
import {ManualModificationRulePane} from "../base/report-custom-rule-pane/ManualModificationRulePane";
import {Stage} from "../../../../app/client/app/entity/report/Stage";
import {usePageNavigation} from "../base/report-page/navigation/page_navigation";
import {selectMenuItem} from "../base/report-page/action";
import {useAuthErrorBoundaryContext} from "../../../../qdep/components/app/error/UnauthorizedErrorBoundary";

interface Props {
    address: ReportPageAddress
    state: ReportOcrState
    dispatch: React.Dispatch<AnyAction>
}

const Rp2Body = (props: Props) => {
    const state = props.state
    const dispatch = props.dispatch
    const address = props.address

    useMount(() => {
        apiClient
            .getReportRevision(address.prrKey)
            .then((response: ApiResponse<ReportRevisionDto>) => {
                if (response.data !== undefined) {
                    dispatch(setReportName(response.data.reportName))
                    dispatch(setReportStageStatus({
                        ocr: response.data.analysis.stages.ocr.status,
                        normalization: response.data.analysis.stages.normalization.status,
                        entityDetection: response.data.analysis.stages.entityDetection.status,
                        tableAnalysis: response.data.analysis.stages.tableAnalysis.status,
                        tableRefs: response.data.analysis.stages.tableRefs.status,
                        summary: response.data.analysis.stages.summary.status,
                    }))
                }
            })

        apiClient
            .getReportOcrMenuItems(address.prrKey)
            .then((response: ApiResponse<ReportOcrMenuItem[]>) => {
                const menu = response.data || [];
                let reportPage = address.query.page
                if (reportPage === undefined && menu.length > 0) {
                    reportPage = menu[0].page
                }

                dispatch(setMenuItems(menu, reportPage))
            })
    })

    const { submitError } = useAuthErrorBoundaryContext()
    const loadPageTablesCallback = useCallback((page: number) => {
        dispatch(startLoadingTables());
        setTimeout(() => {
            apiClient
                .getReportOcrPageTables(address.prrKey, page)
                .then((response: ApiResponse<ReportOcrTable[]>) => {
                    if (!response.errorReason && response.data !== undefined) {
                        dispatch(setTable(response.data))
                    } else {
                        const error = response.errorReason || ErrorReason.UNKNOWN_ERROR;
                        submitError(error)
                    }
                });
        }, 200);
        return
    }, [dispatch, address.prrKey, submitError]);
    const selectMenuItemCallback= useCallback(
        (itemIndex: number) => dispatch(selectMenuItem(itemIndex)),
        [dispatch]
    );
    usePageNavigation(selectMenuItemCallback, loadPageTablesCallback, state.menu.selectedPage)

    return <>
        <ReportWorkspaceHeader
            prrKey={address.prrKey}
            reportName={state.reportName}
            reportStage={StageNames.OCR}
            reportPage={state.menu.selectedPage}
            reportStageStatuses={state.reportStageStatuses}
            updateReportStatuses={stages => dispatch(setReportStageStatus(stages))}

            numCustomRuleUpdates={state.manualModificationRules.customRuleUpdates.length}
            openCustomRuleBlock={() => dispatch(openCustomRulePane())}
        />

        <ReportTablesContainer isLoading={state.tableWorkspace.isLoading}>
            { state.tableWorkspace.tables
                .map((table, index) =>
                    <ReportOcrTableBlock
                        key={`rt-${index}`}
                        table={table}
                        controlPaneShowed={!!state.manualModificationRules && state.manualModificationRules.show && table.entityId === state.manualModificationRules.selectedTableId}
                        dispatch={dispatch}
                    />
                )
            }
        </ReportTablesContainer>

        <TableContextMenu contextMenu={state.contextMenu} dispatch={dispatch}/>
        <Slide
            direction={"up"}
            in={state.manualModificationRules.show}
            unmountOnExit
        >
            <div className={"report-bottom-sticky-container"}>
                <ManualModificationRulePane
                    prrKey={address.prrKey}
                    stage={Stage.OCR_PARSING_STAGE}

                    tableId={state.manualModificationRules.selectedTableId}
                    tableCustomRuleResult={state.manualModificationRules.selectedTableCustomRuleResults}
                    ruleUpdates={state.manualModificationRules.selectedTableRuleUpdates}

                    dispatch={dispatch}
                />
            </div>
        </Slide>
    </>
}

export {Rp2Body}