import React from "react";
import {AnyAction} from "redux";
import {useAsyncFn} from "react-use";
import {SnackbarKey, SnackbarMessage, useSnackbar, VariantType} from "notistack";

import {Checkbox, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';

import {
    ModificationRuleResultRef,
    ModificationRuleUpdate
} from "../../../../../app/client/app/entity/report/ModificationRule";
import {
    WorkspaceBlock,
    WorkspaceBlockBody,
    WorkspaceBlockHeader
} from "../../../../../qdep/components/app/workspace/Workspace";
import {Spacer} from "../../../../../qdep/components/app/util/spacer/Spacer";
import {Label} from "../../../../../qdep/components/app/util/label/Label";
import {ModificationRuleTableToolbar} from "./ModificationRuleTableToolbar";
import {SelectableItem} from "./data/CustomRules";
import {
    clearCustomRuleUpdates,
    closeCustomRulePane,
    deleteSelectedCustomRule,
    deleteSelectedCustomRuleUpdates,
    selectCustomRule,
    selectCustomRuleUpdate
} from "./data/action";
import {PRRKey} from "../../../../../app/client/app/entity/Project";
import {Stage} from "../../../../../app/client/app/entity/report/Stage";
import {apiClient} from "../../../../../app/client/app/client";
import {ReportStatusResult} from "../../../../../app/client/app/entity/ops/ReportStatusResult";

import styles from './ManualModificationRulePane.module.css'
import {setReportStageStatus} from "../report-workspace-header/data/ReportBaseActions";


interface CustomRulePaneProps {
    prrKey: PRRKey
    stage: Stage

    tableId?: string
    ruleUpdates: SelectableItem<ModificationRuleUpdate<any>>[]
    tableCustomRuleResult?: SelectableItem<ModificationRuleResultRef>[]

    dispatch: React.Dispatch<AnyAction>
}

const ManualModificationRulePane = (props: CustomRulePaneProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const [saveUpdatesStatus, saveUpdates] = useAsyncFn(async (prrKey: PRRKey, stage: Stage, updates: ModificationRuleUpdate<any>[]) => {
        const response = await apiClient.updateReportCustomRule(prrKey, stage, updates);

        const notificationKey: SnackbarKey = "update-report-revision-custom-rule-request"
        let notificationVariant: VariantType = "error"
        let notificationMessage: SnackbarMessage = "Something went wrong. Try again later or contact your administrator"
        let opsResult: ReportStatusResult = {status: "failed", stages: undefined}
        if (!response.errorReason && response.data && response.data.status === "ok") {
            notificationVariant = "success"
            notificationMessage = "Report updated. Refresh the page"
            opsResult = response.data
        }
        enqueueSnackbar(notificationMessage, {key: notificationKey, variant: notificationVariant});
        return opsResult
    }, [enqueueSnackbar, props.dispatch]);

    return <WorkspaceBlock>
        <WorkspaceBlockHeader>
            <span>Manual Modification Rules ({props.tableId !== undefined ? props.tableId: "All tables"})</span>
            <Spacer/>
            <IconButton
                disabled={props.ruleUpdates.length === 0 || saveUpdatesStatus.loading}
                onClick={() => {
                    saveUpdates(props.prrKey, props.stage, props.ruleUpdates.map(item => item.data))
                        .then(opsResult => {
                            if (opsResult.status === "ok") {
                                if (opsResult.stages !== undefined) {
                                    props.dispatch(setReportStageStatus(opsResult.stages))
                                }
                                props.dispatch(clearCustomRuleUpdates())
                            }
                        })
                }}
            >
                <SaveIcon/>
            </IconButton>
            <IconButton onClick={() => props.dispatch(closeCustomRulePane())}>
                <CloseIcon/>
            </IconButton>
        </WorkspaceBlockHeader>
        <WorkspaceBlockBody classes={styles.block_body}>
            {(props.tableCustomRuleResult !== undefined && props.tableCustomRuleResult.length > 0) &&
                <div className={styles.table_block}>
                    <ModificationRuleTableToolbar
                        title={`Table Modification Rules (${props.tableCustomRuleResult.length})`}
                        numSelected={props.tableCustomRuleResult.filter(item => item.isSelected).length}
                        onDelete={() => props.dispatch(deleteSelectedCustomRule())}
                    />
                    <TableContainer>
                        <Table size={"small"}>
                            <TableHead>
                                <TableRow>
                                    <TableCell/>
                                    <TableCell>Rule Id</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Rule Type</TableCell>
                                    <TableCell>Description</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    props.tableCustomRuleResult.map((ruleResult, index) =>
                                        <TableRow key={`row-${index}`}>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    checked={ruleResult.isSelected}
                                                    onClick={() => props.dispatch(selectCustomRule(index))}
                                                />
                                            </TableCell>
                                            <TableCell>{ruleResult.data.ruleId}</TableCell>
                                            <TableCell>{statusFormatter(ruleResult.data.status)}</TableCell>
                                            <TableCell>{ruleResult.data.type}</TableCell>
                                            <TableCell>{ruleResult.data.description}</TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            }
            {props.ruleUpdates.length > 0 &&
                <div className={styles.table_block}>
                    <ModificationRuleTableToolbar
                        title={`Table Updates (${props.ruleUpdates.length})`}
                        numSelected={props.ruleUpdates.filter(item => item.isSelected).length}
                        onDelete={() => props.dispatch(deleteSelectedCustomRuleUpdates())}
                    />
                    <TableContainer>
                        <Table size={"small"}>
                            <TableHead>
                                <TableRow>
                                    <TableCell/>
                                    <TableCell>Table Id</TableCell>
                                    <TableCell>Action</TableCell>
                                    <TableCell>Rule Type</TableCell>
                                    <TableCell>Description</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    props.ruleUpdates.map((update, index) =>
                                        <TableRow key={`row-new-${index}`}>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    checked={update.isSelected}
                                                    onClick={() => props.dispatch(selectCustomRuleUpdate(index))}
                                                />
                                            </TableCell>
                                            <TableCell>{update.data.rule.tableId}</TableCell>
                                            <TableCell>{statusFormatter(update.data.eventType)}</TableCell>
                                            <TableCell>{update.data.rule.type}</TableCell>
                                            <TableCell>{update.data.description || ""}</TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            }
        </WorkspaceBlockBody>
    </WorkspaceBlock>
}

function statusFormatter(value: string): React.ReactElement {
    let variant: "success" | "error" | "info" | "disabled" | "warning" = "info"
    switch (value) {
        case "ADD":
        case "APPLIED":
            variant = "success"
            break
        case "INVALID":
            variant = "error"
            break
        case "DELETE":
            variant = "disabled"
            break
        case "EDIT":
            variant = "info"
            break
    }
    return <Label variant={variant} text={value}/>
}

export {ManualModificationRulePane}
