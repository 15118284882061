import React from "react"

import {Chip, Divider, IconButton, Stack} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import LaunchIcon from "@mui/icons-material/Launch";

import {
    Workspace,
    WorkspaceBlock,
    WorkspaceBlockBody,
    WorkspaceBlockHeader,
    WorkspaceSecondaryHeader
} from "../../../../../qdep/components/app/workspace/Workspace";
import {Period} from "../../../../../app/client/app/entity/report/TableMetadata";
import {Spacer} from "../../../../../qdep/components/app/util/spacer/Spacer";
import {DestTableSnap} from "./DestTableSnap";
import {Rp5FeatureSet} from "../../../../../app/client/app/entity/Rp5Report";
import {Rp6CellRefDetailsState} from "../data/reducer";

import styles from './CellRefsView.module.css'
import {Link} from "react-router-dom";

const cellContextContainerClassName = [styles.context_container, styles.cell_context_container].join(" ")

interface ComponentProps {
    cellRef: Rp6CellRefDetailsState | null
    onClose: () => void
}

const CellRefsView = (props: ComponentProps) => {
    if (props.cellRef === null) {
        return <div>todo</div>
    }

    return <Workspace>
        <WorkspaceSecondaryHeader>
            <span>Cell Ref Details</span>
            <Spacer/>
            <IconButton onClick={props.onClose}>
                <CloseIcon/>
            </IconButton>
        </WorkspaceSecondaryHeader>
        <WorkspaceBlock>
            <WorkspaceBlockHeader>
                Source
            </WorkspaceBlockHeader>
            <WorkspaceBlockBody>
                <div className={cellContextContainerClassName}>
                    <Divider textAlign={"left"}>Cell</Divider>
                    <div className={styles.cell_context_body}>
                        <div className={styles.filed_container}>
                            <label>Position</label>
                            <div>
                                <div>
                                    <label>Row:</label>
                                    <span>{props.cellRef.source.cell.row}</span>
                                </div>
                                <div>
                                    <label>Column:</label>
                                    <span>{props.cellRef.source.cell.column}</span>
                                </div>
                            </div>
                        </div>
                        <div className={styles.filed_container}>
                            <label>Value</label>
                            <div
                                className={props.cellRef.source.cell.cellUnit.unitType !== "CURRENCY"
                                    ? styles.cell_value_container
                                    : styles.cell_currency_value_container
                                }
                            >
                                <span>{props.cellRef.source.cell.value}</span>
                                <span>{props.cellRef.source.cell.cellUnit.unit}</span>
                            </div>
                        </div>
                    </div>
                    <PeriodFieldContainer period={props.cellRef.source.cellContext.period}/>
                    <FeaturesFiledContainer features={props.cellRef.source.cellContext.features}/>
                </div>

                <div className={styles.source_context_grid}>
                    <div className={styles.context_container}>
                        <Divider textAlign={"left"}>Row Context</Divider>
                        <div className={styles.filed_container}>
                            <label>Header</label>
                            <span>{props.cellRef.source.rowContext.header || "-"}</span>
                        </div>
                        <PeriodFieldContainer period={props.cellRef.source.rowContext.period}/>
                        <FeaturesFiledContainer features={props.cellRef.source.rowContext.features}/>
                    </div>

                    <div className={styles.context_container}>
                        <Divider textAlign={"left"}>Column Context</Divider>
                        <div className={styles.filed_container}>
                            <label>Header</label>
                            <span>{props.cellRef.source.columnContext.header || "-"}</span>
                        </div>
                        <PeriodFieldContainer period={props.cellRef.source.columnContext.period}/>
                        <FeaturesFiledContainer features={props.cellRef.source.columnContext.features}/>
                    </div>
                </div>
            </WorkspaceBlockBody>
        </WorkspaceBlock>

        { props.cellRef.dest.map((pageGroup, index) =>
            <React.Fragment key={index}>
                <div className={styles.page_group_header_container}>
                    <span>Page {pageGroup.page}</span>
                    <Spacer/>
                    <Link to={location => ({...location, search: `?page=${pageGroup.page}`})} target="_blank">
                        <IconButton>
                            <LaunchIcon/>
                        </IconButton>
                    </Link>
                </div>
                { pageGroup.refs.map(ref =>
                    <DestTableSnap key={ref.tableId} dest={ref}/>
                )}
            </React.Fragment>
        )}

    </Workspace>;
}

interface FeaturesFiledContainerProps {
    features: Rp5FeatureSet | null
}

const FeaturesFiledContainer = (props: FeaturesFiledContainerProps) => {
    return <>
        { (props.features !== null && Object.keys(props.features).length > 0) &&
            <div className={styles.filed_container}>
                <label>Features</label>
                <div className={styles.feature_value_container}>
                    { Object.entries(props.features)
                        .map((feature, index) =>
                            <Chip key={index} label={`${feature[0]} / ${feature[1]}`} size={"small"}/>
                        )
                    }
                </div>
            </div>
        }
    </>
}

interface PeriodFieldContainerProps {
    period: Period
}

const PeriodFieldContainer = (props: PeriodFieldContainerProps) => {
    const period = props.period
    return <>
        { (!period.isUndefined && (!!period.day || !!period.month || !!period.year)) &&
            <div className={styles.filed_container}>
                <label>Period</label>
                <Stack direction={"row"} flexWrap={"wrap"} rowGap={"4px"} columnGap={"8px"}>
                    { !!period.day && <span>Day: {period.day}</span> }
                    { !!period.month && <span>Month: {period.month}</span> }
                    { !!period.year && <span>Year: {period.year}</span> }
                </Stack>
            </div>
        }
    </>
}

export {CellRefsView}