import {AnyAction} from "redux";
import {FeatureFlagDefinition} from "../../../../app/client/app/entity/report/settings/FeatureDefinition";
import {FeatureDefinitionEditorState, FeatureValueSelectorState} from "./state";
import {SelectableItem} from "../../../../qdep/data/util";


const enum ActionTypes {
    SET_FEATURE    = "FEATURE_DEFINITION_EDITOR.SET_FEATURE",
    UPDATE_FEATURE = "FEATURE_DEFINITION_EDITOR.UPDATE_FEATURE",

    ADD_PREPOSITION    = "FEATURE_DEFINITION_EDITOR.ADD_PREPOSITION",
    DELETE_PREPOSITION = "FEATURE_DEFINITION_EDITOR.DELETE_PREPOSITION",

    ADD_VALUE    = "FEATURE_DEFINITION_EDITOR.ADD_VALUE",
    DELETE_VALUE = "FEATURE_DEFINITION_EDITOR.DELETE_VALUE",

    ADD_VALUE_SELECTOR    = "FEATURE_DEFINITION_EDITOR.ADD_VALUE_SELECTOR",
    UPDATE_VALUE_SELECTOR = "FEATURE_DEFINITION_EDITOR.UPDATE_VALUE_SELECTOR",
    DELETE_VALUE_SELECTOR = "FEATURE_DEFINITION_EDITOR.DELETE_VALUE_SELECTOR",
}

function setFeature(feature: FeatureFlagDefinition): AnyAction {
    return {type: ActionTypes.SET_FEATURE, feature: feature}
}

function updateFeature(updates: Partial<FeatureDefinitionEditorState>) {
    return {type: ActionTypes.UPDATE_FEATURE, updates: updates}
}

function addPreposition(value: string): AnyAction {
    return {type: ActionTypes.ADD_PREPOSITION, value: value}
}

function deletePreposition(index: number): AnyAction {
    return {type: ActionTypes.DELETE_PREPOSITION, index: index}
}

function addValue(value: string): AnyAction {
    return {type: ActionTypes.ADD_VALUE, value: value}
}

function deleteValue(index: number): AnyAction {
    return {type: ActionTypes.DELETE_VALUE, index: index}
}

function addValueSelector(value: string): AnyAction {
    return {type: ActionTypes.ADD_VALUE_SELECTOR, value: value}
}

function updateValueSelector(value: string, index: number, updates: Partial<SelectableItem<Partial<FeatureValueSelectorState>>>): AnyAction {
    return {type: ActionTypes.UPDATE_VALUE_SELECTOR, value: value, selectorIndex: index, updates: updates}
}

function deleteValueSelector(value: string): AnyAction {
    return {type: ActionTypes.DELETE_VALUE_SELECTOR, value: value}
}

export {
    ActionTypes,
    setFeature, updateFeature,
    addPreposition, deletePreposition,
    addValue, deleteValue,
    addValueSelector, updateValueSelector, deleteValueSelector,
}
