import React, {ReactNode, useCallback, useEffect, useReducer} from "react";
import {AnyAction} from "redux";

import {Badge, Collapse, IconButton} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import RuleIcon from '@mui/icons-material/Rule';

import {
    WorkspaceBlock,
    WorkspaceBlockBody,
    WorkspaceBlockHeader
} from "../../../../../qdep/components/app/workspace/Workspace";
import {Spacer} from "../../../../../qdep/components/app/util/spacer/Spacer";
import {ReportFeatures} from "../../base/report-workspace-header/data/ReportFeatures";
import {TableTitleContainer} from "../../base/report-table-title-container/TableTitleContainer";
import {openCustomRulePane} from "../../base/report-custom-rule-pane/data/action";
import {ReportTableWorkspaceBlockBody} from "../../base/report-table/ReportTableWorkspaceBlockBody";
import {Rp5Table} from "../../../../../app/client/app/entity/Rp5Report";
import {ReportTableRulePane} from "../table-rules/ReportTableRulePane";
import {Cell, DataGroup, Row} from "./IntermediateTable";
import {defaultTableState} from "./state";
import {closeRulePane, selectDataGroup, selectRule, setData, tableReducer} from "./reducer";
import {FormulaGutter} from "./gutter/FormulaGutter";
import {TransformsGutter} from "./gutter/TransformsGutter";
import {TableCell} from "./cell";

import commonTableStyles from "../../base/report-table/ReportTableWorkspaceBlockBody.module.css";
import styles from "./table.module.css";


interface ContextProps {
    tableId: string
    rowIndex: number
    columnIndex: number
    dgs: DataGroup[]

    position: {
        x: number
        y: number
    }
}

interface ReportTableProps {
    table: Rp5Table
    reportFeatures: ReportFeatures

    controlPaneShowed: boolean
    dispatch: React.Dispatch<AnyAction>
    openContextMenu: (context: ContextProps) => void
}

const ReportTable = (props: ReportTableProps) => {
    const [state, dispatch] = useReducer(tableReducer, defaultTableState);
    const openContextMenuCallback = useCallback((x: number, y: number, cell: Cell) => {
        let dgs: DataGroup[] = []
        if (cell.dgKeys) {
            dgs = cell.dgKeys
                .map(dgKey => state.renderTable.dgs.get(dgKey.id))
                .filter(dg => dg !== undefined) as DataGroup[]
        }

        props.openContextMenu({
            tableId: state.table.entityId,
            rowIndex: cell.row,
            columnIndex: cell.column,
            dgs: dgs,
            position: {x: x, y: y},
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.openContextMenu, state.table.entityId, state.renderTable.dgs]);
    const onCellClickCallback = useCallback((cell: Cell) => {
        if (cell.inspections.length > 0) {
            dispatch(selectRule(cell.inspections.map(inspection => inspection.ruleId)));
        }
    }, []);
    const onSelectDataGroupCallback = useCallback((dgId: string, dkKeyIndex: number, value: boolean) => {
        dispatch(selectDataGroup(dgId, dkKeyIndex, value))
    }, []);

    useEffect(() => {
        dispatch(setData(props.table, props.reportFeatures))
    }, [state.table.entityId, props.reportFeatures, props.table])

    return <>
        <WorkspaceBlock>
            <WorkspaceBlockHeader>
                <TableTitleContainer title={props.table.title} tableId={props.table.entityId}/>
                <Spacer/>
                { props.table.customRuleResult.ruleResults.length > 0 &&
                    <Badge
                        color="error"
                        overlap="circular"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        badgeContent={props.table.customRuleResult.failedRules}
                    >
                        <IconButton onClick={() => props.dispatch(openCustomRulePane(props.table.entityId, props.table.customRuleResult.ruleResults))}>
                            <RuleIcon color={props.controlPaneShowed ? "secondary": undefined}/>
                        </IconButton>
                    </Badge>
                }
            </WorkspaceBlockHeader>
            <ReportTableWorkspaceBlockBody>
                { renderColumnNums(props.reportFeatures, props.table.metadata.column.size) }
                { state.renderTable.rows.map((row: Row, index: number) =>
                    <tr key={`rr-${index}`} className={row.className}>
                        { renderRowNums(props.reportFeatures, index) }
                        { renderRowGutter(props.reportFeatures, row) }
                        { row.cells.map((cell: Cell) =>
                            <TableCell
                                {...cell}
                                key={cell.column}
                                onClick={onCellClickCallback}
                                openContextMenu={openContextMenuCallback}
                                selectDataGroup={onSelectDataGroupCallback}
                            />
                        )}
                    </tr>
                )}
            </ReportTableWorkspaceBlockBody>
        </WorkspaceBlock>

        <Collapse in={state.rulesPane.show} className={styles.rule_result_workspace}>
            <WorkspaceBlock>
                <WorkspaceBlockHeader>
                    <span>Table Analysis Results ({props.table.entityId})</span>
                    <Spacer/>
                    <IconButton onClick={() => dispatch(closeRulePane())}>
                        <CloseIcon/>
                    </IconButton>
                </WorkspaceBlockHeader>
                <WorkspaceBlockBody classes={styles.rule_result_workspace_body}>
                    <ReportTableRulePane
                        rules={state.rulesPane.rules}
                        numRule={state.rulesPane.numRule}
                        numSelectedRules={state.rulesPane.numSelectedRules}
                        dispatch={dispatch}
                    />
                </WorkspaceBlockBody>
            </WorkspaceBlock>
        </Collapse>
    </>
}

function renderRowNums(reportFeatures: ReportFeatures, rowIndex: number): React.ReactElement | undefined {
    if (reportFeatures.rowNumbers) {
        return <td className={commonTableStyles.row_number}>{rowIndex}</td>
    }
    return undefined;
}

function renderColumnNums(reportFeatures: ReportFeatures, size: number): React.ReactElement | undefined {
    if (reportFeatures.columnNumbers) {
        let cells: React.ReactElement[] = []

        if (reportFeatures.rowNumbers) {
            cells.push(<td key={"empty-row-num"} className={[commonTableStyles.column_number, commonTableStyles.row_number].join(" ")}/>)
        }
        if (reportFeatures.showGutterIcons) {
            cells.push(<td key={"empty-row-gutter"} className={[commonTableStyles.column_number, commonTableStyles.row_number].join(" ")}/>)
        }

        for (let index = 0; index < size; index++) {
            cells.push(<td key={`column-num-${index}`} className={commonTableStyles.column_number}>{index}</td>);
        }

        return <tr key={"column-nums-row"}>
            { cells }
        </tr>
    }
    return undefined;
}

function renderRowGutter(reportFeatures: ReportFeatures, row: Row): React.ReactElement | undefined {
    if (reportFeatures.showGutterIcons) {
        const gutters: ReactNode[] = []

        if (row.trigger !== null) {
            gutters.push(<FormulaGutter key={"formula-gutter"} {...row.trigger}/>)
        }
        if (row.transforms !== null && row.transforms.length > 0) {
            gutters.push(<TransformsGutter key={"transform-gutter"} transforms={row.transforms}/>)
        }

        return <td className={commonTableStyles.gutter}>
            { gutters }
        </td>;
    }
    return undefined
}

export type {ContextProps}
export {ReportTable}
