import {AnyAction} from "redux";


interface WithReportTablesWorkspace<T> {
    tableWorkspace: {
        isLoading: boolean
        tables: T[]
    }
}

const TablesWorkspacePrefix = "REPORT_PAGE.TABLE_WORKSPACE."
const startLoadingActionType =  TablesWorkspacePrefix + "START_LOADING"

function startLoadingTables(): AnyAction {
    return {type: startLoadingActionType}
}

function reportTablesContainerReducer<T extends WithReportTablesWorkspace<any>>(state: T, action: AnyAction): T {
    switch (action.type) {
        case startLoadingActionType:
            return {
                ...state,
                tableWorkspace: {
                    isLoading: true,
                    tables: [],
                }
            }
    }
    return state
}

export {TablesWorkspacePrefix, startLoadingTables, reportTablesContainerReducer}
export type {WithReportTablesWorkspace}