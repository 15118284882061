import {SelectableItem} from "../../../../qdep/data/util";
import {FeatureFlagDefinition} from "../../../../app/client/app/entity/report/settings/FeatureDefinition";

interface FeatureDefinitionEditorState {
    id: string | null
    disabled: boolean,
    featureName: string
    prepositions: string[]
    values: FeatureValueState[]
}

interface FeatureValueState {
    name: string
    selectors: SelectableItem<FeatureValueSelectorState>[]
}

interface FeatureValueSelectorState {
    value: string
}

const defaultFeatureDefinitionEditorState: FeatureDefinitionEditorState = {
    id: null,
    disabled: false,
    featureName: "",
    prepositions: [],
    values: [],
}

function valueToState(feature: FeatureFlagDefinition): FeatureDefinitionEditorState {
    return {
        id: feature.id,
        disabled: feature.disabled || false,
        featureName: feature.featureFlagName,
        prepositions: [...feature.prepositions],
        values: feature.values.map(value => ({
            name: value.value,
            selectors: value.selectors.map(selector => ({isSelected: false, data: {value: selector.equal}}))
        }))
    }
}

function stateToValue(state: FeatureDefinitionEditorState): FeatureFlagDefinition {
    return {
        id: state.id,
        disabled: state.disabled,
        featureFlagName: state.featureName,
        prepositions: state.prepositions,
        values: state.values.map(value => ({
            value: value.name,
            selectors: value.selectors.map(selector => ({
                equal: selector.data.value
            }))
        }))
    }
}

export {defaultFeatureDefinitionEditorState, stateToValue, valueToState}
export type {FeatureDefinitionEditorState, FeatureValueState, FeatureValueSelectorState}
