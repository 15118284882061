import React from "react";

import {IconButton, Stack} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';

import {Spacer} from "../../../../../qdep/components/app/util/spacer/Spacer";

import styles from './ManualModificationRulePane.module.css'


interface ModificationRuleTableToolbarProps {
    title: string
    numSelected: number
    onDelete: () => void
}

const ModificationRuleTableToolbar = (props: ModificationRuleTableToolbarProps) => {
    return <Stack direction={"row"} alignItems={"center"} minHeight={"40px"}>
        {props.numSelected === 0
            ? <div className={styles.subheader}>{props.title}</div>
            : <>
                <div className={styles.subheader}>
                    {props.numSelected} selected
                </div>
                <Spacer/>
                <IconButton onClick={props.onDelete}>
                    <DeleteIcon/>
                </IconButton>
            </>
        }
    </Stack>
}

export {ModificationRuleTableToolbar}
