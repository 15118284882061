import React from "react";
import {useDispatch} from "react-redux";
import {push} from "connected-react-router";

import {ListItemIcon, MenuItem, MenuList} from "@mui/material";
import AssessmentIcon from "@mui/icons-material/Assessment";

import {ControlledActionGroupButton} from "../../../../qdep/components/entity-list-workspace-block/ActionGroupButton";
import {PRRKey} from "../../../../app/client/app/entity/Project";


interface ProjectReportListActionGroupProps {
    prrKey?: PRRKey
}

const ProjectReportListActionGroup = (props: ProjectReportListActionGroupProps) => {
    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();

    return <>
        <ControlledActionGroupButton
            isOpen={open}
            open={() => setOpen(true)}
            close={() => setOpen(false)}
            menu={<MenuList id="split-button-menu">
                <MenuItem
                    key="report"
                    disabled={props.prrKey === undefined}
                    onClick={() => {
                        if (props.prrKey !== undefined) {
                            dispatch(push(`/report/${props.prrKey.projectId}/${props.prrKey.reportIndex}/${props.prrKey.revisionIndex}`));
                        }
                        setOpen(false)
                    }}
                >
                    <ListItemIcon>
                        <AssessmentIcon/>
                    </ListItemIcon>
                    Open Report
                </MenuItem>
            </MenuList>}
        />
    </>
}

export {ProjectReportListActionGroup}
