import React, {Dispatch} from "react";
import {AnyAction} from "redux";

import {Skeleton} from "@mui/material";

import {PRRKey} from "../../../../app/client/app/entity/Project";
import {ReportMenuContainer} from "../base/report-menu/ReportMenuContainer";
import {Rp7MenuItemType, Rp7MenuState} from "./data/state";
import {ReportMenuGroupContainer} from "../base/report-menu/ReportMenuGroupContainer";

import styles from "./menu.module.css"
import {selectMenuItem} from "./data/action";
import {Link} from "react-router-dom";
import {ReportMenuItemList} from "../base/report-menu/ReportMenuItemList";


interface PageMenuProps {
    prrKey: PRRKey
    state: Rp7MenuState
    dispatch: Dispatch<AnyAction>
}

const PageMenu = (props: PageMenuProps) => {
    return <ReportMenuContainer prrKey={props.prrKey} dispatch={props.dispatch}>
        <ReportMenuGroupContainer label={"Summary"}>
            <ReportMenuItemList>
                <MenuGroupItem
                    label={"Overview"}
                    itemType={Rp7MenuItemType.OVERVIEW}
                    selectedItem={props.state.selectedItem}
                    dispatch={props.dispatch}
                />
                <CoverageMenuItem
                    label={"Coverage"}
                    selectedItem={props.state.selectedItem}
                    dispatch={props.dispatch}
                />
                <MenuGroupItem
                    label={"Issues"}
                    itemType={Rp7MenuItemType.ISSUE_OVERVIEW}
                    selectedItem={props.state.selectedItem}
                    dispatch={props.dispatch}
                />
            </ReportMenuItemList>
        </ReportMenuGroupContainer>

        { props.state.pages !== undefined &&
            <ReportMenuGroupContainer label={"Pages"}>
                <ReportMenuItemList>
                    { props.state.pages.isLoading &&
                        <>
                            <PageMenuItemSkeleton/>
                            <PageMenuItemSkeleton/>
                            <PageMenuItemSkeleton/>
                        </>
                    }
                </ReportMenuItemList>
            </ReportMenuGroupContainer>
        }
    </ReportMenuContainer>
}

interface MenuGroupItemProps {
    label: string
    itemType: Rp7MenuItemType
    selectedItem: Rp7MenuItemType
    dispatch: Dispatch<AnyAction>
}

const MenuGroupItem = (props: MenuGroupItemProps) => {
    const itemClasses = [styles.menu_item]
    if (props.itemType === props.selectedItem) {
        itemClasses.push(styles.selected)
    }

    return <li
        className={styles.menu_item_container}
        onClick={() => props.dispatch(selectMenuItem(props.itemType))}
    >
        <div className={itemClasses.join(" ")}>
            { props.label }
        </div>
    </li>;
}

interface CoverageMenuItemProps {
    label: string
    selectedItem: Rp7MenuItemType
    dispatch: Dispatch<AnyAction>
}

const CoverageMenuItem = (props: CoverageMenuItemProps) => {
    const itemClasses = [styles.menu_item]
    if (props.selectedItem === Rp7MenuItemType.COVERAGE_TABLES) {
        itemClasses.push(styles.selected)
    }

    return <li className={styles.menu_item_container}>
        <Link to={"summary/coverage"}>
            <div className={itemClasses.join(" ")}>
                { props.label }
            </div>
        </Link>
    </li>;
}
const PageMenuItemSkeleton = () => {
    return <li className={[styles.menu_item_container, styles.skeleton].join(" ")}>
        <Skeleton/>
    </li>
}

export {PageMenu}