import React, {CSSProperties} from "react";

import './Label.css'

type LabelVariant = "success" | "error" | "info" | "disabled" | "warning" | "excluded"

interface LabelProps {
    text: string | number
    variant: LabelVariant
    size?: "small" | "normal" | "large"
    containerStyles?: CSSProperties
    textStyles?: CSSProperties
    icon?: React.ReactNode;

    onClick?: () => void
}

const Label = (props: LabelProps) => {
    const classes = ["label-container", props.variant]
    if (props.size) {
        classes.push(props.size)
    }
    if (props.onClick) {
        classes.push("actionable")
    }

    return <div
        className={classes.join(" ")}
        style={props.containerStyles}
        onClick={props.onClick}
    >
        { props.icon && props.icon }
        <div className={"text"} style={props.textStyles}>
            { props.text }
        </div>
    </div>;
}

export {Label}
export type {LabelProps, LabelVariant}
