import React, {useEffect, useMemo, useState} from "react";

import {Chip, Skeleton, Stack, styled} from "@mui/material";
import {TreeItem, TreeItemProps, TreeView} from "@mui/lab";
import {treeItemClasses} from "@mui/lab/TreeItem";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import {PRRKey} from "../../../app/client/app/entity/Project";
import {apiClient} from "../../../app/client/app/client";
import {Spacer} from "../../../qdep/components/app/util/spacer/Spacer";
import {mapTocItemsToState, TocItemState} from "./state";
import {formatPeriod, Period} from "../../../app/client/app/entity/report/TableMetadata";

import styles from "./TocView.module.css"


interface ComponentProps {
    prrKey: PRRKey

    hideItemWithoutTable: boolean
}

const TocView = (props: ComponentProps) => {
    const [state, setState] = useState<TocItemState[] | null>(null);
    useEffect(
        () => {
            apiClient.getReportContents(props.prrKey)
                .then(response => {
                    if (response.errorReason === undefined && response.data !== undefined) {
                        setState(mapTocItemsToState(response.data.items))
                    }
                })
        },
        [props.prrKey]
    )

    return <div>
        { state === null && <Skeleton height={150} variant={'rectangular'}/> }
        { state !== null &&
            <TreeView
                defaultCollapseIcon={<ExpandMoreIcon/>}
                defaultExpandIcon={<ChevronRightIcon/>}
            >
                <RootItem subItems={state} hideItemWithoutTable={props.hideItemWithoutTable}/>
            </TreeView>
        }
    </div>
}

interface RootItemProps {
    subItems: TocItemState[]
    hideItemWithoutTable: boolean
}

const RootItem = (props: RootItemProps) => {
    const children = useMemo(
        () => {
            const children: React.ReactNode[] = []

            props.subItems.forEach(item => {
                if (!props.hideItemWithoutTable || (props.hideItemWithoutTable && item.totalAttachedTables > 0)) {
                    children.push(
                        <TocTreeItem
                            key={item.nodeId}
                            {...item}
                            hideItemWithoutTable={props.hideItemWithoutTable}
                        />
                    )
                }
            })

            return children
        },
        [props.subItems, props.hideItemWithoutTable]
    )

    return <>
        {children}
    </>
};

const StyledTreeItem = styled((props: TreeItemProps) => (
    <TreeItem {...props}/>
))(() => ({
    [`& .${treeItemClasses.content}`]: {
        width: "inherit",
    },
}))

type TocTreeItemProps = TocItemState & {
    hideItemWithoutTable: boolean
}

const TocTreeItem = (props: TocTreeItemProps) => {
    const page = useMemo(
        () => {
            if (props.pageEnd === null || props.page === props.pageEnd) {
                return props.page.toString()
            } else {
                return `${props.page} - ${props.pageEnd}`
            }
        }, [props.page, props.pageEnd]
    )

    const children = useMemo(
        () => {
            const children: React.ReactNode[] = []

            props.subItems.forEach(item => {
                if (!props.hideItemWithoutTable || (props.hideItemWithoutTable && item.totalAttachedTables > 0)) {
                    children.push(
                        <TocTreeItem
                            key={item.nodeId}
                            {...item}
                            hideItemWithoutTable={props.hideItemWithoutTable}
                        />
                    )
                }
            })

            props.attachedTables.forEach((tableId, index) => {
                const key = `${props.nodeId}_table_${index}`;
                children.push(<StyledTreeItem
                        key={key}
                        nodeId={key}
                        label={tableId}
                    />
                )
            })

            return children
        },
        [props.subItems, props.attachedTables, props.hideItemWithoutTable, props.nodeId]
    )

    return <StyledTreeItem
        key={props.nodeId}
        nodeId={props.nodeId}
        label={
            <TocTreeItemLabel
                page={page}
                title={props.title}
                tables={props.totalAttachedTables}
                period={props.period}
            />
        }
    >
        {children}
    </StyledTreeItem>
}

interface TreeItemLabelProps {
    page: string
    title: string
    tables: number
    period: Period | null
}

const TocTreeItemLabel = (props: TreeItemLabelProps) => {
    const containerClassName = useMemo(
        () => {
            let containerClassName = styles.toc_item_label_container
            if (props.tables === 0) {
                containerClassName += " " + styles.toc_item_label_no_tables
            }
            return containerClassName
        },
        [props.tables]
    )

    return <div className={containerClassName}>
        <Stack direction={"row"} gap={"4px"} flexWrap={"wrap"}>
            <span>
                { props.title }
            </span>
            { (props.period !== null && !props.period.isUndefined) &&
                <Chip size={"small"} label={formatPeriod(props.period)}/>
            }
            { props.tables > 0 &&
                <Chip size={"small"} label={`Tables: ${props.tables}`}/>
            }
        </Stack>
        <Spacer minWidth={16}/>
        <span className={styles.toc_item_label_page_container}>
            { props.page }
        </span>
    </div>;
}

export {TocView}