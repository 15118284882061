import {Dispatch} from "redux";
import {connect} from "react-redux";

import {AppState} from "../../../app/state/state";
import {
    EntityListBlock,
    EntityListBlockAction,
    EntityListBlockProps
} from "../../../qdep/components/entity-list-workspace-block/EntityListBlock";
import {DefaultRowFactory} from "../../../qdep/components/entity-list-workspace-block/default/RowFactory";
import {EntityListSettings} from "../../../qdep/components/entity-list-workspace-block/EntityListSettingsState";
import {WorkspaceBlock} from "../../../qdep/components/app/workspace/Workspace";
import {defaultProjectReportListState} from "./data/state";
import {projectReportListRequest} from "../../../app/data/action/AppAction";
import {listSelectRow, updateSettings} from "./data/action";
import {formatDate} from "../../../qdep/components/entity-list-workspace-block/formatters";
import {ReportRevision} from "../../../app/client/app/entity/Report";
import {ProjectReportListActionGroup} from "./action/ProjectReportListActionGroup";
import {PRRKey} from "../../../app/client/app/entity/Project";
import {RevisionStatusFormatter} from "../../util/RevisionStatusFormatter";


const mapStateToProps = (state: AppState): EntityListBlockProps => {
    const workspaceState = state.report.list || defaultProjectReportListState
    const data = workspaceState.data

    let prrKey: PRRKey | undefined = undefined
    if (workspaceState.selectedRow !== undefined) {
        const item = data.data[workspaceState.selectedRow];
        if (item !== undefined) {
            prrKey = {
                projectId: item.projectId,
                reportIndex: item.index,
                revisionIndex: item.lastRevision.index,
            }
        }
    }

    return {
        title: "Reports",
        titleDetail: data.data !== undefined ? `(${data.data.length})`: "",

        selectedRow: workspaceState.selectedRow,
        rowFactory: DefaultRowFactory,

        settings: workspaceState.settings,
        data: data,

        columnSpec: [
            {name: "projectId", title: "Project Id"},
            {name: "name", title: "Name"},
            {name: "description", title: "Description"},
            {name: "periodType", title: "Period Type"},
            {name: "revisions", title: "Revisions"},
            {
                id: "lastRevisionStatus",
                name: "lastRevision",
                title: "Status",
                format: (value: ReportRevision) => <RevisionStatusFormatter value={value.status}/>
            },
            {
                id: "lastRevisionCreationDate",
                name: "lastRevision",
                title: "Creation date",
                format: (value: ReportRevision) =>
                    value.fileMetadata === null ? "-": formatDate(value.fileMetadata.createDate)
            },
        ],
        actionGroup: <ProjectReportListActionGroup prrKey={prrKey}/>
    }
}

const mapDispatchToProps = (dispatch: Dispatch): EntityListBlockAction => {
    return {
        refresh: (_: number) => dispatch(projectReportListRequest()),
        updateSettings: (settings: EntityListSettings) => dispatch(updateSettings(settings)),
        select: (index: number) => dispatch(listSelectRow(index)),
    }
}

const ProjectReportList = connect(mapStateToProps, mapDispatchToProps)(EntityListBlock)

const ProjectReportListWorkspace = () => {
    return <WorkspaceBlock>
        <ProjectReportList/>
    </WorkspaceBlock>
}

export {ProjectReportListWorkspace}