import {NewProjectDto, ProjectDto} from "../../../../app/client/app/entity/Project";
import {ProjectUpdates} from "../../../../app/client/app/entity/ProjectUpdate";
import {RevisionFilePayload} from "../../../../app/client/app/client";


interface ProjectViewState {
    entity: ProjectDto
    updates: ProjectUpdates
    newRevisions: RevisionFilePayload[]
    openNewReportDialog: boolean
}

function NewProjectViewState(): ProjectViewState {
    return {
        entity: NewProjectDto([]),
        updates: [],
        newRevisions: [],
        openNewReportDialog: false,
    }
}

export type {ProjectViewState}
export {NewProjectViewState}
