import {CustomRuleResults, ModificationRuleData} from "./report/ModificationRule";
import {TableTitle} from "./report/TableTitle";


interface ReportOcrMenuItem {
    page: number
    count: number
}

interface ReportOcrTable {
    prrKey: string
    entityId: string
    page: number
    title: TableTitle
    rows: ReportTableRowDto[]
    modificationRuleResult: CustomRuleResults
}

interface ReportTableRowDto {
    cells: ReportTableCellDto[]
    deleted: boolean
}

interface ReportTableCellDto {
    data: string
    colSpan: number
    indent: number | null
}

enum Rp1CustomRuleType {
    EDIT_CELL_COLSPAN = "EDIT_CELL_COLSPAN",
    EDIT_CELL_VALUE   = "EDIT_CELL_VALUE",
    DELETE_ROW        = "DELETE_ROW",
    SET_TABLE_TITLE   = "SET_TABLE_TITLE",
}

interface Rp1CustomRule extends ModificationRuleData<typeof Rp1CustomRuleType> {
    editCell?: {
        columnIndex: number
        rowIndex: number
        colSpan: number | undefined
        value: string | undefined
    }
    tableTitle?: {
        title: string
    }
}

export type {
    Rp1CustomRule, ReportOcrMenuItem,
    ReportTableCellDto, ReportTableRowDto, ReportOcrTable,
}
export {Rp1CustomRuleType}
