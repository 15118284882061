import {Stack, Typography} from "@mui/material";

const PageNotFoundError = () => {
    return <Stack direction={"column"} justifyContent={"center"} spacing={2} paddingTop={10} width={"100%"}>
        <Typography variant={"h3"}>Page not found</Typography>
        <Stack>
            <Typography>
                Try refreshing the page, or select the other page.
            </Typography>
        </Stack>
    </Stack>
}

export {PageNotFoundError}
