import React from "react";
import {AnyAction} from "redux";

import {MenuItem, TextField} from "@mui/material";

import {updateDateRowRuleSettings, updateSimpleRowRuleSettings} from "../../editor_reducer";
import {
    RowRuleSelectorType,
    RowRuleSettings
} from "../../../../../app/client/app/entity/report/settings/RowRuleSettings";
import {FlagControl} from "../../../../../qdep/components/app/editor/FlagControl";
import {SelectorList, SelectorListItemProps} from "../../selectors-control/SelectorList";
import {Selector} from "../../field-control/Selector";

import tabBodyClasses from "../TabBody.module.css";


interface RowRuleSettingsTabProps {
    settings: RowRuleSettings
    dispatch: React.Dispatch<AnyAction>
    readonly: boolean
}

const RowRuleSettingsTab = (props: RowRuleSettingsTabProps) => {
    return <div className={tabBodyClasses.settings_group_container}>
        <div>
            <div className={tabBodyClasses.group_title}>Row Rule</div>
            <FlagControl
                label={"Enabled"}
                value={props.settings.simpleRule.enabled}
                readonly={props.readonly}
                onUpdate={value => props.dispatch(updateSimpleRowRuleSettings({enabled: value}))}
            />
            <FlagControl
                label={"Empty Header Trigger"}
                value={props.settings.simpleRule.emptyRowHeaderTrigger}
                readonly={props.readonly}
                onUpdate={value => props.dispatch(updateSimpleRowRuleSettings({emptyRowHeaderTrigger: value}))}
            />

            <SelectorList
                initValues={props.settings.simpleRule.selectors}
                defaultValue={{type: RowRuleSelectorType.EQUAL, value: ""}}
                readonly={props.readonly}
                onChange={value => props.dispatch(updateSimpleRowRuleSettings({selectors: value}))}
            >
                <SelectorList.Toolbar label={"Selectors"}/>
                <SelectorList.Body itemComponent={RuleSelector}/>
            </SelectorList>

            <SelectorList
                initValues={props.settings.simpleRule.exclusions}
                defaultValue={{type: RowRuleSelectorType.EQUAL, value: ""}}
                readonly={props.readonly}
                onChange={value => props.dispatch(updateSimpleRowRuleSettings({exclusions: value}))}
            >
                <SelectorList.Toolbar label={"Exclusions"}/>
                <SelectorList.Body itemComponent={RuleSelector}/>
            </SelectorList>
        </div>

        <div>
            <div className={tabBodyClasses.group_title}>Row Date Rule</div>
            <FlagControl
                label={"Enabled"}
                value={props.settings.dateRule.enabled}
                readonly={props.readonly}
                onUpdate={value => props.dispatch(updateDateRowRuleSettings({enabled: value}))}
            />

            <SelectorList
                initValues={props.settings.dateRule.selectors}
                defaultValue={{type: RowRuleSelectorType.EQUAL, value: ""}}
                readonly={props.readonly}
                onChange={value => props.dispatch(updateDateRowRuleSettings({selectors: value}))}
            >
                <SelectorList.Toolbar label={"Selectors"}/>
                <SelectorList.Body itemComponent={RuleSelector}/>
            </SelectorList>
        </div>
    </div>
}

const RuleSelector = (props: SelectorListItemProps) => {
    return <>
        <Selector
            value={props.value.data.type}
            readonly={props.readonly}
            onChange={(value: RowRuleSelectorType) => {
                props.onUpdate(props.index, {data: {type: value}})
            }}
        >
            <MenuItem value={RowRuleSelectorType.REGEXP}>REGEXP</MenuItem>
            <MenuItem value={RowRuleSelectorType.EQUAL}>EQUAL</MenuItem>
            <MenuItem value={RowRuleSelectorType.STARTS_WITH}>STARTS_WITH</MenuItem>
            <MenuItem value={RowRuleSelectorType.ENDS_WITH}>ENDS_WITH</MenuItem>
            <MenuItem value={RowRuleSelectorType.CONTAINS}>CONTAINS</MenuItem>
        </Selector>
        <TextField
            size="small"
            fullWidth
            value={props.value.data.value}
            disabled={props.readonly}
            onChange={event => {
                props.onUpdate(props.index, {
                    data: {
                        value: event.target.value,
                    }
                })
            }}
        />
    </>
}

export {RowRuleSettingsTab}