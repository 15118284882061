import React, {createContext, useContext} from "react";

import {Checkbox, IconButton} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import {SelectableItem} from "../../../../qdep/data/util";
import {Spacer} from "../../../../qdep/components/app/util/spacer/Spacer";

import styles from "./SelectorList.module.css";


type SelectorListItems = SelectableItem<any>[]

interface SelectorListContextData {
    values: SelectorListItems
    readonly: boolean

    addItem: () => void
    updateItem: (itemIndex: number, update: Partial<SelectableItem<any>>) => void
    deleteSelectedItems: () => void
}

const defaultSelectorListContextData: SelectorListContextData = {
    values: [],
    readonly: false,
    addItem: () => {},
    updateItem: () => {},
    deleteSelectedItems: () => {},
}

const SelectorListContext = createContext<SelectorListContextData>(defaultSelectorListContextData);

function useSelectorListContext(): SelectorListContextData {
    const context = useContext(SelectorListContext);

    if (!context) {
        throw new Error("Child components of SelectorList cannot be rendered outside the SelectorList component!");
    }

    return context;
}

interface SelectorListProps {
    initValues: any[]
    defaultValue: any

    onChange: (value: any[]) => void
    children: React.ReactNode | React.ReactNode[]

    readonly: boolean
}

interface SelectorListState {
    values: SelectorListItems
    readonly: boolean
}

class SelectorListContainer extends React.Component<SelectorListProps, SelectorListState> {

    private readonly onChange: (values: SelectableItem<any>[]) => void

    constructor(props: SelectorListProps) {
        super(props);

        this.state = {
            values: props.initValues.map(item => ({isSelected: false, data: item})),
            readonly: props.readonly,
        }

        this.addItem = this.addItem.bind(this)
        this.updateItem = this.updateItem.bind(this)
        this.deleteSelectedItems = this.deleteSelectedItems.bind(this)
        this.onChange = (values) => props.onChange(values.map(item => item.data))
    }

    static getDerivedStateFromProps(props: SelectorListProps, state: SelectorListState): SelectorListState {
        if (props.readonly !== state.readonly) {
            return {
                ...state,
                readonly: props.readonly,
            }
        }
        return state;
    }

    addItem() {
        this.setState(prev => ({
            values: [...prev.values, {isSelected: false, data: this.props.defaultValue}]
        }))
    }

    updateItem(itemIndex: number, update: Partial<SelectableItem<any>>) {
        this.setState(prev => {
            const next = [...prev.values];

            if (update.isSelected !== undefined) {
                next[itemIndex].isSelected = update.isSelected
            }
            if (update.data !== undefined) {
                next[itemIndex].data = {
                    ...next[itemIndex].data,
                    ...update.data,
                }
            }

            return {
                values: next
            }
        }, () => this.onChange(this.state.values))
    }

    deleteSelectedItems() {
        this.setState(
            prev => ({values: prev.values.filter(item => !item.isSelected)}),
            () => this.onChange(this.state.values),
        )
    }

    render() {
        return <SelectorListContext.Provider value={{
            values: this.state.values,
            readonly: this.state.readonly,
            addItem: this.addItem,
            updateItem: this.updateItem,
            deleteSelectedItems: this.deleteSelectedItems,
        }}>
            { this.props.children }
        </SelectorListContext.Provider>
    }
}

interface ListToolbarProps {
    label: string
}

const ListToolbar = (props: ListToolbarProps) => {
    const context = useSelectorListContext();

    return <div className={styles.toolbar_container}>
        <label>{props.label}</label>
        <Spacer/>
        { !context.readonly &&
            <>
                <IconButton
                    onClick={context.addItem}
                >
                    <AddIcon/>
                </IconButton>
                <IconButton
                    onClick={context.deleteSelectedItems}
                >
                    <DeleteIcon/>
                </IconButton>
            </>
        }
    </div>
}

interface SelectorListItemProps {
    index: number
    readonly: boolean
    value: SelectableItem<any>
    onUpdate: (itemIndex: number, update: Partial<SelectableItem<any>>) => void
}

interface ListBodyProps {
    itemComponent: React.FunctionComponent<SelectorListItemProps>
}

const ListBody = (props: ListBodyProps) => {
    const context = useSelectorListContext();
    const Component = props.itemComponent
    const className = context.readonly ? styles.body_container_readonly: styles.body_container

    return <>
        { context.values.length > 0 &&
            <div className={className}>
                { context.values.map((item, index) =>
                    <>
                        { !context.readonly &&
                            <Checkbox
                                checked={item.isSelected}
                                onChange={(_, checked) => {
                                    context.updateItem(index, {isSelected: checked})
                                }}
                            />
                        }
                        <Component key={index} index={index} value={item} readonly={context.readonly} onUpdate={context.updateItem}/>
                    </>
                )}
            </div>
        }
        { context.values.length === 0 &&
            <div className={styles.empty_list_container}>
                <span>No items</span>
            </div>
        }
    </>
}


const SelectorList = (props: SelectorListProps) =>
    <div className={"filed-container " + styles.selector_list_container}>
        <SelectorListContainer {...props}/>
    </div>

SelectorList.Toolbar = ListToolbar
SelectorList.Body = ListBody

export {SelectorList}
export type {SelectorListItemProps}
