import React, {createContext, useCallback, useContext} from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";

import {ErrorReason} from "../../../../app/client/types";
import {authTokenExpired} from "../../../../workspace/auth/login-block/data";


interface AuthErrorBoundaryContextData {
    reason: ErrorReason
    submitError: (reason: ErrorReason) => void
}

const AuthErrorBoundaryContext = createContext<AuthErrorBoundaryContextData>({
    reason: ErrorReason.NONE,
    submitError: () => {}
})

function useAuthErrorBoundaryContext(): AuthErrorBoundaryContextData {
    const context = useContext(AuthErrorBoundaryContext);

    if (!context) {
        throw new Error("AuthErrorBoundaryContext not found");
    }

    return context;
}

interface Props {
    children: React.ReactNode
}

const UnauthorizedErrorBoundary = (props: Props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const submitAuthError = useCallback((reason: ErrorReason) => {
        if (reason === ErrorReason.TOKEN_EXPIRED) {
            dispatch(authTokenExpired())
            history.push("/login")
        }
    }, [dispatch, history])

    return <AuthErrorBoundaryContext.Provider value={{
        reason: ErrorReason.NONE,
        submitError: submitAuthError,
    }}>
        { props.children }
    </AuthErrorBoundaryContext.Provider>
}

export {
    AuthErrorBoundaryContext,
    UnauthorizedErrorBoundary,
    useAuthErrorBoundaryContext,
}
export type {
    AuthErrorBoundaryContextData,
}
