import React, {useEffect, useReducer, useState} from "react";
import {useAsyncFn} from "react-use";
import {AnyAction} from "redux";
import {useHistory} from "react-router-dom";
import _ from "lodash";

import {Button, Divider, IconButton, List, ListItem, TextField} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {Launch} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import {
    FieldContainer,
    WorkspaceBlock,
    WorkspaceBlockBody,
    WorkspaceBlockHeader, WorkspaceSecondaryHeader
} from "../../../../../qdep/components/app/workspace/Workspace";
import {Spacer} from "../../../../../qdep/components/app/util/spacer/Spacer";
import {NewItemButton} from "../../../../../qdep/components/app/util/new-item-button/NewItemButton";
import {Label} from "../../../../../qdep/components/app/util/label/Label";
import {DiscardChangesDialog} from "../../../../../qdep/components/app/dialog/DiscardChangesDialog";
import {ReportModel} from "../../../../../app/client/app/entity/report/model/ReportModel";
import {ApiResponse} from "../../../../../app/client/types";
import {apiClient} from "../../../../../app/client/app/client";
import {AnalysisSettingEditor} from "../../../analysis_settings/editor";
import {RevisionSelector} from "../revision-selector/RevisionSelector";
import {
    defaultState, deleteEntityModelRevision,
    editorReducer,
    newAnalysisSettingsRevision,
    selectAnalysisSettingsRevision,
    setModel,
    updateModel
} from "./editor_reducer";
import {EditorActionsContainer} from "../../../../../qdep/components/app/editor/EditorActionsContainer";

import styles from './editor.module.css'
import {EditorTypeButton} from "./editor_type_btn";
import {SnackbarKey, SnackbarMessage, useSnackbar, VariantType} from "notistack";


interface ComponentProps {
    model: ReportModel
    onUpdate: (model: ReportModel) => void
}

const ModelEditor = (props: ComponentProps) => {
    const history = useHistory();
    const [state, dispatch] = useReducer(editorReducer, defaultState);
    const [showDiscardChangesDialog, setShowDiscardChangesDialog] = useState<{show: boolean, deferredAction?: AnyAction}>({show: false});

    useEffect(() => {
        console.log(props.model)
        dispatch(setModel(props.model))
    }, [props.model])

    const { enqueueSnackbar } = useSnackbar();
    const [saveModelState, saveModel] = useAsyncFn(async (model: ReportModel): Promise<ApiResponse<ReportModel>> => {
        return await apiClient.saveModel(model)
    }, []);
    const [, deleteEntityRevision] = useAsyncFn(async (modelId: string | null, revisionId: string): Promise<void> => {
        const notificationKey: SnackbarKey = "delete_entity_model_revision"
        let notificationVariant: VariantType = "error"
        let notificationMessage: SnackbarMessage = "Something went wrong. Try again later or contact your administrator"

        if (modelId !== null) {
            const response = await apiClient.deleteEntityModelRevision(modelId, revisionId);
            if (response.errorReason === undefined && response.data === true) {
                notificationVariant = "success"
                notificationMessage = "Entity Revision removed"

                dispatch(deleteEntityModelRevision(revisionId))
            }
        }

        enqueueSnackbar(notificationMessage, {key: notificationKey, variant: notificationVariant});
    }, []);

    return <>
        <WorkspaceBlock>
            <WorkspaceBlockHeader>
                Info
            </WorkspaceBlockHeader>
            <WorkspaceBlockBody>
                <FieldContainer title={"Name"}>
                    <TextField
                        className={"field-control"}
                        size={"small"}
                        value={state.model.name}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            dispatch(updateModel({name: event.target.value}))
                        }}
                    />
                </FieldContainer>
                <FieldContainer title={"Description"} notAlign>
                    <TextField
                        multiline
                        rows={3}
                        className={"field-control"}
                        size={"small"}
                        value={state.model.description}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            dispatch(updateModel({description: event.target.value}))
                        }}
                    />
                </FieldContainer>
            </WorkspaceBlockBody>
        </WorkspaceBlock>

        { state.type === "UNDEFINED" &&
            <>
                <WorkspaceSecondaryHeader className={styles.section_subheader}>
                    <span>Type</span>
                </WorkspaceSecondaryHeader>
                <div className={styles.type_block_container}>
                    <EditorTypeButton
                        title={"Create Analysis Settings Revision"}
                        onClick={() => dispatch(newAnalysisSettingsRevision())}
                    />
                    <EditorTypeButton
                        title={"Create Entity Model Revision"}
                        onClick={() => history.push(`/report/models/${props.model.id}/entity/new`)}
                    />
                </div>
            </>
        }

        { state.type === "ANALYSIS" &&
            <WorkspaceBlock>
                <WorkspaceBlockHeader>
                    <span style={{marginRight: "8px"}}>Analysis Settings</span>
                    { state.currentAnalysisSettingsRevision?.isNew && <Label text={"New"} variant={"success"} size={"large"}/> }
                    { state.currentAnalysisSettingsRevision?.isChanged && <Label text={"Changed"} variant={"info"} size={"large"}/> }
                    <Spacer/>
                    <RevisionSelector
                        revisions={state.model.analysisSettingsRevisions.map(revision => revision.revisionId)}
                        selectedRevision={state.currentAnalysisSettingsRevision?.revisionId}
                        onChange={revisionId => {
                            const action = selectAnalysisSettingsRevision(revisionId);
                            if (state.currentAnalysisSettingsRevision?.isChanged) {
                                setShowDiscardChangesDialog({
                                    show: true,
                                    deferredAction: action,
                                })
                            } else {
                                dispatch(action)
                            }
                        }}
                    />
                </WorkspaceBlockHeader>
                <WorkspaceBlockBody>
                    { state.currentAnalysisSettingsRevision === undefined &&
                        <div className={styles.add_revision_btn_wrapper}>
                            <NewItemButton title={"Add Revision"} onClick={() => dispatch(newAnalysisSettingsRevision())}/>
                        </div>
                    }
                    { state.currentAnalysisSettingsRevision !== undefined &&
                        <AnalysisSettingEditor
                            state={state.currentAnalysisSettingsRevision.settings}
                            dispatch={dispatch}
                            readonly={false}
                        />
                    }
                </WorkspaceBlockBody>
            </WorkspaceBlock>
        }

        { state.type === "ENTITY" &&
            <>
                <WorkspaceSecondaryHeader className={styles.section_subheader}>
                    <span>Entity Settings</span>
                    <Spacer/>
                    <IconButton
                        onClick={() => {
                            history.push(`/report/models/${props.model.id}/entity/new`)
                        }}
                    >
                        <AddIcon/>
                    </IconButton>
                </WorkspaceSecondaryHeader>

                { state.model.entityModelRevisions.map((revision, index) =>
                    <WorkspaceBlock key={`entity_rev_${index}`}>
                        <WorkspaceBlockBody classes={styles.entity_card_container}>
                            <div className={styles.entity_card}>
                                <div>
                                    <FieldContainer title={"Revision Id"}>
                                        <span>{ revision.revisionId }</span>
                                    </FieldContainer>
                                    <FieldContainer title={"Name"}>
                                        <span>{ revision.name }</span>
                                    </FieldContainer>
                                    <FieldContainer title={"Description"}>
                                        <span>{ revision.description }</span>
                                    </FieldContainer>
                                    <FieldContainer title={"Created"}>
                                        <span>{ revision.date }</span>
                                    </FieldContainer>
                                </div>

                                { (revision.attachedReports !== null && revision.attachedReports.length > 0) &&
                                    <>
                                        <Divider/>
                                        <List sx={{paddingTop: "0"}}>
                                            { revision.attachedReports?.map(ref =>
                                                <ListItem key={ref.prrKey} disableGutters>
                                                    { ref.prrKey }
                                                </ListItem>
                                            )}
                                        </List>
                                    </>
                                }
                            </div>
                            <div className={styles.entity_card_control}>
                                <IconButton
                                    onClick={() => {
                                        history.push(`/report/models/${props.model.id}/entity/${revision.revisionId}`)
                                    }}
                                >
                                    <Launch/>
                                </IconButton>
                                <Divider flexItem orientation={"horizontal"} sx={{margin: "0 8px"}}/>
                                <IconButton
                                    disabled={revision.attachedReports !== null && revision.attachedReports.length > 0}
                                    onClick={() => deleteEntityRevision(props.model.id, revision.revisionId)}
                                >
                                    <DeleteIcon/>
                                </IconButton>
                            </div>
                        </WorkspaceBlockBody>
                    </WorkspaceBlock>
                )}
            </>
        }

        <EditorActionsContainer>
            <Button disabled variant="outlined">
                Cancel
            </Button>
            <LoadingButton
                loading={saveModelState.loading}
                variant="contained"
                color="secondary"
                onClick={() => {
                    const model: ReportModel = _.cloneDeep(state.model);
                    if (state.currentAnalysisSettingsRevision !== undefined
                        && (state.currentAnalysisSettingsRevision.isChanged || state.currentAnalysisSettingsRevision.isNew))
                    {
                        model.analysisSettingsRevisions.push({
                            revisionId: "",
                            date: "",
                            settings: state.currentAnalysisSettingsRevision.settings,
                        })
                    }

                    saveModel(model)
                        .then(response => {
                            if (!response.errorReason && response.data) {
                                props.onUpdate(response.data)
                            }
                        })
                }}
            >
                Apply
            </LoadingButton>
        </EditorActionsContainer>

        <DiscardChangesDialog
            isOpen={showDiscardChangesDialog.show}
            deferredAction={showDiscardChangesDialog.deferredAction}
            close={() => setShowDiscardChangesDialog({show: false})}
            confirm={deferredAction => {
                setShowDiscardChangesDialog({show: false})
                if (deferredAction) {
                    dispatch(deferredAction)
                }
            }}
        />
    </>
}

export {ModelEditor}
