import {EntityListSettingsState} from "../../../../qdep/components/entity-list-workspace-block/EntityListSettingsState";
import {DataPiece} from "../../../../qdep/data/util";
import {ReportListItem} from "../../../../app/client/app/entity/Report";


interface ProjectReportListState extends EntityListSettingsState {
    data: DataPiece<ReportListItem[]>
}

const defaultProjectReportListState: ProjectReportListState = {
    data: {isLoaded: false, data: []},
    settings: {
        pageSize: 25,
        columns: {
            "Project Id": true,
            "Name": true,
            "Description": false,
            "Period Type": true,
            "Revisions": true,
            "Status": true,
            "Creation date": true,
        }
    }
}

export type {ProjectReportListState}
export {defaultProjectReportListState}