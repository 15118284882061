import {call, put, takeEvery} from 'redux-saga/effects'

import {apiClient, ApiResponse, ErrorReason} from "../../client/app/client";
import {AppActionTypes, projectReportListRequestSucceeded, reportFileListRequestSucceeded} from "../action/AppAction";
import {ReportFile, ReportListItem} from "../../client/app/entity/Report";
import {notificationEmit} from "../../../qdep/notification/reducer";
import {authTokenExpired} from "../../../workspace/auth/login-block/data";
import {push} from "connected-react-router";


function* reportListRequestSaga() {
    let response: ApiResponse<ReportListItem[]> = yield call(apiClient.projectReportList)
    if (!response.errorReason && response.data) {
        yield put(projectReportListRequestSucceeded(response.data))
    } else {
        if (response.errorReason === ErrorReason.TOKEN_EXPIRED) {
            yield put(authTokenExpired());
            yield put(push("/login"));
        } else {
            yield put(notificationEmit(
                "project-report-list-request-error",
                "error",
                "Something went wrong. Try again later or contact your administrator"
            ))
        }
    }
}

function* reportFileListRequestSaga() {
    let response: ApiResponse<ReportFile[]> = yield call(apiClient.reportFileList)
    if (!response.errorReason && response.data) {
        yield put(reportFileListRequestSucceeded(response.data))
    } else {
        if (response.errorReason === ErrorReason.TOKEN_EXPIRED) {
            yield put(authTokenExpired());
            yield put(push("/login"));
        } else {
            yield put(notificationEmit(
                "report-file-list-request-error",
                "error",
                "Something went wrong. Try again later or contact your administrator"
            ))
        }
    }
}

export function * watchReportApiSaga() {
    yield takeEvery(AppActionTypes.PROJECT_REPORT_LIST_REQUEST, reportListRequestSaga)
    yield takeEvery(AppActionTypes.REPORT_FILE_LIST_REQUEST, reportFileListRequestSaga)
}