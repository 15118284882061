import styles from './ReportMenuItemList.module.css'
import React, {useMemo} from "react";
import {Link} from "react-router-dom";
import {BaseMenuItemState} from "./state";
import {Location} from "history";
import {PageNavigationProps} from "../report-page/navigation/page_navigation";


interface ReportMenuItemListProps {
    children: React.ReactNode | React.ReactNode[]
}

const ReportMenuItemList = (props: ReportMenuItemListProps) => {
    return <ul className={styles.menu_item_list}>
        { props.children }
    </ul>
}

interface ReportMenuItemProps {
    children: React.ReactNode
}

const ReportMenuItem = (props: ReportMenuItemProps) => {
    return <li className={styles.menu_item}>
        { props.children }
    </li>
}

interface ReportMenuItemLinkProps {
    index: number
    item: BaseMenuItemState
    children: React.ReactNode | React.ReactNode[]
    itemClass?: string
}

const menuItemClass = styles.menu_item_content
const menuItemSelectedClass = [styles.menu_item_content, styles.selected].join(" ")

const ReportMenuItemLink = (props: ReportMenuItemLinkProps) => {
    const itemClasses = useMemo(() => {
        let result = props.item.selected ? menuItemSelectedClass: menuItemClass
        if (props.itemClass !== undefined) {
            result += " " + props.itemClass
        }
        return result;
    }, [props.item.selected, props.itemClass]);

    return <li className={styles.menu_item}>
        <Link
            to={(location: Location<PageNavigationProps>) => ({
                ...location,
                search: `?page=${props.item.page}`,
                state: {page: props.item.page, menuIndex: props.index, prevMenuIndex: location.state?.menuIndex}
            })}
        >
            <div className={itemClasses}>
                { props.children }
            </div>
        </Link>
    </li>
}

export {ReportMenuItemList, ReportMenuItem, ReportMenuItemLink}
