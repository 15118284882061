import {EntityDefinition} from "./EntityDefinition";
import {FeatureFlagDefinition} from "./FeatureDefinition";
import {OptionalField} from "../../../../../../qdep/data/util";
import {EntityModelRevisionRef} from "../model/ReportModel";

interface EntitySettings {
    entityModelRef: OptionalField<EntityModelRevisionRef>
    entityDefinitions: EntityDefinition[]
    featureFlagDefinitions: FeatureFlagDefinition[]
}

const defaultEntitySettings: EntitySettings = {
    entityModelRef: undefined,
    entityDefinitions: [],
    featureFlagDefinitions: [],
}

export {defaultEntitySettings}
export type  {EntitySettings}
