enum SourceRefDetectionSelectorType {
    COLUMN = "COLUMN",
    ROW_HEADER = "ROW_HEADER",
}

enum DestRefDetectionSelectorType {
    TITLE_REGEXP = "TITLE_REGEXP",
    DATA_GROUP_REGEXP = "DATA_GROUP_REGEXP",
}

interface SourceRefDetectionSelector {
    type: SourceRefDetectionSelectorType
    value: string
}

interface DestRefDetectionSelector {
    type: DestRefDetectionSelectorType
    value: string
}

interface RowRefDetectionSettings {
    enabled: boolean
    hideRefColumn: boolean
    selectors: SourceRefDetectionSelector[]
    destSelectors: DestRefDetectionSelector[]
}

export type {RowRefDetectionSettings, SourceRefDetectionSelector, DestRefDetectionSelector}
export {SourceRefDetectionSelectorType, DestRefDetectionSelectorType}
