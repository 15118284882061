import React, {useState} from "react";

import {Box, Button} from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";


interface FileUploaderProps {
    onChange: (file: File) => void
}

const FileUploader = (props: FileUploaderProps) => {
    const [selectedFilename, setSelectedFilename] = useState("")

    return <label
        htmlFor="file-upload"
        style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "8px",
            alignItems: "center",
        }}
    >
        <input
            id="file-upload"
            name="file-upload"
            style={{ display: 'none' }}
            type="file"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const files = e.target.files;
                if (files && files.length === 1) {
                    const file = files.item(0);
                    if (file) {
                        setSelectedFilename(file.name)
                        props.onChange(file)
                    }
                }
            }}
        />
        <Button variant="outlined" startIcon={<UploadIcon/>} component="span">
            Choose File
        </Button>
        <Box component="span">
            { selectedFilename }
        </Box>
    </label>
}

export {FileUploader}