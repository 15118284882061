import React, {useEffect, useReducer} from "react";
import {useAsyncFn} from "react-use";
import {SnackbarKey, SnackbarMessage, useSnackbar, VariantType} from "notistack";

import {Button} from "@mui/material";
import {LoadingButton} from "@mui/lab";

import {setEntity} from "../../../../entity/entity-editor/action";
import {entityDefinitionEditorReducer} from "../../../../entity/entity-editor/reducer";
import {
    defaultEntityDefinitionEditorState,
    mapStateToEntityDefinition,
    SelectorWarnings
} from "../../../../entity/entity-editor/state";
import {EntityDefinition, Feature,} from "../../../../../../app/client/app/entity/report/settings/EntityDefinition";
import {PRRKey} from "../../../../../../app/client/app/entity/Project";
import {ApiResponse} from "../../../../../../app/client/types";
import {
    ReportStatusResultWithEntity,
    StageStatuses
} from "../../../../../../app/client/app/entity/ops/ReportStatusResult";
import {apiClient} from "../../../../../../app/client/app/client";
import {EditorActionsContainer} from "../../../../../../qdep/components/app/editor/EditorActionsContainer";
import {EntityDefinitionEditorBody} from "../../../../entity/entity-editor/editor_body";
import {EntityOption} from "../../../../entity/entity-editor/sub-items-block/EntitySubItemEditor";


interface EntityDefinitionEditorProps {
    prrKey: PRRKey
    isNew: boolean

    entityVariants: EntityOption[]
    featureOptions: Feature[]
    entityDefinition: EntityDefinition
    relativeEntities: Map<string, EntityDefinition>
    selectorWarnings: SelectorWarnings[]

    apply: (value: EntityDefinition) => void
    updateReportStatuses: (stages: StageStatuses) => void
}

const EntityDefinitionEditor = (props: EntityDefinitionEditorProps) => {
    const [state, dispatch] = useReducer(entityDefinitionEditorReducer, defaultEntityDefinitionEditorState);

    useEffect(() => {
        dispatch(setEntity(props.entityDefinition, props.relativeEntities, props.selectorWarnings))
    }, [props.entityDefinition, props.selectorWarnings, props.relativeEntities])

    const { enqueueSnackbar } = useSnackbar();
    const [applyEntityOp, applyEntity] = useAsyncFn(async (prrKey: PRRKey, entity: EntityDefinition): Promise<ApiResponse<ReportStatusResultWithEntity<EntityDefinition>>> => {
        if (entity.id === null) {
            return await apiClient.createReportEntityDefinition(prrKey, entity);
        } else {
            return await apiClient.updateReportEntityDefinition(prrKey, entity);
        }
    }, []);

    return <>
        <EntityDefinitionEditorBody
            isNew={props.isNew}
            entityVariants={props.entityVariants}
            featureOptions={props.featureOptions}
            state={state}
            dispatch={dispatch}
        />

        <EditorActionsContainer>
            <Button
                variant="outlined"
                onClick={() => dispatch(setEntity(props.entityDefinition, null))}
            >
                Cancel
            </Button>
            <LoadingButton
                loading={applyEntityOp.loading}
                variant="contained"
                color="secondary"
                onClick={() => {
                    const entity = mapStateToEntityDefinition(state.entity);
                    applyEntity(props.prrKey, entity)
                        .then(response => {
                            const notificationKey: SnackbarKey = "update-entity-request"
                            let notificationVariant: VariantType = "error"
                            let notificationMessage: SnackbarMessage = "Something went wrong. Try again later or contact your administrator"

                            if (!response.errorReason && response.data && response.data.status === "ok") {
                                notificationVariant = "success"
                                notificationMessage = "Entity Settings successfully updated. Refresh the page"

                                if (response.data.entity !== undefined) {
                                    props.apply(response.data.entity)
                                }
                                if (response.data.stages !== undefined) {
                                    props.updateReportStatuses(response.data.stages)
                                }
                            }

                            enqueueSnackbar(notificationMessage, {key: notificationKey, variant: notificationVariant});
                        })
                }}
            >
                Apply
            </LoadingButton>
        </EditorActionsContainer>
    </>
}

export {EntityDefinitionEditor}
