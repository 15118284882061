import React, {CSSProperties} from "react";
import {WritableDraft} from "immer/dist/types/types-external";

import {
    Rp5CellRef,
    Rp5DataGroup,
    Rp5RowFormulaTrigger,
    Rp5RowTransform,
    Rp5Table, Rp5TableRule
} from "../../../../../app/client/app/entity/Rp5Report";
import {ReportFeatures} from "../../base/report-workspace-header/data/ReportFeatures";
import {extractCellTags} from "../../base/util/CellTags";

import commonTableStyles from "../../base/report-table/ReportTableWorkspaceBlockBody.module.css";
import rp5TableStyles from "./table.module.css";
import {parsePRRKey} from "../../../../../app/client/app/entity/Project";
import reportTableStyles from "../../base/report-table/ReportTableWorkspaceBlockBody.module.css";


interface IntermediateTable {
    entityId: string

    rows: Row[]
    dgs: DataGroupIndex
    masks: CellMaskIndex
}

interface Row {
    cells: Cell[]

    transforms: Rp5RowTransform[] | null
    trigger: Rp5RowFormulaTrigger | null

    className: string | undefined
}

interface DataGroupKey {
    id: string
    displayName: string
    selected: boolean
    editedManually: boolean
    detached: boolean
}

interface CellClassMask {
    header: boolean
    data_unknown: boolean
    data_numeric: boolean
    indent: number,

    dg_border_top_refs: number
    dg_border_left_refs: number
    dg_border_right_refs: number
    dg_border_bottom_refs: number
    dg_border_top: boolean
    dg_border_left: boolean
    dg_border_right: boolean
    dg_border_bottom: boolean

    rule_ref: boolean
    validation_rule_ref: boolean
    rule_result_failed: boolean
    rule_result_success: boolean
}

function newCellClassMask(): CellClassMask {
    return {
        header: false,
        data_numeric: false,
        data_unknown: false,
        indent: 0,

        dg_border_top_refs: 0,
        dg_border_left_refs: 0,
        dg_border_right_refs: 0,
        dg_border_bottom_refs: 0,
        dg_border_top: false,
        dg_border_left: false,
        dg_border_right: false,
        dg_border_bottom: false,

        rule_ref: false,
        validation_rule_ref: false,
        rule_result_success: false,
        rule_result_failed: false
    }
}

interface Cell {
    row: number
    column: number
    colSpan: number

    className: string | undefined
    styles: CSSProperties | undefined
    isDataCell: boolean
    isSubHeader: boolean

    dgKeys?: DataGroupKey[]
    value: React.ReactNode
    inspections: Rp5TableRule[]
}

interface DataGroup {
    id: string
    name: string
    title: string
    orientation: string
    detached: boolean

    addedManually: boolean
    editedManually: boolean

    startIndex: number
    endIndex: number

    selected: boolean
}

type DataGroupIndex = Map<string, DataGroup>
type CellRef = [number, number]

class CellMaskIndex {
    private readonly index: Map<string, CellClassMask | undefined> = new Map()

    set(key: CellRef, value: CellClassMask | undefined) {
        this.index.set(key.join(","), value)
    }

    modify(key: CellRef, modificationCallback: (mask: CellClassMask) => CellClassMask): void {
        const serializedKey = key.join(",")
        const mask = this.index.get(serializedKey) || newCellClassMask()
        this.index.set(serializedKey, modificationCallback(mask))
    }

    eval(key: CellRef): [string | undefined, CSSProperties | undefined] {
        const mask = this.index.get(key.join(","))
        if (mask === undefined) {
            return [undefined, undefined]
        }

        const classes: string[] = [];
        let properties: CSSProperties = {}

        if (mask.header) {
            classes.push(commonTableStyles.header)
        }
        if (mask.data_numeric) {
            classes.push(commonTableStyles.data_numeric)
        }
        if (mask.indent > 0) {
            switch (mask.indent) {
                case 1:
                    classes.push(reportTableStyles.indent_1)
                    break
                case 2:
                    classes.push(reportTableStyles.indent_2)
                    break
                case 3:
                    classes.push(reportTableStyles.indent_3)
                    break
                default:
                    classes.push(reportTableStyles.indent_4)
            }
        }

        // region dg_border
        const borderColor = "rgba(2,123,243,0.30)";
        const selectedBorderColor = "rgba(236,114,17,0.80)";
        let boxShadowItems: string[] = []

        if (mask.dg_border_top) {
            boxShadowItems.push("inset 0 2px " + (mask.dg_border_top_refs > 0 ? selectedBorderColor: borderColor))
        }
        if (mask.dg_border_bottom) {
            boxShadowItems.push("inset 0 -2px " + (mask.dg_border_bottom_refs > 0 ? selectedBorderColor: borderColor))
        }
        if (mask.dg_border_left) {
            boxShadowItems.push("inset 2px 0 " + (mask.dg_border_left_refs > 0 ? selectedBorderColor: borderColor))
        }
        if (mask.dg_border_right) {
            boxShadowItems.push("inset -2px 0 " + (mask.dg_border_right_refs > 0 ? selectedBorderColor: borderColor))
        }

        if (boxShadowItems.length > 0) {
            properties.boxShadow = boxShadowItems.join(", ")
        }
        // endregion

        if (mask.validation_rule_ref) {
            classes.push(rp5TableStyles.validation_rule_ref);
        } else if (mask.rule_ref) {
            classes.push(rp5TableStyles.rule_ref)
        } else if (mask.rule_result_failed) {
            classes.push(rp5TableStyles.failed_rule_result);
        } else if (mask.rule_result_success) {
            classes.push(rp5TableStyles.passed_rule_result);
        }


        return [
            classes.length === 0 ? undefined: classes.join(" "),
            properties,
        ];
    }
}

function mapRp5Table(data: Rp5Table, reportFeatures: ReportFeatures): IntermediateTable {
    const prrKey = parsePRRKey(data.prrKey);
    const table: IntermediateTable = {
        entityId: data.entityId,
        rows: [],
        dgs: new Map(),
        masks: new CellMaskIndex(),
    }

    const cellMaskIndex = table.masks
    table.rows = data.rows.map((row, rowIndex) => {
        let rowTransforms: Rp5RowTransform[] | null = row.transforms || null
        let rowClassName: string | undefined = undefined
        if (rowTransforms !== null) {
            rowTransforms = rowTransforms.filter(transform => transform.type !== "DETACH")
            if (rowTransforms.length !== row.transforms?.length) {
                rowClassName = rp5TableStyles.detached_row
            }
        }

        const intermediateRow: Row = {
            cells: row.cells.map((rp5Cell, columnIndex) => {
                let indent = rp5Cell.indent || 0
                let hasListItem = false
                if (rp5Cell.tags != null && rp5Cell.tags.length > 0) {
                    const listItemTag = rp5Cell.tags.find(tag => tag.type === "LIST_ITEM");
                    hasListItem = listItemTag !== undefined
                }

                cellMaskIndex.set([rowIndex, columnIndex], {
                    header: rp5Cell.cellType === "HEADER",
                    data_numeric: rp5Cell.cellType === "DATA" && rp5Cell.dataType === "NUMERIC",
                    data_unknown: false,
                    indent: indent,

                    dg_border_top_refs: 0,
                    dg_border_left_refs: 0,
                    dg_border_right_refs: 0,
                    dg_border_bottom_refs: 0,
                    dg_border_bottom: false,
                    dg_border_left: false,
                    dg_border_right: false,
                    dg_border_top: false,

                    rule_ref: false,
                    validation_rule_ref: false,
                    rule_result_success: false,
                    rule_result_failed: false
                })

                return {
                    row: rp5Cell.row,
                    column: rp5Cell.column,
                    colSpan: rp5Cell.colSpan,

                    className: undefined,
                    styles: undefined,
                    isDataCell: rp5Cell.cellType === "DATA",
                    isSubHeader: row.cells.length === 1 && rp5Cell.cellType === "HEADER" && !hasListItem,

                    dgKeys: undefined,
                    value: reportFeatures.showCellValueTags ? extractCellTags(rp5Cell, prrKey): rp5Cell.value,

                    inspections: [],
                } as Cell;
            }),

            transforms: rowTransforms,
            trigger: row.trigger || null,
            className: rowClassName,
        };
        return intermediateRow
    })

    const dgs: DataGroupIndex = table.dgs
    if (reportFeatures.rowDataGroup) addDataGroups(dgs, data.dataGroup, reportFeatures)
    if (reportFeatures.columnDataGroup) addDataGroups(dgs, data.columnDataGroup, reportFeatures)
    for (let dg of dgs.values()) {
        updateDataGroupView(table, dg, "SET")
    }

    // add detach_row class for rows inside detached groups
    for (let dg of dgs.values()) {
        if (dg.detached) {
            for (let i = dg.startIndex; i < dg.endIndex; i++) {
                if (!(data.rows[i].cells.length === 1 && data.rows[i].cells[0].cellType === "HEADER")) {
                    table.rows[i].className = rp5TableStyles.detached_row;
                }
            }
        }
    }

    if (data.rules) {
        data.rules.forEach(rule => {
            const ruleTargetCell = table.rows[rule.row - 1].cells[rule.rowColumn - 1];
            ruleTargetCell.inspections.push(rule)

            cellMaskIndex.modify([rule.row - 1, rule.rowColumn - 1], mask => {
                if (rule.isPassed) {
                    mask.rule_result_success = true
                } else {
                    mask.rule_result_failed = true
                }
                return mask
            })
        })
    }

    for (let rowIndex = 0; rowIndex < table.rows.length; rowIndex++) {
        for (let columnIndex = 0; columnIndex < table.rows[rowIndex].cells.length; columnIndex++) {
            const [className, styles] = cellMaskIndex.eval([rowIndex, columnIndex]);
            const cell = table.rows[rowIndex].cells[columnIndex]
            cell.className = className
            cell.styles = styles
        }
    }

    return table;
}

function addDataGroups(index: DataGroupIndex, dg: Rp5DataGroup, reportFeatures: ReportFeatures) {
    if (!!dg.children) {
        for (let child of dg.children) {
            index.set(child.id, {
                id: child.id,
                name: dgFullName(child, reportFeatures),
                title: child.title,
                orientation: child.orientation,
                detached: child.detached,
                selected: false,

                startIndex: child.startIndex,
                endIndex: child.endIndex,

                addedManually: child.addedManually,
                editedManually: child.editedManually,
            })
            addDataGroups(index, child, reportFeatures)
        }
    }
}

function getCorrectedDataRowColumn(row: Cell[], targetColumn: number): number {
    let column = -1
    let offset = 0
    for (let cell of row) {
        column++
        offset += cell.colSpan
        if (targetColumn < offset) {
            break
        }
    }

    if (targetColumn >= offset) {
        return column + 1
    }

    return column;
}

function updateDataGroupView(table: WritableDraft<IntermediateTable>, dg: DataGroup, action: "SET" | "SELECT" | "UNSELECT"): Set<CellRef> {
    const changedCells: Set<CellRef> = new Set()

    if (dg.orientation === "ROW") {
        const dgRowStart = dg.startIndex;
        const dgRowEnd = dg.endIndex;

        if (action === "SET") {
            const cell = table.rows[dgRowStart].cells[0]
            if (cell.dgKeys === undefined) {
                cell.dgKeys = []
            }
            cell.dgKeys.push({
                id: dg.id,
                displayName: dg.name,
                selected: false,
                editedManually: dg.editedManually || dg.addedManually,
                detached: dg.detached,
            })
        } else if (action === "SELECT" || action === "UNSELECT") {
            const dgColumnStart = getCorrectedDataRowColumn(table.rows[dgRowStart].cells, 0);
            const dataGroupKeys = table.rows[dgRowStart].cells[dgColumnStart].dgKeys;
            if (dataGroupKeys) {
                const dataGroupKey = dataGroupKeys.find(dgKey => dgKey.id === dg.id);
                if (dataGroupKey) {
                    dataGroupKey.selected = action === "SELECT"
                }
            }
        }

        // border_top
        for (let columnIndex = 0; columnIndex < table.rows[dgRowStart].cells.length; columnIndex++) {
            const key: CellRef = [dgRowStart, columnIndex];
            changedCells.add(key)
            table.masks.modify(key, mask => {
                switch (action) {
                    case "SET": mask.dg_border_top = true; break;
                    case "SELECT": mask.dg_border_top_refs += 1; break;
                    case "UNSELECT": mask.dg_border_top_refs -= 1; break;
                }
                return mask
            })
        }

        // border_left/right
        const dgEndIndex = Math.min(table.rows.length, dgRowEnd);
        for (let rowIndex = dgRowStart; rowIndex < dgEndIndex; rowIndex++) {
            const row = table.rows[rowIndex].cells;
            if (row.length > 0) {
                const leftKey: CellRef = [rowIndex, 0]
                changedCells.add(leftKey)
                table.masks.modify(leftKey, mask => {
                    switch (action) {
                        case "SET": mask.dg_border_left = true; break;
                        case "SELECT": mask.dg_border_left_refs += 1; break;
                        case "UNSELECT": mask.dg_border_left_refs -= 1; break;
                    }
                    return mask
                })

                const rightKey: CellRef = [rowIndex, row.length - 1]
                changedCells.add(rightKey)
                table.masks.modify(rightKey, mask => {
                    switch (action) {
                        case "SET": mask.dg_border_right = true; break;
                        case "SELECT": mask.dg_border_right_refs += 1; break;
                        case "UNSELECT": mask.dg_border_right_refs -= 1; break;
                    }
                    return mask
                })
            }
        }

        // border_bottom
        for (let columnIndex = 0; columnIndex < table.rows[dgEndIndex-1].cells.length; columnIndex++) {
            const key: CellRef = [dgEndIndex-1, columnIndex];
            changedCells.add(key)
            table.masks.modify(key, mask => {
                switch (action) {
                    case "SET": mask.dg_border_bottom = true; break;
                    case "SELECT": mask.dg_border_bottom_refs += 1; break;
                    case "UNSELECT": mask.dg_border_bottom_refs -= 1; break;
                }
                return mask
            })
        }
    } else if (dg.orientation === "COLUMN") {
        const dgTopStartColumn = getCorrectedDataRowColumn(table.rows[0].cells, dg.startIndex);
        const dgTopEndColumn = getCorrectedDataRowColumn(table.rows[0].cells, dg.endIndex);

        if (action === "SET") {
            const cell = table.rows[0].cells[dgTopStartColumn]
            if (cell.dgKeys === undefined) {
                cell.dgKeys = []
            }
            cell.dgKeys.push({
                id: dg.id,
                displayName: dg.name,
                selected: false,
                editedManually: dg.editedManually || dg.addedManually,
                detached: dg.detached,
            })
        } else if (action === "SELECT" || action === "UNSELECT") {
            const dataGroupKeys = table.rows[0].cells[dgTopStartColumn].dgKeys;
            if (dataGroupKeys) {
                const dataGroupKey = dataGroupKeys.find(dgKey => dgKey.id === dg.id);
                if (dataGroupKey) {
                    dataGroupKey.selected = action === "SELECT"
                }
            }
        }

        // border_top
        for (let columnIndex = dgTopStartColumn; columnIndex < dgTopEndColumn; columnIndex++) {
            const key: CellRef = [0, columnIndex];
            changedCells.add(key)
            table.masks.modify(key, mask => {
                switch (action) {
                    case "SET": mask.dg_border_top = true; break;
                    case "SELECT": mask.dg_border_top_refs += 1; break;
                    case "UNSELECT": mask.dg_border_top_refs -= 1; break;
                }
                return mask
            })
        }

        // border_left/right
        for (let rowIndex = 0; rowIndex < table.rows.length; rowIndex++) {
            let offset = 0
            for (let columnIndex = 0; columnIndex < table.rows[rowIndex].cells.length; columnIndex++) {
                if (offset === dg.startIndex) {
                    const leftCell: CellRef = [rowIndex, columnIndex];
                    changedCells.add(leftCell)
                    table.masks.modify(leftCell, mask => {
                        switch (action) {
                            case "SET": mask.dg_border_left = true; break;
                            case "SELECT": mask.dg_border_left_refs += 1; break;
                            case "UNSELECT": mask.dg_border_left_refs -= 1; break;
                        }
                        return mask
                    })
                }
                offset += table.rows[rowIndex].cells[columnIndex].colSpan
                if (offset === dg.endIndex) {
                    const rightCell: CellRef = [rowIndex, columnIndex];
                    changedCells.add(rightCell)
                    table.masks.modify(rightCell, mask => {
                        switch (action) {
                            case "SET": mask.dg_border_right = true; break;
                            case "SELECT": mask.dg_border_right_refs += 1; break;
                            case "UNSELECT": mask.dg_border_right_refs -= 1; break;
                        }
                        return mask
                    })
                }
            }
        }

        // border_bottom
        const lastRowIndex = table.rows.length - 1;
        const bottomRow = table.rows[lastRowIndex].cells;
        const dgBottomStartColumn = getCorrectedDataRowColumn(bottomRow, dg.startIndex);
        const dgBottomEndColumn = getCorrectedDataRowColumn(bottomRow, dg.endIndex);
        for (let columnIndex = dgBottomStartColumn; columnIndex < dgBottomEndColumn; columnIndex++) {
            const key: CellRef = [lastRowIndex, columnIndex];
            changedCells.add(key)
            table.masks.modify(key, mask => {
                switch (action) {
                    case "SET": mask.dg_border_bottom = true; break;
                    case "SELECT": mask.dg_border_bottom_refs += 1; break;
                    case "UNSELECT": mask.dg_border_bottom_refs -= 1; break;
                }
                return mask
            })
        }
    }

    return changedCells
}

function updateRuleRefs(table: WritableDraft<IntermediateTable>, refs: Rp5CellRef[], select: boolean) {
    for (let cellRef of refs) {
        const key: CellRef = [cellRef.row - 1, cellRef.rowColumn - 1];
        table.masks.modify(key, mask => {
            mask.rule_ref = select
            return mask
        })
        const [className, styles] = table.masks.eval(key);
        const cell = table.rows[key[0]].cells[key[1]]
        cell.className = className
        cell.styles = styles
    }
}

function updateValidationRuleRefs(table: WritableDraft<IntermediateTable>, refs: Rp5CellRef[], select: boolean) {
    for (let cellRef of refs) {
        const key: CellRef = [cellRef.row - 1, cellRef.rowColumn - 1];
        table.masks.modify(key, mask => {
            mask.validation_rule_ref = select
            return mask
        })
        const [className, styles] = table.masks.eval(key);
        const cell = table.rows[key[0]].cells[key[1]]
        cell.className = className
        cell.styles = styles
    }
}

function dgFullName(dg: Rp5DataGroup, reportFeatures: ReportFeatures): string {
    const details: string[] = []
    if (reportFeatures.rowDataGroupEntityNames) {
        if (!!dg.entityName) {
            details.push(dg.entityName)
        }
        if (!!dg.features) {
            for (let featureName in dg.features) {
                details.push(dg.features[featureName])
            }
        }
    }

    if (details.length > 0) {
        return `${dg.name} [${details.join(" / ")}]`
    }
    return dg.name;
}

export type {
    IntermediateTable,
    Row, Cell, CellRef, CellClassMask,
    DataGroupIndex, DataGroup, DataGroupKey,
}
export {
    CellMaskIndex,
    mapRp5Table,
    updateDataGroupView, updateRuleRefs, updateValidationRuleRefs,
    getCorrectedDataRowColumn,
}
