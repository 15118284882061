enum RowRuleSelectorType {
    REGEXP = "REGEXP",
    EQUAL = "EQUAL",
    STARTS_WITH = "STARTS_WITH",
    ENDS_WITH = "ENDS_WITH",
    CONTAINS = "CONTAINS",
}

interface RowRuleSelector {
    type: RowRuleSelectorType
    value: string
}

interface RowSimpleRuleSettings {
    enabled: boolean
    emptyRowHeaderTrigger: boolean
    selectors: RowRuleSelector[]
    exclusions: RowRuleSelector[]
}

interface RowDateRuleSettings {
    enabled: boolean
    selectors: RowRuleSelector[]
}


interface RowRuleSettings {
    simpleRule: RowSimpleRuleSettings
    dateRule: RowDateRuleSettings
}

interface RowRuleSelectorDto {
    regexp: string | undefined | null
    equal: string | undefined | null
    starts: string | undefined | null
    ends: string | undefined | null
    contains: string | undefined | null
}

interface RowSimpleRuleSettingsDto {
    enabled: boolean
    emptyRowHeaderTrigger: boolean
    selectors: RowRuleSelectorDto[]
    exclusions: RowRuleSelectorDto[]
}

interface RowDateRuleSettingsDto {
    enabled: boolean
    selectors: RowRuleSelectorDto[]
}

interface RowRuleSettingsDto {
    simpleRule: RowSimpleRuleSettingsDto
    dateRule: RowDateRuleSettingsDto
}

function mapRowRuleSettingsToDto(settings: RowRuleSettings): RowRuleSettingsDto {
    const mapRowRuleSelector = (selector: RowRuleSelector): RowRuleSelectorDto => {
        const result: RowRuleSelectorDto = {
            ends: undefined, equal: undefined, regexp: undefined, starts: undefined, contains: undefined
        }

        switch (selector.type) {
            case RowRuleSelectorType.REGEXP:
                result.regexp = selector.value
                break
            case RowRuleSelectorType.EQUAL:
                result.equal = selector.value
                break
            case RowRuleSelectorType.STARTS_WITH:
                result.starts = selector.value
                break
            case RowRuleSelectorType.ENDS_WITH:
                result.ends = selector.value
                break
            case RowRuleSelectorType.CONTAINS:
                result.contains = selector.value
                break
        }

        return result
    }

    return {
        simpleRule: {
            enabled: settings.simpleRule.enabled,
            emptyRowHeaderTrigger: settings.simpleRule.emptyRowHeaderTrigger,
            selectors: settings.simpleRule.selectors
                .map(mapRowRuleSelector),
            exclusions: settings.simpleRule.exclusions
                .map(mapRowRuleSelector),
        },
        dateRule: {
            enabled: settings.dateRule.enabled,
            selectors: settings.dateRule.selectors
                .map(mapRowRuleSelector),
        },
    }
}

function mapRowRuleSettingsDtoToValue(dto: RowRuleSettingsDto): RowRuleSettings {
    const mapRowRuleSelectorDto = (item: RowRuleSelectorDto): RowRuleSelector => {
        let data: RowRuleSelector = {
            type: RowRuleSelectorType.EQUAL,
            value: "",
        }
        if (item) {
            if (item.regexp) {
                data = {
                    type: RowRuleSelectorType.REGEXP,
                    value: item.regexp,
                }
            }
            if (item.equal) {
                data = {
                    type: RowRuleSelectorType.EQUAL,
                    value: item.equal,
                }
            }
            if (item.ends) {
                data = {
                    type: RowRuleSelectorType.ENDS_WITH,
                    value: item.ends,
                }
            }
            if (item.starts) {
                data = {
                    type: RowRuleSelectorType.STARTS_WITH,
                    value: item.starts,
                }
            }
            if (item.contains) {
                data = {
                    type: RowRuleSelectorType.CONTAINS,
                    value: item.contains,
                }
            }
        }

        return data
    }

    return {
        simpleRule: {
            enabled: dto.simpleRule.enabled,
            emptyRowHeaderTrigger: dto.simpleRule.emptyRowHeaderTrigger,
            selectors: dto.simpleRule.selectors.map(mapRowRuleSelectorDto),
            exclusions: dto.simpleRule.exclusions.map(mapRowRuleSelectorDto),
        },
        dateRule: {
            enabled: dto.dateRule.enabled,
            selectors: dto.dateRule.selectors.map(mapRowRuleSelectorDto),
        },
    }
}

export {
    RowRuleSelectorType,
    mapRowRuleSettingsToDto, mapRowRuleSettingsDtoToValue,
}
export type {
    RowRuleSettings, RowSimpleRuleSettings, RowDateRuleSettings, RowRuleSelector,
    RowRuleSettingsDto, RowSimpleRuleSettingsDto, RowDateRuleSettingsDto, RowRuleSelectorDto,
}