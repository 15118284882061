import {applyMiddleware, combineReducers, createStore} from "redux";
import createSagaMiddleware from 'redux-saga'
import {composeWithDevTools} from "redux-devtools-extension";
import { createBrowserHistory } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { persistStore, persistReducer } from 'redux-persist'
import storage from "redux-persist/es/storage";

import {projectListReducer} from "../../workspace/project/list/data/reducer";
import {watchProjectApiSaga} from "../data/saga/ProjectApiSaga";
import {reportFilesListReducer} from "../../workspace/report/files/data/reducer";
import {watchReportApiSaga} from "../data/saga/ReportApiSaga";
import {notificationReducer} from "../../qdep/notification/reducer";
import {authReducer, watchAuthModuleSaga} from "../../workspace/auth/login-block/data";
import {projectReportListReducer} from "../../workspace/report/list/data/reducer";


export const history = createBrowserHistory()

const reducers = combineReducers({
    router: connectRouter(history),
    auth: persistReducer({key: 'auth:v1', storage: storage, blacklist: ["isWaiting"]}, authReducer),
    notification: notificationReducer,

    projects: projectListReducer,
    report: combineReducers({
        list: projectReportListReducer,
        files: reportFilesListReducer,
    }),
})

const sagaMiddleware = createSagaMiddleware()

const store = createStore(reducers, composeWithDevTools(applyMiddleware(
    routerMiddleware(history), sagaMiddleware
)))
const persistor = persistStore(store)

sagaMiddleware.run(watchProjectApiSaga)
sagaMiddleware.run(watchReportApiSaga)
sagaMiddleware.run(watchAuthModuleSaga)

export {store, persistor}