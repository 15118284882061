import React from "react";

import {Skeleton} from "@mui/material";

import {
    WorkspaceBlock,
    WorkspaceBlockBody,
    WorkspaceBlockHeader
} from "../../../../../qdep/components/app/workspace/Workspace";


interface ComponentProps {
    title: string
}

const OverviewSkeleton = (props: ComponentProps) => {
    return <WorkspaceBlock>
        <WorkspaceBlockHeader>
            { props.title }
        </WorkspaceBlockHeader>
        <WorkspaceBlockBody>
            <Skeleton variant={"rectangular"} height={"200px"}/>
        </WorkspaceBlockBody>
    </WorkspaceBlock>
}

export {OverviewSkeleton}