import React, {useState} from "react";
import {useDispatch} from "react-redux";

import {TextField} from "@mui/material";
import {LoadingButton} from "@mui/lab";

import {loginRequest} from "./data";
import {AuthRequestData} from "../../../app/client/auth/client";
import {WorkspaceBlock, WorkspaceBlockBody} from "../../../qdep/components/app/workspace/Workspace";


interface LoginWorkspaceBlockProps {
    isWaiting: boolean
}

const LoginWorkspaceBlock = (props : LoginWorkspaceBlockProps) => {
    const [state, setState] = useState<AuthRequestData>({username: "", password: "", project: ""});
    const dispatch = useDispatch();

    return <WorkspaceBlock classes={"login-page-workspace-block"}>
        <div className={"workspace-block-header"}>
            Log In to Your Account
        </div>
        <WorkspaceBlockBody classes={"login-page-form-container"}>
            <div className={"filed-container small fill"}>
                <label>Username</label>
                <TextField
                    className={"field-control"}
                    size={"small"}
                    disabled={props.isWaiting}
                    value={state.username}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setState(prev => ({...prev, username: event.target.value}))
                    }}
                />
            </div>
            <div className={"filed-container small fill"}>
                <label>Password</label>
                <TextField
                    className={"field-control"}
                    size={"small"}
                    type={"password"}
                    disabled={props.isWaiting}
                    value={state.password}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setState(prev => ({...prev, password: event.target.value}))
                    }}
                />
            </div>

            <div className={"login-page-action-container"}>
                <LoadingButton
                    variant="contained"
                    color="secondary"
                    loading={props.isWaiting}
                    onClick={() => {
                        dispatch(loginRequest(state.username, state.password))
                    }}
                >
                    Sign in
                </LoadingButton>
            </div>
        </WorkspaceBlockBody>
    </WorkspaceBlock>
}

export {LoginWorkspaceBlock}