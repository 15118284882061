import React from "react";
import _ from "lodash";

import {Autocomplete, Breadcrumbs, Switch, TextField, Typography} from "@mui/material";

import {FieldContainer} from "../../../../../qdep/components/app/workspace/Workspace";
import {EntityDependencyNode} from "../state";
import {
    EntityCategory,
    entityCategoryToString, Feature,
    FeatureSet
} from "../../../../../app/client/app/entity/report/settings/EntityDefinition";
import {FeatureSetControl} from "../components/FeatureSetControl";


interface EntityOption {
    entityName: string
    category: EntityCategory
    builtInFeatures: FeatureSet | null
}

interface EntitySubItemEditorProps {
    entityVariants: EntityOption[]
    featureOptions: Feature[]
    entitySubItemPath: string[]
    entitySubItem: EntityDependencyNode
    onChange: (update: Partial<EntityDependencyNode>) => void
}

const EntitySubItemEditor = (props: EntitySubItemEditorProps) => {
    return <>
        <Breadcrumbs sx={{marginBottom: "24px", marginTop: "8px"}}>
            { props.entitySubItemPath.map(
                (item, index) => <Typography key={`path-item-${index}`}>{item}</Typography>
            )}
        </Breadcrumbs>
        <FieldContainer title={"Name"}>
            <Autocomplete
                // freeSolo
                forcePopupIcon={true}
                className={"field-control fill"}
                size={"small"}
                value={props.entitySubItem.entity}
                onChange={(event, newValue) => {
                    props.onChange({entity: newValue});
                }}
                options={props.entityVariants}
                groupBy={(option: EntityOption) => entityCategoryToString(option.category)}
                isOptionEqualToValue={_.isEqual}
                getOptionLabel={(option: EntityOption) => {
                    let builtInFeatures = ""
                    if (option.builtInFeatures !== null) {
                        builtInFeatures = Object.values(option.builtInFeatures).join(", ")
                        if (builtInFeatures !== "") {
                            builtInFeatures = "[" + builtInFeatures + "]"
                        }
                    }
                    return `${option.entityName} ${builtInFeatures}`.trimEnd()
                }}
                renderInput={(params) => <TextField {...params}/>}
            />
        </FieldContainer>
        <FieldContainer title={"Required"}>
            <Switch
                color={"secondary"}
                checked={props.entitySubItem.required}
                onChange={(_, checked) => {
                    props.onChange({required: checked})
                }}
            />
        </FieldContainer>
        <FieldContainer title={"Inlineable"}>
            <Switch
                color={"secondary"}
                checked={props.entitySubItem.inlineable}
                onChange={(_, checked) => {
                    props.onChange({inlineable: checked})
                }}
            />
        </FieldContainer>
        <FeatureSetControl
            label={"Required Features"}
            autocomplete={{
                className:"fill",
                value: props.entitySubItem.requiredFeatures,
                fixedValues: props.entitySubItem.entity?.builtInFeatures,
                options: props.featureOptions,
                onChange: (value) => props.onChange({requiredFeatures: value}),
            }}
        />
    </>
}

export {EntitySubItemEditor}
export type {EntityOption}
