import React from "react";
import AddIcon from "@mui/icons-material/Add";
import {styled} from "@mui/material";
import ButtonBase, {ButtonBaseProps} from "@mui/material/ButtonBase";


const StyledButtonBase = styled((props: ButtonBaseProps) => (
    <ButtonBase {...props}/>
))({
    font: "inherit",
    padding: "8px 16px",
    color: "rgba(0, 0, 0, 0.54)",
    backgroundColor: "rgba(104, 112, 120, 0.14)",
    borderRadius: "10px",
    width: "100%",
    "&:hover": {
        backgroundColor: "rgba(104, 112, 120, 0.24)",
    },
    "&.selected": {
        color: "#ff8800",
        backgroundColor: "rgba(255, 136, 0, 0.20)",
    }
})

interface NewEntityDefinitionButtonProps {
    title: string
    active: boolean
    onClick: () => void
}

const NewDefinitionButton = (props: NewEntityDefinitionButtonProps) => {
    return <div style={{paddingRight: "18px"}}>
        <StyledButtonBase
            className={props.active ? "selected": undefined}
            onClick={props.onClick}
        >
            <AddIcon sx={{paddingRight: "8px"}}/>
            <span>{props.title}</span>
        </StyledButtonBase>
    </div>
}

export {NewDefinitionButton}
