import {StageStatuses} from "../../../../../../app/client/app/entity/ops/ReportStatusResult";

interface ReportBaseState {
    reportName: string | undefined
    reportStageStatuses: StageStatuses
}

const defaultReportBaseState: ReportBaseState = {
    reportName: undefined,
    reportStageStatuses: {
        ocr: null,
        normalization: null,
        entityDetection: null,
        tableAnalysis: null,
        tableRefs: null,
        summary: null,
    },
}

export {defaultReportBaseState}
export type {ReportBaseState}
