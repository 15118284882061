import {AnyAction} from "redux";
import produce from "immer";
import {WritableDraft} from "immer/dist/types/types-external";

import {ModificationRule} from "../../../../app/client/app/entity/report/ModificationRule";
import {ActionTypes} from "./action";
import {State} from "./state";


function reducer(state: State, action: AnyAction): State {
    return produce(state, draft => {
        switch (action.type) {
            case ActionTypes.SET_DATA:
                setDataReducer(draft, action)
                break
            case ActionTypes.SELECT_RULE:
                selectRuleReducer(draft, action)
                break
            case ActionTypes.SELECT_ALL_RULES:
                selectAllRulesReducer(draft, action)
                break
            case ActionTypes.DELETE_RULES:
                deleteRulesReducer(draft, action)
                break
        }
        return draft
    })
}

function setDataReducer(state: WritableDraft<State>, action: AnyAction) {
    const data: ModificationRule[] | undefined = action.data;
    if (data !== undefined) {
        state.items = data.map((item, index) => (
            {rowIndex: index, isSelected: false, data: item}
        ))
    } else {
        state.items = undefined
    }
    state.numSelected = 0
}

function selectRuleReducer(state: WritableDraft<State>, action: AnyAction) {
    const rowIndex: number | undefined = action.rowIndex;
    if (rowIndex !== undefined && state.items !== undefined && state.items.length > rowIndex) {
        const selected = !state.items[rowIndex].isSelected
        state.items[rowIndex].isSelected = selected
        state.numSelected += selected ? +1 : -1
    }
}

function selectAllRulesReducer(state: WritableDraft<State>, _: AnyAction) {
    if (state.items !== undefined) {
        let numRules = state.items.length;
        const selected = !(state.numSelected === numRules)

        if (selected) {
            state.numSelected = numRules
        } else {
            state.numSelected = 0
        }

        for (let rule of state.items.values()) {
            rule.isSelected = selected
        }
    }
}

function deleteRulesReducer(state: WritableDraft<State>, _: AnyAction) {
    if (state.items !== undefined) {
        for (let item of state.items) {
            if (item.isSelected) {
                item.isSelected = false
                item.data.result.status = "DELETE"
            }
        }
        state.numSelected = 0
    }
}

export {reducer}
