import {AnyAction} from "redux";
import {ProjectDto, ReportDto} from "../../../../app/client/app/entity/Project";
import {EntityModelRevisionRef} from "../../../../app/client/app/entity/report/model/ReportModel";

export enum ProjectEditorActionTypes {
    UPDATE_PROJECT = "UPDATE_PROJECT",

    ADD_REPORT = "ADD_REPORT",
    DELETE_REPORT = "DELETE_REPORT",
    UPDATE_REPORT = "UPDATE_REPORT",

    ADD_REVISION              = "ADD_REVISION",
    ADD_REVISION_ENTITY_MODEL = "ADD_REVISION_ENTITY_MODEL",
}

function addReport(): AnyAction {
    return {
        type: ProjectEditorActionTypes.ADD_REPORT,
    }
}

function deleteReport(index: number): AnyAction {
    return {
        type: ProjectEditorActionTypes.DELETE_REPORT,
        index: index,
    }
}

function updateReport(index:number, updates: Partial<ReportDto>): AnyAction {
    return {
        type: ProjectEditorActionTypes.UPDATE_REPORT,
        index: index,
        updates: updates,
    }
}

function updateProject(updates: Omit<Partial<ProjectDto>, 'reports'>): AnyAction {
    return {
        type: ProjectEditorActionTypes.UPDATE_PROJECT,
        updates: updates,
    }
}

function addRevision(reportIndex: number, file: File): AnyAction {
    return {
        type: ProjectEditorActionTypes.ADD_REVISION,
        reportIndex: reportIndex,
        file: file,
    }
}

function addRevisionEntityModel(reportIndex: number, model: EntityModelRevisionRef | null): AnyAction {
    return {
        type: ProjectEditorActionTypes.ADD_REVISION_ENTITY_MODEL,
        reportIndex: reportIndex,
        model: model
    }
}

export {
    addReport, deleteReport, updateReport,
    updateProject,
    addRevision, addRevisionEntityModel,
}
