import {useHistory} from "react-router-dom";

import {ListItemIcon, MenuItem} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';


const CreateButton = () => {
    const history = useHistory();
    return  <MenuItem
        onClick={() => {
            history.push("/projects/create")
        }}
    >
        <ListItemIcon>
            <AddIcon/>
        </ListItemIcon>
        Create Project
    </MenuItem>
}

export {CreateButton}