import React, {useEffect, useState} from "react";
import {useTitle} from "react-use";

import {WorkspaceBlock} from "../../../qdep/components/app/workspace/Workspace";
import {PRRKey} from "../../../app/client/app/entity/Project";
import {apiClient, ErrorReason} from "../../../app/client/app/client";
import {ModificationRulesPaneWorkspaceBody} from "./modificatio-rules/ModificationRulesPaneWorkspaceBody";
import {TabPanel} from "../../../qdep/components/app/util/tab-panel/TabPanel";
import {ReportOverviewWorkspaceState, WorkspaceItemTypes} from "./state";
import {AnalysisSettingsWorkspaceBlock} from "./analysis-settings/AnalysisSettingsWorkspaceBlock";
import {EntityModelWorkspaceBlock} from "./entity-model/EntityModelWorkspaceBlock";
import {AutoLoadedIssueList} from "../stages/base/issue-list/AutoLoadedIssueList";
import {TocWorkspaceBlock} from "./toc-block/TocWorkspaceBlock";
import {StagesWorkspaceBlock} from "./stages-block/StagesWorkspaceBlock";
import {ReportOverviewWorkspaceHeader} from "./header";
import {FilesWorkspaceBlock} from "./files-block/FilesWorkspaceBlock";
import {useAuthErrorBoundaryContext} from "../../../qdep/components/app/error/UnauthorizedErrorBoundary";

import './ReportOverviewWorkspace.css'


interface WorkspaceProps {
    prrKey: PRRKey
    renderItemType: WorkspaceItemTypes
}

const ReportOverviewWorkspaceBody = (props: WorkspaceProps) => {
    const prrKey: PRRKey = props.prrKey
    const [state, setState] = useState<ReportOverviewWorkspaceState | undefined>(undefined);
    const { submitError } = useAuthErrorBoundaryContext()

    useEffect(() => {
        setState(undefined)
        apiClient
            .getReportRevision(prrKey)
            .then(response => {
                if (!response.errorReason && response.data !== undefined) {
                    const entity = response.data;
                    let numModificationErrors: number = 0
                    numModificationErrors += entity.analysis.stages.ocr.failedCustomRules || 0
                    numModificationErrors += entity.analysis.stages.normalization.failedCustomRules || 0
                    numModificationErrors += entity.analysis.stages.entityDetection.failedCustomRules || 0
                    numModificationErrors += entity.analysis.stages.tableAnalysis.failedCustomRules || 0
                    numModificationErrors += entity.analysis.stages.tableRefs.failedCustomRules || 0

                    setState({
                        entity: entity,
                        numModificationErrors: numModificationErrors,
                    });
                } else {
                    const error = response.errorReason || ErrorReason.UNKNOWN_ERROR;
                    submitError(error)
                }
            });
    }, [prrKey, submitError])

    useTitle(state?.entity.reportName || "FINHOUND", {restoreOnUnmount: true})

    return <>
        <ReportOverviewWorkspaceHeader
            prrKey={prrKey}
            reportName={state?.entity.reportName}
            reportStatus={state?.entity.status}
            onStagesUpdate={stages =>
                setState(prev => {
                    if (prev !== undefined) {
                        return {
                            ...prev,
                            entity: {
                                ...prev.entity,
                                analysis: {
                                    stages: {
                                        ocr: {
                                            ...prev.entity.analysis.stages.ocr,
                                            status: stages.ocr || prev.entity.analysis.stages.ocr.status,
                                        },
                                        normalization: {
                                            ...prev.entity.analysis.stages.normalization,
                                            status: stages.normalization || prev.entity.analysis.stages.normalization.status,
                                        },
                                        entityDetection: {
                                            ...prev.entity.analysis.stages.entityDetection,
                                            status: stages.entityDetection || prev.entity.analysis.stages.entityDetection.status,
                                        },
                                        tableAnalysis: {
                                            ...prev.entity.analysis.stages.tableAnalysis,
                                            status: stages.tableAnalysis || prev.entity.analysis.stages.tableAnalysis.status,
                                        },
                                        tableRefs: {
                                            ...prev.entity.analysis.stages.tableRefs,
                                            status: stages.tableRefs || prev.entity.analysis.stages.tableRefs.status,
                                        },
                                        summary: {
                                            ...prev.entity.analysis.stages.summary,
                                            status: stages.summary || prev.entity.analysis.stages.summary.status,
                                        },
                                    }
                                }
                            }
                        }
                    }
                })
            }
        />

        <TabPanel index={WorkspaceItemTypes.STAGES} value={props.renderItemType}>
            <StagesWorkspaceBlock
                prrKey={prrKey}
                analysis={state?.entity.analysis}
            />
        </TabPanel>
        <TabPanel index={WorkspaceItemTypes.ISSUES} value={props.renderItemType}>
            <WorkspaceBlock>
                <AutoLoadedIssueList prrKey={prrKey}/>
            </WorkspaceBlock>
        </TabPanel>
        <TabPanel index={WorkspaceItemTypes.MODIFICATION} value={props.renderItemType}>
            <WorkspaceBlock>
                <ModificationRulesPaneWorkspaceBody prrKey={prrKey}/>
            </WorkspaceBlock>
        </TabPanel>
        <TabPanel index={WorkspaceItemTypes.ANALYSIS_SETTINGS} value={props.renderItemType}>
            <AnalysisSettingsWorkspaceBlock prrKey={prrKey}/>
        </TabPanel>
        <TabPanel index={WorkspaceItemTypes.ENTITY_MODEL} value={props.renderItemType}>
            <WorkspaceBlock>
                <EntityModelWorkspaceBlock prrKey={prrKey}/>
            </WorkspaceBlock>
        </TabPanel>
        <TabPanel index={WorkspaceItemTypes.TOC} value={props.renderItemType}>
            <TocWorkspaceBlock prrKey={prrKey}/>
        </TabPanel>
        <TabPanel index={WorkspaceItemTypes.FILES} value={props.renderItemType}>
            <FilesWorkspaceBlock prrKey={prrKey}/>
        </TabPanel>
    </>;
}

export {ReportOverviewWorkspaceBody}