import {AnyAction} from "redux";
import {ModificationRule} from "../../../../app/client/app/entity/report/ModificationRule";

const enum ActionTypes {
    SET_DATA = "SET_DATA",

    SELECT_RULE      = "SELECT_RULE",
    SELECT_ALL_RULES = "SELECT_ALL_RULES",

    DELETE_RULES = "DELETE_RULES",
}

function setData(data: ModificationRule[] | undefined): AnyAction {
    return {
        type: ActionTypes.SET_DATA,
        data: data,
    }
}

function selectRule(rowIndex: number): AnyAction {
    return {
        type: ActionTypes.SELECT_RULE,
        rowIndex: rowIndex,
    }
}

function selectAllRules(): AnyAction {
    return {
        type: ActionTypes.SELECT_ALL_RULES,
    }
}

function deleteRules(): AnyAction {
    return {type: ActionTypes.DELETE_RULES}
}

export {
    ActionTypes,
    setData, selectRule, selectAllRules, deleteRules,
}
