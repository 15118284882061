import {AnyAction} from "redux";
import produce from "immer";
import {WritableDraft} from "immer/dist/types/types-external";
import _ from "lodash";

import {ReportModel} from "../../../../../app/client/app/entity/report/model/ReportModel";
import {
    AnalysisSettings,
    DefaultReportAnalysisSettings
} from "../../../../../app/client/app/entity/report/settings/AnalysisSettings";
import {analysisSettingsEditorReducer} from "../../../analysis_settings/editor_reducer";


// region state
interface State {
    model: ReportModel
    currentAnalysisSettingsRevision: AnalysisSettingsRevisionState | undefined

    type: "UNDEFINED" | "ENTITY" | "ANALYSIS"
}

interface AnalysisSettingsRevisionState {
    revisionId: string
    settings: AnalysisSettings

    isNew?: boolean
    isChanged?: boolean
}

const defaultState: State = {
    model: {
        id: null,
        name: "",
        description: "",
        analysisSettingsRevisions: [],
        entityModelRevisions: [],
    },
    currentAnalysisSettingsRevision: undefined,
    type: "UNDEFINED"
}
// endregion

// region actions
const enum ActionTypes {
    SET_MODEL = "SET_MODEL",
    UPDATE_MODEL = "UPDATE_MODEL",

    NEW_ANALYSIS_SETTINGS_REVISION    = "NEW_ANALYSIS_SETTINGS_REVISION",
    SELECT_ANALYSIS_SETTINGS_REVISION = "SELECT_ANALYSIS_SETTINGS_REVISION",

    DELETE_ENTITY_MODEL_REVISION = "DELETE_ENTITY_MODEL_REVISION",
}

function setModel(model: ReportModel): AnyAction {
    return {type: ActionTypes.SET_MODEL, model: model}
}

type ModelUpdates = Partial<Pick<ReportModel, "name" | "description">>
function updateModel(update: ModelUpdates): AnyAction {
    return {type: ActionTypes.UPDATE_MODEL, update: update}
}

function newAnalysisSettingsRevision(): AnyAction {
    return {type: ActionTypes.NEW_ANALYSIS_SETTINGS_REVISION}
}

function selectAnalysisSettingsRevision(revisionId: string): AnyAction {
    return {type: ActionTypes.SELECT_ANALYSIS_SETTINGS_REVISION, revisionId: revisionId}
}

function deleteEntityModelRevision(revisionId: string): AnyAction {
    return {type: ActionTypes.DELETE_ENTITY_MODEL_REVISION, revisionId: revisionId}
}

// endregion

// region reducer
function editorReducer(state: State, action: AnyAction): State {
    return produce(state, draft => {
        if (action.type.startsWith("ANALYSIS_SETTINGS_EDITOR.")) {
            if (draft.currentAnalysisSettingsRevision !== undefined) {
                draft.currentAnalysisSettingsRevision.settings = analysisSettingsEditorReducer(
                    draft.currentAnalysisSettingsRevision.settings, action
                )
                if (!draft.currentAnalysisSettingsRevision.isNew) {
                    draft.currentAnalysisSettingsRevision.isChanged = true;
                }
            }
        }

        switch (action.type) {
            case ActionTypes.SET_MODEL:
                setModelReducer(draft, action);
                break;
            case ActionTypes.UPDATE_MODEL:
                updateModelReducer(draft, action);
                break;
            case ActionTypes.NEW_ANALYSIS_SETTINGS_REVISION:
                newAnalysisSettingsRevisionReducer(draft, action)
                break
            case ActionTypes.SELECT_ANALYSIS_SETTINGS_REVISION:
                selectAnalysisSettingsRevisionReducer(draft, action);
                break;
            case ActionTypes.DELETE_ENTITY_MODEL_REVISION:
                deleteEntityModelRevisionReducer(draft, action)
                break
        }
        return draft
    })
}

function setModelReducer(state: WritableDraft<State>, action: AnyAction) {
    const model: ReportModel | undefined = action.model;
    if (model === undefined) {
        return
    }

    state.model = model

    if (model.analysisSettingsRevisions.length > 0) {
        const latestRevisionIndex = model.analysisSettingsRevisions.length - 1
        const latestRevision = model.analysisSettingsRevisions[latestRevisionIndex]
        state.currentAnalysisSettingsRevision = {
            revisionId: latestRevision.revisionId,
            settings: latestRevision.settings,
        }
    } else {
        state.currentAnalysisSettingsRevision = undefined
    }

    if (model.analysisSettingsRevisions.length > 0 && model.entityModelRevisions.length === 0) {
        state.type = "ANALYSIS"
    } else if (model.analysisSettingsRevisions.length === 0 && model.entityModelRevisions.length > 0) {
        state.type = "ENTITY"
    } else if (model.analysisSettingsRevisions.length === 0 && model.entityModelRevisions.length === 0) {
        state.type = "UNDEFINED"
    }
}

function updateModelReducer(state: WritableDraft<State>, action: AnyAction) {
    const update: ModelUpdates | undefined = action.update;
    if (update === undefined) {
        return
    }

    _.merge(state.model, update)
}

function newAnalysisSettingsRevisionReducer(state: WritableDraft<State>, _: AnyAction) {
    state.currentAnalysisSettingsRevision = {
        revisionId: "",
        isNew: true,
        settings: DefaultReportAnalysisSettings,
    }
    state.type = "ANALYSIS"
}

function selectAnalysisSettingsRevisionReducer(state: WritableDraft<State>, action: AnyAction) {
    const revisionId: string | undefined = action.revisionId;
    if (revisionId === undefined) {
        return
    }

    if (state.currentAnalysisSettingsRevision?.revisionId === revisionId) {
        return
    }

    const revisionIndex = state.model.analysisSettingsRevisions.findIndex(revision => revision.revisionId === revisionId)
    if (revisionIndex >= 0) {
        state.currentAnalysisSettingsRevision = {
            revisionId: revisionId,
            settings: state.model.analysisSettingsRevisions[revisionIndex].settings,
        }
    }
}

function deleteEntityModelRevisionReducer(state: WritableDraft<State>, action: AnyAction) {
    const revisionId: string | undefined = action.revisionId;
    if (revisionId === undefined) {
        return
    }

    const revisionIndex = state.model.entityModelRevisions.findIndex(revision => revision.revisionId === revisionId);
    if (revisionIndex !== -1) {
        state.model.entityModelRevisions.splice(revisionIndex, 1)
        if (state.model.entityModelRevisions.length === 0) {
            state.type = "UNDEFINED"
        }
    }
}
// endregion

export {
    defaultState,
    editorReducer,
    setModel, updateModel,
    newAnalysisSettingsRevision, selectAnalysisSettingsRevision,
    deleteEntityModelRevision,
}
