import React, {useState} from "react";

import {Button, Dialog, DialogActions, DialogContent, DialogTitle, ListItemIcon, MenuItem} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import {FileUploader} from "../../../../app/ui/file-uploader/FileUploader";
import {FieldContainer} from "../../../../qdep/components/app/workspace/Workspace";
import {EntityModelRefAutocomplete} from "../../component/EntityModelRefAutocomplete";
import {EntityModelRevisionRef} from "../../../../app/client/app/entity/report/model/ReportModel";


interface AddRevisionButtonProps {
    onClick: () => void
}

const AddRevisionButton = (props: AddRevisionButtonProps) => {
    return <>
        <MenuItem
            onClick={() => props.onClick()}
        >
            <ListItemIcon>
                <AddIcon/>
            </ListItemIcon>
            Add Revision
        </MenuItem>
    </>
}

interface AddRevisionDialogProps {
    isOpen: boolean
    close: () => void
    apply: (file: File, model: EntityModelRevisionRef | null) => void
}

const AddRevisionDialog = (props: AddRevisionDialogProps) => {
    const [file, setFile] = useState<File | undefined>(undefined)
    const [model, setModel] = useState<EntityModelRevisionRef | null>(null)

    return <Dialog open={props.isOpen}>
        <DialogTitle>New Revision</DialogTitle>
        <DialogContent sx={{minWidth: "500px"}}>
            <FieldContainer title={"New File"}>
                <FileUploader onChange={setFile}/>
            </FieldContainer>
            <FieldContainer title={"Entity Model"}>
                <EntityModelRefAutocomplete className={"field-control fill"} onChange={setModel}/>
            </FieldContainer>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.close}>Cancel</Button>
            <Button
                variant="contained"
                color="secondary"
                disabled={file === undefined}
                onClick={() => {
                    if (file !== undefined) {
                        props.apply(file, model);
                    }
                }}
            >
                Apply
            </Button>
        </DialogActions>
    </Dialog>
}

export {AddRevisionButton, AddRevisionDialog}