import {CellMaskIndex, DataGroup, IntermediateTable} from "./IntermediateTable";
import {Rp5Table, Rp5TableRule} from "../../../../../app/client/app/entity/Rp5Report";
import {ReportFeatures} from "../../base/report-workspace-header/data/ReportFeatures";
import {SelectableItem} from "../../../../../qdep/data/util";

interface TableState {
    table: Rp5Table
    renderTable: IntermediateTable
    reportFeatures: ReportFeatures

    rulesPane: TableRulesPaneState
}

interface TableRulesPaneState {
    show: boolean
    rules: Map<string, SelectableItem<Rp5TableRule>>
    numRule:number
    numSelectedRules: number
}

const defaultTableState: TableState = {
    table: {
        prrKey: "",
        entityId: "",
        page: -1,
        title: {
            originalText: "",
            noteName: null,
            titleText: "",
            variants: undefined,
            features: {},
            continued: false,
            unknown: true,
            manuallyEdited: false,
            parent: null,
            period: null,
        },
        metadata: {column:{size: 0, headerSize: 0, periods: []}},
        rows: [],
        dataGroup: {
            id: "root",
            name: "",
            title: "",
            orientation: "ROW",
            detached: false,
            startIndex: 0,
            endIndex: 0,
            children: undefined,
            entityName: undefined,
            features: undefined,
            addedManually: false,
            editedManually: false,
        },
        columnDataGroup: {
            id: "root",
            name: "",
            title: "",
            orientation: "COLUMN",
            detached: false,
            startIndex: 0,
            endIndex: 0,
            children: undefined,
            entityName: undefined,
            features: undefined,
            addedManually: false,
            editedManually: false,
        },
        rules: [],
        customRuleResult: {failedRules: 0, ruleResults: []},
    },
    renderTable: {
        entityId: "",
        rows: [],

        dgs: new Map<string, DataGroup>(),
        masks: new CellMaskIndex(),
    },
    reportFeatures: {
        rowDataGroup: true,
        rowDataGroupEntityNames: false,
        columnDataGroup: false,
        references: false,
        rowNumbers: false,
        columnNumbers: false,
        showGutterIcons: false,
        showCellValueTags: false,
    },

    rulesPane: {
        show: false,
        rules: new Map(),
        numRule: 0,
        numSelectedRules: 0,
    },
}

export type {TableState, TableRulesPaneState}
export {defaultTableState}
