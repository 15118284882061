import React, {useReducer} from "react";
import {useSelector} from "react-redux";

import {Autocomplete, TextField} from "@mui/material";

import {
    FieldContainer,
    WorkspaceBlock,
    WorkspaceBlockBody,
    WorkspaceBlockHeader,
    WorkspaceHeader
} from "../../../qdep/components/app/workspace/Workspace";
import {NewProjectDto} from "../../../app/client/app/entity/Project";
import {ProjectEditorReducer} from "./data/reducer";
import {updateProject} from "./data/action";
import {EditorTabs} from "./tabs/EditorTabs";
import {ProjectEditorBody} from "../component/ProjectEditorBody";
import {AppState} from "../../../app/state/state";

import './ProjectEditorWorkspace.css'


const userRolesSelector = (state: AppState) => state.auth.roles

const ProjectEditorWorkspace = () => {
    const userRoles = useSelector(userRolesSelector);
    const [state, dispatch] = useReducer(ProjectEditorReducer, {
        entity: NewProjectDto(userRoles),
        newRevisions: [],
    });

    return <ProjectEditorBody
        entity={state.entity}
        newRevisions={state.newRevisions}
    >
        <WorkspaceHeader>Create Project</WorkspaceHeader>
        <WorkspaceBlock>
            <WorkspaceBlockHeader>Basic information</WorkspaceBlockHeader>
            <WorkspaceBlockBody>
                <FieldContainer title={"Name"}>
                    <TextField
                        className={"field-control fill"}
                        size={"small"}
                        value={state.entity.name}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            dispatch(updateProject({name: event.target.value}))
                        }}
                    />
                </FieldContainer>
                <FieldContainer title={"Description"} notAlign>
                    <TextField
                        className={"field-control fill"}
                        size={"small"}
                        multiline
                        rows={3}
                        value={state.entity.description}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            dispatch(updateProject({description: event.target.value}))
                        }}
                    />
                </FieldContainer>
                <FieldContainer title={"User Groups"}>
                    <Autocomplete
                        multiple
                        filterSelectedOptions
                        size="small"
                        className={"field-control fill"}
                        options={userRoles}
                        value={state.entity.roles}
                        onChange={(event, newValue) => {
                            dispatch(updateProject({roles: newValue}))
                        }}
                        renderInput={(params) => <TextField {...params}/>}
                    />
                </FieldContainer>
            </WorkspaceBlockBody>
        </WorkspaceBlock>
        <EditorTabs dispatch={dispatch} reports={state.entity.reports} projectName={state.entity.name}/>
    </ProjectEditorBody>
}

export {ProjectEditorWorkspace}