import {AnyAction} from "redux";
import {WritableDraft} from "immer/dist/types/types-external";

interface TableContextMenuState {
    show: boolean
    position: {
        x: number
        y: number
        rowIndex: number
        columnIndex: number
    }
    tableId: string | undefined
}

interface WithTableContextMenu<T extends TableContextMenuState> {
    contextMenu: T
}

const defaultTableContextMenuState: TableContextMenuState = {
    show: false,
    position: {
        x: 0,
        y: 0,
        rowIndex: -1,
        columnIndex: -1,
    },
    tableId: undefined,
}

function closeTableContextMenuReducer<T extends WithTableContextMenu<any>>(state: T, _: AnyAction): T {
    return {
    ...state,
        contextMenu: {...state.contextMenu, show: false}
    }
}

function closeTableContextMenuImmerReducer<T extends WithTableContextMenu<any>>(state: WritableDraft<T>) {
    state.contextMenu.show = false
}

export type {
    WithTableContextMenu,
    TableContextMenuState,
}
export {
    defaultTableContextMenuState,
    closeTableContextMenuReducer, closeTableContextMenuImmerReducer,
}
