import React from "react";
import {AnyAction} from "redux";

import {IconButton} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import {ReportDto} from "../../../../app/client/app/entity/Project";
import {WorkspaceBlock, WorkspaceBlockBody} from "../../../../qdep/components/app/workspace/Workspace";
import {addReport, addRevision, addRevisionEntityModel, deleteReport, updateReport} from "../data/action";
import {ReportEditorBlock} from "../../component/ReportEditorBlock";
import {EmptyReportList} from "../../component/EmptyReportList";


interface ProjectReportListBlockProps {
    projectName: string
    reports: ReportDto[]

    dispatch: React.Dispatch<AnyAction>
}

const ProjectReportListTab = (props: ProjectReportListBlockProps) => {
    return <>
        {
            props.reports.length === 0
                ? <EmptyReportList key={"empty"} onClick={() => props.dispatch(addReport())}/>
                : props.reports.map((report, index) =>
                    <WorkspaceBlock key={index}>
                        <WorkspaceBlockBody classes={"report-editor-body"}>
                            <ReportEditorBlock
                                key={`report-${report.index}`}
                                report={report}
                                projectName={props.projectName}
                                updateReport={((index, updates) => props.dispatch(updateReport(index, updates)))}
                                updateModel={(index, model) => props.dispatch(addRevisionEntityModel(index, model))}
                                addRevision={((index, file) => props.dispatch(addRevision(index, file)))}
                            />
                            <div key={"actions"}>
                                <IconButton onClick={() => props.dispatch(deleteReport(report.index))}>
                                    <DeleteIcon/>
                                </IconButton>
                            </div>
                        </WorkspaceBlockBody>
                    </WorkspaceBlock>
                )
        }
    </>
}

export {ProjectReportListTab}