import {AnyAction} from "redux";
import moment from "moment";
import produce from "immer";
import {WritableDraft} from "immer/dist/types/types-external";
import _ from "lodash";

import {NewReportDto} from "../../../../app/client/app/entity/Project";
import {ProjectEditorActionTypes} from "./action";
import {ProjectEditorState} from "./state";


function ProjectEditorReducer(state: ProjectEditorState, action: AnyAction): ProjectEditorState {
    return produce(state, draft => {
        switch (action.type) {
            case ProjectEditorActionTypes.ADD_REPORT:
                addReportReducer(draft)
                break
            case ProjectEditorActionTypes.DELETE_REPORT:
                deleteReportReducer(draft, action)
                break
            case ProjectEditorActionTypes.UPDATE_PROJECT:
                updateProjectReducer(draft, action)
                break
            case ProjectEditorActionTypes.UPDATE_REPORT:
                updateReportReducer(draft, action)
                break
            case ProjectEditorActionTypes.ADD_REVISION:
                addReportRevisionReducer(draft, action)
                break
            case ProjectEditorActionTypes.ADD_REVISION_ENTITY_MODEL:
                addRevisionEntityModelReducer(draft, action)
                break
        }
        return draft
    })
}

function addReportReducer(state: WritableDraft<ProjectEditorState>) {
    state.entity.reports.unshift(NewReportDto(state.entity.reports.length + 1))
}

function deleteReportReducer(state: WritableDraft<ProjectEditorState>, action: AnyAction) {
    const itemIndex = state.entity.reports.findIndex((item) => item.index === action.index);
    if (itemIndex > -1) {
        state.entity.reports.splice(itemIndex, 1)
        // rm from newRevisions
    }
}

function updateProjectReducer(state: WritableDraft<ProjectEditorState>, action: AnyAction) {
    _.mergeWith(state.entity, action.updates, (obj, src, key) => {
        if (key === "roles") {
            if (_.isArray(src)) {
                return src
            }
            return [];
        }
    })
}

function updateReportReducer(state: WritableDraft<ProjectEditorState>, action: AnyAction) {
    const itemIndex = state.entity.reports.findIndex((item) => item.index === action.index);
    if (itemIndex > -1) {
        _.merge(state.entity.reports[itemIndex], action.updates)
    }
}

function addReportRevisionReducer(state: WritableDraft<ProjectEditorState>, action: AnyAction) {
    const report = state.entity.reports.find((item) => item.index === action.reportIndex);
    if (report !== undefined) {
        if (report.revisions.length === 0) {
            report.revisions.push({
                index: report.revisions.length + 1,
                revisionNumber: ++report.revisionSeq,
                filename: (action.file as File).name,
                file: action.file,
                status: "NEW",
                createData: moment(),

                modelId: undefined,
                modelRevisionId: undefined,
            });
        }

        const revision = report.revisions[0]
        revision.filename = (action.file as File).name
        revision.file = action.file

        if (revision.file !== undefined) {
            state.newRevisions.push({
                project_id: state.entity.id,
                report_index: report.index,
                revision_index: revision.index,
                path: `${state.entity.name}/${report.name}/${revision.file.name}`,
                file: revision.file,
                uploading: false,
                progress: 0,
            });
        }
    }
}

function addRevisionEntityModelReducer(state: WritableDraft<ProjectEditorState>, action: AnyAction) {
    const report = state.entity.reports.find((item) => item.index === action.reportIndex);
    if (report !== undefined) {
        if (report.revisions.length === 0) {
            report.revisions.push({
                index: report.revisions.length + 1,
                revisionNumber: ++report.revisionSeq,
                filename: "",
                file: undefined,
                status: "NEW",
                createData: moment(),

                modelId: undefined,
                modelRevisionId: undefined,
            });
        }

        const revision = report.revisions[0]
        revision.modelId = action.model?.modelId
        revision.modelRevisionId = action.model?.revisionId
    }
}

export {ProjectEditorReducer, addReportRevisionReducer}
