import React, {useReducer} from "react";
import {useMount, useSearchParam, useTitle} from "react-use";

import {useReportPageAddress} from "../base/useReportPageAddress";
import {rp4Reducer} from "./data/reducer";
import {defaultRp4State} from "./data/state";
import {apiClient, ApiResponse} from "../../../../app/client/app/client";
import {ReportRevisionDto} from "../../../../app/client/app/entity/Report";
import {setReportName, setReportStageStatus,} from "../base/report-workspace-header/data/ReportBaseActions";
import {ReportMenuContainer} from "../base/report-menu/ReportMenuContainer";
import {withBaseReducer} from "../base/reportPageReducer";
import {EntitySettings} from "../../../../app/client/app/entity/report/settings/EntitySettings";
import {
    selectDefault,
    selectEntityDefinitionById,
    selectFeatureDefinitionById,
    selectPageByNum,
    setEntitySettings,
    setPagesMenu
} from "./data/action";
import {PageMenu} from "./menu";
import {WorkspaceBody} from "./workspace-body";
import {ReportStagePage} from "../base/report-page/ReportStagePage";

import './rp4.css'


const pageReducer = withBaseReducer(rp4Reducer)

const ReportEntityPage = () => {
    const address = useReportPageAddress();
    const entityIdQueryParam = useSearchParam("entity");
    const featureIdQueryParam = useSearchParam("feature");

    const [state, dispatch] = useReducer(pageReducer, defaultRp4State)

    useTitle(state.reportName || "FINHOUND", {restoreOnUnmount: true})

    useMount(() => {
        apiClient
            .getReportRevision(address.prrKey)
            .then((response: ApiResponse<ReportRevisionDto>) => {
                if (response.data !== undefined) {
                    dispatch(setReportName(response.data.reportName))
                    dispatch(setReportStageStatus({
                        ocr: response.data.analysis.stages.ocr.status,
                        normalization: response.data.analysis.stages.normalization.status,
                        entityDetection: response.data.analysis.stages.entityDetection.status,
                        tableAnalysis: response.data.analysis.stages.tableAnalysis.status,
                        tableRefs: response.data.analysis.stages.tableRefs.status,
                        summary: response.data.analysis.stages.summary.status,
                    }))
                }
            })

        Promise.all([
            apiClient
                .getRp4Pages(address.prrKey)
                .then(response => {
                    if (response.errorReason === undefined && response.data !== undefined) {
                        dispatch(setPagesMenu(response.data))
                    }
                })
            ,
            apiClient
                .getReportRevisionEntitySettings(address.prrKey)
                    .then((response: ApiResponse<EntitySettings>) => {
                    if (!response.errorReason && response.data !== undefined) {
                        dispatch(setEntitySettings(response.data))
                    } else {
                        // todo submit error
                    }
                }),
            ]).then(() => {
                if (featureIdQueryParam !== null) {
                    dispatch(selectFeatureDefinitionById(featureIdQueryParam))
                } else if (entityIdQueryParam !== null) {
                    dispatch(selectEntityDefinitionById(entityIdQueryParam))
                } else if (address.query.page !== undefined) {
                    dispatch(selectPageByNum(address.query.page))
                } else {
                    dispatch(selectDefault())
                }
            })
    })

    return <ReportStagePage>
        <ReportMenuContainer prrKey={address.prrKey} dispatch={dispatch}>
            <PageMenu
                {...state.menuItems}
                dispatch={dispatch}
            />
        </ReportMenuContainer>
        <WorkspaceBody
            prrKey={address.prrKey}
            state={state}
            dispatch={dispatch}
        />
    </ReportStagePage>
}

export {ReportEntityPage}