import React, {useEffect, useReducer} from "react";
import {useAsyncFn} from "react-use";

import {PRRKey} from "../../../../app/client/app/entity/Project";
import {apiClient} from "../../../../app/client/app/client";
import {WorkspaceBlockBody} from "../../../../qdep/components/app/workspace/Workspace";
import {ModificationRuleTable} from "./ModificationRuleTable";
import {ModificationRuleTableSkeleton} from "./ModificationRuleTableSkeleton";
import {ModificationRuleTableToolbar} from "./ModificationRuleTableToolbar";
import {deleteRules, setData} from "./action";
import {reducer} from "./reducer";
import {defaultModificationRulePaneState, State} from "./state";
import {ReportModificationRuleUpdateEvent} from "../../../../app/client/app/entity/report/ModificationRule";


interface ModificationRulesPaneProps {
    prrKey: PRRKey
}

const ModificationRulesPaneWorkspaceBody = (props: ModificationRulesPaneProps) => {
    const [state, dispatch] = useReducer(reducer, defaultModificationRulePaneState);
    useEffect(() => {
        apiClient.listModificationRules(props.prrKey)
            .then(response => {
                if (!response.errorReason && response.data) {
                    dispatch(setData(response.data))
                }
            })
    }, [props.prrKey])

    const [, deleteSelectedRules] = useAsyncFn(async (prrKey: PRRKey, state: State) => {
        if (state.items !== undefined) {
            const updates: ReportModificationRuleUpdateEvent[] = state.items
                .filter(item => item.isSelected)
                .map(item => ({
                    eventType: "DELETE",
                    ruleId: item.data.id,
                    stage: item.data.stage,
                    tableId: item.data.tableId,
                    type: item.data.type,
                }));
            const response = await apiClient.saveReportModificationRules(prrKey, updates);

            if (!response.errorReason && response.data && response.data.status === "ok") {
                dispatch(deleteRules())
            }
        }
    }, []);

    return <WorkspaceBlockBody>
        <ModificationRuleTableToolbar
            title={"Modifications"}
            numSelected={state.numSelected}
            onDelete={() => deleteSelectedRules(props.prrKey, state)}
        />
        { state.items !== undefined
            ? <ModificationRuleTable
                rules={state.items}
                numSelected={state.numSelected}
                dispatch={dispatch}
            />
            : <ModificationRuleTableSkeleton/>
        }
    </WorkspaceBlockBody>
}

export {ModificationRulesPaneWorkspaceBody}