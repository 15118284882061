import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {enableMapSet} from "immer";
import {SnackbarProvider} from "notistack";

import {ThemeProvider} from "@mui/material";

import App from './App';
import reportWebVitals from './reportWebVitals';
import {persistor, store} from "./app/state/store";
import {muiStandardTheme} from "./qdep/components/app/theme/mui";

import './index.css';


enableMapSet()

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <ThemeProvider theme={muiStandardTheme}>
                    <SnackbarProvider
                        maxSnack={3}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                        classes={{
                            variantError: "notification-container"
                        }}
                    >
                        <App/>
                    </SnackbarProvider>
                </ThemeProvider>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
