import React, {useMemo} from "react";

import {Stack} from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";

import {WorkspaceBlock, WorkspaceBlockHeader} from "../../../../../qdep/components/app/workspace/Workspace";
import {TableTitleContainer} from "../../base/report-table-title-container/TableTitleContainer";
import {ReportTableWorkspaceBlockBody} from "../../base/report-table/ReportTableWorkspaceBlockBody";
import {Label} from "../../../../../qdep/components/app/util/label/Label";
import reportTableStyles from "../../base/report-table/ReportTableWorkspaceBlockBody.module.css";
import {CellViewState, mapToTableViewState} from "./table_view_state";
import {ExplicitCellRef, Rp6Table} from "../../../../../app/client/app/entity/Rp6Report";
import {Spacer} from "../../../../../qdep/components/app/util/spacer/Spacer";
import {OpenContextMenuProps} from "../data/action";

import tableStyles from "./table.module.css";


interface ComponentProps {
    table: Rp6Table
    openCellRefDrawer: (cell: ExplicitCellRef) => void
    openContextMenu: (props: OpenContextMenuProps) => void
}

const ReportTable = (props: ComponentProps) => {
    const tableView = useMemo(() => mapToTableViewState(props.table), [props.table]);

    return <WorkspaceBlock>
        <WorkspaceBlockHeader>
            <TableTitleContainer title={tableView.title} tableId={tableView.entityId}/>
        </WorkspaceBlockHeader>
        <ReportTableWorkspaceBlockBody>
            { tableView.rows.map((row, rowIndex) =>
                <tr key={`dt_tr-${rowIndex}`}>
                    { row.cells
                        .map((cell, columnIndex) =>
                            <td
                                key={`rc-${columnIndex}`}
                                className={cellClassName(cell)}
                                colSpan={cell.colSpan}

                                contextMenu={undefined}
                                onContextMenu={event => {
                                    event.preventDefault()
                                    props.openContextMenu({
                                        tableId: props.table.entityId,
                                        position: {
                                            x: event.clientX - 2,
                                            y: event.clientY - 4,
                                            columnIndex: columnIndex,
                                            rowIndex: rowIndex,
                                        },
                                        rowRefs: row.cells[0].rowRefNames,
                                    })
                                }}

                                onClick={() => {
                                    if (cell.cellRefs !== null) {
                                        props.openCellRefDrawer(cell.cellRefs.ref)
                                    }
                                }}
                            >
                                { cellValue(cell) }
                            </td>
                        )
                    }
                </tr>
            )}
        </ReportTableWorkspaceBlockBody>
    </WorkspaceBlock>
}

function cellValue(
    cell: CellViewState,
): React.ReactElement | React.ReactElement[] {
    let items: React.ReactElement[] = []

    if (cell.cellRefs !== null && cell.cellRefs.num > 0) {
        items.push(
            <Label
                key={`ref-count`}
                text={cell.cellRefs.num}
                variant={"info"}
                size={"small"}
            />
        )
        items.push(<Spacer key={"spacer"}/>)
    }

    items.push(<span key={"value"}>{cell.value}</span>);

    if (cell.rowRefNames !== null) {
        cell.rowRefNames.forEach((refName, index) => {
            items.push(
                <Label
                    key={`ref-${index}`}
                    icon={<LinkIcon fontSize={"small"}/>}
                    text={refName}
                    variant={"info"}
                    size={"small"}
                />
            )
        })
    }

    if (items.length > 1) {
        return <Stack direction={"row"} alignItems={"center"} gap={"4px"}>
            { items }
        </Stack>
    }

    return items;
}

function cellClassName(cell: CellViewState): string | undefined {
    let classNames = [];
    if (cell.cellType === "HEADER") {
        classNames.push(reportTableStyles.header)
    } else if (cell.cellType === "DATA"
        && (cell.dataType === "NUMERIC" || cell.dataType === "NUMERIC_CURRENCY"  || cell.dataType === "NUMERIC_PERCENT" ))
    {
        classNames.push(reportTableStyles.data_numeric)
    }
    if (cell.cellRefs !== null) {
        if (cell.cellRefs.ref.suppressed) {
            classNames.push(tableStyles.ref_suppressed);
        } else if (!cell.cellRefs.ref.resolved) {
            classNames.push(tableStyles.ref_not_resolved);
        } else {
            classNames.push(cell.cellRefs.ref.valid ? tableStyles.ref_valid: tableStyles.ref_not_valid);
        }
    }

    if (classNames.length > 0) {
        return classNames.join(" ")
    }
    return undefined
}

export {ReportTable}