import {AnyAction} from "redux";

import {ApiResponse, ErrorReason} from "../../../app/client/types";
import {authClient, AuthRequestData, AuthResponseData} from "../../../app/client/auth/client";
import {call, put, takeEvery} from "redux-saga/effects";
import {AuthState} from "../../../app/state/state";
import {apiClient} from "../../../app/client/app/client";

enum LoginActions {
    LOGIN_REQUEST           = "AUTH.LOGIN_REQUEST",
    LOGIN_REQUEST_SUCCEEDED = 'AUTH.LOGIN_REQUEST.SUCCEEDED',
    LOGIN_REQUEST_FAILED    = 'AUTH.LOGIN_REQUEST.FAILED',

    // todo
    // REFRESH_REQUEST           = "AUTH.REFRESH_REQUEST",
    // REFRESH_REQUEST_SUCCEEDED = 'AUTH.REFRESH_REQUEST.SUCCEEDED',
    // REFRESH_REQUEST_FAILED    = 'AUTH.REFRESH_REQUEST.FAILED',

    AUTH_TOKEN_EXPIRED = "AUTH.TOKEN_EXPIRED",
    LOGOUT_REQUEST           = 'AUTH.LOGOUT_REQUEST',
    LOGOUT_REQUEST_SUCCEEDED = 'AUTH.LOGOUT_REQUEST.SUCCEEDED',
}

function loginRequest(username: string, password: string): AnyAction {
    return {
        type: LoginActions.LOGIN_REQUEST,
        username: username,
        password: password
    }
}

function loginRequestSucceeded(data: AuthResponseData): AnyAction {
    return {
        type: LoginActions.LOGIN_REQUEST_SUCCEEDED,
        expirationTime: data.expiredTime,
        roles: data.roles,
    }
}

function loginRequestFailed(errReason: ErrorReason): AnyAction {
    return {
        type: LoginActions.LOGIN_REQUEST_FAILED,
        errReason: errReason
    }
}

function authTokenExpired(): AnyAction {
    return {
        type: LoginActions.AUTH_TOKEN_EXPIRED,
    }
}

function logoutRequest(): AnyAction {
    return {
        type: LoginActions.LOGIN_REQUEST
    }
}

function logoutRequestSucceeded(): AnyAction {
    return {
        type: LoginActions.LOGOUT_REQUEST_SUCCEEDED
    }
}

const initState: AuthState = {
    isLogged: false,
    isWaiting: false,
    errorMessage: undefined,

    tokenExp: -1,
    roles: [],
}

function authReducer(state: AuthState = initState, action: AnyAction): AuthState {
    switch (action.type) {
        case LoginActions.LOGIN_REQUEST:
            return {
                ...state,
                isWaiting: true,
            }
        case LoginActions.LOGIN_REQUEST_SUCCEEDED:
            return {
                isLogged: true,
                isWaiting: false,
                errorMessage: undefined,
                tokenExp: action.expirationTime,
                roles: action.roles,
            }
        case LoginActions.LOGIN_REQUEST_FAILED:
            let errMessage: string
            switch (action.errReason) {
                case ErrorReason.WRONG_USER_OR_PASS:
                    errMessage = "Invalid username or password"
                    break
                default:
                    errMessage = "Something went wrong. Try again later or contact your administrator"
                    break
            }
            return {
                ...state,
                isLogged: false,
                isWaiting: false,
                errorMessage: errMessage
            }
        case LoginActions.AUTH_TOKEN_EXPIRED:
            return {
                ...initState,
                isLogged: false,
                isWaiting: false,
                errorMessage: 'The session has expired, please log in again'
            }
        case LoginActions.LOGOUT_REQUEST_SUCCEEDED:
            return initState
    }

    return state;
}

function* loginRequestSaga(action: AnyAction) {
    const requestData: AuthRequestData = {
        username: action.username,
        password: action.password,
        project: "",
    }
    const response: ApiResponse<AuthResponseData> = yield call(authClient.login, requestData)
    if (!response.errorReason && response.data) {
        apiClient.setAuthToken(response.data.token)
        apiClient.saveAuthToken(response.data.token)
        yield put(loginRequestSucceeded(response.data))
    } else {
        const err: ErrorReason = response.errorReason || ErrorReason.UNKNOWN_ERROR
        yield put(loginRequestFailed(err))
    }
}

function* logoutRequestSaga() {
    apiClient.unsetAuthToken()
    yield put(logoutRequestSucceeded())
}

function* watchAuthModuleSaga() {
    yield takeEvery(LoginActions.LOGIN_REQUEST, loginRequestSaga)
    yield takeEvery(LoginActions.LOGOUT_REQUEST, logoutRequestSaga)
}

export {
    LoginActions,
    authReducer,
    loginRequest, loginRequestSucceeded, loginRequestFailed,
    authTokenExpired,
    logoutRequest, logoutRequestSucceeded,
    watchAuthModuleSaga,
}