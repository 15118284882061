import {Button, ButtonGroup} from "@mui/material";


interface NumberInputControllerProps {
    value: number
    formattedValue?: string
    onChange: (value: number) => void

    minValue?: number
    maxValue?: number
}

const NumberInputController = (props: NumberInputControllerProps) => {
    return <ButtonGroup size="small" aria-label="small outlined button group">
        <Button
            disabled={props.minValue === undefined ? false : props.value <= props.minValue}
            onClick={() => props.onChange(props.value - 1)}
        >
            -
        </Button>
        <Button disabled style={{color: "black", borderColor: "rgba(104, 112, 120, 0.5)"}}>
            { props.formattedValue === undefined ? props.value : props.formattedValue }
        </Button>
        <Button
            disabled={props.maxValue === undefined ? false : props.value >= props.maxValue}
            onClick={() => props.onChange(props.value + 1)}
        >
            +
        </Button>
    </ButtonGroup>
}

export {NumberInputController}
