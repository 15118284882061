import {AnyAction} from "redux";

import {
    EntityDefinitionState,
    EntityRelationshipsVariantState,
    EntitySelectorRuleState,
    EntityDependencyNode, SelectorWarnings,
} from "./state";
import {SelectableItem} from "../../../../qdep/data/util";
import {EntityDefinition} from "../../../../app/client/app/entity/report/settings/EntityDefinition";


enum ActionTypes {
    SET_ENTITY    = "ENTITY_DEFINITION_EDITOR.SET_ENTITY",
    UPDATE_ENTITY = "ENTITY_DEFINITION_EDITOR.UPDATE_ENTITY",

    // selectors
    ADD_ENTITY_SELECTOR    = "ENTITY_DEFINITION_EDITOR.ADD_ENTITY_SELECTOR",
    UPDATE_ENTITY_SELECTOR = "ENTITY_DEFINITION_EDITOR.UPDATE_ENTITY_SELECTOR",
    DELETE_ENTITY_SELECTOR = "ENTITY_DEFINITION_EDITOR.DELETE_ENTITY_SELECTOR",

    // sub items
    ENTITY_TREE_TOGGLE_NODE = "ENTITY_DEFINITION_EDITOR.ENTITY_TREE_TOGGLE_NODE",
    ENTITY_TREE_SELECT_NODE = "ENTITY_DEFINITION_EDITOR.ENTITY_TREE_SELECT_NODE",

    ENTITY_TREE_ADD_NODE           = "ENTITY_DEFINITION_EDITOR.ENTITY_TREE_ADD_NODE",
    ENTITY_TREE_ADD_SIBLING_NODE   = "ENTITY_DEFINITION_EDITOR.ENTITY_TREE_ADD_SIBLING_NODE",
    ENTITY_TREE_ADD_CONDITION_NODE = "ENTITY_DEFINITION_EDITOR.ENTITY_TREE_ADD_CONDITION_NODE",

    ENTITY_TREE_DELETE_NODE      = "ENTITY_DEFINITION_EDITOR.ENTITY_TREE_DELETE_NODE",
    ENTITY_TREE_UPDATE_SUB_ITEM  = "ENTITY_DEFINITION_EDITOR.ENTITY_TREE_UPDATE_SUB_ITEM",
    ENTITY_TREE_UPDATE_VARIANT   = "ENTITY_DEFINITION_EDITOR.ENTITY_TREE_UPDATE_VARIANT",

    ENTITY_TREE_UPDATE_CONDITION = "ENTITY_DEFINITION_EDITOR.ENTITY_TREE_UPDATE_CONDITION",
}

function setEntity(
    entity: EntityDefinition,
    entityIndex: Map<string, EntityDefinition> | null,
    selectorWarnings?: SelectorWarnings[]
): AnyAction {
    return {
        type: ActionTypes.SET_ENTITY,
        entity: entity,
        entityIndex: entityIndex,
        selectorWarnings: selectorWarnings
    }
}

function updateEntity(updates: Partial<Pick<EntityDefinitionState, 'entityName' | 'category' | 'builtInFeatures'>>): AnyAction {
    return {
        type: ActionTypes.UPDATE_ENTITY,
        updates: updates,
    }
}

// selectors
function addEntitySelector(): AnyAction {
    return { type: ActionTypes.ADD_ENTITY_SELECTOR }
}

function updateEntitySelector(index: number, selector: Partial<SelectableItem<Partial<EntitySelectorRuleState>>>): AnyAction {
    return {
        type: ActionTypes.UPDATE_ENTITY_SELECTOR,
        index: index,
        selector: selector,
    }
}

function deleteEntitySelector(): AnyAction {
    return { type: ActionTypes.DELETE_ENTITY_SELECTOR }
}

// sub items
function toggleNode(nodeId: string): AnyAction {
    return {type: ActionTypes.ENTITY_TREE_TOGGLE_NODE, nodeId: nodeId}
}

function selectNode(nodeId: string | undefined): AnyAction {
    return {type: ActionTypes.ENTITY_TREE_SELECT_NODE, nodeId: nodeId}
}

function addNode(): AnyAction {
    return {type: ActionTypes.ENTITY_TREE_ADD_NODE}
}

function addSiblingNode(): AnyAction {
    return {type: ActionTypes.ENTITY_TREE_ADD_SIBLING_NODE}
}

function addConditionNode(): AnyAction {
    return {type: ActionTypes.ENTITY_TREE_ADD_CONDITION_NODE}
}

function deleteNode(): AnyAction {
    return {type: ActionTypes.ENTITY_TREE_DELETE_NODE}
}

function updateSubItemNode(updates: Partial<EntityDependencyNode>): AnyAction {
    return {type: ActionTypes.ENTITY_TREE_UPDATE_SUB_ITEM, updates: updates}
}

type VariantNodeUpdate = Partial<Pick<EntityRelationshipsVariantState, "requiredFeatures" | "excludedFeatures">>
function updateVariantNode(updates: VariantNodeUpdate): AnyAction {
    return {type: ActionTypes.ENTITY_TREE_UPDATE_VARIANT, updates: updates}
}

type ConditionNodeUpdate = Partial<Pick<EntityDependencyNode, "conditionName" | "absoluteValue">>;
function updateConditionNode(updates: ConditionNodeUpdate): AnyAction {
    return {type: ActionTypes.ENTITY_TREE_UPDATE_CONDITION, updates: updates}
}

export {
    ActionTypes,
    setEntity, updateEntity,
    addEntitySelector, updateEntitySelector, deleteEntitySelector,
    toggleNode, selectNode, deleteNode, updateSubItemNode, updateVariantNode,
    addNode, addSiblingNode, addConditionNode,
    updateConditionNode,
}
export type {VariantNodeUpdate, ConditionNodeUpdate}
