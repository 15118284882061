import {Label} from "../../qdep/components/app/util/label/Label";

interface ProjectStatusFormatterProps {
    value: string // NEW | PROCESSING | ANALYSED | ERROR
}

const ProjectStatusFormatter = (props: ProjectStatusFormatterProps) => {
    switch (props.value) {
        case "ANALYSED":
            return <Label variant={"success"} text={props.value}/>
        case "ERROR":
            return <Label variant={"error"} text={props.value}/>
        case "NEW":
        case "PROCESSING":
        default:
            return <Label variant={"info"} text={props.value}/>
    }
}

export {ProjectStatusFormatter}
