import {SimpleNotification} from "./type";
import {AnyAction} from "redux";
import {SnackbarKey, VariantType} from "notistack";


enum NotificationModuleActionTypes {
    EMIT = "NOTIFICATION_MODULE.EMIT",
    REMOVE = "NOTIFICATION_MODULE.REMOVE",
}

function notificationEmit(key: SnackbarKey, variant: VariantType, message: string): AnyAction {
    return {
        type: NotificationModuleActionTypes.EMIT,
        notification: {
            key: key,
            variant: variant,
            message: message,
        },
    }
}

function notificationRemove(key: SnackbarKey) {
    return {
        type: NotificationModuleActionTypes.REMOVE,
        key: key,
    }
}

function notificationReducer(state: SimpleNotification[] = [], action: AnyAction): SimpleNotification[] {
    switch (action.type) {
        case NotificationModuleActionTypes.EMIT:
            return [...state, action.notification]
        case NotificationModuleActionTypes.REMOVE:
            return state.filter(notification => notification.key !== action.key)
    }
    return state
}

export {notificationEmit, notificationRemove, notificationReducer}