import {defaultReportBaseState, ReportBaseState} from "../../base/report-workspace-header/data/ReportBaseState";
import {Rp7CoverageTable, Rp7PageCoverageMenuItem} from "../../../../../app/client/app/entity/Rp7Report";
import {WithReportTablesWorkspace} from "../../base/report-tables-container/state";

interface Rp7State extends
    ReportBaseState,
    WithReportTablesWorkspace<Rp7CoverageTable>
{
    menu: Rp7MenuState
}

const enum Rp7MenuItemType {
    OVERVIEW = "OVERVIEW",

    COVERAGE_TABLES = "COVERAGE_TABLES",
    ISSUE_TABLES    = "ISSUE_TABLES",
    ISSUE_OVERVIEW  = "ISSUE_OVERVIEW",
}

interface Rp7MenuState {
    selectedItem: Rp7MenuItemType
    pageNum: number | undefined
    pages: Rp7MenuPageState | undefined
}

interface Rp7MenuPageState {
    isLoading: boolean
    selectedPageIndex: number | undefined
    pages: Rp7PageCoverageMenuItemState[]
}

interface Rp7PageCoverageMenuItemState extends Rp7PageCoverageMenuItem {
    selected: boolean
}

const defaultRp7State: Rp7State = {
    ...defaultReportBaseState,
    menu: {
        selectedItem: Rp7MenuItemType.OVERVIEW,
        pageNum: undefined,
        pages: undefined,
    },
    tableWorkspace: {
        isLoading: true,
        tables: [],
    }
}

export {
    defaultRp7State,
    Rp7MenuItemType,
}
export type {
    Rp7State,
    Rp7MenuState,
    Rp7MenuPageState, Rp7PageCoverageMenuItemState,
}