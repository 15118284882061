import {Label} from "./Label";

interface SwitchLabelProps {
    value: boolean
}

const SwitchLabel = (props: SwitchLabelProps) => {
    const text = props.value ? "On" : "Off"
    const variant = props.value ? "success" : "error"
    return <Label text={text} variant={variant}/>
}


export {SwitchLabel}