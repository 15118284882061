import React, {Dispatch} from "react";
import {AnyAction} from "redux";

import {Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";

import {ModificationRule} from "../../../../app/client/app/entity/report/ModificationRule";
import {Label} from "../../../../qdep/components/app/util/label/Label";
import {IndexedAndSelectableItem} from "../../../../qdep/data/util";
import {selectAllRules, selectRule} from "./action";


interface ModificationRuleTableProps {
    rules: IndexedAndSelectableItem<ModificationRule>[]
    numSelected: number
    dispatch: Dispatch<AnyAction>
}

const ModificationRuleTable = (props: ModificationRuleTableProps) => {
    const numRules = props.rules.length

    return <TableContainer>
        <Table size={"small"}>
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={props.numSelected > 0 && props.numSelected < numRules}
                            checked={numRules > 0 && props.numSelected === numRules}
                            onClick={() => props.dispatch(selectAllRules())}
                        />
                    </TableCell>
                    <TableCell>Stage</TableCell>
                    <TableCell>Table Id</TableCell>
                    <TableCell>Rule Id</TableCell>
                    <TableCell>Rule Type</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Description</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    props.rules.map(rule =>
                        <TableRow key={rule.data.id}>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    color="primary"
                                    checked={rule.isSelected}
                                    onClick={() => props.dispatch(selectRule(rule.rowIndex))}
                                />
                            </TableCell>
                            <TableCell>{rule.data.stage}</TableCell>
                            <TableCell>{rule.data.tableId}</TableCell>
                            <TableCell>{rule.data.id}</TableCell>
                            <TableCell>{rule.data.type}</TableCell>
                            <TableCell>{statusFormatter(rule.data.result.status)}</TableCell>
                            <TableCell>{rule.data.result.description}</TableCell>
                        </TableRow>
                    )
                }
            </TableBody>
        </Table>
    </TableContainer>
}

function statusFormatter(value: string): React.ReactElement {
    let variant: "success" | "error" | "info" | "disabled" | "warning" = "info"
    switch (value) {
        case "ADD":
        case "APPLIED":
            variant = "success"
            break
        case "UNKNOWN":
        case "INVALID":
            variant = "error"
            break
        case "DELETE":
            variant = "disabled"
            break
        case "EDIT":
            variant = "info"
            break
    }
    return <Label variant={variant} text={value}/>
}

export {ModificationRuleTable}