import {WritableDraft} from "immer/dist/types/types-external";
import {AnyAction} from "redux";

interface BaseMenuItemState {
    selected: boolean
    page: number
}

interface BaseMenuState<T extends BaseMenuItemState> {
    items: T[]
    selectedItem: number | undefined
    selectedPage: number | undefined
}

interface WithMenuState<T extends BaseMenuItemState> {
    menu: BaseMenuState<T>
}

function prevPageReducer<T extends WithMenuState<BaseMenuItemState>>(state: WritableDraft<T>) {
    const selectedItem = state.menu.selectedItem;
    if (selectedItem !== undefined && selectedItem > 0) {
        state.menu.items[selectedItem].selected = false
        const prevItem = selectedItem-1;
        state.menu.items[prevItem].selected = true
        state.menu.selectedItem = prevItem
        state.menu.selectedPage = state.menu.items[prevItem].page
    }
}

function nextPageReducer<T extends WithMenuState<BaseMenuItemState>>(state: WritableDraft<T>) {
    const selectedItem = state.menu.selectedItem;
    if (selectedItem !== undefined && selectedItem + 1 < state.menu.items.length) {
        state.menu.items[selectedItem].selected = false
        const nextItem = selectedItem+1;
        state.menu.items[nextItem].selected = true
        state.menu.selectedItem = nextItem
        state.menu.selectedPage = state.menu.items[nextItem].page
    }
}

function selectPageReducer<T extends WithMenuState<BaseMenuItemState>>(state: WritableDraft<T>, action: AnyAction) {
    const menuIndex: number | undefined = action.menuIndex;
    if (menuIndex === undefined) {
        return
    }

    const prevSelectedItem = state.menu.selectedItem;
    if (prevSelectedItem !== undefined) {
        state.menu.items[prevSelectedItem].selected = false
    }

    state.menu.items[menuIndex].selected = true
    state.menu.selectedItem = menuIndex
    state.menu.selectedPage = state.menu.items[menuIndex].page
}

export {prevPageReducer, nextPageReducer, selectPageReducer}
export type {BaseMenuState, BaseMenuItemState, WithMenuState}
