import {AnyAction} from "redux";

import {EntitiesModelRevision} from "../../../../app/client/app/entity/report/model/ReportModel";
import {EntityDefinition} from "../../../../app/client/app/entity/report/settings/EntityDefinition";
import {FeatureFlagDefinition} from "../../../../app/client/app/entity/report/settings/FeatureDefinition";


const enum ActionTypes {
    SET_REVISION = "SET_REVISION",

    // entity definition
    SELECT_ENTITY_DEFINITION        = "SELECT_ENTITY_DEFINITION",
    ADD_ENTITY_DEFINITION           = "ADD_ENTITY_DEFINITION",
    DELETE_ENTITY_DEFINITION        = "DELETE_ENTITY_DEFINITION",
    APPLY_ENTITY_DEFINITION_CHANGES = "APPLY_ENTITY_DEFINITION_CHANGES",

    // feature definition
    ADD_FEATURE_DEFINITION           = "ADD_FEATURE_DEFINITION",
    SELECT_FEATURE_DEFINITION        = "SELECT_FEATURE_DEFINITION",
    DELETE_FEATURE_DEFINITION        = "DELETE_FEATURE_DEFINITION",
    APPLY_FEATURE_DEFINITION_CHANGES = "APPLY_FEATURE_DEFINITION_CHANGES",
}

function setRevision(revision: EntitiesModelRevision): AnyAction {
    return {type: ActionTypes.SET_REVISION, revision: revision}
}

// region entity definition
function selectEntityDefinition(index: number | undefined): AnyAction {
    return {
        type: ActionTypes.SELECT_ENTITY_DEFINITION,
        index: index,
    }
}

function addEntityDefinition(): AnyAction {
    return {
        type: ActionTypes.ADD_ENTITY_DEFINITION,
    }
}

function deleteEntityDefinition(): AnyAction {
    return {
        type: ActionTypes.DELETE_ENTITY_DEFINITION,
    }
}

function applyEntityDefinitionChanges(entityDefinition: EntityDefinition): AnyAction {
    return {
        type: ActionTypes.APPLY_ENTITY_DEFINITION_CHANGES,
        entityDefinition: entityDefinition,
    }
}
// endregion

// region feature definition
function addFeatureDefinition(): AnyAction {
    return {type: ActionTypes.ADD_FEATURE_DEFINITION}
}

function selectFeatureDefinition(index: number | undefined): AnyAction {
    return {type: ActionTypes.SELECT_FEATURE_DEFINITION, index: index}
}

function deleteFeatureDefinition(): AnyAction {
    return {
        type: ActionTypes.DELETE_FEATURE_DEFINITION,
    }
}

function applyFeatureDefinitionChanges(featureDefinition: FeatureFlagDefinition): AnyAction {
    return {
        type: ActionTypes.APPLY_FEATURE_DEFINITION_CHANGES,
        featureDefinition: featureDefinition,
    }
}
// endregion

export {
    ActionTypes,
    setRevision,
    addEntityDefinition, deleteEntityDefinition, selectEntityDefinition, applyEntityDefinitionChanges,
    addFeatureDefinition, deleteFeatureDefinition, selectFeatureDefinition, applyFeatureDefinitionChanges,
}
