import React from "react";

import {Rp7OverviewCoverageData} from "../../../../../../app/client/app/entity/Rp7Report";
import {Label} from "../../../../../../qdep/components/app/util/label/Label";
import {formatPercent, OtherMetricContainer} from "../OtherMetricContainer";

import styles from "./CoverageOverview.module.css";


interface ComponentProps {
    metrics: Rp7OverviewCoverageData
}

const CoverageMetric = (props: ComponentProps) => {
    return <div className={styles.metric_block}>
        <div className={styles.metric_block_avg_container}>
            <div className={styles.metric_block_avg_title}>
                Avg Coverage
            </div>
            <div className={styles.metric_block_avg_value}>
                { renderAvgCoverage(props.metrics.avgCoverage) }
            </div>
        </div>

        <div className={styles.metric_block_other_container}>
            <OtherMetricContainer
                title={"Full Coverage"}
                value={`${props.metrics.fullCoveredTablesNum} / ${props.metrics.tableNum}`}
            />
            <OtherMetricContainer
                title={"Full Coverage Rate"}
                value={formatPercent(props.metrics.fullCoveredTablesNum /  props.metrics.tableNum)}
            />
        </div>
    </div>
}

function renderAvgCoverage(value: number): React.ReactNode {
    const percent = value * 100
    let variant: "success" | "error" = "success"
    if (percent < 80) {
        variant = "error"
    }

    return <Label size={"large"} variant={variant} text={percent.toFixed(2) + "%"}/>;
}

export {CoverageMetric}