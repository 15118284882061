import React from "react";
import {AnyAction} from "redux";

import {PRRKey} from "../../../../app/client/app/entity/Project";
import {ReportMenuContainer} from "../base/report-menu/ReportMenuContainer";
import {Rp3MenuItemState} from "./data/state";
import {BaseMenuState} from "../base/report-menu/state";
import {ReportMenuGroupContainer} from "../base/report-menu/ReportMenuGroupContainer";
import {ReportMenuItemLink, ReportMenuItemList} from "../base/report-menu/ReportMenuItemList";


interface ReportMenuProps {
    prrKey: PRRKey
    menu: BaseMenuState<Rp3MenuItemState>
    dispatch: React.Dispatch<AnyAction>
}

const ReportMenu = (props: ReportMenuProps) => {
    return <ReportMenuContainer prrKey={props.prrKey} dispatch={props.dispatch}>
        <ReportMenuGroupContainer isHighLevelGroup label={"Pages"}>
            <ReportMenuItemList>
                { props.menu.items.map((item, index) =>
                    <ReportMenuItemLink
                        key={`mi-${index}`}
                        index={index}
                        item={item}
                    >
                        <span className={"rp2-menu-item-page"}>Page {item.page}</span>
                        <span className={"rp2-menu-item-desc"}>{item.count} table(s)</span>
                    </ReportMenuItemLink>)
                }
            </ReportMenuItemList>
        </ReportMenuGroupContainer>
    </ReportMenuContainer>
}

export {ReportMenu}
