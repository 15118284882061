import {CustomRuleResults, ModificationRuleData} from "./report/ModificationRule";
import {ColumnMetadata, Period} from "./report/TableMetadata";
import {OptionalField} from "../../../../qdep/data/util";
import {TableTitle} from "./report/TableTitle";
import {CellTag, Lexeme} from "./rp";

interface Rp5MenuItem {
    page: number
    tables: number
    issues: number
}

interface Rp5CellRef {
    row: number
    rowColumn: number
}

interface Rp5TableRule {
    ruleId: string
    name: string
    row: number
    rowColumn: number
    readonly isPassed: boolean
    cellRefs: Rp5CellRef[]
    description: string
    expectedValue: string | null
    actualValue: string | null
    diffValue: string | null
}

interface Rp5Cell {
    row: number
    column: number
    colSpan: number

    cellType: "HEADER" | "DATA" | string
    dataType: "NUMERIC" | string

    cellUnit: Rp5CellUnit

    value: string
    indent: number | null
    typeValue: string | null
    readonly tags: CellTag[] | null
    readonly lexemes: Lexeme[] | null
}

interface Rp5CellUnit {
    unit: string | null
    unitType: "UNKNOWN" | "CURRENCY" | "PERCENT" | string
}

interface Rp5Row {
    cells: Rp5Cell[]

    transforms?: Rp5RowTransform[] | null
    rowRefs?: string[]
    period?: Period | null
    trigger?: Rp5RowFormulaTrigger | null
}

interface Rp5RowTransform {
    type: string
}

interface Rp5RowFormulaTrigger {
    rowIndex: number
    triggerCauses: Rp5TriggerCause[] | null

    attached: boolean
    attachedDataGroupId: string | null

    suppressed: boolean
    suppressionCause: string | null
}

interface Rp5TriggerCause {
    causeType: string

    suppressed: boolean
    suppressionCause: string | null
}

interface Rp5DataGroup {
    readonly id: string
    readonly name: string
    readonly title: string
    readonly orientation: string
    readonly detached: boolean

    readonly addedManually: boolean
    readonly editedManually: boolean

    readonly startIndex: number
    readonly endIndex: number

    readonly children: OptionalField<Rp5DataGroup[]>

    readonly entityName: OptionalField<string>
    readonly features: OptionalField<Rp5FeatureSet>
}

interface Rp5FeatureSet {
    [featureName: string]: string
}

interface Rp5TableMetadata {
    column: ColumnMetadata
}

interface Rp5Table {
    readonly prrKey: string
    readonly entityId: string
    readonly page: number
    readonly title: TableTitle

    metadata: Rp5TableMetadata
    rows: Rp5Row[]

    readonly dataGroup: Rp5DataGroup
    columnDataGroup: Rp5DataGroup
    rules: Rp5TableRule[]
    customRuleResult: CustomRuleResults
}

enum Rp5CustomRuleType {
    ADD_DATA_GROUP    = "ADD_DATA_GROUP",
    EDIT_DATA_GROUP   = "EDIT_DATA_GROUP",
    DELETE_DATA_GROUP = "DELETE_DATA_GROUP",

    ADD_ROW_GROUP_SUM_TRIGGER = "ADD_ROW_GROUP_SUM_TRIGGER",
    ADD_ROW_FORMULA_TEMPLATE  = "ADD_ROW_FORMULA_TEMPLATE",
    SUPPRESS_ROW_SUM_TRIGGER  = "SUPPRESS_ROW_SUM_TRIGGER",

    ADD_COLUMN_GROUP_SUM_TRIGGER = "ADD_COLUMN_GROUP_SUM_TRIGGER",
    SUPPRESS_COLUMN_SUM_TRIGGER  = "SUPPRESS_COLUMN_SUM_TRIGGER",

    ADD_ROW_TRANSFORMER = "ADD_ROW_TRANSFORMER",
}

interface Rp5CustomRule extends ModificationRuleData<typeof Rp5CustomRuleType> {
    editDataGroup?: {
        entityId: string
        title: string
        orientation: string
        startIndex: number
        endIndex: number
        detached: boolean
    }
    addDataGroup?: {
        title: string
        orientation: string
        startIndex: number
        endIndex: number
        detached: boolean
    }
    deleteDataGroup?: {
        entityId: string
        orientation: string
    }

    addRowGroupSumTarget?: {
        rowIndex: number
    }
    addRowFormulaTemplate?: {
        rowIndex: number
        templateName: string
        addDataGroup: boolean
    }
    suppressRowSumTarget?: {
        index: number
    }

    addColumnSumTarget?: {
        columnIndex: number
        rowIndex: number
    }
    suppressColumnSumTarget?: {
        columnIndex: number
        rowIndex: number
    }

    rowTransformer?: {
        type: string
        rowIndex: number
    }
}

export type {
    Rp5MenuItem,
    Rp5Row,
    Rp5Cell, Rp5CellUnit,
    Rp5Table, Rp5TableMetadata,
    Rp5TableRule, Rp5CellRef,
    Rp5DataGroup, Rp5FeatureSet,
    Rp5CustomRule,
    Rp5RowTransform,
    Rp5RowFormulaTrigger, Rp5TriggerCause,
}
export {Rp5CustomRuleType}
