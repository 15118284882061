import React, {useReducer} from "react";
import {useTitle} from "react-use";

import {useReportPageAddress} from "../base/useReportPageAddress";
import {defaultReportOcrState, reportOcrReducer} from "./data/reducer";
import {PageMenu} from "./PageMenu";
import {withBaseAndModificationRuleReducer, withReportTablesContainerReducer} from "../base/reportPageReducer";
import {ReportWorkspace} from "../base/report-workspace/ReportWorkspace";
import {ReportStagePage} from "../base/report-page/ReportStagePage";
import {Rp2Body} from "./rp2_body";


const pageReducer = withReportTablesContainerReducer(
    withBaseAndModificationRuleReducer(
        reportOcrReducer
    )
)

const ReportOcrPage = () => {
    const address = useReportPageAddress();
    const [state, dispatch] = useReducer(pageReducer, defaultReportOcrState);
    useTitle(state.reportName || "FINHOUND", {restoreOnUnmount: true})

    return <ReportStagePage>
        <PageMenu
            prrKey={address.prrKey}
            menu={state.menu}
            dispatch={dispatch}
        />
        <ReportWorkspace>
            <Rp2Body address={address} state={state} dispatch={dispatch}/>
        </ReportWorkspace>
    </ReportStagePage>
}

export {ReportOcrPage}