import React, {useState} from "react";
import {AnyAction} from "redux";

import {ListItemIcon, ListItemText, Menu, MenuItem} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import FunctionsIcon from "@mui/icons-material/Functions";
import PercentIcon from '@mui/icons-material/Percent';

import {closeTableContextMenu, openDrawer} from "../page/action";
import {addCustomRule} from "../../base/report-custom-rule-pane/data/action";
import {Rp5CustomRuleType} from "../../../../../app/client/app/entity/Rp5Report";
import {Rp5ContextMenu} from "../page/reducer";


interface ComponentProps {
    contextMenu: Rp5ContextMenu
    dispatch: React.Dispatch<AnyAction>
}

const RowFormulaContextMenu = (props: ComponentProps) => {
    const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null as Element | null);

    return <>
        <MenuItem
            onClick={(event) => setSubMenuAnchorEl(event.currentTarget)}
        >
            <ListItemIcon>
                <AddIcon/>
            </ListItemIcon>
            <ListItemText>Add Row Formula</ListItemText>
            <ArrowRightIcon/>
        </MenuItem>

        <Menu
            open={subMenuAnchorEl !== null && props.contextMenu.show}
            anchorEl={subMenuAnchorEl}
            onClose={() => setSubMenuAnchorEl(null)}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
            <MenuItem
                onClick={() => {
                    props.dispatch(closeTableContextMenu())
                    if (props.contextMenu.tableId !== undefined) {
                        props.dispatch(addCustomRule({
                            tableId: props.contextMenu.tableId,
                            type: Rp5CustomRuleType.ADD_ROW_GROUP_SUM_TRIGGER,
                            addRowGroupSumTarget: {
                                rowIndex: props.contextMenu.position.rowIndex,
                            }
                        }, `Add the sum trigger to row ${props.contextMenu.position.rowIndex}`))
                    }
                }}
            >
                <ListItemIcon>
                    <FunctionsIcon/>
                </ListItemIcon>
                <ListItemText>Group Sum</ListItemText>
            </MenuItem>
            <MenuItem
                onClick={() => {
                    props.dispatch(closeTableContextMenu())
                    props.dispatch(openDrawer())
                }}
            >
                <ListItemIcon>
                    <PercentIcon/>
                </ListItemIcon>
                <ListItemText>Tax Template</ListItemText>
            </MenuItem>
            <MenuItem
                disabled
            >
                <ListItemIcon>
                    <BorderColorIcon/>
                </ListItemIcon>
                <ListItemText>Pivot</ListItemText>
            </MenuItem>
        </Menu>
    </>;
}

export {RowFormulaContextMenu}