import {Label} from "../../qdep/components/app/util/label/Label";

interface RevisionStatusFormatterProps {
    value: string //  NEW, PENDING, ANALYSING, ANALYSED, OUTDATED, ERROR, OCR_RUNNING, OCR_PASSED,
}

const RevisionStatusFormatter = (props: RevisionStatusFormatterProps) => {
    switch (props.value) {
        case "ANALYSED":
            return <Label variant={"success"} text={props.value}/>
        case "ERROR":
            return <Label variant={"error"} text={props.value}/>
        case "OUTDATED":
            return <Label variant={"warning"} text={props.value}/>
        case "NEW":
        case "PROCESSING":
        default:
            return <Label variant={"info"} text={props.value}/>
    }
}

export {RevisionStatusFormatter}
