import React from "react";

import {IconButton} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import styles from "./coverage/CoverageOverview.module.css";


function formatPercent(value: number): string {
    return (value * 100).toFixed(2) + "%"
}

interface OtherMetricContainerProps {
    title: string
    value: number | string
}

const OtherMetricContainer = (props: OtherMetricContainerProps) => {
    return <>
        <div className={styles.metric_block_other_title}>
            <div>{ props.title }</div>
            <IconButton>
                <InfoOutlinedIcon fontSize={"small"}/>
            </IconButton>
        </div>
        <div className={styles.metric_block_other_value}>
            { props.value}
        </div>
    </>
}

export {
    OtherMetricContainer,
    formatPercent,
}