import React from "react";

import {
    WorkspaceBlock,
    WorkspaceBlockHeader
} from "../../../../../qdep/components/app/workspace/Workspace";
import {Rp7CoverageTable, Rp7CoverageTableCell} from "../../../../../app/client/app/entity/Rp7Report";
import {TableTitleContainer} from "../../base/report-table-title-container/TableTitleContainer";
import {ReportTableWorkspaceBlockBody} from "../../base/report-table/ReportTableWorkspaceBlockBody";

import commonTableStyles from "../../base/report-table/ReportTableWorkspaceBlockBody.module.css";
import styles from "./table.module.css";
import {Label} from "../../../../../qdep/components/app/util/label/Label";
import {Spacer} from "../../../../../qdep/components/app/util/spacer/Spacer";


interface ComponentProps {
    table: Rp7CoverageTable
}

const CoverageTable = (props: ComponentProps) => {
    return <WorkspaceBlock>
        <WorkspaceBlockHeader>
            <TableTitleContainer title={props.table.title} tableId={props.table.entityId}/>
            <Spacer/>
            { renderCoverageLabel(props.table.coverageRate) }
        </WorkspaceBlockHeader>
        <ReportTableWorkspaceBlockBody>
            { props.table.rows.map((row, rowIndex) =>
                <tr key={"rr-" + rowIndex}>
                    { row.cells.map((cell, columnIndex) =>
                        <td
                            key={columnIndex}
                            colSpan={cell.columnSpan}
                            className={getCellClassName(cell)}
                        >
                            { cell.value }
                        </td>
                    )}
                </tr>
            )}
        </ReportTableWorkspaceBlockBody>
    </WorkspaceBlock>
}

function getCellClassName(cell: Rp7CoverageTableCell): string | undefined {
    if (cell.cellType === "HEADER") {
        return commonTableStyles.header
    }

    const classes: string[] = []
    if (cell.cellType === "DATA") {
        classes.push(commonTableStyles.data_numeric)
    }
    if (cell.coverage === "COVERED") {
        classes.push(styles.covered)
    } else if (cell.coverage === "NOT_COVERED") {
        classes.push(styles.not_covered)
    }

    if (classes.length > 0) {
        return classes.join(" ");
    }
    return undefined;
}

function renderCoverageLabel(coverageRate: number) {
    const percent = coverageRate * 100
    let variant: "success" | "error" = "success"
    if (percent < 80) {
        variant = "error"
    }

    return <Label variant={variant} text={percent.toFixed(0) + "%"}/>;
}

export {CoverageTable}