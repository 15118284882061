import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

interface ConfirmDeleteDialogProps {
    object: string
    isOpen: boolean
    close: () => void
    confirm: () => void
}

const ConfirmDeleteDialog = (props: ConfirmDeleteDialogProps) => {
    return <Dialog open={props.isOpen}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Are you sure you want to delete the {props.object}?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.close}>Cancel</Button>
            <Button variant="contained" color="secondary"
                    onClick={props.confirm}
            >
                Confirm
            </Button>
        </DialogActions>
    </Dialog>
}

export {ConfirmDeleteDialog}