import React, {Dispatch} from "react";
import {AnyAction} from "redux";
import {useHistory} from "react-router-dom";

import {Button, Stack} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import styles from "./menu.module.css";
import {MenuItemState, MenuState} from "./page_state";
import {NewDefinitionButton} from "../../stages/rp4-entity/component/new-definitio-button/NewDefinitionButton";
import {Spacer} from "../../../../qdep/components/app/util/spacer/Spacer";
import {Label} from "../../../../qdep/components/app/util/label/Label";
import {
    addEntityDefinition,
    addFeatureDefinition,
    selectEntityDefinition,
    selectFeatureDefinition
} from "./page_action";


interface ComponentProps {
    modelId: string
    menuState: MenuState
    dispatch: Dispatch<AnyAction>
}


const PageMenu = (props: ComponentProps) => {
    const history = useHistory();
    return <div className={styles.menu_container}>
        <div className={styles.action_container}>
            <Button
                variant={"text"}
                color={"info"}
                disableRipple
                startIcon={<ArrowBackIcon/>}
                onClick={() => history.push(`/report/models/${props.modelId}`)}
            >
                Back
            </Button>
        </div>

        <div className={styles.menu_group_container}>
            <label>Entities</label>
            <NewDefinitionButton
                title={"Create a new entity"}
                active={props.menuState.selectedMenuItem.section === "ENTITY" && props.menuState.selectedMenuItem.isNew}
                onClick={() => props.dispatch(addEntityDefinition())}
            />
            <ul className={styles.menu_list}>
                { props.menuState.entities.map((item, index) =>
                    <li key={`ei-${index}`} className={styles.menu_item_container}>
                        <MenuItem
                            {...item}
                            onClick={() => props.dispatch(selectEntityDefinition(index))}
                        />
                    </li>)
                }
            </ul>
        </div>

        <div className={styles.menu_group_container}>
            <label>Entity Features</label>
            <NewDefinitionButton
                title={"Create a new feature"}
                active={props.menuState.selectedMenuItem.section === "FEATURE" && props.menuState.selectedMenuItem.isNew}
                onClick={() => props.dispatch(addFeatureDefinition())}
            />
            <ul className={styles.menu_list}>
                { props.menuState.features.map((item, index) =>
                    <li key={`fi-${index}`} className={styles.menu_item_container}>
                        <MenuItem
                            {...item}
                            onClick={() => props.dispatch(selectFeatureDefinition(index))}
                        />
                    </li>)
                }
            </ul>
        </div>

    </div>
}

interface MenuItemProps extends MenuItemState {
    onClick: () => void
}

const MenuItem = (props: MenuItemProps) => {
    const classes: string[] = [styles.menu_item]
    if (props.selected) {
        classes.push(styles.selected)
    }

    return <div
        className={classes.join(" ")}
        onClick={props.onClick}
    >
        <span>{props.itemName}</span>
        <Spacer/>
        <Stack direction={"column"} gap={"4px"}>
            { props.new && <span><Label text={"NEW"} variant={"success"} size={"small"}/></span> }
            { props.edited && <span><Label text={"EDITED"} variant={"info"} size={"small"}/></span> }
            { props.deleted && <span><Label text={"DELETED"} variant={"disabled"} size={"small"}/></span> }
        </Stack>
    </div>;
}

export {PageMenu}
