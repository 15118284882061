import React from "react";

import {Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";


const ModificationRuleTableSkeleton = () => {
    return <TableContainer>
        <Table size={"small"}>
            <TableHead>
                <TableRow>
                    <TableCell/>
                    <TableCell>Stage</TableCell>
                    <TableCell>Table Id</TableCell>
                    <TableCell>Rule Id</TableCell>
                    <TableCell>Rule Type</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Description</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell><Skeleton/></TableCell>
                    <TableCell><Skeleton/></TableCell>
                    <TableCell><Skeleton/></TableCell>
                    <TableCell><Skeleton/></TableCell>
                    <TableCell><Skeleton/></TableCell>
                    <TableCell><Skeleton/></TableCell>
                    <TableCell><Skeleton/></TableCell>
                </TableRow>
                <TableRow>
                    <TableCell><Skeleton/></TableCell>
                    <TableCell><Skeleton/></TableCell>
                    <TableCell><Skeleton/></TableCell>
                    <TableCell><Skeleton/></TableCell>
                    <TableCell><Skeleton/></TableCell>
                    <TableCell><Skeleton/></TableCell>
                    <TableCell><Skeleton/></TableCell>
                </TableRow>
                <TableRow>
                    <TableCell><Skeleton/></TableCell>
                    <TableCell><Skeleton/></TableCell>
                    <TableCell><Skeleton/></TableCell>
                    <TableCell><Skeleton/></TableCell>
                    <TableCell><Skeleton/></TableCell>
                    <TableCell><Skeleton/></TableCell>
                    <TableCell><Skeleton/></TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </TableContainer>
}

export {ModificationRuleTableSkeleton}