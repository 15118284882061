import {useEffect} from "react";
import {useHistory} from "react-router-dom";


interface PageNavigationProps {
    page?: number
    menuIndex?: number
    prevMenuIndex?: number
}

function usePageNavigation(
    selectMenuItemCallback: (itemIndex: number) => void,
    loadPageTablesCallback: (page: number) => void,
    selectedPage: number | undefined
) {
    const history = useHistory<PageNavigationProps>();
    useEffect(() => {
        const unreg = history.listen((location, action) => {
            if (location.state !== undefined && action !== "REPLACE") {
                const page: number | undefined = location.state.page;
                const menuIndex: number | undefined = location.state.menuIndex;
                const prevMenuIndex: number | undefined = location.state.prevMenuIndex;
                if (page !== undefined && menuIndex !== undefined && prevMenuIndex !== menuIndex) {
                    selectMenuItemCallback(menuIndex)
                }
            }
        })
        return () => {
            unreg();
        };
    }, [history, selectMenuItemCallback])

    useEffect(() => {
        if (selectedPage !== undefined) {
            loadPageTablesCallback(selectedPage);
            history.replace(
                `${history.location.pathname}?page=${selectedPage}`,
                {page: selectedPage, menuIndex: undefined, prevMenuIndex: undefined}
            )
        }
    }, [history, loadPageTablesCallback, selectedPage])
}

export {usePageNavigation}
export type {PageNavigationProps}
