import React, {useState} from "react";

import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";

import {FieldContainer} from "../../../../../qdep/components/app/workspace/Workspace";
import {LoadingButton} from "@mui/lab";


interface ComponentProps {
    open: boolean
    loading: boolean
    close: () => void
    apply: (info: ComponentState) => void
}

interface ComponentState {
    name: string
    description: string
}

const defaultState: ComponentState = {name: "", description: ""}

const NewRevisionDialog = (props: ComponentProps) => {
    const [state, setState] = useState<ComponentState>(defaultState);

    return <Dialog open={props.open}>
        <DialogTitle>
            New Entity Model Revision
        </DialogTitle>

        <DialogContent>
            <FieldContainer title={"Revision Name"}>
                <TextField
                    className={"field-control"}
                    size={"small"}
                    value={state.name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setState(prev => ({...prev, name: event.target.value}))
                    }}
                />
            </FieldContainer>
            <FieldContainer title={"Description"} notAlign>
                <TextField
                    multiline
                    rows={3}
                    className={"field-control"}
                    size={"small"}
                    value={state.description}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setState(prev => ({...prev, description: event.target.value}))
                    }}
                />
            </FieldContainer>
        </DialogContent>

        <DialogActions>
            <Button
                onClick={() => {
                    props.close()
                    setState(defaultState)
                }}
            >
                Cancel
            </Button>
            <LoadingButton
                variant="contained"
                color="secondary"
                loading={props.loading}
                onClick={() => {
                    props.apply(state)
                    setState(defaultState)
                }}
            >
                Apply
            </LoadingButton>
        </DialogActions>
    </Dialog>
}

export {NewRevisionDialog}