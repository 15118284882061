import React from "react";

import {FeatureSetAutocomplete, FeatureSetAutocompleteProps} from "./FeatureSetAutocomplete";
import {FieldContainer} from "../../../../../qdep/components/app/workspace/Workspace";


interface ComponentProps {
    label: string
    autocomplete: FeatureSetAutocompleteProps
}

const FeatureSetControl = (props: ComponentProps) => {
    return <FieldContainer title={props.label} notAlign>
        <FeatureSetAutocomplete {...props.autocomplete}/>
    </FieldContainer>
}

export {FeatureSetControl}