import React from "react";

interface TabPanelProps {
    index: number;
    value: number;

    children: React.ReactElement
}

const TabPanel = (props: TabPanelProps) => {
    if (props.index !== props.value) {
        return <></>
    }
    return props.children;
}

export {TabPanel}