import {AnyAction} from "redux";

import {ProjectDto, ReportDto, ReportRevisionDto} from "../../../../app/client/app/entity/Project";
import {ProjectViewState} from "./state";
import moment from "moment";
import {
    AddReportUpdate,
    NewAddReportUpdate,
    NewAddRevisionUpdate,
    ProjectUpdate
} from "../../../../app/client/app/entity/ProjectUpdate";
import produce from "immer";
import {WritableDraft} from "immer/dist/types/types-external";
import {EntityModelRevisionRef} from "../../../../app/client/app/entity/report/model/ReportModel";


enum ProjectViewWorkspaceActions {
    INIT_ENTITY         = "INIT_ENTITY",
    OPEN_REPORT_EDITOR  = "OPEN_REPORT_EDITOR",
    CLOSE_REPORT_EDITOR = "CLOSE_REPORT_EDITOR",
    ADD_REPORT          = "ADD_REPORT",
    ADD_REPORT_REVISION = "ADD_REPORT_REVISION",
}

function initEntity(entity: ProjectDto): AnyAction {
    return {
        type: ProjectViewWorkspaceActions.INIT_ENTITY,
        entity: entity,
    }
}

function openReportEditor(): AnyAction {
    return {
        type: ProjectViewWorkspaceActions.OPEN_REPORT_EDITOR,
    }
}

function closeReportEditor(): AnyAction {
    return {
        type: ProjectViewWorkspaceActions.CLOSE_REPORT_EDITOR,
    }
}

function addReport(report: ReportDto, model: EntityModelRevisionRef | null): AnyAction {
    return {
        type: ProjectViewWorkspaceActions.ADD_REPORT,
        entity: report,
        model: model,
    }
}

function addReportRevision(reportIndex: number, file: File, model: EntityModelRevisionRef | null): AnyAction {
    return {
        type: ProjectViewWorkspaceActions.ADD_REPORT_REVISION,
        reportIndex: reportIndex,
        file: file,
        model: model
    }
}

function ProjectViewWorkspaceReducer(state: ProjectViewState, action: AnyAction): ProjectViewState {
    return produce(state, draft => {
        switch (action.type) {
            case ProjectViewWorkspaceActions.INIT_ENTITY:
                draft.entity = action.entity
                draft.updates = []
                draft.openNewReportDialog = false
                break
            case ProjectViewWorkspaceActions.OPEN_REPORT_EDITOR:
                draft.openNewReportDialog = true
                break
            case ProjectViewWorkspaceActions.CLOSE_REPORT_EDITOR:
                draft.openNewReportDialog = false
                break
            case ProjectViewWorkspaceActions.ADD_REPORT:
                addReportReducer(draft, action)
                break
            case ProjectViewWorkspaceActions.ADD_REPORT_REVISION:
                addReportRevisionReducer(draft, action)
                break
        }
        return draft
    })
}

function addReportRevisionReducer(state: WritableDraft<ProjectViewState>, action: AnyAction) {
    const model: EntityModelRevisionRef | null = action.model || null
    const report = state.entity.reports.find((item) => item.index === action.reportIndex);
    if (report !== undefined) {
        const revision: ReportRevisionDto = {
            index: report.revisions.length + 1,
            revisionNumber: ++report.revisionSeq,
            filename: (action.file as File).name,
            file: action.file,
            status: "NEW",
            createData: moment()
        }

        report.revisions.push(revision);
        state.updates.push(NewAddRevisionUpdate({
            reportIndex: action.reportIndex,
            index: report.revisions.length,
            revisionNumber: report.revisionSeq,
            filename: (action.file as File).name,

            modelId: model?.modelId,
            modelRevisionId: model?.revisionId,
        }))
        if (revision.file !== undefined) {
            state.newRevisions.push({
                project_id: state.entity.id,
                report_index: report.index,
                revision_index: report.revisions.length,
                path: `${state.entity.name}/${report.name}/${revision.file.name}`,
                file: revision.file,
                uploading: false,
                progress: 0,
            })
        }
    }
}

function addReportReducer(state: WritableDraft<ProjectViewState>, action: AnyAction) {
    const reportIndex = state.entity.reports && state.entity.reports.length > 0
        ? Math.max(...state.entity.reports.map(report => report.index)) + 1
        : 1;
    const newReport: ReportDto = {
        ...action.entity,
        index: reportIndex,
    }
    const update: ProjectUpdate<AddReportUpdate> = NewAddReportUpdate({
        index: newReport.index,
        name: newReport.name,
        description: newReport.description,
        periodType: newReport.periodType,
        periodValue: newReport.periodValue,
        filename: newReport.revisions.length > 0 ? newReport.revisions[0].filename : undefined,

        modelId: action.model?.modelId,
        modelRevisionId: action.model?.revisionId,
    });

    state.entity.reports.push(newReport);
    state.updates.push(update)
    if (newReport.revisions.length > 0 && newReport.revisions[0].file !== undefined) {
        state.newRevisions.push({
            project_id: state.entity.id,
            report_index: newReport.index,
            revision_index: 1,
            path: `${state.entity.name}/${newReport.name}/${newReport.revisions[0].file.name}`,
            file: newReport.revisions[0].file,
            uploading: false,
            progress: 0,
        })
    }
}

export {
    initEntity,
    openReportEditor, closeReportEditor, addReport,
    addReportRevision,
    ProjectViewWorkspaceReducer,
}
