import {AnyAction} from "redux";
import {ModelRefDto} from "../../../../app/client/app/entity/report/model/ReportModel";
import produce from "immer";
import {WritableDraft} from "immer/dist/types/types-external";


interface State {
    menu: MenuState
}

interface MenuState {
    selectedMenuItem: {
        isNew: boolean
        index: number | undefined
    }

    items: MenuItemState[]
}

interface MenuItemState {
    itemId: string
    itemName: string
    selected: boolean

    new: boolean
    edited: boolean
    deleted: boolean
}

const defaultState: State = {
    menu: {
        selectedMenuItem: {
            isNew: false,
            index: undefined,
        },
        items: []
    }
}

export {defaultState}
export type {
    State,
    MenuState, MenuItemState,
}

// ------------------------------------------------------------------------

const enum ActionTypes {
    MENU_SET    = "MENU_SET",
    MENU_ADD    = "MENU_ADD",
    MENU_SELECT = "MENU_SELECT",
    MENU_UPDATE = "MENU_UPDATE"
}

function setMenu(items: ModelRefDto[], selectedItem: string | undefined): AnyAction {
    return {type: ActionTypes.MENU_SET, items: items, selectedItem: selectedItem}
}

function selectMenuItem(index: number): AnyAction {
    return {type: ActionTypes.MENU_SELECT, index: index}
}

function addMenuItem(): AnyAction {
    return {type: ActionTypes.MENU_ADD}
}

function updateMenuItem(modelRef: ModelRefDto): AnyAction {
    return {type: ActionTypes.MENU_UPDATE, modelRef: modelRef}
}

// ------------------------------------------------------------------------

function pageReducer(state: State, action: AnyAction): State {
    return produce(state, draft => {
        switch (action.type) {
            case ActionTypes.MENU_SET:
                setMenuReducer(draft, action)
                break
            case ActionTypes.MENU_ADD:
                addMenuItemReducer(draft, action)
                break
            case ActionTypes.MENU_SELECT:
                selectMenuReducer(draft, action)
                break
            case ActionTypes.MENU_UPDATE:
                updateMenuItemReducer(draft, action)
                break
        }
        return draft
    })
}

function setMenuReducer(state: WritableDraft<State>, action: AnyAction) {
    const items: ModelRefDto[] | undefined = action.items;
    if (items === undefined) {
        return
    }

    state.menu.items = items.map(item => ({
        itemId: item.modelId,
        itemName: item.modelName,
        selected: false,
        new: false,
        edited: false,
        deleted: false,
    }))
    if (items.length > 0) {
        if (action.selectedItem === undefined) {
            state.menu.items[0].selected = true;
            state.menu.selectedMenuItem.index = 0
            state.menu.selectedMenuItem.isNew = false
        } else {
            const index = state.menu.items.findIndex(item => item.itemId === action.selectedItem);
            state.menu.items[index].selected = true;
            state.menu.selectedMenuItem.index = index
            state.menu.selectedMenuItem.isNew = false
        }
    }
}

function addMenuItemReducer(state: WritableDraft<State>, _: AnyAction) {
    const prevIndex = state.menu.selectedMenuItem.index;
    if (prevIndex !== undefined) {
        state.menu.items[prevIndex].selected = false
    }

    state.menu.selectedMenuItem.index = undefined
    state.menu.selectedMenuItem.isNew = true
}

function selectMenuReducer(state: WritableDraft<State>, action: AnyAction) {
    const index: number | undefined = action.index;
    if (index === undefined || index < 0 || index > state.menu.items.length) {
        return
    }

    const prevIndex = state.menu.selectedMenuItem.index;
    if (prevIndex !== undefined) {
        state.menu.items[prevIndex].selected = false
    }

    state.menu.items[index].selected = true
    state.menu.selectedMenuItem.index = index
    state.menu.selectedMenuItem.isNew = false
}

function updateMenuItemReducer(state: WritableDraft<State>, action: AnyAction) {
    const modelRef: ModelRefDto | undefined = action.modelRef;
    if (modelRef === undefined) {
        return
    }

    const itemIndex = state.menu.items.findIndex(item => item.itemId === modelRef.modelId);
    if (itemIndex >= 0) {
        state.menu.items[itemIndex].selected = true
        state.menu.items[itemIndex].edited = true
        state.menu.selectedMenuItem.index = itemIndex
    } else {
        state.menu.items.push({
            itemId: modelRef.modelId,
            itemName: modelRef.modelName,
            deleted: false,
            edited: false,
            new: true,
            selected: true
        })
        state.menu.selectedMenuItem.index = state.menu.items.length - 1
    }

    state.menu.selectedMenuItem.isNew = false;
}

export {
    pageReducer,
    setMenu, addMenuItem, selectMenuItem, updateMenuItem,
}
