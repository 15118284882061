import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {SnackbarKey, useSnackbar} from "notistack";

import {notificationRemove} from "./reducer";
import {SimpleNotification} from "./type";

import './Notifier.css'


let displayed: SnackbarKey[] = [];

interface NotifierProps {
    notificationSelector: (state: any) => SimpleNotification[]
}

const useNotifier = (props: NotifierProps) => {
    const dispatch = useDispatch();
    const notifications = useSelector(props.notificationSelector);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const storeDisplayed = (id: SnackbarKey) => {
        displayed = [...displayed, id];
    };

    const removeDisplayed = (id: SnackbarKey) => {
        displayed = [...displayed.filter(key => id !== key)];
    };

    useEffect(() => {
        notifications.forEach(notification => {
            if (displayed.includes(notification.key)) return;
            enqueueSnackbar(notification.message, {
                key:
                notification.key,
                variant: notification.variant,
                onExited: (event, snackbarKey) => {
                    dispatch(notificationRemove(notification.key))
                    removeDisplayed(notification.key);
                },
            });

            storeDisplayed(notification.key);
        })
    }, [notifications, closeSnackbar, enqueueSnackbar, dispatch])

}

export default useNotifier;