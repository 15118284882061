import React from "react";

import {Breadcrumbs, MenuItem as SelectMenuItem, Select, Switch, Typography} from "@mui/material";

import {FieldContainer} from "../../../../../qdep/components/app/workspace/Workspace";
import {ConditionNodeUpdate} from "../action";
import {EntityDependencyNode} from "../state";


interface EntityConditionEditorProps {
    entitySubItemPath: string[]
    node: EntityDependencyNode
    onChange: (updates: Partial<ConditionNodeUpdate>) => void
}

const EntityConditionEditor = (props: EntityConditionEditorProps) => {
    return <>
        <Breadcrumbs sx={{marginBottom: "24px", marginTop: "8px"}}>
            { props.entitySubItemPath.map(
                (item, index) => <Typography key={`path-item-${index}`}>{item}</Typography>
            )}
        </Breadcrumbs>
        <FieldContainer title={"Type"}>
            <Select
                className={"field-control fill"}
                size="small"
                value={props.node.conditionName}
                onChange={event => props.onChange({conditionName: event.target.value})}
            >
                <SelectMenuItem value={"SYSTEM_CONDITION_ANY"}>ANY</SelectMenuItem>
                <SelectMenuItem value={"SYSTEM_RATIO_CHECK"}>RATIO_CHECK</SelectMenuItem>
                <SelectMenuItem value={"SYSTEM_NEGATIVE_CONTEXT_VALUE"}>NEGATIVE_CONTEXT_VALUE</SelectMenuItem>
                <SelectMenuItem disabled value={"SYSTEM_CONDITION_AT_LEAST_ONE"}>AT_LEAST_ONE</SelectMenuItem>
                <SelectMenuItem disabled value={"SYSTEM_CONDITION_ONE_OF"}>ONE_OF</SelectMenuItem>
            </Select>
        </FieldContainer>
        { props.node.conditionName === "SYSTEM_RATIO_CHECK" &&
            <FieldContainer title={"Absolute Value"}>
                <Switch
                    color={"secondary"}
                    checked={props.node.absoluteValue}
                    onChange={(_, checked) => {
                        props.onChange({absoluteValue: checked})
                    }}
                />
            </FieldContainer>
        }
    </>
}

export {EntityConditionEditor}
