import React from "react";
import {AnyAction} from "redux";

import {MenuItem, TextField} from "@mui/material";

import {
    SourceRefDetectionSelectorType,
    RowRefDetectionSettings as SettingsDto, DestRefDetectionSelectorType
} from "../../../../../app/client/app/entity/report/settings/RowRefDetectionSettings";
import {updateRefDetectionSettings} from "../../editor_reducer";
import {SelectorList, SelectorListItemProps} from "../../selectors-control/SelectorList";
import {FlagControl} from "../../../../../qdep/components/app/editor/FlagControl";
import {Selector} from "../../field-control/Selector";


interface RefDetectionSettingsTabProps {
    settings: SettingsDto
    dispatch: React.Dispatch<AnyAction>
    readonly: boolean
}

const RefDetectionSettingsTab = (props: RefDetectionSettingsTabProps) => {
    return <div>
        <FlagControl
            label={"Enabled"}
            description={"Enables Explicit Ref Detection."}
            value={props.settings.enabled}
            readonly={props.readonly}
            onUpdate={value => props.dispatch(updateRefDetectionSettings({enabled: value}))}
        />
        <FlagControl
            label={"Hide Ref Columns"}
            description={"Delete the notes column if found."}
            value={props.settings.hideRefColumn}
            readonly={props.readonly}
            onUpdate={value => props.dispatch(updateRefDetectionSettings({hideRefColumn: value}))}
        />

        <SelectorList
            initValues={props.settings.selectors}
            defaultValue={{type: SourceRefDetectionSelectorType.ROW_HEADER, value: ""}}
            readonly={props.readonly}
            onChange={value => props.dispatch(updateRefDetectionSettings({selectors: value}))}
        >
            <SelectorList.Toolbar label={"Source Ref Selectors"}/>
            <SelectorList.Body itemComponent={SourceRefSelector}/>
        </SelectorList>

        <SelectorList
            initValues={props.settings.destSelectors}
            defaultValue={{type: DestRefDetectionSelectorType.TITLE_REGEXP, value: ""}}
            readonly={props.readonly}
            onChange={value => props.dispatch(updateRefDetectionSettings({destSelectors: value}))}
        >
            <SelectorList.Toolbar label={"Dest Ref Selectors"}/>
            <SelectorList.Body itemComponent={DestRefSelector}/>
        </SelectorList>
    </div>
}

const SourceRefSelector = (props: SelectorListItemProps) => {
    return <>
        <Selector
            value={props.value.data.type}
            readonly={props.readonly}
            onChange={(value: SourceRefDetectionSelectorType) => {
                props.onUpdate(props.index, {data: {type: value}})
            }}
        >
            <MenuItem value={SourceRefDetectionSelectorType.COLUMN}>COLUMN</MenuItem>
            <MenuItem value={SourceRefDetectionSelectorType.ROW_HEADER}>ROW_HEADER</MenuItem>
        </Selector>
        <TextField
            size="small"
            fullWidth
            value={props.value.data.value}
            disabled={props.readonly}
            onChange={event => {
                props.onUpdate(props.index, {data: {value: event.target.value}})
            }}
        />
    </>
}

const DestRefSelector = (props: SelectorListItemProps) => {
    return <>
        <Selector
            value={props.value.data.type}
            readonly={props.readonly}
            onChange={(value: SourceRefDetectionSelectorType) => {
                props.onUpdate(props.index, {data: {type: value}})
            }}
        >
            <MenuItem value={DestRefDetectionSelectorType.TITLE_REGEXP}>TITLE_REGEXP</MenuItem>
            <MenuItem value={DestRefDetectionSelectorType.DATA_GROUP_REGEXP}>DATA_GROUP_REGEXP</MenuItem>
        </Selector>
        <TextField
            size="small"
            fullWidth
            value={props.value.data.value}
            disabled={props.readonly}
            onChange={event => {
                props.onUpdate(props.index, {data: {value: event.target.value}})
            }}
        />
    </>
}

export {RefDetectionSettingsTab}