import React, {useState} from "react";
import {AnyAction} from "redux";


import {Badge, IconButton} from "@mui/material";
import RuleIcon from "@mui/icons-material/Rule";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";

import {WorkspaceBlock, WorkspaceBlockHeader} from "../../../../qdep/components/app/workspace/Workspace";
import {openTableContextMenu} from "./data/action";
import {Spacer} from "../../../../qdep/components/app/util/spacer/Spacer";
import {ReportOcrTable, ReportTableCellDto, Rp1CustomRule} from "../../../../app/client/app/entity/Rp1Report";
import {addCustomRule, openCustomRulePane} from "../base/report-custom-rule-pane/data/action";
import {ReportTableWorkspaceBlockBody} from "../base/report-table/ReportTableWorkspaceBlockBody";
import {TableTitleContainer} from "../base/report-table-title-container/TableTitleContainer";
import {EditTableTitleDialog} from "./title-container/EditTableTitleDialog";

import reportTableStyles from '../base/report-table/ReportTableWorkspaceBlockBody.module.css'
import styles from './ReportOcrTableBlock.module.css'


interface ReportOcrTableProps {
    table: ReportOcrTable
    controlPaneShowed: boolean
    dispatch: React.Dispatch<AnyAction>
}

const ReportOcrTableBlock = (props: ReportOcrTableProps) => {
    const [openEditTableTitleDialog, setOpenEditTableTitleDialog] = useState(false);

    return <>
        <WorkspaceBlock>
            <WorkspaceBlockHeader>
                <TableTitleContainer title={props.table.title} tableId={props.table.entityId}/>
                <Spacer/>
                <IconButton
                    onClick={() => setOpenEditTableTitleDialog(true)}
                >
                    <BorderColorRoundedIcon/>
                </IconButton>
                { props.table.modificationRuleResult.ruleResults.length > 0 &&
                    <Badge
                        color="error"
                        overlap="circular"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        badgeContent={props.table.modificationRuleResult.failedRules}
                    >
                        <IconButton onClick={() => props.dispatch(openCustomRulePane(props.table.entityId, props.table.modificationRuleResult.ruleResults))}>
                            <RuleIcon color={props.controlPaneShowed ? "secondary": undefined}/>
                        </IconButton>
                    </Badge>
                }
            </WorkspaceBlockHeader>
            <ReportTableWorkspaceBlockBody>
                { props.table.rows.map((row, rowIndex) =>
                    <tr key={`rr-${rowIndex}`} className={row.deleted ? styles.deleted_row: undefined}>
                        { row.cells
                            .map((cell, columnIndex) =>
                                cellValue(cell, rowIndex, columnIndex, props.table.entityId, props.dispatch)
                            )
                        }
                    </tr>
                )}
            </ReportTableWorkspaceBlockBody>
        </WorkspaceBlock>

        <EditTableTitleDialog
            isOpen={openEditTableTitleDialog}
            close={() => setOpenEditTableTitleDialog(false)}
            apply={(rule: Rp1CustomRule) => {
                setOpenEditTableTitleDialog(false)
                props.dispatch(addCustomRule(rule, `Table Title: "${rule.tableTitle?.title}"`))
            }}

            tableId={props.table.entityId}
            tableTitle={props.table.title}
        />
    </>
}

function cellValue(cell: ReportTableCellDto,
                   rowIndex: number, columnIndex: number,
                   tableId: string,
                   dispatch: React.Dispatch<AnyAction>
): React.ReactElement {
    let className: string | undefined = undefined
    if (cell.indent !== null && cell.indent > 0) {
        switch (cell.indent) {
            case 1:
                className = reportTableStyles.indent_1
                break
            case 2:
                className = reportTableStyles.indent_2
                break
            case 3:
                className = reportTableStyles.indent_3
                break
            default:
                className = reportTableStyles.indent_4
        }
    }

    return <td
        key={`rc-${columnIndex}`}
        className={className}
        colSpan={cell.colSpan}
        onContextMenu={(e) => {
            e.preventDefault()
            dispatch(openTableContextMenu(e.clientX - 2, e.clientY - 4, rowIndex, columnIndex, tableId))
        }}
    >
        {cell.data}
    </td>;
}

export {ReportOcrTableBlock}
