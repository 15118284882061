import React, {useEffect, useState} from "react";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, MenuItem, Select,
    SelectChangeEvent,
    Stack, Switch,
    TextField
} from "@mui/material";

import {Label} from "../../../../../qdep/components/app/util/label/Label";
import {Rp5ContextDataGroup} from "../page/reducer";
import {FieldContainer} from "../../../../../qdep/components/app/workspace/Workspace";
import {Rp5CustomRule, Rp5CustomRuleType} from "../../../../../app/client/app/entity/Rp5Report";
import {NumberInputController} from "../../../../../qdep/components/app/util/number-input/NumberInputController";

interface EditDataGroupDialogProps {
    isOpen: boolean
    close: () => void
    apply: (rule: Rp5CustomRule) => void

    tableId: string | undefined
    numRows: number

    dataGroup: Rp5ContextDataGroup | undefined
}

const defaultState: Rp5ContextDataGroup = {
    title: "UNKNOWN",
    dgId: "UNKNOWN",
    name: "UNKNOWN",
    orientation: "UNKNOWN",
    detached: false,
    startIndex: 0,
    endIndex: 0,
};

const EditDataGroupDialog = (props: EditDataGroupDialogProps) => {
    const [state, setState] = useState(defaultState);

    useEffect(() => {
        if (props.dataGroup) {
            setState({
                dgId: props.dataGroup.dgId,
                title: props.dataGroup.title,
                name: props.dataGroup.name,
                detached: props.dataGroup.detached,
                orientation: props.dataGroup.orientation,
                startIndex: props.dataGroup.startIndex,
                endIndex: props.dataGroup.endIndex - 1,
            });
        } else {
            setState(defaultState)
        }
    }, [props.dataGroup])
    
    return <Dialog open={props.isOpen}>
        <DialogTitle>
            {
                state.dgId === "NEW"
                    ? <span>Add Data Group</span>
                    : <Stack direction={"row"} alignItems={"center"} spacing={"4px"}>
                        <span>Edit Data Group:</span>
                        <Label text={state.dgId || "UNKNOWN"} variant={"info"} size={"large"}/>
                    </Stack>
            }
        </DialogTitle>
        <DialogContent sx={{minWidth: "500px"}}>

            <FieldContainer title={"Table Id"}>
                <span className={"field-control"}>
                    { props.tableId || "UNKNOWN" }
                </span>
            </FieldContainer>
            <FieldContainer title={"Orientation"}>
                {
                    state.dgId === "NEW"
                        ? <Select
                            size="small"
                            className={"field-control"}
                            value={state.orientation}
                            onChange={(event: SelectChangeEvent) => {
                                setState(prev => ({...prev, orientation: event.target.value}))
                            }}
                        >
                            <MenuItem value={"ROW"}>ROW</MenuItem>
                            <MenuItem value={"COLUMN"}>COLUMN</MenuItem>
                        </Select>
                       : <span className={"field-control"}>
                            { state.orientation || "" }
                       </span>
                }
            </FieldContainer>
            <FieldContainer title={"Title"} notAlign>
                <TextField
                    className={"field-control"}
                    size={"small"}
                    value={state.title || ""}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setState(prev => ({...prev, title: event.target.value}))
                    }}
                />
            </FieldContainer>
            <FieldContainer title={"Start Index"}>
                <NumberInputController
                    minValue={0}
                    maxValue={state.endIndex}
                    value={state.startIndex}
                    onChange={value => setState(prev => ({...prev, startIndex: value}))}
                />
            </FieldContainer>
            <FieldContainer title={"End Index"}>
                <NumberInputController
                    minValue={state.startIndex}
                    maxValue={props.numRows - 1}
                    value={state.endIndex}
                    onChange={value => setState(prev => ({...prev, endIndex: value}))}
                />
            </FieldContainer>

            <FieldContainer title={"Detached"}>
                <Switch
                    color={"secondary"}
                    checked={state.detached}
                    onChange={(_, checked) => setState(prev => ({...prev, detached: checked}))}
                />
            </FieldContainer>

        </DialogContent>
        <DialogActions>
            <Button onClick={props.close}>Cancel</Button>
            <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                    if (state.dgId === "NEW") {
                        props.apply({
                            tableId: props.tableId || "UNKNOWN",
                            type: Rp5CustomRuleType.ADD_DATA_GROUP,
                            addDataGroup: {
                                title: state.title,
                                orientation: state.orientation,
                                startIndex: state.startIndex,
                                endIndex: state.endIndex,
                                detached: state.detached,
                            }
                        });
                    } else {
                        props.apply({
                            tableId: props.tableId || "UNKNOWN",
                            type: Rp5CustomRuleType.EDIT_DATA_GROUP,
                            editDataGroup: {
                                entityId: state.dgId,
                                title: state.title,
                                orientation: state.orientation,
                                startIndex: state.startIndex,
                                endIndex: state.endIndex,
                                detached: state.detached,
                            }
                        });
                    }
                }}
            >
                Apply
            </Button>
        </DialogActions>
    </Dialog>
}

export {EditDataGroupDialog}