import React from "react";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, FormControlLabel, FormGroup,
    FormLabel,
    Radio,
    RadioGroup, Switch
} from "@mui/material";

import {EntityListSettings} from "./EntityListSettingsState";

import styles from './EntityListSettingsDialog.module.css'

interface EntityListSettingsDialogProps {
    isOpen: boolean
    close: () => void

    settings: EntityListSettings
    save: () => void

    update: (settings: EntityListSettings) => void
}

const EntityListSettingsDialog = (props: EntityListSettingsDialogProps) => {
    return <Dialog open={props.isOpen}>
        <DialogTitle>Preferences</DialogTitle>
        <DialogContent className={styles.settings_dialog_body}>
            <FormControl component="fieldset" className={styles.settings_container}>
                <FormLabel component="legend">Page size</FormLabel>
                <RadioGroup
                    value={props.settings.pageSize}
                    onChange={(event, value) => props.update({
                        ...props.settings,
                        pageSize: parseInt(value, 10),
                    })}
                >
                    <FormControlLabel value="25"  control={<Radio color="secondary" />} label="25" />
                    <FormControlLabel value="50"  control={<Radio color="secondary" />} label="50" />
                    <FormControlLabel value="100" control={<Radio color="secondary" />} label="100" />
                </RadioGroup>
            </FormControl>
            <FormControl component="fieldset" className={styles.settings_container} key={"right-column"}>
                <FormLabel component="legend">Columns</FormLabel>
                <FormGroup>
                    { Object.entries(props.settings.columns).map((item) =>
                        <FormControlLabel
                            key={`${item[0]}-column`}
                            label={item[0]}
                            control={<Switch
                                color="secondary"
                                checked={item[1]}
                                onChange={(event, value) =>
                                    props.update({
                                        ...props.settings,
                                        columns: {
                                            ...props.settings.columns,
                                            [item[0]]: value,
                                        }
                                    })
                                }
                            />}
                        />
                    )}
                </FormGroup>
            </FormControl>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.close}>Cancel</Button>
            <Button variant="contained" color="secondary" onClick={props.save}>
                Save
            </Button>
        </DialogActions>
    </Dialog>
}

export {EntityListSettingsDialog}