import {EntityListSettings} from "../../../../qdep/components/entity-list-workspace-block/EntityListSettingsState";
import {AnyAction} from "redux";

enum ProjectReportListWorkspaceActionTypes {
    UPDATE_SETTINGS = 'WORKSPACE.PROJECT_REPORT_LIST.UPDATE_SETTINGS',
    SELECT_ROW      = 'WORKSPACE.PROJECT_REPORT_LIST.SELECT_ROW',
}

function updateSettings(settings: EntityListSettings): AnyAction {
    return {
        type: ProjectReportListWorkspaceActionTypes.UPDATE_SETTINGS,
        settings: settings,
    }
}

function listSelectRow(index: number): AnyAction {
    return {
        type: ProjectReportListWorkspaceActionTypes.SELECT_ROW,
        index: index
    }
}

export {
    ProjectReportListWorkspaceActionTypes,
    updateSettings, listSelectRow,
}