import {AnyAction} from "redux";

import {ReportBaseState} from "./ReportBaseState";
import {ReportBaseActions} from "./ReportBaseActions";
import {StageStatuses} from "../../../../../../app/client/app/entity/ops/ReportStatusResult";


function reportSetStageStatus<T extends ReportBaseState>(state: T, action: AnyAction): T {
    let newState: T = {
        ...state,
        reportStageStatuses: {
            ...state.reportStageStatuses,
        }
    }

    const stages: StageStatuses | undefined = action.stages;
    if (stages !== undefined) {
        if (stages.ocr !== null) {
            newState.reportStageStatuses.ocr = stages.ocr
        }
        if (stages.normalization !== null) {
            newState.reportStageStatuses.normalization = stages.normalization
        }
        if (stages.entityDetection !== null) {
            newState.reportStageStatuses.entityDetection = stages.entityDetection
        }
        if (stages.tableAnalysis !== null) {
            newState.reportStageStatuses.tableAnalysis = stages.tableAnalysis
        }
        if (stages.tableRefs !== null) {
            newState.reportStageStatuses.tableRefs = stages.tableRefs
        }
        if (stages.summary !== null) {
            newState.reportStageStatuses.summary = stages.summary
        }
    }

    return newState;
}

function reportBaseReducer<T extends ReportBaseState>(state: T, action: AnyAction): T {
    switch (action.type) {
        case ReportBaseActions.REPORT_SET_NAME:
            return {
                ...state,
                reportName: action.name,
            }
        case ReportBaseActions.REPORT_SET_STAGE_STATUS:
            return reportSetStageStatus(state, action)
    }
    return state
}

export {reportBaseReducer}