import React, {Dispatch} from "react";
import {Link, useHistory} from "react-router-dom";

import {Button, Stack} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {MenuItemState, MenuState} from "./page_reducer";
import {AnyAction} from "redux";
import {Spacer} from "../../../../qdep/components/app/util/spacer/Spacer";
import {Label} from "../../../../qdep/components/app/util/label/Label";
import {NewItemButton} from "../../../../qdep/components/app/util/new-item-button/NewItemButton";
import {addMenuItem, selectMenuItem} from "./page_reducer";

import styles from "./menu.module.css";


type ComponentProps = MenuState & {
    dispatch: Dispatch<AnyAction>
}

const PageMenu = (props: ComponentProps) => {
    const history = useHistory();
    return <div className={styles.menu_container}>
        <div className={styles.action_container}>
            <Button
                variant={"text"}
                color={"info"}
                disableRipple
                startIcon={<ArrowBackIcon/>}
                onClick={() => history.push("/reports")}
            >
                Back
            </Button>
        </div>

        <div className={styles.menu_group_container}>
            <label>Report Models</label>
            <NewItemButton
                title={"Create a new model"}
                active={props.selectedMenuItem.isNew}
                onClick={() => props.dispatch(addMenuItem())}
            />
            <ul className={styles.menu_list}>
                { props.items.map((item, index) =>
                    <li className={styles.menu_item_container} key={`mi-${index}`}>
                        <Link to={`/report/models/${item.itemId}`}>
                            <MenuItem
                                {...item}
                                selected={item.selected}
                                onClick={() => props.dispatch(selectMenuItem(index))}
                            />
                        </Link>
                    </li>)
                }
            </ul>
        </div>
    </div>
}

interface MenuItemProps extends MenuItemState {
    onClick: () => void
}

const MenuItem = (props: MenuItemProps) => {
    const classes: string[] = [styles.menu_item]
    if (props.selected) {
        classes.push(styles.selected)
    }

    return <div
        className={classes.join(" ")}
        onClick={props.onClick}
    >
        <span>{props.itemName}</span>
        <Spacer/>
        <Stack direction={"column"} gap={"4px"}>
            { props.new && <span><Label text={"NEW"} variant={"success"} size={"small"}/></span> }
            { props.edited && <span><Label text={"EDITED"} variant={"info"} size={"small"}/></span> }
            { props.deleted && <span><Label text={"DELETED"} variant={"disabled"} size={"small"}/></span> }
        </Stack>
    </div>;
}


export {PageMenu}
