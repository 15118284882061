import {Route, Switch} from "react-router-dom";

import {Main} from "../../../qdep/components/app/main/Main";
import {ReportModelEditorPage} from "./model-editor-page/page";
import {EntityEditorPage} from "./entity-editor-page/page";


const ReportModelPage = () => {
    return <Main>
        <Switch>
            <Route exact path={"/report/models/:modelId/entity/:revisionId"}>
                <EntityEditorPage/>
            </Route>

            <Route path={["/report/models/:modelId", "/report/models"]}>
                <ReportModelEditorPage/>
            </Route>
        </Switch>
    </Main>
}

export {ReportModelPage}