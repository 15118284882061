import {useState} from "react";
import {useHistory} from "react-router-dom";

import {IconButton} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import AddLinkIcon from "@mui/icons-material/AddLink";

import {Spacer} from "../../../../qdep/components/app/util/spacer/Spacer";
import {OptionalField} from "../../../../qdep/data/util";
import {ModelRevisionRef} from "../../../../app/client/app/entity/report/model/ReportModel";
import {SelectEntityModelRef} from "./SelectEntityModelRef";
import {PRRKey} from "../../../../app/client/app/entity/Project";

import styles from './EntityModelRef.module.css'


interface ComponentProps {
    prrKey: PRRKey
    modelRevisionRef: OptionalField<ModelRevisionRef>
    onAttach: (analysisSettingsRef: ModelRevisionRef) => void
}

const EntityModelRef = (props: ComponentProps) => {
    const history = useHistory();
    const [openSelectDialog, setOpenSelectDialog] = useState(false);

    return <>
        <div className={styles.container}>
            <span className={styles.label}>Entity Model Ref:</span>
            <span className={styles.value}>
                { !!props.modelRevisionRef
                    ? `${props.modelRevisionRef.modelId} / ${props.modelRevisionRef.revisionId}`
                    : "None"
                }
            </span>
            <Spacer/>
            <IconButton
                disabled={!props.modelRevisionRef}
                onClick={() => {
                    if (!!props.modelRevisionRef) {
                        history.push(`/report/models/${props.modelRevisionRef.modelId}/entity/${props.modelRevisionRef.revisionId}`);
                    }
                }}
            >
                <LaunchIcon/>
            </IconButton>
            <IconButton
                onClick={() => setOpenSelectDialog(true)}
            >
                <AddLinkIcon/>
            </IconButton>
        </div>

        <SelectEntityModelRef
            prrKey={props.prrKey}
            currentRevisionId={props.modelRevisionRef?.revisionId}

            isOpen={openSelectDialog}
            close={() => setOpenSelectDialog(false)}
            apply={ref => {
                props.onAttach(ref)
                setOpenSelectDialog(false)
            }}
        />
    </>
}

export {EntityModelRef}