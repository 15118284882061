import {ModificationRuleData} from "./report/ModificationRule";
import {ColumnMetadata} from "./report/TableMetadata";
import {TableTitle} from "./report/TableTitle";
import {CellTag, Lexeme} from "./rp";

interface Rp3MenuItem {
    page: number
    count: number
}

interface Rp3Cell {
    colSpan: number
    cellType: string
    dataType: string
    value: string
    indent: number | null
    readonly tags: CellTag[] | null
    readonly lexemes: Lexeme[] | null
}

interface Rp3Row {
    cells: Rp3Cell[]
    rowRefs: string[] | null
}

interface Rp3Table {
    prrKey: string
    entityId: string
    page: number
    filtered: boolean
    title: TableTitle
    metadata: Rp3TableMetadata
    rows: Rp3Row[]
    insights: Rp3Insight[]
}

interface Rp3TableMetadata {
    column: ColumnMetadata
}

enum Rp3CustomRuleType {
    FILTER_TABLE     = "FILTER_TABLE",
    NOT_FILTER_TABLE = "NOT_FILTER_TABLE",

    SPLIT_TABLE  = "SPLIT_TABLE",
    MERGE_TABLES = "MERGE_TABLES",
    VERTICAL_MERGE_TABLES = "VERTICAL_MERGE_TABLES",

    MARK_COLUMN_AS_HEADER = "MARK_COLUMN_AS_HEADER",
    MARK_ROW_AS_HEADER    = "MARK_ROW_AS_HEADER",
}

interface Rp3CustomRule extends ModificationRuleData<typeof Rp3CustomRuleType> {
    rowIndex?: number | null
    columnIndex?: number | null
}

interface Rp3Insight {
    name: string
}

export type {
    Rp3MenuItem,
    Rp3Cell, Rp3Row, Rp3Table,
    Rp3CustomRule,
    Rp3Insight,
}
export {Rp3CustomRuleType}
