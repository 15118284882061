import {IndexedAndSelectableItem} from "../../../../qdep/data/util";
import {ModificationRule} from "../../../../app/client/app/entity/report/ModificationRule";

interface State {
    items: IndexedAndSelectableItem<ModificationRule>[] | undefined
    numSelected: number
}

const defaultModificationRulePaneState: State = {
    items: undefined,
    numSelected: 0,
}

export type {State}
export {defaultModificationRulePaneState}
