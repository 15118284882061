import React, {useEffect, useState} from "react";

import {Autocomplete, CircularProgress, TextField} from "@mui/material";

import {EntityModelRevisionRef} from "../../../app/client/app/entity/report/model/ReportModel";
import {apiClient} from "../../../app/client/app/client";


interface ComponentProps {
    className: string
    onChange: (model: EntityModelRevisionRef | null) => void
}

const EntityModelRefAutocomplete = (props: ComponentProps) => {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<readonly EntityModelRevisionRef[]>([]);
    const loading = open && options.length === 0;

    useEffect(() => {
        if (!loading) {
            return undefined;
        }

        apiClient.listEntityModelRefs()
            .then(response => {
                const revisionRefs = response.data;
                if (response.errorReason === undefined && revisionRefs !== undefined) {
                    setOptions(revisionRefs)
                }
            });
    }, [loading]);


    return <Autocomplete
        className={props.className}
        size="small"

        open={open}
        loading={loading}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}

        options={options}
        isOptionEqualToValue={(option, value) => option.modelId === value.modelId && option.revisionId === value.revisionId}
        getOptionLabel={(option) => `${option.modelName} / ${option.revisionName}`}
        onChange={(_, value) => {
            props.onChange(value);
        }}

        renderInput={(params) => (
            <TextField
                {...params}
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20}/> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                }}
            />
        )}
    />
}

export {EntityModelRefAutocomplete}