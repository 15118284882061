import React, {useEffect, useState} from "react";

import {TableBlockSkeleton} from "../../../base/report-tables-container/TableBlockSkeleton";
import {apiClient, ErrorReason} from "../../../../../../app/client/app/client";
import {Rp4Table} from "../../../../../../app/client/app/entity/Rp4Report";
import {PRRKey} from "../../../../../../app/client/app/entity/Project";
import {ReportTable} from "./table";
import {useAuthErrorBoundaryContext} from "../../../../../../qdep/components/app/error/UnauthorizedErrorBoundary";


interface ComponentProps {
    prrKey: PRRKey
    selectedPageNum: number
}

const TablesContainer = (props: ComponentProps) => {
    const { submitError } = useAuthErrorBoundaryContext()
    const [tables, setTables] = useState<Rp4Table[] | undefined>(undefined);
    useEffect(() => {
        setTables(undefined)
        setTimeout(() => {
            apiClient
                .getRp4ListPageTables(props.prrKey, props.selectedPageNum)
                .then((response) => {
                    if (response.errorReason === undefined && response.data !== undefined) {
                        setTables(response.data)
                    } else {
                        const error = response.errorReason || ErrorReason.UNKNOWN_ERROR
                        submitError(error)
                    }
                })
        }, 200)
    }, [props.prrKey, props.selectedPageNum, submitError])

    return <>
        { tables === undefined && <TableBlockSkeleton/> }
        { tables !== undefined &&
            tables.map((table: Rp4Table) =>
                <ReportTable
                    key={table.entityId}
                    prrKey={props.prrKey}
                    table={table}
                />
            )
        }
    </>
}

export {TablesContainer}