import React, {useEffect, useState} from "react";

import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";

import {FieldContainer} from "../../../../../qdep/components/app/workspace/Workspace";
import {Rp1ContextMenuCellDetail} from "../data/reducer";
import {Rp1CustomRule, Rp1CustomRuleType} from "../../../../../app/client/app/entity/Rp1Report";


interface EditCellValueDialogProps {
    isOpen: boolean
    close: () => void
    apply: (rule: Rp1CustomRule) => void

    tableId: string | undefined
    cellRef: Rp1ContextMenuCellDetail | undefined
}

const EditCellValueDialog = (props: EditCellValueDialogProps) => {
    const [state, setState] = useState("");
    useEffect(() => {
        setState(props.cellRef !== undefined ? props.cellRef.value : "")
    }, [props.cellRef])

    return <Dialog open={props.isOpen}>
        <DialogTitle>Edit Cell Value</DialogTitle>
        <DialogContent sx={{minWidth: "500px"}}>
            <FieldContainer title={"Table Id"}>
                <span className={"field-control"}>
                    { props.tableId || "UNKNOWN" }
                </span>
            </FieldContainer>
            <FieldContainer title={"Row"}>
                <span className={"field-control"}>
                    { props.cellRef !== undefined ? props.cellRef.row : "UNKNOWN" }
                </span>
            </FieldContainer>
            <FieldContainer title={"Column"}>
                <span className={"field-control"}>
                    { props.cellRef !== undefined ? `${props.cellRef.column} (${props.cellRef.columnIndex})` : "UNKNOWN" }
                </span>
            </FieldContainer>
            <FieldContainer title={"Value"}>
                <TextField
                    className={"field-control"}
                    size={"small"}
                    value={state || ""}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setState(event.target.value)
                    }}
                />
            </FieldContainer>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.close}>Cancel</Button>
            <Button
                variant="contained"
                color="secondary"
                disabled={props.tableId === undefined}
                onClick={() => {
                    if (props.tableId && props.cellRef) {
                        props.apply({
                            type: Rp1CustomRuleType.EDIT_CELL_VALUE,
                            tableId: props.tableId || "UNKNOWN",
                            editCell: {
                                rowIndex: props.cellRef.row,
                                columnIndex: props.cellRef.columnIndex,
                                colSpan: undefined,
                                value: state,
                            },
                        });
                    }
                }}
            >
                Apply
            </Button>
        </DialogActions>
    </Dialog>
}

export {EditCellValueDialog}