import React, {Dispatch, useMemo, useState} from "react";
import {AnyAction} from "redux";
import {Link} from "react-router-dom";

import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {AccordionSummaryProps} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {IconButton, styled} from "@mui/material";

import {MenuQueryParams, Rp4MenuEntityItem, Rp4MenuItem, RP4MenuState, Rp4PageMenuItemState} from "./data/state";
import {
    addEntityDefinition,
    addFeatureDefinition,
    selectEntityDefinition,
    selectFeatureDefinition,
    selectPage
} from "./data/action";
import {Label} from "../../../../qdep/components/app/util/label/Label";
import {Spacer} from "../../../../qdep/components/app/util/spacer/Spacer";
import {ReportMenuGroupContainer} from "../base/report-menu/ReportMenuGroupContainer";
import {
    EntityCategory,
    entityCategoryToString
} from "../../../../app/client/app/entity/report/settings/EntityDefinition";

import styles from './menu.module.css'
import {ReportMenuItem, ReportMenuItemList} from "../base/report-menu/ReportMenuItemList";
import AddIcon from "@mui/icons-material/Add";


type EntitiesMenuProps = RP4MenuState & {
    dispatch: Dispatch<AnyAction>
}

const PageMenu = (props: EntitiesMenuProps) => {
    return <>
        <EntityMenuGroups
            isNew={props.selectedMenuItem.section === "ENTITY" && props.selectedMenuItem.isNew}
            entities={props.entities}
            dispatch={props.dispatch}
            queryParams={props.queryParams}
        />

        <CollapsibleReportMenuGroupContainer
            label={"Features"}
            isNew={props.selectedMenuItem.section === "FEATURE" && props.selectedMenuItem.isNew}
            add={() => props.dispatch(addFeatureDefinition())}
        >
            <ReportMenuItemList>
                { props.features.map((item, index) =>
                    <ReportMenuItem key={`fi-${index}`}>
                        <Link to={location => {
                            let queryParams: string[] = [`feature=${item.itemId}`]
                            if (props.queryParams.has("page")) {
                                queryParams.push(`page=${props.queryParams.get("page")}`)
                            }

                            return  {
                                ...location,
                                search: "?" + queryParams.join("&")
                            }}}
                        >
                            <MenuItem
                                {...item}
                                onClick={() => props.dispatch(selectFeatureDefinition(index))}
                            />
                        </Link>
                    </ReportMenuItem>
                )}
            </ReportMenuItemList>
        </CollapsibleReportMenuGroupContainer>

        <ReportMenuGroupContainer isHighLevelGroup label={"Pages"}>
            <ReportMenuItemList>
                { props.pages.map((item, index) =>
                    <ReportMenuItem key={`fi-${index}`}>
                        <Link to={location => ({...location, search: `?page=${item.page}`})}>
                            <PageMenuItem
                                {...item}
                                onClick={() => props.dispatch(selectPage(index))}
                            />
                        </Link>
                    </ReportMenuItem>
                )}
            </ReportMenuItemList>
        </ReportMenuGroupContainer>
    </>
}

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props}/>
))(() => ({
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ExpandMoreIcon/>}
        {...props}
    />
))(() => ({
    padding: 0,
    margin: 0,

    color: '#687078',
    fontWeight: 500,
    fontSize: '1rem',

    position: 'sticky',
    top: 0,
    backgroundColor: '#fff',
    zIndex: 1,

    '& .MuiAccordionSummary-content': {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',

        padding: 0,
        margin: 0,
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
    padding: 0,
}));

interface CollapsibleReportMenuGroupContainerProps {
    label: string
    openByDefault?: boolean

    add: () => void
    isNew: boolean

    children: React.ReactNode | React.ReactNode[]
}

const CollapsibleReportMenuGroupContainer = (props: CollapsibleReportMenuGroupContainerProps) => {
    const [open, setOpen] = useState(props.openByDefault ?? true);

    return <Accordion
        expanded={open}
        onChange={(_, expanded) => setOpen(expanded)}
    >
        <AccordionSummary>
            <span>{ props.label }</span>
            <Spacer/>
            <IconButton
                color={props.isNew ? "secondary": "default"}
                onClick={(event) => {
                    event.stopPropagation();
                    props.add();
                }}
            >
                <AddIcon/>
            </IconButton>
        </AccordionSummary>
        <AccordionDetails>
            { props.children }
        </AccordionDetails>
    </Accordion>
}

interface EntityMenuGroupsProps  {
    isNew: boolean
    entities: Rp4MenuEntityItem[]
    dispatch: Dispatch<AnyAction>

    queryParams: MenuQueryParams
}

interface EntityMenuGroup {
    groupIndexOffset: number
    group: EntityCategory
    items: Rp4MenuEntityItem[]
}

const EntityMenuGroups = (props: EntityMenuGroupsProps) => {
    const entityMenuGroups = useMemo(() => {
        let currentGroupIndexOffset = 0

        return props.entities
            .reduce<EntityMenuGroup[]>((acc, entity) => {
                const group = entity.category

                if (acc.length > 0 && acc[acc.length - 1].group === group) {
                    acc[acc.length - 1].items.push(entity);
                } else {
                    acc.push({group, items: [entity], groupIndexOffset: currentGroupIndexOffset});
                }
                currentGroupIndexOffset++

                return acc
            }, [])
        },
        [props.entities]
    );

    return <CollapsibleReportMenuGroupContainer
        label={"Entities"}
        isNew={props.isNew}
        add={() => props.dispatch(addEntityDefinition())}
    >
        { entityMenuGroups.map((group, index) =>
            <ReportMenuGroupContainer
                key={`entity_group/${index}`}
                label={entityCategoryToString(group.group)}
            >
                <ReportMenuItemList>
                    { group.items.map((item, index) =>
                        <ReportMenuItem key={`ei-${index}`}>
                            <Link to={location => {
                                let queryParams: string[] = [`entity=${item.itemId}`]
                                if (props.queryParams.has("page")) {
                                    queryParams.push(`page=${props.queryParams.get("page")}`)
                                }

                                return  {
                                    ...location,
                                    search: "?" + queryParams.join("&")
                                }}}
                            >
                                <EntityMenuItem
                                    {...item}
                                    onClick={() => props.dispatch(selectEntityDefinition(group.groupIndexOffset + index))}
                                />
                            </Link>
                        </ReportMenuItem>
                    )}
                </ReportMenuItemList>
            </ReportMenuGroupContainer>
        )}
    </CollapsibleReportMenuGroupContainer>
}

interface EntityMenuItemProps extends Rp4MenuEntityItem {
    onClick: () => void
}

const EntityMenuItem = (props: EntityMenuItemProps) => {
    const containerClassName = useMemo(() => {
        const classes = [styles.entity_menu_item_container]
        if (props.selected) {
            classes.push(styles.selected)
        }
        return classes.join(" ");
    }, [props.selected]);

    return <div
        className={containerClassName}
        onClick={props.onClick}
    >
        <div className={styles.entity_menu_item_body}>
            <span className={styles.entity_menu_item_name}>{props.itemName}</span>
            <div className={styles.entity_menu_item_features}>
                { props.builtInFeatures !== null && Object.values(props.builtInFeatures).map((value, index) =>
                    <Label key={index} text={value} variant={"info"} size={"small"}/>
                )}
            </div>
        </div>
        <Spacer/>
        { props.modification === "NEW" && <span><Label text={"NEW"} variant={"success"} size={"small"}/></span> }
        { props.modification === "EDITOR" && <span><Label text={"EDITED"} variant={"info"} size={"small"}/></span> }
        { props.modification === "DELETED" && <span><Label text={"DELETED"} variant={"disabled"} size={"small"}/></span> }
    </div>
}


interface MenuItemProps extends Rp4MenuItem {
    onClick: () => void
}

const MenuItem = (props: MenuItemProps) => {
    return <div
        className={"rp4-menu-item " + (props.selected ? "selected": "")}
        onClick={props.onClick}
    >
        <span className={"rp4-menu-item-name"}>{props.itemName}</span>
        <Spacer/>
        { props.modification === "NEW" && <span><Label text={"NEW"} variant={"success"} size={"small"}/></span> }
        { props.modification === "EDITOR" && <span><Label text={"EDITED"} variant={"info"} size={"small"}/></span> }
        { props.modification === "DELETED" && <span><Label text={"DELETED"} variant={"disabled"} size={"small"}/></span> }
    </div>
}

interface PageMenuItemProps extends Rp4PageMenuItemState {
    onClick: () => void
}

const PageMenuItem = (props: PageMenuItemProps) => {
    return <div
        className={"rp4-menu-item " + (props.selected ? "selected": "")}
        onClick={props.onClick}
    >
        <span className={"rp4-menu-item-name"}>Page {props.page}</span>
        <span className={"rp4-menu-item-desc"}>{props.tables} table(s)</span>
        {
            props.entityMatches > 0 && <Label variant={"info"} text={props.entityMatches} size={"small"}/>
        }
    </div>
}

export {PageMenu}
