import React from "react";

import {Button} from "@mui/material";

import {WorkspaceBlock} from "../../../qdep/components/app/workspace/Workspace";

import './EmptyReportList.css'


interface EmptyReportListProps {
    onClick: () => void
}

const EmptyReportList = (props: EmptyReportListProps) => {
    return <WorkspaceBlock classes={"report-container-empty"}>
        <div>
            <img src={"/icon/empty_list.svg"} alt={""} width="168" height="107"/>
        </div>
        <div className={"title"}>
            There are no reports yet.
        </div>
        <Button
            variant={"contained"}
            onClick={props.onClick}
        >
            Add Report
        </Button>
    </WorkspaceBlock>
}

export {EmptyReportList}
