import React from "react";

import {Breadcrumbs, Typography} from "@mui/material";
import {VariantNodeUpdate} from "../action";
import {FeatureSetControl} from "../components/FeatureSetControl";
import {Feature} from "../../../../../app/client/app/entity/report/settings/EntityDefinition";


interface ComponentProps {
    name: string
    requiredFeatures: Feature[]
    excludedFeatures: Feature[]
    featureOptions: Feature[]
    onChange: (updates: VariantNodeUpdate) => void
}

const EntityVariantEditor = (props: ComponentProps) => {
    return <>
        <Breadcrumbs sx={{marginBottom: "24px", marginTop: "8px"}}>
            <Typography>{props.name}</Typography>
        </Breadcrumbs>
        <FeatureSetControl
            label={"Required Features"}
            autocomplete={{
                className: "fill",
                value: props.requiredFeatures,
                options: props.featureOptions,
                onChange: value => props.onChange({requiredFeatures: value}),
            }}
        />
        <FeatureSetControl
            label={"Excluded Features"}
            autocomplete={{
                className: "fill",
                value: props.excludedFeatures,
                options: props.featureOptions,
                onChange: value => props.onChange({excludedFeatures: value}),
            }}
        />
    </>
}

export {EntityVariantEditor}