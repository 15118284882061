import {EntityListSettingsState} from "../../../../qdep/components/entity-list-workspace-block/EntityListSettingsState";
import {ProjectListDto} from "../../../../app/client/app/entity/Project";
import {DataPiece} from "../../../../qdep/data/util";

interface ProjectListState extends EntityListSettingsState {
    data: DataPiece<ProjectListDto[]>
}

const defaultProjectListState: ProjectListState = {
    data: {isLoaded: false, data: []},
    settings: {
        pageSize: 25,
        columns: {
            "ID": true,
            "Name": true,
            "Description": false,
            "Status": true,
            "Reports": false,
            "Creation time": false,
            "Last updated": true,
        }
    }
}

export type {ProjectListState}
export {defaultProjectListState}
