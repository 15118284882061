import {ApiClientProps, ApiResponse, ErrorReason} from "../types";
import axios, {AxiosInstance, AxiosResponse} from "axios";


interface AuthRequestData {
    username: string
    password: string
    project: string
}

interface AuthResponseData {
    token: string
    expiredTime: number
    roles: string[]
}

interface AuthClientProps extends ApiClientProps {
    projectUuid: string
}

class AuthClient {
    readonly config: AuthClientProps
    readonly client: AxiosInstance

    constructor(props: AuthClientProps, client: AxiosInstance) {
        this.config = props
        this.client = client

        this.login = this.login.bind(this)
    }

    login(data: AuthRequestData): Promise<ApiResponse<AuthResponseData>> {
        return this.client
            .post(
                '/auth/v1alpha1/login',
                { ...data, project: this.config.projectUuid },
                {
                    baseURL: this.config.baseUrl,
                    timeout: 5000,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    validateStatus: () => true
                },
            )
            .then((response: AxiosResponse): ApiResponse<AuthResponseData> => {
                switch (response.status) {
                    case 200: {
                        const token = response.headers.token
                        const payload = JSON.parse(window.atob(token.split('.')[1]))

                        const tokenExpirationTime = payload.exp
                        const tokenRoles = payload.roles

                        return {
                            data: {
                                token: token,
                                expiredTime: tokenExpirationTime,
                                roles: tokenRoles,
                            }
                        }
                    }
                    case 401: {
                        return {
                            errorReason: ErrorReason.WRONG_USER_OR_PASS
                        }
                    }
                    default: {
                        return {
                            errorReason: ErrorReason.UNKNOWN_ERROR
                        }
                    }
                }
            })
            .catch((reason: any) => {
                console.log(reason)
                return {
                    errorReason: ErrorReason.UNKNOWN_ERROR,
                }
            })
    }
}

export const authClient = new AuthClient(
    {
        baseUrl: process.env.REACT_APP_API_BASE_URL || "http://localhost:8080",
        projectUuid: "51b550f4-895d-49f0-a58e-67aac4c08f5f",
    },
    axios.create(),
)

export { AuthClient }
export type { AuthRequestData, AuthResponseData }