import React, {useState} from "react";
import {useHotkeys} from "react-hotkeys-hook";

import {Main} from "../../../../../qdep/components/app/main/Main";
import {TocViewDialog} from "./toc/TocViewDialog";
import {useReportPageAddress} from "../useReportPageAddress";


interface ComponentProps {
    children: React.ReactNode | React.ReactNode[]
}

const ReportStagePage = (props: ComponentProps) => {
    const address = useReportPageAddress();
    const [showTocView, setShowTovView] = useState(false);
    useHotkeys('ctrl + t', () => setShowTovView(true))

    return <>
        <Main>
            { props.children }
        </Main>

        <TocViewDialog
            isOpen={showTocView}
            close={() => setShowTovView(false)}
            prrKey={address.prrKey}
        />
    </>
}

export {ReportStagePage}
