import React from "react";
import {AnyAction} from "redux";

import {Button, Stack, Tab, Tabs} from "@mui/material";

import {TabPanel} from "../../../../qdep/components/app/util/tab-panel/TabPanel";
import {ProjectReportTab} from "./ProjectReportTab";
import {ReportDto} from "../../../../app/client/app/entity/Project";
import {Spacer} from "../../../../qdep/components/app/util/spacer/Spacer";
import {openReportEditor} from "../data/reducer";


interface ViewerTabsProps {
    projectId: string
    reports: ReportDto[]

    dispatch: React.Dispatch<AnyAction>
}

const ViewerTabs = (props: ViewerTabsProps) => {
    const [tabIndex, setTabIndex] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    return <>
        <Stack direction={"row"} alignItems={"center"}>
            <Tabs value={tabIndex} onChange={handleChange} indicatorColor={"secondary"} textColor={"secondary"}>
                <Tab sx={{fontSize: "16px"}} label={"Reports"}/>
            </Tabs>
            <Spacer/>
            { tabIndex === 0 &&
                <div>
                    <Button
                        variant={"outlined"}
                        onClick={() => props.dispatch(openReportEditor())}
                    >
                        Add Report
                    </Button>
                </div>
            }
        </Stack>
        <div style={{padding: "15px 0"}}>
            <TabPanel index={0} value={tabIndex}>
                <ProjectReportTab
                    projectId={props.projectId}
                    reports={props.reports}
                    dispatch={props.dispatch}
                />
            </TabPanel>
        </div>
    </>
}

export {ViewerTabs}
