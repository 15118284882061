import React, {useState} from "react";
import {AnyAction} from "redux";

import {Divider, ListItemIcon, Menu, MenuItem} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import FunctionsIcon from '@mui/icons-material/Functions';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import LinkOffIcon from '@mui/icons-material/LinkOff';

import {closeTableContextMenu} from "../page/action";
import {EditDataGroupDialog} from "./EditDataGroupDialog";
import {Rp5ContextDataGroup, Rp5ContextMenu} from "../page/reducer";
import {Rp5CustomRuleType} from "../../../../../app/client/app/entity/Rp5Report";
import {DataGroupSubMenu} from "./DataGroupSubMenu";
import {addCustomRule} from "../../base/report-custom-rule-pane/data/action";
import {RowFormulaContextMenu} from "./RowFormulaContextMenu";


interface TableContextMenuProps {
    contextMenu: Rp5ContextMenu
    dispatch: React.Dispatch<AnyAction>
}

interface EditDataGroupDialogState {
    show: boolean
    dataGroup: Rp5ContextDataGroup | undefined
}

const TableContextMenu = (props: TableContextMenuProps) => {
    const [editDataGroupDialogState, setEditDataGroupDialogState] = useState({
        show: false,
        dataGroup: undefined
    } as EditDataGroupDialogState);

    return <>
        <Menu
            open={props.contextMenu.show}
            onClose={() => props.dispatch(closeTableContextMenu())}
            anchorReference="anchorPosition"
            anchorPosition={
                props.contextMenu.show
                    ? { top: props.contextMenu.position.y, left: props.contextMenu.position.x }
                    : undefined
            }
        >
            <RowFormulaContextMenu
                contextMenu={props.contextMenu}
                dispatch={props.dispatch}
            />
            <MenuItem
                disabled={props.contextMenu.rowTrigger === null || props.contextMenu.rowTrigger.suppressed}
                onClick={() => {
                    props.dispatch(closeTableContextMenu())
                    if (props.contextMenu.tableId !== undefined) {
                        props.dispatch(addCustomRule({
                            tableId: props.contextMenu.tableId,
                            type: Rp5CustomRuleType.SUPPRESS_ROW_SUM_TRIGGER,
                            suppressRowSumTarget: {
                                index: props.contextMenu.position.rowIndex,
                            },
                        }, `Row ${props.contextMenu.position.rowIndex}. Trigger suppression`))
                    }
                }}
            >
                <ListItemIcon>
                    <FilterAltIcon/>
                </ListItemIcon>
                Suppress Row Sum Trigger
            </MenuItem>
            <Divider/>
            <MenuItem
                onClick={() => {
                    props.dispatch(closeTableContextMenu())
                    if (props.contextMenu.tableId !== undefined) {
                        props.dispatch(addCustomRule({
                            tableId: props.contextMenu.tableId,
                            type: Rp5CustomRuleType.ADD_ROW_TRANSFORMER,
                            rowTransformer: {
                                type: "REVERSE_NUMBER_SIGN",
                                rowIndex: props.contextMenu.position.rowIndex,
                            }
                        }, ""))
                    }
                }}
            >
                <ListItemIcon>
                    <AutoFixHighIcon/>
                </ListItemIcon>
                Reverse Number Sign
            </MenuItem>
            <MenuItem
                onClick={() => {
                    props.dispatch(closeTableContextMenu())
                    if (props.contextMenu.tableId !== undefined) {
                        props.dispatch(addCustomRule({
                            tableId: props.contextMenu.tableId,
                            type: Rp5CustomRuleType.ADD_ROW_TRANSFORMER,
                            rowTransformer: {
                                type: "DETACH",
                                rowIndex: props.contextMenu.position.rowIndex,
                            }
                        }, ""))
                    }
                }}
            >
                <ListItemIcon>
                    <LinkOffIcon/>
                </ListItemIcon>
                Detach Row
            </MenuItem>
            <Divider/>
            <MenuItem
                onClick={() => {
                    props.dispatch(closeTableContextMenu())
                    if (props.contextMenu.tableId !== undefined) {
                        props.dispatch(addCustomRule({
                            tableId: props.contextMenu.tableId,
                            type: Rp5CustomRuleType.ADD_COLUMN_GROUP_SUM_TRIGGER,
                            addColumnSumTarget: {
                                columnIndex: props.contextMenu.position.columnIndex,
                                rowIndex: props.contextMenu.position.rowIndex,
                            }
                        }, `Add the sum trigger to column ${props.contextMenu.position.columnIndex}`))
                    }
                }}
            >
                <ListItemIcon>
                    <FunctionsIcon/>
                </ListItemIcon>
                Add Column Sum Trigger
            </MenuItem>
            <MenuItem
                onClick={() => {
                    props.dispatch(closeTableContextMenu())
                    if (props.contextMenu.tableId !== undefined) {
                        props.dispatch(addCustomRule({
                            tableId: props.contextMenu.tableId,
                            type: Rp5CustomRuleType.SUPPRESS_COLUMN_SUM_TRIGGER,
                            suppressColumnSumTarget: {
                                columnIndex: props.contextMenu.position.columnIndex,
                                rowIndex: props.contextMenu.position.rowIndex,
                            },
                        }, `Column ${props.contextMenu.position.columnIndex}. Trigger suppression`))
                    }
                }}
            >
                <ListItemIcon>
                    <FilterAltIcon/>
                </ListItemIcon>
                Suppress Column Sum Trigger
            </MenuItem>

            <Divider/>
            <MenuItem
                onClick={() => {
                    props.dispatch(closeTableContextMenu())
                    setEditDataGroupDialogState(prev => ({
                        ...prev,
                        show: true,
                        dataGroup: {
                            title: "",
                            dgId: "NEW",
                            name: "NEW",
                            orientation: "ROW",
                            detached: false,
                            // todo normalize row indexes
                            startIndex: props.contextMenu.position.rowIndex || 0,
                            endIndex: props.contextMenu.position.rowIndex + 1 || 1,
                        }
                    }))
                }}
            >
                <ListItemIcon>
                    <AddIcon/>
                </ListItemIcon>
                Add Data Group
            </MenuItem>

            {
                props.contextMenu.dataGroups && props.contextMenu.dataGroups
                    .map((dg, index) =>
                        <DataGroupSubMenu
                            key={`dg-${index}`}
                            dataGroup={dg}
                            onEdit={() => {
                                props.dispatch(closeTableContextMenu())
                                setEditDataGroupDialogState(prev => ({
                                    ...prev,
                                    show: true,
                                    dataGroup: dg,
                                }))
                            }}
                            onDelete={() => {
                                props.dispatch(closeTableContextMenu())
                                if (props.contextMenu.tableId !== undefined) {
                                    props.dispatch(addCustomRule({
                                        tableId: props.contextMenu.tableId,
                                        type: Rp5CustomRuleType.DELETE_DATA_GROUP,
                                        deleteDataGroup: {
                                            entityId: dg.dgId,
                                            orientation: dg.orientation,
                                        }
                                    }, `Edit ${dg.dgId}`));
                                }
                            }}
                            onDetach={() => {
                                props.dispatch(closeTableContextMenu())
                                if (props.contextMenu.tableId !== undefined) {
                                    props.dispatch(addCustomRule({
                                        tableId: props.contextMenu.tableId,
                                        type: Rp5CustomRuleType.EDIT_DATA_GROUP,
                                        editDataGroup: {
                                            entityId: dg.dgId,
                                            title: dg.title,
                                            orientation: dg.orientation,
                                            startIndex: dg.startIndex,
                                            endIndex: dg.endIndex - 1,
                                            detached: true,
                                        }
                                    }, `Edit ${dg.dgId}`));
                                }
                            }}
                            onAttach={() => {
                                props.dispatch(closeTableContextMenu())
                                if (props.contextMenu.tableId !== undefined) {
                                    props.dispatch(addCustomRule({
                                        tableId: props.contextMenu.tableId,
                                        type: Rp5CustomRuleType.EDIT_DATA_GROUP,
                                        editDataGroup: {
                                            entityId: dg.dgId,
                                            title: dg.title,
                                            orientation: dg.orientation,
                                            startIndex: dg.startIndex,
                                            endIndex: dg.endIndex - 1,
                                            detached: false,
                                        }
                                    }, `Edit ${dg.dgId}`));
                                }
                            }}
                        />
                    )
            }
        </Menu>

        <EditDataGroupDialog
            isOpen={editDataGroupDialogState.show}
            close={() => {
                setEditDataGroupDialogState(prev => ({
                    ...prev,
                    show: false,
                    dataGroup: undefined
                }))
            }}
            apply={(rule) => {
                props.dispatch(addCustomRule(rule))
                setEditDataGroupDialogState(prev => ({
                    ...prev,
                    show: false,
                    dataGroup: undefined
                }))
            }}
            tableId={props.contextMenu.tableId}
            numRows={props.contextMenu.table?.numRows || 0}
            dataGroup={editDataGroupDialogState.dataGroup}
        />
    </>
}

export {TableContextMenu}
