import {AnyAction} from "redux";
import produce from "immer";
import {WritableDraft} from "immer/dist/types/types-external";

import {Rp2Actions} from "./action";
import {defaultRp3State, Rp3CellState, Rp3MenuItemState, Rp3RowState, Rp3State, Rp3TableState} from "./state";
import {closeTableContextMenuImmerReducer, TableContextMenuState} from "../../base/context-menu/TableContextMenuState";
import {Rp3Cell, Rp3MenuItem, Rp3Table} from "../../../../../app/client/app/entity/Rp3Report";
import {RpActions} from "../../base/report-page/action";

import reportTableStyles from '../../base/report-table/ReportTableWorkspaceBlockBody.module.css'
import {TableOperation} from "../../../../../app/client/app/entity/ops/ops";
import {extractCellTags} from "../../base/util/CellTags";
import {nextPageReducer, prevPageReducer, selectPageReducer} from "../../base/report-menu/state";


interface Rp3ContextMenu extends TableContextMenuState {
    filtered: boolean
    verticalMerge: boolean
}

function rp2Reducer(state: Rp3State = defaultRp3State, action: AnyAction): Rp3State {
    return produce(state, draft => {
        switch (action.type) {
            case RpActions.NAVIGATION_PREV_PAGE:
                prevPageReducer(draft)
                break
            case RpActions.NAVIGATION_NEXT_PAGE:
                nextPageReducer(draft)
                break
            case RpActions.NAVIGATION_MENU_ITEM:
                selectPageReducer(draft, action)
                break

            case Rp2Actions.REPORT_SET_MENU_ITEMS:
                setMenuItemsReducer(draft, action)
                break
            case Rp2Actions.REPORT_SET_TABLES:
                setTablesReducer(draft, action)
                break
            case Rp2Actions.REPORT_REPLACE_TABLE:
                replaceTableReducer(draft, action)
                break
            case Rp2Actions.OPEN_CONTEXT_MENU:
                openContextMenuReducer(draft, action)
                break
            case Rp2Actions.CLOSE_CONTEXT_MENU:
                closeTableContextMenuImmerReducer(draft)
                break
        }
        return draft
    })
}

// ------------------------------------------------------------------------------------------------------

function setMenuItemsReducer(state: WritableDraft<Rp3State>, action: AnyAction) {
    const menuItems: Rp3MenuItem[] | undefined = action.menuItems;
    let pageNumber: number | undefined = action.pageNumber
    if (menuItems === undefined) {
        return
    }

    const pageStates = menuItems.map<Rp3MenuItemState>(item => ({
        ...item, selected: false
    }))

    state.menu.items = pageStates

    let selectedItem: number | undefined
    if (pageNumber !== undefined) {
        selectedItem = pageStates.findIndex(item => item.page === pageNumber)
        if (selectedItem === -1) {
            selectedItem = undefined
        }
    }
    if (selectedItem === undefined && pageStates.length > 0) {
        selectedItem = 0
        pageNumber = pageStates[0].page
    }

    if (selectedItem !== undefined) {
        state.menu.items[selectedItem].selected = true
    }

    state.menu.selectedItem = selectedItem;
    state.menu.selectedPage = pageNumber
}

function setTablesReducer(state: WritableDraft<Rp3State>, action: AnyAction) {
    state.tableWorkspace.isLoading = false
    state.tableWorkspace.tables = action.tables.map(mapRp2TableToState)
}

function replaceTableReducer(state: WritableDraft<Rp3State>, action: AnyAction) {
    const tables: TableOperation<Rp3Table>[] | undefined = action.tables
    if (tables === undefined || tables.length === 0) {
        return
    }

    state.tableWorkspace.isLoading = false;

    for (let operation of tables) {
        const tableIndex = state.tableWorkspace.tables.findIndex(table => table.entityId === operation.table.entityId);
        switch (operation.operation) {
            case "DELETE":
                if (tableIndex !== -1) {
                    state.tableWorkspace.tables.splice(tableIndex, 1);
                }
                break
            case "REPLACE":
                if (tableIndex !== -1) {
                    state.tableWorkspace.tables[tableIndex] = mapRp2TableToState(operation.table);
                }
                break
            case "SAVE":
                if (tableIndex === -1) {
                    state.tableWorkspace.tables.push(mapRp2TableToState(operation.table));
                }
                break;
        }
    }
}

function openContextMenuReducer(state: WritableDraft<Rp3State>, action: AnyAction) {
    const tableId = action.tableId;
    const table = state.tableWorkspace.tables.find(table => table.entityId === tableId);

    state.contextMenu = {
        show: true,
        position: {
            x: action.position.x,
            y: action.position.y,
            rowIndex: action.position.rowIndex,
            columnIndex: action.position.columnIndex,
        },
        tableId: undefined,
        filtered: false,
        verticalMerge: false,
    }

    if (table) {
        state.contextMenu.tableId = tableId
        state.contextMenu.filtered = table.filtered
        state.contextMenu.verticalMerge = table.verticalMergeInsight
    }
}

function mapRp2TableToState(table: Rp3Table): Rp3TableState {
    const rows: Rp3RowState[] = table.rows.map((row, rowIndex) => ({
        cells: row.cells.map<Rp3CellState>((cell, columnIndex) => ({
            className: getCellClassName(cell),
            rowIndex: rowIndex,
            rowColumnIndex: columnIndex,
            colSpan: cell.colSpan,
            value: extractCellTags(cell,
                {projectId: "unknown", reportIndex: "unknown", revisionIndex: "unknown"}
            ),
            refs: null,
        }))
    }));

    for (let i = 0; i < table.rows.length; i++) {
        rows[i].cells[0].refs = table.rows[i].rowRefs;
    }

    let verticalMergeInsight = false
    if (!!table.insights) {
        verticalMergeInsight = table.insights
            .findIndex(insight => insight.name === "VerticalMergeWithNext") !== -1
    }

    return {
        entityId: table.entityId,
        filtered: table.filtered,
        title: table.title,
        rows: rows,
        verticalMergeInsight: verticalMergeInsight
    }
}

function getCellClassName(cell: Rp3Cell): string | undefined {
    let classNames: string[] = [];
    if (cell.cellType === "HEADER") {
        classNames.push(reportTableStyles.header)

        let indent = 0
        if (cell.indent !== null && cell.indent > 0) {
            indent = cell.indent
        }

        switch (indent) {
            case 0: break
            case 1:
                classNames.push(reportTableStyles.indent_1)
                break
            case 2:
                classNames.push(reportTableStyles.indent_2)
                break
            case 3:
                classNames.push(reportTableStyles.indent_3)
                break
            default:
                classNames.push(reportTableStyles.indent_4)
        }
    } else if (cell.cellType === "DATA"
        && (cell.dataType === "NUMERIC" || cell.dataType === "NUMERIC_CURRENCY"  || cell.dataType === "NUMERIC_PERCENT" ))
    {
        classNames.push(reportTableStyles.data_numeric)
    }
    return classNames.join(" ");
}


export {rp2Reducer, defaultRp3State}
export type {Rp3ContextMenu}