import {AnyAction} from "redux";
import produce from "immer";
import {WritableDraft} from "immer/dist/types/types-external";

import {ReportOcrActions} from "./action";
import {
    closeTableContextMenuImmerReducer,
    defaultTableContextMenuState,
    TableContextMenuState,
    WithTableContextMenu
} from "../../base/context-menu/TableContextMenuState";
import {ReportOcrMenuItem, ReportOcrTable, Rp1CustomRule} from "../../../../../app/client/app/entity/Rp1Report";
import {defaultReportBaseState, ReportBaseState} from "../../base/report-workspace-header/data/ReportBaseState";
import {defaultManualModificationRules, WithCustomRules} from "../../base/report-custom-rule-pane/data/CustomRules";
import {WithReportTablesWorkspace} from "../../base/report-tables-container/state";
import {
    BaseMenuItemState,
    nextPageReducer,
    prevPageReducer,
    selectPageReducer,
    WithMenuState
} from "../../base/report-menu/state";
import {RpActions} from "../../base/report-page/action";

interface Rp1ContextMenuCellDetail {
    row: number
    column: number
    columnIndex: number
    columnSpan: number
    value: string
}

interface Rp1ContextMenu extends TableContextMenuState {
    cellRef: Rp1ContextMenuCellDetail | undefined
}

interface ReportOcrState extends
    ReportBaseState,
    WithMenuState<Rp2MenuItemState>,
    WithReportTablesWorkspace<ReportOcrTable>,
    WithCustomRules<Rp1CustomRule>,
    WithTableContextMenu<Rp1ContextMenu>
{}

type Rp2MenuItemState = BaseMenuItemState & ReportOcrMenuItem

const defaultReportOcrState: ReportOcrState = {
    ...defaultReportBaseState,

    menu: {
        items: [],
        selectedItem: undefined,
        selectedPage: undefined,
    },
    tableWorkspace: {
        isLoading: true,
        tables: [],
    },
    contextMenu: {
        ...defaultTableContextMenuState,
        tableId: undefined,
        cellRef: undefined
    },
    manualModificationRules: defaultManualModificationRules
}

function reportOcrReducer(state: ReportOcrState = defaultReportOcrState, action: AnyAction): ReportOcrState {
    return produce(state, draft => {
        switch (action.type) {
            case RpActions.NAVIGATION_PREV_PAGE:
                prevPageReducer(draft)
                break
            case RpActions.NAVIGATION_NEXT_PAGE:
                nextPageReducer(draft)
                break
            case RpActions.NAVIGATION_MENU_ITEM:
                selectPageReducer(draft, action)
                break

            case ReportOcrActions.REPORT_OCR_SET_MENU_ITEMS:
                setMenuItems(draft, action)
                break
            case ReportOcrActions.REPORT_OCR_SET_TABLES:
                setTables(draft, action)
                break
            case ReportOcrActions.OPEN_CONTEXT_MENU:
                openContextMenu(draft, action)
                break
            case ReportOcrActions.CLOSE_CONTEXT_MENU:
                closeTableContextMenuImmerReducer(draft)
                break
        }
        return draft
    })
}

function setMenuItems(state: WritableDraft<ReportOcrState>, action: AnyAction) {
    const menuItems: ReportOcrMenuItem[] | undefined = action.items;
    const pageNumber: number | undefined = action.pageNumber;

    if (menuItems === undefined) {
        return
    }

    const itemStates = menuItems.map<Rp2MenuItemState>(item => ({
        ...item,
        selected: pageNumber === item.page
    }));
    const selectedItem = itemStates.findIndex(item => item.selected);

    state.menu.items = itemStates
    state.menu.selectedItem = selectedItem === -1 ? undefined: selectedItem
    state.menu.selectedPage = pageNumber
}

function setTables(state: WritableDraft<ReportOcrState>, action: AnyAction) {
    state.tableWorkspace.isLoading = false
    state.tableWorkspace.tables = action.tables
}

function openContextMenu(state: WritableDraft<ReportOcrState>, action: AnyAction) {
    const tableId = action.tableId;
    if (tableId) {
        const table: ReportOcrTable | undefined = state.tableWorkspace.tables.find(table => table.entityId === tableId);
        if (table) {
            const row = action.position.rowIndex;
            const column = action.position.columnIndex;
            let columnIndex = 0;

            const tableRow = table.rows[row].cells;
            for (let i = 0; i < column; i++) {
                columnIndex += tableRow[i].colSpan
            }

            state.contextMenu.show = true
            state.contextMenu.position = {
                x: action.position.x,
                y: action.position.y,
                rowIndex: row,
                columnIndex: column,
            }
            state.contextMenu.tableId = tableId
            state.contextMenu.cellRef = {
                row: row,
                column: column,
                columnIndex: columnIndex,
                columnSpan: tableRow[column].colSpan,
                value: tableRow[column].data,
            }
        }
    }
}

export {reportOcrReducer, defaultReportOcrState}
export type {ReportOcrState, Rp2MenuItemState, Rp1ContextMenu, Rp1ContextMenuCellDetail}