import React from "react";

import {Skeleton} from "@mui/material";

import {
    WorkspaceBlock,
    WorkspaceBlockBody,
    WorkspaceBlockHeader
} from "../../../../qdep/components/app/workspace/Workspace";
import {Spacer} from "../../../../qdep/components/app/util/spacer/Spacer";
import {RevisionSelector} from "./revision-selector/RevisionSelector";


const ModelEditorSkeleton = () => {
    return <>
        <WorkspaceBlock>
            <WorkspaceBlockHeader>
                Info
            </WorkspaceBlockHeader>
            <WorkspaceBlockBody>
                <Skeleton/>
            </WorkspaceBlockBody>
        </WorkspaceBlock>

        <WorkspaceBlock>
            <WorkspaceBlockHeader>
                <span>Analysis Settings</span>
                <Spacer/>
                <RevisionSelector loading/>
            </WorkspaceBlockHeader>
            <WorkspaceBlockBody>
                <Skeleton/>
            </WorkspaceBlockBody>
        </WorkspaceBlock>

        <WorkspaceBlock>
            <WorkspaceBlockHeader>
                <span>Entity Settings</span>
                <Spacer/>
                <RevisionSelector loading/>
            </WorkspaceBlockHeader>
            <WorkspaceBlockBody>
                <Skeleton/>
            </WorkspaceBlockBody>
        </WorkspaceBlock>
    </>
}

export {ModelEditorSkeleton}
