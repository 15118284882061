import React, {ReactNode} from "react";
import {TableBlockSkeleton} from "./TableBlockSkeleton";
import {PageNotFoundError} from "../../../../../qdep/components/app/error/PageNotFoundError";


interface ComponentProps {
    isLoading: boolean
    children: ReactNode | ReactNode[]
}

const ReportTablesContainer = (props: ComponentProps) => {
    return <>
        { props.isLoading && <TableBlockSkeleton/> }
        { (!props.isLoading && React.Children.count(props.children) === 0) && <PageNotFoundError/> }
        { (!props.isLoading && React.Children.count(props.children) > 0) && props.children }
    </>
}

export {ReportTablesContainer}
