import React, {useEffect, useState} from "react";

import {Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {FieldContainer} from "../../../../../qdep/components/app/workspace/Workspace";
import {Rp1CustomRule, Rp1CustomRuleType} from "../../../../../app/client/app/entity/Rp1Report";
import {TableTitle} from "../../../../../app/client/app/entity/report/TableTitle";


interface EditTableTitleDialogProps {
    isOpen: boolean
    close: () => void
    apply: (rule: Rp1CustomRule) => void

    tableId: string
    tableTitle: TableTitle | null | undefined
}

const EditTableTitleDialog = (props: EditTableTitleDialogProps) => {
    const [value, setValue] = useState(props.tableTitle?.originalText || "");
    useEffect(() => {
        setValue(props.tableTitle?.originalText || "")
    }, [props.tableTitle])

    return <Dialog open={props.isOpen}>
        <DialogTitle>Edit Table Title</DialogTitle>
        <DialogContent sx={{minWidth: "500px"}}>
            <FieldContainer title={"Table Id"}>
                <span className={"field-control"}>{ props.tableId }</span>
            </FieldContainer>
            <FieldContainer title={"Value"}>
                <Autocomplete
                    freeSolo
                    fullWidth
                    forcePopupIcon={true}
                    className={"field-control"}
                    size={"small"}
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue || "");
                    }}
                    onInputChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    options={props.tableTitle?.variants || []}
                    renderInput={(params) => <TextField {...params}/>}
                />
            </FieldContainer>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.close}>Cancel</Button>
            <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                    props.apply({
                        type: Rp1CustomRuleType.SET_TABLE_TITLE,
                        tableId: props.tableId,
                        tableTitle: {title: value}
                    })
                }}
            >
                Apply
            </Button>
        </DialogActions>
    </Dialog>
}

export {EditTableTitleDialog}