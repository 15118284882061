import {Dispatch} from "redux";
import {connect} from "react-redux";

import {WorkspaceBlock} from "../../../qdep/components/app/workspace/Workspace";
import {
    EntityListBlock,
    EntityListBlockAction,
    EntityListBlockProps
} from "../../../qdep/components/entity-list-workspace-block/EntityListBlock";
import {EntityListSettings} from "../../../qdep/components/entity-list-workspace-block/EntityListSettingsState";
import {AppState} from "../../../app/state/state";
import {defaultProjectListState} from "./data/state";
import {formatDate} from "../../../qdep/components/entity-list-workspace-block/formatters";
import {projectListRequest, projectOpenOverview} from "../../../app/data/action/AppAction";
import {listSelectRow, updateSettings} from "./data/action";
import {DefaultRowFactory} from "../../../qdep/components/entity-list-workspace-block/default/RowFactory";
import {ProjectListActionGroup} from "./action/ProjectListActionGroup";
import {ProjectStatusFormatter} from "../../util/ProjectStatusFormatter";


const mapStateToProps = (state: AppState): EntityListBlockProps => {
    const workspaceState = state.projects || defaultProjectListState
    const data = workspaceState.data

    return {
        title: "Projects",
        titleDetail: data.data !== undefined ? `(${data.data.length})`: "",

        selectedRow: workspaceState.selectedRow,
        rowFactory: DefaultRowFactory,

        settings: workspaceState.settings,
        data: workspaceState.data,

        columnSpec: [
            {name: "id", title: "ID"},
            {name: "name", title: "Name"},
            {name: "description", title: "Description"},
            {name: "status", title: "Status", format: value => <ProjectStatusFormatter value={value}/>},
            {name: "reports", title: "Reports"},
            {name: "createDate", title: "Creation time", format: formatDate},
            {name: "updateDate", title: "Last updated", format: formatDate},
        ],
        actionGroup: <ProjectListActionGroup/>
    }
}

const mapDispatchToProps = (dispatch: Dispatch): EntityListBlockAction => {
    return {
        refresh: (_: number) => dispatch(projectListRequest()),
        updateSettings: (settings: EntityListSettings) => dispatch(updateSettings(settings)),
        select: (index: number) => dispatch(listSelectRow(index)),
        open: (index: number) => dispatch(projectOpenOverview(index)),
    }
}

const ProjectListBlock = connect(mapStateToProps, mapDispatchToProps)(EntityListBlock)

const ProjectListWorkspace = () => {
    return <WorkspaceBlock>
        <ProjectListBlock/>
    </WorkspaceBlock>
}

export {ProjectListWorkspace}