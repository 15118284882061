import React, {useEffect} from "react";

import {Divider, MenuItem as SelectMenuItem, Select, SelectChangeEvent, TextField} from "@mui/material";

import {ReportDto} from "../../../app/client/app/entity/Project";
import {FieldContainer} from "../../../qdep/components/app/workspace/Workspace";
import {FileUploader} from "../../../app/ui/file-uploader/FileUploader";
import {EntityModelRefAutocomplete} from "./EntityModelRefAutocomplete";
import {EntityModelRevisionRef} from "../../../app/client/app/entity/report/model/ReportModel";


interface ReportEditorBlockProps {
    projectName: string
    report: ReportDto

    updateReport: (index:number, updates: Partial<ReportDto>) => void
    updateModel: (index:number, model: EntityModelRevisionRef | null) => void
    addRevision: (reportIndex: number, file: File) => void
}

const ReportEditorBlock = (props: ReportEditorBlockProps) => {
    useEffect(() =>
        {
            let value = props.report.periodValue
            if (props.report.periodType === "Year") {
                value = "Y" + value
            }
            if (props.report.periodType === "Quarter") {
                value = "Q" + value
            }
            props.updateReport(props.report.index, {
                name: `${props.projectName} ${value}`
            })
        },
        // todo fix
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.projectName, props.report.periodType, props.report.periodValue, props.report.index]
    )
    return <div key={"editor"}>
        <FieldContainer title={"Name"}>
            <span className={"field-control"}>
                { props.report.name }
            </span>
        </FieldContainer>
        <FieldContainer title={"Description"} notAlign>
            <TextField
                className={"field-control"}
                size={"small"}
                multiline
                rows={3}
                value={props.report.description}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    props.updateReport(props.report.index, {description: event.target.value})
                }}
            />
        </FieldContainer>
        <Divider sx={{margin: "16px", maxWidth: "448px"}}/>
        <FieldContainer title={"Period Type"}>
            <Select
                className={"field-control"}
                size="small"
                value={props.report.periodType}
                onChange={(event: SelectChangeEvent) => {
                    props.updateReport(props.report.index, {periodType: event.target.value})
                }}
            >
                <SelectMenuItem value={"Year"}>Year</SelectMenuItem>
                <SelectMenuItem value={"Quarter"}>Quarter</SelectMenuItem>
                <SelectMenuItem value={"Custom"}>Custom</SelectMenuItem>
            </Select>
        </FieldContainer>
        <FieldContainer title={"Period"}>
            <TextField
                className={"field-control"}
                size={"small"}
                value={props.report.periodValue}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    props.updateReport(props.report.index, {periodValue: event.target.value})
                }}
            />
        </FieldContainer>
        <Divider sx={{margin: "16px", maxWidth: "448px"}}/>
        <FieldContainer title={"File"}>
            <FileUploader onChange={file => props.addRevision(props.report.index, file)}/>
        </FieldContainer>
        <FieldContainer title={"Entity Model"}>
            <EntityModelRefAutocomplete
                className={"field-control"}
                onChange={model => props.updateModel(props.report.index, model)}
            />
        </FieldContainer>
    </div>
}

export {ReportEditorBlock}
export type {ReportEditorBlockProps}
