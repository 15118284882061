import {defaultReportBaseState, ReportBaseState} from "../../base/report-workspace-header/data/ReportBaseState";
import {
    EntityCategory,
    EntityDefinition, Feature,
    FeatureSet,
} from "../../../../../app/client/app/entity/report/settings/EntityDefinition";
import {FeatureFlagDefinition} from "../../../../../app/client/app/entity/report/settings/FeatureDefinition";
import {Rp4PageMenuItem} from "../../../../../app/client/app/entity/Rp4Report";
import {EntityOption} from "../../../entity/entity-editor/sub-items-block/EntitySubItemEditor";
import {SelectorWarnings} from "../../../entity/entity-editor/state";
import {EntityModelRevisionRef} from "../../../../../app/client/app/entity/report/model/ReportModel";


enum Rp4WorkspaceMode {
    ENTITY_EDITOR = "ENTITY_EDITOR",
    FEATURE_EDITOR = "FEATURE_EDITOR",
    PAGE_EDITOR = "PAGE_EDITOR",
    UNKNOWN_ERROR = "UNKNOWN_ERROR",
}

interface RP4MenuState {
    selectedMenuItem: {
        isNew: boolean
        section: "ENTITY" | "FEATURE" | "PAGE" | undefined
        index: number | undefined
        page?: number
    }

    entities: Rp4MenuEntityItem[]
    features: Rp4MenuItem[]
    pages: Rp4PageMenuItemState[]

    queryParams : MenuQueryParams
}

interface Rp4MenuItem {
    itemId: string
    itemName: string
    selected: boolean

    modification: "NEW" | "EDITOR" | "DELETED" | null
}

interface Rp4MenuEntityItem extends Rp4MenuItem {
    category: EntityCategory
    builtInFeatures: FeatureSet | null
}

interface Rp4PageMenuItemState extends Rp4PageMenuItem {
    selected: boolean
}

type MenuQueryParams = Map<string, string>

interface Rp4EntityDefinitionEditorState {
    isNew: boolean
    entity: EntityDefinition
    relativeEntities: Map<string, EntityDefinition>
    selectorWarnings: SelectorWarnings[]
}

interface Rp4FeatureDefinitionEditorState {
    isNew: boolean
    feature: FeatureFlagDefinition
}

interface Rp4State extends ReportBaseState {
    mode: Rp4WorkspaceMode

    menuItems: RP4MenuState

    entityModelRef: EntityModelRevisionRef | null
    entities: EntityDefinition[]
    entityIndex: Map<string, EntityDefinition>
    features: FeatureFlagDefinition[]

    entityVariants: EntityOption[]
    featureOptions: Feature[],

    entityDefinitionEditor: Rp4EntityDefinitionEditorState
    featureDefinitionEditor: Rp4FeatureDefinitionEditorState
}

const defaultRp4State: Rp4State = {
    ...defaultReportBaseState,
    mode: Rp4WorkspaceMode.UNKNOWN_ERROR,

    menuItems: {
        selectedMenuItem: {
            isNew: true,
            section: "ENTITY",
            index: undefined,
        },
        entities: [],
        features: [],
        pages: [],
        queryParams: new Map<string, string>(),
    },

    entityModelRef: null,
    entities: [],
    entityIndex: new Map(),
    features: [],

    entityVariants: [],
    featureOptions: [],

    entityDefinitionEditor: {
        isNew: true,
        entity: {
            id: null,
            disabled: null,
            entityName: "",
            category: EntityCategory.OTHER,
            builtInFeatures: null,
            selectors: [],
            variants: [],
        },
        relativeEntities: new Map(),
        selectorWarnings: []
    },
    featureDefinitionEditor: {
        isNew: true,
        feature: {
            id: null,
            disabled: null,
            featureFlagName: "",
            prepositions: [],
            values: [],
        }
    },
}

export type {
    Rp4State,
    RP4MenuState, Rp4MenuItem, Rp4MenuEntityItem,
    Rp4EntityDefinitionEditorState,
    Rp4PageMenuItemState,
    MenuQueryParams,
}
export {defaultRp4State, Rp4WorkspaceMode}
