import {connect} from "react-redux";

import {ListItemIcon, MenuItem} from "@mui/material";
import AssessmentIcon from '@mui/icons-material/Assessment';

import {AppState} from "../../../../app/state/state";
import {Dispatch} from "redux";
import {projectOpenOverview} from "../../../../app/data/action/AppAction";


interface Props {
    selectedRow: number | undefined
}

interface Action {
    open: (index: number) => void
}

const OpenOverview = (props: Props & Action) => {
    return <MenuItem
        disabled={props.selectedRow === undefined}
        onClick={() => {
            if (props.selectedRow !== undefined) {
                props.open(props.selectedRow);
            }
        }}
    >
        <ListItemIcon>
            <AssessmentIcon/>
        </ListItemIcon>
        Open Overview
    </MenuItem>
}

const mapStateToProps = (state: AppState): Props => {
    return {
        selectedRow: state.projects.selectedRow
    };
}

const mapActions = (dispatch: Dispatch): Action => {
    return {
        open: (index: number) => dispatch(projectOpenOverview(index))
    };
}

const ConnectedOpenOverview = connect(mapStateToProps, mapActions)(OpenOverview)

export {ConnectedOpenOverview as OpenOverview}