import React from "react";

import {Rp5RowFormulaTrigger} from "../../../../../../app/client/app/entity/Rp5Report";
import {LambdaDisabledIcon} from "../../../../../../qdep/components/app/icon/LambdaDisabledIcon";
import {LambdaIcon} from "../../../../../../qdep/components/app/icon/LambdaIcon";
import {Label} from "../../../../../../qdep/components/app/util/label/Label";
import {GutterTooltip} from "../../../base/util/GutterTolltip";

import styles from './Gutter.module.css'
import {Spacer} from "../../../../../../qdep/components/app/util/spacer/Spacer";


const FormulaGutter = (gutter: Rp5RowFormulaTrigger) => {
    return <GutterTooltip
        placement={"bottom-start"}
        title={
           <div className={styles.tooltip_container}>
               <div className={styles.header_container}>
                   <label>Status:</label>
                   <Spacer/>
                   { gutter.suppressed && <Label variant={"disabled"} text={"Suppressed"}/> }
                   { !gutter.suppressed && <Label variant={"success"} text={"Active"}/> }
                   { (gutter.attached && gutter.attachedDataGroupId !== null) && <Label variant={"info"} text={gutter.attachedDataGroupId}/> }
               </div>
               { (gutter.triggerCauses !== null && gutter.triggerCauses.length > 0) &&
                   <div className={styles.value_container}>
                       <label>Trigger Cause(s):</label>
                       <ul className={styles.list_container}>
                           { gutter.triggerCauses.map((cause, index) =>
                               <li key={index}>{cause.causeType}</li>
                           )}
                       </ul>
                   </div>
               }
               { gutter.suppressed &&
                   <div className={styles.value_container}>
                       <label>Suppression Cause(s):</label>
                       <ul className={styles.list_container}>
                           <li>{ gutter.suppressionCause }</li>
                       </ul>
                   </div>
               }
           </div>
        }
    >
        <div>
            { gutter.suppressed && <LambdaDisabledIcon/>}
            { !gutter.suppressed && <LambdaIcon/>}
        </div>
    </GutterTooltip>

}

export {FormulaGutter}
