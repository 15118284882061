import React, {useEffect, useState} from "react";

import {useReportPageAddress} from "../../../base/useReportPageAddress";
import {OverviewSkeleton} from "../OverviewSkeleton";
import {Rp7OverviewIssueData} from "../../../../../../app/client/app/entity/Rp7Report";
import {
    WorkspaceBlock,
    WorkspaceBlockBody,
    WorkspaceBlockHeader
} from "../../../../../../qdep/components/app/workspace/Workspace";
import {apiClient} from "../../../../../../app/client/app/client";
import {Rp5IssueStats} from "./Rp5IssueStats";
import {Rp6IssueStats} from "./Rp6IssueStats";

import styles from "./IssueOverview.module.css";


interface IssueOverviewState {
    isLoading: boolean
    data: Rp7OverviewIssueData | undefined
}

const defaultState: IssueOverviewState = {
    isLoading: true,
    data: undefined
}

const IssueOverview = () => {
    const address = useReportPageAddress();
    const [state, setState] = useState(defaultState);

    useEffect(() => {
        setState(defaultState)
        apiClient
            .rp7GetOverviewIssueData(address.prrKey)
            .then(response => {
                if (response.errorReason === undefined && response.data !== undefined) {
                    setState({
                        isLoading: false,
                        data: response.data,
                    })
                }
            })

    }, [address.prrKey])

    return <>
        { state.isLoading && <OverviewSkeleton title={"Issue"}/> }
        { !state.isLoading &&
            <WorkspaceBlock>
                <WorkspaceBlockHeader>
                    Issue
                </WorkspaceBlockHeader>
                <WorkspaceBlockBody>
                    <div className={styles.content_block}>
                        <div className={styles.stats_block}>
                            <Rp5IssueStats
                                num={state.data?.rp5Inspections || 0}
                                numValid={state.data?.rp5InspectionsValid || 0}
                                numFailed={state.data?.rp5InspectionsFailed || 0}
                            />
                        </div>
                        <hr className={styles.divider}/>
                        <div className={styles.stats_block}>
                            <Rp6IssueStats
                                num={state.data?.rp6Inspections || 0}
                                numUnresolved={state.data?.rp6InspectionsUnresolved || 0}
                                numValid={state.data?.rp6InspectionsValid || 0}
                                numFailed={state.data?.rp6InspectionsFailed || 0}
                            />
                        </div>
                    </div>
                </WorkspaceBlockBody>
            </WorkspaceBlock>
        }
    </>
}

export {IssueOverview}