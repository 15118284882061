import {AnyAction} from "redux";
import {ExplicitCellRef, Rp6MenuItem, Rp6Table} from "../../../../../app/client/app/entity/Rp6Report";


enum Rp6Actions {
    REPORT_SET_MENU_ITEMS = "REPORT.CROSS_ANALYSIS.SET_MENU_ITEMS",
    REPORT_SET_TABLES     = "REPORT.CROSS_ANALYSIS.SET_TABLES",

    OPEN_CELL_REF_DRAWER = "REPORT.CROSS_ANALYSIS.CELL_REF_DRAWER.OPEN",
    CLOSE_CELL_REF_DRAWER = "REPORT.CROSS_ANALYSIS.CELL_REF_DRAWER.CLOSE",

    OPEN_CONTEXT_MENU  = "REPORT.CROSS_ANALYSIS.TABLE_CONTEXT_MENU.OPEN",
    CLOSE_CONTEXT_MENU = "REPORT.CROSS_ANALYSIS.TABLE_CONTEXT_MENU.CLOSE",
}

function setMenuItems(menu: Rp6MenuItem[], page: number| undefined): AnyAction {
    return {
        type: Rp6Actions.REPORT_SET_MENU_ITEMS,
        menu: menu,
        page: page,
    }
}

function setTable(tables: Rp6Table[]): AnyAction {
    return {
        type: Rp6Actions.REPORT_SET_TABLES,
        tables: tables,
    }
}

function openCellRefDrawer(ref: ExplicitCellRef): AnyAction {
    return {type: Rp6Actions.OPEN_CELL_REF_DRAWER, ref: ref}
}

function closeCellRefDrawer(): AnyAction {
    return {type: Rp6Actions.CLOSE_CELL_REF_DRAWER}
}

interface OpenContextMenuProps {
    tableId: string
    position: {
        x: number
        y: number
        columnIndex: number
        rowIndex: number
    }
    rowRefs: Set<string> | null
}

function openContextMenu(props: OpenContextMenuProps): AnyAction {
    return {type: Rp6Actions.OPEN_CONTEXT_MENU, props: props}
}

function closeContextMenu(): AnyAction {
    return {type: Rp6Actions.CLOSE_CONTEXT_MENU}
}

export {
    Rp6Actions,
    setMenuItems, setTable,
    openCellRefDrawer, closeCellRefDrawer,
    openContextMenu, closeContextMenu,
}
export type {
    OpenContextMenuProps
}
