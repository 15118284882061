import React, {Dispatch, useMemo} from "react";
import {AnyAction} from "redux";

import {MenuItem as SelectMenuItem, MenuItem, TextField} from "@mui/material";

import {updateDataGroupSettings, updateDevSettings} from "../../editor_reducer";
import {FlagControl} from "../../../../../qdep/components/app/editor/FlagControl";
import {DevSettings} from "../../../../../app/client/app/entity/report/settings/DevSettings";
import {NumberInputController} from "../../../../../qdep/components/app/util/number-input/NumberInputController";
import {FieldContainer} from "../../../../../qdep/components/app/workspace/Workspace";
import {Spacer} from "../../../../../qdep/components/app/util/spacer/Spacer";
import {SelectorList, SelectorListItemProps} from "../../selectors-control/SelectorList";
import {Selector} from "../../field-control/Selector";

import tabBodyClasses from "../TabBody.module.css";


interface ComponentProps {
    greedyAlgorithm: boolean
    unattachedDataGroups: boolean
    devSettings: DevSettings

    dispatch: Dispatch<AnyAction>
    readonly: boolean
}

const DevSettingsTab = (props: ComponentProps) => {
    const titleHeightErrorPercent = useMemo(
        () => {
            let value = props.devSettings.titleHeightErrorPercent ?? 15
            return (value / 10).toFixed(1) + "%"
        },
        [props.devSettings.titleHeightErrorPercent]
    );

    return <div className={tabBodyClasses.settings_group_container}>
        <div>
            <FlagControl
                label={"Greedy Algorithm"}
                description={"Enables Greedy Algorithms for the Heuristic Data Groups."}
                value={props.greedyAlgorithm}
                readonly={props.readonly}
                onUpdate={value => props.dispatch(updateDataGroupSettings({enableGreedyAlgorithm: value}))}
            />
            <FlagControl
                label={"Unattached Data Group"}
                description={"Enables find and create data groups for the unattached row triggers."}
                value={props.unattachedDataGroups}
                readonly={props.readonly}
                onUpdate={value => props.dispatch(updateDataGroupSettings({enableUnattachedDataGroups: value}))}
            />
            <FlagControl
                disabled
                label={"Plain mode (Date Range)"}
                description={"Enables the plain mode instead of  the hierarchical mode for Date Range Data Groups."}
                value={false}
                readonly={props.readonly}
                onUpdate={() => {}}
            />
            <FlagControl
                disabled
                label={"Date Sequence Check (Date Range)"}
                description={
                    "Enables Date Sequence Check for the Date Range Data Groups. " +
                    "The check groups ascending sequences of dates into one structure. " +
                    "Required 'Header Date Formats'."
                }
                value={true}
                readonly={props.readonly}
                onUpdate={() => {}}
            />
            <FlagControl
                label={"Strict Entity Dependencies"}
                description={"Disable the auto creation of a data group for entities without explicit dependencies."}
                value={props.devSettings.strictEntityDependencies}
                readonly={props.readonly}
                onUpdate={value => props.dispatch(updateDevSettings({strictEntityDependencies: value}))}
            />
            <FlagControl
                label={"Strict Root Entity Dependencies"}
                description={"Disable the auto creation of a data group for root entities without explicit dependencies."}
                value={props.devSettings.strictRootEntityDependencies}
                readonly={props.readonly}
                onUpdate={value => props.dispatch(updateDevSettings({strictRootEntityDependencies: value}))}
            />
            <FieldContainer title={"Entity Engine Version"}>
                <Spacer/>
                <Selector
                    value={props.devSettings.entityEngineVersion}
                    readonly={props.readonly}
                    minWidth={"200px"}
                    onChange={value => props.dispatch(updateDevSettings({entityEngineVersion: value}))}
                >
                    <SelectMenuItem value={"EnglishV1"}>English V1</SelectMenuItem>
                    <SelectMenuItem value={"EnglishV2"}>English V2</SelectMenuItem>
                    <SelectMenuItem value={"EnglishV3"} disabled>English V3</SelectMenuItem>
                    <SelectMenuItem value={"GermanV1"}>German V1</SelectMenuItem>
                </Selector>
            </FieldContainer>
        </div>

        <div>
            <div className={tabBodyClasses.group_title}>Page Segmentation</div>
            <FlagControl
                disabled
                label={"Page Segmentation"}
                description={
                    "Clustering of page lines into paragraphs by line height and margins. " +
                    "Improves table title filtering and title selections."
                }
                value={true}
                readonly={props.readonly}
                onUpdate={() => {}}
            />
            <FieldContainer title={"Line Height Error"}>
                <>
                    <Spacer/>
                    <NumberInputController
                        minValue={1}
                        formattedValue={titleHeightErrorPercent}
                        value={props.devSettings.titleHeightErrorPercent || 15}
                        onChange={value => props.dispatch(updateDevSettings({titleHeightErrorPercent: value}))}
                    />
                </>
            </FieldContainer>
            <FlagControl
                label={"Detect Page Header/Footer"}
                value={props.devSettings.enablePageHeaderAndFooterFiltering}
                readonly={props.readonly}
                onUpdate={value => props.dispatch(updateDevSettings({enablePageHeaderAndFooterFiltering: value}))}
            />

            <SelectorList
                initValues={props.devSettings.pageHeaderSelectors}
                defaultValue={{equal: ""}}
                readonly={props.readonly}
                onChange={value => props.dispatch(updateDevSettings({pageHeaderSelectors: value}))}
            >
                <SelectorList.Toolbar label={"Page Header Selectors"}/>
                <SelectorList.Body itemComponent={PageHeaderSelector}/>
            </SelectorList>
        </div>
    </div>
}

const PageHeaderSelector = (props: SelectorListItemProps) => {
    return <>
        <Selector
            value={"EQUAL"}
            readonly={props.readonly}
            onChange={() => {}}
        >
            <MenuItem value={"EQUAL"}>EQUAL</MenuItem>
        </Selector>
        <TextField
            size="small"
            fullWidth
            value={props.value.data.equal}
            disabled={props.readonly}
            onChange={event => {
                props.onUpdate(props.index, {data: {equal: event.target.value}})
            }}
        />
    </>
}

export {DevSettingsTab}
