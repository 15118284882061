import {Period} from "./report/TableMetadata";
import {Rp5Cell, Rp5FeatureSet} from "./Rp5Report";
import {Rp3Row} from "./Rp3Report";
import {TableTitle} from "./report/TableTitle";
import {ModificationRuleData} from "./report/ModificationRule";

interface Rp6MenuItem {
    page: number
    tables: number
    refs: number
}

interface Rp6Table {
    prrKey: string
    entityId: string
    title: TableTitle
    page: number

    rows: Rp3Row[]
    explicitRefs: ExplicitCellRef[] | null
}

interface ExplicitCellRef {
    ref: RefPosition

    source: SourceCell
    destTable: Record<string, TableMatchRef> | null

    resolved: boolean
    valid: boolean
    suppressed: boolean
}

interface SourceCell {
    tableId: string

    rowContext: SourceContext
    columnContext: SourceContext
    cellContext: SourceCellContext

    cell: Rp5Cell
}

interface SourceContext {
    period: Period
    features: Rp5FeatureSet | null
    header: string
}

interface SourceCellContext {
    period: Period
    features: Rp5FeatureSet
}

interface RefPosition {
    rowIndex: number
    refName: string
}

interface TableMatchRef {
    tableId: string
    page: number
    dest: DestRef | null
    valid: boolean
}

interface DestRef {
    type: string
    direct: CellRef | null
    virtual: CellRef[] | null
    possibleVariants: CellRef[] | null
}

interface CellRef {
    tableId: string
    period: Period
    cell: Rp5Cell
}

enum Rp6ModificationRuleType {
    SUPPRESS_CELL_REF = "SUPPRESS_CELL_REF",
    SUPPRESS_CELL_REF_BY_COLUMN = "SUPPRESS_CELL_REF_BY_COLUMN",

    ADD_REF    = "ADD_REF",
    EDIT_REF   = "EDIT_REF",
    DELETE_REF = "DELETE_REF",
}

interface Rp6ModificationRule extends ModificationRuleData<typeof Rp6ModificationRuleType> {
    suppressCellRefs?: {
        columnIndex: number | null
        rowIndex: number | null
    },
    deleteRef?: RefPosition
    addRef?: RefPosition
    editRef?: {
        rowIndex: number
        refName: string
        newRefName: string
    }
}

export {Rp6ModificationRuleType}
export type {
    Rp6MenuItem,
    Rp6Table,
    ExplicitCellRef, TableMatchRef, DestRef, CellRef, RefPosition,
    SourceCell, SourceContext, SourceCellContext,
    Rp6ModificationRule,
}
