type ProjectUpdates = ProjectUpdate<any>[]

enum ProjectUpdateType {
    ADD_REPORT          = "ADD_REPORT",
    ADD_REPORT_REVISION = "ADD_REPORT_REVISION",
}

interface ProjectUpdate<T> {
    type: ProjectUpdateType
    payload: T
}

interface AddReportRevisionUpdate {
    reportIndex: number
    index: number
    revisionNumber: number
    filename: string

    modelId: string | undefined
    modelRevisionId: string | undefined
}

interface AddReportUpdate {
    // new report props
    index: number
    name: string
    description: string
    periodType: string
    periodValue: string
    // new revision props
    filename: string | undefined

    modelId: string | undefined
    modelRevisionId: string | undefined
}

function NewAddRevisionUpdate(revision: AddReportRevisionUpdate): ProjectUpdate<AddReportRevisionUpdate> {
    return {
        type: ProjectUpdateType.ADD_REPORT_REVISION,
        payload: revision,
    }
}

function NewAddReportUpdate(report: AddReportUpdate): ProjectUpdate<AddReportUpdate> {
    return {
        type: ProjectUpdateType.ADD_REPORT,
        payload: report,
    }
}

export {
    NewAddRevisionUpdate, NewAddReportUpdate
}
export type {
    ProjectUpdates,
    ProjectUpdate,
    AddReportUpdate,
}
