import React, {Dispatch} from "react";
import {AnyAction} from "redux";

import {Divider} from "@mui/material";

import {applyEntityDefinitionChanges, applyFeatureDefinitionChanges} from "./data/action";
import {Rp4State, Rp4WorkspaceMode} from "./data/state";
import {PRRKey} from "../../../../app/client/app/entity/Project";
import {setReportStageStatus} from "../base/report-workspace-header/data/ReportBaseActions";
import {ErrorBoundary} from "./component/ErrorBoundary";
import {EntityDefinitionEditor} from "./component/entity-definition-eidtor/EntityDefinitionEditor";
import {FeatureFlagDefinitionEditor} from "./component/feature-flag-editor/FeatureFlagDefinitionEditor";
import {TablesContainer} from "./component/report-table/tables-container";
import {ReportWorkspaceHeader} from "../base/report-workspace-header/ReportWorkspaceHeader";
import {StageNames} from "../base/StageNames";
import {ReportWorkspace} from "../base/report-workspace/ReportWorkspace";
import {HeaderControlGroup} from "./component/header-control-group/HeaderControlGroup";

import styles from './page.module.css'


interface WorkspaceBodyProps {
    prrKey: PRRKey
    state: Rp4State
    dispatch: Dispatch<AnyAction>
}

const WorkspaceBody = (props: WorkspaceBodyProps) => {
    return <ErrorBoundary
        mode={props.state.mode}
        entityId={props.state.entityDefinitionEditor.entity.id}
        featureId={props.state.featureDefinitionEditor.feature.id}
    >
        { props.state.mode === Rp4WorkspaceMode.ENTITY_EDITOR &&
            <ReportWorkspace alignCenter>
                <div className={styles.workspace_wrapper}>
                    <ReportWorkspaceHeader
                        prrKey={props.prrKey}
                        reportName={props.state.reportName}
                        reportStage={StageNames.ENTITY_DETECTION}
                        reportPage={props.state.menuItems.queryParams.get("page")}
                        reportStageStatuses={props.state.reportStageStatuses}
                        updateReportStatuses={stages => props.dispatch(setReportStageStatus(stages))}
                        numCustomRuleUpdates={0}
                        disableSettings
                    >
                        <HeaderControlGroup
                            type={"ENTITY"}
                            prrKey={props.prrKey}
                            definitionId={props.state.entityDefinitionEditor.entity.id}
                            enabled={!(props.state.entityDefinitionEditor.entity.disabled || false)}
                            entityModelRef={props.state.entityModelRef}
                            dispatch={props.dispatch}
                        />
                        <Divider orientation={"vertical"} flexItem sx={{margin: "6px 4px"}}/>
                    </ReportWorkspaceHeader>
                    <EntityDefinitionEditor
                        prrKey={props.prrKey}
                        isNew={props.state.entityDefinitionEditor.isNew}
                        entityVariants={props.state.entityVariants}
                        featureOptions={props.state.featureOptions}
                        entityDefinition={props.state.entityDefinitionEditor.entity}
                        relativeEntities={props.state.entityDefinitionEditor.relativeEntities}
                        selectorWarnings={props.state.entityDefinitionEditor.selectorWarnings}
                        apply={value => props.dispatch(applyEntityDefinitionChanges(value))}
                        updateReportStatuses={stages => props.dispatch(setReportStageStatus(stages))}
                    />
                </div>
            </ReportWorkspace>
        }
        { props.state.mode === Rp4WorkspaceMode.FEATURE_EDITOR &&
            <ReportWorkspace alignCenter>
                <div className={styles.workspace_wrapper}>
                    <ReportWorkspaceHeader
                        prrKey={props.prrKey}
                        reportName={props.state.reportName}
                        reportStage={StageNames.ENTITY_DETECTION}
                        reportPage={props.state.menuItems.queryParams.get("page")}
                        reportStageStatuses={props.state.reportStageStatuses}
                        updateReportStatuses={stages => props.dispatch(setReportStageStatus(stages))}
                        numCustomRuleUpdates={0}
                        disableSettings
                    >
                        <HeaderControlGroup
                            type={"FEATURE"}
                            prrKey={props.prrKey}
                            definitionId={props.state.featureDefinitionEditor.feature.id}
                            enabled={!(props.state.featureDefinitionEditor.feature.disabled || false)}
                            entityModelRef={props.state.entityModelRef}
                            dispatch={props.dispatch}
                        />
                        <Divider orientation={"vertical"} flexItem sx={{margin: "6px 4px"}}/>
                    </ReportWorkspaceHeader>
                    <FeatureFlagDefinitionEditor
                        prrKey={props.prrKey}
                        isNew={props.state.featureDefinitionEditor.isNew}
                        feature={props.state.featureDefinitionEditor.feature}
                        apply={feature => props.dispatch(applyFeatureDefinitionChanges(feature))}
                        updateReportStatuses={stages => props.dispatch(setReportStageStatus(stages))}
                    />
                </div>
            </ReportWorkspace>
        }
        {  props.state.mode === Rp4WorkspaceMode.PAGE_EDITOR &&
            <ReportWorkspace>
                <ReportWorkspaceHeader
                    prrKey={props.prrKey}
                    reportName={props.state.reportName}
                    reportStage={StageNames.ENTITY_DETECTION}
                    reportPage={props.state.menuItems.queryParams.get("page")}
                    reportStageStatuses={props.state.reportStageStatuses}
                    updateReportStatuses={stages => props.dispatch(setReportStageStatus(stages))}
                    numCustomRuleUpdates={0}
                />
                <TablesContainer
                    prrKey={props.prrKey}
                    selectedPageNum={props.state.menuItems.selectedMenuItem.page || props.state.menuItems.pages[0].page}
                />
            </ReportWorkspace>
        }
    </ErrorBoundary>
}

export {WorkspaceBody}