import moment from "moment";


interface ProjectListDto {
    id: string
    name: string
    description: string

    status: string
    reports: number

    createDate: moment.Moment
    updateDate: moment.Moment
}

interface ProjectDto {
    id: string
    name: string
    description: string
    roles: string[]
    status: string

    reports: ReportDto[]

    createDate: moment.Moment
    updateDate: moment.Moment
}

interface ReportDto {
    index: number
    name: string
    description: string

    periodType: string   // year | quarter | custom
    periodValue: string

    revisions: ReportRevisionDto[]

    revisionSeq: number
}

interface ReportRevisionDto {
    index: number

    revisionNumber: number
    filename: string

    file?: File
    modelId?: string
    modelRevisionId?: string

    status: string // NEW | UPLOADED | PROCESSING | ANALYZED | ERROR | SYSTEM_ERROR | OUTDATED

    createData: moment.Moment
}

interface PRRKey {
    projectId: string
    reportIndex: number | string
    revisionIndex: number | string
}

function parsePRRKey(value: string): PRRKey {
    const parts = value.split("-");
    return {
        projectId: parts[0],
        reportIndex: parts[1],
        revisionIndex: parts[2],
    }
}

function NewProjectDto(roles: string[]): ProjectDto {
    return {
        id: "",
        name: "",
        description: "",
        roles: roles,
        status: "NEW",

        reports: [],
        createDate: moment(),
        updateDate: moment(),
    }
}

function NewReportDto(index: number): ReportDto {
    return {
        index: index,
        name: "",
        description: "",

        periodType: "",
        periodValue: "",

        revisions: [],

        revisionSeq: 0,
    }
}

export {
    NewProjectDto,
    NewReportDto,
    parsePRRKey
}
export type {
    ProjectListDto,
    ProjectDto,
    ReportDto,
    ReportRevisionDto,
    PRRKey,
}