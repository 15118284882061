import React, {Dispatch} from "react";
import {AnyAction} from "redux";

import {MenuItem, TextField} from "@mui/material";

import {
    ColumnRuleSettings,
    ColumnTriggerSelectorType
} from "../../../../../app/client/app/entity/report/settings/ColumnRuleSettings";
import {FlagControl} from "../../../../../qdep/components/app/editor/FlagControl";
import {updateColumnRuleSettings} from "../../editor_reducer";
import {SelectorList, SelectorListItemProps} from "../../selectors-control/SelectorList";
import {Selector} from "../../field-control/Selector";


interface ColumnRuleSettingsTabProps {
    settings: ColumnRuleSettings
    dispatch: Dispatch<AnyAction>
    readonly: boolean
}

const ColumnRuleSettingsTab = (props: ColumnRuleSettingsTabProps) => {
    return <div>
        <FlagControl
            label={"Enabled"}
            value={props.settings.enabled}
            readonly={props.readonly}
            onUpdate={value => props.dispatch(updateColumnRuleSettings({enabled: value}))}
        />

        <SelectorList
            initValues={props.settings.selectors}
            defaultValue={{type: ColumnTriggerSelectorType.REGEXP, value: ""}}
            readonly={props.readonly}
            onChange={value => props.dispatch(updateColumnRuleSettings({selectors: value}))}
        >
            <SelectorList.Toolbar label={"Selectors"}/>
            <SelectorList.Body itemComponent={ColumnTriggerSelectorEditor}/>
        </SelectorList>
    </div>
}

const ColumnTriggerSelectorEditor = (props: SelectorListItemProps) => {
    return <>
        <Selector
            value={props.value.data.type}
            readonly={props.readonly}
            onChange={(value: ColumnTriggerSelectorType) => {
                props.onUpdate(props.index, {data: {type: value}})
            }}
        >
            <MenuItem value={ColumnTriggerSelectorType.REGEXP}>REGEXP</MenuItem>
            <MenuItem value={ColumnTriggerSelectorType.EQUAL}>EQUAL</MenuItem>
            <MenuItem value={ColumnTriggerSelectorType.STARTS_WITH}>STARTS_WITH</MenuItem>
            <MenuItem value={ColumnTriggerSelectorType.ENDS_WITH}>ENDS_WITH</MenuItem>
            <MenuItem value={ColumnTriggerSelectorType.CONTAINS}>CONTAINS</MenuItem>
        </Selector>
        <TextField
            size="small"
            fullWidth
            value={props.value.data.value}
            disabled={props.readonly}
            onChange={event => {
                props.onUpdate(props.index, {
                    data: {
                        value: event.target.value,
                    }
                })
            }}
        />
    </>
}

export {ColumnRuleSettingsTab}