import { createTheme } from '@mui/material/styles';
import type {} from '@mui/lab/themeAugmentation';

const muiStandardTheme = createTheme({
    palette: {
        primary: {
            main: '#687078',
            contrastText: '#fff',
        },
        secondary: {
            main: '#ec7211',
        },
        error: {
            contrastText: '#D8000C',
            main: '#FFD2D2',
        },
        warning: {
            main: 'rgba(255, 136, 0, 0.20)',
            contrastText: '#ff8800',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "capitalize",
                },
            }
        },
        MuiSwitch: {
            styleOverrides: {
                switchBase: {
                    color: '#687078',
                },
            }
        },
        MuiTimeline: {
            styleOverrides: {
                root: {
                    margin: 0,
                    padding: "0 16px",
                }
            }
        },
        MuiTimelineItem: {
            styleOverrides: {
                root: {
                    '&::before': {
                        flex: 0,
                        padding: 0,
                    },
                }
            }
        },
        MuiTimelineContent: {
            styleOverrides: {
                root: {
                    padding: "0 16px"
                }
            }
        },
        MuiTimelineSeparator: {
            styleOverrides: {
                root: {
                    paddingTop: 4,
                }
            }
        }
    }
});

export {muiStandardTheme}
