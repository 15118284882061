import React from "react";

import {styled, Tooltip, tooltipClasses, TooltipProps} from "@mui/material";

import {Rp5TableRule} from "../../../../../app/client/app/entity/Rp5Report";
import {Label} from "../../../../../qdep/components/app/util/label/Label";

import styles from './FormulaTargetTooltip.module.css'


interface ComponentProps {
    inspections: Rp5TableRule[]
    children: React.ReactElement
}

const FormulaTargetTooltip = (props: ComponentProps) => {
    return <FormulaTooltip
        placement={"right-start"}
        title={
            <div className={styles.inspections_container}>
                { props.inspections.map((inspection, index) =>
                    <InspectionDescription key={inspection.ruleId} number={index + 1} {...inspection}/>
                )}
            </div>
        }
    >
        { props.children }
    </FormulaTooltip>
}

const FormulaTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'unset',
        border: 'unset',
    },
}));

interface InspectionDescriptionProps extends Rp5TableRule {
    number: number
}

const InspectionDescription = (inspection: InspectionDescriptionProps) => {
    const isEADFormat = inspection.expectedValue !== null &&
        inspection.actualValue !== null &&
        inspection.diffValue !== null

    return <div className={styles.inspection_container}>
        <div className={styles.inspection_header_container}>
            <span>{inspection.number} Inspection </span>
            { inspection.isPassed && <Label variant={"success"} text={"PASSED"} size={"small"}/> }
            { !inspection.isPassed && <Label variant={"error"} text={"FAILED"} size={"small"}/> }
        </div>
        <div className={styles.inspection_body_container}>
            <div>
                <label>Type: </label>
                <span>{inspection.name}</span>
            </div>

            { (isEADFormat || inspection.description !== "") &&
                <div className={styles.inspection_description_container}>
                    <label>Description:</label>
                    { !isEADFormat && <span>{ inspection.description }</span> }
                    { isEADFormat &&
                        <ul>
                           <li>
                               <span className={styles.aed_item_label}>Expected:</span>
                               <span className={styles.aed_item_value}>{inspection.expectedValue}</span>
                           </li>
                           <li>
                               <span className={styles.aed_item_label}>Actual:</span>
                               <span className={styles.aed_item_value}>{inspection.actualValue}</span>
                           </li>
                           <li>
                               <span className={styles.aed_item_label}>Diff:</span>
                               <span className={styles.aed_item_value}>{inspection.diffValue}</span>
                           </li>
                        </ul>
                    }
                </div>
            }
        </div>
    </div>
}

export {FormulaTargetTooltip}