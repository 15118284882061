import {AnyAction} from "redux";
import produce from "immer";
import {WritableDraft} from "immer/dist/types/types-external";

import {TableState} from "./state";
import {
    updateDataGroupView,
    CellRef,
    mapRp5Table,
    updateRuleRefs,
    updateValidationRuleRefs
} from "./IntermediateTable";
import {ReportFeatures} from "../../base/report-workspace-header/data/ReportFeatures";
import {Rp5Table, Rp5TableRule} from "../../../../../app/client/app/entity/Rp5Report";
import {SelectableItem} from "../../../../../qdep/data/util";


// ------------- action ---------------
const enum TableActionTypes {
    SET_DATA = "SET_DATA",

    SELECT_DG = "SELECT_DG",

    CLOSE_RULE_PANE  = "CLOSE_RULE_PANE",
    SELECT_RULE      = "SELECT_RULE",
    SELECT_ALL_RULES = "SELECT_ALL_RULES",
}

function setData(table: Rp5Table, reportFeatures: ReportFeatures): AnyAction {
    return {
        type: TableActionTypes.SET_DATA,
        table: table,
        features: reportFeatures,
    }
}

function selectDataGroup(dgId: string, dkKeyIndex: number, value: boolean): AnyAction {
    return {
        type: TableActionTypes.SELECT_DG,
        id: dgId,
        index: dkKeyIndex,
        value: value
    }
}

function closeRulePane(): AnyAction {
    return {type: TableActionTypes.CLOSE_RULE_PANE}
}

function selectRule(ruleIds: string[]): AnyAction {
    return {
        type: TableActionTypes.SELECT_RULE,
        ids: ruleIds,
    }
}

function selectAllRules(): AnyAction {
    return {type: TableActionTypes.SELECT_ALL_RULES}
}

// ------------- reducer ---------------
function tableReducer(state: TableState, action: AnyAction): TableState {
    return produce(state, draft => {
        switch (action.type) {
            case TableActionTypes.SET_DATA:
                setTableReducer(draft, action);
                break;
            case TableActionTypes.SELECT_DG:
                selectDataGroupReducer(draft, action);
                break;
            case TableActionTypes.CLOSE_RULE_PANE:
                closeRulePaneReducer(draft, action)
                break
            case TableActionTypes.SELECT_RULE:
                selectRuleReducer(draft, action);
                break;
            case TableActionTypes.SELECT_ALL_RULES:
                selectAllRulesReducer(draft, action);
                break;
        }
        return draft
    })
}

function setTableReducer(state: WritableDraft<TableState>, action: AnyAction) {
    const table: Rp5Table = action.table;

    state.table = table
    state.renderTable = mapRp5Table(table, action.features)
    state.reportFeatures = action.features

    const rules = new Map<string, SelectableItem<Rp5TableRule>>();
    for (let rule of table.rules) {
        rules.set(rule.ruleId, {
            isSelected: false,
            data: rule,
        })
    }
    state.rulesPane.show = false
    state.rulesPane.rules = rules
    state.rulesPane.numRule = table.rules.length
    state.rulesPane.numSelectedRules = 0
}

function selectDataGroupReducer(state: WritableDraft<TableState>, action: AnyAction) {
    const dgId = action.id;
    const value = action.value;

    const dg = state.renderTable.dgs.get(dgId);
    if (!!dg) {
        const table = state.renderTable;
        table.dgs.set(dg.id, {...dg, selected: value})

        const changedCells: Set<CellRef> = updateDataGroupView(table, dg,value ? "SELECT": "UNSELECT")

        changedCells.forEach(key => {
            const [className, styles] = table.masks.eval(key);
            const cell = table.rows[key[0]].cells[key[1]]
            cell.className = className
            cell.styles = styles
        })
    }
}

function closeRulePaneReducer(state: WritableDraft<TableState>, _: AnyAction) {
    state.rulesPane.show = false
}

function selectRuleReducer(state: WritableDraft<TableState>, action: AnyAction) {
    const ruleIds: string[] | undefined= action.ids;
    if (ruleIds === undefined) {
        return
    }

    // state.rulesPane.show = true
    for (let ruleId of ruleIds) {
        const rule = state.rulesPane.rules.get(ruleId);
        if (rule !== undefined) {
            const selected = !rule.isSelected
            rule.isSelected = selected

            state.rulesPane.numSelectedRules += selected ? 1: -1

            if (rule.data.name.startsWith("Validation/")) {
                updateValidationRuleRefs(state.renderTable, rule.data.cellRefs, selected);
            } else {
                updateRuleRefs(state.renderTable, rule.data.cellRefs, selected);
            }
        }
    }
}

function selectAllRulesReducer(state: WritableDraft<TableState>, _: AnyAction) {
    const selected = !(state.rulesPane.numSelectedRules === state.rulesPane.numRule)

    if (selected) {
        state.rulesPane.numSelectedRules = state.rulesPane.numRule
    } else {
        state.rulesPane.numSelectedRules = 0
    }

    for (let rule of state.rulesPane.rules.values()) {
        rule.isSelected = selected

        if (rule.data.name.startsWith("Validation/")) {
            updateValidationRuleRefs(state.renderTable, rule.data.cellRefs, selected);
        } else {
            updateRuleRefs(state.renderTable, rule.data.cellRefs, selected);
        }
    }
}

export {
    TableActionTypes,
    setData, selectDataGroup,
    selectRule, selectAllRules, closeRulePane,
    tableReducer,
};