import {Switch} from "@mui/material";

import {Spacer} from "../util/spacer/Spacer";
import {SwitchLabel} from "../util/label/SwitchLabel";

import styles from './FlagControl.module.css'


interface ComponentProps {
    value: boolean
    onUpdate: (value: boolean) => void

    label: string
    description?: string

    disabled?: boolean
    readonly?: boolean
}

const FlagControl = (props: ComponentProps) => {
    return <div className={styles.container}>
        <div className={styles.value_container}>
            <label>{props.label}</label>
            <Spacer/>
            { props.readonly === true &&
                <SwitchLabel value={props.value}/>
            }
            { props.readonly !== true &&
                <Switch
                    disabled={props.disabled}
                    color={"secondary"}
                    checked={props.value}
                    onChange={(_, checked) => props.onUpdate(checked)}
                />
            }
        </div>
        { props.description !== undefined &&
            <div className={styles.description_container}>
                {props.description}
            </div>
        }
    </div>
}

export {FlagControl}