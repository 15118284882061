import {defaultProjectListState, ProjectListState} from "./state";
import {AnyAction} from "redux";
import {AppActionTypes} from "../../../../app/data/action/AppAction";
import {
    selectRowReducer,
    updateDataReducer,
    updateSettingsReducer
} from "../../../../qdep/components/entity-list-workspace-block/EntityListSettingsState";
import {ProjectListWorkspaceActionTypes} from "./action";
import {ProjectDto, ProjectListDto} from "../../../../app/client/app/entity/Project";


function projectListReducer(state: ProjectListState = defaultProjectListState, action: AnyAction): ProjectListState {
    switch (action.type) {
        case ProjectListWorkspaceActionTypes.UPDATE_SETTINGS:
            return updateSettingsReducer(state, action)
        case ProjectListWorkspaceActionTypes.SELECT_ROW:
            return selectRowReducer(state, action)
        case AppActionTypes.PROJECT_LIST_REQUEST_SUCCEEDED:
            return updateDataReducer(state, action)
        case AppActionTypes.PROJECT_CREATE_REQUEST_SUCCEEDED:
            return createEntityReducer(state, action)
        case AppActionTypes.PROJECT_DELETE_REQUEST_SUCCEEDED:
            return deleteEntityReducer(state, action)
    }
    return state
}

function createEntityReducer(state: ProjectListState, action: AnyAction): ProjectListState {
    const entity: ProjectDto = action.entity
    if (entity) {
        const newData = [...state.data.data, {
            id: entity.id,
            name: entity.name,
            description: entity.description,
            status: entity.status,
            reports: entity.reports.length,
            createDate: entity.createDate,
            updateDate: entity.updateDate,
        }];
        return {
            ...state,
            data: {
                ...state.data,
                data: newData,
            }
        }
    }
    return state
}

function deleteEntityReducer(state: ProjectListState, action: AnyAction): ProjectListState {
    const documentIndex = state.data.data.findIndex((item: ProjectListDto) => item.id === action.entityId);
    if (documentIndex > -1) {
        const newData = [...state.data.data];
        newData.splice(documentIndex, 1)
        return {
            ...state,
            selectedRow: undefined,
            data: {
                ...state.data,
                data: newData,
            }
        }
    }
    return state
}

export {projectListReducer}