import React, {useMemo, useState} from "react";
import {useHotkeys} from "react-hotkeys-hook";
import {useParams} from "react-router-dom";

import {Main} from "../../../qdep/components/app/main/Main";
import {ReportOverviewSideBar} from "./side_bar";
import {ReportOverviewWorkspaceBody} from "./ReportOverviewWorkspaceBody";
import {slugToWorkspaceItemTypes, WorkspaceItemTypes} from "./state";
import {ReportMenu} from "./menu";
import {PRRKey} from "../../../app/client/app/entity/Project";
import {ChainErrorBoundary} from "../../../qdep/components/app/error/ChainErrorBoundary";
import {isUnknownError} from "../../../app/client/types";
import {Workspace} from "../../../qdep/components/app/workspace/Workspace";


const ReportOverviewPage = () => {
    const address = usePageUrlParam();
    const [prrKey, setPrrKey] = useState(address.prrKey);

    const [workspaceType, setWorkspaceType] = useState(address.workspaceType);
    useHotkeys('ctrl+t', () => setWorkspaceType(WorkspaceItemTypes.TOC))

    return <Main>
        <ReportMenu prrKey={prrKey} onSelect={setPrrKey}/>
        <ReportOverviewSideBar prrKey={prrKey} currentWorkspaceType={workspaceType} onChangeWorkspaceType={setWorkspaceType}/>
        <Workspace>
            <ChainErrorBoundary errorMatcher={isUnknownError} dependencyHash={address.prrKeyStr}>
                <ReportOverviewWorkspaceBody prrKey={prrKey} renderItemType={workspaceType}/>
            </ChainErrorBoundary>
        </Workspace>
    </Main>
}

interface PageUrlParam {
    project:  string
    report:   string
    revision: string
    workspace: string | undefined
}

interface PageParam {
    prrKey: PRRKey
    prrKeyStr: string
    workspaceType: WorkspaceItemTypes
}

function usePageUrlParam(): PageParam {
    const routeParams = useParams<PageUrlParam>()
    return useMemo(
        () => ({
            prrKey: {
                projectId: routeParams.project,
                reportIndex: routeParams.report,
                revisionIndex: routeParams.revision,
            },
            prrKeyStr: `${routeParams.project}-${routeParams.report}-${routeParams.revision}`,
            workspaceType: slugToWorkspaceItemTypes(routeParams.workspace),
        }),
        [routeParams.project, routeParams.report, routeParams.revision, routeParams.workspace]
    )
}

export {ReportOverviewPage}