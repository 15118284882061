import React from "react";

import {MenuItem, Select, Skeleton} from "@mui/material";

import styles from './RevisionSelector.module.css'


interface ComponentProps {
    loading?: boolean
    revisions?: string[]
    selectedRevision?: string

    onChange?: (revisionId: string) => void
}

const RevisionSelector = (props: ComponentProps) => {
    return <div className={styles.container}>
        <label>Revision</label>
        <Select
            size={"small"}
            variant={"outlined"}
            className={styles.controller}
            disabled={props.loading || !(props.revisions && props.revisions.length > 0)}
            value={props.loading ? "loading" : props.selectedRevision || "none"}
            onChange={event => {
                if (props.onChange) {
                    props.onChange(event.target.value);
                }
            }}
        >
            { props.loading && <MenuItem value={"loading"}><Skeleton width={"100%"}/></MenuItem> }
            { props.revisions && props.revisions.length > 0 &&
                 props.revisions.map((revision, index) =>
                    <MenuItem
                        key={index}
                        value={revision}
                        disabled={props.selectedRevision === revision}
                    >
                        {revision}
                    </MenuItem>
                )
            }
            { props.selectedRevision === undefined && <MenuItem value={"none"}>None</MenuItem> }
        </Select>
    </div>
}

export {RevisionSelector}
