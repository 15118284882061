import React from "react";

import {Dialog, DialogContent, LinearProgress} from "@mui/material";

import styles from './SavingProgressDialog.module.css'


interface ProjectEditorSavingState {
    open: boolean
    projectSaving: boolean
    fileUploadProgress: FileUploadingProgress[]
}

interface FileUploadingProgress {
    name: string
    progress: number
}

const ProgressDialog = (props: ProjectEditorSavingState) => {
    return <Dialog open={props.open}>
        <DialogContent>
            <div className={styles.items_container}>
                <span className={styles.file_name}>Project saving</span>
                <LinearProgress value={props.projectSaving ? undefined : 100}/>
                <span/>

                { props.fileUploadProgress.map((item, index) =>
                    <React.Fragment key={index}>
                        <span className={styles.file_name}>{item.name}</span>
                        <LinearProgress variant="determinate" value={item.progress}/>
                        <span>{item.progress}%</span>
                    </React.Fragment>
                )}
            </div>
        </DialogContent>
    </Dialog>
}

export {ProgressDialog}
export type {ProjectEditorSavingState, FileUploadingProgress}