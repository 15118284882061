import {useParams} from "react-router-dom";
import {useSearchParam} from "react-use";
import {PRRKey} from "../../../../app/client/app/entity/Project";
import {useMemo} from "react";

interface ReportPageRouteParams {
    project:  string
    report:   string
    revision: string
}

interface ReportPageQueryParams {
    page: number | undefined
}

interface ReportPageAddress {
    prrKey: PRRKey
    query: ReportPageQueryParams
}

function useReportPageAddress(): ReportPageAddress {
    const routeParams = useParams<ReportPageRouteParams>()
    const pageQueryParam = useSearchParam("page");

    return useMemo(
        () => ({
            prrKey: {
                projectId: routeParams.project,
                reportIndex: routeParams.report,
                revisionIndex: routeParams.revision,
            },
            query: {
                page: parsePageQueryParam(pageQueryParam),
            }
        }),
        [routeParams.project, routeParams.report, routeParams.revision, pageQueryParam]
    )
}

function parsePageQueryParam(param: string | null): number | undefined {
    if (param !== null) {
        const pageNum = parseInt(param, 10);
        if (!isNaN(pageNum)) {
            return pageNum
        }
    }
    return undefined
}

export {useReportPageAddress}
export type {ReportPageRouteParams, ReportPageQueryParams, ReportPageAddress}