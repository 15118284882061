import React, {useState} from "react";
import {useAsyncFn} from "react-use";
import {useSnackbar} from "notistack";

import {IconButton, Tooltip} from "@mui/material";
import AddLinkIcon from '@mui/icons-material/AddLink';
import EditIcon from "@mui/icons-material/Edit";
import LaunchIcon from '@mui/icons-material/Launch';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

import {Spacer} from "../../../../qdep/components/app/util/spacer/Spacer";
import {SelectBaseAnalysisSettingsDialog} from "./SelectBaseAnalysisSettingsDialog";
import {OptionalField} from "../../../../qdep/data/util";
import {ModelRevisionRef} from "../../../../app/client/app/entity/report/model/ReportModel";
import {PRRKey} from "../../../../app/client/app/entity/Project";
import {apiClient} from "../../../../app/client/app/client";

import styles from './BaseAnalysisSettingsRef.module.css'


interface ComponentProps {
    prrKey: PRRKey
    // todo change to OptionalField<ModelRevisionRefDto>
    modelRevisionRef: OptionalField<ModelRevisionRef>
    onAttach: (analysisSettingsRef: ModelRevisionRef) => void
    readonly: boolean
    updateReadOnly: (value: boolean) => void
}

const BaseAnalysisSettingsRef = (props: ComponentProps) => {
    const [openSelectDialog, setOpenSelectDialog] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [, resetSettings] = useAsyncFn(async () => {
        const response = await apiClient.resetReportSettings(props.prrKey);
        if (response.errorReason === undefined && response.data !== undefined) {
            enqueueSnackbar(
                "Report Settings updated. Refresh the page",
                {key: "reset-analysis-settings-request", variant: "success"},
            );
            // todo update report stages status
        }
    }, [props.prrKey]);

    return <>
        <div className={styles.container}>
            <span className={styles.label}>Base Settings Ref:</span>
            <span className={styles.value}>
                { !!props.modelRevisionRef
                    ? `${props.modelRevisionRef.modelId} / ${props.modelRevisionRef.revisionId}`
                    : "None"
                }
            </span>
            <Spacer/>
            <IconButton disabled>
                <LaunchIcon/>
            </IconButton>
            <IconButton onClick={() => setOpenSelectDialog(true)}>
                <AddLinkIcon/>
            </IconButton>
            <hr className={styles.divider}/>
            <Tooltip title={"Reset settings to default"}>
                <IconButton onClick={resetSettings}>
                    <SettingsBackupRestoreIcon/>
                </IconButton>
            </Tooltip>
            <IconButton
                disabled={!props.readonly}
                onClick={() => props.updateReadOnly(false)}
            >
                <EditIcon/>
            </IconButton>
        </div>

        <SelectBaseAnalysisSettingsDialog
            prrKey={props.prrKey}
            currentRevisionId={props.modelRevisionRef?.revisionId}

            isOpen={openSelectDialog}
            close={() => setOpenSelectDialog(false)}
            apply={ref => {
                props.onAttach(ref)
                setOpenSelectDialog(false)
            }}
        />
    </>
}

export {BaseAnalysisSettingsRef}
