import React, {useState} from "react";
import {useMount} from "react-use";

import {IconButton, Skeleton, Stack} from "@mui/material";
import VerifiedIcon from '@mui/icons-material/Verified';

import {DestRef, TableMatchRef} from "../../../../../app/client/app/entity/Rp6Report";
import {apiClient} from "../../../../../app/client/app/client";
import {Rp3Cell, Rp3Table} from "../../../../../app/client/app/entity/Rp3Report";
import {ReportTableWorkspaceBlockBody} from "../../base/report-table/ReportTableWorkspaceBlockBody";
import {
    WorkspaceBlock,
    WorkspaceBlockBody,
    WorkspaceBlockHeader
} from "../../../../../qdep/components/app/workspace/Workspace";

import reportTableStyles from "../../base/report-table/ReportTableWorkspaceBlockBody.module.css";
import styles from "./DestTableSnap.module.css"
import {Label} from "../../../../../qdep/components/app/util/label/Label";
import {Spacer} from "../../../../../qdep/components/app/util/spacer/Spacer";
import {TableTitleContainer} from "../../base/report-table-title-container/TableTitleContainer";
import {TableTitle} from "../../../../../app/client/app/entity/report/TableTitle";


interface ComponentProps {
    dest: TableMatchRef
}

interface ComponentState {
    originDest: Rp3Table | null
    viewDest: TableView | null
}

interface TableView {
    title: TableTitle
    rows: RowView[]
}

interface RowView {
    cells: CellView[]
    rowNumber: number
    rowEndNumber?: number
}

interface CellView {
    className: string
    colSpan: number
    value: string
    needApprove?: boolean
}

const DestTableSnap = (props: ComponentProps) => {
    const [state, setState] = useState<ComponentState>({originDest: null, viewDest: null})
    useMount(() => {
        apiClient.getTableById(props.dest.tableId)
            .then(response => {
                if (response.errorReason === undefined && response.data !== undefined) {
                    setState({
                        originDest: response.data,
                        viewDest: addRefToTable(response.data, props.dest.dest),
                    })
                }
            })
    })

    return <WorkspaceBlock key={props.dest.tableId}>
        <WorkspaceBlockHeader>
            <Stack direction={"row"} width={"100%"} gap={"4px"} alignItems={"center"}>
                <TableTitleContainer title={state.viewDest?.title} tableId={props.dest.tableId}/>
                <Spacer/>
                { renderTableResultLabel(props.dest) }
            </Stack>
        </WorkspaceBlockHeader>
        { !state.viewDest &&
            <WorkspaceBlockBody>
                <Skeleton variant={"rectangular"} height={"200px"}/>
            </WorkspaceBlockBody>
        }
        { !!state.viewDest &&
            <ReportTableWorkspaceBlockBody>
                { state.viewDest.rows.map((row, rowIndex) =>
                    <tr key={`dt_tr-${rowIndex}`}>
                        <td className={reportTableStyles.row_number}>
                            <span>{ row.rowNumber }</span>
                            { row.rowEndNumber !== undefined && <span>:{row.rowEndNumber}</span> }
                        </td>
                        { row.cells
                            .map((cell, columnIndex) =>
                                <td
                                    key={`rc-${columnIndex}`}
                                    className={cell.className}
                                    colSpan={cell.colSpan}
                                >
                                    { renderCellValue(cell) }
                                </td>
                            )
                        }
                    </tr>
                )}
            </ReportTableWorkspaceBlockBody>
        }
    </WorkspaceBlock>
}

function cellClassName(cell: Rp3Cell): string {
    let classNames = [];
    if (cell.cellType === "HEADER") {
        classNames.push(reportTableStyles.header)
    } else if (cell.cellType === "DATA"
        && (cell.dataType === "NUMERIC" || cell.dataType === "NUMERIC_CURRENCY"  || cell.dataType === "NUMERIC_PERCENT" ))
    {
        classNames.push(reportTableStyles.data_numeric)
    }

    if (classNames.length > 0) {
        return classNames.join(" ")
    }
    return ""
}

function addRefToTable(table: Rp3Table | null, ref: DestRef | null): TableView | null {
    if (table === null) {
        return null
    }

    if (ref === null) {
        return {
            title: table.title,
            rows: table.rows.map((rp2row, index) => ({
                cells: rp2row.cells.map(rp2Cell => ({
                    className: cellClassName(rp2Cell),
                    colSpan: rp2Cell.colSpan,
                    value: rp2Cell.value,
                })),
                rowNumber: index,
            })),
        };
    }

    let view: TableView | null = null
    if (ref.type === "DIRECT" && ref.direct !== null) {
        const rowIndex = ref.direct.cell.row;
        const minRowIndex = Math.max(0, rowIndex - 3);
        const maxRowIndex = Math.min(rowIndex + 2, table.rows.length);
        const columnIndex = ref.direct.cell.column - 1;
        const rows: RowView[] = table.rows
            .slice(minRowIndex, maxRowIndex)
            .map((rp2row, index) => ({
                cells: rp2row.cells.map(rp2Cell => ({
                    className: cellClassName(rp2Cell),
                    colSpan: rp2Cell.colSpan,
                    value: rp2Cell.value,
                })),
                rowNumber: index + minRowIndex,
            }));

        rows[rowIndex-minRowIndex-1].cells[columnIndex].className += " " + styles.ref_cell;

        view = {
            title: table.title,
            rows: rows,
        };
    }
    if (ref.type === "VIRTUAL" && ref.virtual !== null) {
        const minRowIndex =
                Math.max(0, Math.min(...ref.virtual.map(cell => cell.cell.row)) - 2);
        const maxRowIndex = Math.max(...ref.virtual.map(cell => cell.cell.row));

        const rows: RowView[] = table.rows
            .slice(minRowIndex, Math.min(maxRowIndex + 1, table.rows.length))
            .map((rp2row, index) => ({
                cells: rp2row.cells.map(rp2Cell => ({
                    className: cellClassName(rp2Cell),
                    colSpan: rp2Cell.colSpan,
                    value: rp2Cell.value,
                })),
                rowNumber: index + minRowIndex,
            }));

        ref.virtual.forEach(cell => {
            rows[cell.cell.row-minRowIndex-1].cells[cell.cell.column-1].className += " " + styles.ref_cell
        })

        view = {
            title: table.title,
            rows: rows,
        };
    }
    if (ref.type === "POSSIBLE_DIRECT" && ref.possibleVariants !== null) {
        const minRowIndex =
            Math.max(0, Math.min(...ref.possibleVariants.map(cell => cell.cell.row)) - 2);
        const maxRowIndex = Math.max(...ref.possibleVariants.map(cell => cell.cell.row));

        const rows: RowView[] = table.rows
            .slice(minRowIndex, Math.min(maxRowIndex + 1, table.rows.length))
            .map((rp2row, index) => ({
                cells: rp2row.cells.map(rp2Cell => ({
                    className: cellClassName(rp2Cell),
                    colSpan: rp2Cell.colSpan,
                    value: rp2Cell.value,
                })),
                rowNumber: index + minRowIndex,
            }));

        ref.possibleVariants.forEach(cell => {
            rows[cell.cell.row-minRowIndex-1].cells[cell.cell.column-1].className += " " + styles.possible_ref_cell
            rows[cell.cell.row-minRowIndex-1].cells[cell.cell.column-1].needApprove = true
        })

        view = {
            title: table.title,
            rows: rows,
        };
    }

    if (view !== null && view.rows.length > 0) {
        const headerSize = Math.min(table.metadata.column.headerSize, view.rows[0].rowNumber)

        const headers: RowView[] = []
        for (let i = 0; i < headerSize; i++) {
            headers.push({
                cells: table.rows[i].cells.map(rp2Cell => ({
                    className: cellClassName(rp2Cell),
                    colSpan: rp2Cell.colSpan,
                    value: rp2Cell.value,
                })),
                rowNumber: i,
            });
        }

        if (view.rows[0].rowNumber - headerSize > 0) {
            headers.push({
                rowNumber: headerSize,
                rowEndNumber: view.rows[0].rowNumber - 1,
                cells: [{
                    className: styles.collapsed_part,
                    colSpan: table.metadata.column.size,
                    value: "",
                }]
            })
        }

        if (view.rows[view.rows.length-1].rowNumber + 1 !== table.rows.length) {
            view.rows.push({
                rowNumber: view.rows[view.rows.length-1].rowNumber + 1,
                rowEndNumber: table.rows.length - 1,
                cells: [{
                    className: styles.collapsed_part,
                    colSpan: table.metadata.column.size,
                    value: "",
                }]
            })
        }

        view.rows.unshift(...headers);
    }

    return view;
}

function renderTableResultLabel(ref: TableMatchRef): React.ReactElement {
    if (ref.dest === null || ref.dest.type === "POSSIBLE_DIRECT") {
        return <Label text={"Not linked"} variant={"info"}/>
    }
    if (ref.valid) {
        return <Label text={"Passed"} variant={"success"}/>;
    }
    return <Label text={"Failed"} variant={"error"}/>;
}

function renderCellValue(cell: CellView): React.ReactNode {
    if (!!cell.needApprove) {
        return <div className={styles.value_container}>
            <span>{cell.value}</span>
            <IconButton>
                <VerifiedIcon color={"secondary"}/>
            </IconButton>
        </div>
    }
    return cell.value;
}

export {DestTableSnap}